/* eslint-disable */
export const protobufPackage = 'com.celertech.positionmanager.api.enums.timeinforcetype';

export enum TimeInForceType {
    DAY = 1,
    GTD = 2,
    GTC = 3,
    FOK = 4,
    OPG = 5,
    IOC = 6,
    GTX = 7,
    ATC = 8,
    UNRECOGNIZED = -1
}
