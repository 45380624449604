import ModuleWindowControls from '@/components/mosaic/ModuleWindowControl';
import ModuleWindow from './ModuleWindow';

const FeatureModule = (props: any) => {
    const { screen, tradingModule } = props;

    return (
        <ModuleWindow
            {...props}
            renderToolbar={() => (
                <div className="flex justify-between items-center w-full h-full border-b border-b-neutral-700 text-neutral-200">
                    <div className="h-full flex items-center">
                        <span className="p-2 font-semibold">{tradingModule?.title}</span>
                    </div>
                    <ModuleWindowControls screen={screen} />
                </div>
            )}>
            {tradingModule?.component}
        </ModuleWindow>
    );
};

export default FeatureModule;
