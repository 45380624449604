/* eslint-disable react/no-unknown-property */
import { TimeInForceType } from '@/compiled_proto/com/celertech/positionmanager/api/enums/TimeInForceTypeProto';
import { Button } from '@/components/common/Button';
import { Modify } from '@/model/common';
import { submitMarketOrder } from '@/services/OrderService';
import { useAppSelector } from '@/state/hooks';
import { selectCredentials, selectCurrentAccount } from '@/state/reducers/authSlice';
import { selectSettings } from '@/state/reducers/settingSlice';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Modal, { ModalProps } from '../Modal';
import ConvertConfirmationTab from './ConvertConfirmationTab';

type ConvertConfirmationModalProps = Modify<
    ModalProps,
    {
        onClose: () => void;
        conversionProps: any;
    }
>;

const ConvertConfirmationModal = (props: ConvertConfirmationModalProps) => {
    const { handlers, onClose, conversionProps } = props;
    const settings = useAppSelector(selectSettings);

    // const [toastError] = useToast();

    const credentials = useAppSelector(selectCredentials);
    const currentAccount = useAppSelector(selectCurrentAccount);

    const [isLoading, setIsLoading] = useState(false);

    const { watch } = useFormContext();
    const formValues = watch(['bestPrice', 'pair', 'convertFrom', 'amount', 'side']);

    const onSubmitConvert = useCallback(async () => {
        setIsLoading(true);
        // try {
        // } catch (e) {
        //     toastError(e);
        // }
        if (credentials) {
            onClose();
            const [bestPrice, pair, convertFrom, amount, side] = formValues;
            await submitMarketOrder(
                {
                    spotPrice: bestPrice,
                    securityId: pair,
                    currencyOut: convertFrom.value,
                    quantity: amount,
                    timeInForce: settings.trading.defaultMarketOrderDuration.value as TimeInForceType,
                    side
                },
                credentials,
                currentAccount
            );
            handlers.close();
        }
        setIsLoading(false);
    }, [credentials, currentAccount, formValues, settings]);

    return (
        <Modal {...props} size="max-w-sm" closeOnOverlay>
            <div className="h-full flex flex-col lg:block lg:h-auto">
                <div className="h-full flex flex-col lg:h-auto lg:block w-full text-neutral-200 py-2 px-6">
                    <ConvertConfirmationTab conversionProps={conversionProps} />
                </div>
                <div className="flex flex-row justify-between text-neutral-200 text-sm items-center py-4 px-6">
                    <Button
                        type="button"
                        className="w-full rounded-md p-2 px-4 bg-brand-primary hover:bg-brand-primary-light"
                        onClick={onSubmitConvert}
                        isLoading={isLoading}>
                        CONFIRM ORDER
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ConvertConfirmationModal;
