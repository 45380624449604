/* eslint-disable */
import { AssetType } from '../enums/AssetTypeProto';
import { Side } from '../enums/SideProto';
import { ProductType } from '../enums/ProductTypeProto';
import { QuotingType } from '../enums/QuotingTypeProto';
import { QuoteType } from '../enums/QuoteTypeProto';
import { ManualQuoteSource } from '../enums/ManualQuoteSourceProto';
import { QuoteRequestRejectReason } from '../enums/QuoteRequestRejectReasonProto';
import { QuoteCancelType } from '../enums/QuoteCancelTypeProto';
import Long from 'long';
import { KeyValue } from './QuoteMetadata';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.marketdata.api.quote';

export interface QuoteDownstreamEvent {
    quoteRequestId: string;
    quoteId: string;
    assetType: AssetType;
    exchangeCode: string;
    securityCode: string;
    securityId: string;
    side: Side;
    bidPx: number;
    bidSpotPx: number;
    offerPx: number;
    offerSpotPx: number;
    quoteTimestampUtcInMillis: string;
    validUntilTimeUtcInMillis: string;
    destination: string;
    legQuoteGroup: QuoteDownstreamEvent_LegQuoteGroup[];
    bookAsFilled: boolean;
    accountBookOn: string;
    bidMargin: number;
    offerMargin: number;
    productType: ProductType;
    account: string;
    requestedByUser: string;
    quotingType: QuotingType;
    settlCurrency: string;
    rateSource: string;
    midPx: number;
    /** SessionKey of user who made the quote */
    quotedBySessionKey: string;
    quoteMetadata: KeyValue[];
    skipCreditCheck: string;
    /** Indicative, Tradeable, Restricted Tradeable, or Counter */
    quoteType: QuoteType;
    tieredLegQuoteGroup: QuoteDownstreamEvent_LegQuoteGroup[];
    manualQuoteSource: ManualQuoteSource;
}

export interface QuoteDownstreamEvent_LegQuoteGroup {
    bidForwardPoints: number;
    offerForwardPoints: number;
    bidPx: number;
    offerPx: number;
    currency: string;
    settlementDate: string;
    maturityDate: string;
    settlementType: string;
    bidSize: number;
    offerSize: number;
    midRate: number;
    rawMidRate: number;
}

export interface QuoteRequestRejectDownstreamEvent {
    quoteRequestId: string;
    quoteRequestRejectReason: QuoteRequestRejectReason;
    quoteRequestRejectGroup: QuoteRequestRejectGroup[];
    requestedByUser: string;
    /** time when this quote request reject is sent */
    rejectTimestampInUtcInMillis: string;
    quoteMetadata: KeyValue[];
}

export interface QuoteRequestRejectGroup {
    assetType: AssetType;
    productType: ProductType;
    exchangeCode: string;
    securityCode: string;
    securityId: string;
    text: string;
}

export interface QuoteCancelDownstreamEvent {
    quoteRequestId: string;
    quoteId: string;
    quoteCancelType: QuoteCancelType;
    requestedByUser: string;
    /** time when this quote request cancel is sent */
    cancelTimestampInUtcInMillis: string;
    quoteMetadata: KeyValue[];
}

/** Notifies the Celer Trading Client GUI that it has received a quote request */
export interface QuoteRequestNotification {
    requestorSessionKey: string;
    requestorSessionToken: string;
    quoteRequestId: string;
    quoteRequestNotificationGroup: QuoteRequestNotificationGroup[];
    text: string;
    requestedByUser: string;
    orderSource: string;
    /** time when this quote request notification is sent */
    timestampInUtcInMillis: string;
    /** Type of quote; valid values are AUTOMATIC, MANUAL */
    quotingType: QuotingType;
    quoteMetadata: KeyValue[];
    quoteRequestNotificationAllocGroup: QuoteRequestNotificationAllocGroup[];
}

export interface QuoteAcknowledgement {
    quoteRequestId: string;
    quoteId: string;
    quoteAckStatus: string;
    quoteRejectReason: string;
    text: string;
}

export interface QuoteRequestNotificationGroup {
    assetType: AssetType;
    productType: ProductType;
    exchangeCode: string;
    securityCode: string;
    securityId: string;
    settlementDate: string;
    side: Side;
    qty: number;
    currencyCode: string;
    partyId: string;
    maturityDate: string;
    text: string;
    settlementType: string;
    accountName: string;
    /** Used for NDFs */
    rateSource: string;
}

export interface QuoteRequestNotificationAllocGroup {
    allocAccount: string;
    allocQty: number;
    allocSettlementDate: string;
}

function createBaseQuoteDownstreamEvent(): QuoteDownstreamEvent {
    return {
        quoteRequestId: '',
        quoteId: '',
        assetType: 1,
        exchangeCode: '',
        securityCode: '',
        securityId: '',
        side: 1,
        bidPx: 0,
        bidSpotPx: 0,
        offerPx: 0,
        offerSpotPx: 0,
        quoteTimestampUtcInMillis: '0',
        validUntilTimeUtcInMillis: '0',
        destination: '',
        legQuoteGroup: [],
        bookAsFilled: false,
        accountBookOn: '',
        bidMargin: 0,
        offerMargin: 0,
        productType: 1,
        account: '',
        requestedByUser: '',
        quotingType: 1,
        settlCurrency: '',
        rateSource: '',
        midPx: 0,
        quotedBySessionKey: '',
        quoteMetadata: [],
        skipCreditCheck: '',
        quoteType: 1,
        tieredLegQuoteGroup: [],
        manualQuoteSource: 1
    };
}

export const QuoteDownstreamEvent = {
    encode(message: QuoteDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.quoteRequestId !== '') {
            writer.uint32(10).string(message.quoteRequestId);
        }
        if (message.quoteId !== '') {
            writer.uint32(18).string(message.quoteId);
        }
        if (message.assetType !== 1) {
            writer.uint32(24).int32(message.assetType);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(34).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(42).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(50).string(message.securityId);
        }
        if (message.side !== 1) {
            writer.uint32(56).int32(message.side);
        }
        if (message.bidPx !== 0) {
            writer.uint32(65).double(message.bidPx);
        }
        if (message.bidSpotPx !== 0) {
            writer.uint32(73).double(message.bidSpotPx);
        }
        if (message.offerPx !== 0) {
            writer.uint32(81).double(message.offerPx);
        }
        if (message.offerSpotPx !== 0) {
            writer.uint32(89).double(message.offerSpotPx);
        }
        if (message.quoteTimestampUtcInMillis !== '0') {
            writer.uint32(112).int64(message.quoteTimestampUtcInMillis);
        }
        if (message.validUntilTimeUtcInMillis !== '0') {
            writer.uint32(120).int64(message.validUntilTimeUtcInMillis);
        }
        if (message.destination !== '') {
            writer.uint32(130).string(message.destination);
        }
        for (const v of message.legQuoteGroup) {
            QuoteDownstreamEvent_LegQuoteGroup.encode(v!, writer.uint32(138).fork()).ldelim();
        }
        if (message.bookAsFilled === true) {
            writer.uint32(144).bool(message.bookAsFilled);
        }
        if (message.accountBookOn !== '') {
            writer.uint32(154).string(message.accountBookOn);
        }
        if (message.bidMargin !== 0) {
            writer.uint32(161).double(message.bidMargin);
        }
        if (message.offerMargin !== 0) {
            writer.uint32(169).double(message.offerMargin);
        }
        if (message.productType !== 1) {
            writer.uint32(176).int32(message.productType);
        }
        if (message.account !== '') {
            writer.uint32(186).string(message.account);
        }
        if (message.requestedByUser !== '') {
            writer.uint32(194).string(message.requestedByUser);
        }
        if (message.quotingType !== 1) {
            writer.uint32(200).int32(message.quotingType);
        }
        if (message.settlCurrency !== '') {
            writer.uint32(210).string(message.settlCurrency);
        }
        if (message.rateSource !== '') {
            writer.uint32(218).string(message.rateSource);
        }
        if (message.midPx !== 0) {
            writer.uint32(225).double(message.midPx);
        }
        if (message.quotedBySessionKey !== '') {
            writer.uint32(234).string(message.quotedBySessionKey);
        }
        for (const v of message.quoteMetadata) {
            KeyValue.encode(v!, writer.uint32(242).fork()).ldelim();
        }
        if (message.skipCreditCheck !== '') {
            writer.uint32(250).string(message.skipCreditCheck);
        }
        if (message.quoteType !== 1) {
            writer.uint32(256).int32(message.quoteType);
        }
        for (const v of message.tieredLegQuoteGroup) {
            QuoteDownstreamEvent_LegQuoteGroup.encode(v!, writer.uint32(266).fork()).ldelim();
        }
        if (message.manualQuoteSource !== 1) {
            writer.uint32(272).int32(message.manualQuoteSource);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): QuoteDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuoteDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.quoteRequestId = reader.string();
                    break;
                case 2:
                    message.quoteId = reader.string();
                    break;
                case 3:
                    message.assetType = reader.int32() as any;
                    break;
                case 4:
                    message.exchangeCode = reader.string();
                    break;
                case 5:
                    message.securityCode = reader.string();
                    break;
                case 6:
                    message.securityId = reader.string();
                    break;
                case 7:
                    message.side = reader.int32() as any;
                    break;
                case 8:
                    message.bidPx = reader.double();
                    break;
                case 9:
                    message.bidSpotPx = reader.double();
                    break;
                case 10:
                    message.offerPx = reader.double();
                    break;
                case 11:
                    message.offerSpotPx = reader.double();
                    break;
                case 14:
                    message.quoteTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 15:
                    message.validUntilTimeUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 16:
                    message.destination = reader.string();
                    break;
                case 17:
                    message.legQuoteGroup.push(QuoteDownstreamEvent_LegQuoteGroup.decode(reader, reader.uint32()));
                    break;
                case 18:
                    message.bookAsFilled = reader.bool();
                    break;
                case 19:
                    message.accountBookOn = reader.string();
                    break;
                case 20:
                    message.bidMargin = reader.double();
                    break;
                case 21:
                    message.offerMargin = reader.double();
                    break;
                case 22:
                    message.productType = reader.int32() as any;
                    break;
                case 23:
                    message.account = reader.string();
                    break;
                case 24:
                    message.requestedByUser = reader.string();
                    break;
                case 25:
                    message.quotingType = reader.int32() as any;
                    break;
                case 26:
                    message.settlCurrency = reader.string();
                    break;
                case 27:
                    message.rateSource = reader.string();
                    break;
                case 28:
                    message.midPx = reader.double();
                    break;
                case 29:
                    message.quotedBySessionKey = reader.string();
                    break;
                case 30:
                    message.quoteMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 31:
                    message.skipCreditCheck = reader.string();
                    break;
                case 32:
                    message.quoteType = reader.int32() as any;
                    break;
                case 33:
                    message.tieredLegQuoteGroup.push(
                        QuoteDownstreamEvent_LegQuoteGroup.decode(reader, reader.uint32())
                    );
                    break;
                case 34:
                    message.manualQuoteSource = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<QuoteDownstreamEvent>): QuoteDownstreamEvent {
        const message = createBaseQuoteDownstreamEvent();
        message.quoteRequestId = object.quoteRequestId ?? '';
        message.quoteId = object.quoteId ?? '';
        message.assetType = object.assetType ?? 1;
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.side = object.side ?? 1;
        message.bidPx = object.bidPx ?? 0;
        message.bidSpotPx = object.bidSpotPx ?? 0;
        message.offerPx = object.offerPx ?? 0;
        message.offerSpotPx = object.offerSpotPx ?? 0;
        message.quoteTimestampUtcInMillis = object.quoteTimestampUtcInMillis ?? '0';
        message.validUntilTimeUtcInMillis = object.validUntilTimeUtcInMillis ?? '0';
        message.destination = object.destination ?? '';
        message.legQuoteGroup =
            object.legQuoteGroup?.map((e) => QuoteDownstreamEvent_LegQuoteGroup.fromPartial(e)) || [];
        message.bookAsFilled = object.bookAsFilled ?? false;
        message.accountBookOn = object.accountBookOn ?? '';
        message.bidMargin = object.bidMargin ?? 0;
        message.offerMargin = object.offerMargin ?? 0;
        message.productType = object.productType ?? 1;
        message.account = object.account ?? '';
        message.requestedByUser = object.requestedByUser ?? '';
        message.quotingType = object.quotingType ?? 1;
        message.settlCurrency = object.settlCurrency ?? '';
        message.rateSource = object.rateSource ?? '';
        message.midPx = object.midPx ?? 0;
        message.quotedBySessionKey = object.quotedBySessionKey ?? '';
        message.quoteMetadata = object.quoteMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.skipCreditCheck = object.skipCreditCheck ?? '';
        message.quoteType = object.quoteType ?? 1;
        message.tieredLegQuoteGroup =
            object.tieredLegQuoteGroup?.map((e) => QuoteDownstreamEvent_LegQuoteGroup.fromPartial(e)) || [];
        message.manualQuoteSource = object.manualQuoteSource ?? 1;
        return message;
    }
};

function createBaseQuoteDownstreamEvent_LegQuoteGroup(): QuoteDownstreamEvent_LegQuoteGroup {
    return {
        bidForwardPoints: 0,
        offerForwardPoints: 0,
        bidPx: 0,
        offerPx: 0,
        currency: '',
        settlementDate: '',
        maturityDate: '',
        settlementType: '',
        bidSize: 0,
        offerSize: 0,
        midRate: 0,
        rawMidRate: 0
    };
}

export const QuoteDownstreamEvent_LegQuoteGroup = {
    encode(message: QuoteDownstreamEvent_LegQuoteGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.bidForwardPoints !== 0) {
            writer.uint32(17).double(message.bidForwardPoints);
        }
        if (message.offerForwardPoints !== 0) {
            writer.uint32(25).double(message.offerForwardPoints);
        }
        if (message.bidPx !== 0) {
            writer.uint32(33).double(message.bidPx);
        }
        if (message.offerPx !== 0) {
            writer.uint32(41).double(message.offerPx);
        }
        if (message.currency !== '') {
            writer.uint32(50).string(message.currency);
        }
        if (message.settlementDate !== '') {
            writer.uint32(58).string(message.settlementDate);
        }
        if (message.maturityDate !== '') {
            writer.uint32(66).string(message.maturityDate);
        }
        if (message.settlementType !== '') {
            writer.uint32(74).string(message.settlementType);
        }
        if (message.bidSize !== 0) {
            writer.uint32(81).double(message.bidSize);
        }
        if (message.offerSize !== 0) {
            writer.uint32(89).double(message.offerSize);
        }
        if (message.midRate !== 0) {
            writer.uint32(97).double(message.midRate);
        }
        if (message.rawMidRate !== 0) {
            writer.uint32(105).double(message.rawMidRate);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): QuoteDownstreamEvent_LegQuoteGroup {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuoteDownstreamEvent_LegQuoteGroup();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.bidForwardPoints = reader.double();
                    break;
                case 3:
                    message.offerForwardPoints = reader.double();
                    break;
                case 4:
                    message.bidPx = reader.double();
                    break;
                case 5:
                    message.offerPx = reader.double();
                    break;
                case 6:
                    message.currency = reader.string();
                    break;
                case 7:
                    message.settlementDate = reader.string();
                    break;
                case 8:
                    message.maturityDate = reader.string();
                    break;
                case 9:
                    message.settlementType = reader.string();
                    break;
                case 10:
                    message.bidSize = reader.double();
                    break;
                case 11:
                    message.offerSize = reader.double();
                    break;
                case 12:
                    message.midRate = reader.double();
                    break;
                case 13:
                    message.rawMidRate = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<QuoteDownstreamEvent_LegQuoteGroup>): QuoteDownstreamEvent_LegQuoteGroup {
        const message = createBaseQuoteDownstreamEvent_LegQuoteGroup();
        message.bidForwardPoints = object.bidForwardPoints ?? 0;
        message.offerForwardPoints = object.offerForwardPoints ?? 0;
        message.bidPx = object.bidPx ?? 0;
        message.offerPx = object.offerPx ?? 0;
        message.currency = object.currency ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.maturityDate = object.maturityDate ?? '';
        message.settlementType = object.settlementType ?? '';
        message.bidSize = object.bidSize ?? 0;
        message.offerSize = object.offerSize ?? 0;
        message.midRate = object.midRate ?? 0;
        message.rawMidRate = object.rawMidRate ?? 0;
        return message;
    }
};

function createBaseQuoteRequestRejectDownstreamEvent(): QuoteRequestRejectDownstreamEvent {
    return {
        quoteRequestId: '',
        quoteRequestRejectReason: 1,
        quoteRequestRejectGroup: [],
        requestedByUser: '',
        rejectTimestampInUtcInMillis: '0',
        quoteMetadata: []
    };
}

export const QuoteRequestRejectDownstreamEvent = {
    encode(message: QuoteRequestRejectDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.quoteRequestId !== '') {
            writer.uint32(10).string(message.quoteRequestId);
        }
        if (message.quoteRequestRejectReason !== 1) {
            writer.uint32(16).int32(message.quoteRequestRejectReason);
        }
        for (const v of message.quoteRequestRejectGroup) {
            QuoteRequestRejectGroup.encode(v!, writer.uint32(26).fork()).ldelim();
        }
        if (message.requestedByUser !== '') {
            writer.uint32(34).string(message.requestedByUser);
        }
        if (message.rejectTimestampInUtcInMillis !== '0') {
            writer.uint32(40).int64(message.rejectTimestampInUtcInMillis);
        }
        for (const v of message.quoteMetadata) {
            KeyValue.encode(v!, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): QuoteRequestRejectDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuoteRequestRejectDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.quoteRequestId = reader.string();
                    break;
                case 2:
                    message.quoteRequestRejectReason = reader.int32() as any;
                    break;
                case 3:
                    message.quoteRequestRejectGroup.push(QuoteRequestRejectGroup.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.requestedByUser = reader.string();
                    break;
                case 5:
                    message.rejectTimestampInUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 6:
                    message.quoteMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<QuoteRequestRejectDownstreamEvent>): QuoteRequestRejectDownstreamEvent {
        const message = createBaseQuoteRequestRejectDownstreamEvent();
        message.quoteRequestId = object.quoteRequestId ?? '';
        message.quoteRequestRejectReason = object.quoteRequestRejectReason ?? 1;
        message.quoteRequestRejectGroup =
            object.quoteRequestRejectGroup?.map((e) => QuoteRequestRejectGroup.fromPartial(e)) || [];
        message.requestedByUser = object.requestedByUser ?? '';
        message.rejectTimestampInUtcInMillis = object.rejectTimestampInUtcInMillis ?? '0';
        message.quoteMetadata = object.quoteMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        return message;
    }
};

function createBaseQuoteRequestRejectGroup(): QuoteRequestRejectGroup {
    return { assetType: 1, productType: 1, exchangeCode: '', securityCode: '', securityId: '', text: '' };
}

export const QuoteRequestRejectGroup = {
    encode(message: QuoteRequestRejectGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.assetType !== 1) {
            writer.uint32(8).int32(message.assetType);
        }
        if (message.productType !== 1) {
            writer.uint32(16).int32(message.productType);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(26).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(34).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(42).string(message.securityId);
        }
        if (message.text !== '') {
            writer.uint32(50).string(message.text);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): QuoteRequestRejectGroup {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuoteRequestRejectGroup();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.assetType = reader.int32() as any;
                    break;
                case 2:
                    message.productType = reader.int32() as any;
                    break;
                case 3:
                    message.exchangeCode = reader.string();
                    break;
                case 4:
                    message.securityCode = reader.string();
                    break;
                case 5:
                    message.securityId = reader.string();
                    break;
                case 6:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<QuoteRequestRejectGroup>): QuoteRequestRejectGroup {
        const message = createBaseQuoteRequestRejectGroup();
        message.assetType = object.assetType ?? 1;
        message.productType = object.productType ?? 1;
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.text = object.text ?? '';
        return message;
    }
};

function createBaseQuoteCancelDownstreamEvent(): QuoteCancelDownstreamEvent {
    return {
        quoteRequestId: '',
        quoteId: '',
        quoteCancelType: 1,
        requestedByUser: '',
        cancelTimestampInUtcInMillis: '0',
        quoteMetadata: []
    };
}

export const QuoteCancelDownstreamEvent = {
    encode(message: QuoteCancelDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.quoteRequestId !== '') {
            writer.uint32(18).string(message.quoteRequestId);
        }
        if (message.quoteId !== '') {
            writer.uint32(26).string(message.quoteId);
        }
        if (message.quoteCancelType !== 1) {
            writer.uint32(32).int32(message.quoteCancelType);
        }
        if (message.requestedByUser !== '') {
            writer.uint32(42).string(message.requestedByUser);
        }
        if (message.cancelTimestampInUtcInMillis !== '0') {
            writer.uint32(48).int64(message.cancelTimestampInUtcInMillis);
        }
        for (const v of message.quoteMetadata) {
            KeyValue.encode(v!, writer.uint32(58).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): QuoteCancelDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuoteCancelDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.quoteRequestId = reader.string();
                    break;
                case 3:
                    message.quoteId = reader.string();
                    break;
                case 4:
                    message.quoteCancelType = reader.int32() as any;
                    break;
                case 5:
                    message.requestedByUser = reader.string();
                    break;
                case 6:
                    message.cancelTimestampInUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 7:
                    message.quoteMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<QuoteCancelDownstreamEvent>): QuoteCancelDownstreamEvent {
        const message = createBaseQuoteCancelDownstreamEvent();
        message.quoteRequestId = object.quoteRequestId ?? '';
        message.quoteId = object.quoteId ?? '';
        message.quoteCancelType = object.quoteCancelType ?? 1;
        message.requestedByUser = object.requestedByUser ?? '';
        message.cancelTimestampInUtcInMillis = object.cancelTimestampInUtcInMillis ?? '0';
        message.quoteMetadata = object.quoteMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        return message;
    }
};

function createBaseQuoteRequestNotification(): QuoteRequestNotification {
    return {
        requestorSessionKey: '',
        requestorSessionToken: '',
        quoteRequestId: '',
        quoteRequestNotificationGroup: [],
        text: '',
        requestedByUser: '',
        orderSource: '',
        timestampInUtcInMillis: '0',
        quotingType: 1,
        quoteMetadata: [],
        quoteRequestNotificationAllocGroup: []
    };
}

export const QuoteRequestNotification = {
    encode(message: QuoteRequestNotification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.requestorSessionKey !== '') {
            writer.uint32(10).string(message.requestorSessionKey);
        }
        if (message.requestorSessionToken !== '') {
            writer.uint32(18).string(message.requestorSessionToken);
        }
        if (message.quoteRequestId !== '') {
            writer.uint32(26).string(message.quoteRequestId);
        }
        for (const v of message.quoteRequestNotificationGroup) {
            QuoteRequestNotificationGroup.encode(v!, writer.uint32(34).fork()).ldelim();
        }
        if (message.text !== '') {
            writer.uint32(42).string(message.text);
        }
        if (message.requestedByUser !== '') {
            writer.uint32(50).string(message.requestedByUser);
        }
        if (message.orderSource !== '') {
            writer.uint32(58).string(message.orderSource);
        }
        if (message.timestampInUtcInMillis !== '0') {
            writer.uint32(64).int64(message.timestampInUtcInMillis);
        }
        if (message.quotingType !== 1) {
            writer.uint32(72).int32(message.quotingType);
        }
        for (const v of message.quoteMetadata) {
            KeyValue.encode(v!, writer.uint32(82).fork()).ldelim();
        }
        for (const v of message.quoteRequestNotificationAllocGroup) {
            QuoteRequestNotificationAllocGroup.encode(v!, writer.uint32(90).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): QuoteRequestNotification {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuoteRequestNotification();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.requestorSessionKey = reader.string();
                    break;
                case 2:
                    message.requestorSessionToken = reader.string();
                    break;
                case 3:
                    message.quoteRequestId = reader.string();
                    break;
                case 4:
                    message.quoteRequestNotificationGroup.push(
                        QuoteRequestNotificationGroup.decode(reader, reader.uint32())
                    );
                    break;
                case 5:
                    message.text = reader.string();
                    break;
                case 6:
                    message.requestedByUser = reader.string();
                    break;
                case 7:
                    message.orderSource = reader.string();
                    break;
                case 8:
                    message.timestampInUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 9:
                    message.quotingType = reader.int32() as any;
                    break;
                case 10:
                    message.quoteMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 11:
                    message.quoteRequestNotificationAllocGroup.push(
                        QuoteRequestNotificationAllocGroup.decode(reader, reader.uint32())
                    );
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<QuoteRequestNotification>): QuoteRequestNotification {
        const message = createBaseQuoteRequestNotification();
        message.requestorSessionKey = object.requestorSessionKey ?? '';
        message.requestorSessionToken = object.requestorSessionToken ?? '';
        message.quoteRequestId = object.quoteRequestId ?? '';
        message.quoteRequestNotificationGroup =
            object.quoteRequestNotificationGroup?.map((e) => QuoteRequestNotificationGroup.fromPartial(e)) || [];
        message.text = object.text ?? '';
        message.requestedByUser = object.requestedByUser ?? '';
        message.orderSource = object.orderSource ?? '';
        message.timestampInUtcInMillis = object.timestampInUtcInMillis ?? '0';
        message.quotingType = object.quotingType ?? 1;
        message.quoteMetadata = object.quoteMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.quoteRequestNotificationAllocGroup =
            object.quoteRequestNotificationAllocGroup?.map((e) => QuoteRequestNotificationAllocGroup.fromPartial(e)) ||
            [];
        return message;
    }
};

function createBaseQuoteAcknowledgement(): QuoteAcknowledgement {
    return { quoteRequestId: '', quoteId: '', quoteAckStatus: '', quoteRejectReason: '', text: '' };
}

export const QuoteAcknowledgement = {
    encode(message: QuoteAcknowledgement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.quoteRequestId !== '') {
            writer.uint32(10).string(message.quoteRequestId);
        }
        if (message.quoteId !== '') {
            writer.uint32(18).string(message.quoteId);
        }
        if (message.quoteAckStatus !== '') {
            writer.uint32(26).string(message.quoteAckStatus);
        }
        if (message.quoteRejectReason !== '') {
            writer.uint32(34).string(message.quoteRejectReason);
        }
        if (message.text !== '') {
            writer.uint32(42).string(message.text);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): QuoteAcknowledgement {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuoteAcknowledgement();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.quoteRequestId = reader.string();
                    break;
                case 2:
                    message.quoteId = reader.string();
                    break;
                case 3:
                    message.quoteAckStatus = reader.string();
                    break;
                case 4:
                    message.quoteRejectReason = reader.string();
                    break;
                case 5:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<QuoteAcknowledgement>): QuoteAcknowledgement {
        const message = createBaseQuoteAcknowledgement();
        message.quoteRequestId = object.quoteRequestId ?? '';
        message.quoteId = object.quoteId ?? '';
        message.quoteAckStatus = object.quoteAckStatus ?? '';
        message.quoteRejectReason = object.quoteRejectReason ?? '';
        message.text = object.text ?? '';
        return message;
    }
};

function createBaseQuoteRequestNotificationGroup(): QuoteRequestNotificationGroup {
    return {
        assetType: 1,
        productType: 1,
        exchangeCode: '',
        securityCode: '',
        securityId: '',
        settlementDate: '',
        side: 1,
        qty: 0,
        currencyCode: '',
        partyId: '',
        maturityDate: '',
        text: '',
        settlementType: '',
        accountName: '',
        rateSource: ''
    };
}

export const QuoteRequestNotificationGroup = {
    encode(message: QuoteRequestNotificationGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.assetType !== 1) {
            writer.uint32(8).int32(message.assetType);
        }
        if (message.productType !== 1) {
            writer.uint32(16).int32(message.productType);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(34).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(42).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(50).string(message.securityId);
        }
        if (message.settlementDate !== '') {
            writer.uint32(58).string(message.settlementDate);
        }
        if (message.side !== 1) {
            writer.uint32(64).int32(message.side);
        }
        if (message.qty !== 0) {
            writer.uint32(73).double(message.qty);
        }
        if (message.currencyCode !== '') {
            writer.uint32(82).string(message.currencyCode);
        }
        if (message.partyId !== '') {
            writer.uint32(90).string(message.partyId);
        }
        if (message.maturityDate !== '') {
            writer.uint32(98).string(message.maturityDate);
        }
        if (message.text !== '') {
            writer.uint32(106).string(message.text);
        }
        if (message.settlementType !== '') {
            writer.uint32(114).string(message.settlementType);
        }
        if (message.accountName !== '') {
            writer.uint32(122).string(message.accountName);
        }
        if (message.rateSource !== '') {
            writer.uint32(130).string(message.rateSource);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): QuoteRequestNotificationGroup {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuoteRequestNotificationGroup();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.assetType = reader.int32() as any;
                    break;
                case 2:
                    message.productType = reader.int32() as any;
                    break;
                case 4:
                    message.exchangeCode = reader.string();
                    break;
                case 5:
                    message.securityCode = reader.string();
                    break;
                case 6:
                    message.securityId = reader.string();
                    break;
                case 7:
                    message.settlementDate = reader.string();
                    break;
                case 8:
                    message.side = reader.int32() as any;
                    break;
                case 9:
                    message.qty = reader.double();
                    break;
                case 10:
                    message.currencyCode = reader.string();
                    break;
                case 11:
                    message.partyId = reader.string();
                    break;
                case 12:
                    message.maturityDate = reader.string();
                    break;
                case 13:
                    message.text = reader.string();
                    break;
                case 14:
                    message.settlementType = reader.string();
                    break;
                case 15:
                    message.accountName = reader.string();
                    break;
                case 16:
                    message.rateSource = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<QuoteRequestNotificationGroup>): QuoteRequestNotificationGroup {
        const message = createBaseQuoteRequestNotificationGroup();
        message.assetType = object.assetType ?? 1;
        message.productType = object.productType ?? 1;
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.currencyCode = object.currencyCode ?? '';
        message.partyId = object.partyId ?? '';
        message.maturityDate = object.maturityDate ?? '';
        message.text = object.text ?? '';
        message.settlementType = object.settlementType ?? '';
        message.accountName = object.accountName ?? '';
        message.rateSource = object.rateSource ?? '';
        return message;
    }
};

function createBaseQuoteRequestNotificationAllocGroup(): QuoteRequestNotificationAllocGroup {
    return { allocAccount: '', allocQty: 0, allocSettlementDate: '' };
}

export const QuoteRequestNotificationAllocGroup = {
    encode(message: QuoteRequestNotificationAllocGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.allocAccount !== '') {
            writer.uint32(10).string(message.allocAccount);
        }
        if (message.allocQty !== 0) {
            writer.uint32(17).double(message.allocQty);
        }
        if (message.allocSettlementDate !== '') {
            writer.uint32(26).string(message.allocSettlementDate);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): QuoteRequestNotificationAllocGroup {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuoteRequestNotificationAllocGroup();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.allocAccount = reader.string();
                    break;
                case 2:
                    message.allocQty = reader.double();
                    break;
                case 3:
                    message.allocSettlementDate = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<QuoteRequestNotificationAllocGroup>): QuoteRequestNotificationAllocGroup {
        const message = createBaseQuoteRequestNotificationAllocGroup();
        message.allocAccount = object.allocAccount ?? '';
        message.allocQty = object.allocQty ?? 0;
        message.allocSettlementDate = object.allocSettlementDate ?? '';
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
