// import Footer from './Footer';
// import UserMarket from '../UserMarket';
import LiteFooter from './LiteFooter';
import LiteNavbar from './LiteNavbar';

interface LiteLayoutProps extends React.PropsWithChildren {}

export default function LiteLayout({ children }: LiteLayoutProps) {
    return (
        <div className="flex flex-col h-full gap-1">
            <header className="flex justify-center w-full bg-brand-background">
                <LiteNavbar />
            </header>
            <main className="flex flex-1 basis-0 bg-brand-background justify-center overflow-y-auto">{children}</main>
            <LiteFooter />
        </div>
    );
}
