import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { ComponentProps, forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props extends ComponentProps<'input'> {
    min?: number;
    step?: number;
    className?: string;
    defaultValue?: string | number;
}

const NumberInput = forwardRef<HTMLInputElement, Props>((props, ref: any) => {
    const { name = '', min = 0, step = 1, disabled, defaultValue, className, onChange } = props;
    const { getValues, setValue, resetField } = useFormContext();

    const initValue = +(defaultValue || 0);

    return (
        <div className="relative flex">
            <input
                {...props}
                ref={ref}
                onChange={(e) => {
                    const nextValue = BigNumber(e.target.value);
                    const isDirty = !nextValue.isEqualTo(initValue);
                    if (!isDirty) resetField(name);
                    else {
                        if (onChange) onChange(e);
                    }
                }}
                autoComplete="off"
                className={cn(
                    'appearance-none block w-full px-3 py-2 bg-brand-background-dark border border-neutral-700 rounded-md shadow-sm text-sm placeholder-neutral-500 focus:outline-none focus:ring-brand-primary focus:border-brand-primary',
                    { 'text-neutral-400 cursor-not-allowed': disabled },
                    { 'text-neutral-200': !className },
                    className
                )}
            />
            <div className="absolute right-2 flex flex-col h-full justify-center text-xs text-neutral-200 w-5">
                <button
                    type="button"
                    className={cn('hover:text-brand-primary-light px-0', {
                        'text-neutral-400 cursor-not-allowed': disabled
                    })}
                    onClick={() => {
                        if (!disabled) {
                            const prevValue = +(getValues(name) || 0);
                            const nextValue = BigNumber(prevValue).plus(step);
                            const isDirty = !nextValue.isEqualTo(initValue);
                            if (!isDirty) resetField(name);
                            setValue(name, nextValue.toNumber(), { shouldValidate: true, shouldDirty: isDirty });
                        }
                    }}>
                    &#x25B2;
                </button>
                <button
                    type="button"
                    className={cn('hover:text-brand-primary-light px-0', {
                        'text-neutral-400 cursor-not-allowed': disabled
                    })}
                    onClick={() => {
                        if (!disabled) {
                            const prevValue = +(getValues(name) || 0);
                            const nextValue = BigNumber(prevValue).minus(step);
                            if (nextValue.isLessThanOrEqualTo(min)) {
                                const isDirty = min !== initValue;
                                if (!isDirty) resetField(name);
                                setValue(name, min, { shouldValidate: true, shouldDirty: isDirty });
                            } else {
                                const isDirty = !nextValue.isEqualTo(initValue);
                                if (!isDirty) resetField(name);
                                setValue(name, nextValue.toNumber(), { shouldValidate: true, shouldDirty: isDirty });
                            }
                        }
                    }}>
                    &#x25BC;
                </button>
            </div>
        </div>
    );
});

NumberInput.displayName = 'NumberInput';

export default NumberInput;
