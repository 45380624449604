import { ExecutionType } from '@/compiled_proto/com/celertech/orderrouting/api/enums/ExecutionTypeProto';
import { Button } from '@/components/common/Button';
import Divider from '@/components/common/Divider';
import Loader from '@/components/common/Loader';
import { searchBlotter } from '@/services/OrderService';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { selectCredentials } from '@/state/reducers/authSlice';
import { setHistoricTrades } from '@/state/reducers/blotterSlice';
import { getDateFormat } from '@/utils/format';
import { isAfter } from 'date-fns';
import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocale } from 'react-aria';
import { default as DatePicker, default as ReactDatePicker } from 'react-datepicker';
import DatePickerInput from './DatePickerInput';
import ExecutionTypeSelect from './ExecutionTypeSelect';

export interface ExecutionTypeState {
    value: ExecutionType;
    label: string;
}

export interface ExtendedDatePicker extends ReactDatePicker<never, undefined> {
    handleBlur(): void;
    handleCalendarClickOutside(): void;
    state: {
        open: boolean;
    };
}

interface SearchControlsProps {
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    executionType: ExecutionTypeState;
    setExecutiontype: Dispatch<SetStateAction<ExecutionTypeState>>;
    children?: ReactNode;
}

const SearchControls = (props: SearchControlsProps) => {
    const { isLoading, setIsLoading, executionType, setExecutiontype, children } = props;

    const dispatch = useAppDispatch();
    const credentials = useAppSelector(selectCredentials);

    const { locale } = useLocale();
    const dateFormatPattern = getDateFormat(locale);

    const startRef = useRef<ExtendedDatePicker | null>(null);
    const endRef = useRef<ExtendedDatePicker | null>(null);

    const minTime = useMemo(() => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return today;
    }, []);

    const maxTime = useMemo(() => {
        const today = new Date();
        today.setHours(23, 59, 59, 999);
        return today;
    }, []);

    const [startDate, setStartDate] = useState<Date | null>(minTime);
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [error, setError] = useState<{ msg: string }>({ msg: '' });

    const defaultDatePickerProps = useMemo(
        () => ({
            locale: locale.includes('en') ? 'en' : locale,
            showTimeSelect: true,
            timeIntervals: 30,
            minTime,
            maxTime,
            maxDate: new Date()
        }),
        [minTime, maxTime, locale]
    );

    const searchHistory = useCallback(async () => {
        try {
            if (credentials) {
                setIsLoading(true);
                if (!startDate || !endDate) throw Error('Unknown Date');
                if (isAfter(startDate, endDate)) throw Error('To Date Is Before The From Date');
                setError({ msg: '' });
                const resp = await searchBlotter(credentials, ExecutionType.TRADE, startDate, endDate);
                dispatch(setHistoricTrades(resp));
                setIsLoading(false);
            }
        } catch (e) {
            const msg = (e as Error).message;
            setError({ msg });
            setIsLoading(false);
        }
    }, [startDate, endDate, credentials]);

    useEffect(() => {
        if (startRef.current) {
            startRef.current.handleBlur = () => {};
            startRef.current.handleCalendarClickOutside = () => {};
        }
        if (endRef.current) {
            endRef.current.handleBlur = () => {};
            endRef.current.handleCalendarClickOutside = () => {};
        }
    }, [startRef, endRef]);

    return (
        <>
            <div className="flex flex-col p-3 gap-2">
                <div className="relative flex flex-wrap justify-between sm:items-center gap-3">
                    <div className="flex items-center gap-3 flex-row text-neutral-200">
                        {/* Wrapping Datepicker with span to control gap between controls */}
                        <span>
                            <DatePicker
                                ref={startRef}
                                selected={startDate}
                                dateFormat={dateFormatPattern}
                                onCalendarOpen={() => endRef.current?.setOpen(false)}
                                onChange={(date) => {
                                    setStartDate(date);
                                    startRef.current?.setOpen(false);
                                }}
                                customInput={
                                    <DatePickerInput
                                        label="From"
                                        parentRef={startRef}
                                        labelClassName="!text-2xs"
                                        className="h-[34px] pl-3 !rounded-sm !text-xs"
                                        inline
                                    />
                                }
                                disabledKeyboardNavigation
                                {...defaultDatePickerProps}
                            />
                        </span>
                        <span>
                            <DatePicker
                                ref={endRef}
                                selected={endDate}
                                dateFormat={dateFormatPattern}
                                onCalendarOpen={() => startRef.current?.setOpen(false)}
                                onChange={(date) => {
                                    setEndDate(date);
                                    endRef.current?.setOpen(false);
                                }}
                                customInput={
                                    <DatePickerInput
                                        label="To"
                                        parentRef={endRef}
                                        labelClassName="!text-2xs"
                                        className="h-[34px] pl-3 !rounded-sm !text-xs"
                                        inline
                                    />
                                }
                                disabledKeyboardNavigation
                                {...defaultDatePickerProps}
                            />
                        </span>
                        <ExecutionTypeSelect
                            type="execution"
                            label="Execution Type"
                            options={executionTypeOptions}
                            selected={executionType}
                            onChange={(e) => setExecutiontype(e)}
                            enableSorting
                        />
                    </div>
                    <div className="hidden md:flex gap-3 text-neutral-200">
                        <button
                            className="px-4 py-1 rounded-sm text-xs bg-neutral-600 hover:bg-neutral-500"
                            onClick={() => {
                                dispatch(setHistoricTrades(null));
                                setError({ msg: '' });
                            }}>
                            Clear
                        </button>
                        <Button
                            className="rounded-sm text-xs"
                            onClick={searchHistory}
                            isLoading={isLoading}
                            loader={<Loader className="!w-4 !h-4" />}>
                            Search
                        </Button>
                    </div>
                </div>
                {error.msg && <div className="text-brand-red italic text-sm px-2">{error.msg}</div>}
            </div>
            <Divider />
            {children}
            <span className="flex md:hidden">
                <Divider />
            </span>
            <div className="flex md:hidden p-3 gap-3 text-neutral-200">
                <button
                    className="px-4 py-1 rounded-sm text-xs bg-neutral-600 hover:bg-neutral-500"
                    onClick={() => {
                        dispatch(setHistoricTrades(null));
                        setError({ msg: '' });
                    }}>
                    Clear
                </button>
                <Button
                    className="rounded-sm text-xs w-fit"
                    onClick={searchHistory}
                    isLoading={isLoading}
                    loader={<Loader className="!w-4 !h-4" />}>
                    Search
                </Button>
            </div>
        </>
    );
};

export default SearchControls;

export const executionTypeOptions = [
    { label: 'ALL', value: 'All' },
    // { label: 'NEW', value: 1 },
    { label: 'TRADE', value: 2 },
    { label: 'CANCELED', value: 3 },
    { label: 'REPLACED', value: 4 },
    // { label: 'PENDING_CANCEL', value: 5 },
    { label: 'REJECTED', value: 6 },
    // { label: 'PENDING_NEW', value: 7 },
    { label: 'RESTATEMENT', value: 8 },
    { label: 'UNKNOWN', value: 9 },
    // { label: 'PENDING_AMEND', value: 10 },
    { label: 'SUSPENDED', value: 11 },
    { label: 'TRADE_CORRECT', value: 12 },
    { label: 'TRADE_CANCEL', value: 13 },
    { label: 'EXPIRED', value: 15 },
    { label: 'SENDING', value: 17 },
    { label: 'ORDER_STATUS', value: 18 },
    { label: 'DONE_FOR_DAY', value: 19 }
];

// OrderStatus.NEW,
// OrderStatus.PENDING_NEW,
// OrderStatus.PENDING_AMEND,
// OrderStatus.PENDING_CANCEL
