import TraderOrderModal from '@/components/modal/TraderOrderModal';
import WatchListModal from '@/components/modal/WatchListModal';
import ModuleWindow from '@/components/mosaic/ModuleWindow';
import ModuleWindowControls from '@/components/mosaic/ModuleWindowControl';
import instrumentConfig from '@/config/instruments';
import { ModalOperations } from '@/model/common';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { selectMarketPairs } from '@/state/reducers/marketPairSlice';
import {
    TraderMarket,
    addSubscription,
    selectCurrentWatchList,
    selectShouldReinstateWatchlist,
    selectTraderWatchLists,
    setShouldReinstateWatchlist
} from '@/state/reducers/traderMarketSlice';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import useSize from '@/utils/hooks/useSize';
import cn from 'classnames';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { MdHardware } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import DndTickers from './DndTickers';
import InstrumentSelector from './InstrumentSelector';
import WatchListActions from './WatchListActions';
import WatchListSwitch from './WatchListSwitch';

const defaultCcyOption = {
    label: 'Instrument Selector',
    value: undefined,
    market: undefined
};

const TickersModule = (props: any) => {
    const { screen, tradingModule } = props;
    const dispatch = useAppDispatch();
    const pairs = useAppSelector(selectMarketPairs);
    const watchLists = useAppSelector(selectTraderWatchLists);
    const selectedWatchList = useAppSelector(selectCurrentWatchList);
    const shouldReinstateWatchlist = useAppSelector(selectShouldReinstateWatchlist);

    const moduleBodyRef = useRef<HTMLDivElement>(null);
    const size = useSize(moduleBodyRef);

    const orderDisclosure = useDisclosure(false);
    const watchListDisclosure = useDisclosure(false);

    const watchList = useMemo(() => selectedWatchList || watchLists[0], [watchLists, selectedWatchList]);
    const addedInstruments = useMemo(() => watchList.markets.map((market) => market.celer), [watchList.markets]);

    const [showControls, setShowControls] = useState<boolean>(true);
    const [modalMode, setModalMode] = useState<ModalOperations>('Create');
    const [dndItems, setDndItems] = useState<{ id: number; market: TraderMarket }[]>(
        watchList.markets.map((market) => ({ id: uuidv4(), market }))
    );

    const instrumentOptions = useMemo(() => {
        const options = pairs.map((instrument) => ({
            label: instrument.show,
            value: instrument.celer,
            market: instrument,
            config: instrumentConfig[instrument.celer],
            added: addedInstruments.includes(instrument.celer)
        }));

        options.sort((a, b) => (a.added === b.added ? 0 : a.added ? -1 : 1));

        return options;
    }, [watchList.markets, addedInstruments, pairs]);

    const { shouldFullWidth } = useMemo(() => {
        if (!size) return { shouldFullWidth: false };
        else if (size.width < 345) return { shouldFullWidth: true };
        return { shouldFullWidth: false };
    }, [size]);

    useEffect(() => {
        // should reinstate watchlist
        if (shouldReinstateWatchlist) {
            const { mode, market } = shouldReinstateWatchlist;
            if (mode === 'add') setDndItems([...dndItems, { id: uuidv4(), market }]);
            else if (mode === 'remove') setDndItems(dndItems.filter((i) => i.market.celer !== market.celer));
        }
        dispatch(setShouldReinstateWatchlist(false));
    }, [shouldReinstateWatchlist]);

    useEffect(() => {
        // always define shouldReinstateWatchlist as false onMount
        dispatch(setShouldReinstateWatchlist(false));
    }, []);

    return (
        <Fragment>
            <ModuleWindow
                {...props}
                renderToolbar={() => (
                    <div className="flex justify-between items-center w-full h-full border-b border-b-neutral-700 text-neutral-200">
                        <span className="p-2 font-semibold">{tradingModule.title}</span>
                        <div className="flex h-full items-center">
                            <div
                                className="relative cursor-pointer flex py-1 pl-3 pr-8 text-xs leading-5 text-neutral-200 hover:bg-brand-primary bg-brand-background-dark border-x border-neutral-700 hover:border-neutral-600"
                                onClick={() => setShowControls(!showControls)}>
                                <span className="text-xs leading-5">{showControls ? 'Collapse' : 'Expand'} Tools</span>
                                <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                                    <MdHardware className="h-4 w-4 rotate-45" />
                                </div>
                            </div>
                            <ModuleWindowControls screen={screen} />
                        </div>
                    </div>
                )}>
                <div ref={moduleBodyRef} className="h-full flex flex-col bg-brand-background">
                    <div
                        className={cn(
                            'flex flex-wrap gap-2 justify-between items-center text-neutral-200 border-neutral-700',
                            {
                                hidden: !showControls,
                                'p-2 border-b': showControls
                            }
                        )}>
                        <div className="flex flex-wrap-reverse gap-2">
                            <WatchListSwitch parentSize={size} setItems={setDndItems} />
                            <div
                                className={cn('flex items-center gap-2', {
                                    'w-full': shouldFullWidth
                                })}>
                                <div
                                    title="Add New Watchlist"
                                    className="flex whitespace-nowrap cursor-pointer shadow-md bg-brand-background-dark border border-neutral-700 hover:border-neutral-600 h-full px-2 py-1"
                                    onClick={() => {
                                        setModalMode('Create');
                                        watchListDisclosure[1].toggle();
                                    }}>
                                    <span className="text-xs leading-5">New Watchlist</span>
                                </div>
                                <WatchListActions
                                    parentSize={size}
                                    onEdit={() => {
                                        setModalMode('Edit');
                                        watchListDisclosure[1].toggle();
                                    }}
                                    onDelete={() => {
                                        setModalMode('Delete');
                                        watchListDisclosure[1].toggle();
                                    }}
                                    deleteDisabled={watchLists.length === 1}
                                />
                            </div>
                        </div>
                        <InstrumentSelector
                            parentSize={size}
                            watchListId={watchList.id}
                            selected={defaultCcyOption}
                            options={instrumentOptions}
                            onSubmit={(market) => {
                                dispatch(addSubscription({ id: watchList.id, market }));
                                setDndItems((items) => [...items, { id: uuidv4(), market }]);
                            }}
                            onRemove={(market) => setDndItems(dndItems.filter((i) => i.market.celer !== market.celer))}
                        />
                    </div>
                    <div className="flex-1 basis-0 overflow-auto">
                        {dndItems.length !== 0 && (
                            <DndTickers
                                items={dndItems}
                                setItems={setDndItems}
                                orderDisclosure={orderDisclosure}
                                watchList={watchList}
                            />
                        )}
                        {dndItems.length === 0 && (
                            <div className="flex justify-center items-center h-full text-neutral-400 px-2 text-sm text-center">
                                <span>Start adding tickers with the Instrument Selector</span>
                            </div>
                        )}
                    </div>
                </div>
            </ModuleWindow>
            <TraderOrderModal opened={orderDisclosure[0]} handlers={orderDisclosure[1]} />
            <WatchListModal
                mode={modalMode}
                opened={watchListDisclosure[0]}
                handlers={watchListDisclosure[1]}
                setItems={setDndItems}
            />
        </Fragment>
    );
};

export default TickersModule;
