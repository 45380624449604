import { InferType, object, string } from 'yup';
import { Nullable } from '../../../model/common';
import { PriceSchemaParams, QuantitySchemaParams, priceSchema, quantitySchema } from './common';

interface AmendOrderSchemaParams extends PriceSchemaParams, QuantitySchemaParams {}

export const amendOrderSchema = (params: AmendOrderSchemaParams) => {
    return object({
        pair: string(),
        side: string(),
        ccy: string(),
        orderType: string(),
        orderId: string(),
        status: string(),
        duration: string(),
        quantity: quantitySchema(params),
        price: priceSchema(params)
    });
};

export type AmendOrderFormValues = InferType<ReturnType<typeof amendOrderSchema>>;
export type AmendOrderFormInput = Nullable<AmendOrderFormValues>;
