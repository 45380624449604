import { useAppSelector } from '@/state/hooks';
import { selectGeneralSettings } from '@/state/reducers/settingSlice';
import { useDateFormatter } from 'react-aria';

export type UseTrueDateFormatterReturn = ReturnType<typeof useDateFormatter>;

export const useTrueDateFormatter = (): UseTrueDateFormatterReturn => {
    const generalSettings = useAppSelector(selectGeneralSettings);
    const dateFormatter = useDateFormatter(generalSettings.timezone.value === 'UTC' ? { timeZone: 'UTC' } : undefined);
    return dateFormatter;
};
