import Divider from '@/components/common/Divider';
import { OpenPositionRow } from '@/components/trader-modules/Blotters/OpenPositionTable';
import useOrderExecutionTrader from '@/utils/hooks/useOrderExecutionTrader';
import { Tab } from '@headlessui/react';
import Modal, { ModalClose, ModalHeader, ModalProps, ModalTab, ModalTabList, ModalTitle } from '../Modal';
import LimitOrderForm from './LimitOrderForm';
import MarketOrderForm from './MarketOrderForm';
import OcoOrderForm from './OcoOrderForm';
import StopOrderForm from './StopOrderForm';

const tabs = ['Market', 'Limit', 'Stop', 'OCO'];

interface TraderOrderModalProps extends ModalProps {
    prefillValues?: OpenPositionRow;
}

export default function TraderOrderModal(props: TraderOrderModalProps) {
    const { handlers, prefillValues } = props;
    const orderExecutionProps = useOrderExecutionTrader();

    return (
        <Modal {...props} size="max-w-lg">
            <div className="h-full flex flex-col lg:block lg:h-auto">
                <ModalHeader>
                    <ModalTitle>Order Placement</ModalTitle>
                    <ModalClose handlers={handlers} />
                </ModalHeader>
                <Divider />
                <div className="flex flex-col flex-1 basis-0 h-full lg:h-auto lg:block w-full text-neutral-200">
                    <Tab.Group>
                        <ModalTabList>
                            {tabs.map((tab) => (
                                <ModalTab key={tab}>{tab}</ModalTab>
                            ))}
                        </ModalTabList>
                        <Tab.Panels className="flex flex-1 basis-0 overflow-hidden">
                            <Tab.Panel className="flex-1 overflow-y-auto">
                                <MarketOrderForm
                                    {...orderExecutionProps}
                                    prefillValues={prefillValues}
                                    onClose={handlers.close}
                                    closeOnSubmit
                                />
                            </Tab.Panel>
                            <Tab.Panel className="flex-1 overflow-y-auto">
                                <LimitOrderForm {...orderExecutionProps} onClose={handlers.close} closeOnSubmit />
                            </Tab.Panel>
                            <Tab.Panel className="flex-1 overflow-y-auto">
                                <StopOrderForm {...orderExecutionProps} onClose={handlers.close} closeOnSubmit />
                            </Tab.Panel>
                            <Tab.Panel className="flex-1 overflow-y-auto">
                                <OcoOrderForm {...orderExecutionProps} onClose={handlers.close} closeOnSubmit />
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
        </Modal>
    );
}

{
    /* <div className="flex flex-col h-full">
    <Tab.Group>
        <Tab.List className="flex bg-brand-background border-b-[1px] border-b-neutral-700">
            {tabs.map((tab) => (
                <Tab
                    key={tab}
                    className={({ selected }) =>
                        cn('p-1 px-2 font-medium leading-5 text-sm text-neutral-200 focus:outline-none', {
                            'border-b-[1px] bg-brand-background-dark border-b-brand-border': selected,
                            'text-neutral-400 hover:bg-white/[0.12] hover:text-neutral-200': !selected
                        })
                    }>
                    {tab}
                </Tab>
            ))}
        </Tab.List>
        <Tab.Panel className="flex-1 overflow-y-auto">
            <MarketOrderForm />
        </Tab.Panel>
        <Tab.Panel className="flex-1 overflow-y-auto">
            <LimitOrderForm />
        </Tab.Panel>
        <Tab.Panel className="flex-1 overflow-y-auto">
            <StopOrderForm />
        </Tab.Panel>
    </Tab.Group>
</div> */
}
