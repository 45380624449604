import { Combobox, Transition } from '@headlessui/react';
import cn from 'classnames';
import { Fragment, forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { MdUnfoldMore } from 'react-icons/md';

interface Option {
    label: string;
    value: any;
}

interface FilterSelectProps {
    options: Option[];
    selected?: Option;
    enableSorting?: boolean;
    disabled?: boolean;
    placeholder?: string;
    onChange?: (e: Option) => void;
}

const FilterSelect = forwardRef((props: FilterSelectProps, ref) => {
    const {
        options,
        selected,
        enableSorting = false,
        disabled = false,
        placeholder = 'Select an option',
        onChange
    } = props;

    const buttonRef = useRef<any>();
    const inputRef = useRef<any>();
    const [query, setQuery] = useState<any>('');
    const [inputFocused, setInputFocused] = useState<boolean>(false);

    const onClickOutside = useCallback(() => {
        if (buttonRef.current) {
            if (inputFocused) (buttonRef.current as any).click();
        }
    }, [inputFocused, buttonRef.current]);

    const sortedOptions = useMemo(() => {
        return enableSorting ? options.sort((a, b) => a.label?.localeCompare(b.label)) : options;
    }, [options, enableSorting]);

    const filteredOption = useMemo(() => {
        return query === ''
            ? sortedOptions
            : sortedOptions.filter((option) =>
                  option.label
                      .toLowerCase()
                      .replace(/\s+|\//g, '')
                      .includes(query.toLowerCase().replace(/\s+|\//g, ''))
              );
    }, [sortedOptions, query]);

    return (
        <Combobox value={selected} onChange={onChange}>
            <div className="relative">
                <div className="relative w-full cursor-default overflow-hidden rounded-md bg-brand-background-dark border border-neutral-700 text-left shadow-md sm:text-sm">
                    <Combobox.Button className="w-full">
                        <Fragment>
                            <Combobox.Input
                                ref={inputRef}
                                className={cn(
                                    'w-full border-none py-2.5 pl-3 pr-10 text-sm leading-5 bg-brand-background-dark outline-none',
                                    {
                                        ['text-neutral-200 placeholder:text-neutral-200']: !disabled,
                                        ['text-neutral-400 placeholder:text-neutral-400']: disabled
                                    }
                                )}
                                placeholder={selected?.label || placeholder}
                                onChange={(event) => {
                                    setQuery(event.target.value);
                                    setInputFocused(true);
                                }}
                                onBlur={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        if (filteredOption && filteredOption.length > 0) {
                                            onChange?.(filteredOption[0]);
                                            onClickOutside();
                                            if (inputRef.current) inputRef.current.value = '';
                                        }
                                    }
                                }}
                            />
                            {!disabled && (
                                <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                                    <MdUnfoldMore className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                            )}
                        </Fragment>
                    </Combobox.Button>
                </div>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}>
                    <Combobox.Options className="absolute z-[60] mt-2 max-h-60 w-full overflow-auto rounded-md bg-brand-background-dark border border-neutral-700 py-1 text-sm shadow-lg shadow-neutral-900">
                        {filteredOption.length === 0 && query !== '' ? (
                            <div className="relative cursor-default select-none py-2 px-4 text-neutral-200">
                                Nothing found.
                            </div>
                        ) : (
                            filteredOption.map((option, optionIdx) => (
                                <Combobox.Option
                                    key={optionIdx}
                                    className={({ active }) =>
                                        cn(`relative cursor-pointer select-none py-2 pl-3 pr-4`, {
                                            'bg-brand-primary': active,
                                            'text-brand-primary-light': selected?.value === option.value,
                                            'text-neutral-200': active && selected?.value === option.value
                                        })
                                    }
                                    value={option}>
                                    <span className={cn(`block truncate`)}>{option?.label}</span>
                                </Combobox.Option>
                            ))
                        )}
                    </Combobox.Options>
                </Transition>
            </div>
        </Combobox>
    );
});

FilterSelect.displayName = 'FilterSelect';

export default FilterSelect;
