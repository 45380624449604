// export const parseBool = (value: string): boolean => JSON.parse(value.toLowerCase());

export const ERROR_RETRY_INTERVAL = window.config.swr?.errorRetryInterval || 5000;
export const ERROR_RETRY_COUNT = window.config.swr?.errorRetryCount || 3;
export const LOADING_TIMEOUT = window.config.swr?.loadingTimeout || 3000;
export const FOCUS_THROTTLE_INTERVAL = window.config.swr?.focusThrottleInterval || 5000;
export const DEDUPING_INTERVAL = window.config.swr?.dedupingInterval || 2000;
export const REFRESH_INTERVAL = window.config.swr?.refreshInterval || 0;
export const REFRESH_WHEN_HIDDEN = window.config.swr?.refreshWhenHidden || false;
export const REFRESH_WHEN_OFFLINE = window.config.swr?.refreshWhenOffline || false;
export const REVALIDATE_ON_FOCUS = window.config.swr?.revalidateOnFocus || true;
export const REVALIDATE_ON_RECONNECT = window.config.swr?.revalidateOnReconnect || true;
export const REVALIDATE_ON_MOUNT = window.config.swr?.revalidateOnMount || true;
export const REVALIDATE_IF_STALE = window.config.swr?.revalidateIfStale || true;
export const SHOULD_RETRY_ON_ERROR = window.config.swr?.shouldRetryOnError || true;

export const SWROptions = {
    errorRetryInterval: ERROR_RETRY_INTERVAL,
    errorRetryCount: ERROR_RETRY_COUNT,
    loadingTimeout: LOADING_TIMEOUT,
    focusThrottleInterval: FOCUS_THROTTLE_INTERVAL,
    dedupingInterval: DEDUPING_INTERVAL,
    refreshInterval: REFRESH_INTERVAL,
    refreshWhenHidden: REFRESH_WHEN_HIDDEN,
    refreshWhenOffline: REFRESH_WHEN_OFFLINE,
    revalidateOnFocus: REVALIDATE_ON_FOCUS,
    revalidateOnReconnect: REVALIDATE_ON_RECONNECT,
    revalidateOnMount: REVALIDATE_ON_MOUNT,
    revalidateIfStale: REVALIDATE_IF_STALE,
    shouldRetryOnError: SHOULD_RETRY_ON_ERROR
};
