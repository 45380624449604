import { grpc } from '@improbable-eng/grpc-web';
import { createChannel, createClient, Metadata } from 'nice-grpc-web';
import { Middleware } from 'redux';
import { AppDispatch, RootState } from '../store';

import {
    OrderRoutingOrderEvent,
    OrderServiceClient,
    OrderServiceDefinition
} from '@/compiled_proto/com/celertech/orderrouting/api/notification/OrderServiceProto';
import { forEachAsync } from '@/helpers/asyncIterableHelper';
import { controlClearSubscriptions, controlInitSubscriptions, User } from '../reducers/authSlice';
import { processOrderEvent } from '../reducers/blotterSlice';

import { ExecutionType } from '@/compiled_proto/com/celertech/orderrouting/api/enums/ExecutionTypeProto';
import { toastExecutionType } from '@/helpers/executionTypeHelper';
import { toastOrderRejectedStatus, toastOrderStatus } from '@/helpers/orderStatusHelper';
import { BlotterItem } from '@/model/blotters';
import { parseNotificationSettings } from '@/utils/hooks/useToast';
import { Logger } from '@/utils/logger';
import { ToastOptions } from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';

const parseOrderUpdate = (
    previousEvents: BlotterItem[],
    orderUpdate: OrderRoutingOrderEvent,
    toastConfig: ToastOptions
) => {
    const { fxOrderSnapshotDownstreamEvent, createOrderRequestRejectDownstreamEvent } = orderUpdate;
    Logger({
        title: 'Inbound: OrderStatusMiddleware event',
        callback: () => {
            console.log({ fxOrderSnapshotDownstreamEvent, createOrderRequestRejectDownstreamEvent });
        }
    });
    if (fxOrderSnapshotDownstreamEvent) {
        const { executionType } = fxOrderSnapshotDownstreamEvent;
        if (executionType === ExecutionType.REPLACED) {
            // TODO: Handle more types, as of now it only handles Order Amendment
            toastExecutionType(previousEvents, fxOrderSnapshotDownstreamEvent, toastConfig);
        } else {
            // TODO: Distinguish order type
            // TODO: Type absolute quantity, write out buy or sell
            toastOrderStatus(fxOrderSnapshotDownstreamEvent, toastConfig);
        }
    } else if (createOrderRequestRejectDownstreamEvent) {
        toastOrderRejectedStatus(createOrderRequestRejectDownstreamEvent, toastConfig);
    }
};

let subscriptionId: any = null;
let orderStatusSubscription: any = null;

const setupOrderStatusSubscription = async (credentials: User, dispatch: AppDispatch, store: any) => {
    orderStatusSubscription = orderServiceClientWs.updateOnAllFxOrderSnapshots(
        {},
        {
            metadata: Metadata({
                'authorization-token': credentials.authToken
            })
        }
    );
    // Create a dictionary to store the previous event information for each order

    const currentSubscriptionId = uuidv4();
    subscriptionId = currentSubscriptionId;
    forEachAsync(
        orderStatusSubscription,
        (orderUpdate: OrderRoutingOrderEvent) => {
            const state: RootState = store.getState();
            const previousEvents: BlotterItem[] = state.blotter.blotterOrders;

            parseOrderUpdate(previousEvents, orderUpdate, parseNotificationSettings(state.settings.notifications));

            // Update blotter
            if (
                orderUpdate.fxOrderSnapshotDownstreamEvent &&
                !orderUpdate.createOrderRequestRejectDownstreamEvent?.rejectReason
            ) {
                dispatch(processOrderEvent(orderUpdate.fxOrderSnapshotDownstreamEvent));
            }
        },
        () => subscriptionId !== currentSubscriptionId,
        `OrderStatusMiddleware`
    ).catch((error) => console.error({ 'OrderStatusMiddleware subscription': error }));
};

const wsChannelUrl = window.config.integration.celertech.websocket;
const wsChannel = createChannel(wsChannelUrl, grpc.WebsocketTransport());

// Subscribe to order updates
// wss://gce-uat-lon-webtrader-fx.celer-tech.com/com.celertech.orderrouting.api.order.OrderService/updateOnAllFxOrderSnapshots
const orderServiceClientWs: OrderServiceClient = createClient(OrderServiceDefinition, wsChannel);

const OrderStatusMiddleware: Middleware = (store) => (next) => (action) => {
    if (controlClearSubscriptions.match(action)) {
        Logger({ title: `OrderStatusMiddleware: Clear Subscriptions`, callback: () => {} });
        subscriptionId = null;
        orderStatusSubscription = null;
    } else if (controlInitSubscriptions.match(action)) {
        Logger({ title: `OrderStatusMiddleware: Initialise Subscriptions`, callback: () => {} });
        const dispatch: AppDispatch = store.dispatch;
        const state: RootState = store.getState();
        const creds = state.auth.user;
        // If not already listening, setup subscriptions
        if (!orderStatusSubscription && creds) {
            setupOrderStatusSubscription(creds, dispatch, store);
        }
    }

    // Pass on to next middlewares in line
    next(action);
};

export default OrderStatusMiddleware;
