import InstrumentInfo from '@/components/common/InstrumentInfo';
import { OpenPositionRow } from '@/components/trader-modules/Blotters/OpenPositionTable';
import { Highlights } from '@/components/trader-modules/MarketList/UserMarket';
import { getCurrency } from '@/utils/hooks/useCurrency';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import { GetColorIndexesReturn, useInstrument } from '@/utils/hooks/useInstrument';
import usePreviousState from '@/utils/hooks/usePreviousState';
import { CellContext } from '@tanstack/react-table';
import cn from 'classnames';
import { useMemo, useState } from 'react';
import { MdChevronRight, MdClose } from 'react-icons/md';

interface OpenPositionColumnsParams {
    onOrderModal: (row: OpenPositionRow) => void;
    formatQty: Intl.NumberFormat;
}

export const openPositionColumns = (params: OpenPositionColumnsParams) => {
    const { onOrderModal, formatQty } = params;

    return [
        {
            accessorKey: 'tableInstrument',
            header: ({ table }) => {
                return (
                    <div className="flex space-x-1 items-center truncate">
                        <button
                            onClick={(e) => {
                                table.toggleAllRowsExpanded();
                                e.stopPropagation();
                            }}
                            title={table.getIsAllRowsExpanded() ? 'Collapse all rows' : 'Expand all rows'}
                            className="relative transform scale-125 left-0">
                            <MdChevronRight
                                className={cn('h-3.5 w-3.5 font-bold text-primary duration-200', {
                                    ['rotate-90 ']: table.getIsAllRowsExpanded()
                                })}
                                aria-hidden
                            />
                        </button>

                        <span className="truncate">Instrument</span>
                    </div>
                );
            },
            cell: ({ row, getValue }: CellContext<OpenPositionRow, any>) => {
                return (
                    <div
                        className="flex items-center gap-1"
                        style={{
                            paddingLeft: `${row.depth * 1.5}rem`
                        }}>
                        <div className={`flex space-x-1`}>
                            {row.getCanExpand() && (
                                <button title={row.getIsExpanded() ? 'Collapse current row' : 'Expand current row'}>
                                    <MdChevronRight
                                        className={cn('h-3.5 w-3.5 text-primary duration-200', {
                                            ['rotate-90']: row.getIsExpanded()
                                        })}
                                        aria-hidden
                                    />
                                </button>
                            )}

                            <span>{getValue()}</span>
                        </div>
                        {row.getCanExpand() && <InstrumentInfo market={row.original.market} />}
                    </div>
                );
            }
        },
        { accessorKey: 'type', header: 'Type' },
        {
            accessorKey: 'netQtyPosition',
            header: 'Net Position',
            cell: ({ row, getValue }: CellContext<OpenPositionRow, any>) => {
                const value = getValue();

                return (
                    <div
                        className={cn('text-sm', {
                            'text-brand-primary': value > 0,
                            'text-brand-red': value < 0
                        })}>
                        {isNaN(value) ? '-' : formatQty.format(value)}
                    </div>
                );
            }
        },
        {
            size: 50,
            minSize: 50,
            accessorKey: 'action',
            header: 'Close',
            cell: ({ row, getValue }: CellContext<OpenPositionRow, any>) => {
                const original = row.original;
                return (
                    <div
                        className={cn('flex justify-center items-center mr-1.5', {
                            'bg-brand-red hover:bg-brand-red-dark': original.netQtyPosition !== 0,
                            'bg-neutral-600 cursor-not-allowed': original.netQtyPosition === 0
                        })}
                        onClick={(e) => {
                            if (original.netQtyPosition !== 0) onOrderModal(original);
                            e.stopPropagation();
                        }}>
                        <MdClose className="w-4 h-4" />
                    </div>
                );
            }
        },
        {
            accessorKey: 'openPrice',
            header: 'Open Price',
            cell: ({ row, getValue }: CellContext<OpenPositionRow, any>) => {
                const value = getValue();

                return <div className="text-sm">{isNaN(value) ? '-' : value}</div>;
            }
        },
        {
            accessorKey: 'currentPrice',
            header: 'Current Price',
            cell: ({ row, getValue }: CellContext<OpenPositionRow, any>) => {
                const value = getValue();

                const prevMid = usePreviousState(value);
                const { formatPip, formatHighlights } = useInstrument(row.original.instrument);
                const formattedMid = useMemo(() => value && formatPip(value), [value]);
                const [midHighlights, setMidHighlights] = useState<GetColorIndexesReturn | undefined>();

                useDidUpdate(() => {
                    if (value && prevMid) setMidHighlights(formatHighlights(prevMid, value, midHighlights));
                }, [value]);

                return (
                    <div className="text-sm">
                        {isNaN(value) ? '-' : <Highlights ticker={formattedMid} highlights={midHighlights} />}
                    </div>
                );
            }
        },
        {
            accessorKey: 'unrealisedPnl',
            header: 'Unrealised P/L',
            width: 150,
            cell: ({ row, getValue }: CellContext<OpenPositionRow, any>) => {
                const { value, currency } = getValue();
                const { show, order_decimals } = getCurrency(currency);

                const number = new Intl.NumberFormat(formatQty.resolvedOptions().locale, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: Math.max(0, order_decimals - 2)
                });

                return (
                    <div
                        className={cn('text-sm', {
                            'text-brand-primary': value > 0,
                            'text-brand-red': value < 0
                        })}>
                        {isNaN(value) ? '-' : ` ${show} ${number.format(value)}`}
                    </div>
                );
            }
        },
        {
            accessorKey: 'realisedPnl',
            header: 'Realised P/L',
            width: 150,
            cell: ({ row, getValue }: CellContext<OpenPositionRow, any>) => {
                const { value, currency } = getValue();
                const { show, order_decimals } = getCurrency(currency);

                const number = new Intl.NumberFormat(formatQty.resolvedOptions().locale, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: Math.max(0, order_decimals - 2)
                });

                return (
                    <div
                        className={cn('text-sm', {
                            'text-brand-primary': value > 0,
                            'text-brand-red': value < 0
                        })}>
                        {isNaN(value) ? '-' : ` ${show} ${number.format(value)}`}
                    </div>
                );
            }
        },
        { accessorKey: 'settlementDate', header: 'Value Date' },
        { accessorKey: 'accountCode', header: 'Account' }
    ];
};
