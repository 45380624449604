/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { AccountType } from '../enums/AccountTypeProto';
import { ContingencyType } from '../enums/ContingencyTypeProto';
import { ExecutionInstruction } from '../enums/ExecutionInstructionProto';
import { HandlingInstruction } from '../enums/HandlingInstructionProto';
import { OptionType } from '../enums/OptionTypeProto';
import { OrderType } from '../enums/OrderTypeProto';
import { PartyIdSource } from '../enums/PartyIdSourceProto';
import { PartyRole } from '../enums/PartyRoleProto';
import { PegPriceType } from '../enums/PegPriceTypeProto';
import { ProductType } from '../enums/ProductTypeProto';
import { Side } from '../enums/SideProto';
import { StrategyType } from '../enums/StrategyTypeProto';
import { TimeInForceType } from '../enums/TimeInForceTypeProto';
import { OrderIdData } from './OrderIds';
import { KeyValue } from './OrderMetadata';

export const protobufPackage = 'com.celertech.orderrouting.api.order';

export enum MigrationRequestSource {
    CLIENT = 0,
    SERVER = 1,
    UNRECOGNIZED = -1
}

export interface CreateFutureOrderRequest {
    clientRequestId: string;
    clientOrderId: string;
    exchangeIdSource: string;
    exchange: string;
    securityIdSource: string;
    securityCode: string;
    securityId: string;
    expiry: string;
    accountType: AccountType;
    account: string;
    side: Side;
    qty: number;
    price: number;
    orderType: OrderType;
    timeInForce: TimeInForceType;
    timeInForceExpiry: string;
    orderText: string;
    giveIn: string;
    giveUp: string;
    strategyType: StrategyType;
    algoType: string;
    leg: CreateFutureStrategyLegOrder[];
    handlingInstruction: HandlingInstruction;
    onBehalfOfCompId: string;
    deliverToCompId: string;
    currency: string;
    minQty: number;
    maxFloor: number;
    quoteId: string;
    destinationKey: string;
    bookAsFilled: boolean;
    parentOrderId: string;
    commission: number;
    checkpointTimestamp: string[];
    orderAllocGroup: CreateOrderAllocGroup[];
    orderPartiesGroup: CreateOrderPartiesGroup[];
    orderMetadata: KeyValue[];
    onBehalfOfUsername: string;
    stopPrice: number;
    productType: ProductType;
    /** List of LPs to execute the trade on */
    includedLPs: string[];
    /** List of LPs to not execute the trade on */
    excludedLPs: string[];
}

export interface CreateFutureStrategyLegOrder {
    underlyingCode: string;
    underlyingSecurityId: string;
    underlyingExpiry: string;
    side: Side;
    ratioQty: number;
    /** Add settlementType(i.e. tenor) for Future asset type. e.g. 3M forward, 3rd Wed date base metals. */
    settlementType: string;
}

export interface CreateOptionOrderRequest {
    clientRequestId: string;
    clientOrderId: string;
    exchangeIdSource: string;
    exchange: string;
    securityIdSource: string;
    securityCode: string;
    securityId: string;
    expiry: string;
    optionType: OptionType;
    strike: number;
    account: string;
    side: Side;
    qty: number;
    price: number;
    orderType: OrderType;
    timeInForce: TimeInForceType;
    timeInForceExpiry: string;
    orderText: string;
    accountType: AccountType;
    giveIn: string;
    giveUp: string;
    handlingInstruction: HandlingInstruction;
    onBehalfOfCompId: string;
    deliverToCompId: string;
    currency: string;
    minQty: number;
    maxFloor: number;
    quoteId: string;
    destinationKey: string;
    bookAsFilled: boolean;
    parentOrderId: string;
    algoType: string;
    commission: number;
    checkpointTimestamp: string[];
    orderAllocGroup: CreateOrderAllocGroup[];
    orderPartiesGroup: CreateOrderPartiesGroup[];
    orderMetadata: KeyValue[];
    onBehalfOfUsername: string;
    stopPrice: number;
    productType: ProductType;
}

export interface CreateFxOrderRequest {
    clientRequestId: string;
    clientOrderId: string;
    exchangeIdSource: string;
    exchange: string;
    securityIdSource: string;
    securityCode: string;
    securityId: string;
    accountType: AccountType;
    account: string;
    side: Side;
    qty: number;
    price: number;
    currency: string;
    orderType: OrderType;
    timeInForce: TimeInForceType;
    timeInForceExpiry: string;
    orderText: string;
    giveIn: string;
    giveUp: string;
    handlingInstruction: HandlingInstruction;
    onBehalfOfCompId: string;
    deliverToCompId: string;
    minQty: number;
    maxFloor: number;
    quoteId: string;
    destinationKey: string;
    bookAsFilled: boolean;
    parentOrderId: string;
    commission: number;
    productType: ProductType;
    algoType: string;
    leg: CreateFxStrategyLegOrder[];
    checkpointTimestamp: string[];
    orderAllocGroup: CreateOrderAllocGroup[];
    orderPartiesGroup: CreateOrderPartiesGroup[];
    orderMetadata: KeyValue[];
    onBehalfOfUsername: string;
    fixingSource: string;
    stopPrice: number;
    /** list of LPs to execute the trade on */
    includedLPs: string[];
    /** list of LPs to not execute the trade on */
    excludedLPs: string[];
    maxShow: number;
    /** Type of pegged order */
    pegPriceType: PegPriceType;
}

export interface CreateCryptoOrderRequest {
    clientRequestId: string;
    clientOrderId: string;
    exchangeIdSource: string;
    exchange: string;
    securityIdSource: string;
    securityCode: string;
    securityId: string;
    accountType: AccountType;
    account: string;
    side: Side;
    qty: number;
    price: number;
    currency: string;
    orderType: OrderType;
    timeInForce: TimeInForceType;
    timeInForceExpiry: string;
    orderText: string;
    giveIn: string;
    giveUp: string;
    handlingInstruction: HandlingInstruction;
    onBehalfOfCompId: string;
    deliverToCompId: string;
    minQty: number;
    maxFloor: number;
    quoteId: string;
    destinationKey: string;
    bookAsFilled: boolean;
    parentOrderId: string;
    commission: number;
    productType: ProductType;
    algoType: string;
    leg: CreateCryptoStrategyLegOrder[];
    checkpointTimestamp: string[];
    orderAllocGroup: CreateOrderAllocGroup[];
    orderPartiesGroup: CreateOrderPartiesGroup[];
    orderMetadata: KeyValue[];
    onBehalfOfUsername: string;
    fixingSource: string;
    stopPrice: number;
}

export interface CreateCryptoStrategyLegOrder {
    underlyingCode: string;
    underlyingSecurityId: string;
    legValueDate: string;
    side: Side;
    qty: number;
    price: number;
    legForwardPts: number;
    spotPrice: number;
    settlementType: string;
    /**
     * Maturity of the quote; used for quote requests related to FIXED_INCOME
     * Fixing date for NON_DELIVERABLE_FORWARD
     */
    maturityDate: string;
}

export interface CreateFixedIncomeOrderRequest {
    clientRequestId: string;
    clientOrderId: string;
    exchangeIdSource: string;
    exchange: string;
    securityIdSource: string;
    securityCode: string;
    securityId: string;
    settlementDate: string;
    accountType: AccountType;
    account: string;
    side: Side;
    qty: number;
    price: number;
    currency: string;
    orderType: OrderType;
    timeInForce: TimeInForceType;
    timeInForceExpiry: string;
    orderText: string;
    giveIn: string;
    giveUp: string;
    handlingInstruction: HandlingInstruction;
    onBehalfOfCompId: string;
    deliverToCompId: string;
    minQty: number;
    maxFloor: number;
    quoteId: string;
    destinationKey: string;
    bookAsFilled: boolean;
    parentOrderId: string;
    commission: number;
    maturityDate: string;
    productType: ProductType;
    algoType: string;
    checkpointTimestamp: string[];
    orderAllocGroup: CreateOrderAllocGroup[];
    orderPartiesGroup: CreateOrderPartiesGroup[];
    settlementType: string;
    orderMetadata: KeyValue[];
    onBehalfOfUsername: string;
    stopPrice: number;
}

export interface CreateFxStrategyLegOrder {
    underlyingCode: string;
    underlyingSecurityId: string;
    legValueDate: string;
    side: Side;
    qty: number;
    price: number;
    legForwardPts: number;
    spotPrice: number;
    settlementType: string;
    /**
     * Maturity of the quote; used for quote requests related to FIXED_INCOME
     * Fixing date for NON_DELIVERABLE_FORWARD
     */
    maturityDate: string;
}

export interface CreateEquityOrderRequest {
    clientRequestId: string;
    clientOrderId: string;
    exchangeIdSource: string;
    exchange: string;
    securityIdSource: string;
    securityCode: string;
    securityId: string;
    accountType: AccountType;
    account: string;
    side: Side;
    qty: number;
    price: number;
    currency: string;
    orderType: OrderType;
    timeInForce: TimeInForceType;
    timeInForceExpiry: string;
    orderText: string;
    giveIn: string;
    giveUp: string;
    handlingInstruction: HandlingInstruction;
    onBehalfOfCompId: string;
    deliverToCompId: string;
    minQty: number;
    maxFloor: number;
    executionInstruction: ExecutionInstruction;
    quoteId: string;
    destinationKey: string;
    bookAsFilled: boolean;
    parentOrderId: string;
    algoType: string;
    checkpointTimestamp: string[];
    orderAllocGroup: CreateOrderAllocGroup[];
    orderPartiesGroup: CreateOrderPartiesGroup[];
    orderMetadata: KeyValue[];
    onBehalfOfUsername: string;
    stopPrice: number;
    productType: ProductType;
    expiry: string;
}

export interface CreateOrderAllocGroup {
    allocAccount: string;
    allocQty: number;
    allocSide: Side;
    allocSettlementDate: string;
    allocPrice: number;
    linkingId: number;
}

export interface CreateOrderPartiesGroup {
    partyId: string;
    partyIdSource: PartyIdSource;
    partyRole: PartyRole;
}

export interface CancelOrderRequest {
    clientRequestId: string;
    orderId: string;
    clOrdId: string;
    origClOrdId: string;
    onBehalfOfCompId: string;
    deliverToCompId: string;
    checkpointTimestamp: string[];
    onBehalfOfUsername: string;
    parentOrderId: string;
}

export interface AmendOrderRequest {
    clientRequestId: string;
    orderId: string;
    clOrdId: string;
    origClOrdId: string;
    account: string;
    qty: number;
    price: number;
    orderText: string;
    giveIn: string;
    giveUp: string;
    onBehalfOfCompId: string;
    deliverToCompId: string;
    quoteId: string;
    checkpointTimestamp: string[];
    stopPrice: number;
    orderMetadata: KeyValue[];
    timeInForceExpiry: string;
    executionInstruction: ExecutionInstruction;
    minQty: number;
    maxShow: number;
    commission: number;
    instructionsMessage: string;
}

export interface FindAllAssignedOrderSnapshots {
    clientRequestId: string;
}

export interface FindAllOrderSnapshotsByClientOrderId {
    clientRequestId: string;
    clOrderId: string;
}

export interface FindAllOrderSnapshotsByOrderId {
    clientRequestId: string;
    orderId: string;
}

export interface AcceptManagedOrderRequest {
    clientRequestId: string;
    orderId: string;
}

export interface ManagedOrderAmendmentReview {
    clientRequestId: string;
    orderId: string;
    reviewAnswer: ManagedOrderAmendmentReview_ReviewAnswer;
    text: string;
}

export enum ManagedOrderAmendmentReview_ReviewAnswer {
    APPROVE = 0,
    REJECT = 1,
    UNRECOGNIZED = -1
}

export interface OrderRoutingDestinationCommand {
    clientRequestId: string;
    orderRoutingDestinationKey: string;
    operation: OrderRoutingDestinationCommand_Operation;
    incomingSeqNo: number;
    outgoingSeqNo: number;
}

export enum OrderRoutingDestinationCommand_Operation {
    START = 0,
    STOP = 1,
    EOD = 2,
    RESET_SEQ = 3,
    UNRECOGNIZED = -1
}

/** Submit this request given a filled order along with updates to the account and commission */
export interface PostTradeAmendRequest {
    clientRequestId: string;
    id: string;
    orderId: string;
    account: string;
    commission: number;
    reason: string;
    trader: string;
}

/** Used when instructions are required post trade for downstream system, things like Account Codes to debit or credit */
export interface PostTradeAmendInstructionRequest {
    clientRequestId: string;
    execId: string;
    orderId: string;
    instruction: string;
    orderMetadata: KeyValue[];
}

export interface CreateFxListOrder {
    clientRequestId: string;
    listID: string;
    contingencyType: ContingencyType;
    strategyOrders: CreateFxOrderRequest[];
}

export interface CreateFxSspOrder {
    clientRequestId: string;
    listID: string;
    netSpotOrder?: CreateFxOrderRequest;
    subOrders: CreateFxOrderRequest[];
}

export interface CancelTradeRequest {
    clientRequestId: string;
    id: string;
    orderId: string;
}

export interface OrderStatusRequest {
    clientRequestId: string;
    clOrderId: string;
    orderId: string;
}

/** Submit this request given filled orders for aggregating into a single ticket with this account */
export interface PostTradeAggregationRequest {
    clientRequestId: string;
    clientReportId: string;
    orderIdData: OrderIdData[];
    account: string;
    commission: number;
    reason: string;
}

/** Submit this request to receive strategy order snapshot notification */
export interface UpdateOnOrderSnapshotRequest {
    clientRequestId: string;
}

/** Submit this request to find all active strategy orders */
export interface FindAllStrategyOrderSnapshots {
    clientRequestId: string;
}

/** Submit this request to receive notifications of order migration events */
export interface UpdateOnOrderMigrationRequest {
    clientRequestId: string;
}

/** Submit this request to receive notifications of order gateway events */
export interface UpdateOnOrderMigrationGatewayRequest {
    clientRequestId: string;
}

/** Request the status of the order gateway */
export interface OrderMigrationGatewayStatusRequest {
    clientRequestId: string;
}

/** Submit a request to change the gateway active status */
export interface OrderMigrationGatewayUpstreamEvent {
    clientRequestId: string;
    active: boolean;
}

/**
 * Submits a request to route orders to a destination.  The outbound destination is the target destination.
 * To migrate orders back, the outbound destination will be the CLOB
 * clients will request to migrate/reinstate orders by id
 */
export interface PassiveOrderMigrationRequest {
    clientRequestId: string;
    outboundDestination: string;
    orderId: string[];
    migrationRequestSource: MigrationRequestSource;
}

/** Submit this request to find all migrated orders */
export interface FindAllMigratedOrderSnapshots {
    clientRequestId: string;
}

/** Submit this request to receive notifications of BAF order snapshot */
export interface UpdateOnBafOrderSnapshotRequest {
    clientRequestId: string;
}

/** there would be only client orders for BAF */
export interface FindAllBafOrderSnapshots {
    clientRequestId: string;
}

/**
 * Submits a request to force fill an order using the orderID, this is the child leg of an order
 * so in the case of a stop it is the single order, in the case of an OCO it is either one of the legs
 */
export interface ForceFillOrderRequest {
    clientRequestId: string;
    orderId: string;
    priceToFillAt: number;
}

/** Request that will allow for force cancelling of orders */
export interface ForceCancelOrderRequest {
    clientRequestId: string;
    orderId: string;
}

function createBaseCreateFutureOrderRequest(): CreateFutureOrderRequest {
    return {
        clientRequestId: '',
        clientOrderId: '',
        exchangeIdSource: '',
        exchange: '',
        securityIdSource: '',
        securityCode: '',
        securityId: '',
        expiry: '',
        accountType: 1,
        account: '',
        side: 1,
        qty: 0,
        price: 0,
        orderType: 1,
        timeInForce: 1,
        timeInForceExpiry: '',
        orderText: '',
        giveIn: '',
        giveUp: '',
        strategyType: 1,
        algoType: '',
        leg: [],
        handlingInstruction: 1,
        onBehalfOfCompId: '',
        deliverToCompId: '',
        currency: '',
        minQty: 0,
        maxFloor: 0,
        quoteId: '',
        destinationKey: '',
        bookAsFilled: false,
        parentOrderId: '0',
        commission: 0,
        checkpointTimestamp: [],
        orderAllocGroup: [],
        orderPartiesGroup: [],
        orderMetadata: [],
        onBehalfOfUsername: '',
        stopPrice: 0,
        productType: 1,
        includedLPs: [],
        excludedLPs: []
    };
}

export const CreateFutureOrderRequest = {
    encode(message: CreateFutureOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.clientOrderId !== '') {
            writer.uint32(18).string(message.clientOrderId);
        }
        if (message.exchangeIdSource !== '') {
            writer.uint32(26).string(message.exchangeIdSource);
        }
        if (message.exchange !== '') {
            writer.uint32(34).string(message.exchange);
        }
        if (message.securityIdSource !== '') {
            writer.uint32(42).string(message.securityIdSource);
        }
        if (message.securityCode !== '') {
            writer.uint32(50).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(58).string(message.securityId);
        }
        if (message.expiry !== '') {
            writer.uint32(66).string(message.expiry);
        }
        writer.uint32(72).int32(message.accountType);
        if (message.account !== '') {
            writer.uint32(82).string(message.account);
        }
        writer.uint32(88).int32(message.side);
        if (message.qty !== 0) {
            writer.uint32(97).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(105).double(message.price);
        }
        writer.uint32(112).int32(message.orderType);
        writer.uint32(120).int32(message.timeInForce);
        if (message.timeInForceExpiry !== '') {
            writer.uint32(130).string(message.timeInForceExpiry);
        }
        if (message.orderText !== '') {
            writer.uint32(138).string(message.orderText);
        }
        if (message.giveIn !== '') {
            writer.uint32(146).string(message.giveIn);
        }
        if (message.giveUp !== '') {
            writer.uint32(154).string(message.giveUp);
        }
        writer.uint32(160).int32(message.strategyType);
        if (message.algoType !== '') {
            writer.uint32(170).string(message.algoType);
        }
        for (const v of message.leg) {
            CreateFutureStrategyLegOrder.encode(v!, writer.uint32(178).fork()).ldelim();
        }
        writer.uint32(184).int32(message.handlingInstruction);
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(194).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(202).string(message.deliverToCompId);
        }
        if (message.currency !== '') {
            writer.uint32(210).string(message.currency);
        }
        if (message.minQty !== 0) {
            writer.uint32(217).double(message.minQty);
        }
        if (message.maxFloor !== 0) {
            writer.uint32(225).double(message.maxFloor);
        }
        if (message.quoteId !== '') {
            writer.uint32(234).string(message.quoteId);
        }
        if (message.destinationKey !== '') {
            writer.uint32(242).string(message.destinationKey);
        }
        if (message.bookAsFilled === true) {
            writer.uint32(248).bool(message.bookAsFilled);
        }
        if (message.parentOrderId !== '0') {
            writer.uint32(256).int64(message.parentOrderId);
        }
        if (message.commission !== 0) {
            writer.uint32(265).double(message.commission);
        }
        writer.uint32(274).fork();
        for (const v of message.checkpointTimestamp) {
            writer.int64(v);
        }
        writer.ldelim();
        for (const v of message.orderAllocGroup) {
            CreateOrderAllocGroup.encode(v!, writer.uint32(282).fork()).ldelim();
        }
        for (const v of message.orderPartiesGroup) {
            CreateOrderPartiesGroup.encode(v!, writer.uint32(290).fork()).ldelim();
        }
        for (const v of message.orderMetadata) {
            KeyValue.encode(v!, writer.uint32(298).fork()).ldelim();
        }
        if (message.onBehalfOfUsername !== '') {
            writer.uint32(306).string(message.onBehalfOfUsername);
        }
        if (message.stopPrice !== 0) {
            writer.uint32(313).double(message.stopPrice);
        }
        writer.uint32(320).int32(message.productType);
        for (const v of message.includedLPs) {
            writer.uint32(330).string(v!);
        }
        for (const v of message.excludedLPs) {
            writer.uint32(338).string(v!);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateFutureOrderRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateFutureOrderRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.clientOrderId = reader.string();
                    break;
                case 3:
                    message.exchangeIdSource = reader.string();
                    break;
                case 4:
                    message.exchange = reader.string();
                    break;
                case 5:
                    message.securityIdSource = reader.string();
                    break;
                case 6:
                    message.securityCode = reader.string();
                    break;
                case 7:
                    message.securityId = reader.string();
                    break;
                case 8:
                    message.expiry = reader.string();
                    break;
                case 9:
                    message.accountType = reader.int32() as any;
                    break;
                case 10:
                    message.account = reader.string();
                    break;
                case 11:
                    message.side = reader.int32() as any;
                    break;
                case 12:
                    message.qty = reader.double();
                    break;
                case 13:
                    message.price = reader.double();
                    break;
                case 14:
                    message.orderType = reader.int32() as any;
                    break;
                case 15:
                    message.timeInForce = reader.int32() as any;
                    break;
                case 16:
                    message.timeInForceExpiry = reader.string();
                    break;
                case 17:
                    message.orderText = reader.string();
                    break;
                case 18:
                    message.giveIn = reader.string();
                    break;
                case 19:
                    message.giveUp = reader.string();
                    break;
                case 20:
                    message.strategyType = reader.int32() as any;
                    break;
                case 21:
                    message.algoType = reader.string();
                    break;
                case 22:
                    message.leg.push(CreateFutureStrategyLegOrder.decode(reader, reader.uint32()));
                    break;
                case 23:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 24:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 25:
                    message.deliverToCompId = reader.string();
                    break;
                case 26:
                    message.currency = reader.string();
                    break;
                case 27:
                    message.minQty = reader.double();
                    break;
                case 28:
                    message.maxFloor = reader.double();
                    break;
                case 29:
                    message.quoteId = reader.string();
                    break;
                case 30:
                    message.destinationKey = reader.string();
                    break;
                case 31:
                    message.bookAsFilled = reader.bool();
                    break;
                case 32:
                    message.parentOrderId = longToString(reader.int64() as Long);
                    break;
                case 33:
                    message.commission = reader.double();
                    break;
                case 34:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                        }
                    } else {
                        message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                    }
                    break;
                case 35:
                    message.orderAllocGroup.push(CreateOrderAllocGroup.decode(reader, reader.uint32()));
                    break;
                case 36:
                    message.orderPartiesGroup.push(CreateOrderPartiesGroup.decode(reader, reader.uint32()));
                    break;
                case 37:
                    message.orderMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 38:
                    message.onBehalfOfUsername = reader.string();
                    break;
                case 39:
                    message.stopPrice = reader.double();
                    break;
                case 40:
                    message.productType = reader.int32() as any;
                    break;
                case 41:
                    message.includedLPs.push(reader.string());
                    break;
                case 42:
                    message.excludedLPs.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateFutureOrderRequest>): CreateFutureOrderRequest {
        const message = createBaseCreateFutureOrderRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.clientOrderId = object.clientOrderId ?? '';
        message.exchangeIdSource = object.exchangeIdSource ?? '';
        message.exchange = object.exchange ?? '';
        message.securityIdSource = object.securityIdSource ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.expiry = object.expiry ?? '';
        message.accountType = object.accountType ?? 1;
        message.account = object.account ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.orderType = object.orderType ?? 1;
        message.timeInForce = object.timeInForce ?? 1;
        message.timeInForceExpiry = object.timeInForceExpiry ?? '';
        message.orderText = object.orderText ?? '';
        message.giveIn = object.giveIn ?? '';
        message.giveUp = object.giveUp ?? '';
        message.strategyType = object.strategyType ?? 1;
        message.algoType = object.algoType ?? '';
        message.leg = object.leg?.map((e) => CreateFutureStrategyLegOrder.fromPartial(e)) || [];
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.currency = object.currency ?? '';
        message.minQty = object.minQty ?? 0;
        message.maxFloor = object.maxFloor ?? 0;
        message.quoteId = object.quoteId ?? '';
        message.destinationKey = object.destinationKey ?? '';
        message.bookAsFilled = object.bookAsFilled ?? false;
        message.parentOrderId = object.parentOrderId ?? '0';
        message.commission = object.commission ?? 0;
        message.checkpointTimestamp = object.checkpointTimestamp?.map((e) => e) || [];
        message.orderAllocGroup = object.orderAllocGroup?.map((e) => CreateOrderAllocGroup.fromPartial(e)) || [];
        message.orderPartiesGroup = object.orderPartiesGroup?.map((e) => CreateOrderPartiesGroup.fromPartial(e)) || [];
        message.orderMetadata = object.orderMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.onBehalfOfUsername = object.onBehalfOfUsername ?? '';
        message.stopPrice = object.stopPrice ?? 0;
        message.productType = object.productType ?? 1;
        message.includedLPs = object.includedLPs?.map((e) => e) || [];
        message.excludedLPs = object.excludedLPs?.map((e) => e) || [];
        return message;
    }
};

function createBaseCreateFutureStrategyLegOrder(): CreateFutureStrategyLegOrder {
    return {
        underlyingCode: '',
        underlyingSecurityId: '',
        underlyingExpiry: '',
        side: 1,
        ratioQty: 0,
        settlementType: ''
    };
}

export const CreateFutureStrategyLegOrder = {
    encode(message: CreateFutureStrategyLegOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.underlyingCode !== '') {
            writer.uint32(10).string(message.underlyingCode);
        }
        if (message.underlyingSecurityId !== '') {
            writer.uint32(18).string(message.underlyingSecurityId);
        }
        if (message.underlyingExpiry !== '') {
            writer.uint32(26).string(message.underlyingExpiry);
        }
        writer.uint32(32).int32(message.side);
        if (message.ratioQty !== 0) {
            writer.uint32(41).double(message.ratioQty);
        }
        if (message.settlementType !== '') {
            writer.uint32(50).string(message.settlementType);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateFutureStrategyLegOrder {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateFutureStrategyLegOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.underlyingCode = reader.string();
                    break;
                case 2:
                    message.underlyingSecurityId = reader.string();
                    break;
                case 3:
                    message.underlyingExpiry = reader.string();
                    break;
                case 4:
                    message.side = reader.int32() as any;
                    break;
                case 5:
                    message.ratioQty = reader.double();
                    break;
                case 6:
                    message.settlementType = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateFutureStrategyLegOrder>): CreateFutureStrategyLegOrder {
        const message = createBaseCreateFutureStrategyLegOrder();
        message.underlyingCode = object.underlyingCode ?? '';
        message.underlyingSecurityId = object.underlyingSecurityId ?? '';
        message.underlyingExpiry = object.underlyingExpiry ?? '';
        message.side = object.side ?? 1;
        message.ratioQty = object.ratioQty ?? 0;
        message.settlementType = object.settlementType ?? '';
        return message;
    }
};

function createBaseCreateOptionOrderRequest(): CreateOptionOrderRequest {
    return {
        clientRequestId: '',
        clientOrderId: '',
        exchangeIdSource: '',
        exchange: '',
        securityIdSource: '',
        securityCode: '',
        securityId: '',
        expiry: '',
        optionType: 1,
        strike: 0,
        account: '',
        side: 1,
        qty: 0,
        price: 0,
        orderType: 1,
        timeInForce: 1,
        timeInForceExpiry: '',
        orderText: '',
        accountType: 1,
        giveIn: '',
        giveUp: '',
        handlingInstruction: 1,
        onBehalfOfCompId: '',
        deliverToCompId: '',
        currency: '',
        minQty: 0,
        maxFloor: 0,
        quoteId: '',
        destinationKey: '',
        bookAsFilled: false,
        parentOrderId: '0',
        algoType: '',
        commission: 0,
        checkpointTimestamp: [],
        orderAllocGroup: [],
        orderPartiesGroup: [],
        orderMetadata: [],
        onBehalfOfUsername: '',
        stopPrice: 0,
        productType: 1
    };
}

export const CreateOptionOrderRequest = {
    encode(message: CreateOptionOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.clientOrderId !== '') {
            writer.uint32(18).string(message.clientOrderId);
        }
        if (message.exchangeIdSource !== '') {
            writer.uint32(26).string(message.exchangeIdSource);
        }
        if (message.exchange !== '') {
            writer.uint32(34).string(message.exchange);
        }
        if (message.securityIdSource !== '') {
            writer.uint32(42).string(message.securityIdSource);
        }
        if (message.securityCode !== '') {
            writer.uint32(50).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(58).string(message.securityId);
        }
        if (message.expiry !== '') {
            writer.uint32(66).string(message.expiry);
        }
        writer.uint32(72).int32(message.optionType);
        if (message.strike !== 0) {
            writer.uint32(81).double(message.strike);
        }
        if (message.account !== '') {
            writer.uint32(90).string(message.account);
        }
        writer.uint32(96).int32(message.side);
        if (message.qty !== 0) {
            writer.uint32(105).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(113).double(message.price);
        }
        writer.uint32(120).int32(message.orderType);
        writer.uint32(128).int32(message.timeInForce);
        if (message.timeInForceExpiry !== '') {
            writer.uint32(138).string(message.timeInForceExpiry);
        }
        if (message.orderText !== '') {
            writer.uint32(146).string(message.orderText);
        }
        writer.uint32(152).int32(message.accountType);
        if (message.giveIn !== '') {
            writer.uint32(162).string(message.giveIn);
        }
        if (message.giveUp !== '') {
            writer.uint32(170).string(message.giveUp);
        }
        writer.uint32(176).int32(message.handlingInstruction);
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(186).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(194).string(message.deliverToCompId);
        }
        if (message.currency !== '') {
            writer.uint32(202).string(message.currency);
        }
        if (message.minQty !== 0) {
            writer.uint32(209).double(message.minQty);
        }
        if (message.maxFloor !== 0) {
            writer.uint32(217).double(message.maxFloor);
        }
        if (message.quoteId !== '') {
            writer.uint32(226).string(message.quoteId);
        }
        if (message.destinationKey !== '') {
            writer.uint32(234).string(message.destinationKey);
        }
        if (message.bookAsFilled === true) {
            writer.uint32(240).bool(message.bookAsFilled);
        }
        if (message.parentOrderId !== '0') {
            writer.uint32(248).int64(message.parentOrderId);
        }
        if (message.algoType !== '') {
            writer.uint32(258).string(message.algoType);
        }
        if (message.commission !== 0) {
            writer.uint32(265).double(message.commission);
        }
        writer.uint32(274).fork();
        for (const v of message.checkpointTimestamp) {
            writer.int64(v);
        }
        writer.ldelim();
        for (const v of message.orderAllocGroup) {
            CreateOrderAllocGroup.encode(v!, writer.uint32(282).fork()).ldelim();
        }
        for (const v of message.orderPartiesGroup) {
            CreateOrderPartiesGroup.encode(v!, writer.uint32(290).fork()).ldelim();
        }
        for (const v of message.orderMetadata) {
            KeyValue.encode(v!, writer.uint32(298).fork()).ldelim();
        }
        if (message.onBehalfOfUsername !== '') {
            writer.uint32(306).string(message.onBehalfOfUsername);
        }
        if (message.stopPrice !== 0) {
            writer.uint32(313).double(message.stopPrice);
        }
        writer.uint32(320).int32(message.productType);
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateOptionOrderRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateOptionOrderRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.clientOrderId = reader.string();
                    break;
                case 3:
                    message.exchangeIdSource = reader.string();
                    break;
                case 4:
                    message.exchange = reader.string();
                    break;
                case 5:
                    message.securityIdSource = reader.string();
                    break;
                case 6:
                    message.securityCode = reader.string();
                    break;
                case 7:
                    message.securityId = reader.string();
                    break;
                case 8:
                    message.expiry = reader.string();
                    break;
                case 9:
                    message.optionType = reader.int32() as any;
                    break;
                case 10:
                    message.strike = reader.double();
                    break;
                case 11:
                    message.account = reader.string();
                    break;
                case 12:
                    message.side = reader.int32() as any;
                    break;
                case 13:
                    message.qty = reader.double();
                    break;
                case 14:
                    message.price = reader.double();
                    break;
                case 15:
                    message.orderType = reader.int32() as any;
                    break;
                case 16:
                    message.timeInForce = reader.int32() as any;
                    break;
                case 17:
                    message.timeInForceExpiry = reader.string();
                    break;
                case 18:
                    message.orderText = reader.string();
                    break;
                case 19:
                    message.accountType = reader.int32() as any;
                    break;
                case 20:
                    message.giveIn = reader.string();
                    break;
                case 21:
                    message.giveUp = reader.string();
                    break;
                case 22:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 23:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 24:
                    message.deliverToCompId = reader.string();
                    break;
                case 25:
                    message.currency = reader.string();
                    break;
                case 26:
                    message.minQty = reader.double();
                    break;
                case 27:
                    message.maxFloor = reader.double();
                    break;
                case 28:
                    message.quoteId = reader.string();
                    break;
                case 29:
                    message.destinationKey = reader.string();
                    break;
                case 30:
                    message.bookAsFilled = reader.bool();
                    break;
                case 31:
                    message.parentOrderId = longToString(reader.int64() as Long);
                    break;
                case 32:
                    message.algoType = reader.string();
                    break;
                case 33:
                    message.commission = reader.double();
                    break;
                case 34:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                        }
                    } else {
                        message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                    }
                    break;
                case 35:
                    message.orderAllocGroup.push(CreateOrderAllocGroup.decode(reader, reader.uint32()));
                    break;
                case 36:
                    message.orderPartiesGroup.push(CreateOrderPartiesGroup.decode(reader, reader.uint32()));
                    break;
                case 37:
                    message.orderMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 38:
                    message.onBehalfOfUsername = reader.string();
                    break;
                case 39:
                    message.stopPrice = reader.double();
                    break;
                case 40:
                    message.productType = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateOptionOrderRequest>): CreateOptionOrderRequest {
        const message = createBaseCreateOptionOrderRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.clientOrderId = object.clientOrderId ?? '';
        message.exchangeIdSource = object.exchangeIdSource ?? '';
        message.exchange = object.exchange ?? '';
        message.securityIdSource = object.securityIdSource ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.expiry = object.expiry ?? '';
        message.optionType = object.optionType ?? 1;
        message.strike = object.strike ?? 0;
        message.account = object.account ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.orderType = object.orderType ?? 1;
        message.timeInForce = object.timeInForce ?? 1;
        message.timeInForceExpiry = object.timeInForceExpiry ?? '';
        message.orderText = object.orderText ?? '';
        message.accountType = object.accountType ?? 1;
        message.giveIn = object.giveIn ?? '';
        message.giveUp = object.giveUp ?? '';
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.currency = object.currency ?? '';
        message.minQty = object.minQty ?? 0;
        message.maxFloor = object.maxFloor ?? 0;
        message.quoteId = object.quoteId ?? '';
        message.destinationKey = object.destinationKey ?? '';
        message.bookAsFilled = object.bookAsFilled ?? false;
        message.parentOrderId = object.parentOrderId ?? '0';
        message.algoType = object.algoType ?? '';
        message.commission = object.commission ?? 0;
        message.checkpointTimestamp = object.checkpointTimestamp?.map((e) => e) || [];
        message.orderAllocGroup = object.orderAllocGroup?.map((e) => CreateOrderAllocGroup.fromPartial(e)) || [];
        message.orderPartiesGroup = object.orderPartiesGroup?.map((e) => CreateOrderPartiesGroup.fromPartial(e)) || [];
        message.orderMetadata = object.orderMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.onBehalfOfUsername = object.onBehalfOfUsername ?? '';
        message.stopPrice = object.stopPrice ?? 0;
        message.productType = object.productType ?? 1;
        return message;
    }
};

function createBaseCreateFxOrderRequest(): CreateFxOrderRequest {
    return {
        clientRequestId: '',
        clientOrderId: '',
        exchangeIdSource: '',
        exchange: '',
        securityIdSource: '',
        securityCode: '',
        securityId: '',
        accountType: 1,
        account: '',
        side: 1,
        qty: 0,
        price: 0,
        currency: '',
        orderType: 1,
        timeInForce: 1,
        timeInForceExpiry: '',
        orderText: '',
        giveIn: '',
        giveUp: '',
        handlingInstruction: 1,
        onBehalfOfCompId: '',
        deliverToCompId: '',
        minQty: 0,
        maxFloor: 0,
        quoteId: '',
        destinationKey: '',
        bookAsFilled: false,
        parentOrderId: '0',
        commission: 0,
        productType: 1,
        algoType: '',
        leg: [],
        checkpointTimestamp: [],
        orderAllocGroup: [],
        orderPartiesGroup: [],
        orderMetadata: [],
        onBehalfOfUsername: '',
        fixingSource: '',
        stopPrice: 0,
        includedLPs: [],
        excludedLPs: [],
        maxShow: 0,
        pegPriceType: 1
    };
}

export const CreateFxOrderRequest = {
    encode(message: CreateFxOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.clientOrderId !== '') {
            writer.uint32(18).string(message.clientOrderId);
        }
        if (message.exchangeIdSource !== '') {
            writer.uint32(26).string(message.exchangeIdSource);
        }
        if (message.exchange !== '') {
            writer.uint32(34).string(message.exchange);
        }
        if (message.securityIdSource !== '') {
            writer.uint32(42).string(message.securityIdSource);
        }
        if (message.securityCode !== '') {
            writer.uint32(50).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(58).string(message.securityId);
        }
        writer.uint32(72).int32(message.accountType);
        if (message.account !== '') {
            writer.uint32(82).string(message.account);
        }
        writer.uint32(88).int32(message.side);
        if (message.qty !== 0) {
            writer.uint32(97).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(105).double(message.price);
        }
        if (message.currency !== '') {
            writer.uint32(114).string(message.currency);
        }
        writer.uint32(120).int32(message.orderType);
        writer.uint32(128).int32(message.timeInForce);
        if (message.timeInForceExpiry !== '') {
            writer.uint32(138).string(message.timeInForceExpiry);
        }
        if (message.orderText !== '') {
            writer.uint32(146).string(message.orderText);
        }
        if (message.giveIn !== '') {
            writer.uint32(154).string(message.giveIn);
        }
        if (message.giveUp !== '') {
            writer.uint32(162).string(message.giveUp);
        }
        writer.uint32(168).int32(message.handlingInstruction);
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(178).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(186).string(message.deliverToCompId);
        }
        if (message.minQty !== 0) {
            writer.uint32(193).double(message.minQty);
        }
        if (message.maxFloor !== 0) {
            writer.uint32(209).double(message.maxFloor);
        }
        if (message.quoteId !== '') {
            writer.uint32(218).string(message.quoteId);
        }
        if (message.destinationKey !== '') {
            writer.uint32(226).string(message.destinationKey);
        }
        if (message.bookAsFilled === true) {
            writer.uint32(232).bool(message.bookAsFilled);
        }
        if (message.parentOrderId !== '0') {
            writer.uint32(240).int64(message.parentOrderId);
        }
        if (message.commission !== 0) {
            writer.uint32(249).double(message.commission);
        }
        writer.uint32(256).int32(message.productType);
        if (message.algoType !== '') {
            writer.uint32(266).string(message.algoType);
        }
        for (const v of message.leg) {
            CreateFxStrategyLegOrder.encode(v!, writer.uint32(274).fork()).ldelim();
        }
        writer.uint32(282).fork();
        for (const v of message.checkpointTimestamp) {
            writer.int64(v);
        }
        writer.ldelim();
        for (const v of message.orderAllocGroup) {
            CreateOrderAllocGroup.encode(v!, writer.uint32(290).fork()).ldelim();
        }
        for (const v of message.orderPartiesGroup) {
            CreateOrderPartiesGroup.encode(v!, writer.uint32(298).fork()).ldelim();
        }
        for (const v of message.orderMetadata) {
            KeyValue.encode(v!, writer.uint32(306).fork()).ldelim();
        }
        if (message.onBehalfOfUsername !== '') {
            writer.uint32(314).string(message.onBehalfOfUsername);
        }
        if (message.fixingSource !== '') {
            writer.uint32(322).string(message.fixingSource);
        }
        if (message.stopPrice !== 0) {
            writer.uint32(329).double(message.stopPrice);
        }
        for (const v of message.includedLPs) {
            writer.uint32(338).string(v!);
        }
        for (const v of message.excludedLPs) {
            writer.uint32(346).string(v!);
        }
        if (message.maxShow !== 0) {
            writer.uint32(353).double(message.maxShow);
        }
        writer.uint32(360).int32(message.pegPriceType);
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateFxOrderRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateFxOrderRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.clientOrderId = reader.string();
                    break;
                case 3:
                    message.exchangeIdSource = reader.string();
                    break;
                case 4:
                    message.exchange = reader.string();
                    break;
                case 5:
                    message.securityIdSource = reader.string();
                    break;
                case 6:
                    message.securityCode = reader.string();
                    break;
                case 7:
                    message.securityId = reader.string();
                    break;
                case 9:
                    message.accountType = reader.int32() as any;
                    break;
                case 10:
                    message.account = reader.string();
                    break;
                case 11:
                    message.side = reader.int32() as any;
                    break;
                case 12:
                    message.qty = reader.double();
                    break;
                case 13:
                    message.price = reader.double();
                    break;
                case 14:
                    message.currency = reader.string();
                    break;
                case 15:
                    message.orderType = reader.int32() as any;
                    break;
                case 16:
                    message.timeInForce = reader.int32() as any;
                    break;
                case 17:
                    message.timeInForceExpiry = reader.string();
                    break;
                case 18:
                    message.orderText = reader.string();
                    break;
                case 19:
                    message.giveIn = reader.string();
                    break;
                case 20:
                    message.giveUp = reader.string();
                    break;
                case 21:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 22:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 23:
                    message.deliverToCompId = reader.string();
                    break;
                case 24:
                    message.minQty = reader.double();
                    break;
                case 26:
                    message.maxFloor = reader.double();
                    break;
                case 27:
                    message.quoteId = reader.string();
                    break;
                case 28:
                    message.destinationKey = reader.string();
                    break;
                case 29:
                    message.bookAsFilled = reader.bool();
                    break;
                case 30:
                    message.parentOrderId = longToString(reader.int64() as Long);
                    break;
                case 31:
                    message.commission = reader.double();
                    break;
                case 32:
                    message.productType = reader.int32() as any;
                    break;
                case 33:
                    message.algoType = reader.string();
                    break;
                case 34:
                    message.leg.push(CreateFxStrategyLegOrder.decode(reader, reader.uint32()));
                    break;
                case 35:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                        }
                    } else {
                        message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                    }
                    break;
                case 36:
                    message.orderAllocGroup.push(CreateOrderAllocGroup.decode(reader, reader.uint32()));
                    break;
                case 37:
                    message.orderPartiesGroup.push(CreateOrderPartiesGroup.decode(reader, reader.uint32()));
                    break;
                case 38:
                    message.orderMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 39:
                    message.onBehalfOfUsername = reader.string();
                    break;
                case 40:
                    message.fixingSource = reader.string();
                    break;
                case 41:
                    message.stopPrice = reader.double();
                    break;
                case 42:
                    message.includedLPs.push(reader.string());
                    break;
                case 43:
                    message.excludedLPs.push(reader.string());
                    break;
                case 44:
                    message.maxShow = reader.double();
                    break;
                case 45:
                    message.pegPriceType = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateFxOrderRequest>): CreateFxOrderRequest {
        const message = createBaseCreateFxOrderRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.clientOrderId = object.clientOrderId ?? '';
        message.exchangeIdSource = object.exchangeIdSource ?? '';
        message.exchange = object.exchange ?? '';
        message.securityIdSource = object.securityIdSource ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.accountType = object.accountType ?? 1;
        message.account = object.account ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.currency = object.currency ?? '';
        message.orderType = object.orderType ?? 1;
        message.timeInForce = object.timeInForce ?? 1;
        message.timeInForceExpiry = object.timeInForceExpiry ?? '';
        message.orderText = object.orderText ?? '';
        message.giveIn = object.giveIn ?? '';
        message.giveUp = object.giveUp ?? '';
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.minQty = object.minQty ?? 0;
        message.maxFloor = object.maxFloor ?? 0;
        message.quoteId = object.quoteId ?? '';
        message.destinationKey = object.destinationKey ?? '';
        message.bookAsFilled = object.bookAsFilled ?? false;
        message.parentOrderId = object.parentOrderId ?? '0';
        message.commission = object.commission ?? 0;
        message.productType = object.productType ?? 1;
        message.algoType = object.algoType ?? '';
        message.leg = object.leg?.map((e) => CreateFxStrategyLegOrder.fromPartial(e)) || [];
        message.checkpointTimestamp = object.checkpointTimestamp?.map((e) => e) || [];
        message.orderAllocGroup = object.orderAllocGroup?.map((e) => CreateOrderAllocGroup.fromPartial(e)) || [];
        message.orderPartiesGroup = object.orderPartiesGroup?.map((e) => CreateOrderPartiesGroup.fromPartial(e)) || [];
        message.orderMetadata = object.orderMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.onBehalfOfUsername = object.onBehalfOfUsername ?? '';
        message.fixingSource = object.fixingSource ?? '';
        message.stopPrice = object.stopPrice ?? 0;
        message.includedLPs = object.includedLPs?.map((e) => e) || [];
        message.excludedLPs = object.excludedLPs?.map((e) => e) || [];
        message.maxShow = object.maxShow ?? 0;
        message.pegPriceType = object.pegPriceType ?? 1;
        return message;
    }
};

function createBaseCreateCryptoOrderRequest(): CreateCryptoOrderRequest {
    return {
        clientRequestId: '',
        clientOrderId: '',
        exchangeIdSource: '',
        exchange: '',
        securityIdSource: '',
        securityCode: '',
        securityId: '',
        accountType: 1,
        account: '',
        side: 1,
        qty: 0,
        price: 0,
        currency: '',
        orderType: 1,
        timeInForce: 1,
        timeInForceExpiry: '',
        orderText: '',
        giveIn: '',
        giveUp: '',
        handlingInstruction: 1,
        onBehalfOfCompId: '',
        deliverToCompId: '',
        minQty: 0,
        maxFloor: 0,
        quoteId: '',
        destinationKey: '',
        bookAsFilled: false,
        parentOrderId: '0',
        commission: 0,
        productType: 1,
        algoType: '',
        leg: [],
        checkpointTimestamp: [],
        orderAllocGroup: [],
        orderPartiesGroup: [],
        orderMetadata: [],
        onBehalfOfUsername: '',
        fixingSource: '',
        stopPrice: 0
    };
}

export const CreateCryptoOrderRequest = {
    encode(message: CreateCryptoOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.clientOrderId !== '') {
            writer.uint32(18).string(message.clientOrderId);
        }
        if (message.exchangeIdSource !== '') {
            writer.uint32(26).string(message.exchangeIdSource);
        }
        if (message.exchange !== '') {
            writer.uint32(34).string(message.exchange);
        }
        if (message.securityIdSource !== '') {
            writer.uint32(42).string(message.securityIdSource);
        }
        if (message.securityCode !== '') {
            writer.uint32(50).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(58).string(message.securityId);
        }
        writer.uint32(72).int32(message.accountType);
        if (message.account !== '') {
            writer.uint32(82).string(message.account);
        }
        writer.uint32(88).int32(message.side);
        if (message.qty !== 0) {
            writer.uint32(97).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(105).double(message.price);
        }
        if (message.currency !== '') {
            writer.uint32(114).string(message.currency);
        }
        writer.uint32(120).int32(message.orderType);
        writer.uint32(128).int32(message.timeInForce);
        if (message.timeInForceExpiry !== '') {
            writer.uint32(138).string(message.timeInForceExpiry);
        }
        if (message.orderText !== '') {
            writer.uint32(146).string(message.orderText);
        }
        if (message.giveIn !== '') {
            writer.uint32(154).string(message.giveIn);
        }
        if (message.giveUp !== '') {
            writer.uint32(162).string(message.giveUp);
        }
        writer.uint32(168).int32(message.handlingInstruction);
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(178).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(186).string(message.deliverToCompId);
        }
        if (message.minQty !== 0) {
            writer.uint32(193).double(message.minQty);
        }
        if (message.maxFloor !== 0) {
            writer.uint32(209).double(message.maxFloor);
        }
        if (message.quoteId !== '') {
            writer.uint32(218).string(message.quoteId);
        }
        if (message.destinationKey !== '') {
            writer.uint32(226).string(message.destinationKey);
        }
        if (message.bookAsFilled === true) {
            writer.uint32(232).bool(message.bookAsFilled);
        }
        if (message.parentOrderId !== '0') {
            writer.uint32(240).int64(message.parentOrderId);
        }
        if (message.commission !== 0) {
            writer.uint32(249).double(message.commission);
        }
        writer.uint32(256).int32(message.productType);
        if (message.algoType !== '') {
            writer.uint32(266).string(message.algoType);
        }
        for (const v of message.leg) {
            CreateCryptoStrategyLegOrder.encode(v!, writer.uint32(274).fork()).ldelim();
        }
        writer.uint32(282).fork();
        for (const v of message.checkpointTimestamp) {
            writer.int64(v);
        }
        writer.ldelim();
        for (const v of message.orderAllocGroup) {
            CreateOrderAllocGroup.encode(v!, writer.uint32(290).fork()).ldelim();
        }
        for (const v of message.orderPartiesGroup) {
            CreateOrderPartiesGroup.encode(v!, writer.uint32(298).fork()).ldelim();
        }
        for (const v of message.orderMetadata) {
            KeyValue.encode(v!, writer.uint32(306).fork()).ldelim();
        }
        if (message.onBehalfOfUsername !== '') {
            writer.uint32(314).string(message.onBehalfOfUsername);
        }
        if (message.fixingSource !== '') {
            writer.uint32(322).string(message.fixingSource);
        }
        if (message.stopPrice !== 0) {
            writer.uint32(329).double(message.stopPrice);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateCryptoOrderRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateCryptoOrderRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.clientOrderId = reader.string();
                    break;
                case 3:
                    message.exchangeIdSource = reader.string();
                    break;
                case 4:
                    message.exchange = reader.string();
                    break;
                case 5:
                    message.securityIdSource = reader.string();
                    break;
                case 6:
                    message.securityCode = reader.string();
                    break;
                case 7:
                    message.securityId = reader.string();
                    break;
                case 9:
                    message.accountType = reader.int32() as any;
                    break;
                case 10:
                    message.account = reader.string();
                    break;
                case 11:
                    message.side = reader.int32() as any;
                    break;
                case 12:
                    message.qty = reader.double();
                    break;
                case 13:
                    message.price = reader.double();
                    break;
                case 14:
                    message.currency = reader.string();
                    break;
                case 15:
                    message.orderType = reader.int32() as any;
                    break;
                case 16:
                    message.timeInForce = reader.int32() as any;
                    break;
                case 17:
                    message.timeInForceExpiry = reader.string();
                    break;
                case 18:
                    message.orderText = reader.string();
                    break;
                case 19:
                    message.giveIn = reader.string();
                    break;
                case 20:
                    message.giveUp = reader.string();
                    break;
                case 21:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 22:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 23:
                    message.deliverToCompId = reader.string();
                    break;
                case 24:
                    message.minQty = reader.double();
                    break;
                case 26:
                    message.maxFloor = reader.double();
                    break;
                case 27:
                    message.quoteId = reader.string();
                    break;
                case 28:
                    message.destinationKey = reader.string();
                    break;
                case 29:
                    message.bookAsFilled = reader.bool();
                    break;
                case 30:
                    message.parentOrderId = longToString(reader.int64() as Long);
                    break;
                case 31:
                    message.commission = reader.double();
                    break;
                case 32:
                    message.productType = reader.int32() as any;
                    break;
                case 33:
                    message.algoType = reader.string();
                    break;
                case 34:
                    message.leg.push(CreateCryptoStrategyLegOrder.decode(reader, reader.uint32()));
                    break;
                case 35:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                        }
                    } else {
                        message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                    }
                    break;
                case 36:
                    message.orderAllocGroup.push(CreateOrderAllocGroup.decode(reader, reader.uint32()));
                    break;
                case 37:
                    message.orderPartiesGroup.push(CreateOrderPartiesGroup.decode(reader, reader.uint32()));
                    break;
                case 38:
                    message.orderMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 39:
                    message.onBehalfOfUsername = reader.string();
                    break;
                case 40:
                    message.fixingSource = reader.string();
                    break;
                case 41:
                    message.stopPrice = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateCryptoOrderRequest>): CreateCryptoOrderRequest {
        const message = createBaseCreateCryptoOrderRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.clientOrderId = object.clientOrderId ?? '';
        message.exchangeIdSource = object.exchangeIdSource ?? '';
        message.exchange = object.exchange ?? '';
        message.securityIdSource = object.securityIdSource ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.accountType = object.accountType ?? 1;
        message.account = object.account ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.currency = object.currency ?? '';
        message.orderType = object.orderType ?? 1;
        message.timeInForce = object.timeInForce ?? 1;
        message.timeInForceExpiry = object.timeInForceExpiry ?? '';
        message.orderText = object.orderText ?? '';
        message.giveIn = object.giveIn ?? '';
        message.giveUp = object.giveUp ?? '';
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.minQty = object.minQty ?? 0;
        message.maxFloor = object.maxFloor ?? 0;
        message.quoteId = object.quoteId ?? '';
        message.destinationKey = object.destinationKey ?? '';
        message.bookAsFilled = object.bookAsFilled ?? false;
        message.parentOrderId = object.parentOrderId ?? '0';
        message.commission = object.commission ?? 0;
        message.productType = object.productType ?? 1;
        message.algoType = object.algoType ?? '';
        message.leg = object.leg?.map((e) => CreateCryptoStrategyLegOrder.fromPartial(e)) || [];
        message.checkpointTimestamp = object.checkpointTimestamp?.map((e) => e) || [];
        message.orderAllocGroup = object.orderAllocGroup?.map((e) => CreateOrderAllocGroup.fromPartial(e)) || [];
        message.orderPartiesGroup = object.orderPartiesGroup?.map((e) => CreateOrderPartiesGroup.fromPartial(e)) || [];
        message.orderMetadata = object.orderMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.onBehalfOfUsername = object.onBehalfOfUsername ?? '';
        message.fixingSource = object.fixingSource ?? '';
        message.stopPrice = object.stopPrice ?? 0;
        return message;
    }
};

function createBaseCreateCryptoStrategyLegOrder(): CreateCryptoStrategyLegOrder {
    return {
        underlyingCode: '',
        underlyingSecurityId: '',
        legValueDate: '',
        side: 1,
        qty: 0,
        price: 0,
        legForwardPts: 0,
        spotPrice: 0,
        settlementType: '',
        maturityDate: ''
    };
}

export const CreateCryptoStrategyLegOrder = {
    encode(message: CreateCryptoStrategyLegOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.underlyingCode !== '') {
            writer.uint32(10).string(message.underlyingCode);
        }
        if (message.underlyingSecurityId !== '') {
            writer.uint32(18).string(message.underlyingSecurityId);
        }
        if (message.legValueDate !== '') {
            writer.uint32(26).string(message.legValueDate);
        }
        writer.uint32(32).int32(message.side);
        if (message.qty !== 0) {
            writer.uint32(41).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(49).double(message.price);
        }
        if (message.legForwardPts !== 0) {
            writer.uint32(57).double(message.legForwardPts);
        }
        if (message.spotPrice !== 0) {
            writer.uint32(65).double(message.spotPrice);
        }
        if (message.settlementType !== '') {
            writer.uint32(74).string(message.settlementType);
        }
        if (message.maturityDate !== '') {
            writer.uint32(82).string(message.maturityDate);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateCryptoStrategyLegOrder {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateCryptoStrategyLegOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.underlyingCode = reader.string();
                    break;
                case 2:
                    message.underlyingSecurityId = reader.string();
                    break;
                case 3:
                    message.legValueDate = reader.string();
                    break;
                case 4:
                    message.side = reader.int32() as any;
                    break;
                case 5:
                    message.qty = reader.double();
                    break;
                case 6:
                    message.price = reader.double();
                    break;
                case 7:
                    message.legForwardPts = reader.double();
                    break;
                case 8:
                    message.spotPrice = reader.double();
                    break;
                case 9:
                    message.settlementType = reader.string();
                    break;
                case 10:
                    message.maturityDate = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateCryptoStrategyLegOrder>): CreateCryptoStrategyLegOrder {
        const message = createBaseCreateCryptoStrategyLegOrder();
        message.underlyingCode = object.underlyingCode ?? '';
        message.underlyingSecurityId = object.underlyingSecurityId ?? '';
        message.legValueDate = object.legValueDate ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.legForwardPts = object.legForwardPts ?? 0;
        message.spotPrice = object.spotPrice ?? 0;
        message.settlementType = object.settlementType ?? '';
        message.maturityDate = object.maturityDate ?? '';
        return message;
    }
};

function createBaseCreateFixedIncomeOrderRequest(): CreateFixedIncomeOrderRequest {
    return {
        clientRequestId: '',
        clientOrderId: '',
        exchangeIdSource: '',
        exchange: '',
        securityIdSource: '',
        securityCode: '',
        securityId: '',
        settlementDate: '',
        accountType: 1,
        account: '',
        side: 1,
        qty: 0,
        price: 0,
        currency: '',
        orderType: 1,
        timeInForce: 1,
        timeInForceExpiry: '',
        orderText: '',
        giveIn: '',
        giveUp: '',
        handlingInstruction: 1,
        onBehalfOfCompId: '',
        deliverToCompId: '',
        minQty: 0,
        maxFloor: 0,
        quoteId: '',
        destinationKey: '',
        bookAsFilled: false,
        parentOrderId: '0',
        commission: 0,
        maturityDate: '',
        productType: 1,
        algoType: '',
        checkpointTimestamp: [],
        orderAllocGroup: [],
        orderPartiesGroup: [],
        settlementType: '',
        orderMetadata: [],
        onBehalfOfUsername: '',
        stopPrice: 0
    };
}

export const CreateFixedIncomeOrderRequest = {
    encode(message: CreateFixedIncomeOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.clientOrderId !== '') {
            writer.uint32(18).string(message.clientOrderId);
        }
        if (message.exchangeIdSource !== '') {
            writer.uint32(26).string(message.exchangeIdSource);
        }
        if (message.exchange !== '') {
            writer.uint32(34).string(message.exchange);
        }
        if (message.securityIdSource !== '') {
            writer.uint32(42).string(message.securityIdSource);
        }
        if (message.securityCode !== '') {
            writer.uint32(50).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(58).string(message.securityId);
        }
        if (message.settlementDate !== '') {
            writer.uint32(66).string(message.settlementDate);
        }
        writer.uint32(72).int32(message.accountType);
        if (message.account !== '') {
            writer.uint32(82).string(message.account);
        }
        writer.uint32(88).int32(message.side);
        if (message.qty !== 0) {
            writer.uint32(97).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(105).double(message.price);
        }
        if (message.currency !== '') {
            writer.uint32(114).string(message.currency);
        }
        writer.uint32(120).int32(message.orderType);
        writer.uint32(128).int32(message.timeInForce);
        if (message.timeInForceExpiry !== '') {
            writer.uint32(138).string(message.timeInForceExpiry);
        }
        if (message.orderText !== '') {
            writer.uint32(146).string(message.orderText);
        }
        if (message.giveIn !== '') {
            writer.uint32(154).string(message.giveIn);
        }
        if (message.giveUp !== '') {
            writer.uint32(162).string(message.giveUp);
        }
        writer.uint32(168).int32(message.handlingInstruction);
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(178).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(186).string(message.deliverToCompId);
        }
        if (message.minQty !== 0) {
            writer.uint32(193).double(message.minQty);
        }
        if (message.maxFloor !== 0) {
            writer.uint32(209).double(message.maxFloor);
        }
        if (message.quoteId !== '') {
            writer.uint32(218).string(message.quoteId);
        }
        if (message.destinationKey !== '') {
            writer.uint32(226).string(message.destinationKey);
        }
        if (message.bookAsFilled === true) {
            writer.uint32(232).bool(message.bookAsFilled);
        }
        if (message.parentOrderId !== '0') {
            writer.uint32(240).int64(message.parentOrderId);
        }
        if (message.commission !== 0) {
            writer.uint32(249).double(message.commission);
        }
        if (message.maturityDate !== '') {
            writer.uint32(258).string(message.maturityDate);
        }
        writer.uint32(264).int32(message.productType);
        if (message.algoType !== '') {
            writer.uint32(274).string(message.algoType);
        }
        writer.uint32(282).fork();
        for (const v of message.checkpointTimestamp) {
            writer.int64(v);
        }
        writer.ldelim();
        for (const v of message.orderAllocGroup) {
            CreateOrderAllocGroup.encode(v!, writer.uint32(290).fork()).ldelim();
        }
        for (const v of message.orderPartiesGroup) {
            CreateOrderPartiesGroup.encode(v!, writer.uint32(298).fork()).ldelim();
        }
        if (message.settlementType !== '') {
            writer.uint32(306).string(message.settlementType);
        }
        for (const v of message.orderMetadata) {
            KeyValue.encode(v!, writer.uint32(314).fork()).ldelim();
        }
        if (message.onBehalfOfUsername !== '') {
            writer.uint32(322).string(message.onBehalfOfUsername);
        }
        if (message.stopPrice !== 0) {
            writer.uint32(329).double(message.stopPrice);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateFixedIncomeOrderRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateFixedIncomeOrderRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.clientOrderId = reader.string();
                    break;
                case 3:
                    message.exchangeIdSource = reader.string();
                    break;
                case 4:
                    message.exchange = reader.string();
                    break;
                case 5:
                    message.securityIdSource = reader.string();
                    break;
                case 6:
                    message.securityCode = reader.string();
                    break;
                case 7:
                    message.securityId = reader.string();
                    break;
                case 8:
                    message.settlementDate = reader.string();
                    break;
                case 9:
                    message.accountType = reader.int32() as any;
                    break;
                case 10:
                    message.account = reader.string();
                    break;
                case 11:
                    message.side = reader.int32() as any;
                    break;
                case 12:
                    message.qty = reader.double();
                    break;
                case 13:
                    message.price = reader.double();
                    break;
                case 14:
                    message.currency = reader.string();
                    break;
                case 15:
                    message.orderType = reader.int32() as any;
                    break;
                case 16:
                    message.timeInForce = reader.int32() as any;
                    break;
                case 17:
                    message.timeInForceExpiry = reader.string();
                    break;
                case 18:
                    message.orderText = reader.string();
                    break;
                case 19:
                    message.giveIn = reader.string();
                    break;
                case 20:
                    message.giveUp = reader.string();
                    break;
                case 21:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 22:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 23:
                    message.deliverToCompId = reader.string();
                    break;
                case 24:
                    message.minQty = reader.double();
                    break;
                case 26:
                    message.maxFloor = reader.double();
                    break;
                case 27:
                    message.quoteId = reader.string();
                    break;
                case 28:
                    message.destinationKey = reader.string();
                    break;
                case 29:
                    message.bookAsFilled = reader.bool();
                    break;
                case 30:
                    message.parentOrderId = longToString(reader.int64() as Long);
                    break;
                case 31:
                    message.commission = reader.double();
                    break;
                case 32:
                    message.maturityDate = reader.string();
                    break;
                case 33:
                    message.productType = reader.int32() as any;
                    break;
                case 34:
                    message.algoType = reader.string();
                    break;
                case 35:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                        }
                    } else {
                        message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                    }
                    break;
                case 36:
                    message.orderAllocGroup.push(CreateOrderAllocGroup.decode(reader, reader.uint32()));
                    break;
                case 37:
                    message.orderPartiesGroup.push(CreateOrderPartiesGroup.decode(reader, reader.uint32()));
                    break;
                case 38:
                    message.settlementType = reader.string();
                    break;
                case 39:
                    message.orderMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 40:
                    message.onBehalfOfUsername = reader.string();
                    break;
                case 41:
                    message.stopPrice = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateFixedIncomeOrderRequest>): CreateFixedIncomeOrderRequest {
        const message = createBaseCreateFixedIncomeOrderRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.clientOrderId = object.clientOrderId ?? '';
        message.exchangeIdSource = object.exchangeIdSource ?? '';
        message.exchange = object.exchange ?? '';
        message.securityIdSource = object.securityIdSource ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.accountType = object.accountType ?? 1;
        message.account = object.account ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.currency = object.currency ?? '';
        message.orderType = object.orderType ?? 1;
        message.timeInForce = object.timeInForce ?? 1;
        message.timeInForceExpiry = object.timeInForceExpiry ?? '';
        message.orderText = object.orderText ?? '';
        message.giveIn = object.giveIn ?? '';
        message.giveUp = object.giveUp ?? '';
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.minQty = object.minQty ?? 0;
        message.maxFloor = object.maxFloor ?? 0;
        message.quoteId = object.quoteId ?? '';
        message.destinationKey = object.destinationKey ?? '';
        message.bookAsFilled = object.bookAsFilled ?? false;
        message.parentOrderId = object.parentOrderId ?? '0';
        message.commission = object.commission ?? 0;
        message.maturityDate = object.maturityDate ?? '';
        message.productType = object.productType ?? 1;
        message.algoType = object.algoType ?? '';
        message.checkpointTimestamp = object.checkpointTimestamp?.map((e) => e) || [];
        message.orderAllocGroup = object.orderAllocGroup?.map((e) => CreateOrderAllocGroup.fromPartial(e)) || [];
        message.orderPartiesGroup = object.orderPartiesGroup?.map((e) => CreateOrderPartiesGroup.fromPartial(e)) || [];
        message.settlementType = object.settlementType ?? '';
        message.orderMetadata = object.orderMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.onBehalfOfUsername = object.onBehalfOfUsername ?? '';
        message.stopPrice = object.stopPrice ?? 0;
        return message;
    }
};

function createBaseCreateFxStrategyLegOrder(): CreateFxStrategyLegOrder {
    return {
        underlyingCode: '',
        underlyingSecurityId: '',
        legValueDate: '',
        side: 1,
        qty: 0,
        price: 0,
        legForwardPts: 0,
        spotPrice: 0,
        settlementType: '',
        maturityDate: ''
    };
}

export const CreateFxStrategyLegOrder = {
    encode(message: CreateFxStrategyLegOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.underlyingCode !== '') {
            writer.uint32(10).string(message.underlyingCode);
        }
        if (message.underlyingSecurityId !== '') {
            writer.uint32(18).string(message.underlyingSecurityId);
        }
        if (message.legValueDate !== '') {
            writer.uint32(26).string(message.legValueDate);
        }
        writer.uint32(32).int32(message.side);
        if (message.qty !== 0) {
            writer.uint32(41).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(49).double(message.price);
        }
        if (message.legForwardPts !== 0) {
            writer.uint32(57).double(message.legForwardPts);
        }
        if (message.spotPrice !== 0) {
            writer.uint32(65).double(message.spotPrice);
        }
        if (message.settlementType !== '') {
            writer.uint32(74).string(message.settlementType);
        }
        if (message.maturityDate !== '') {
            writer.uint32(82).string(message.maturityDate);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateFxStrategyLegOrder {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateFxStrategyLegOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.underlyingCode = reader.string();
                    break;
                case 2:
                    message.underlyingSecurityId = reader.string();
                    break;
                case 3:
                    message.legValueDate = reader.string();
                    break;
                case 4:
                    message.side = reader.int32() as any;
                    break;
                case 5:
                    message.qty = reader.double();
                    break;
                case 6:
                    message.price = reader.double();
                    break;
                case 7:
                    message.legForwardPts = reader.double();
                    break;
                case 8:
                    message.spotPrice = reader.double();
                    break;
                case 9:
                    message.settlementType = reader.string();
                    break;
                case 10:
                    message.maturityDate = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateFxStrategyLegOrder>): CreateFxStrategyLegOrder {
        const message = createBaseCreateFxStrategyLegOrder();
        message.underlyingCode = object.underlyingCode ?? '';
        message.underlyingSecurityId = object.underlyingSecurityId ?? '';
        message.legValueDate = object.legValueDate ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.legForwardPts = object.legForwardPts ?? 0;
        message.spotPrice = object.spotPrice ?? 0;
        message.settlementType = object.settlementType ?? '';
        message.maturityDate = object.maturityDate ?? '';
        return message;
    }
};

function createBaseCreateEquityOrderRequest(): CreateEquityOrderRequest {
    return {
        clientRequestId: '',
        clientOrderId: '',
        exchangeIdSource: '',
        exchange: '',
        securityIdSource: '',
        securityCode: '',
        securityId: '',
        accountType: 1,
        account: '',
        side: 1,
        qty: 0,
        price: 0,
        currency: '',
        orderType: 1,
        timeInForce: 1,
        timeInForceExpiry: '',
        orderText: '',
        giveIn: '',
        giveUp: '',
        handlingInstruction: 1,
        onBehalfOfCompId: '',
        deliverToCompId: '',
        minQty: 0,
        maxFloor: 0,
        executionInstruction: 1,
        quoteId: '',
        destinationKey: '',
        bookAsFilled: false,
        parentOrderId: '0',
        algoType: '',
        checkpointTimestamp: [],
        orderAllocGroup: [],
        orderPartiesGroup: [],
        orderMetadata: [],
        onBehalfOfUsername: '',
        stopPrice: 0,
        productType: 1,
        expiry: ''
    };
}

export const CreateEquityOrderRequest = {
    encode(message: CreateEquityOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.clientOrderId !== '') {
            writer.uint32(18).string(message.clientOrderId);
        }
        if (message.exchangeIdSource !== '') {
            writer.uint32(26).string(message.exchangeIdSource);
        }
        if (message.exchange !== '') {
            writer.uint32(34).string(message.exchange);
        }
        if (message.securityIdSource !== '') {
            writer.uint32(42).string(message.securityIdSource);
        }
        if (message.securityCode !== '') {
            writer.uint32(50).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(58).string(message.securityId);
        }
        writer.uint32(72).int32(message.accountType);
        if (message.account !== '') {
            writer.uint32(82).string(message.account);
        }
        writer.uint32(88).int32(message.side);
        if (message.qty !== 0) {
            writer.uint32(97).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(105).double(message.price);
        }
        if (message.currency !== '') {
            writer.uint32(114).string(message.currency);
        }
        writer.uint32(120).int32(message.orderType);
        writer.uint32(128).int32(message.timeInForce);
        if (message.timeInForceExpiry !== '') {
            writer.uint32(138).string(message.timeInForceExpiry);
        }
        if (message.orderText !== '') {
            writer.uint32(146).string(message.orderText);
        }
        if (message.giveIn !== '') {
            writer.uint32(154).string(message.giveIn);
        }
        if (message.giveUp !== '') {
            writer.uint32(162).string(message.giveUp);
        }
        writer.uint32(168).int32(message.handlingInstruction);
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(178).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(186).string(message.deliverToCompId);
        }
        if (message.minQty !== 0) {
            writer.uint32(193).double(message.minQty);
        }
        if (message.maxFloor !== 0) {
            writer.uint32(209).double(message.maxFloor);
        }
        writer.uint32(216).int32(message.executionInstruction);
        if (message.quoteId !== '') {
            writer.uint32(226).string(message.quoteId);
        }
        if (message.destinationKey !== '') {
            writer.uint32(234).string(message.destinationKey);
        }
        if (message.bookAsFilled === true) {
            writer.uint32(240).bool(message.bookAsFilled);
        }
        if (message.parentOrderId !== '0') {
            writer.uint32(248).int64(message.parentOrderId);
        }
        if (message.algoType !== '') {
            writer.uint32(258).string(message.algoType);
        }
        writer.uint32(266).fork();
        for (const v of message.checkpointTimestamp) {
            writer.int64(v);
        }
        writer.ldelim();
        for (const v of message.orderAllocGroup) {
            CreateOrderAllocGroup.encode(v!, writer.uint32(274).fork()).ldelim();
        }
        for (const v of message.orderPartiesGroup) {
            CreateOrderPartiesGroup.encode(v!, writer.uint32(282).fork()).ldelim();
        }
        for (const v of message.orderMetadata) {
            KeyValue.encode(v!, writer.uint32(290).fork()).ldelim();
        }
        if (message.onBehalfOfUsername !== '') {
            writer.uint32(298).string(message.onBehalfOfUsername);
        }
        if (message.stopPrice !== 0) {
            writer.uint32(305).double(message.stopPrice);
        }
        writer.uint32(312).int32(message.productType);
        if (message.expiry !== '') {
            writer.uint32(322).string(message.expiry);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateEquityOrderRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateEquityOrderRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.clientOrderId = reader.string();
                    break;
                case 3:
                    message.exchangeIdSource = reader.string();
                    break;
                case 4:
                    message.exchange = reader.string();
                    break;
                case 5:
                    message.securityIdSource = reader.string();
                    break;
                case 6:
                    message.securityCode = reader.string();
                    break;
                case 7:
                    message.securityId = reader.string();
                    break;
                case 9:
                    message.accountType = reader.int32() as any;
                    break;
                case 10:
                    message.account = reader.string();
                    break;
                case 11:
                    message.side = reader.int32() as any;
                    break;
                case 12:
                    message.qty = reader.double();
                    break;
                case 13:
                    message.price = reader.double();
                    break;
                case 14:
                    message.currency = reader.string();
                    break;
                case 15:
                    message.orderType = reader.int32() as any;
                    break;
                case 16:
                    message.timeInForce = reader.int32() as any;
                    break;
                case 17:
                    message.timeInForceExpiry = reader.string();
                    break;
                case 18:
                    message.orderText = reader.string();
                    break;
                case 19:
                    message.giveIn = reader.string();
                    break;
                case 20:
                    message.giveUp = reader.string();
                    break;
                case 21:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 22:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 23:
                    message.deliverToCompId = reader.string();
                    break;
                case 24:
                    message.minQty = reader.double();
                    break;
                case 26:
                    message.maxFloor = reader.double();
                    break;
                case 27:
                    message.executionInstruction = reader.int32() as any;
                    break;
                case 28:
                    message.quoteId = reader.string();
                    break;
                case 29:
                    message.destinationKey = reader.string();
                    break;
                case 30:
                    message.bookAsFilled = reader.bool();
                    break;
                case 31:
                    message.parentOrderId = longToString(reader.int64() as Long);
                    break;
                case 32:
                    message.algoType = reader.string();
                    break;
                case 33:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                        }
                    } else {
                        message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                    }
                    break;
                case 34:
                    message.orderAllocGroup.push(CreateOrderAllocGroup.decode(reader, reader.uint32()));
                    break;
                case 35:
                    message.orderPartiesGroup.push(CreateOrderPartiesGroup.decode(reader, reader.uint32()));
                    break;
                case 36:
                    message.orderMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 37:
                    message.onBehalfOfUsername = reader.string();
                    break;
                case 38:
                    message.stopPrice = reader.double();
                    break;
                case 39:
                    message.productType = reader.int32() as any;
                    break;
                case 40:
                    message.expiry = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateEquityOrderRequest>): CreateEquityOrderRequest {
        const message = createBaseCreateEquityOrderRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.clientOrderId = object.clientOrderId ?? '';
        message.exchangeIdSource = object.exchangeIdSource ?? '';
        message.exchange = object.exchange ?? '';
        message.securityIdSource = object.securityIdSource ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.accountType = object.accountType ?? 1;
        message.account = object.account ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.currency = object.currency ?? '';
        message.orderType = object.orderType ?? 1;
        message.timeInForce = object.timeInForce ?? 1;
        message.timeInForceExpiry = object.timeInForceExpiry ?? '';
        message.orderText = object.orderText ?? '';
        message.giveIn = object.giveIn ?? '';
        message.giveUp = object.giveUp ?? '';
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.minQty = object.minQty ?? 0;
        message.maxFloor = object.maxFloor ?? 0;
        message.executionInstruction = object.executionInstruction ?? 1;
        message.quoteId = object.quoteId ?? '';
        message.destinationKey = object.destinationKey ?? '';
        message.bookAsFilled = object.bookAsFilled ?? false;
        message.parentOrderId = object.parentOrderId ?? '0';
        message.algoType = object.algoType ?? '';
        message.checkpointTimestamp = object.checkpointTimestamp?.map((e) => e) || [];
        message.orderAllocGroup = object.orderAllocGroup?.map((e) => CreateOrderAllocGroup.fromPartial(e)) || [];
        message.orderPartiesGroup = object.orderPartiesGroup?.map((e) => CreateOrderPartiesGroup.fromPartial(e)) || [];
        message.orderMetadata = object.orderMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.onBehalfOfUsername = object.onBehalfOfUsername ?? '';
        message.stopPrice = object.stopPrice ?? 0;
        message.productType = object.productType ?? 1;
        message.expiry = object.expiry ?? '';
        return message;
    }
};

function createBaseCreateOrderAllocGroup(): CreateOrderAllocGroup {
    return { allocAccount: '', allocQty: 0, allocSide: 1, allocSettlementDate: '', allocPrice: 0, linkingId: 0 };
}

export const CreateOrderAllocGroup = {
    encode(message: CreateOrderAllocGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.allocAccount !== '') {
            writer.uint32(10).string(message.allocAccount);
        }
        if (message.allocQty !== 0) {
            writer.uint32(17).double(message.allocQty);
        }
        writer.uint32(24).int32(message.allocSide);
        if (message.allocSettlementDate !== '') {
            writer.uint32(34).string(message.allocSettlementDate);
        }
        if (message.allocPrice !== 0) {
            writer.uint32(41).double(message.allocPrice);
        }
        if (message.linkingId !== 0) {
            writer.uint32(48).int32(message.linkingId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrderAllocGroup {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateOrderAllocGroup();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.allocAccount = reader.string();
                    break;
                case 2:
                    message.allocQty = reader.double();
                    break;
                case 3:
                    message.allocSide = reader.int32() as any;
                    break;
                case 4:
                    message.allocSettlementDate = reader.string();
                    break;
                case 5:
                    message.allocPrice = reader.double();
                    break;
                case 6:
                    message.linkingId = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateOrderAllocGroup>): CreateOrderAllocGroup {
        const message = createBaseCreateOrderAllocGroup();
        message.allocAccount = object.allocAccount ?? '';
        message.allocQty = object.allocQty ?? 0;
        message.allocSide = object.allocSide ?? 1;
        message.allocSettlementDate = object.allocSettlementDate ?? '';
        message.allocPrice = object.allocPrice ?? 0;
        message.linkingId = object.linkingId ?? 0;
        return message;
    }
};

function createBaseCreateOrderPartiesGroup(): CreateOrderPartiesGroup {
    return { partyId: '', partyIdSource: 1, partyRole: 1 };
}

export const CreateOrderPartiesGroup = {
    encode(message: CreateOrderPartiesGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.partyId !== '') {
            writer.uint32(10).string(message.partyId);
        }
        writer.uint32(16).int32(message.partyIdSource);
        writer.uint32(24).int32(message.partyRole);
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrderPartiesGroup {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateOrderPartiesGroup();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.partyId = reader.string();
                    break;
                case 2:
                    message.partyIdSource = reader.int32() as any;
                    break;
                case 3:
                    message.partyRole = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateOrderPartiesGroup>): CreateOrderPartiesGroup {
        const message = createBaseCreateOrderPartiesGroup();
        message.partyId = object.partyId ?? '';
        message.partyIdSource = object.partyIdSource ?? 1;
        message.partyRole = object.partyRole ?? 1;
        return message;
    }
};

function createBaseCancelOrderRequest(): CancelOrderRequest {
    return {
        clientRequestId: '',
        orderId: '0',
        clOrdId: '',
        origClOrdId: '',
        onBehalfOfCompId: '',
        deliverToCompId: '',
        checkpointTimestamp: [],
        onBehalfOfUsername: '',
        parentOrderId: '0'
    };
}

export const CancelOrderRequest = {
    encode(message: CancelOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.orderId !== '0') {
            writer.uint32(16).int64(message.orderId);
        }
        if (message.clOrdId !== '') {
            writer.uint32(26).string(message.clOrdId);
        }
        if (message.origClOrdId !== '') {
            writer.uint32(34).string(message.origClOrdId);
        }
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(42).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(50).string(message.deliverToCompId);
        }
        writer.uint32(58).fork();
        for (const v of message.checkpointTimestamp) {
            writer.int64(v);
        }
        writer.ldelim();
        if (message.onBehalfOfUsername !== '') {
            writer.uint32(66).string(message.onBehalfOfUsername);
        }
        if (message.parentOrderId !== '0') {
            writer.uint32(72).int64(message.parentOrderId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CancelOrderRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCancelOrderRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.clOrdId = reader.string();
                    break;
                case 4:
                    message.origClOrdId = reader.string();
                    break;
                case 5:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 6:
                    message.deliverToCompId = reader.string();
                    break;
                case 7:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                        }
                    } else {
                        message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                    }
                    break;
                case 8:
                    message.onBehalfOfUsername = reader.string();
                    break;
                case 9:
                    message.parentOrderId = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CancelOrderRequest>): CancelOrderRequest {
        const message = createBaseCancelOrderRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.orderId = object.orderId ?? '0';
        message.clOrdId = object.clOrdId ?? '';
        message.origClOrdId = object.origClOrdId ?? '';
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.checkpointTimestamp = object.checkpointTimestamp?.map((e) => e) || [];
        message.onBehalfOfUsername = object.onBehalfOfUsername ?? '';
        message.parentOrderId = object.parentOrderId ?? '0';
        return message;
    }
};

function createBaseAmendOrderRequest(): AmendOrderRequest {
    return {
        clientRequestId: '',
        orderId: '0',
        clOrdId: '',
        origClOrdId: '',
        account: '',
        qty: 0,
        price: 0,
        orderText: '',
        giveIn: '',
        giveUp: '',
        onBehalfOfCompId: '',
        deliverToCompId: '',
        quoteId: '',
        checkpointTimestamp: [],
        stopPrice: 0,
        orderMetadata: [],
        timeInForceExpiry: '',
        executionInstruction: 1,
        minQty: 0,
        maxShow: 0,
        commission: 0,
        instructionsMessage: ''
    };
}

export const AmendOrderRequest = {
    encode(message: AmendOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.orderId !== '0') {
            writer.uint32(16).int64(message.orderId);
        }
        if (message.clOrdId !== '') {
            writer.uint32(26).string(message.clOrdId);
        }
        if (message.origClOrdId !== '') {
            writer.uint32(34).string(message.origClOrdId);
        }
        if (message.account !== '') {
            writer.uint32(42).string(message.account);
        }
        if (message.qty !== 0) {
            writer.uint32(49).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(57).double(message.price);
        }
        if (message.orderText !== '') {
            writer.uint32(66).string(message.orderText);
        }
        if (message.giveIn !== '') {
            writer.uint32(74).string(message.giveIn);
        }
        if (message.giveUp !== '') {
            writer.uint32(82).string(message.giveUp);
        }
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(90).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(98).string(message.deliverToCompId);
        }
        if (message.quoteId !== '') {
            writer.uint32(106).string(message.quoteId);
        }
        writer.uint32(114).fork();
        for (const v of message.checkpointTimestamp) {
            writer.int64(v);
        }
        writer.ldelim();
        if (message.stopPrice !== 0) {
            writer.uint32(121).double(message.stopPrice);
        }
        for (const v of message.orderMetadata) {
            KeyValue.encode(v!, writer.uint32(130).fork()).ldelim();
        }
        if (message.timeInForceExpiry !== '') {
            writer.uint32(138).string(message.timeInForceExpiry);
        }
        writer.uint32(144).int32(message.executionInstruction);
        if (message.minQty !== 0) {
            writer.uint32(153).double(message.minQty);
        }
        if (message.maxShow !== 0) {
            writer.uint32(161).double(message.maxShow);
        }
        if (message.commission !== 0) {
            writer.uint32(169).double(message.commission);
        }
        if (message.instructionsMessage !== '') {
            writer.uint32(178).string(message.instructionsMessage);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): AmendOrderRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAmendOrderRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.clOrdId = reader.string();
                    break;
                case 4:
                    message.origClOrdId = reader.string();
                    break;
                case 5:
                    message.account = reader.string();
                    break;
                case 6:
                    message.qty = reader.double();
                    break;
                case 7:
                    message.price = reader.double();
                    break;
                case 8:
                    message.orderText = reader.string();
                    break;
                case 9:
                    message.giveIn = reader.string();
                    break;
                case 10:
                    message.giveUp = reader.string();
                    break;
                case 11:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 12:
                    message.deliverToCompId = reader.string();
                    break;
                case 13:
                    message.quoteId = reader.string();
                    break;
                case 14:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                        }
                    } else {
                        message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                    }
                    break;
                case 15:
                    message.stopPrice = reader.double();
                    break;
                case 16:
                    message.orderMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 17:
                    message.timeInForceExpiry = reader.string();
                    break;
                case 18:
                    message.executionInstruction = reader.int32() as any;
                    break;
                case 19:
                    message.minQty = reader.double();
                    break;
                case 20:
                    message.maxShow = reader.double();
                    break;
                case 21:
                    message.commission = reader.double();
                    break;
                case 22:
                    message.instructionsMessage = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<AmendOrderRequest>): AmendOrderRequest {
        const message = createBaseAmendOrderRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.orderId = object.orderId ?? '0';
        message.clOrdId = object.clOrdId ?? '';
        message.origClOrdId = object.origClOrdId ?? '';
        message.account = object.account ?? '';
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.orderText = object.orderText ?? '';
        message.giveIn = object.giveIn ?? '';
        message.giveUp = object.giveUp ?? '';
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.quoteId = object.quoteId ?? '';
        message.checkpointTimestamp = object.checkpointTimestamp?.map((e) => e) || [];
        message.stopPrice = object.stopPrice ?? 0;
        message.orderMetadata = object.orderMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.timeInForceExpiry = object.timeInForceExpiry ?? '';
        message.executionInstruction = object.executionInstruction ?? 1;
        message.minQty = object.minQty ?? 0;
        message.maxShow = object.maxShow ?? 0;
        message.commission = object.commission ?? 0;
        message.instructionsMessage = object.instructionsMessage ?? '';
        return message;
    }
};

function createBaseFindAllAssignedOrderSnapshots(): FindAllAssignedOrderSnapshots {
    return { clientRequestId: '' };
}

export const FindAllAssignedOrderSnapshots = {
    encode(message: FindAllAssignedOrderSnapshots, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAllAssignedOrderSnapshots {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAllAssignedOrderSnapshots();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAllAssignedOrderSnapshots>): FindAllAssignedOrderSnapshots {
        const message = createBaseFindAllAssignedOrderSnapshots();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseFindAllOrderSnapshotsByClientOrderId(): FindAllOrderSnapshotsByClientOrderId {
    return { clientRequestId: '', clOrderId: '' };
}

export const FindAllOrderSnapshotsByClientOrderId = {
    encode(message: FindAllOrderSnapshotsByClientOrderId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.clOrderId !== '') {
            writer.uint32(18).string(message.clOrderId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAllOrderSnapshotsByClientOrderId {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAllOrderSnapshotsByClientOrderId();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.clOrderId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAllOrderSnapshotsByClientOrderId>): FindAllOrderSnapshotsByClientOrderId {
        const message = createBaseFindAllOrderSnapshotsByClientOrderId();
        message.clientRequestId = object.clientRequestId ?? '';
        message.clOrderId = object.clOrderId ?? '';
        return message;
    }
};

function createBaseFindAllOrderSnapshotsByOrderId(): FindAllOrderSnapshotsByOrderId {
    return { clientRequestId: '', orderId: '0' };
}

export const FindAllOrderSnapshotsByOrderId = {
    encode(message: FindAllOrderSnapshotsByOrderId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.orderId !== '0') {
            writer.uint32(16).int64(message.orderId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAllOrderSnapshotsByOrderId {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAllOrderSnapshotsByOrderId();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAllOrderSnapshotsByOrderId>): FindAllOrderSnapshotsByOrderId {
        const message = createBaseFindAllOrderSnapshotsByOrderId();
        message.clientRequestId = object.clientRequestId ?? '';
        message.orderId = object.orderId ?? '0';
        return message;
    }
};

function createBaseAcceptManagedOrderRequest(): AcceptManagedOrderRequest {
    return { clientRequestId: '', orderId: '0' };
}

export const AcceptManagedOrderRequest = {
    encode(message: AcceptManagedOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.orderId !== '0') {
            writer.uint32(16).int64(message.orderId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): AcceptManagedOrderRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAcceptManagedOrderRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<AcceptManagedOrderRequest>): AcceptManagedOrderRequest {
        const message = createBaseAcceptManagedOrderRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.orderId = object.orderId ?? '0';
        return message;
    }
};

function createBaseManagedOrderAmendmentReview(): ManagedOrderAmendmentReview {
    return { clientRequestId: '', orderId: '0', reviewAnswer: 0, text: '' };
}

export const ManagedOrderAmendmentReview = {
    encode(message: ManagedOrderAmendmentReview, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.orderId !== '0') {
            writer.uint32(16).int64(message.orderId);
        }
        writer.uint32(24).int32(message.reviewAnswer);
        if (message.text !== '') {
            writer.uint32(34).string(message.text);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): ManagedOrderAmendmentReview {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseManagedOrderAmendmentReview();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.reviewAnswer = reader.int32() as any;
                    break;
                case 4:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<ManagedOrderAmendmentReview>): ManagedOrderAmendmentReview {
        const message = createBaseManagedOrderAmendmentReview();
        message.clientRequestId = object.clientRequestId ?? '';
        message.orderId = object.orderId ?? '0';
        message.reviewAnswer = object.reviewAnswer ?? 0;
        message.text = object.text ?? '';
        return message;
    }
};

function createBaseOrderRoutingDestinationCommand(): OrderRoutingDestinationCommand {
    return { clientRequestId: '', orderRoutingDestinationKey: '', operation: 0, incomingSeqNo: 0, outgoingSeqNo: 0 };
}

export const OrderRoutingDestinationCommand = {
    encode(message: OrderRoutingDestinationCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.orderRoutingDestinationKey !== '') {
            writer.uint32(18).string(message.orderRoutingDestinationKey);
        }
        writer.uint32(24).int32(message.operation);
        if (message.incomingSeqNo !== 0) {
            writer.uint32(32).int32(message.incomingSeqNo);
        }
        if (message.outgoingSeqNo !== 0) {
            writer.uint32(40).int32(message.outgoingSeqNo);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): OrderRoutingDestinationCommand {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderRoutingDestinationCommand();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.orderRoutingDestinationKey = reader.string();
                    break;
                case 3:
                    message.operation = reader.int32() as any;
                    break;
                case 4:
                    message.incomingSeqNo = reader.int32();
                    break;
                case 5:
                    message.outgoingSeqNo = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<OrderRoutingDestinationCommand>): OrderRoutingDestinationCommand {
        const message = createBaseOrderRoutingDestinationCommand();
        message.clientRequestId = object.clientRequestId ?? '';
        message.orderRoutingDestinationKey = object.orderRoutingDestinationKey ?? '';
        message.operation = object.operation ?? 0;
        message.incomingSeqNo = object.incomingSeqNo ?? 0;
        message.outgoingSeqNo = object.outgoingSeqNo ?? 0;
        return message;
    }
};

function createBasePostTradeAmendRequest(): PostTradeAmendRequest {
    return { clientRequestId: '', id: '0', orderId: '0', account: '', commission: 0, reason: '', trader: '' };
}

export const PostTradeAmendRequest = {
    encode(message: PostTradeAmendRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.id !== '0') {
            writer.uint32(16).int64(message.id);
        }
        if (message.orderId !== '0') {
            writer.uint32(24).int64(message.orderId);
        }
        if (message.account !== '') {
            writer.uint32(34).string(message.account);
        }
        if (message.commission !== 0) {
            writer.uint32(41).double(message.commission);
        }
        if (message.reason !== '') {
            writer.uint32(50).string(message.reason);
        }
        if (message.trader !== '') {
            writer.uint32(58).string(message.trader);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): PostTradeAmendRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePostTradeAmendRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 4:
                    message.account = reader.string();
                    break;
                case 5:
                    message.commission = reader.double();
                    break;
                case 6:
                    message.reason = reader.string();
                    break;
                case 7:
                    message.trader = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<PostTradeAmendRequest>): PostTradeAmendRequest {
        const message = createBasePostTradeAmendRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.id = object.id ?? '0';
        message.orderId = object.orderId ?? '0';
        message.account = object.account ?? '';
        message.commission = object.commission ?? 0;
        message.reason = object.reason ?? '';
        message.trader = object.trader ?? '';
        return message;
    }
};

function createBasePostTradeAmendInstructionRequest(): PostTradeAmendInstructionRequest {
    return { clientRequestId: '', execId: '0', orderId: '0', instruction: '', orderMetadata: [] };
}

export const PostTradeAmendInstructionRequest = {
    encode(message: PostTradeAmendInstructionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.execId !== '0') {
            writer.uint32(16).int64(message.execId);
        }
        if (message.orderId !== '0') {
            writer.uint32(24).int64(message.orderId);
        }
        if (message.instruction !== '') {
            writer.uint32(34).string(message.instruction);
        }
        for (const v of message.orderMetadata) {
            KeyValue.encode(v!, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): PostTradeAmendInstructionRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePostTradeAmendInstructionRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.execId = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 4:
                    message.instruction = reader.string();
                    break;
                case 5:
                    message.orderMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<PostTradeAmendInstructionRequest>): PostTradeAmendInstructionRequest {
        const message = createBasePostTradeAmendInstructionRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.execId = object.execId ?? '0';
        message.orderId = object.orderId ?? '0';
        message.instruction = object.instruction ?? '';
        message.orderMetadata = object.orderMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        return message;
    }
};

function createBaseCreateFxListOrder(): CreateFxListOrder {
    return { clientRequestId: '', listID: '', contingencyType: 1, strategyOrders: [] };
}

export const CreateFxListOrder = {
    encode(message: CreateFxListOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.listID !== '') {
            writer.uint32(26).string(message.listID);
        }
        writer.uint32(32).int32(message.contingencyType);
        for (const v of message.strategyOrders) {
            CreateFxOrderRequest.encode(v!, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateFxListOrder {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateFxListOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 3:
                    message.listID = reader.string();
                    break;
                case 4:
                    message.contingencyType = reader.int32() as any;
                    break;
                case 5:
                    message.strategyOrders.push(CreateFxOrderRequest.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateFxListOrder>): CreateFxListOrder {
        const message = createBaseCreateFxListOrder();
        message.clientRequestId = object.clientRequestId ?? '';
        message.listID = object.listID ?? '';
        message.contingencyType = object.contingencyType ?? 1;
        message.strategyOrders = object.strategyOrders?.map((e) => CreateFxOrderRequest.fromPartial(e)) || [];
        return message;
    }
};

function createBaseCreateFxSspOrder(): CreateFxSspOrder {
    return { clientRequestId: '', listID: '', netSpotOrder: undefined, subOrders: [] };
}

export const CreateFxSspOrder = {
    encode(message: CreateFxSspOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.listID !== '') {
            writer.uint32(18).string(message.listID);
        }
        if (message.netSpotOrder !== undefined) {
            CreateFxOrderRequest.encode(message.netSpotOrder, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.subOrders) {
            CreateFxOrderRequest.encode(v!, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateFxSspOrder {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateFxSspOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.listID = reader.string();
                    break;
                case 3:
                    message.netSpotOrder = CreateFxOrderRequest.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.subOrders.push(CreateFxOrderRequest.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateFxSspOrder>): CreateFxSspOrder {
        const message = createBaseCreateFxSspOrder();
        message.clientRequestId = object.clientRequestId ?? '';
        message.listID = object.listID ?? '';
        message.netSpotOrder =
            object.netSpotOrder !== undefined && object.netSpotOrder !== null
                ? CreateFxOrderRequest.fromPartial(object.netSpotOrder)
                : undefined;
        message.subOrders = object.subOrders?.map((e) => CreateFxOrderRequest.fromPartial(e)) || [];
        return message;
    }
};

function createBaseCancelTradeRequest(): CancelTradeRequest {
    return { clientRequestId: '', id: '0', orderId: '0' };
}

export const CancelTradeRequest = {
    encode(message: CancelTradeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.id !== '0') {
            writer.uint32(16).int64(message.id);
        }
        if (message.orderId !== '0') {
            writer.uint32(24).int64(message.orderId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CancelTradeRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCancelTradeRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CancelTradeRequest>): CancelTradeRequest {
        const message = createBaseCancelTradeRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.id = object.id ?? '0';
        message.orderId = object.orderId ?? '0';
        return message;
    }
};

function createBaseOrderStatusRequest(): OrderStatusRequest {
    return { clientRequestId: '', clOrderId: '', orderId: '0' };
}

export const OrderStatusRequest = {
    encode(message: OrderStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.clOrderId !== '') {
            writer.uint32(18).string(message.clOrderId);
        }
        if (message.orderId !== '0') {
            writer.uint32(24).int64(message.orderId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): OrderStatusRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderStatusRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.clOrderId = reader.string();
                    break;
                case 3:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<OrderStatusRequest>): OrderStatusRequest {
        const message = createBaseOrderStatusRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.clOrderId = object.clOrderId ?? '';
        message.orderId = object.orderId ?? '0';
        return message;
    }
};

function createBasePostTradeAggregationRequest(): PostTradeAggregationRequest {
    return { clientRequestId: '', clientReportId: '', orderIdData: [], account: '', commission: 0, reason: '' };
}

export const PostTradeAggregationRequest = {
    encode(message: PostTradeAggregationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.clientReportId !== '') {
            writer.uint32(18).string(message.clientReportId);
        }
        for (const v of message.orderIdData) {
            OrderIdData.encode(v!, writer.uint32(26).fork()).ldelim();
        }
        if (message.account !== '') {
            writer.uint32(34).string(message.account);
        }
        if (message.commission !== 0) {
            writer.uint32(41).double(message.commission);
        }
        if (message.reason !== '') {
            writer.uint32(50).string(message.reason);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): PostTradeAggregationRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePostTradeAggregationRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.clientReportId = reader.string();
                    break;
                case 3:
                    message.orderIdData.push(OrderIdData.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.account = reader.string();
                    break;
                case 5:
                    message.commission = reader.double();
                    break;
                case 6:
                    message.reason = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<PostTradeAggregationRequest>): PostTradeAggregationRequest {
        const message = createBasePostTradeAggregationRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.clientReportId = object.clientReportId ?? '';
        message.orderIdData = object.orderIdData?.map((e) => OrderIdData.fromPartial(e)) || [];
        message.account = object.account ?? '';
        message.commission = object.commission ?? 0;
        message.reason = object.reason ?? '';
        return message;
    }
};

function createBaseUpdateOnOrderSnapshotRequest(): UpdateOnOrderSnapshotRequest {
    return { clientRequestId: '' };
}

export const UpdateOnOrderSnapshotRequest = {
    encode(message: UpdateOnOrderSnapshotRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOnOrderSnapshotRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateOnOrderSnapshotRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UpdateOnOrderSnapshotRequest>): UpdateOnOrderSnapshotRequest {
        const message = createBaseUpdateOnOrderSnapshotRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseFindAllStrategyOrderSnapshots(): FindAllStrategyOrderSnapshots {
    return { clientRequestId: '' };
}

export const FindAllStrategyOrderSnapshots = {
    encode(message: FindAllStrategyOrderSnapshots, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAllStrategyOrderSnapshots {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAllStrategyOrderSnapshots();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAllStrategyOrderSnapshots>): FindAllStrategyOrderSnapshots {
        const message = createBaseFindAllStrategyOrderSnapshots();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseUpdateOnOrderMigrationRequest(): UpdateOnOrderMigrationRequest {
    return { clientRequestId: '' };
}

export const UpdateOnOrderMigrationRequest = {
    encode(message: UpdateOnOrderMigrationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOnOrderMigrationRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateOnOrderMigrationRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UpdateOnOrderMigrationRequest>): UpdateOnOrderMigrationRequest {
        const message = createBaseUpdateOnOrderMigrationRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseUpdateOnOrderMigrationGatewayRequest(): UpdateOnOrderMigrationGatewayRequest {
    return { clientRequestId: '' };
}

export const UpdateOnOrderMigrationGatewayRequest = {
    encode(message: UpdateOnOrderMigrationGatewayRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOnOrderMigrationGatewayRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateOnOrderMigrationGatewayRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UpdateOnOrderMigrationGatewayRequest>): UpdateOnOrderMigrationGatewayRequest {
        const message = createBaseUpdateOnOrderMigrationGatewayRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseOrderMigrationGatewayStatusRequest(): OrderMigrationGatewayStatusRequest {
    return { clientRequestId: '' };
}

export const OrderMigrationGatewayStatusRequest = {
    encode(message: OrderMigrationGatewayStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): OrderMigrationGatewayStatusRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderMigrationGatewayStatusRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<OrderMigrationGatewayStatusRequest>): OrderMigrationGatewayStatusRequest {
        const message = createBaseOrderMigrationGatewayStatusRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseOrderMigrationGatewayUpstreamEvent(): OrderMigrationGatewayUpstreamEvent {
    return { clientRequestId: '', active: false };
}

export const OrderMigrationGatewayUpstreamEvent = {
    encode(message: OrderMigrationGatewayUpstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.active === true) {
            writer.uint32(16).bool(message.active);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): OrderMigrationGatewayUpstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderMigrationGatewayUpstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.active = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<OrderMigrationGatewayUpstreamEvent>): OrderMigrationGatewayUpstreamEvent {
        const message = createBaseOrderMigrationGatewayUpstreamEvent();
        message.clientRequestId = object.clientRequestId ?? '';
        message.active = object.active ?? false;
        return message;
    }
};

function createBasePassiveOrderMigrationRequest(): PassiveOrderMigrationRequest {
    return { clientRequestId: '', outboundDestination: '', orderId: [], migrationRequestSource: 0 };
}

export const PassiveOrderMigrationRequest = {
    encode(message: PassiveOrderMigrationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.outboundDestination !== '') {
            writer.uint32(18).string(message.outboundDestination);
        }
        writer.uint32(26).fork();
        for (const v of message.orderId) {
            writer.int64(v);
        }
        writer.ldelim();
        writer.uint32(32).int32(message.migrationRequestSource);
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): PassiveOrderMigrationRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePassiveOrderMigrationRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.outboundDestination = reader.string();
                    break;
                case 3:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.orderId.push(longToString(reader.int64() as Long));
                        }
                    } else {
                        message.orderId.push(longToString(reader.int64() as Long));
                    }
                    break;
                case 4:
                    message.migrationRequestSource = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<PassiveOrderMigrationRequest>): PassiveOrderMigrationRequest {
        const message = createBasePassiveOrderMigrationRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.outboundDestination = object.outboundDestination ?? '';
        message.orderId = object.orderId?.map((e) => e) || [];
        message.migrationRequestSource = object.migrationRequestSource ?? 0;
        return message;
    }
};

function createBaseFindAllMigratedOrderSnapshots(): FindAllMigratedOrderSnapshots {
    return { clientRequestId: '' };
}

export const FindAllMigratedOrderSnapshots = {
    encode(message: FindAllMigratedOrderSnapshots, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAllMigratedOrderSnapshots {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAllMigratedOrderSnapshots();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAllMigratedOrderSnapshots>): FindAllMigratedOrderSnapshots {
        const message = createBaseFindAllMigratedOrderSnapshots();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseUpdateOnBafOrderSnapshotRequest(): UpdateOnBafOrderSnapshotRequest {
    return { clientRequestId: '' };
}

export const UpdateOnBafOrderSnapshotRequest = {
    encode(message: UpdateOnBafOrderSnapshotRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOnBafOrderSnapshotRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateOnBafOrderSnapshotRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UpdateOnBafOrderSnapshotRequest>): UpdateOnBafOrderSnapshotRequest {
        const message = createBaseUpdateOnBafOrderSnapshotRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseFindAllBafOrderSnapshots(): FindAllBafOrderSnapshots {
    return { clientRequestId: '' };
}

export const FindAllBafOrderSnapshots = {
    encode(message: FindAllBafOrderSnapshots, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAllBafOrderSnapshots {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAllBafOrderSnapshots();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAllBafOrderSnapshots>): FindAllBafOrderSnapshots {
        const message = createBaseFindAllBafOrderSnapshots();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseForceFillOrderRequest(): ForceFillOrderRequest {
    return { clientRequestId: '', orderId: '0', priceToFillAt: 0 };
}

export const ForceFillOrderRequest = {
    encode(message: ForceFillOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.orderId !== '0') {
            writer.uint32(16).int64(message.orderId);
        }
        if (message.priceToFillAt !== 0) {
            writer.uint32(25).double(message.priceToFillAt);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): ForceFillOrderRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseForceFillOrderRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.priceToFillAt = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<ForceFillOrderRequest>): ForceFillOrderRequest {
        const message = createBaseForceFillOrderRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.orderId = object.orderId ?? '0';
        message.priceToFillAt = object.priceToFillAt ?? 0;
        return message;
    }
};

function createBaseForceCancelOrderRequest(): ForceCancelOrderRequest {
    return { clientRequestId: '', orderId: '0' };
}

export const ForceCancelOrderRequest = {
    encode(message: ForceCancelOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.orderId !== '0') {
            writer.uint32(16).int64(message.orderId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): ForceCancelOrderRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseForceCancelOrderRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<ForceCancelOrderRequest>): ForceCancelOrderRequest {
        const message = createBaseForceCancelOrderRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.orderId = object.orderId ?? '0';
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
