import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { selectNotifications, setNotificationsVisited } from '@/state/reducers/notificationSlice';
import { selectGeneralSettings } from '@/state/reducers/settingSlice';
import { formatNotification, formatUTCNotification } from '@/utils/format';
import { useDebouncedValue } from '@/utils/hooks/useDebouncedValue';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import useScreenSizes from '@/utils/hooks/useScreenSizes';
import { CSSProperties, useEffect, useState } from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { CheckMarkIcon, ErrorIcon } from '../common/Icons';
import NotificationPreviewModal from '../modal/NotificationsModal/NotificationPreviewModal';

interface NotificationsProps {
    onClick?: () => void;
}

export default function Notifications(props: NotificationsProps) {
    const dispatch = useAppDispatch();
    const notifications = useAppSelector(selectNotifications);
    const generalSettings = useAppSelector(selectGeneralSettings);

    const screen = useScreenSizes();
    const disclosure = useDisclosure(false);
    const [debouncedNotifications] = useDebouncedValue(notifications, 1000);

    const [selectedNotification, setSelectedNotification] = useState<any>(null);

    useEffect(() => {
        dispatch(setNotificationsVisited(true));
    }, [debouncedNotifications]);

    const VirtualizedNotification = ({ index, style }: ListItemProps) => {
        const notification = notifications[index];
        return (
            <div
                style={style}
                className="flex gap-3 bg-brand-background-dark text-neutral-200 p-2 border-b border-neutral-700"
                onClick={() => {
                    setSelectedNotification(notification);
                    disclosure[1].open();
                }}>
                <span className="mt-1">
                    <NotificationIcon type={notification.type} />
                </span>
                <div className="flex flex-col w-full gap-1 text-xs sm:text-sm">
                    <div className="flex w-full justify-between gap-2">
                        <div className="font-semibold line-clamp-2">{notification.title}</div>
                        <div className="text-2xs sm:text-xs text-neutral-400 whitespace-nowrap flex flex-col items-end">
                            <span>
                                {generalSettings.timezone.value === 'UTC'
                                    ? formatUTCNotification(notification.created_at)
                                    : formatNotification(notification.created_at)}
                            </span>
                            <span className="text-2xs">( {generalSettings.timezone.label} )</span>
                        </div>
                    </div>

                    <div className="text-neutral-400 line-clamp-2">{notification.body}</div>
                </div>
            </div>
        );
    };

    return (
        <div className="h-full w-full flex flex-col">
            <AutoSizer>
                {({ height, width }) => (
                    <FixedSizeList
                        height={height}
                        width={width}
                        itemCount={notifications.length}
                        itemSize={screen.mobile ? 85 : 100}>
                        {VirtualizedNotification}
                    </FixedSizeList>
                )}
            </AutoSizer>
            {notifications.length === 0 && (
                <div className="flex h-full items-center justify-center text-neutral-500">No notifications</div>
            )}
            {selectedNotification && (
                <NotificationPreviewModal opened={disclosure[0]} handlers={disclosure[1]} {...selectedNotification} />
            )}
        </div>
    );
}

export const NotificationIcon = ({ type }: any) => {
    switch (type) {
        case 'info':
            return <BsInfoCircle />;
        case 'success':
            return <CheckMarkIcon />;
        case 'error':
            return <ErrorIcon />;
        default:
            return <CheckMarkIcon />;
    }
};

export interface ListItemProps {
    index: number;
    style: CSSProperties;
}
