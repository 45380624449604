import cn from 'classnames';

interface DividerProps {
    className?: string;
}

const Divider = ({ className }: DividerProps) => (
    <hr
        className={cn('w-full border-none h-[1px] m-0 flex-shrink-0', className, {
            ['bg-brand-border']: !className
        })}
    />
);

export default Divider;
