import { Tab } from '@headlessui/react';

import HistoricSearchModal from '@/components/modal/HistoricSearchModal';
import OrderBlotterTable from '@/components/modules/Blotters/OrderBlotterTable';
import TradeBlotterTable from '@/components/modules/Blotters/TradeBlotterTable';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import cn from 'classnames';
import OpenPositionTable, { OpenPositionRow } from './OpenPositionTable';

const tabs = ['Orders', 'Trades', 'Open Positions'];

interface TraderBlottersProps {
    setCSVData: React.Dispatch<React.SetStateAction<any[]>>;
    onOrderModal: (row: OpenPositionRow) => void;
}

export default function Blotters({ setCSVData, onOrderModal }: TraderBlottersProps) {
    const [opened, handlers] = useDisclosure(false);
    return (
        <div className="w-full h-full flex flex-col">
            <HistoricSearchModal opened={opened} handlers={handlers} />
            <Tab.Group defaultIndex={2}>
                <div className="flex flex-auto flex-row items-center space-x-1 w-full">
                    <Tab.List className="flex bg-brand-background border-b-[1px] border-b-neutral-700 w-full">
                        {tabs.map((tab) => (
                            <Tab
                                key={tab}
                                className={({ selected }) =>
                                    cn('p-1 px-2 text-sm font-medium leading-5 text-neutral-200 focus:outline-none', {
                                        'border-b-[1px] bg-brand-background-dark border-b-brand-border': selected,
                                        'text-neutral-400 hover:bg-white/[0.12] hover:text-neutral-200': !selected
                                    })
                                }>
                                {tab}
                            </Tab>
                        ))}
                    </Tab.List>
                </div>
                <Tab.Panels className="relative self-stretch grow basis-full p-2 overflow-hidden">
                    <Tab.Panel className="h-full">
                        <OrderBlotterTable setCSVData={setCSVData} />
                    </Tab.Panel>
                    <Tab.Panel className="h-full">
                        <TradeBlotterTable setCSVData={setCSVData} />
                    </Tab.Panel>
                    <Tab.Panel className="h-full">
                        <OpenPositionTable setCSVData={setCSVData} onOrderModal={onOrderModal} />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
}
