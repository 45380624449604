import { useMediaQuery } from './useMediaQuery';

type ScreenList = 'mobile' | 'tablet' | 'laptop' | 'desktop';
export interface UseScreenSizesReturn {
    current: ScreenList;
    mobile?: boolean;
    tablet?: boolean;
    desktop?: boolean;
    laptop?: boolean;
}

const useScreenSizes = (): UseScreenSizesReturn => {
    const mobile = useMediaQuery('(max-width: 639px)');
    const tablet = useMediaQuery('(min-width: 640px) and (max-width: 1023px)');
    const laptop = useMediaQuery('(min-width: 1024px) and (max-width: 1359px)');
    const desktop = useMediaQuery('(min-width: 1360px)');

    const screenList = [mobile, tablet, laptop, desktop];
    const screenListString: ScreenList[] = ['mobile', 'tablet', 'laptop', 'desktop'];

    return {
        current: screenListString[screenList.findIndex((screen) => screen)],
        mobile,
        tablet,
        desktop,
        laptop
    };
};

export default useScreenSizes;
