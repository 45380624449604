/* eslint-disable */
import { AssetType } from '../enums/AssetTypeProto';
import { ProductType } from '../enums/ProductTypeProto';
import { FxNetPositionCalculationType } from './FxNetPositionCalculationTypeProto';
import Long from 'long';
import { TransactionLeg } from './TransactionLegProto';
import { KeyValue } from './KeyValueProto';
import { SubLedgerSnapshotDownstreamEvent } from '../subledger/DownstreamSubLedgerProto';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.piggybank.api.generalledger';

/** General Ledger Transaction */
export interface GeneralLedgerTransactionDownstreamEvent {
    clientTransactionId: string;
    account: string;
    assetType: AssetType;
    productType: ProductType;
    securityId: string;
    currency: string;
    tradeDate: string;
    createdTimestampUtcInMillis: string;
    updatedTimestampUtcInMillis: string;
    username: string;
    securityCode: string;
    orderId: string;
    cancelled: boolean;
    commission: number;
    transactionLegs: TransactionLeg[];
    metadata: KeyValue[];
    fxWorkflowCalculationType: FxNetPositionCalculationType;
}

export interface TransactionDownstreamEvent {
    generalLedger?: GeneralLedgerTransactionDownstreamEvent;
    subLedger: SubLedgerSnapshotDownstreamEvent[];
}

export interface CancelTransactionDownstreamEvent {
    generalLedger?: GeneralLedgerTransactionDownstreamEvent;
    subLedger: SubLedgerSnapshotDownstreamEvent[];
}

export interface TransactionDownstreamEventsOnTradeDate {
    clientTransactionId: string;
    tradeDate: string;
    transactions: TransactionDownstreamEvent[];
}

function createBaseGeneralLedgerTransactionDownstreamEvent(): GeneralLedgerTransactionDownstreamEvent {
    return {
        clientTransactionId: '0',
        account: '',
        assetType: 1,
        productType: 1,
        securityId: '',
        currency: '',
        tradeDate: '',
        createdTimestampUtcInMillis: '0',
        updatedTimestampUtcInMillis: '0',
        username: '',
        securityCode: '',
        orderId: '0',
        cancelled: false,
        commission: 0,
        transactionLegs: [],
        metadata: [],
        fxWorkflowCalculationType: 1
    };
}

export const GeneralLedgerTransactionDownstreamEvent = {
    encode(message: GeneralLedgerTransactionDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientTransactionId !== '0') {
            writer.uint32(8).int64(message.clientTransactionId);
        }
        if (message.account !== '') {
            writer.uint32(18).string(message.account);
        }
        if (message.assetType !== 1) {
            writer.uint32(24).int32(message.assetType);
        }
        if (message.productType !== 1) {
            writer.uint32(32).int32(message.productType);
        }
        if (message.securityId !== '') {
            writer.uint32(42).string(message.securityId);
        }
        if (message.currency !== '') {
            writer.uint32(74).string(message.currency);
        }
        if (message.tradeDate !== '') {
            writer.uint32(82).string(message.tradeDate);
        }
        if (message.createdTimestampUtcInMillis !== '0') {
            writer.uint32(96).int64(message.createdTimestampUtcInMillis);
        }
        if (message.updatedTimestampUtcInMillis !== '0') {
            writer.uint32(104).int64(message.updatedTimestampUtcInMillis);
        }
        if (message.username !== '') {
            writer.uint32(114).string(message.username);
        }
        if (message.securityCode !== '') {
            writer.uint32(122).string(message.securityCode);
        }
        if (message.orderId !== '0') {
            writer.uint32(128).int64(message.orderId);
        }
        if (message.cancelled === true) {
            writer.uint32(136).bool(message.cancelled);
        }
        if (message.commission !== 0) {
            writer.uint32(145).double(message.commission);
        }
        for (const v of message.transactionLegs) {
            TransactionLeg.encode(v!, writer.uint32(162).fork()).ldelim();
        }
        for (const v of message.metadata) {
            KeyValue.encode(v!, writer.uint32(170).fork()).ldelim();
        }
        if (message.fxWorkflowCalculationType !== 1) {
            writer.uint32(176).int32(message.fxWorkflowCalculationType);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): GeneralLedgerTransactionDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGeneralLedgerTransactionDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientTransactionId = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.account = reader.string();
                    break;
                case 3:
                    message.assetType = reader.int32() as any;
                    break;
                case 4:
                    message.productType = reader.int32() as any;
                    break;
                case 5:
                    message.securityId = reader.string();
                    break;
                case 9:
                    message.currency = reader.string();
                    break;
                case 10:
                    message.tradeDate = reader.string();
                    break;
                case 12:
                    message.createdTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 13:
                    message.updatedTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 14:
                    message.username = reader.string();
                    break;
                case 15:
                    message.securityCode = reader.string();
                    break;
                case 16:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 17:
                    message.cancelled = reader.bool();
                    break;
                case 18:
                    message.commission = reader.double();
                    break;
                case 20:
                    message.transactionLegs.push(TransactionLeg.decode(reader, reader.uint32()));
                    break;
                case 21:
                    message.metadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 22:
                    message.fxWorkflowCalculationType = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<GeneralLedgerTransactionDownstreamEvent>): GeneralLedgerTransactionDownstreamEvent {
        const message = createBaseGeneralLedgerTransactionDownstreamEvent();
        message.clientTransactionId = object.clientTransactionId ?? '0';
        message.account = object.account ?? '';
        message.assetType = object.assetType ?? 1;
        message.productType = object.productType ?? 1;
        message.securityId = object.securityId ?? '';
        message.currency = object.currency ?? '';
        message.tradeDate = object.tradeDate ?? '';
        message.createdTimestampUtcInMillis = object.createdTimestampUtcInMillis ?? '0';
        message.updatedTimestampUtcInMillis = object.updatedTimestampUtcInMillis ?? '0';
        message.username = object.username ?? '';
        message.securityCode = object.securityCode ?? '';
        message.orderId = object.orderId ?? '0';
        message.cancelled = object.cancelled ?? false;
        message.commission = object.commission ?? 0;
        message.transactionLegs = object.transactionLegs?.map((e) => TransactionLeg.fromPartial(e)) || [];
        message.metadata = object.metadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.fxWorkflowCalculationType = object.fxWorkflowCalculationType ?? 1;
        return message;
    }
};

function createBaseTransactionDownstreamEvent(): TransactionDownstreamEvent {
    return { generalLedger: undefined, subLedger: [] };
}

export const TransactionDownstreamEvent = {
    encode(message: TransactionDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.generalLedger !== undefined) {
            GeneralLedgerTransactionDownstreamEvent.encode(message.generalLedger, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.subLedger) {
            SubLedgerSnapshotDownstreamEvent.encode(v!, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): TransactionDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTransactionDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.generalLedger = GeneralLedgerTransactionDownstreamEvent.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.subLedger.push(SubLedgerSnapshotDownstreamEvent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<TransactionDownstreamEvent>): TransactionDownstreamEvent {
        const message = createBaseTransactionDownstreamEvent();
        message.generalLedger =
            object.generalLedger !== undefined && object.generalLedger !== null
                ? GeneralLedgerTransactionDownstreamEvent.fromPartial(object.generalLedger)
                : undefined;
        message.subLedger = object.subLedger?.map((e) => SubLedgerSnapshotDownstreamEvent.fromPartial(e)) || [];
        return message;
    }
};

function createBaseCancelTransactionDownstreamEvent(): CancelTransactionDownstreamEvent {
    return { generalLedger: undefined, subLedger: [] };
}

export const CancelTransactionDownstreamEvent = {
    encode(message: CancelTransactionDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.generalLedger !== undefined) {
            GeneralLedgerTransactionDownstreamEvent.encode(message.generalLedger, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.subLedger) {
            SubLedgerSnapshotDownstreamEvent.encode(v!, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CancelTransactionDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCancelTransactionDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.generalLedger = GeneralLedgerTransactionDownstreamEvent.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.subLedger.push(SubLedgerSnapshotDownstreamEvent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CancelTransactionDownstreamEvent>): CancelTransactionDownstreamEvent {
        const message = createBaseCancelTransactionDownstreamEvent();
        message.generalLedger =
            object.generalLedger !== undefined && object.generalLedger !== null
                ? GeneralLedgerTransactionDownstreamEvent.fromPartial(object.generalLedger)
                : undefined;
        message.subLedger = object.subLedger?.map((e) => SubLedgerSnapshotDownstreamEvent.fromPartial(e)) || [];
        return message;
    }
};

function createBaseTransactionDownstreamEventsOnTradeDate(): TransactionDownstreamEventsOnTradeDate {
    return { clientTransactionId: '0', tradeDate: '', transactions: [] };
}

export const TransactionDownstreamEventsOnTradeDate = {
    encode(message: TransactionDownstreamEventsOnTradeDate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientTransactionId !== '0') {
            writer.uint32(8).int64(message.clientTransactionId);
        }
        if (message.tradeDate !== '') {
            writer.uint32(18).string(message.tradeDate);
        }
        for (const v of message.transactions) {
            TransactionDownstreamEvent.encode(v!, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): TransactionDownstreamEventsOnTradeDate {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTransactionDownstreamEventsOnTradeDate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientTransactionId = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.tradeDate = reader.string();
                    break;
                case 3:
                    message.transactions.push(TransactionDownstreamEvent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<TransactionDownstreamEventsOnTradeDate>): TransactionDownstreamEventsOnTradeDate {
        const message = createBaseTransactionDownstreamEventsOnTradeDate();
        message.clientTransactionId = object.clientTransactionId ?? '0';
        message.tradeDate = object.tradeDate ?? '';
        message.transactions = object.transactions?.map((e) => TransactionDownstreamEvent.fromPartial(e)) || [];
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
