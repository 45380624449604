import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastProps } from '../../utils/hooks/useToast';
import { RootState } from '../store';

export interface Notification extends Pick<ToastProps, 'title' | 'body'> {
    type: 'success' | 'error' | 'warning' | 'info';
    created_at: Date;
}

export interface NotificationState {
    notifications: Notification[];
    visited: boolean;
    opened: boolean;
}

const initialState: NotificationState = {
    notifications: [],
    visited: true,
    opened: false
};

export const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNotifications: (state, action: PayloadAction<NotificationState>) => {
            state.notifications = action.payload.notifications;
        },
        pushNotification: (state, action: PayloadAction<Notification>) => {
            state.notifications = [action.payload, ...state.notifications];
            if (state.visited) state.visited = false;
        },
        setNotificationsVisited: (state, action: PayloadAction<boolean>) => {
            state.visited = action.payload;
        },
        openNotifications: (state) => {
            state.opened = true;
        },
        closeNotifications: (state) => {
            state.opened = false;
        },
        toggleNotifications: (state) => {
            state.opened = !state.opened;
        }
    }
});

export const {
    setNotifications,
    pushNotification,
    setNotificationsVisited,
    openNotifications,
    closeNotifications,
    toggleNotifications
} = notificationSlice.actions;

export const selectNotifications = (state: RootState) => state.notifications.notifications;
export const selectNotificationsOpened = (state: RootState) => state.notifications.opened;
export const selectNotificationsVisited = (state: RootState) => state.notifications.visited;

export default notificationSlice.reducer;
