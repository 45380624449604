import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';

import { Button } from '@/components/common/Button';
import Divider from '@/components/common/Divider';
import {
    FiatWithdrawalFormInput,
    FiatWithdrawalFormValues,
    fiatWithdrawalSchema
} from '@/components/form/schema/fiatWithdrawalSchema';
import FilterSelect from '@/components/inputs/FilterSelect';
import Input from '@/components/inputs/Input';
import InputController from '@/components/inputs/InputController';
import Select from '@/components/inputs/Select';
import currencyConfig from '@/config/currency';
import { requestFiatWithdrawal } from '@/services/EmailService';
import { useAppSelector } from '@/state/hooks';
import { selectCredentials } from '@/state/reducers/authSlice';
import { CollapsedBalances } from '@/state/reducers/balanceSlice';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import useToast from '@/utils/hooks/useToast';
import { Tab } from '@headlessui/react';
import { AxiosError } from 'axios';
import { ModalProps } from '../Modal';
import WithdrawalConfirmationModal from '../WithdrawalConfirmationModal';
import { getLatestAccountBalance } from './CryptoWithdrawalTab';

const disclaimer = window.config?.modules?.withdrawal?.fiat?.disclaimer;

export const defaultAccount = { label: 'Select an account', value: undefined };
export const defaultCurrency = { label: 'Select a currency', value: undefined };

const fiatCurrency = [
    defaultCurrency,
    ...currencyConfig
        .filter((ccy) => ccy.type === 'Fiat' && ccy.allowDepositWithdrawal)
        .map((ccy) => ({
            label: ccy.show,
            value: ccy.show
        }))
];

const defaultValues: Partial<FiatWithdrawalFormInput> = {
    account: defaultAccount,
    amount: undefined,
    currency: defaultCurrency,
    bank: {
        beneficiary_name: '',
        swift_bic: '',
        clearing_code: '',
        account_name: '',
        account_number_iban: '',
        account_currency: defaultCurrency
    },
    intermediary_bank: undefined,
    email: undefined
};

const FiatWithdrawalTab = (props: ModalProps) => {
    const { opened, handlers } = props;

    const credentials = useAppSelector(selectCredentials);
    const confirmation = useDisclosure(false);
    const [toastError, toastSuccess] = useToast();

    const [balances, setBalances] = useState<CollapsedBalances[]>();

    const form = useForm<FiatWithdrawalFormInput>({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(fiatWithdrawalSchema(balances))
    });

    const {
        reset,
        watch,
        trigger,
        handleSubmit,
        formState: { isValid, isSubmitting }
    } = form;

    const [account, currency] = watch(['account', 'currency']);

    const onSubmit = async (data: FiatWithdrawalFormInput, e) => {
        const dataValidated = data as FiatWithdrawalFormValues;
        try {
            if (credentials) {
                await requestFiatWithdrawal(credentials, dataValidated);
                const title = `Fiat Withdrawal Request`;
                const body = `Your request to withdraw ${dataValidated.currency.label} ${dataValidated.amount} has been submitted`;
                toastSuccess({ body, title });
            }
        } catch (err: any) {
            const { response } = err as AxiosError<string>;
            const title = response?.statusText;
            const body = response?.data;
            toastError({ body, title, persist: true });
        }
    };

    const onError = (e: FieldErrors) => console.log(e);

    const accounts = useMemo(() => {
        const temp = credentials?.accounts?.map((account) => ({
            label: account.code,
            value: account.code
        }));
        if (temp) return [defaultAccount, ...temp];
        return [defaultAccount];
    }, [credentials]);

    useEffect(() => {
        if (account?.value && credentials) {
            getLatestAccountBalance(credentials, account.value, (balances) => setBalances(balances));
        } else {
            setBalances(undefined);
        }
    }, [account, credentials]);

    useEffect(() => {
        if (opened) reset(defaultValues);
    }, [opened]);

    return (
        <Tab.Panel className="h-full">
            <FormProvider {...form}>
                <form className="h-full flex flex-col lg:block" onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="flex-1 basis-0 lg:flex-auto p-2 py-3 sm:p-4 h-auto lg:h-[450px] overflow-y-scroll">
                        <div className="flex flex-col gap-4">
                            <div className="space-y-3 sm:space-y-2">
                                <h3 className="font-bold">Withdrawal Details</h3>
                                <InputController
                                    name="account"
                                    label="Account To Withdraw"
                                    onChangeOverride={() => {
                                        if (account?.value && currency?.value) trigger('amount');
                                    }}
                                    required>
                                    <Select options={accounts || []} />
                                </InputController>
                                <InputController
                                    name="currency"
                                    label="Withdrawal Currency"
                                    onChangeOverride={() => {
                                        if (account?.value && currency?.value) trigger('amount');
                                    }}
                                    required>
                                    <FilterSelect options={fiatCurrency} />
                                </InputController>
                                <InputController
                                    name="amount"
                                    label="Withdrawal Amount"
                                    placeholder="Withdrawal Amount"
                                    disabled={!(account?.value && currency?.value)}
                                    required>
                                    <Input />
                                </InputController>
                            </div>
                            <div className="space-y-3 sm:space-y-2">
                                <h3 className="font-bold">Bank Details</h3>

                                <InputController
                                    name="bank.beneficiary_name"
                                    label="Beneficiary Bank Name"
                                    placeholder="Beneficiary Bank Name"
                                    required>
                                    <Input />
                                </InputController>
                                <InputController
                                    name="bank.swift_bic"
                                    label="SWIFT ID / BIC"
                                    placeholder="SWIFT ID / BIC"
                                    required>
                                    <Input />
                                </InputController>
                                <InputController
                                    name="bank.clearing_code"
                                    label="Clearing Code"
                                    placeholder="Clearing Code (Optional)">
                                    <Input />
                                </InputController>
                                <InputController
                                    name="bank.account_name"
                                    label="Beneficiary Name"
                                    placeholder="Beneficiary Name"
                                    required>
                                    <Input />
                                </InputController>
                                <InputController
                                    name="bank.account_number_iban"
                                    label="Account Number / IBAN"
                                    placeholder="Account Number / IBAN"
                                    required>
                                    <Input />
                                </InputController>
                                <InputController
                                    name="bank.account_currency"
                                    label="Account Currency"
                                    placeholder="Account Currency"
                                    required>
                                    <FilterSelect options={fiatCurrency} />
                                </InputController>
                            </div>
                            <div className="space-y-3 sm:space-y-2">
                                <h3 className="font-bold">Intermediary Bank Details (if applicable)</h3>

                                <InputController
                                    name="intermediary_bank.beneficiary_name"
                                    label="Bank Name"
                                    placeholder="Beneficiary Bank Name">
                                    <Input />
                                </InputController>
                                <InputController
                                    name="intermediary_bank.address"
                                    label="Bank Address"
                                    placeholder="Account Address">
                                    <Input />
                                </InputController>
                                <InputController
                                    name="intermediary_bank.swift_bic"
                                    label="SWIFT ID / BIC"
                                    placeholder="SWIFT ID / BIC">
                                    <Input />
                                </InputController>
                                <InputController
                                    name="intermediary_bank.account_number"
                                    label="Account Number"
                                    placeholder="Account Number">
                                    <Input />
                                </InputController>
                            </div>
                            <div className="space-y-3 sm:space-y-2">
                                <h3 className="font-bold">Email Details (optional)</h3>
                                <InputController name="email.cc_to" label="CC To" placeholder="CC To">
                                    <Input />
                                </InputController>
                            </div>
                            {disclaimer && (
                                <div className="space-y-3 sm:space-y-2">
                                    <h3 className="font-bold">Terms & Conditions</h3>
                                    <div className="text-sm text-neutral-400 text-justify">{disclaimer}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <Divider />
                    <div className="flex flex-row justify-between text-neutral-200 text-sm items-center p-2 sm:p-4">
                        <div className="flex justify-center w-full">
                            <Button
                                type="button"
                                onClick={confirmation[1].open}
                                isLoading={isSubmitting}
                                disabled={!isValid || isSubmitting}>
                                Send Withdrawal Request
                            </Button>
                        </div>
                    </div>
                    <WithdrawalConfirmationModal
                        type="Fiat"
                        opened={confirmation[0]}
                        handlers={confirmation[1]}
                        user={credentials?.username}
                        keys={Object.keys(defaultValues) as Array<keyof FiatWithdrawalFormValues>}
                        manualOnSubmit={handleSubmit(onSubmit, onError)}
                    />
                </form>
            </FormProvider>
        </Tab.Panel>
    );
};

export default FiatWithdrawalTab;
