import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface PairMap {
    celer: string;
    netdania: string;
    show: string;
}

export interface TickerState {
    activeTicker: PairMap;
}

const initialState: TickerState = {
    activeTicker: {
        celer: 'BTC/USD',
        netdania: 'BTC/USD',
        show: 'BTC/USD'
    }
};

export const tickerSlice = createSlice({
    name: 'ticker',
    initialState,
    reducers: {
        setActiveTicker: (state, action: PayloadAction<PairMap>) => {
            state.activeTicker = action.payload;
        }
    }
});

export const { setActiveTicker } = tickerSlice.actions;
export const selectActiveTicker = (state: RootState) => state.ticker.activeTicker;

export default tickerSlice.reducer;
