import { Tab } from '@headlessui/react';
import InputController from '../../inputs/InputController';
import Select from '../../inputs/Select';

export const notificationDurationOptions = [
    { label: 'User Click', value: Infinity },
    { label: '5 Seconds', value: 5000 },
    { label: '10 Seconds', value: 10000 },
    { label: '30 Seconds', value: 30000 },
    { label: '60 Seconds', value: 60000 }
];

export const notificationShowOptions = [
    { label: 'Enabled', value: true },
    { label: 'Disabled', value: false }
];

const NotificationsTab = () => {
    return (
        <Tab.Panel className="p-2 py-3 sm:p-4 h-auto lg:h-[400px]">
            <div className="space-y-4">
                {/* <InputController name="notifications.duration" label="Hide notifications after" required>
                    <Select options={notificationDurationOptions} />
                </InputController> */}
                <InputController name="notifications.show" label="Show notifications" required>
                    <Select options={notificationShowOptions} />
                </InputController>
            </div>
        </Tab.Panel>
    );
};

export default NotificationsTab;
