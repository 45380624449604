import { useAppDispatch } from '@/state/hooks';
import { TraderMarket, arrangeWatchList } from '@/state/reducers/traderMarketSlice';
import { UseDisclosureReturn } from '@/utils/hooks/useDisclosure';
import { DndContext, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy } from '@dnd-kit/sortable';
import { Dispatch, SetStateAction, useCallback } from 'react';
import TickerWidget from './TickerWidget';

interface DndTickersProps {
    items: { id: number; market: TraderMarket }[];
    setItems: Dispatch<
        SetStateAction<
            {
                id: number;
                market: TraderMarket;
            }[]
        >
    >;
    orderDisclosure: UseDisclosureReturn;
    watchList: any;
}

const DndTickers = (props: DndTickersProps) => {
    const { items, setItems, orderDisclosure, watchList } = props;
    const dispatch = useAppDispatch();

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 0.01
            }
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 150,
                tolerance: 5
            }
        })
    );

    const handleDragEnd = useCallback(
        (event) => {
            const { active, over } = event;

            if (active.id !== over.id) {
                const oldIndex = items.findIndex((item) => item.id === active.id);
                const newIndex = items.findIndex((item) => item.id === over.id);

                const sortedItems = arrayMove(items, oldIndex, newIndex);

                dispatch(arrangeWatchList({ id: watchList.id, markets: sortedItems.map((item) => item.market) }));
                setItems(sortedItems);
            }
        },
        [items, setItems]
    );

    return (
        <div className="h-auto flex flex-wrap gap-2 p-2">
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext items={items} strategy={rectSortingStrategy}>
                    {items.map((item) => (
                        <TickerWidget
                            key={item.id}
                            disclosure={orderDisclosure}
                            watchListId={watchList.id}
                            onRemove={() => setItems(items.filter((i) => i.id !== item.id))}
                            {...item}
                        />
                    ))}
                </SortableContext>
            </DndContext>
        </div>
    );
};

export default DndTickers;
