import { format } from 'date-fns';

export const debug = window.config.debug;

export const Logger = ({ title, callback }) => {
    if (debug) {
        const now = new Date();
        const timestamp = format(now, '[HH:mm:ss]');
        console.groupCollapsed(`${timestamp} ${title}`);
        callback();
        console.groupEnd();
    }
};
