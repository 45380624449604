import { WithdrawalType } from '@/components/modal/WithdrawalModal/WithdrawalRequestModal';
import { Nullable } from '@/model/common';
import { InferType, number, object, string } from 'yup';

export const withdrawalRequestSchema = (balance: number, foundEntity?: any) =>
    object({
        withdrawalType: object({ label: string(), value: string() }).required('Please select a withdrawal type.'),
        currencyId: object({ label: string(), value: number() }).required('Deposit Currency cannot be empty!'),
        bankInfoId: object({ label: string(), value: number() }).when('withdrawalType', {
            is: (withdrawalType) => withdrawalType.value === WithdrawalType.FIAT,
            then: object({ label: string(), value: number().required() }).required('Please select a bank account.')
        }),
        walletId: object({ label: string(), value: number() })
            .required('Please select a wallet.')
            .when('withdrawalType', {
                is: (withdrawalType) => withdrawalType.value === WithdrawalType.CRYPTO,
                then: object({ label: string(), value: number().required() }).required('Please select a wallet.')
            }),

        // amount value must be greater than 0
        amount: number()
            .test('amount', 'Withdrawal amount must be more than 0', (value) => {
                return (value || 0) > 0;
            })
            .test('amount', 'Withdrawal amount exceeded current balance', (value) => {
                if (foundEntity?.permissions?.includes('ACCOUNT_VIEW')) {
                    return (value || 0) <= balance;
                } else {
                    return true;
                }
            })
            .required('Amount cannot be empty!'),
        requestDescription: string()
    });

export type WithdrawalRequestFormValues = InferType<ReturnType<typeof withdrawalRequestSchema>>;
export type WithdrawalRequestFormInput = Nullable<WithdrawalRequestFormValues>;
