import { InferType, number, object, string } from 'yup';
import { Nullable } from '../../../model/common';
import { QuantitySchemaParams } from './common';

interface TickerOrderSchemaParams extends QuantitySchemaParams {}

export const tickerOrderSchema = (params: TickerOrderSchemaParams) => {
    const { currencyPair, currencyOut, order_decimals, min_order_size } = params;
    return object({
        side: number().required('Invalid Input: Please input a side.'),
        bestPrice: number().required('Invalid Input: Please input a price.'),
        quantity: number()
            .min(min_order_size, `Invalid input: The minimum order quantity is ${min_order_size} ${currencyOut}`)
            .test(
                'maxDigitsAfterDecimal',
                `Invalid Input: The maximum allowed number of decimals for ${currencyPair} is ${order_decimals}`,
                (number) => {
                    const regex = new RegExp(`^\\d+(\\.\\d{0,${order_decimals}})?$`, 'gm');
                    return regex.test((number || '').toString());
                }
            )
            .typeError('Invalid Input: Please input a quantity.'),
        orderType: object({ label: string(), value: number() }).required('Order type cannot be empty!'),
        duration: object({ label: string(), value: number() }).required('Duration cannot be empty!'),
        slippage: string()
    });
};
type TickerOrderSchema = ReturnType<typeof tickerOrderSchema>;
export type TickerOrderFormValues = InferType<TickerOrderSchema>;
export type TickerOrderFormInput = Nullable<TickerOrderFormValues>;
