import { chartStudiesTheme, chartTheme } from '@/config/chart';
import { IChartingLibraryWidget } from '@/types/charting_library';
import { CSSVariables } from '@/utils/hooks/useCSSVariables';

export const getLocalChartState = () => {
    const saved_chart = localStorage.getItem('chartState');
    return saved_chart ? JSON.parse(saved_chart) : undefined;
};

export const getCurrentTimeRange = () => {
    const now = new Date().getTime();
    const from = now - 9 * 30 * 24 * 60 * 60;
    return { from, to: now };
};

export const initChartSettings = (chartWidget: IChartingLibraryWidget, variables: CSSVariables) => {
    const visibleRange = getCurrentTimeRange();
    chartWidget.applyOverrides(chartTheme(variables));
    chartWidget.applyStudiesOverrides(chartStudiesTheme(variables));
    // chartWidget.chart().getTimezoneApi().setTimezone('Etc/UTC');
    chartWidget.chart().setVisibleRange(visibleRange);
};
