import { PiggyBankUpdateEvent } from '../../compiled_proto/com/celertech/piggybank/api/PiggyBankServiceProto';
import { SubLedgerSnapshotDownstreamEvent } from '../../compiled_proto/com/celertech/piggybank/api/subledger/DownstreamSubLedgerProto';

export function extractBalanceUpdate(subledgerEvent: SubLedgerSnapshotDownstreamEvent) {
    return {
        currency: subledgerEvent.currency,
        netPosition: subledgerEvent.netPosition,
        notionalCurrency: subledgerEvent.notionalCurrency,
        notionalNetPosition: subledgerEvent.notionalNetPosition,
        settlementDate: subledgerEvent.settlementDate
    };
}
