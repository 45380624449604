import { setActivePair } from '@/state/reducers/marketPairSlice';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import buildJson from '../config/build.json';
import useInterval from '../utils/hooks/useInterval';
import useToast from '../utils/hooks/useToast';

const prefix = window.config?.name ? `${window.config.name}_` : '';
const resourcesToReload = ['theme.css', 'config.js'];

export const fetchBuildRev = async () => {
    const buildRevResponse = await axios.get(`/build.json?cb=${Date.now()}`);
    const buildRev = buildRevResponse.data;
    return buildRev['build'];
};

// Function to reload specific resources
const reloadResources = async (buildRev: string) => {
    resourcesToReload.forEach(async (resource: string) => {
        // Append a cache-busting query parameter to the URL to ensure fresh retrieval
        const cacheBustingUrl = `/config/${prefix}${resource}?v=${buildRev}`;
        await axios.get(cacheBustingUrl);
    });
};

const checkInterval = +(window.config.buildrevInterval || 600000);

export default function BuildRevision() {
    const dispatch = useDispatch();
    const [toastError, toastSuccess, toastInfo] = useToast();

    const buildRev = buildJson.build;

    useInterval(
        () => {
            fetchBuildRev().then((latestRev) => {
                reloadResources(latestRev);
                const localVersion = localStorage.getItem('version');
                if (localVersion !== latestRev) {
                    const defaultPair = {
                        celer: 'BTC/USD',
                        netdania: 'BTC/USD',
                        show: 'BTC/USD'
                    };
                    dispatch(setActivePair(defaultPair));
                    localStorage.setItem('version', latestRev);
                }
                if (buildRev !== latestRev) {
                    toastInfo({
                        body: (
                            <div className="mt-1">
                                <div>Please refresh your browser to see the new changes.</div>
                            </div>
                        ),
                        title: 'New version of trader available',
                        // set id to prevent repeated notifications per interval
                        toastConfig: { id: 'Build Revision', duration: Infinity }
                    });
                }
            });
        },
        checkInterval,
        true
    );

    return null;
}
