import HighlightedPips from '@/components/common/HighlightedPips';
import { numberFormatterConfig } from '@/config/config';
import { accumulatePriceBookBidAsks } from '@/helpers/orderbookHelper';
import { useAppSelector } from '@/state/hooks';
import { PairMap } from '@/state/reducers/marketPairSlice';
import { formatNumber } from '@/utils/format';
import useActivePair from '@/utils/hooks/useActivePair';
import { GetPipIndexesReturn, useInstrument } from '@/utils/hooks/useInstrument';
import { PriceLevel } from '@/utils/pricebook';
import BigNumber from 'bignumber.js';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useNumberFormatter } from 'react-aria';

const VerticalBook = () => {
    const activePair = useActivePair();

    const priceBooks = useAppSelector((state) => state.celerMarket.priceBooks[activePair.celer]);
    const { bids, asks } = useMemo(() => accumulatePriceBookBidAsks(priceBooks), [priceBooks, activePair.celer]);

    const { formatPip, formatSpread } = useInstrument(activePair.celer);

    const hasOrderBook = bids && asks && bids[0] && asks[0];

    return (
        <Fragment>
            {!hasOrderBook ? (
                <div className="p-2 italic text-sm text-gray-300">No orderbook data</div>
            ) : (
                <div className="h-full flex flex-col min-w-[300px]">
                    <div className="flex flex-row p-1 px-2 text-gray-300 text-sm w-full border-b border-b-neutral-700">
                        <div className="flex-[1_1_33%] text-end">Price</div>
                        <div className="flex-[1_0_33%] text-end">Size</div>
                        <div className="flex-[1_0_33%] text-end">Size Acc.</div>
                    </div>
                    <div className="order-book flex-1 basis-0 overflow-y-auto">
                        {asks.map((row, i) => (
                            <OrderBookRow
                                key={i}
                                {...row}
                                type="ask"
                                formatPip={formatPip}
                                totalQuantity={asks[0].accQuantity}
                            />
                        ))}
                        <TopOfBookSpread bids={bids} asks={asks} activePair={activePair} formatSpread={formatSpread} />
                        {bids.map((row, i) => (
                            <OrderBookRow
                                key={i}
                                {...row}
                                type="bid"
                                formatPip={formatPip}
                                totalQuantity={bids[bids.length - 1].accQuantity}
                            />
                        ))}
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default VerticalBook;

interface OrderBookRowProps {
    price: number;
    quantity: number;
    accQuantity: number;
    totalQuantity: number;
    formatPip: (price: number) => GetPipIndexesReturn;
    type: 'ask' | 'bid';
}

const OrderBookRow = ({ price, quantity, accQuantity, totalQuantity, formatPip, type }: OrderBookRowProps) => {
    const sideColor = useMemo(() => (type === 'ask' ? 'bg-brand-primary-dark' : 'bg-brand-red-dark'), [type]);
    const width = useMemo(
        () => BigNumber.maximum(BigNumber(accQuantity).dividedBy(totalQuantity).times(100).toFixed(0), 0),
        [accQuantity, totalQuantity]
    );
    const defaultFormatNumber = useNumberFormatter(numberFormatterConfig);
    const thousandsFormatNumber = useNumberFormatter({ maximumFractionDigits: 0, notation: 'compact' });
    const millionsFormatNumber = useNumberFormatter({ maximumFractionDigits: 1, notation: 'compact' });

    const { price: formattedPrice, pipIndexes } = formatPip(price);

    return (
        <div className="flex text-sm px-2 py-[0.5px] relative group hover:bg-brand-background-dark mt-[1px] text-neutral-300">
            <div className={`flex-[1_1_33%] flex justify-end items-center z-10`}>
                <div className="w-full text-end">
                    <HighlightedPips price={formattedPrice} boldIndexes={pipIndexes} fontSize="text-base" />
                </div>
            </div>
            <div className={`flex-[1_0_33%] flex justify-end items-center z-10`}>
                <div className="w-full text-end lowercase">
                    {formatNumber(quantity, defaultFormatNumber, thousandsFormatNumber, millionsFormatNumber)}
                </div>
            </div>
            <div className={`flex-[1_0_33%] flex justify-end items-center z-10`}>
                <div className="w-full text-end lowercase">
                    {formatNumber(accQuantity, defaultFormatNumber, thousandsFormatNumber, millionsFormatNumber)}
                </div>
            </div>
            <div className={`right-0 top-0 h-full absolute ${sideColor}`} style={{ width: `${width}%` }} />
        </div>
    );
};

interface TopOfBookSpreadProps {
    asks: PriceLevel[];
    bids: PriceLevel[];
    activePair: PairMap;
    formatSpread(spread: BigNumber): string;
}

const TopOfBookSpread = ({ asks, bids, activePair, formatSpread }: TopOfBookSpreadProps) => {
    const [prev, setPrev] = useState({ asks: asks.length, bids: bids.length });

    const spreadRef = useRef<HTMLDivElement | null>(null);
    const spread = useMemo(() => BigNumber(asks[asks.length - 1].price).minus(bids[0].price), [asks, bids]);

    useEffect(() => {
        /*
           Checks if bids and asks returned are different in levels of depth,
           If it is within the non-distruptive* range of depths (in this case < 5), do nothing.
           If difference exceeds acceptable range, scroll TOB-Spread back into view.
        */
        const isNonDistruptiveDifference =
            Math.abs(prev.asks - asks.length) < 5 && Math.abs(prev.bids - bids.length) < 5;
        if (spreadRef.current && !isNonDistruptiveDifference) {
            setPrev({
                asks: asks.length,
                bids: bids.length
            });
            spreadRef.current.scrollIntoView({
                block: 'center'
            });
        }
    }, [spreadRef.current, asks, bids]);

    useEffect(() => {
        // Checks if current active pair is changed, scroll TOB-Spread into view.
        if (spreadRef.current) {
            spreadRef.current.scrollIntoView({
                block: 'center'
            });
        }
    }, [activePair]);

    return (
        <div
            ref={spreadRef}
            className="flex flex-row bg-brand-background-dark text-gray-400 text-sm px-2 justify-end mt-2 mb-2">
            Top of Book Spread (pips): {formatSpread(spread)}
        </div>
    );
};
