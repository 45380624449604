import { useAppSelector } from '@/state/hooks';
import { TraderMarket } from '@/state/reducers/traderMarketSlice';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import { GetColorIndexesReturn, GetPipIndexesReturn, useInstrument } from '@/utils/hooks/useInstrument';
import usePreviousSelector from '@/utils/hooks/usePreviousSelector';
import BigNumber from 'bignumber.js';
import { useMemo, useState } from 'react';

interface PreviousBidAsks {
    bid?: number;
    ask?: number;
}

export interface UseTickerReturn {
    baseCcy: string;
    quoteCcy: string;
    bid: number;
    ask: number;
    formattedBid: 0 | GetPipIndexesReturn;
    formattedAsk: 0 | GetPipIndexesReturn;
    spread: string | undefined;
    askHighlights: GetColorIndexesReturn | undefined;
    bidHighlights: GetColorIndexesReturn | undefined;
}

const useTicker = (market: TraderMarket): UseTickerReturn => {
    const bidAsk = useAppSelector((state) => state.marketPair.bidAsk[market.celer]);
    const prevBidAsk = usePreviousSelector((state) => state.marketPair.bidAsk[market.celer]) as PreviousBidAsks;
    const [askHighlights, setAskHighlights] = useState<GetColorIndexesReturn | undefined>();
    const [bidHighlights, setBidHighlights] = useState<GetColorIndexesReturn | undefined>();

    const { formatPip, formatHighlights, formatSpread } = useInstrument(market.celer);
    const [baseCcy, quoteCcy] = useMemo(() => market.celer.split('/'), [market]);

    // currently displaying bids and asks
    const formattedBid = useMemo(() => bidAsk?.bid && formatPip(bidAsk.bid), [bidAsk?.bid]);
    const formattedAsk = useMemo(() => bidAsk?.ask && formatPip(bidAsk.ask), [bidAsk?.ask]);
    const spread = useMemo(() => {
        if (bidAsk?.bid && prevBidAsk?.bid) return formatSpread(BigNumber(bidAsk?.ask).minus(bidAsk?.bid));
        else return undefined;
    }, [bidAsk?.bid, bidAsk?.ask]);

    // for highlighting changes based on prev price
    useDidUpdate(() => {
        if (bidAsk?.bid && prevBidAsk?.bid)
            setBidHighlights(formatHighlights(prevBidAsk.bid, bidAsk.bid, bidHighlights));
    }, [bidAsk?.bid]);

    useDidUpdate(() => {
        if (bidAsk?.ask && prevBidAsk?.ask)
            setAskHighlights(formatHighlights(prevBidAsk.ask, bidAsk.ask, askHighlights));
    }, [bidAsk?.ask]);

    return {
        baseCcy,
        quoteCcy,
        bid: bidAsk?.bid || 0,
        ask: bidAsk?.ask || 0,
        formattedBid,
        formattedAsk,
        spread,
        askHighlights,
        bidHighlights
    };
};

export default useTicker;
