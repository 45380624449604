/* eslint-disable */
import { AssetType } from '../../enums/AssetTypeProto';
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.staticdata.api.user.limit';

export interface UserLimitDownstreamEvent {
    username: string;
    exchangeCode: string;
    securityCode: string;
    maximumVolume: number;
    positionLimit: number;
    enabled: boolean;
    createdInMillis: string;
    updatedInMillis: string;
    assetType: AssetType;
}

export interface DeleteUserLimitNotification {
    username: string;
    assetType: AssetType;
    exchangeCode: string;
    securityCode: string;
}

function createBaseUserLimitDownstreamEvent(): UserLimitDownstreamEvent {
    return {
        username: '',
        exchangeCode: '',
        securityCode: '',
        maximumVolume: 0,
        positionLimit: 0,
        enabled: false,
        createdInMillis: '0',
        updatedInMillis: '0',
        assetType: 1
    };
}

export const UserLimitDownstreamEvent = {
    encode(message: UserLimitDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.username !== '') {
            writer.uint32(10).string(message.username);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(26).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(42).string(message.securityCode);
        }
        if (message.maximumVolume !== 0) {
            writer.uint32(49).double(message.maximumVolume);
        }
        if (message.positionLimit !== 0) {
            writer.uint32(57).double(message.positionLimit);
        }
        if (message.enabled === true) {
            writer.uint32(64).bool(message.enabled);
        }
        if (message.createdInMillis !== '0') {
            writer.uint32(72).int64(message.createdInMillis);
        }
        if (message.updatedInMillis !== '0') {
            writer.uint32(80).int64(message.updatedInMillis);
        }
        if (message.assetType !== 1) {
            writer.uint32(88).int32(message.assetType);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UserLimitDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUserLimitDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.username = reader.string();
                    break;
                case 3:
                    message.exchangeCode = reader.string();
                    break;
                case 5:
                    message.securityCode = reader.string();
                    break;
                case 6:
                    message.maximumVolume = reader.double();
                    break;
                case 7:
                    message.positionLimit = reader.double();
                    break;
                case 8:
                    message.enabled = reader.bool();
                    break;
                case 9:
                    message.createdInMillis = longToString(reader.int64() as Long);
                    break;
                case 10:
                    message.updatedInMillis = longToString(reader.int64() as Long);
                    break;
                case 11:
                    message.assetType = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UserLimitDownstreamEvent>): UserLimitDownstreamEvent {
        const message = createBaseUserLimitDownstreamEvent();
        message.username = object.username ?? '';
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.maximumVolume = object.maximumVolume ?? 0;
        message.positionLimit = object.positionLimit ?? 0;
        message.enabled = object.enabled ?? false;
        message.createdInMillis = object.createdInMillis ?? '0';
        message.updatedInMillis = object.updatedInMillis ?? '0';
        message.assetType = object.assetType ?? 1;
        return message;
    }
};

function createBaseDeleteUserLimitNotification(): DeleteUserLimitNotification {
    return { username: '', assetType: 1, exchangeCode: '', securityCode: '' };
}

export const DeleteUserLimitNotification = {
    encode(message: DeleteUserLimitNotification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.username !== '') {
            writer.uint32(10).string(message.username);
        }
        if (message.assetType !== 1) {
            writer.uint32(16).int32(message.assetType);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(26).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(34).string(message.securityCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUserLimitNotification {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeleteUserLimitNotification();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.username = reader.string();
                    break;
                case 2:
                    message.assetType = reader.int32() as any;
                    break;
                case 3:
                    message.exchangeCode = reader.string();
                    break;
                case 4:
                    message.securityCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<DeleteUserLimitNotification>): DeleteUserLimitNotification {
        const message = createBaseDeleteUserLimitNotification();
        message.username = object.username ?? '';
        message.assetType = object.assetType ?? 1;
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
