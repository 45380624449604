/* eslint-disable */
export const protobufPackage = 'com.celertech.orderrouting.api.enums.producttype';

export enum ProductType {
    SPOT = 1,
    FORWARD = 2,
    SWAP = 3,
    MONEY_MARKET = 4,
    NON_DELIVERABLE_FORWARD = 5,
    NON_DELIVERABLE_SWAP = 6,
    CFD = 7,
    SHARE = 8,
    OUTRIGHT = 9,
    OPTION = 10,
    /**
     * BITCOIN - CFD_FUTURE = 11;
     * 	CFD_SPOT = 12;
     * 	CFD_SHARE = 13;
     */
    BITCOIN = 14,
    BITCOIN_CASH = 15,
    ETHEREUM = 16,
    LITECOIN = 17,
    OAX = 18,
    RIPPLE = 19,
    CRYPTO_CROSS = 20,
    UNRECOGNIZED = -1
}
