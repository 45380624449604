import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@/components/common/Button';
import CurrencySelect from '@/components/inputs/CurrencySelect';
import { currencyOptions } from '@/helpers/currencyHelper';
import { Modify } from '@/model/common';
import { LiteMarket } from '@/state/reducers/liteMarketSlice';
import { store } from '@/state/store';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import { MdAddCircle } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import Modal, { ModalClose, ModalHeader, ModalTitle } from '../Modal';

interface MarketFormProps {
    items: Modify<LiteMarket, { id: any }>[];
    setItems: (items: Modify<LiteMarket, { id: any }>[]) => void;
}

const isEditMode = false;
const defaultFrom = { label: 'Please select a base currency', ccy: '', value: 'Base ccy' };
const defaultTo = { label: 'Please select a quote currency', ccy: '', value: 'Quote ccy' };

const AddMarketForm = ({ items, setItems }: MarketFormProps) => {
    const [opened, handlers] = useDisclosure(false);
    const fromDiclosure = useDisclosure(false);
    const toDiclosure = useDisclosure(false);

    const [from, onFromChange] = useState(defaultFrom);
    const [to, onToChange] = useState(defaultTo);

    const fromOptions = useMemo(() => {
        const keys = Object.keys(store.getState().marketPair.bidAsk);
        const availableCurrencies = keys.map((key) => key.split('/')[0]);
        return currencyOptions
            .filter((ccy) => availableCurrencies.find((key) => key === ccy.value))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [fromDiclosure[0]]);

    const toOptions = useMemo(() => {
        const keys = Object.keys(store.getState().marketPair.bidAsk);
        const availablePairs = keys.filter((key) => key.split('/')[0] === from.value);
        return currencyOptions
            .filter((ccy) => !items.find((item) => item.name === from.value && item.toName === ccy.value))
            .filter((ccy) => ccy.value !== from.value && availablePairs.find((pair) => pair.includes(ccy.value)))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [toDiclosure[0], fromOptions, from]);

    const onSubmit = useCallback(() => {
        setItems([
            ...items,
            {
                id: uuidv4(),
                name: from.value,
                logo: from.ccy,
                toName: to.value,
                toLogo: to.ccy
            }
        ]);
        onFromChange(defaultFrom);
        onToChange(defaultTo);
        handlers.close();
    }, [items, from, to]);

    useEffect(() => {
        const temp = toOptions[0] || defaultTo;
        onToChange(temp);
    }, [from]);

    useEffect(() => {
        if (opened) {
            onFromChange(defaultFrom);
            onToChange(defaultTo);
        }
    }, [opened]);

    return (
        <div className="text-neutral-200">
            <div
                className="flex w-full group gap-3 p-4 px-3 justify-center items-center cursor-pointer border border-neutral-700 rounded-md text-neutral-400 hover:text-neutral-200"
                onClick={handlers.toggle}>
                <MdAddCircle className="w-6 h-6" />
                <div className="text-xs">
                    <div>Click here to add more currencies to monitor</div>
                    <div className="text-neutral-500 group-hover:text-neutral-300">
                        You may arrange them or remove them any time
                    </div>
                </div>
            </div>
            <Modal opened={opened} handlers={handlers} size="max-w-lg">
                <div className="h-full flex flex-col lg:h-auto gap-4 text-neutral-200">
                    <div className="flex flex-col gap-1 p-6 pb-0">
                        <ModalHeader className="!p-0">
                            <ModalTitle> {isEditMode ? 'Edit Market Monitor' : 'Add Market Monitor'}</ModalTitle>
                            <ModalClose handlers={handlers} />
                        </ModalHeader>
                        <span className="flex items-center text-neutral-400 text-sm">
                            Please select a base currency that you would like to monitor
                        </span>
                    </div>
                    <div className="flex-1 basis-0 lg:flex-auto px-6 h-auto min-h-[300px] flex flex-col gap-3">
                        <div className="space-y-4">
                            <div className="flex flex-col gap-3">
                                <CurrencySelect
                                    selected={from}
                                    onSelectChange={onFromChange}
                                    options={fromOptions}
                                    onClick={fromDiclosure[1].toggle}
                                    disabled={!!from.ccy}
                                />
                                {from.ccy && (
                                    <Fragment>
                                        <span className="text-sm text-neutral-400">
                                            Now select a currency against the base currency
                                        </span>
                                        <CurrencySelect
                                            selected={to}
                                            onSelectChange={onToChange}
                                            options={toOptions}
                                            onClick={toDiclosure[1].toggle}
                                        />
                                        <div
                                            className="cursor-pointer pr-2 -mt-1 text-sm text-right text-neutral-200 hover:text-brand-primary-light"
                                            onClick={() => onFromChange(defaultFrom)}>
                                            Reset Base Currency
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end p-6 pt-0">
                        <div className="flex flex-row justify-between text-neutral-200 text-sm items-center pt-4">
                            <div className="flex justify-end w-full gap-3">
                                <Button className="w-fit bg-neutral-600 hover:bg-neutral-500" onClick={handlers.close}>
                                    Cancel
                                </Button>
                                <Button className="w-fit" onClick={onSubmit} disabled={!from.ccy || !to.ccy}>
                                    {isEditMode ? 'Update' : 'Confirm'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AddMarketForm;

{
    /* <Listbox>
{({ open }) => (
    <div className="relative w-full">
        <Listbox.Button
            className={cn(
                'flex w-full group gap-3 p-4 px-3 justify-center items-center cursor-pointer border border-neutral-700 rounded-md',
                {
                    ['rounded-b-none text-neutral-200']: open,
                    ['text-neutral-400 hover:text-neutral-200']: !open
                }
            )}
            onClick={addition[1].toggle}>
            <MdAddCircle className="w-6 h-6" />
            <div className="text-xs">
                <div>Click here to add more currencies to monitor</div>
                <div
                    className={cn('', {
                        ['text-neutral-300']: open,
                        ['text-neutral-500 group-hover:text-neutral-300']: !open
                    })}>
                    You may arrange them or remove them any time
                </div>
            </div>
        </Listbox.Button>
        <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Listbox.Options className="absolute z-[60] max-h-60 w-full overflow-auto rounded-b-md bg-brand-background-dark border border-t-0 border-neutral-700 py-1 text-sm shadow-lg shadow-neutral-900">
                {marketOptions.map((option, optionIdx) => (
                    <Listbox.Option
                        key={optionIdx}
                        className={({ active }) =>
                            cn(`group relative cursor-pointer select-none p-3 px-4`, {
                                ['bg-brand-primary text-neutral-200']: active
                            })
                        }
                        value={option}
                        onClick={() => {
                            setItems([
                                ...items,
                                {
                                    id: uuidv4(),
                                    name: option.value,
                                    logo: option.ccy
                                }
                            ]);
                        }}>
                        <div className="flex flex-[2_1_0] items-center gap-3 text-neutral-200">
                            <CurrencyIcon ccy={option.ccy} />
                            <div className="flex flex-col">
                                <span className="block truncate text-sm">{option.label}</span>
                                <span className="text-neutral-400 group-hover:text-neutral-200 text-2xs sm:text-xs leading-normal">
                                    {option.value}
                                </span>
                            </div>
                        </div>
                    </Listbox.Option>
                ))}
            </Listbox.Options>
        </Transition>
    </div>
)}
</Listbox> */
}
