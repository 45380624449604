import { Modify } from '@/model/common';
import { useNumberFormatter } from 'react-aria';
import currencyConfig, { CurrencyConfigProps } from '../../config/currency';

export interface UseCurrencyReturn
    extends Modify<
        Partial<CurrencyConfigProps>,
        {
            min_order_size: number;
            order_decimals: number;
            increments: number;
            longName: string;
            show: string;
        }
    > {}

export const useCurrency = (currency: string): UseCurrencyReturn => {
    const config = currencyConfig.find((ccyConfig) => ccyConfig.celer === currency || ccyConfig.show === currency);

    return {
        type: config?.type || 'Crypto',
        min_order_size: config?.min_order_size || 0,
        order_decimals: config?.order_decimals || 0,
        increments: config?.increments || 1,
        longName: config?.longName || '',
        show: config?.show || currency,
        celer: config?.celer || currency
    };
};

export const useCurrencyFormatter = (currency: string): Intl.NumberFormat => {
    const config = currencyConfig.find((ccyConfig) => ccyConfig.celer === currency || ccyConfig.show === currency);
    const order_decimals = config?.order_decimals || 0;

    const numberFormatter = useNumberFormatter({
        minimumFractionDigits: order_decimals,
        maximumFractionDigits: order_decimals
    });

    return numberFormatter;
};

export const getCurrency = (currency: string): UseCurrencyReturn => {
    const config = currencyConfig.find((ccyConfig) => ccyConfig.celer === currency || ccyConfig.show === currency);

    return {
        min_order_size: config?.min_order_size || 0,
        order_decimals: config?.order_decimals || 0,
        increments: config?.increments || 1,
        longName: config?.longName || '',
        show: config?.show || currency,
        celer: config?.celer || currency
    };
};

export const currencyFormatter = (locale: string, currency: string): Intl.NumberFormat => {
    const config = currencyConfig.find((ccyConfig) => ccyConfig.celer === currency || ccyConfig.show === currency);
    const order_decimals = config?.order_decimals || 0;
    return Intl.NumberFormat(locale, {
        minimumFractionDigits: order_decimals,
        maximumFractionDigits: order_decimals
    });
};
