import { getEnvironment } from '@/helpers/environmentHelper';

const env = getEnvironment();

const EnvironmentBadge = () => {
    return (
        <div className="hidden lg:block py-2 cursor-default">
            {env && env !== 'prod' && (
                <div className="py-1 px-4 rounded-md uppercase bg-brand-primary text-neutral-200 font-semibold text-sm">
                    {env}
                </div>
            )}
        </div>
    );
};

export default EnvironmentBadge;
