/* eslint-disable */
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.baseserver.communication.login';

export enum LoginResponseType {
    LOGGED_IN = 1,
    BAD_CREDENTIALS = 2,
    SERVER_NOT_AVAILABLE = 3,
    QR_TOKEN_CODE_REQUIRED = 4,
    UNRECOGNIZED = -1
}

export interface LoginResponse {
    clientRequestId: string;
    username: string;
    loggedIn: boolean;
    message: string;
    sessionToken: string;
    loginResponseType: LoginResponseType;
    acceptedTerms: boolean;
    qrCode: Uint8Array;
}

export interface LogoutMessage {
    clientRequestId: string;
    username: string;
    message: string;
    forcedLogout: boolean;
}

export interface TermsAndConditionsMessage {
    clientRequestId: string;
    termsAndConditionText: string;
    accepted: boolean;
}

function createBaseLoginResponse(): LoginResponse {
    return {
        clientRequestId: '',
        username: '',
        loggedIn: false,
        message: '',
        sessionToken: '',
        loginResponseType: 1,
        acceptedTerms: false,
        qrCode: new Uint8Array()
    };
}

export const LoginResponse = {
    encode(message: LoginResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        if (message.loggedIn === true) {
            writer.uint32(24).bool(message.loggedIn);
        }
        if (message.message !== '') {
            writer.uint32(34).string(message.message);
        }
        if (message.sessionToken !== '') {
            writer.uint32(50).string(message.sessionToken);
        }
        if (message.loginResponseType !== 1) {
            writer.uint32(56).int32(message.loginResponseType);
        }
        if (message.acceptedTerms === true) {
            writer.uint32(64).bool(message.acceptedTerms);
        }
        if (message.qrCode.length !== 0) {
            writer.uint32(74).bytes(message.qrCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): LoginResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLoginResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 3:
                    message.loggedIn = reader.bool();
                    break;
                case 4:
                    message.message = reader.string();
                    break;
                case 6:
                    message.sessionToken = reader.string();
                    break;
                case 7:
                    message.loginResponseType = reader.int32() as any;
                    break;
                case 8:
                    message.acceptedTerms = reader.bool();
                    break;
                case 9:
                    message.qrCode = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<LoginResponse>): LoginResponse {
        const message = createBaseLoginResponse();
        message.clientRequestId = object.clientRequestId ?? '';
        message.username = object.username ?? '';
        message.loggedIn = object.loggedIn ?? false;
        message.message = object.message ?? '';
        message.sessionToken = object.sessionToken ?? '';
        message.loginResponseType = object.loginResponseType ?? 1;
        message.acceptedTerms = object.acceptedTerms ?? false;
        message.qrCode = object.qrCode ?? new Uint8Array();
        return message;
    }
};

function createBaseLogoutMessage(): LogoutMessage {
    return { clientRequestId: '', username: '', message: '', forcedLogout: false };
}

export const LogoutMessage = {
    encode(message: LogoutMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        if (message.message !== '') {
            writer.uint32(26).string(message.message);
        }
        if (message.forcedLogout === true) {
            writer.uint32(32).bool(message.forcedLogout);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): LogoutMessage {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLogoutMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 3:
                    message.message = reader.string();
                    break;
                case 4:
                    message.forcedLogout = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<LogoutMessage>): LogoutMessage {
        const message = createBaseLogoutMessage();
        message.clientRequestId = object.clientRequestId ?? '';
        message.username = object.username ?? '';
        message.message = object.message ?? '';
        message.forcedLogout = object.forcedLogout ?? false;
        return message;
    }
};

function createBaseTermsAndConditionsMessage(): TermsAndConditionsMessage {
    return { clientRequestId: '', termsAndConditionText: '', accepted: false };
}

export const TermsAndConditionsMessage = {
    encode(message: TermsAndConditionsMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.termsAndConditionText !== '') {
            writer.uint32(18).string(message.termsAndConditionText);
        }
        if (message.accepted === true) {
            writer.uint32(24).bool(message.accepted);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): TermsAndConditionsMessage {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTermsAndConditionsMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.termsAndConditionText = reader.string();
                    break;
                case 3:
                    message.accepted = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<TermsAndConditionsMessage>): TermsAndConditionsMessage {
        const message = createBaseTermsAndConditionsMessage();
        message.clientRequestId = object.clientRequestId ?? '';
        message.termsAndConditionText = object.termsAndConditionText ?? '';
        message.accepted = object.accepted ?? false;
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;
