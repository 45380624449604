import { useAppSelector } from '@/state/hooks';
import { selectGeneralSettings } from '@/state/reducers/settingSlice';
import { formatNotification, formatUTCNotification } from '@/utils/format';
import { ReactNode } from 'react';
import { NotificationIcon } from '../../modules/Notifications';
import Modal, { ModalProps } from '../Modal';

interface NotificationPreviewModal extends ModalProps {
    type: string;
    title: string;
    body: string | ReactNode;
    created_at: Date;
}

const NotificationPreviewModal = (props: NotificationPreviewModal) => {
    const { opened, handlers, type, title, body, created_at } = props;

    const generalSettings = useAppSelector(selectGeneralSettings);

    return (
        <Modal {...props} className="h-auto" size="max-w-md" closeOnOverlay>
            <div className="flex gap-3 text-neutral-200 p-3 sm:p-4">
                <span className="mt-1">
                    <NotificationIcon type={type} />
                </span>
                <div className="flex flex-col w-full gap-1 text-sm sm:text-base">
                    <div className="flex w-full justify-between gap-2">
                        <div className="font-semibold">{title}</div>
                        <div className="text-2xs sm:text-sm text-neutral-400 whitespace-nowrap flex flex-col items-end">
                            <span>
                                {generalSettings.timezone.value === 'UTC'
                                    ? formatUTCNotification(created_at)
                                    : formatNotification(created_at)}
                            </span>
                            <span className="text-2xs">( {generalSettings.timezone.label} )</span>
                        </div>
                    </div>
                    <div className="text-neutral-400 whitespace-pre-wrap">{body}</div>
                </div>
            </div>
        </Modal>
    );
};

export default NotificationPreviewModal;
