import { XplorSpotModules } from '@/config/xplorSpotLayout';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MosaicNode } from 'react-mosaic-component';
import { RootState } from '../store';

export interface XplorSpotLayoutState {
    desktopNode?: MosaicNode<XplorSpotModules> | null;
    mobileNode?: MosaicNode<XplorSpotModules> | null;
    tabletNode?: MosaicNode<XplorSpotModules> | null;
    laptopNode?: MosaicNode<XplorSpotModules> | null;
}

export const initialState: XplorSpotLayoutState = {
    mobileNode: undefined,
    tabletNode: undefined,
    laptopNode: undefined,
    desktopNode: undefined
};

export const xplorSpotLayoutSlice = createSlice({
    name: 'xplorSpotLayout',
    initialState,
    reducers: {
        setXplorSpotMobileLayoutNode: (state, action: PayloadAction<MosaicNode<XplorSpotModules> | null>) => {
            state.mobileNode = action.payload;
        },
        setXplorSpotTabletLayoutNode: (state, action: PayloadAction<MosaicNode<XplorSpotModules> | null>) => {
            state.tabletNode = action.payload;
        },
        setXplorSpotLaptopLayoutNode: (state, action: PayloadAction<MosaicNode<XplorSpotModules> | null>) => {
            state.laptopNode = action.payload;
        },
        setXplorSpotDesktopLayoutNode: (state, action: PayloadAction<MosaicNode<XplorSpotModules> | null>) => {
            state.desktopNode = action.payload;
        }
    }
});

export const {
    setXplorSpotMobileLayoutNode,
    setXplorSpotTabletLayoutNode,
    setXplorSpotLaptopLayoutNode,
    setXplorSpotDesktopLayoutNode
} = xplorSpotLayoutSlice.actions;

export const selectXplorSpotMobileLayoutNode = (state: RootState) => state.xplorSpotLayout.mobileNode;
export const selectXplorSpotTabletLayoutNode = (state: RootState) => state.xplorSpotLayout.tabletNode;
export const selectXplorSpotLaptopLayoutNode = (state: RootState) => state.xplorSpotLayout.laptopNode;
export const selectXplorSpotDesktopLayoutNode = (state: RootState) => state.xplorSpotLayout.desktopNode;

export default xplorSpotLayoutSlice.reducer;
