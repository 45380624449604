import { MdClose } from 'react-icons/md';
import { MosaicContext, MosaicWindowContext } from 'react-mosaic-component';

const ModuleWindowControls = ({ screen }: any) => {
    return (
        <div className="h-full flex items-center">
            {(screen.laptop || screen.desktop) && (
                <MosaicContext.Consumer>
                    {({ mosaicActions, blueprintNamespace }) => (
                        <MosaicWindowContext.Consumer>
                            {({ mosaicWindowActions, blueprintNamespace }) => (
                                <div
                                    title="Close Section"
                                    className="cursor-pointer hover:bg-brand-primary h-full px-2 flex items-center"
                                    onClick={() => mosaicActions.remove(mosaicWindowActions.getPath())}>
                                    <MdClose />
                                </div>
                            )}
                        </MosaicWindowContext.Consumer>
                    )}
                </MosaicContext.Consumer>
            )}
        </div>
    );
};

export default ModuleWindowControls;
