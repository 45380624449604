import { useEffect, useRef } from 'react';
import { useAppSelector } from '../../state/hooks';

function usePreviousSelector(selector: any) {
    const object = useAppSelector(selector);
    const prevListRef = useRef(object);

    useEffect(() => {
        prevListRef.current = object;
    }, [object]);

    return prevListRef.current;
}

export default usePreviousSelector;
