import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import useScreenSizes from '../../../utils/hooks/useScreenSizes';
import { tradePages } from './LiteSidebar';

export default function LiteFooter() {
    const screen = useScreenSizes();

    return (
        <footer className="flex bg-brand-background text-neutral-200 text-xs h-16 lg:h-0">
            {(screen.mobile || screen.tablet) && <MobileTabNavigation />}
        </footer>
    );
}

const MobileTabNavigation = () => {
    const navigate = useNavigate();
    const pathname = location.pathname.replace(/\/$/, '');

    return (
        <div className="w-full h-full grid grid-flow-col auto-cols-fr divide-x-0 2xs:divide-x 2xs:divide-neutral-700 bg-brand-background-dark">
            {tradePages.map((tab, index) => (
                <TabButton
                    key={tab.label}
                    Icon={tab.Icon}
                    isActive={pathname === tab.path}
                    onClick={() => navigate(tab.path)}>
                    {tab.label}
                </TabButton>
            ))}
        </div>
    );
};

const TabButton = ({ Icon, isActive, onClick, children }: any) => {
    return (
        <button
            className={cn('h-full flex flex-col items-center justify-center gap-0.5 p-2 min-w-[42px]', {
                ['text-brand-primary']: isActive,
                ['text-neutral-200']: !isActive
            })}
            onClick={onClick}>
            <div>
                <Icon className="w-4 h-4" />
            </div>
            <span className="text-2xs 2xs:text-xs">{children}</span>
        </button>
    );
};
