import {
    DEFAULT_LAPTOP_LAYOUT,
    DEFAULT_LAYOUT,
    DEFAULT_MOBILE_LAYOUT,
    DEFAULT_TABLET_LAYOUT,
    XplorTraderModules
} from '@/config/xplorTraderLayout';
import { useAppSelector } from '@/state/hooks';
import {
    selectXplorTraderDesktopLayoutNode,
    selectXplorTraderLaptopLayoutNode,
    selectXplorTraderMobileLayoutNode,
    selectXplorTraderTabletLayoutNode
} from '@/state/reducers/xplorTraderLayoutSlice';
import { useMemo } from 'react';
import { MosaicNode } from 'react-mosaic-component';
import { UseScreenSizesReturn } from './useScreenSizes';

export interface UseXplorTraderLayoutReturn {
    layout: MosaicNode<XplorTraderModules> | null | undefined | any;
    default_layout: MosaicNode<XplorTraderModules>;
}

const useXplorTraderLayout = (screen: UseScreenSizesReturn): UseXplorTraderLayoutReturn => {
    const mobileLayoutNode = useAppSelector(selectXplorTraderMobileLayoutNode);
    const tabletLayoutNode = useAppSelector(selectXplorTraderTabletLayoutNode);
    const laptopLayoutNode = useAppSelector(selectXplorTraderLaptopLayoutNode);
    const desktopLayoutNode = useAppSelector(selectXplorTraderDesktopLayoutNode);

    const xplorTraderLayout = useMemo(() => {
        switch (screen.current) {
            case 'mobile':
                return {
                    layout: mobileLayoutNode || DEFAULT_MOBILE_LAYOUT,
                    default_layout: DEFAULT_MOBILE_LAYOUT
                };
            case 'tablet':
                return {
                    layout: tabletLayoutNode || DEFAULT_TABLET_LAYOUT,
                    default_layout: DEFAULT_TABLET_LAYOUT
                };
            case 'laptop':
                return {
                    layout: laptopLayoutNode || DEFAULT_LAPTOP_LAYOUT,
                    default_layout: DEFAULT_LAPTOP_LAYOUT
                };
            case 'desktop':
                return {
                    layout: desktopLayoutNode || DEFAULT_LAYOUT,
                    default_layout: DEFAULT_LAYOUT
                };
            default:
                return {
                    layout: desktopLayoutNode || DEFAULT_LAYOUT,
                    default_layout: DEFAULT_LAYOUT
                };
        }
    }, [screen.current, mobileLayoutNode, tabletLayoutNode, laptopLayoutNode, desktopLayoutNode]);

    return xplorTraderLayout;
};

export default useXplorTraderLayout;
