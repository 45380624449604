import { User } from '@/state/reducers/authSlice';
import { Metadata, createChannel, createClient } from 'nice-grpc-web';
import { v4 as uuidv4 } from 'uuid';

import {
    AccountPropertyServiceClient,
    AccountPropertyServiceDefinition
} from '@/compiled_proto/com/celertech/staticdata/api/account/property/AccountPropertyProto';
import { FindAccountPropertiesByCode } from '@/compiled_proto/com/celertech/staticdata/api/account/property/UpstreamAccountPropertyProto';
import { Logger } from '@/utils/logger';

const CHANNEL_URL = window.config.integration.celertech.rest;

const channel = createChannel(CHANNEL_URL);

export async function getAccountProperties(account: string, credentials: User) {
    const accountPropertyServiceClient: AccountPropertyServiceClient = createClient(
        AccountPropertyServiceDefinition,
        channel
    );

    // Market order
    const findAccountPropertiesByCodeRequest = FindAccountPropertiesByCode.fromPartial({
        clientRequestId: uuidv4(),
        code: account
    });

    const findAccountPropertiesByCodeResponse = await accountPropertyServiceClient.findAccountPropertiesByCode(
        findAccountPropertiesByCodeRequest,
        {
            metadata: Metadata({
                'authorization-token': credentials.authToken
            })
        }
    );

    Logger({
        title: 'Outbound: Find Account Properties By Code Response',
        callback: () => {
            console.log({
                findAccountPropertiesByCodeRequest,
                findAccountPropertiesByCodeResponse
            });
        }
    });
    return findAccountPropertiesByCodeResponse?.accountProperties || [];
}
