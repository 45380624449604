import Divider from '@/components/common/Divider';
import ExportCSV from '@/components/common/ExportCSV';
import Tooltip from '@/components/common/Tooltip';
import { BlotterItem } from '@/model/blotters';
import { findOrderHistory } from '@/services/OrderService';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { selectCredentials } from '@/state/reducers/authSlice';
import { setHistoricTrades } from '@/state/reducers/blotterSlice';
import useScreenSizes from '@/utils/hooks/useScreenSizes';
import { copyStyles } from '@/utils/popout';
import { Fragment, useEffect, useState } from 'react';
import { isTablet } from 'react-device-detect';
import { createPortal } from 'react-dom';
import { MdOpenInNew } from 'react-icons/md';
import useSWR from 'swr';
import Loader from '../common/Loader';
import PopOut, { PopOutHeader, PopOutTitle } from '../common/PopOut';
import OrderHistoryTable from '../modules/Blotters/OrderHistoryTable';
import Modal, { ModalClose, ModalHeader, ModalProps, ModalTitle } from './Modal';

interface DealHistoryModalProps extends ModalProps {
    order: BlotterItem;
}

const DealHistoryModal = (props: DealHistoryModalProps) => {
    const { opened, handlers } = props;
    const dispatch = useAppDispatch();

    const screen = useScreenSizes();
    const [CSVData, setCSVData] = useState<any[]>([]);
    const [popOut, setPopOut] = useState<Window | null>(null);

    useEffect(() => {
        dispatch(setHistoricTrades(null));
        if (opened && popOut) {
            popOut.close();
            setPopOut(null);
        }
    }, [opened]);

    const openPopOutWindow = () => {
        const popOutWindow = window.open('', '', 'width=1200,height=600');
        if (popOutWindow) {
            copyStyles(document, popOutWindow.document);
            setPopOut(popOutWindow);
            handlers.close();
        }
    };

    return (
        <Fragment>
            <Modal {...props} size="sm:max-w-[90vw] h-full lg:h-[600px]">
                <ModalHeader>
                    <div className="flex space-x-2 items-center">
                        <ModalTitle>Order History</ModalTitle>
                        <ExportCSV className="w-5 h-5" csvData={CSVData} />
                        {(screen.laptop || screen.desktop) && !isTablet && (
                            <Tooltip
                                width={120}
                                content="Open in Separate Window"
                                containerClassName="h-5"
                                placement="top-start">
                                <button type="button" className="text-xl" onClick={openPopOutWindow}>
                                    <MdOpenInNew />
                                </button>
                            </Tooltip>
                        )}
                    </div>
                    <ModalClose handlers={handlers} />
                </ModalHeader>
                <DealHistoryBody setCSVData={setCSVData} {...props} />
            </Modal>
            {popOut &&
                createPortal(
                    <PopOut>
                        <PopOutHeader>
                            <PopOutTitle>Order History</PopOutTitle>
                            <Tooltip content="Download to CSV" className="-bottom-10 w-max">
                                <ExportCSV className="w-5 h-5" csvData={CSVData} />
                            </Tooltip>
                        </PopOutHeader>
                        <DealHistoryBody setCSVData={setCSVData} {...props} />
                    </PopOut>,
                    popOut.document.body
                )}
        </Fragment>
    );
};

export default DealHistoryModal;

interface DealHistoryBodyProps extends DealHistoryModalProps {
    setCSVData: (data: any[]) => void;
}

const DealHistoryBody = (props: DealHistoryBodyProps) => {
    const { order, setCSVData } = props;
    const credentials = useAppSelector(selectCredentials);

    const { data, isLoading } = useSWR('orderHistory' + order.orderId, fetchOrderHistory, {
        refreshInterval: 5000
    });

    async function fetchOrderHistory() {
        if (credentials) return await findOrderHistory({ orderId: order.orderId }, credentials);
        return [];
    }

    return (
        <div className="flex flex-col w-full h-full">
            <Divider />
            {isLoading && (
                <div className="flex justify-center items-center flex-1">
                    <Loader className="text-brand-primary" />
                </div>
            )}

            {!isLoading && !!data?.length && (
                <div className="flex-1 basis-0 p-2 overflow-hidden">
                    <OrderHistoryTable orderHistories={data || []} setCSVData={setCSVData} />
                </div>
            )}

            {!isLoading && !data?.length && (
                <div className="historic-search-empty p-2 flex justify-center items-center text-center flex-1 text-neutral-400 text-sm font-bold italic">
                    <div>No History Was Found For This Order.</div>
                </div>
            )}
        </div>
    );
};
