/* eslint-disable */
import { AssetType } from '../enums/AssetTypeProto';
import { ProductType } from '../enums/ProductTypeProto';
import { Side } from '../enums/SideProto';
import { OrderType } from '../enums/OrderTypeProto';
import { TimeInForceType } from '../enums/TimeInForceTypeProto';
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.positionmanager.api.margin';

/** Clients can register for updates on all margin changes */
export interface UpdateOnMarginRequest {}

/** Margin check request for CreateOrderRequest */
export interface OrderMarginCheckRequest {
    clientRequestId: string;
    accountCode: string;
    marginOrder: MarginOrder[];
}

export interface MarginOrder {
    orderId: string;
    account: string;
    securityId: string;
    securityCode: string;
    currency: string;
    price: number;
    qty: number;
    assetType: AssetType;
    productType: ProductType;
    side: Side;
    createdTimestampUtcInMillis: string;
    settlementDate: string;
    orderType: OrderType;
    trader: string;
    isRequestedOrder: boolean;
    timeInForceType: TimeInForceType;
}

/** Subscribe to account usage updates */
export interface AccountUsageSubscribeRequest {
    accountCode: string[];
}

export interface AllAccountUsageSubscribeRequest {}

function createBaseUpdateOnMarginRequest(): UpdateOnMarginRequest {
    return {};
}

export const UpdateOnMarginRequest = {
    encode(_: UpdateOnMarginRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOnMarginRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateOnMarginRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(_: DeepPartial<UpdateOnMarginRequest>): UpdateOnMarginRequest {
        const message = createBaseUpdateOnMarginRequest();
        return message;
    }
};

function createBaseOrderMarginCheckRequest(): OrderMarginCheckRequest {
    return { clientRequestId: '', accountCode: '', marginOrder: [] };
}

export const OrderMarginCheckRequest = {
    encode(message: OrderMarginCheckRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.accountCode !== '') {
            writer.uint32(18).string(message.accountCode);
        }
        for (const v of message.marginOrder) {
            MarginOrder.encode(v!, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): OrderMarginCheckRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderMarginCheckRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.accountCode = reader.string();
                    break;
                case 3:
                    message.marginOrder.push(MarginOrder.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<OrderMarginCheckRequest>): OrderMarginCheckRequest {
        const message = createBaseOrderMarginCheckRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.accountCode = object.accountCode ?? '';
        message.marginOrder = object.marginOrder?.map((e) => MarginOrder.fromPartial(e)) || [];
        return message;
    }
};

function createBaseMarginOrder(): MarginOrder {
    return {
        orderId: '0',
        account: '',
        securityId: '',
        securityCode: '',
        currency: '',
        price: 0,
        qty: 0,
        assetType: 1,
        productType: 1,
        side: 1,
        createdTimestampUtcInMillis: '0',
        settlementDate: '',
        orderType: 1,
        trader: '',
        isRequestedOrder: false,
        timeInForceType: 1
    };
}

export const MarginOrder = {
    encode(message: MarginOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.orderId !== '0') {
            writer.uint32(8).int64(message.orderId);
        }
        if (message.account !== '') {
            writer.uint32(18).string(message.account);
        }
        if (message.securityId !== '') {
            writer.uint32(26).string(message.securityId);
        }
        if (message.securityCode !== '') {
            writer.uint32(34).string(message.securityCode);
        }
        if (message.currency !== '') {
            writer.uint32(42).string(message.currency);
        }
        if (message.price !== 0) {
            writer.uint32(49).double(message.price);
        }
        if (message.qty !== 0) {
            writer.uint32(57).double(message.qty);
        }
        if (message.assetType !== 1) {
            writer.uint32(64).int32(message.assetType);
        }
        if (message.productType !== 1) {
            writer.uint32(72).int32(message.productType);
        }
        if (message.side !== 1) {
            writer.uint32(80).int32(message.side);
        }
        if (message.createdTimestampUtcInMillis !== '0') {
            writer.uint32(88).int64(message.createdTimestampUtcInMillis);
        }
        if (message.settlementDate !== '') {
            writer.uint32(98).string(message.settlementDate);
        }
        if (message.orderType !== 1) {
            writer.uint32(104).int32(message.orderType);
        }
        if (message.trader !== '') {
            writer.uint32(114).string(message.trader);
        }
        if (message.isRequestedOrder === true) {
            writer.uint32(120).bool(message.isRequestedOrder);
        }
        if (message.timeInForceType !== 1) {
            writer.uint32(128).int32(message.timeInForceType);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarginOrder {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarginOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.account = reader.string();
                    break;
                case 3:
                    message.securityId = reader.string();
                    break;
                case 4:
                    message.securityCode = reader.string();
                    break;
                case 5:
                    message.currency = reader.string();
                    break;
                case 6:
                    message.price = reader.double();
                    break;
                case 7:
                    message.qty = reader.double();
                    break;
                case 8:
                    message.assetType = reader.int32() as any;
                    break;
                case 9:
                    message.productType = reader.int32() as any;
                    break;
                case 10:
                    message.side = reader.int32() as any;
                    break;
                case 11:
                    message.createdTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 12:
                    message.settlementDate = reader.string();
                    break;
                case 13:
                    message.orderType = reader.int32() as any;
                    break;
                case 14:
                    message.trader = reader.string();
                    break;
                case 15:
                    message.isRequestedOrder = reader.bool();
                    break;
                case 16:
                    message.timeInForceType = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<MarginOrder>): MarginOrder {
        const message = createBaseMarginOrder();
        message.orderId = object.orderId ?? '0';
        message.account = object.account ?? '';
        message.securityId = object.securityId ?? '';
        message.securityCode = object.securityCode ?? '';
        message.currency = object.currency ?? '';
        message.price = object.price ?? 0;
        message.qty = object.qty ?? 0;
        message.assetType = object.assetType ?? 1;
        message.productType = object.productType ?? 1;
        message.side = object.side ?? 1;
        message.createdTimestampUtcInMillis = object.createdTimestampUtcInMillis ?? '0';
        message.settlementDate = object.settlementDate ?? '';
        message.orderType = object.orderType ?? 1;
        message.trader = object.trader ?? '';
        message.isRequestedOrder = object.isRequestedOrder ?? false;
        message.timeInForceType = object.timeInForceType ?? 1;
        return message;
    }
};

function createBaseAccountUsageSubscribeRequest(): AccountUsageSubscribeRequest {
    return { accountCode: [] };
}

export const AccountUsageSubscribeRequest = {
    encode(message: AccountUsageSubscribeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.accountCode) {
            writer.uint32(10).string(v!);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): AccountUsageSubscribeRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAccountUsageSubscribeRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.accountCode.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<AccountUsageSubscribeRequest>): AccountUsageSubscribeRequest {
        const message = createBaseAccountUsageSubscribeRequest();
        message.accountCode = object.accountCode?.map((e) => e) || [];
        return message;
    }
};

function createBaseAllAccountUsageSubscribeRequest(): AllAccountUsageSubscribeRequest {
    return {};
}

export const AllAccountUsageSubscribeRequest = {
    encode(_: AllAccountUsageSubscribeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): AllAccountUsageSubscribeRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAllAccountUsageSubscribeRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(_: DeepPartial<AllAccountUsageSubscribeRequest>): AllAccountUsageSubscribeRequest {
        const message = createBaseAllAccountUsageSubscribeRequest();
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
