import { OrderHistory } from '@/model/blotters';
import { formatDateTime, formatLocalDateTime } from '@/utils/format';
import { getInstrumentFormatter } from '@/utils/hooks/useInstrument';
import { getAssetName, translatePair } from '@/utils/symbolMapping';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import cn from 'classnames';
import { orderStatusColorCell } from './orderBlotterTableHelper';

export const historyColumns = (generalSettings): ColumnDef<OrderHistory>[] => {
    return [
        { accessorKey: 'time', header: `Time (${generalSettings.timezone.value})`, size: 150 },
        { accessorKey: 'pair', header: 'Pair', size: 100 },
        { accessorKey: 'ccy', header: 'Ccy', size: 60 },
        { accessorKey: 'orderType', header: 'Order Type', size: 100 },
        {
            accessorKey: 'status',
            header: 'Status',
            size: 120,
            cell: (context: CellContext<OrderHistory, any>) => {
                const { getValue } = context;
                return <span className={orderStatusColorCell(context)}>{getValue()}</span>;
            }
        },
        {
            accessorKey: 'executionType',
            header: 'Execution Type',
            size: 120,
            cell: (context: CellContext<OrderHistory, any>) => {
                const { getValue } = context;
                return <span className={orderStatusColorCell(context)}>{getValue()}</span>;
            }
        },
        {
            accessorKey: 'side',
            header: 'Side',
            size: 60,
            cell: ({ row, getValue }: CellContext<OrderHistory, any>) => {
                const value = getValue();
                return (
                    <div
                        className={cn('text-sm', {
                            'text-brand-primary': value === 'BUY',
                            'text-brand-red': value === 'SELL'
                        })}>
                        {value}
                    </div>
                );
            }
        },
        { accessorKey: 'qty', header: 'Qty', size: 80 },
        { accessorKey: 'price', header: 'Price', size: 80 },
        { accessorKey: 'lastPrice', header: 'Last Price', size: 80 },
        { accessorKey: 'lastQty', header: 'Last Qty', size: 80 },
        { accessorKey: 'avgPrice', header: 'Avg Price', size: 80 },
        { accessorKey: 'stopPrice', header: 'Stop Price', size: 80 },
        { accessorKey: 'cumQty', header: 'Cum Qty', size: 80 },
        { accessorKey: 'leavesQty', header: 'Leaves Qty', size: 90 },
        { accessorKey: 'tif', header: 'Tif', size: 60 },
        { accessorKey: 'account', header: 'Account', size: 100 },
        { accessorKey: 'user', header: 'User', size: 100 }
    ];
};

export const mapOrderHistoryBlotters = (trade, locale, credentials, formatDate, isUTC) => {
    const { formatPrice, formatAvgPrice } = getInstrumentFormatter(locale, trade.pair);
    const executionTime =
        trade.executionTime &&
        (isUTC
            ? formatDateTime(formatDate, trade.executionTime)
            : formatLocalDateTime(formatDate, trade.executionTime));

    return {
        ...trade,
        time: executionTime,
        price: formatPrice(trade.price),
        lastPrice: formatPrice(trade.lastPrice),
        avgPrice: formatAvgPrice(trade.avgPrice),
        stopPrice: formatPrice(trade.stopPrice),
        pair: translatePair(trade.pair),
        ccy: getAssetName(trade.ccy),
        ccy1: getAssetName(trade.ccy1),
        ccy2: getAssetName(trade.ccy2)
    };
};
