import useOrderExecution from '@/utils/hooks/useOrderExecution';
import { Tab } from '@headlessui/react';
import cn from 'classnames';
import LimitOrderForm from '../form/LimitOrderForm';
import MarketOrderForm from '../form/MarketOrderForm';
import OcoOrderForm from '../form/OcoOrderForm';
import StopOrderForm from '../form/StopOrderForm';

const tabs = ['Market', 'Limit', 'Stop', 'OCO'];

interface OrderExecutionProps {}

export default function OrderExecution(props: OrderExecutionProps) {
    const orderExecutionProps = useOrderExecution();

    return (
        <div className="flex flex-col h-full">
            <Tab.Group>
                <Tab.List className="flex bg-brand-background border-b-[1px] border-b-neutral-700">
                    {tabs.map((tab) => (
                        <Tab
                            key={tab}
                            className={({ selected }) =>
                                cn('p-1 px-2 font-medium leading-5 text-sm text-neutral-200 focus:outline-none', {
                                    'border-b-[1px] bg-brand-background-dark border-b-brand-border': selected,
                                    'text-neutral-400 hover:bg-white/[0.12] hover:text-neutral-200': !selected
                                })
                            }>
                            {tab}
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panel className="flex-1 overflow-y-auto">
                    <MarketOrderForm {...orderExecutionProps} />
                </Tab.Panel>
                <Tab.Panel className="flex-1 overflow-y-auto">
                    <LimitOrderForm {...orderExecutionProps} />
                </Tab.Panel>
                <Tab.Panel className="flex-1 overflow-y-auto">
                    <StopOrderForm {...orderExecutionProps} />
                </Tab.Panel>
                <Tab.Panel className="flex-1 overflow-y-auto">
                    <OcoOrderForm {...orderExecutionProps} />
                </Tab.Panel>
            </Tab.Group>
        </div>
    );
}
