import cn from 'classnames';
import { IconType } from 'react-icons/lib';
import { MdHistory, MdHome } from 'react-icons/md';
import { useNavigate } from 'react-router';

export const tradePages: {
    label: string;
    Icon: IconType;
    path: string;
}[] = [
    { label: 'Home', Icon: MdHome, path: '/lite' },
    // { label: 'Transfer', Icon: MdSwapVerticalCircle, path: '/lite/transfer' },
    { label: 'History', Icon: MdHistory, path: '/lite/history' }
];

const LiteSidebar = () => {
    const navigate = useNavigate();
    const pathname = location.pathname.replace(/\/$/, '');
    return (
        <div className="hidden lg:flex flex-col p-6 gap-3 w-64">
            {tradePages.map(({ Icon, label, path }, index) => (
                <div
                    key={index}
                    className={cn('flex w-full gap-2 px-4 py-2 rounded-md items-center cursor-pointer', {
                        ['bg-brand-primary']: pathname === path,
                        ['hover:bg-brand-primary']: pathname !== path
                    })}
                    onClick={() => {
                        if (pathname !== path) navigate(path);
                    }}>
                    <Icon className="w-6 h-6" /> {label}
                </div>
            ))}
        </div>
    );
};

export default LiteSidebar;
