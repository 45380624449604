/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.orderrouting.api.order';

export interface OrderSnapshotNotificationDownstreamEvent {
    sessionKey: string;
    groupCode: string;
    createdTimestampUtcInMillis: string;
}

export interface DeleteOrderSnapshotNotificationConfirmation {
    sessionKey: string;
    groupCode: string;
}

function createBaseOrderSnapshotNotificationDownstreamEvent(): OrderSnapshotNotificationDownstreamEvent {
    return { sessionKey: '', groupCode: '', createdTimestampUtcInMillis: '0' };
}

export const OrderSnapshotNotificationDownstreamEvent = {
    encode(message: OrderSnapshotNotificationDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.sessionKey !== '') {
            writer.uint32(10).string(message.sessionKey);
        }
        if (message.groupCode !== '') {
            writer.uint32(18).string(message.groupCode);
        }
        if (message.createdTimestampUtcInMillis !== '0') {
            writer.uint32(24).int64(message.createdTimestampUtcInMillis);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): OrderSnapshotNotificationDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderSnapshotNotificationDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sessionKey = reader.string();
                    break;
                case 2:
                    message.groupCode = reader.string();
                    break;
                case 3:
                    message.createdTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<OrderSnapshotNotificationDownstreamEvent>
    ): OrderSnapshotNotificationDownstreamEvent {
        const message = createBaseOrderSnapshotNotificationDownstreamEvent();
        message.sessionKey = object.sessionKey ?? '';
        message.groupCode = object.groupCode ?? '';
        message.createdTimestampUtcInMillis = object.createdTimestampUtcInMillis ?? '0';
        return message;
    }
};

function createBaseDeleteOrderSnapshotNotificationConfirmation(): DeleteOrderSnapshotNotificationConfirmation {
    return { sessionKey: '', groupCode: '' };
}

export const DeleteOrderSnapshotNotificationConfirmation = {
    encode(message: DeleteOrderSnapshotNotificationConfirmation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.sessionKey !== '') {
            writer.uint32(10).string(message.sessionKey);
        }
        if (message.groupCode !== '') {
            writer.uint32(18).string(message.groupCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): DeleteOrderSnapshotNotificationConfirmation {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeleteOrderSnapshotNotificationConfirmation();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sessionKey = reader.string();
                    break;
                case 2:
                    message.groupCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<DeleteOrderSnapshotNotificationConfirmation>
    ): DeleteOrderSnapshotNotificationConfirmation {
        const message = createBaseDeleteOrderSnapshotNotificationConfirmation();
        message.sessionKey = object.sessionKey ?? '';
        message.groupCode = object.groupCode ?? '';
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
