import { AccumulatedPriceLevel, PriceLevel as NumberPriceLevel, PriceBook } from '@/utils/pricebook';
import BigNumber from 'bignumber.js';

export const calculateVWAP = (depths: NumberPriceLevel[] | null, quantity: number) => {
    const availableQuantityInBook = depths?.reduce((acc, curr) => acc + +curr.quantity, 0) || 0;

    if (quantity > availableQuantityInBook) {
        return { vwap: null, message: 'Quantity size is bigger than the order book' };
    } else {
        let temp = quantity;

        const depthsToConsider: NumberPriceLevel[] = [];

        depths?.forEach((depth) => {
            const remainder = temp - depth.quantity;
            if (temp > 0) {
                if (remainder > 0) {
                    depthsToConsider.push(depth);
                    temp = remainder;
                } else {
                    depthsToConsider.push({ ...depth, quantity: temp });
                    temp = 0;
                }
            }
        });

        const pv = depthsToConsider.reduce(
            (acc, curr) => acc + BigNumber(curr.price).times(curr.quantity).toNumber(),
            0
        );
        return { vwap: BigNumber(pv).dividedBy(quantity), message: '' };
    }
};

export const accumulatePriceBookBidAsks = (
    priceBook: PriceBook
): {
    bids: AccumulatedPriceLevel[] | null;
    asks: AccumulatedPriceLevel[] | null;
} => {
    let bids: AccumulatedPriceLevel[] | null = null;
    let asks: AccumulatedPriceLevel[] | null = null;

    if (priceBook) {
        asks =
            priceBook.asks
                ?.filter(Boolean)
                ?.map((ask, i) => ({
                    ...ask,
                    accQuantity: priceBook.asks.slice(0, i + 1).reduce((acc, curr) => acc + curr?.quantity, 0)
                }))
                ?.reverse() || null;

        bids =
            priceBook.bids?.filter(Boolean)?.map((ask, i) => ({
                ...ask,
                accQuantity: priceBook.bids.slice(0, i + 1).reduce((acc, curr) => acc + curr?.quantity, 0)
            })) || null;
    }

    return { bids, asks };
};
