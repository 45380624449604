import { qtyFormatterConfig } from '@/config/config';
import { CelerBalance } from '@/state/reducers/balanceSlice';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import usePreviousState from '@/utils/hooks/usePreviousState';
import cn from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNumberFormatter } from 'react-aria';
import { MdSwapVert } from 'react-icons/md';

interface OldBalanceProps {
    balance: CelerBalance;
    isNewlyAdded: boolean;
}

const OldBalance = ({ balance, isNewlyAdded }: OldBalanceProps) => {
    const prevPosition = usePreviousState(balance.netPosition);
    const formatQty = useNumberFormatter(qtyFormatterConfig);

    const timer = useRef<NodeJS.Timeout>();
    const [priceChange, setPriceChange] = useState({ increased: false, decreased: false });

    const balanceColor = useMemo(() => {
        if (balance.netPosition === 0) return 'text-neutral-200';
        else if (balance.netPosition > 0) return 'text-brand-primary';
        else return 'text-brand-red';
    }, [balance.netPosition]);

    const handleEvents = useCallback(() => {
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => setPriceChange({ increased: false, decreased: false }), 3000);
    }, [timer.current]);

    useDidUpdate(() => {
        if (balance.netPosition && prevPosition) {
            if (balance.netPosition === prevPosition) {
                setPriceChange({ increased: false, decreased: false });
            } else if (balance.netPosition > prevPosition) {
                setPriceChange({ increased: true, decreased: false });
                handleEvents();
            } else {
                setPriceChange({ increased: false, decreased: true });
                handleEvents();
            }
        }
    }, [balance.netPosition]);

    useEffect(() => {
        if (isNewlyAdded) {
            if (balance.netPosition > 0) {
                setPriceChange({ increased: true, decreased: false });
                handleEvents();
            } else if (balance.netPosition < 0) {
                setPriceChange({ increased: false, decreased: true });
                handleEvents();
            }
        }
    }, [isNewlyAdded]);

    return (
        <div className="flex justify-between">
            <div className="text-left flex-[1_1_0]">{balance.currency}</div>
            <div
                className={cn('flex flex-[2_1_0] relative items-center justify-center gap-2 text-right', {
                    [balanceColor]: true
                })}>
                <span className="absolute left-0">
                    {priceChange.increased && <MdSwapVert className={cn('w-4 h-4 text-brand-primary-light')} />}
                    {priceChange.decreased && <MdSwapVert className={cn('w-4 h-4 text-brand-red-light')} />}
                </span>
                <span className="flex-[1_1_0]">{formatQty.format(balance.netPosition)}</span>
            </div>
        </div>
    );
};

export default OldBalance;
