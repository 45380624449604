import { autoUpdate, flip, offset, size, useFloating } from '@floating-ui/react';
import { Listbox, Transition } from '@headlessui/react';
import cn from 'classnames';
import { Fragment, forwardRef, useMemo } from 'react';
import { MdExpandMore } from 'react-icons/md';

interface Option {
    label: any;
    value: any;
}

interface ExecutionTypeSelectProps {
    enableSorting?: boolean;
    type?: 'default' | 'execution';
    label?: string;
    labelClassName?: string;
    className?: string;
    options: Option[];
    selected?: Option;
    disabled?: boolean;
    onChange?: (e: Option) => void;
}

const types = {
    default: {
        button: 'h-[33.99px] relative w-full rounded-sm bg-brand-background border border-neutral-700 py-1 pl-3 pr-10 text-left text-sm',
        options:
            'z-[60] max-h-60 overflow-auto rounded-sm bg-brand-background border border-neutral-700 py-1 text-sm shadow-lg shadow-neutral-900',
        activeOption: 'bg-brand-primary'
    },
    execution: {
        button: 'h-[33.99px] relative w-full rounded-sm bg-brand-background border border-neutral-700 py-1 pl-3 pr-10 text-left text-sm shadow-sm text-neutral-200 placeholder-neutral-500 focus:outline-none focus:ring-neutral-600 focus:border-neutral-600',
        options:
            'z-[60] max-h-60 overflow-auto rounded-sm bg-brand-background border border-neutral-700 py-1 text-sm shadow-lg shadow-neutral-900',
        activeOption: 'bg-brand-primary'
    }
};

const ExecutionTypeSelect = forwardRef((props: ExecutionTypeSelectProps, ref) => {
    const {
        type = 'default',
        options,
        selected,
        label,
        labelClassName,
        className,
        enableSorting = false,
        disabled = false,
        onChange
    } = props;

    const middleware = useMemo(() => {
        return [
            size({
                apply({ rects, elements }) {
                    Object.assign(elements.floating.style, {
                        width: `${rects.reference.width}px`
                    });
                }
            }),
            offset(8),
            flip()
        ];
    }, []);

    const { refs, floatingStyles } = useFloating({
        strategy: 'fixed',
        placement: 'bottom-start',
        whileElementsMounted: autoUpdate,
        middleware
    });

    const sortedOptions = useMemo(() => {
        return enableSorting ? options.sort((a, b) => a.label?.localeCompare(b.label)) : options;
    }, [options, enableSorting]);

    return (
        <Listbox value={selected?.value} onChange={onChange} disabled={disabled}>
            <div ref={refs.setReference} className="relative flex min-w-[180px] w-full">
                <div className="relative w-full">
                    {label && (
                        <div
                            className={cn(
                                'absolute -top-[8px] text-xs ml-2 text-neutral-200 px-1 bg-background z-10',
                                labelClassName
                            )}>
                            {label}
                        </div>
                    )}
                    <Listbox.Button
                        className={cn(
                            '',
                            types[type].button,
                            {
                                'cursor-not-allowed': disabled,
                                'cursor-pointer': !disabled
                            },
                            className
                        )}>
                        <span className="block truncate">{selected?.label}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <MdExpandMore className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </Listbox.Button>
                </div>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    {/* temporary shadow to the dropdown to differentiate */}
                    <Listbox.Options
                        ref={refs.setFloating}
                        style={floatingStyles}
                        className={cn('', types[type].options)}>
                        {sortedOptions.length === 0 ? (
                            <div className="relative cursor-default select-none py-2 px-4 text-neutral-200">
                                No options found
                            </div>
                        ) : (
                            sortedOptions.map((option, optionIdx) => (
                                <Listbox.Option
                                    key={optionIdx}
                                    className={({ active }) =>
                                        cn(`relative cursor-pointer select-none py-2 pl-3 pr-4`, {
                                            [`${types[type].activeOption}`]: active,
                                            'text-brand-primary-light': selected?.value === option.value,
                                            'text-neutral-200': active && selected?.value === option.value
                                        })
                                    }
                                    value={option}>
                                    <span className={cn(`block truncate`)}>{option.label}</span>
                                </Listbox.Option>
                            ))
                        )}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    );
});

ExecutionTypeSelect.displayName = 'ExecutionTypeSelect';

export default ExecutionTypeSelect;
