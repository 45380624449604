import useScreenSizes from '@/utils/hooks/useScreenSizes';
import { Toaster } from 'react-hot-toast';
import DesktopNotificationsModal from './modal/NotificationsModal/DesktopNotificationsModal';
import MobileNotificationsModal from './modal/NotificationsModal/MobileNotificationsModal';

const ResponsiveToaster = () => {
    const screen = useScreenSizes();

    return screen.laptop || screen.desktop ? (
        <>
            <Toaster
                position="top-right"
                reverseOrder={true}
                toastOptions={{
                    className: 'toast',
                    duration: Infinity
                }}
            />
            <DesktopNotificationsModal />
        </>
    ) : (
        <MobileNotificationsModal />
    );
};

export default ResponsiveToaster;
