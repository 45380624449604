import { grpc } from '@improbable-eng/grpc-web';
import { createChannel, createClient, Metadata } from 'nice-grpc-web';
import { Middleware } from 'redux';
import { AppDispatch, RootState } from '../store';

import {
    NetPositionEvent,
    NetPositionUpdateEvent,
    PositionManagerServiceClient,
    PositionManagerServiceDefinition
} from '@/compiled_proto/com/celertech/positionmanager/api/pnl/PostionManagerServiceProto';
import { forEachAsync } from '@/helpers/asyncIterableHelper';
import { Logger } from '@/utils/logger';
import { v4 as uuidv4 } from 'uuid';
import { controlClearSubscriptions, controlInitSubscriptions, User } from '../reducers/authSlice';
import { setCurrencyPosition, setOpenPositions } from '../reducers/openPositionSlice';

let positionSubscriptionId: any = null;
let unrealisedPnLSubscriptionId: any = null;
let positionsSubscription: any = null;
let unrealisedPnLSubscription: any = null;

const setupPositionSubscription = async (store: any, dispatch: AppDispatch, credentials: User) => {
    // Subscribe for changes to openPositions
    positionsSubscription = positionManagerClient.subscribeForPositions(
        { accounts: [credentials.accounts.map((acc) => acc.code).join(',')] },
        {
            metadata: Metadata({
                'authorization-token': credentials.authToken
            })
        }
    );

    const currentSubscriptionId = uuidv4();
    positionSubscriptionId = currentSubscriptionId;
    forEachAsync(
        positionsSubscription,
        (openPositionUpdate: NetPositionUpdateEvent) => {
            const positionEvent = openPositionUpdate.positionEvent;

            if (positionEvent) {
                if (!positionEvent.positionUpdate?.shouldDelete) {
                    dispatch(setCurrencyPosition(positionEvent));
                }
            }
        },
        () => positionSubscriptionId !== currentSubscriptionId,
        'OpenPositionMiddleware'
    ).catch((error) => console.error({ 'OpenPositionMiddleware subscription for open positions': error }));
};

const setupUnrealisedPnLSubscription = async (store: any, dispatch: AppDispatch, credentials: User) => {
    // Subscribe for changes to unrealisedPnL
    unrealisedPnLSubscription = positionManagerClient.subscribeForUnrealisedPnl(
        { throttleDelayInMillis: '0' },
        {
            metadata: Metadata({
                'authorization-token': credentials.authToken
            })
        }
    );

    const currentSubscriptionId = uuidv4();
    unrealisedPnLSubscriptionId = currentSubscriptionId;
    forEachAsync(
        unrealisedPnLSubscription,
        (openPositionUpdate: { event: NetPositionUpdateEvent[]; isHeartBeat: boolean }) => {
            const unrealisedPnL = openPositionUpdate.event
                .map((e) => e.positionEvent)
                .filter((e) => e && !e.positionUpdate?.shouldDelete) as NetPositionEvent[];
            if (unrealisedPnL.length > 0) dispatch(setOpenPositions(unrealisedPnL));
        },
        () => unrealisedPnLSubscriptionId !== currentSubscriptionId,
        'OpenPositionMiddleware'
    ).catch((error) => console.error({ 'OpenPositionMiddleware subscription for unrealised PnL': error }));
};

const wsChannelUrl = window.config.integration.celertech.websocket;
const wsChannel = createChannel(wsChannelUrl, grpc.WebsocketTransport());

// Subscribe to openPosition updates

const positionManagerClient: PositionManagerServiceClient = createClient(PositionManagerServiceDefinition, wsChannel);

const openPositionMiddleware: Middleware = (store) => (next) => (action) => {
    if (controlClearSubscriptions.match(action)) {
        Logger({ title: `OpenPositionMiddleware: Clear Subscriptions`, callback: () => {} });
        positionSubscriptionId = null;
        unrealisedPnLSubscriptionId = null;
        positionsSubscription = null;
        unrealisedPnLSubscription = null;
    } else if (controlInitSubscriptions.match(action)) {
        Logger({ title: `OpenPositionMiddleware: Initialise Subscriptions`, callback: () => {} });

        const dispatch: AppDispatch = store.dispatch;
        const state: RootState = store.getState();
        const creds = state.auth.user;
        // If not already listening, setup subscriptions
        if (!positionsSubscription && !unrealisedPnLSubscription && creds) {
            setupPositionSubscription(store, dispatch, creds);
            setupUnrealisedPnLSubscription(store, dispatch, creds);
        }
    }

    // Pass on to next middlewares in line
    next(action);
};

export default openPositionMiddleware;
