/* eslint-disable */
import Long from 'long';
import { ProductType } from '../enums/ProductTypeProto';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.positionmanager.api.margin';

/** Margin downstream event to denote that margin has changed */
export interface MarginDownstreamEvent {
    account: string;
    usage: number;
    accountValue: number;
    unrealisedPnl: number;
    currency: string;
    createdTimestampUtcInMillis: string;
    validMargin: boolean;
    marginText: string;
    productType: ProductType[];
}

/** Result for order margin check */
export interface OrderMarginCheckResult {
    clOrderId: string;
    account: string;
    message: string;
    usage: number;
    accountValue: number;
    canTrade: boolean;
    productType: ProductType[];
}

function createBaseMarginDownstreamEvent(): MarginDownstreamEvent {
    return {
        account: '',
        usage: 0,
        accountValue: 0,
        unrealisedPnl: 0,
        currency: '',
        createdTimestampUtcInMillis: '0',
        validMargin: false,
        marginText: '',
        productType: []
    };
}

export const MarginDownstreamEvent = {
    encode(message: MarginDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.account !== '') {
            writer.uint32(10).string(message.account);
        }
        if (message.usage !== 0) {
            writer.uint32(17).double(message.usage);
        }
        if (message.accountValue !== 0) {
            writer.uint32(25).double(message.accountValue);
        }
        if (message.unrealisedPnl !== 0) {
            writer.uint32(33).double(message.unrealisedPnl);
        }
        if (message.currency !== '') {
            writer.uint32(42).string(message.currency);
        }
        if (message.createdTimestampUtcInMillis !== '0') {
            writer.uint32(48).int64(message.createdTimestampUtcInMillis);
        }
        if (message.validMargin === true) {
            writer.uint32(56).bool(message.validMargin);
        }
        if (message.marginText !== '') {
            writer.uint32(66).string(message.marginText);
        }
        writer.uint32(74).fork();
        for (const v of message.productType) {
            writer.int32(v);
        }
        writer.ldelim();
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarginDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarginDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.account = reader.string();
                    break;
                case 2:
                    message.usage = reader.double();
                    break;
                case 3:
                    message.accountValue = reader.double();
                    break;
                case 4:
                    message.unrealisedPnl = reader.double();
                    break;
                case 5:
                    message.currency = reader.string();
                    break;
                case 6:
                    message.createdTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 7:
                    message.validMargin = reader.bool();
                    break;
                case 8:
                    message.marginText = reader.string();
                    break;
                case 9:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.productType.push(reader.int32() as any);
                        }
                    } else {
                        message.productType.push(reader.int32() as any);
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<MarginDownstreamEvent>): MarginDownstreamEvent {
        const message = createBaseMarginDownstreamEvent();
        message.account = object.account ?? '';
        message.usage = object.usage ?? 0;
        message.accountValue = object.accountValue ?? 0;
        message.unrealisedPnl = object.unrealisedPnl ?? 0;
        message.currency = object.currency ?? '';
        message.createdTimestampUtcInMillis = object.createdTimestampUtcInMillis ?? '0';
        message.validMargin = object.validMargin ?? false;
        message.marginText = object.marginText ?? '';
        message.productType = object.productType?.map((e) => e) || [];
        return message;
    }
};

function createBaseOrderMarginCheckResult(): OrderMarginCheckResult {
    return { clOrderId: '', account: '', message: '', usage: 0, accountValue: 0, canTrade: false, productType: [] };
}

export const OrderMarginCheckResult = {
    encode(message: OrderMarginCheckResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clOrderId !== '') {
            writer.uint32(10).string(message.clOrderId);
        }
        if (message.account !== '') {
            writer.uint32(18).string(message.account);
        }
        if (message.message !== '') {
            writer.uint32(26).string(message.message);
        }
        if (message.usage !== 0) {
            writer.uint32(33).double(message.usage);
        }
        if (message.accountValue !== 0) {
            writer.uint32(41).double(message.accountValue);
        }
        if (message.canTrade === true) {
            writer.uint32(48).bool(message.canTrade);
        }
        writer.uint32(58).fork();
        for (const v of message.productType) {
            writer.int32(v);
        }
        writer.ldelim();
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): OrderMarginCheckResult {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderMarginCheckResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clOrderId = reader.string();
                    break;
                case 2:
                    message.account = reader.string();
                    break;
                case 3:
                    message.message = reader.string();
                    break;
                case 4:
                    message.usage = reader.double();
                    break;
                case 5:
                    message.accountValue = reader.double();
                    break;
                case 6:
                    message.canTrade = reader.bool();
                    break;
                case 7:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.productType.push(reader.int32() as any);
                        }
                    } else {
                        message.productType.push(reader.int32() as any);
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<OrderMarginCheckResult>): OrderMarginCheckResult {
        const message = createBaseOrderMarginCheckResult();
        message.clOrderId = object.clOrderId ?? '';
        message.account = object.account ?? '';
        message.message = object.message ?? '';
        message.usage = object.usage ?? 0;
        message.accountValue = object.accountValue ?? 0;
        message.canTrade = object.canTrade ?? false;
        message.productType = object.productType?.map((e) => e) || [];
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
