import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { ComponentProps, forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';

interface TickerQuantityInputProps extends ComponentProps<'input'> {
    name: string;
    label?: string;
    min?: string | number;
    step?: number;
    className?: any;
    active_ccy: string;
    instrumentType: 'Crypto CFD' | 'FX Spot' | 'Index CFD' | 'Comm CFD' | '';
    currencyAssetName: string;
    ccy2_enabled: boolean;
    switchCcy: () => void;
}

const TickerQuantityInput = forwardRef<HTMLInputElement, TickerQuantityInputProps>((props, ref) => {
    const { active_ccy, instrumentType, currencyAssetName, ccy2_enabled, switchCcy, ...inputProps } = props;
    const { name, min = 0, step = 1, disabled = false, className } = inputProps;
    const { getValues, setValue } = useFormContext();

    return (
        <div className="relative flex flex-1">
            <input
                {...inputProps}
                ref={ref}
                autoComplete="off"
                className={cn(
                    'appearance-none block w-full px-2 py-1 bg-brand-background-dark border border-neutral-700 text-sm placeholder-neutral-500 focus:outline-none',
                    { 'text-neutral-400 cursor-not-allowed': disabled },
                    { 'text-neutral-200': !className },
                    className
                )}
                placeholder="0"
                // placeholder={min.toString()}
            />
            <div className="absolute right-12 flex flex-col h-full justify-center text-[8px] text-neutral-200 w-5">
                <button
                    type="button"
                    className={cn('hover:text-brand-primary-light -mb-0.5 px-0', {
                        'text-neutral-400 cursor-not-allowed': disabled
                    })}
                    onClick={() => {
                        if (!disabled) {
                            const prevValue = +(getValues(name) || 0);
                            const nextValue = BigNumber(prevValue).plus(step).toNumber();
                            setValue(name, nextValue, { shouldValidate: true });
                        }
                    }}>
                    &#x25B2;
                </button>
                <button
                    type="button"
                    className={cn('hover:text-brand-primary-light -mt-0.5 px-0', {
                        'text-neutral-400 cursor-not-allowed': disabled
                    })}
                    onClick={() => {
                        if (!disabled) {
                            const prevValue = +(getValues(name) || 0);
                            const nextValue = BigNumber(prevValue).minus(step).toNumber();
                            if (nextValue <= +min) setValue(name, min, { shouldValidate: true });
                            else setValue(name, nextValue, { shouldValidate: true });
                        }
                    }}>
                    &#x25BC;
                </button>
            </div>
            <div
                className={cn('absolute right-2 flex flex-col h-full justify-center text-sm', {
                    'text-neutral-400 cursor-not-allowed': !ccy2_enabled,
                    'text-neutral-200 cursor-pointer hover:text-brand-primary-light': ccy2_enabled
                })}
                onClick={() => {
                    if (ccy2_enabled) switchCcy();
                }}>
                {['Index CFD', 'Comm CFD'].includes(instrumentType) ? 'Qty' : currencyAssetName}
            </div>
        </div>
    );
});

TickerQuantityInput.displayName = 'TickerQuantityInput';

export default TickerQuantityInput;
