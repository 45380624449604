import { useAppSelector } from '@/state/hooks';
import { selectAccountUsages } from '@/state/reducers/accountUsageSlice';
import { selectCurrentAccount } from '@/state/reducers/authSlice';
import { getCurrency } from '@/utils/hooks/useCurrency';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { Fragment, forwardRef, useMemo } from 'react';
import { useNumberFormatter } from 'react-aria';

interface AccountValueProps {}

export default function AccountValue(props: AccountValueProps) {
    const currentAccount = useAppSelector(selectCurrentAccount);
    const accountUsages = useAppSelector(selectAccountUsages);

    const { order_decimals } = useMemo(() => {
        if (accountUsages && currentAccount) {
            return getCurrency(accountUsages[currentAccount]?.currency || 'USD');
        } else {
            return getCurrency('USD');
        }
    }, [accountUsages, currentAccount]);

    const formatNumber = useNumberFormatter({
        minimumFractionDigits: 0,
        maximumFractionDigits: Math.max(0, order_decimals - 2)
    });

    const usages = useMemo(() => {
        if (accountUsages && currentAccount) {
            const accountUsage = accountUsages[currentAccount];
            if (accountUsage) {
                return {
                    currency: accountUsage.currency || 'USD',
                    unrealizedPnL: BigNumber(accountUsage.unrealisedPnl).toNumber(),
                    totalEquity: BigNumber(accountUsage.accountValue).toNumber(),
                    marginUsed: BigNumber(accountUsage.usage).toNumber(),
                    availableMargin: BigNumber(accountUsage.accountValue).minus(accountUsage.usage).toNumber(),
                    marginUsage: BigNumber(accountUsage.usage)
                        .dividedBy(accountUsage.accountValue)
                        .times(100)
                        .toNumber()
                };
            }
        }

        return null;
    }, [accountUsages, currentAccount]);

    return (
        <div className="flex flex-col w-full h-full text-neutral-200 overflow-y-auto py-2 gap-2">
            {usages && (
                <Fragment>
                    <Row
                        usages={usages}
                        label="Unrealized P/L"
                        accessorKey="unrealizedPnL"
                        formatNumber={formatNumber}
                    />
                    <Row usages={usages} label="Total Equity" accessorKey="totalEquity" formatNumber={formatNumber} />
                    <Row usages={usages} label="Margin Used" accessorKey="marginUsed" formatNumber={formatNumber} />
                    <Row
                        usages={usages}
                        label="Available Margin"
                        accessorKey="availableMargin"
                        formatNumber={formatNumber}
                    />
                    <Row usages={usages} label="Margin Usage" accessorKey="marginUsage" formatNumber={formatNumber} />
                </Fragment>
            )}
        </div>
    );
}

interface RowProps {
    label: string;
    usages: {
        currency: string;
        unrealizedPnL: number;
        totalEquity: number;
        marginUsed: number;
        availableMargin: number;
        marginUsage: number;
    };
    accessorKey: 'unrealizedPnL' | 'totalEquity' | 'marginUsed' | 'availableMargin' | 'marginUsage';
    formatNumber: Intl.NumberFormat;
}

const Row = forwardRef<HTMLInputElement, RowProps>(({ label, usages, accessorKey, formatNumber }, ref) => {
    const value = usages[accessorKey];

    const balanceColor = useMemo(() => {
        if (value === 0 || accessorKey === 'marginUsage') return 'text-neutral-200';
        else if (value > 0) return 'text-brand-primary';
        else return 'text-brand-red';
    }, [value]);

    return (
        <div ref={ref} className="w-full text-sm flex justify-between items-center gap-2 px-2 whitespace-nowrap">
            <span>{label}</span>
            <div className={cn('flex flex-wrap-reverse justify-end gap-x-2', balanceColor)}>
                {accessorKey === 'marginUsage' && <ProgressBar valuePercentage={value} />}
                {accessorKey !== 'marginUsage' && usages.currency} {formatNumber.format(value)}{' '}
                {accessorKey === 'marginUsage' && '%'}
            </div>
        </div>
    );
});

Row.displayName = 'Row';

type ProgressBarProps = {
    valuePercentage: number;
};

const ProgressBar = (props: ProgressBarProps) => {
    const { valuePercentage: value } = props;
    const fillerRelativePercentage = (100 / value) * 100;

    return (
        <div
            className="w-16 flex items-center"
            role="progressbar"
            aria-valuemin={0}
            aria-valuemax={100}
            aria-valuenow={value}>
            <div className="flex-1 h-2 bg-neutral-200 border-md overflow-hidden" style={{ width: `${value}%` }}>
                <div className="filler" style={{ width: `${value}%` }}>
                    <div className="filler-background" style={{ width: `${fillerRelativePercentage}%` }} />
                </div>
            </div>
        </div>
    );
};
