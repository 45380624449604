import ModuleWindow from '@/components/mosaic/ModuleWindow';
import ModuleWindowControls from '@/components/mosaic/ModuleWindowControl';
import { useAppSelector } from '@/state/hooks';
import { selectActiveMarketPair } from '@/state/reducers/marketPairSlice';
import cn from 'classnames';
import { useCallback } from 'react';
import { isTablet } from 'react-device-detect';
import { MdOpenInNew } from 'react-icons/md';
import AdvancedChartView from './AdvancedChartView';

const ChartModule = (props: any) => {
    const { screen, shouldOverlayCharts, tradingModule } = props;

    const activePair = useAppSelector(selectActiveMarketPair);
    const onPopOut = useCallback(
        () => window.open(`/chart?symbol=${activePair.netdania}`, '', 'width=1200,height=600'),
        [activePair]
    );

    return (
        <ModuleWindow
            {...props}
            renderToolbar={() => (
                <div className="flex justify-between items-center w-full h-full border-b border-b-neutral-700 text-neutral-200">
                    <div className="h-full flex items-center">
                        <span className="p-2 font-semibold">{tradingModule.title}</span>

                        {(screen.laptop || screen.desktop) && !isTablet && (
                            <div className="flex items-center h-full">
                                <div
                                    title="Open in Separate Window"
                                    className={cn('h-full px-2 flex items-center', {
                                        ['cursor-pointer hover:bg-brand-primary']: true
                                    })}
                                    onClick={onPopOut}>
                                    <MdOpenInNew className="w-4 h-4" />
                                </div>
                            </div>
                        )}
                    </div>
                    <ModuleWindowControls screen={screen} />
                </div>
            )}>
            <div className="h-full bg-brand-background">
                {shouldOverlayCharts && <div className="overlay h-full w-full absolute z-10" />}
                {activePair?.celer && <AdvancedChartView />}
            </div>
        </ModuleWindow>
    );
};

export default ChartModule;
