import { Tab } from '@headlessui/react';
import Divider from '../../common/Divider';
import Modal, { ModalClose, ModalHeader, ModalProps, ModalTab, ModalTabList, ModalTitle } from '../Modal';
import FiatWithdrawalTab from '../WithdrawalModal/FiatWithdrawalTab';
import CryptoWithdrawalTab from './CryptoWithdrawalTab';

const tabs = ['Fiat Withdrawal', 'Crypto Withdrawal'];

const WithdrawalModal = (props: ModalProps) => {
    const { handlers } = props;
    return (
        <Modal {...props} size="max-w-2xl">
            <div className="h-full flex flex-col lg:block lg:h-auto">
                <ModalHeader>
                    <ModalTitle>Withdrawals</ModalTitle>
                    <ModalClose handlers={handlers} />
                </ModalHeader>
                <Divider />
                <div className="flex-1 basis-0 h-full flex flex-col lg:h-auto lg:block w-full text-neutral-200 mt-[1px]">
                    <Tab.Group>
                        <ModalTabList>
                            {tabs.map((tab) => (
                                <ModalTab key={tab}>{tab}</ModalTab>
                            ))}
                        </ModalTabList>
                        <Tab.Panels className="flex-1 basis-0">
                            <FiatWithdrawalTab {...props} />
                            <CryptoWithdrawalTab {...props} />
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
        </Modal>
    );
};

export default WithdrawalModal;
