import Balances from '@/components/modules/Balances';
import MarketList from '@/components/modules/MarketList';
import Notifications from '@/components/modules/Notifications';
import OrderBook from '@/components/modules/OrderBook';
import OrderExecution from '@/components/modules/OrderExecution';
import { IconType } from 'react-icons/lib';
import {
    MdAccountBalanceWallet,
    MdCandlestickChart,
    MdHistory,
    MdNotifications,
    MdPivotTableChart,
    MdPriceChange,
    MdSwapVerticalCircle
} from 'react-icons/md';
import { MosaicNode } from 'react-mosaic-component';

export type XplorSpotModules = 'Order' | 'Balances' | 'Chart' | 'Order Book' | 'Blotter' | 'Markets' | 'Notifications';
export const tabList = ['Order', 'Balances', 'Chart', 'Order Book', 'Blotter', 'Markets', 'Notifications'];
export const tabIcon = {
    Order: MdSwapVerticalCircle,
    Balances: MdAccountBalanceWallet,
    Chart: MdCandlestickChart,
    'Order Book': MdPriceChange,
    Blotter: MdHistory,
    Markets: MdPivotTableChart,
    Notifications: MdNotifications
} as Record<XplorSpotModules, IconType>;

export const DEFAULT_LAYOUT: MosaicNode<XplorSpotModules> = {
    direction: 'row',
    first: {
        direction: 'row',
        first: {
            direction: 'column',
            first: 'Order',
            second: 'Balances',
            splitPercentage: 60
        },
        second: {
            direction: 'column',
            first: {
                direction: 'row',
                first: 'Order Book',
                second: 'Chart',
                splitPercentage: 30
            },
            second: 'Blotter',
            splitPercentage: 60
        },
        splitPercentage: 25
    },
    second: 'Markets',
    splitPercentage: 78
};

export const DEFAULT_MOBILE_LAYOUT: MosaicNode<XplorSpotModules> = {
    direction: 'row',
    first: 'Order',
    second: 'Chart',
    splitPercentage: 100
};

export const DEFAULT_TABLET_LAYOUT: MosaicNode<XplorSpotModules> = {
    direction: 'row',
    first: 'Chart',
    second: 'Order',
    splitPercentage: 60
};

export const DEFAULT_LAPTOP_LAYOUT: MosaicNode<XplorSpotModules> = {
    direction: 'row',
    first: {
        direction: 'row',
        first: {
            direction: 'column',
            first: 'Order',
            second: 'Balances',
            splitPercentage: 60
        },
        second: {
            direction: 'column',
            first: 'Chart',
            second: 'Blotter',
            splitPercentage: 60
        },
        splitPercentage: 35
    },
    second: {
        direction: 'column',
        first: 'Markets',
        second: 'Order Book',
        splitPercentage: 60
    },
    splitPercentage: 71
};

export const xplorSpotLayoutConfig: Record<
    string,
    {
        title: string;
        component?: JSX.Element;
    }
> = {
    Order: {
        title: 'Order',
        component: (
            <div id="orderform" className="h-full bg-brand-background">
                <OrderExecution />
            </div>
        )
    },
    Balances: {
        title: 'Balances',
        component: (
            <div id="balances" className="h-full bg-brand-background">
                <Balances />
            </div>
        )
    },
    Chart: {
        title: 'Chart'
    },
    'Order Book': {
        title: 'Market Depth',
        component: <OrderBook />
    },
    Blotter: {
        title: 'Blotter'
    },
    Markets: {
        title: 'Markets',
        component: (
            <div id="market-list" className="bg-brand-background h-full max-h-full overflow-hidden">
                <MarketList />
            </div>
        )
    },
    Notifications: {
        title: 'Notifications',
        component: (
            <div id="notifications" className="bg-brand-background h-full max-h-full overflow-hidden">
                <Notifications />
            </div>
        )
    }
};
