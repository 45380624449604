import {
    LogoutServiceClient,
    LogoutServiceDefinition
} from '@/compiled_proto/com/celertech/baseserver/communication/login/LogoutProto';
import { useAppSelector } from '@/state/hooks';
import { selectCredentials } from '@/state/reducers/authSlice';
import { Metadata, createChannel, createClient } from 'nice-grpc-web';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

const CHANNEL_URL = window.config.integration.celertech.rest;
const channel = createChannel(CHANNEL_URL);
const logoutClient: LogoutServiceClient = createClient(LogoutServiceDefinition, channel);

const EventListeners = () => {
    const location = useLocation();
    const credentials = useAppSelector(selectCredentials);

    const shouldLogout = useMemo(() => !['/login', '/forgot-password'].includes(location.pathname), [location]);

    const handleBeforeUnload = useCallback(
        async (event) => {
            try {
                if (credentials && shouldLogout) {
                    const resp = logoutClient.logout(
                        {},
                        {
                            metadata: Metadata({
                                'authorization-token': credentials.authToken
                            })
                        }
                    );
                    console.log({ resp });
                }
            } catch (err) {
                console.log(err);
            }
            setTimeout(() => {
                event.preventDefault();
            }, 100);
        },
        [shouldLogout]
    );

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [handleBeforeUnload]);

    return null;
};

export default EventListeners;

// code snippet from celer

// useCallback(async zt=>{
//     try {
//         await logoutCall()
//     } catch (Zt) {
//         Network.Error(Zt)
//     }
//     Ut({
//         type: ActionTypes$f.LOGOUT_RESPONSE
//     }),
//     setTimeout(()=>{
//         zt.preventDefault()
//     }
//     , 100)
