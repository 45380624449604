import { InferType, number, object, string } from 'yup';
import { Nullable } from '../../../model/common';
import { QuantitySchemaParams, quantitySchema } from './common';

interface MarketOrderSchemaParams extends QuantitySchemaParams {}

export const marketOrderSchema = (params: MarketOrderSchemaParams) => {
    return object({
        quantity: quantitySchema(params),
        duration: object({ label: string(), value: number() }).required('Duration cannot be empty!')
    });
};
type MarketOrderSchema = ReturnType<typeof marketOrderSchema>;
export type MarketOrderFormValues = InferType<MarketOrderSchema>;
export type MarketOrderFormInput = Nullable<MarketOrderFormValues>;
