import ContextMenu, { ContextMenuItem } from '@/components/common/ContextMenu';
import Divider from '@/components/common/Divider';
import LiteOrderModal from '@/components/modal/LiteOrderModal';
import { qtyFormatterConfig } from '@/config/config';
import { currencyConfigDict } from '@/config/currency';
import { shouldUseNopBalance } from '@/helpers/environmentHelper';
import { selectAggregatedBalance, selectBalanceStatus, selectNopBalance } from '@/state/reducers/balanceSlice';
import useContextMenu from '@/utils/hooks/useContextMenu';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import cn from 'classnames';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useNumberFormatter } from 'react-aria';
import { MdAddCircle, MdAutoAwesomeMotion, MdRemoveCircle } from 'react-icons/md';
import { useSelector } from 'react-redux';
import LiteActions from '../LiteActions';
import LiteBalance from './LiteBalance';
import { OldLiteBalance } from './OldLiteBalance';

interface LiteBalancesProps {}

export default function LiteBalances(props: LiteBalancesProps) {
    const status = useSelector(selectBalanceStatus);
    const nopBalance = useSelector(selectNopBalance);
    const celerBalance = useSelector(selectAggregatedBalance);

    const formatQty = useNumberFormatter(qtyFormatterConfig);

    const [opened, handlers] = useDisclosure(false);

    const [selectedBalanceCcy, setSelectedBalanceCcy] = useState<string>('');
    const [showBalanceSettlements, setShowBalanceSettlements] = useState<Record<string, boolean>>({});
    const [initFrom, setInitFrom] = useState<string>('');
    const [initTo, setInitTo] = useState<string>('');

    const { contextMenuX, contextMenuY, contextMenuVisible, handleOpenContextMenu, handleCloseContextMenu } =
        useContextMenu();

    const onSelectingBalance = useCallback(
        (e: any, ccy: string) => {
            setSelectedBalanceCcy(ccy);
            handleOpenContextMenu(e);
        },
        [handleOpenContextMenu]
    );

    const cryptoBalances = useMemo(() => {
        if (shouldUseNopBalance) {
            return nopBalance?.balances.filter((balance) => currencyConfigDict[balance.currency]?.type === 'Crypto');
        } else {
            return celerBalance.filter((balance) => currencyConfigDict[balance.currency]?.type === 'Crypto');
        }
    }, [nopBalance, celerBalance]);

    const fiatBalances = useMemo(() => {
        if (shouldUseNopBalance) {
            return nopBalance?.balances.filter((balance) => currencyConfigDict[balance.currency]?.type === 'Fiat');
        } else {
            return celerBalance.filter((balance) => currencyConfigDict[balance.currency]?.type === 'Fiat');
        }
    }, [nopBalance, celerBalance]);

    const balanceTotals = useMemo(() => {
        if (shouldUseNopBalance)
            return nopBalance?.balances.reduce((acc, balance) => acc + balance.totalAmountInBaseCurrency, 0) || 0;
    }, [nopBalance]);

    return (
        <Fragment>
            <div className="flex flex-col h-fit w-full gap-1 bg-brand-background-dark p-6">
                <span className="text-neutral-200 font-bold text-base sm:text-lg">Your Balances</span>
                <LiteActions orderModal={handlers} setInitFrom={setInitFrom} setInitTo={setInitTo} />
                <div className="flex flex-col gap-5">
                    {status === 'OPEN' && (
                        <Fragment>
                            {cryptoBalances.length > 0 && (
                                <div className="flex flex-col items-center gap-3">
                                    <div className="flex w-full justify-between">
                                        <span className="text-sm sm:text-base">Crypto Assets</span>
                                    </div>
                                    <div className="flex w-full text-xs sm:text-sm text-neutral-400 gap-3 px-3">
                                        <span className="flex-[1_1_0]">Asset</span>
                                        <span className="flex-[1_1_0] text-end">Balance</span>
                                        {nopBalance && (
                                            <div className="text-right flex-[1_1_0]">
                                                Balance {nopBalance.baseCurrency ? `(${nopBalance.baseCurrency})` : ''}
                                            </div>
                                        )}
                                    </div>
                                    <Divider className="bg-neutral-700" />
                                    {shouldUseNopBalance &&
                                        cryptoBalances.map((balance) => (
                                            <LiteBalance
                                                key={balance.currency}
                                                balance={balance}
                                                settlementsDisclosure={showBalanceSettlements[balance.currency]}
                                                onClick={onSelectingBalance}
                                            />
                                        ))}
                                    {!shouldUseNopBalance &&
                                        cryptoBalances.map((balance) => (
                                            <OldLiteBalance
                                                key={balance.currency}
                                                balance={balance}
                                                onClick={onSelectingBalance}
                                            />
                                        ))}
                                </div>
                            )}
                            {fiatBalances.length > 0 && (
                                <div className="flex flex-col items-center gap-3">
                                    <div className="flex w-full justify-between">
                                        <span className="text-sm sm:text-base">Fiat Currencies</span>
                                    </div>
                                    <div className="flex w-full text-xs sm:text-sm text-neutral-400 gap-3 px-3">
                                        <span className="flex-[1_1_0]">Currency</span>
                                        <span className="flex-[1_1_0] text-end">Balance</span>
                                        {nopBalance && (
                                            <div className="text-right flex-[1_1_0]">
                                                Balance {nopBalance.baseCurrency ? `(${nopBalance.baseCurrency})` : ''}
                                            </div>
                                        )}
                                    </div>
                                    <Divider className="bg-neutral-700" />
                                    {shouldUseNopBalance &&
                                        fiatBalances.map((balance) => (
                                            <LiteBalance
                                                key={balance.currency}
                                                balance={balance}
                                                settlementsDisclosure={showBalanceSettlements[balance.currency]}
                                                onClick={onSelectingBalance}
                                            />
                                        ))}
                                    {!shouldUseNopBalance &&
                                        fiatBalances.map((balance) => (
                                            <OldLiteBalance
                                                key={balance.currency}
                                                balance={balance}
                                                onClick={onSelectingBalance}
                                            />
                                        ))}
                                </div>
                            )}
                            {shouldUseNopBalance && balanceTotals !== undefined && (
                                <div className="flex font-semibold pt-2">
                                    <div className="text-left flex-[1_1_0]">Total</div>
                                    <div
                                        className={cn(
                                            'flex flex-[2_1_0] relative items-center justify-center gap-2 text-right',
                                            {
                                                ['text-neutral-200']: balanceTotals === 0,
                                                ['text-brand-primary']: balanceTotals > 0,
                                                ['text-brand-red']: balanceTotals < 0
                                            }
                                        )}>
                                        <span className="flex-[1_1_0]">{formatQty.format(balanceTotals)}</span>
                                    </div>
                                </div>
                            )}
                        </Fragment>
                    )}
                </div>
                {status === 'CLOSED' && (
                    <div className="flex flex-col gap-2 p-2 justify-center items-center h-full text-xs sm:text-sm text-center text-neutral-400">
                        <div className="italic">Unable to fetch live balances</div>
                        <div className="italic">Please refresh the browser</div>
                    </div>
                )}
            </div>

            <ContextMenu
                x={contextMenuX}
                y={contextMenuY}
                visible={contextMenuVisible}
                onClose={handleCloseContextMenu}>
                {shouldUseNopBalance && (
                    <ContextMenuItem
                        onClick={() => {
                            const newShowBalanceSettlements = { ...showBalanceSettlements };
                            newShowBalanceSettlements[selectedBalanceCcy] = !showBalanceSettlements[selectedBalanceCcy];
                            setShowBalanceSettlements(newShowBalanceSettlements);
                        }}>
                        <MdAutoAwesomeMotion />
                        <span>{!showBalanceSettlements[selectedBalanceCcy] ? 'Show' : 'Hide'} Settlements</span>
                    </ContextMenuItem>
                )}
                <ContextMenuItem
                    onClick={() => {
                        setInitFrom('');
                        setInitTo(selectedBalanceCcy);
                        handlers.toggle();
                    }}>
                    <MdAddCircle />
                    <span>Buy {selectedBalanceCcy}</span>
                </ContextMenuItem>
                <ContextMenuItem
                    className="hover:bg-brand-red"
                    onClick={() => {
                        setInitFrom(selectedBalanceCcy);
                        setInitTo('');
                        handlers.toggle();
                    }}>
                    <MdRemoveCircle />
                    <span>Sell {selectedBalanceCcy}</span>
                </ContextMenuItem>
            </ContextMenu>
            <LiteOrderModal initFrom={initFrom} initTo={initTo} opened={opened} handlers={handlers} />
        </Fragment>
    );
}
