/* eslint-disable */
import { CallContext, CallOptions } from 'nice-grpc-common';
import { LoginRequest } from './UpstreamLoginProto';
import { LoginResponse } from './DownstreamLoginProto';

export const protobufPackage = 'com.celertech.baseserver.communication.login';

export type LoginServiceDefinition = typeof LoginServiceDefinition;
export const LoginServiceDefinition = {
    name: 'LoginService',
    fullName: 'com.celertech.baseserver.communication.login.LoginService',
    methods: {
        login: {
            name: 'login',
            requestType: LoginRequest,
            requestStream: false,
            responseType: LoginResponse,
            responseStream: false,
            options: {}
        }
    }
} as const;

export interface LoginServiceServiceImplementation<CallContextExt = {}> {
    login(request: LoginRequest, context: CallContext & CallContextExt): Promise<DeepPartial<LoginResponse>>;
}

export interface LoginServiceClient<CallOptionsExt = {}> {
    login(request: DeepPartial<LoginRequest>, options?: CallOptions & CallOptionsExt): Promise<LoginResponse>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;
