import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { OrderBookWorkspace, selectOrderBookWorkspace, setOrderBookWorkspace } from '@/state/reducers/workspaceSlice';
import { Tab } from '@headlessui/react';
import cn from 'classnames';
import HorizontalBook from './HorizontalBook';
import VerticalBook from './VerticalBook';

interface OrderBookProps {}

const tabs: OrderBookWorkspace[] = ['Vertical', 'Horizontal'];

export default function OrderBook(props: OrderBookProps) {
    const dispatch = useAppDispatch();
    const orderBookWorkspace = useAppSelector(selectOrderBookWorkspace);

    return (
        <Tab.Group
            defaultIndex={tabs.findIndex((tab) => tab === orderBookWorkspace)}
            onChange={(index) => dispatch(setOrderBookWorkspace(tabs[index]))}>
            <div className="h-full flex flex-col">
                <Tab.List className="flex bg-brand-background border-b-[1px] border-b-neutral-700">
                    {tabs.map((tab) => (
                        <Tab
                            key={tab}
                            className={({ selected }) =>
                                cn('p-1 px-2 font-medium leading-5 text-sm text-neutral-200 focus:outline-none', {
                                    'border-b-[1px] bg-brand-background-dark border-b-brand-border': selected,
                                    'text-neutral-400 hover:bg-white/[0.12] hover:text-neutral-200': !selected
                                })
                            }>
                            {tab}
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panel className="flex-1 flex flex-col bg-brand-background overflow-auto">
                    <VerticalBook />
                </Tab.Panel>
                <Tab.Panel className="flex-1 flex flex-col bg-brand-background overflow-auto">
                    <HorizontalBook />
                </Tab.Panel>
            </div>
        </Tab.Group>
    );
}
