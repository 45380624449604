import { TimeInForceType } from '@/compiled_proto/com/celertech/positionmanager/api/enums/TimeInForceTypeProto';
import { timezoneOptions } from '@/components/modal/SettingsModal/GeneralTab';
import {
    notificationDurationOptions,
    notificationShowOptions
} from '@/components/modal/SettingsModal/NotificationsTab';
import {
    defaultLimitOrderDurationOptions,
    defaultMarketOrderDurationOptions,
    shortSellPreventionOptions,
    tradingLayoutOptions,
    twoClickTradingOptions
} from '@/components/modal/SettingsModal/TradingTab';
import { Modify } from '@/model/common';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToastOptions } from 'react-hot-toast';
import { RootState } from '../store';

export interface SettingState {
    general: { timezone: { label: string; value: string } };
    notifications: ModifiedToastOptions;
    trading: {
        tradingLayout: { label: string; value: string };
        twoClickTrading: { label: string; value: boolean };
        shortSellPrevention: { label: string; value: boolean };
        // deprecated
        defaultOrderDuration?: { label: keyof typeof TimeInForceType; value: TimeInForceType };
        defaultMarketOrderDuration: { label: keyof typeof TimeInForceType; value: TimeInForceType };
        defaultLimitOrderDuration: { label: keyof typeof TimeInForceType; value: TimeInForceType };
        // 'xplorspot' | 'xplorspotlite'
    };
}

const defaultTradingLayout = window.config?.layout?.default;

const initialState: SettingState = {
    general: {
        timezone: timezoneOptions[0]
    },
    notifications: {
        show: notificationShowOptions[0],
        duration: notificationDurationOptions[1]
    },
    trading: {
        tradingLayout: defaultTradingLayout === 'xplorspot' ? tradingLayoutOptions[0] : tradingLayoutOptions[1],
        twoClickTrading: twoClickTradingOptions[0],
        shortSellPrevention: shortSellPreventionOptions[0],
        defaultOrderDuration: defaultMarketOrderDurationOptions[0],
        defaultMarketOrderDuration: defaultMarketOrderDurationOptions[0],
        defaultLimitOrderDuration: defaultLimitOrderDurationOptions[0]
    }
};

export interface ModifiedToastOptions
    extends Modify<
        ToastOptions,
        {
            show: { label: string; value?: boolean };
            duration: { label: string; value?: number };
        }
    > {}

export const settingSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setSettings: (state, action: PayloadAction<SettingState>) => {
            const keys = Object.keys(action.payload);

            keys.forEach((key) => {
                state[key] = action.payload[key];
            });
        },
        setTradingLayout: (state, action: PayloadAction<'xplorspot' | 'xplorspotlite' | 'xplortrader'>) => {
            state.trading.tradingLayout =
                tradingLayoutOptions.find((option) => option.value === action.payload) || tradingLayoutOptions[0];
        },
        setSettingsNotifications: (state, action: PayloadAction<ModifiedToastOptions>) => {
            state.notifications = action.payload;
        }
    }
});

export const { setSettings, setTradingLayout, setSettingsNotifications } = settingSlice.actions;

export const selectSettings = (state: RootState) => state.settings;
export const selectTradingSettings = (state: RootState) => state.settings.trading;
export const selectGeneralSettings = (state: RootState) => state.settings.general;
export const selectShortSellPrevention = (state: RootState) => state.settings.trading.shortSellPrevention;
export const selectNotificationSettings = (state: RootState) => state.settings.notifications;

export default settingSlice.reducer;
