/* eslint-disable */
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.orderrouting.api.order';

export interface CreateOrderSnapshotNotificationRequest {
    clientRequestId: string;
    sessionKey: string;
    groupCode: string;
}

export interface DeleteOrderSnapshotNotificationRequest {
    clientRequestId: string;
    sessionKey: string;
    groupCode: string;
}

export interface FindOrderSnapshotNotificationsBySessionKeyRequest {
    clientRequestId: string;
    sessionKey: string;
}

export interface FindOrderSnapshotNotificationsByGroupCodeRequest {
    clientRequestId: string;
    groupCode: string;
}

export interface FindAllOrderSnapshotNotificationsRequest {
    clientRequestId: string;
}

function createBaseCreateOrderSnapshotNotificationRequest(): CreateOrderSnapshotNotificationRequest {
    return { clientRequestId: '', sessionKey: '', groupCode: '' };
}

export const CreateOrderSnapshotNotificationRequest = {
    encode(message: CreateOrderSnapshotNotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.sessionKey !== '') {
            writer.uint32(18).string(message.sessionKey);
        }
        if (message.groupCode !== '') {
            writer.uint32(26).string(message.groupCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrderSnapshotNotificationRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateOrderSnapshotNotificationRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.sessionKey = reader.string();
                    break;
                case 3:
                    message.groupCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateOrderSnapshotNotificationRequest>): CreateOrderSnapshotNotificationRequest {
        const message = createBaseCreateOrderSnapshotNotificationRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.sessionKey = object.sessionKey ?? '';
        message.groupCode = object.groupCode ?? '';
        return message;
    }
};

function createBaseDeleteOrderSnapshotNotificationRequest(): DeleteOrderSnapshotNotificationRequest {
    return { clientRequestId: '', sessionKey: '', groupCode: '' };
}

export const DeleteOrderSnapshotNotificationRequest = {
    encode(message: DeleteOrderSnapshotNotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.sessionKey !== '') {
            writer.uint32(18).string(message.sessionKey);
        }
        if (message.groupCode !== '') {
            writer.uint32(26).string(message.groupCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): DeleteOrderSnapshotNotificationRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeleteOrderSnapshotNotificationRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.sessionKey = reader.string();
                    break;
                case 3:
                    message.groupCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<DeleteOrderSnapshotNotificationRequest>): DeleteOrderSnapshotNotificationRequest {
        const message = createBaseDeleteOrderSnapshotNotificationRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.sessionKey = object.sessionKey ?? '';
        message.groupCode = object.groupCode ?? '';
        return message;
    }
};

function createBaseFindOrderSnapshotNotificationsBySessionKeyRequest(): FindOrderSnapshotNotificationsBySessionKeyRequest {
    return { clientRequestId: '', sessionKey: '' };
}

export const FindOrderSnapshotNotificationsBySessionKeyRequest = {
    encode(
        message: FindOrderSnapshotNotificationsBySessionKeyRequest,
        writer: _m0.Writer = _m0.Writer.create()
    ): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.sessionKey !== '') {
            writer.uint32(18).string(message.sessionKey);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindOrderSnapshotNotificationsBySessionKeyRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindOrderSnapshotNotificationsBySessionKeyRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.sessionKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<FindOrderSnapshotNotificationsBySessionKeyRequest>
    ): FindOrderSnapshotNotificationsBySessionKeyRequest {
        const message = createBaseFindOrderSnapshotNotificationsBySessionKeyRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.sessionKey = object.sessionKey ?? '';
        return message;
    }
};

function createBaseFindOrderSnapshotNotificationsByGroupCodeRequest(): FindOrderSnapshotNotificationsByGroupCodeRequest {
    return { clientRequestId: '', groupCode: '' };
}

export const FindOrderSnapshotNotificationsByGroupCodeRequest = {
    encode(
        message: FindOrderSnapshotNotificationsByGroupCodeRequest,
        writer: _m0.Writer = _m0.Writer.create()
    ): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.groupCode !== '') {
            writer.uint32(18).string(message.groupCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindOrderSnapshotNotificationsByGroupCodeRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindOrderSnapshotNotificationsByGroupCodeRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.groupCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<FindOrderSnapshotNotificationsByGroupCodeRequest>
    ): FindOrderSnapshotNotificationsByGroupCodeRequest {
        const message = createBaseFindOrderSnapshotNotificationsByGroupCodeRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.groupCode = object.groupCode ?? '';
        return message;
    }
};

function createBaseFindAllOrderSnapshotNotificationsRequest(): FindAllOrderSnapshotNotificationsRequest {
    return { clientRequestId: '' };
}

export const FindAllOrderSnapshotNotificationsRequest = {
    encode(message: FindAllOrderSnapshotNotificationsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAllOrderSnapshotNotificationsRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAllOrderSnapshotNotificationsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<FindAllOrderSnapshotNotificationsRequest>
    ): FindAllOrderSnapshotNotificationsRequest {
        const message = createBaseFindAllOrderSnapshotNotificationsRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;
