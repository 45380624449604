import { NetPositionEvent } from '@/compiled_proto/com/celertech/positionmanager/api/pnl/PostionManagerServiceProto';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { PURGE } from 'redux-persist';
import { FetchingStatus } from '../../model/fetchingStatus';
import { RootState } from '../store';

export enum ChangeOpenPositionAccountStatus {
    SUCCESS = 1,
    BAD_REQUEST = 2,
    UNEXPECTED = -1
}

export class ChangeOpenPositionAccountError extends Error {
    public constructor(public code: ChangeOpenPositionAccountStatus, message?: string) {
        super(message);
    }
}

export type OpenPosition = NetPositionEvent;
export type OpenPositions = NetPositionEvent[];

export interface OpenPositionState {
    status: FetchingStatus;
    openPositions: OpenPositions;
}

const initialState: OpenPositionState = {
    status: 'idle',
    openPositions: []
};

export const openPositionSlice = createSlice({
    name: 'openPosition',
    initialState,
    reducers: {
        setCurrencyPosition: (state, action: PayloadAction<OpenPosition>) => {
            if (state.openPositions.length !== 0) {
                const positionUpdate = action.payload.positionUpdate;

                const index = state.openPositions.findIndex((p) => {
                    const stateP = p.positionUpdate;

                    const isSettlementDate = stateP?.settlementDate === positionUpdate?.settlementDate;
                    const isAccountCode = stateP?.accountCode === positionUpdate?.accountCode;
                    const isSecurityId = stateP?.securityId === positionUpdate?.securityId;
                    const isRealisedPnL = stateP?.realisedPnl === positionUpdate?.realisedPnl;
                    const isOpenPrice = p.openPrice === action.payload.openPrice;

                    return isSettlementDate && isAccountCode && isSecurityId && (isRealisedPnL || isOpenPrice);
                });
                if (index !== -1) state.openPositions[index] = action.payload;
            }
        },
        setOpenPositions: (state, action: PayloadAction<OpenPositions>) => {
            const openPositions: OpenPositions = [];

            action.payload.forEach((position) => {
                const found = openPositions.find((p) => _.isEqual(p.positionUpdate, position.positionUpdate));
                if (!found) openPositions.push(position);
            });

            state.openPositions = openPositions;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => initialState);
    }
});

export const { setCurrencyPosition, setOpenPositions } = openPositionSlice.actions;
export const selectOpenPositions = (state: RootState) => state.openPosition.openPositions;
export const selectOpenPositionStatus = (state: RootState) => state.openPosition.status;

export default openPositionSlice.reducer;
