import currencyConfig from '@/config/currency';
import { CollapsedBalances, NewBalance } from '@/state/reducers/balanceSlice';
import BigNumber from 'bignumber.js';

export function collapseBalances(data: NewBalance[]): CollapsedBalances[] {
    const balances: CollapsedBalances[] = [];

    const temp = data
        .map((balance) => {
            const foundCcy =
                currencyConfig.find((ccyConfig) => ccyConfig.celer === balance.currencyName)?.show ||
                balance.currencyDigitalAssetName ||
                balance.currencyName;
            return {
                currency: foundCcy,
                currencyName: foundCcy,
                amount: balance.amount,
                amountInBaseCurrency: balance.amountInBaseCurrency,
                netPosition: balance.amount,
                valueDate: balance.valueDate
            };
        })
        .filter((b) => b.amount !== 0);
    temp.forEach((balance) => {
        const index = balances.findIndex(({ currency }) => currency === balance.currency);
        if (index !== -1) {
            balances[index].totalAmount = BigNumber(balances[index].totalAmount).plus(balance.amount).toNumber();
            balances[index].totalAmountInBaseCurrency = BigNumber(balances[index].totalAmountInBaseCurrency)
                .plus(balance.amountInBaseCurrency)
                .toNumber();
            balances[index].rows.push({
                amount: balance.amount,
                amountInBaseCurrency: balance.amountInBaseCurrency,
                valueDate: balance.valueDate
            });
        } else {
            balances.push({
                currency: balance.currency,
                totalAmount: balance.amount,
                totalAmountInBaseCurrency: balance.amountInBaseCurrency,
                rows: [
                    {
                        amount: balance.amount,
                        amountInBaseCurrency: balance.amountInBaseCurrency,
                        valueDate: balance.valueDate
                    }
                ]
            });
        }
    });
    balances.forEach((balance) => balance.rows.sort((a, b) => (a.valueDate > b.valueDate ? 1 : -1)));
    balances.sort(balanceSort);

    return balances;
}

export const balanceSort = (a: CollapsedBalances, b: CollapsedBalances) => {
    if (a.currency < b.currency) return -1;
    if (a.currency > b.currency) return 1;
    return 0;
};
