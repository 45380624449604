/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.preference.api.profile';

export interface CreateProfileRequest {
    clientRequestId: string;
    name: string;
    description: string;
    active: boolean;
    preference: PersistPreferenceRequest[];
    type: string;
    username: string;
}

export interface UpdateProfileRequest {
    clientRequestId: string;
    id: string;
    name: string;
    description: string;
    active: boolean;
}

export interface DeleteProfileRequest {
    clientRequestId: string;
    username: string;
    id: string;
}

export interface PersistPreferenceRequest {
    clientRequestId: string;
    profileId: string;
    key: string;
    value: string;
}

export interface DeletePreferenceRequest {
    clientRequestId: string;
    profileId: string;
    key: string;
}

export interface FindProfilesRequest {
    clientRequestId: string;
}

export interface FindProfilesByUsernameAndType {
    clientRequestId: string;
    username: string;
    type: string;
}

export interface FindPreferencesForProfileIdRequest {
    clientRequestId: string;
    profileId: string;
}

function createBaseCreateProfileRequest(): CreateProfileRequest {
    return { clientRequestId: '', name: '', description: '', active: false, preference: [], type: '', username: '' };
}

export const CreateProfileRequest = {
    encode(message: CreateProfileRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.name !== '') {
            writer.uint32(18).string(message.name);
        }
        if (message.description !== '') {
            writer.uint32(26).string(message.description);
        }
        if (message.active === true) {
            writer.uint32(32).bool(message.active);
        }
        for (const v of message.preference) {
            PersistPreferenceRequest.encode(v!, writer.uint32(42).fork()).ldelim();
        }
        if (message.type !== '') {
            writer.uint32(50).string(message.type);
        }
        if (message.username !== '') {
            writer.uint32(58).string(message.username);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateProfileRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateProfileRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.description = reader.string();
                    break;
                case 4:
                    message.active = reader.bool();
                    break;
                case 5:
                    message.preference.push(PersistPreferenceRequest.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.type = reader.string();
                    break;
                case 7:
                    message.username = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateProfileRequest>): CreateProfileRequest {
        const message = createBaseCreateProfileRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.name = object.name ?? '';
        message.description = object.description ?? '';
        message.active = object.active ?? false;
        message.preference = object.preference?.map((e) => PersistPreferenceRequest.fromPartial(e)) || [];
        message.type = object.type ?? '';
        message.username = object.username ?? '';
        return message;
    }
};

function createBaseUpdateProfileRequest(): UpdateProfileRequest {
    return { clientRequestId: '', id: '0', name: '', description: '', active: false };
}

export const UpdateProfileRequest = {
    encode(message: UpdateProfileRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.id !== '0') {
            writer.uint32(16).int64(message.id);
        }
        if (message.name !== '') {
            writer.uint32(26).string(message.name);
        }
        if (message.description !== '') {
            writer.uint32(34).string(message.description);
        }
        if (message.active === true) {
            writer.uint32(40).bool(message.active);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateProfileRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateProfileRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.description = reader.string();
                    break;
                case 5:
                    message.active = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UpdateProfileRequest>): UpdateProfileRequest {
        const message = createBaseUpdateProfileRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.id = object.id ?? '0';
        message.name = object.name ?? '';
        message.description = object.description ?? '';
        message.active = object.active ?? false;
        return message;
    }
};

function createBaseDeleteProfileRequest(): DeleteProfileRequest {
    return { clientRequestId: '', username: '', id: '0' };
}

export const DeleteProfileRequest = {
    encode(message: DeleteProfileRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        if (message.id !== '0') {
            writer.uint32(24).int64(message.id);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): DeleteProfileRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeleteProfileRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 3:
                    message.id = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<DeleteProfileRequest>): DeleteProfileRequest {
        const message = createBaseDeleteProfileRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.username = object.username ?? '';
        message.id = object.id ?? '0';
        return message;
    }
};

function createBasePersistPreferenceRequest(): PersistPreferenceRequest {
    return { clientRequestId: '', profileId: '0', key: '', value: '' };
}

export const PersistPreferenceRequest = {
    encode(message: PersistPreferenceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.profileId !== '0') {
            writer.uint32(16).int64(message.profileId);
        }
        if (message.key !== '') {
            writer.uint32(26).string(message.key);
        }
        if (message.value !== '') {
            writer.uint32(34).string(message.value);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): PersistPreferenceRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePersistPreferenceRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.profileId = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.key = reader.string();
                    break;
                case 4:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<PersistPreferenceRequest>): PersistPreferenceRequest {
        const message = createBasePersistPreferenceRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.profileId = object.profileId ?? '0';
        message.key = object.key ?? '';
        message.value = object.value ?? '';
        return message;
    }
};

function createBaseDeletePreferenceRequest(): DeletePreferenceRequest {
    return { clientRequestId: '', profileId: '0', key: '' };
}

export const DeletePreferenceRequest = {
    encode(message: DeletePreferenceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.profileId !== '0') {
            writer.uint32(16).int64(message.profileId);
        }
        if (message.key !== '') {
            writer.uint32(26).string(message.key);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): DeletePreferenceRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeletePreferenceRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.profileId = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.key = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<DeletePreferenceRequest>): DeletePreferenceRequest {
        const message = createBaseDeletePreferenceRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.profileId = object.profileId ?? '0';
        message.key = object.key ?? '';
        return message;
    }
};

function createBaseFindProfilesRequest(): FindProfilesRequest {
    return { clientRequestId: '' };
}

export const FindProfilesRequest = {
    encode(message: FindProfilesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindProfilesRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindProfilesRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindProfilesRequest>): FindProfilesRequest {
        const message = createBaseFindProfilesRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseFindProfilesByUsernameAndType(): FindProfilesByUsernameAndType {
    return { clientRequestId: '', username: '', type: '' };
}

export const FindProfilesByUsernameAndType = {
    encode(message: FindProfilesByUsernameAndType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        if (message.type !== '') {
            writer.uint32(26).string(message.type);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindProfilesByUsernameAndType {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindProfilesByUsernameAndType();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 3:
                    message.type = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindProfilesByUsernameAndType>): FindProfilesByUsernameAndType {
        const message = createBaseFindProfilesByUsernameAndType();
        message.clientRequestId = object.clientRequestId ?? '';
        message.username = object.username ?? '';
        message.type = object.type ?? '';
        return message;
    }
};

function createBaseFindPreferencesForProfileIdRequest(): FindPreferencesForProfileIdRequest {
    return { clientRequestId: '', profileId: '0' };
}

export const FindPreferencesForProfileIdRequest = {
    encode(message: FindPreferencesForProfileIdRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.profileId !== '0') {
            writer.uint32(16).int64(message.profileId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindPreferencesForProfileIdRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindPreferencesForProfileIdRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.profileId = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindPreferencesForProfileIdRequest>): FindPreferencesForProfileIdRequest {
        const message = createBaseFindPreferencesForProfileIdRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.profileId = object.profileId ?? '0';
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
