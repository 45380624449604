/* eslint-disable */
export const protobufPackage = 'com.celertech.orderrouting.api.enums.pegpricetype';

export enum PegPriceType {
    LAST_PEG = 1,
    MID_PRICE_PEG = 2,
    OPENING_PEG = 3,
    MARKET_PEG = 4,
    PRIMARY_PEG = 5,
    PEG_TO_VWAP = 6,
    TRAILING_STOP_PEG = 7,
    PEG_TO_LIMIT_PRICE = 8,
    SHORT_SALE_MIN_PRICE_PEG = 9,
    UNRECOGNIZED = -1
}
