import React, { useState } from 'react';

// type UseContextMenuReturn = [(event: React.MouseEvent) => void, () => void];
export type UseContextMenuReturn = {
    contextMenuX: number;
    contextMenuY: number;
    contextMenuVisible: boolean;
    handleOpenContextMenu: (event: React.MouseEvent) => void;
    handleCloseContextMenu: () => void;
};

const useContextMenu = (): UseContextMenuReturn => {
    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const [contextMenuX, setContextMenuX] = useState(0);
    const [contextMenuY, setContextMenuY] = useState(0);

    const handleOpenContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenuVisible(true);
        setContextMenuX(event.clientX);
        setContextMenuY(event.clientY);
    };

    const handleCloseContextMenu = () => {
        setContextMenuVisible(false);
    };

    return {
        contextMenuX,
        contextMenuY,
        contextMenuVisible,
        handleOpenContextMenu,
        handleCloseContextMenu
    };
};

export default useContextMenu;
