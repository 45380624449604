import { FileExtensionsBasedOnMimeType, notSupportedVideosTypes } from '@/constants/general';
import { useSso } from '@/utils/providers/SSOProvider';
import { FileWithPath } from '@mantine/dropzone';
import { useFullscreen } from '@mantine/hooks';
import cn from 'classnames';
import { saveAs } from 'file-saver';
import React, { Dispatch, Fragment, Ref, SetStateAction, useMemo } from 'react';
import { MdClose, MdDownload } from 'react-icons/md';
import { PdfViewer } from '../common/PdfViewer';

export type DocumentPreviewProps = {
    files: FileWithPath[];
    setFiles: Dispatch<SetStateAction<FileWithPath[]>>;
    previewFile?: any;
    fileType?: any;
    requestDetails: {
        entityId: number;
        requestId: number;
    };
};

type DeleteButtonProps = {
    className?: string;
    setFiles: Dispatch<SetStateAction<FileWithPath[]>>;
};

const DeleteButton: React.FC<DeleteButtonProps> = ({ className, setFiles }) => {
    return (
        <button
            title="Remove file"
            onClick={(e) => {
                e.stopPropagation();
                setFiles([]);
            }}
            className={cn(
                `z-10 bg-secondary rounded-full p-1 flex items-center justify-center absolute top-2 right-2 ${className}`
            )}>
            <MdClose className="w-4 h-4" />
        </button>
    );
};

export const DocumentPreview: React.FC<DocumentPreviewProps> = ({
    files,
    setFiles,
    previewFile,
    fileType,
    requestDetails
}) => {
    const { ref, toggle } = useFullscreen();
    const { foundEntity } = useSso();

    const fileData = useMemo(() => {
        const blob = new Blob([previewFile]);
        return URL.createObjectURL(blob);
    }, [previewFile]);

    const fileName = `${
        requestDetails
            ? requestDetails.requestId + '-' + requestDetails.entityId + '-proof-of-ownership'
            : foundEntity?.entityId + '-proof-of-ownership'
    }${FileExtensionsBasedOnMimeType[fileType]}`;

    return (
        <div className="text-neutral-200 h-full flex justify-center">
            {previewFile && fileType ? (
                <Fragment>
                    {fileType.includes('pdf') && <PdfViewer file={fileData} />}
                    {fileType.includes('image') && (
                        <div className="relative flex justify-center h-full w-full border border-neutral-500 rounded-sm">
                            <img
                                ref={ref}
                                src={fileData as string}
                                className="object-contain rounded-sm hover:cursor-zoom-in h-full"
                                alt="proof of ownership"
                                onClick={toggle}
                            />
                        </div>
                    )}
                    {fileType.includes('video') && (
                        <div className=" relative flex justify-center h-full w-full">
                            {notSupportedVideosTypes.includes(fileType) ? (
                                <div className="bg-brand-background h-full w-full text-center space-y-4 bg-opacity-35 flex flex-col items-center justify-center">
                                    <div>
                                        The video is not supported by the browser. <br /> Please download the video to
                                        view it.
                                    </div>
                                    <button
                                        type="button"
                                        title="Download Video"
                                        onClick={() => {
                                            saveAs(fileData, fileName);
                                        }}
                                        className=" bg-primary-600 hover:bg-primary-700 z-10 rounded-full flex w-auto px-2 py-1 items-center text-xs ">
                                        Download Video
                                        <MdDownload className="p-1 w-6 h-6 text-white rounded-full " />
                                    </button>
                                </div>
                            ) : (
                                <>
                                    <button
                                        type="button"
                                        title="Download Video"
                                        onClick={() => {
                                            saveAs(fileData, fileName);
                                        }}
                                        className="bg-primary-500 hover:bg-primary-700 absolute top-2 right-2 z-10 rounded-full flex w-auto px-2 py-1 items-center text-xs ">
                                        Download Video
                                        <MdDownload className="p-1 w-6 h-6 text-white rounded-full " />
                                    </button>
                                    <video controls src={fileData} className="object-cover h-full" />
                                </>
                            )}
                        </div>
                    )}
                </Fragment>
            ) : (
                <UploadedDocumentPreview ref={ref} files={files} setFiles={setFiles} toggle={toggle} />
            )}
        </div>
    );
};

interface UploadedDocumentPreviewProps {
    ref: Ref<HTMLImageElement>;
    files: FileWithPath[];
    setFiles: Dispatch<SetStateAction<FileWithPath[]>>;
    toggle: () => void;
}

const UploadedDocumentPreview = ({ ref, files, setFiles, toggle }: UploadedDocumentPreviewProps) => {
    const file = useMemo(() => {
        return files.length > 0 ? files[0] : null;
    }, [files]);

    const fileUrl = useMemo(() => {
        if (file) return URL.createObjectURL(file);
        return undefined;
    }, [file]);

    return (
        <Fragment>
            {file && (
                <div className="h-full flex justify-center w-full">
                    <>
                        {getFileType(file) === 'pdf' && (
                            <div className="relative h-full w-full">
                                <DeleteButton setFiles={setFiles} />
                                <PdfViewer file={fileUrl} />
                            </div>
                        )}
                        {getFileType(file) === 'image' && (
                            <div className="relative flex justify-center h-full w-full border border-neutral-500 rounded-sm">
                                <DeleteButton setFiles={setFiles} />
                                <img
                                    ref={ref}
                                    src={fileUrl}
                                    className="object-contain rounded-sm hover:cursor-zoom-in h-full"
                                    alt={`${file.name}`}
                                    onClick={toggle}
                                />
                            </div>
                        )}
                        {getFileType(file) === 'video' && (
                            <div className="relative flex justify-center h-full w-full border border-neutral-500 rounded-sm overflow-hidden">
                                <DeleteButton setFiles={setFiles} />

                                {notSupportedVideosTypes.includes(file.type) ? (
                                    <div className="bg-black bg-opacity-35 h-full w-full text-center flex flex-col items-center justify-center">
                                        <div>
                                            The video is not supported by the browser, <br />
                                            but it is ready to be uploaded
                                        </div>
                                    </div>
                                ) : (
                                    <video controls src={fileUrl} className="object-cover h-full" />
                                )}
                            </div>
                        )}
                    </>
                </div>
            )}
        </Fragment>
    );
};

const getFileType = (file: File) => {
    switch (file.type) {
        case 'application/pdf':
            return 'pdf';
        case 'image/jpeg':
        case 'image/png':
            return 'image';
        case 'video/mp4':
        case 'video/avi':
        case 'video/x-msvideo':
        case 'video/msvideo':
        case 'application/x-troff-msvideo':
        case 'video/mpeg':
        case 'video/webm':
            return 'video';
        default:
            return 'unknown';
    }
};
