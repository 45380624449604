/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.staticdata.api.user.account';

export interface CreateUserAccount {
    clientRequestId: string;
    username: string;
    accountId: string;
    accountCode: string;
}

export interface DeleteUserAccount {
    clientRequestId: string;
    username: string;
    accountCode: string;
}

export interface FindAllUserAccounts {
    clientRequestId: string;
}

export interface FindAssignedUserAccounts {
    clientRequestId: string;
}

export interface FindUserAccountsByUsername {
    clientRequestId: string;
    username: string;
}

export interface UpdateOnStaticDataUserAccountRequest {
    clientRequestId: string;
}

export interface FindUserAccountsByAccountCode {
    clientRequestId: string;
    accountCode: string;
}

function createBaseCreateUserAccount(): CreateUserAccount {
    return { clientRequestId: '', username: '', accountId: '0', accountCode: '' };
}

export const CreateUserAccount = {
    encode(message: CreateUserAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        if (message.accountId !== '0') {
            writer.uint32(24).int64(message.accountId);
        }
        if (message.accountCode !== '') {
            writer.uint32(34).string(message.accountCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateUserAccount {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateUserAccount();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 3:
                    message.accountId = longToString(reader.int64() as Long);
                    break;
                case 4:
                    message.accountCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateUserAccount>): CreateUserAccount {
        const message = createBaseCreateUserAccount();
        message.clientRequestId = object.clientRequestId ?? '';
        message.username = object.username ?? '';
        message.accountId = object.accountId ?? '0';
        message.accountCode = object.accountCode ?? '';
        return message;
    }
};

function createBaseDeleteUserAccount(): DeleteUserAccount {
    return { clientRequestId: '', username: '', accountCode: '' };
}

export const DeleteUserAccount = {
    encode(message: DeleteUserAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        if (message.accountCode !== '') {
            writer.uint32(26).string(message.accountCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUserAccount {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeleteUserAccount();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 3:
                    message.accountCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<DeleteUserAccount>): DeleteUserAccount {
        const message = createBaseDeleteUserAccount();
        message.clientRequestId = object.clientRequestId ?? '';
        message.username = object.username ?? '';
        message.accountCode = object.accountCode ?? '';
        return message;
    }
};

function createBaseFindAllUserAccounts(): FindAllUserAccounts {
    return { clientRequestId: '' };
}

export const FindAllUserAccounts = {
    encode(message: FindAllUserAccounts, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAllUserAccounts {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAllUserAccounts();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAllUserAccounts>): FindAllUserAccounts {
        const message = createBaseFindAllUserAccounts();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseFindAssignedUserAccounts(): FindAssignedUserAccounts {
    return { clientRequestId: '' };
}

export const FindAssignedUserAccounts = {
    encode(message: FindAssignedUserAccounts, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAssignedUserAccounts {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAssignedUserAccounts();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAssignedUserAccounts>): FindAssignedUserAccounts {
        const message = createBaseFindAssignedUserAccounts();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseFindUserAccountsByUsername(): FindUserAccountsByUsername {
    return { clientRequestId: '', username: '' };
}

export const FindUserAccountsByUsername = {
    encode(message: FindUserAccountsByUsername, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindUserAccountsByUsername {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindUserAccountsByUsername();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindUserAccountsByUsername>): FindUserAccountsByUsername {
        const message = createBaseFindUserAccountsByUsername();
        message.clientRequestId = object.clientRequestId ?? '';
        message.username = object.username ?? '';
        return message;
    }
};

function createBaseUpdateOnStaticDataUserAccountRequest(): UpdateOnStaticDataUserAccountRequest {
    return { clientRequestId: '' };
}

export const UpdateOnStaticDataUserAccountRequest = {
    encode(message: UpdateOnStaticDataUserAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOnStaticDataUserAccountRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateOnStaticDataUserAccountRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UpdateOnStaticDataUserAccountRequest>): UpdateOnStaticDataUserAccountRequest {
        const message = createBaseUpdateOnStaticDataUserAccountRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseFindUserAccountsByAccountCode(): FindUserAccountsByAccountCode {
    return { clientRequestId: '', accountCode: '' };
}

export const FindUserAccountsByAccountCode = {
    encode(message: FindUserAccountsByAccountCode, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.accountCode !== '') {
            writer.uint32(18).string(message.accountCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindUserAccountsByAccountCode {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindUserAccountsByAccountCode();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.accountCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindUserAccountsByAccountCode>): FindUserAccountsByAccountCode {
        const message = createBaseFindUserAccountsByAccountCode();
        message.clientRequestId = object.clientRequestId ?? '';
        message.accountCode = object.accountCode ?? '';
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
