/* eslint-disable */
export const protobufPackage = 'com.celertech.marketdata.api.enums.assettype';

export enum AssetType {
    EQUITY = 1,
    FUTURE = 2,
    OPTION = 3,
    FX = 4,
    FIXED_INCOME = 5,
    CRYPTO = 6,
    UNRECOGNIZED = -1
}
