import { InferType, number, object, string } from 'yup';
import { Nullable } from '../../../model/common';

export const cryptoDepositSchema = () =>
    object({
        // select
        account: object({ label: string(), value: string() }).test(
            'valueNotUndefined',
            `Please select an account`,
            (acc) => !!acc.value
        ),
        amount: number()
            .required('Deposit Amount cannot be empty!')
            .typeError('Invalid Input: Please input a number.')
            .min(0, 'Invalid Input: Can not be negative.'),
        asset: object({ label: string(), value: string() }).test(
            'valueNotUndefined',
            `Please select a crypto asset`,
            (ccy) => !!ccy.value
        ),
        address: string().required('Invalid Input: Please input an address.'),
        email: object({
            cc_to: string()
        }).optional()
    });

export type CryptoDepositFormValues = InferType<ReturnType<typeof cryptoDepositSchema>>;
export type CryptoDepositFormInput = Nullable<CryptoDepositFormValues>;
