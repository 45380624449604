/* eslint-disable */
export const protobufPackage = 'com.celertech.marketdata.api.enums.handlinginstruction';

export enum HandlingInstruction {
    /** AUTOMATED_NO_BROKER - Automated execution without broker intervention. */
    AUTOMATED_NO_BROKER = 1,
    /** AUTOMATED_BROKER_OK - Automated execution, possibly with intervention by a broker. */
    AUTOMATED_BROKER_OK = 2,
    /** MANUAL - Manual execution, managed by a broker. */
    MANUAL = 3,
    UNRECOGNIZED = -1
}
