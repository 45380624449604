import {
    notificationDurationOptions,
    notificationShowOptions
} from '@/components/modal/SettingsModal/NotificationsTab';
import { Modify } from '@/model/common';
import { useAppSelector } from '@/state/hooks';
import { User, doLogout } from '@/state/reducers/authSlice';
import { pushNotification } from '@/state/reducers/notificationSlice';
import { ModifiedToastOptions, selectNotificationSettings } from '@/state/reducers/settingSlice';
import { RootState, store } from '@/state/store';
import history from '@/utils/history';
import { ReactNode } from 'react';
import { FieldErrors } from 'react-hook-form';
import { Toast, ToastOptions, toast } from 'react-hot-toast';

interface AppendedToastOptions extends Modify<ToastOptions, { show?: boolean }> {}

interface UseToastsProps {
    body: string | ReactNode;
    title?: string;
    persist?: boolean;
    toastConfig?: AppendedToastOptions;
}

export interface ToastProps extends UseToastsProps {
    toastConfig: AppendedToastOptions;
}

interface ToastContentProps extends UseToastsProps {
    t: Toast;
}

const useToast = () => {
    const notificationSettings = useAppSelector(selectNotificationSettings);
    const defaultConfig = parseNotificationSettings(notificationSettings);

    const error = ({ body, title, persist, toastConfig }: UseToastsProps) =>
        toastError({ body, title, persist, toastConfig: { ...defaultConfig, ...toastConfig } });
    const success = ({ body, title, toastConfig }: UseToastsProps) =>
        toastSuccess({ body, title, toastConfig: { ...defaultConfig, ...toastConfig } });
    const info = ({ body, title, toastConfig }: UseToastsProps) =>
        toastInfo({ body, title, toastConfig: { ...defaultConfig, ...toastConfig } });

    return [error, success, info];
};

export default useToast;

export const toastError = ({ body, title, persist = false, toastConfig }: ToastProps) => {
    // if (toastConfig.show) toast.error((t) => toastContent({ t, body, title }), toastConfig);
    if (persist) store.dispatch(pushNotification({ type: 'error', body, title, created_at: new Date() }));
};

export const toastSuccess = ({ body, title, toastConfig }: ToastProps) => {
    // if (toastConfig.show) toast.success((t) => toastContent({ t, body, title }), toastConfig);
    store.dispatch(pushNotification({ type: 'success', body, title, created_at: new Date() }));
};

export const toastInfo = ({ body, title, toastConfig }: ToastProps) => {
    // if (toastConfig.show) toast((t) => toastContent({ t, body, title }), toastConfig);
    // store.dispatch(pushNotification({ type: 'info', body, title, created_at: new Date() }));
};

const toastContent = ({ t, body, title }: ToastContentProps) => (
    <div className="hover:cursor-pointer text-sm w-[290px]" onClick={() => toast.dismiss(t.id)}>
        {title && (
            <>
                <strong>{title}</strong>
                <br />
            </>
        )}
        {body}
    </div>
);

export const formatFieldErrorMessages = (errors: FieldErrors) => {
    const keys = Object.keys(errors);

    let body = '';

    keys.forEach((key) => {
        body += `[${key}] ${errors[key]?.message}\n`;
    });

    return body;
};

/* 
    formatFieldErrorMessages can be used with form handleSubmit as such

    const onError = (e: FieldErrors) => {
        const body = formatFieldErrorMessages(e);
        toastError({ body, title: 'Form Error' });
    };

    handleSubmit(onSubmit, onError)
*/

export const parseNotificationSettings = (notificationSettings: ModifiedToastOptions): AppendedToastOptions => {
    return {
        ...notificationSettings,
        show: notificationSettings?.show?.value,
        duration: notificationSettings.duration.value
    };
};

export const toastLogout = (credentials: User) => {
    const state: RootState = store.getState();
    const dispatch = store.dispatch;
    const toastConfig = parseNotificationSettings({
        id: 'force_logout',
        ...state.settings.notifications,
        show: notificationShowOptions[0],
        duration: notificationDurationOptions[0]
    });

    // to prevent old credentials being used

    if (credentials.authToken === state.auth.user?.authToken) {
        dispatch(doLogout({ credentials }) as any);
        toastError({ title: 'You have been logged out', body: 'Service disconnected', toastConfig });
        history.push('/login');
    }
};
