import { ChangeEventHandler, ComponentProps, ReactElement, cloneElement, forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Loader from '../common/Loader';
import { FormInputWrapper } from './FormInputWrapper';

interface InputControllerProps extends ComponentProps<'input'> {
    // name?: string;
    // label?: string;
    // labelClassName?: string;
    // description?: string;
    // className?: any;
    // onChangeOverride?: ChangeEventHandler<HTMLInputElement>;
    // asyncInput?: boolean;
    // alignStart?: boolean;
    // children: ReactElement;
    name?: string;
    // data?: Option[];
    label?: string | JSX.Element;
    description?: string;
    className?: any;
    onChangeOverride?: ChangeEventHandler<HTMLInputElement>;
    // onChangeOverride?: (event: ChangeEvent, callback: ChangeEventHandler<HTMLInputElement>) => void;
    onChangeCallback?: ChangeEventHandler<HTMLInputElement>;
    children: ReactElement;
    asyncInput?: boolean;
    alignStart?: boolean;
    error?: string;
    labelClassName?: string;
    inputInfo?: JSX.Element;
    errorPadding?: boolean;
}

const InputController = forwardRef<HTMLInputElement, InputControllerProps>((props, ref) => {
    const {
        name = '',
        label,
        children,
        alignStart,
        asyncInput,
        inputInfo,
        labelClassName,
        description,
        errorPadding,
        ...rest
    } = props;
    const { required, onChangeOverride, disabled } = rest;
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState: { isSubmitted, errors, defaultValues }
            }) => {
                return (
                    <FormInputWrapper
                        label={label}
                        error={error}
                        required={required}
                        description={description}
                        labelClassName={labelClassName}
                        inputInfo={inputInfo}
                        alignStart={alignStart}
                        errorPadding={errorPadding}>
                        {asyncInput ? (
                            <Loader className="ml-0 mr-0" />
                        ) : (
                            <>
                                {cloneElement(children, {
                                    ref,
                                    id: name,
                                    name,
                                    value,
                                    onBlur,
                                    onChange: (e) => {
                                        if (onChangeOverride) onChangeOverride(e);
                                        else onChange(e);
                                        // if (onChangeCallback) onChangeCallback(e);
                                    },
                                    selected: value,
                                    checked: value,
                                    defaultValue: defaultValues && defaultValues[name],
                                    className: {
                                        'text-neutral-400 cursor-not-allowed': disabled,
                                        '': !disabled
                                    },
                                    ...rest
                                })}
                            </>
                        )}
                    </FormInputWrapper>
                );
            }}
        />
    );
});

InputController.displayName = 'InputController';

export default InputController;
