type Environment = 'local' | 'uat' | 'demo' | 'prod';

const spotLogo = window.config?.logo;
const traderLogo = window.config?.trader_logo;
const digitalLogo = window.config?.digital_logo;

const UAT_HOSTNAME = window.config.envhostname?.uat;
const TRADER_UAT_HOSTNAME = window.config.envhostname?.['trader-uat'];
const DIGITAL_UAT_HOSTNAME = window.config.envhostname?.['digital-uat'];
const PORTAL_UAT_HOSTNAME = window.config.envhostname?.['portal-uat'];
const DEMO_HOSTNAME = window.config.envhostname?.demo;
const TRADER_DEMO_HOSTNAME = window.config.envhostname?.['trader-demo'];
const DIGITAL_DEMO_HOSTNAME = window.config.envhostname?.['digital-demo'];
const PORTAL_DEMO_HOSTNAME = window.config.envhostname?.['portal-demo'];
const PROD_HOSTNAME = window.config.envhostname?.prod;
const TRADER_PROD_HOSTNAME = window.config.envhostname?.['trader-prod'];
const DIGITAL_PROD_HOSTNAME = window.config.envhostname?.['digital-prod'];
const PORTAL_PROD_HOSTNAME = window.config.envhostname?.['portal-prod'];

export const getEnvironment = (): Environment => {
    if (window && window.location) {
        switch (window.location.hostname) {
            case 'onechart':
                return 'local';
            case 'localhost':
                return 'local';
            case UAT_HOSTNAME:
                return 'uat';
            case TRADER_UAT_HOSTNAME:
                return 'uat';
            case DIGITAL_UAT_HOSTNAME:
                return 'uat';
            case DEMO_HOSTNAME:
                return 'demo';
            case TRADER_DEMO_HOSTNAME:
                return 'demo';
            case DIGITAL_DEMO_HOSTNAME:
                return 'demo';
            case PROD_HOSTNAME:
                return 'prod';
            case TRADER_PROD_HOSTNAME:
                return 'prod';
            case DIGITAL_PROD_HOSTNAME:
                return 'prod';
            default:
                return 'local';
        }
    }

    return 'local';
};

export const getPlatform = () => {
    if (window && window.location) {
        switch (window.location.hostname) {
            case TRADER_UAT_HOSTNAME:
                return 'trader';
            case DIGITAL_UAT_HOSTNAME:
                return 'digital';
            case TRADER_DEMO_HOSTNAME:
                return 'trader';
            case DIGITAL_DEMO_HOSTNAME:
                return 'digital';
            case TRADER_PROD_HOSTNAME:
                return 'trader';
            case DIGITAL_PROD_HOSTNAME:
                return 'digital';
            default:
                return 'spot';
        }
    }

    return 'spot';
};

export const getPlatformLogo = () => {
    if (window && window.location) {
        switch (window.location.hostname) {
            case TRADER_UAT_HOSTNAME:
                return traderLogo;
            case DIGITAL_UAT_HOSTNAME:
                return digitalLogo;
            case TRADER_DEMO_HOSTNAME:
                return traderLogo;
            case DIGITAL_DEMO_HOSTNAME:
                return digitalLogo;
            case TRADER_PROD_HOSTNAME:
                return traderLogo;
            case DIGITAL_PROD_HOSTNAME:
                return digitalLogo;
            default:
                return spotLogo;
        }
    }

    return spotLogo;
};

export const getProtocol = () => {
    switch (window.location.hostname) {
        case 'localhost':
            return 'http:';
        default:
            return 'https:';
    }
};

export const getPortalURL = () => {
    if (window && window.location) {
        switch (window.location.hostname) {
            case 'onechart':
                return PORTAL_DEMO_HOSTNAME;
            case 'localhost':
                return 'localhost:3000';
            case UAT_HOSTNAME:
                return PORTAL_UAT_HOSTNAME;
            case TRADER_UAT_HOSTNAME:
                return PORTAL_UAT_HOSTNAME;
            case DIGITAL_UAT_HOSTNAME:
                return PORTAL_UAT_HOSTNAME;
            case DEMO_HOSTNAME:
                return PORTAL_DEMO_HOSTNAME;
            case TRADER_DEMO_HOSTNAME:
                return PORTAL_DEMO_HOSTNAME;
            case DIGITAL_DEMO_HOSTNAME:
                return PORTAL_DEMO_HOSTNAME;
            case PROD_HOSTNAME:
                return PORTAL_PROD_HOSTNAME;
            case TRADER_PROD_HOSTNAME:
                return PORTAL_PROD_HOSTNAME;
            case DIGITAL_PROD_HOSTNAME:
                return PORTAL_PROD_HOSTNAME;
            default:
                return PORTAL_DEMO_HOSTNAME;
        }
    }

    return PORTAL_DEMO_HOSTNAME;
};

export const shouldUseNopBalance = ['local', 'uat', 'prod'].includes(getEnvironment());
export const protocol = getProtocol();
export const portalURL = getPortalURL();
export const portalAPI = (url: string) => `${protocol}//${portalURL}${url}`;
