import AccountValue from '@/components/trader-modules/AccountValue';
import MarketList from '@/components/trader-modules/MarketList';
import Notifications from '@/components/trader-modules/Notifications';
import { IconType } from 'react-icons/lib';
import {
    MdAccountBalanceWallet,
    MdCandlestickChart,
    MdHistory,
    MdNotifications,
    MdPivotTableChart,
    MdPriceChange
} from 'react-icons/md';
import { MosaicNode } from 'react-mosaic-component';

export type XplorTraderModules = 'Tickers' | 'Account Value' | 'Blotter' | 'Markets' | 'Chart' | 'Notifications';
export const xplorTraderTabList = ['Tickers', 'Account Value', 'Blotter', 'Markets', 'Chart', 'Notifications'];
export const xplorTraderTabIcon = {
    Tickers: MdPriceChange,
    Blotter: MdHistory,
    'Account Value': MdAccountBalanceWallet,
    Chart: MdCandlestickChart,
    Markets: MdPivotTableChart,
    Notifications: MdNotifications
} as Record<XplorTraderModules, IconType>;

export const DEFAULT_LAYOUT: MosaicNode<XplorTraderModules> = {
    direction: 'row',
    first: {
        direction: 'column',
        first: {
            direction: 'row',
            first: 'Chart',
            second: 'Tickers',
            splitPercentage: 45
        },
        second: {
            direction: 'row',
            first: 'Account Value',
            second: 'Blotter',
            splitPercentage: 30
        },
        splitPercentage: 60
    },
    second: 'Markets',
    splitPercentage: 78
};

export const DEFAULT_MOBILE_LAYOUT: MosaicNode<XplorTraderModules> = {
    direction: 'row',
    first: 'Notifications',
    second: 'Tickers',
    splitPercentage: 0
};

export const DEFAULT_TABLET_LAYOUT: MosaicNode<XplorTraderModules> = {
    direction: 'row',
    first: 'Tickers',
    second: 'Chart',
    splitPercentage: 45
};

export const DEFAULT_LAPTOP_LAYOUT: MosaicNode<XplorTraderModules> = {
    direction: 'row',
    first: {
        direction: 'column',
        first: {
            direction: 'row',
            first: 'Chart',
            second: 'Tickers',
            splitPercentage: 50
        },
        second: {
            direction: 'row',
            first: 'Account Value',
            second: 'Blotter',
            splitPercentage: 30
        },
        splitPercentage: 60
    },
    second: 'Markets',
    splitPercentage: 71
};

export const xplorTraderLayoutConfig: Record<
    string,
    {
        title: string;
        component?: JSX.Element;
    }
> = {
    Tickers: {
        title: 'Tickers'
    },
    Blotter: {
        title: 'Blotter'
    },
    Chart: {
        title: 'Chart'
    },
    'Account Value': {
        title: 'Account Value',
        component: (
            <div id="accountValue" className="h-full bg-brand-background">
                <AccountValue />
            </div>
        )
    },
    Markets: {
        title: 'Markets',
        component: (
            <div id="market-list" className="bg-brand-background h-full max-h-full overflow-hidden">
                <MarketList />
            </div>
        )
    },
    Notifications: {
        title: 'Notifications',
        component: (
            <div id="notifications" className="bg-brand-background h-full max-h-full overflow-hidden">
                <Notifications />
            </div>
        )
    }
};
