/* eslint-disable */
import { CallContext, CallOptions } from 'nice-grpc-common';
import { Empty } from '../../../../../google/protobuf/empty';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.baseserver.communication.login';

export interface GrpcLogoutMessage {
    authorizationToken: string;
    successful: boolean;
    message: string;
    forcedLogout: boolean;
}

function createBaseGrpcLogoutMessage(): GrpcLogoutMessage {
    return { authorizationToken: '', successful: false, message: '', forcedLogout: false };
}

export const GrpcLogoutMessage = {
    encode(message: GrpcLogoutMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.authorizationToken !== '') {
            writer.uint32(10).string(message.authorizationToken);
        }
        if (message.successful === true) {
            writer.uint32(16).bool(message.successful);
        }
        if (message.message !== '') {
            writer.uint32(26).string(message.message);
        }
        if (message.forcedLogout === true) {
            writer.uint32(32).bool(message.forcedLogout);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): GrpcLogoutMessage {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGrpcLogoutMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.authorizationToken = reader.string();
                    break;
                case 2:
                    message.successful = reader.bool();
                    break;
                case 3:
                    message.message = reader.string();
                    break;
                case 4:
                    message.forcedLogout = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<GrpcLogoutMessage>): GrpcLogoutMessage {
        const message = createBaseGrpcLogoutMessage();
        message.authorizationToken = object.authorizationToken ?? '';
        message.successful = object.successful ?? false;
        message.message = object.message ?? '';
        message.forcedLogout = object.forcedLogout ?? false;
        return message;
    }
};

export type LogoutServiceDefinition = typeof LogoutServiceDefinition;
export const LogoutServiceDefinition = {
    name: 'LogoutService',
    fullName: 'com.celertech.baseserver.communication.login.LogoutService',
    methods: {
        logout: {
            name: 'logout',
            requestType: Empty,
            requestStream: false,
            responseType: GrpcLogoutMessage,
            responseStream: false,
            options: {}
        }
    }
} as const;

export interface LogoutServiceServiceImplementation<CallContextExt = {}> {
    logout(request: Empty, context: CallContext & CallContextExt): Promise<DeepPartial<GrpcLogoutMessage>>;
}

export interface LogoutServiceClient<CallOptionsExt = {}> {
    logout(request: DeepPartial<Empty>, options?: CallOptions & CallOptionsExt): Promise<GrpcLogoutMessage>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;
