import { XplorTraderModules } from '@/config/xplorTraderLayout';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MosaicNode } from 'react-mosaic-component';
import { RootState } from '../store';

interface XplorTraderLayoutState {
    desktopNode?: MosaicNode<XplorTraderModules> | null;
    mobileNode?: MosaicNode<XplorTraderModules> | null;
    tabletNode?: MosaicNode<XplorTraderModules> | null;
    laptopNode?: MosaicNode<XplorTraderModules> | null;
}

const initialState: XplorTraderLayoutState = {
    mobileNode: undefined,
    tabletNode: undefined,
    laptopNode: undefined,
    desktopNode: undefined
};

export const xplorTraderLayoutSlice = createSlice({
    name: 'xplorTraderLayout',
    initialState,
    reducers: {
        setXplorTraderMobileLayoutNode: (state, action: PayloadAction<MosaicNode<XplorTraderModules> | null>) => {
            state.mobileNode = action.payload;
        },
        setXplorTraderTabletLayoutNode: (state, action: PayloadAction<MosaicNode<XplorTraderModules> | null>) => {
            state.tabletNode = action.payload;
        },
        setXplorTraderLaptopLayoutNode: (state, action: PayloadAction<MosaicNode<XplorTraderModules> | null>) => {
            state.laptopNode = action.payload;
        },
        setXplorTraderDesktopLayoutNode: (state, action: PayloadAction<MosaicNode<XplorTraderModules> | null>) => {
            state.desktopNode = action.payload;
        }
    }
});

export const {
    setXplorTraderMobileLayoutNode,
    setXplorTraderTabletLayoutNode,
    setXplorTraderLaptopLayoutNode,
    setXplorTraderDesktopLayoutNode
} = xplorTraderLayoutSlice.actions;

export const selectXplorTraderMobileLayoutNode = (state: RootState) => state.xplorTraderLayout.mobileNode;
export const selectXplorTraderTabletLayoutNode = (state: RootState) => state.xplorTraderLayout.tabletNode;
export const selectXplorTraderLaptopLayoutNode = (state: RootState) => state.xplorTraderLayout.laptopNode;
export const selectXplorTraderDesktopLayoutNode = (state: RootState) => state.xplorTraderLayout.desktopNode;

export default xplorTraderLayoutSlice.reducer;
