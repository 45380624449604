import { store } from '@/state/store';

const xplorspotEnabled = window.config?.layout?.xplorspot?.enabled;
const xplorspotliteEnabled = window.config?.layout?.xplorspotlite?.enabled;

export const showLayoutSwitch = xplorspotEnabled && xplorspotliteEnabled;

type Layout = 'xplorspot' | 'xplorspotlite' | 'xplortrader';

export function isCorrectLayout(correctLayout: Layout) {
    const state = store.getState();
    const credentials = state.auth.user;
    const currentAccount = state.auth.currentAccount;

    const currentAccountProperties =
        credentials?.accounts.find((account) => account.code === currentAccount)?.properties || [];
    const accountType = currentAccountProperties.find((property) => property.key === 'TYPE')?.value;

    if (accountType === 'NOP') {
        return ['xplorspot', 'xplorspotlite'].includes(correctLayout);
    } else if (accountType === 'MARGIN') {
        return correctLayout === 'xplortrader';
    } else {
        return true;
    }
}
