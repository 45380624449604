import { Fragment, useCallback, useEffect, useState } from 'react';

import { Button } from '@/components/common/Button';
import CurrencyIcon from '@/components/common/CurrencyIcon';
import { Modify } from '@/model/common';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { LiteMarket, selectLiteMarkets, setLiteMarkets } from '@/state/reducers/liteMarketSlice';
import { useCurrency } from '@/utils/hooks/useCurrency';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cn from 'classnames';
import { MdDone, MdDragIndicator, MdRemoveCircle, MdSwapHoriz } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import Modal, { ModalClose, ModalHeader, ModalProps, ModalTitle } from '../Modal';
import AddMarketForm from './AddMarketForm';

const LiteMarketsModal = (props: ModalProps) => {
    const { opened, handlers } = props;
    const dispatch = useAppDispatch();
    const liteMarkets = useAppSelector(selectLiteMarkets);

    const removal = useDisclosure(false);
    const [items, setItems] = useState<Modify<LiteMarket, { id: any }>[]>(
        liteMarkets.map((market) => ({ id: uuidv4(), ...market }))
    );

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const onSubmit = useCallback(() => {
        dispatch(
            setLiteMarkets(
                items.map((item) => {
                    const { id, ...rest } = item;
                    return rest;
                }) as LiteMarket[]
            )
        );
        handlers.close();
    }, [items]);

    useEffect(() => {
        if (opened) {
            setItems(liteMarkets.map((market) => ({ id: uuidv4(), ...market })));
        }
    }, [opened]);

    return (
        <Fragment>
            <Modal {...props} size="max-w-lg">
                <div className="h-full flex flex-col lg:h-auto gap-4">
                    <div className="flex flex-col gap-1 p-6 pb-0">
                        <ModalHeader className="!p-0">
                            <ModalTitle>Market Monitoring Settings</ModalTitle>
                            <ModalClose handlers={handlers} />
                        </ModalHeader>
                        <span className="flex items-center text-neutral-400 text-sm">
                            Arrange the currencies by clicking & dragging the
                            <MdDragIndicator className="w-4 h-4 text-neutral-200" />
                            icon
                        </span>
                    </div>
                    <div className="flex-1 basis-0 lg:flex-auto px-6 h-auto lg:h-[750px] overflow-y-scroll flex flex-col gap-3">
                        {!!items.length && (
                            <span
                                className={cn('flex items-center w-fit text-neutral-200 text-sm cursor-pointer gap-1', {
                                    ['hover:text-brand-red-light']: !removal[0],
                                    ['hover:text-brand-primary-light']: removal[0]
                                })}
                                onClick={removal[1].toggle}>
                                {!removal[0] ? (
                                    <Fragment>
                                        Remove Markets <MdRemoveCircle className="w-4 h-4" />
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        Finished Editting <MdDone className="w-4 h-4" />
                                    </Fragment>
                                )}
                            </span>
                        )}
                        <DndContext
                            sensors={sensors}
                            modifiers={[restrictToVerticalAxis]}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}>
                            <SortableContext items={items} strategy={verticalListSortingStrategy}>
                                {items.map((item) => (
                                    <div key={item.id} className="flex items-center gap-3">
                                        {removal[0] && (
                                            <MdRemoveCircle
                                                className="w-5 h-5 text-neutral-400 hover:text-neutral-200 cursor-pointer"
                                                onClick={() => setItems(items.filter((i) => i.id !== item.id))}
                                            />
                                        )}
                                        <SortableItem {...item} />
                                    </div>
                                ))}
                            </SortableContext>
                        </DndContext>
                        <AddMarketForm items={items} setItems={setItems} />
                    </div>
                    <div className="flex justify-end p-6 pt-0">
                        <Button
                            type="button"
                            className="px-4 py-2 rounded-md text-sm text-neutral-200 bg-brand-primary hover:bg-brand-primary-dark"
                            onClick={onSubmit}>
                            Save Changes
                        </Button>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );

    function handleDragEnd(event) {
        const { active, over } = event;

        if (active.id !== over.id) {
            setItems((items) => {
                const oldIndex = items.findIndex((item) => item.id === active.id);
                const newIndex = items.findIndex((item) => item.id === over.id);

                return arrayMove(items, oldIndex, newIndex);
            });
        }
    }
};

export default LiteMarketsModal;

function SortableItem(props: Modify<LiteMarket, { id: number }>) {
    const { id, name, logo, toName } = props;
    const { longName } = useCurrency(name);

    const sortable = useSortable({ id });

    const { attributes, listeners, setNodeRef, transform, transition, isDragging, active } = sortable;

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };

    return (
        <div
            ref={setNodeRef}
            className="flex w-full justify-between items-center text-neutral-200 bg-brand-background border border-neutral-700 hover:border-neutral-600 outline-none active:outline active:outline-neutral-200 p-3 px-4 rounded-md"
            style={style}>
            <div className="flex flex-[2_1_0] items-center gap-3">
                <CurrencyIcon ccy={logo} />
                <div className="flex flex-col">
                    <span>{longName || name}</span>
                    <span className="flex items-center gap-3 text-neutral-400 text-2xs sm:text-xs">
                        {name} <MdSwapHoriz className="h-4 w-4" /> {toName}
                    </span>
                </div>
            </div>
            <div className="cursor-grab active:cursor-grabbing" {...attributes} {...listeners}>
                <MdDragIndicator className="w-6 h-6" />
            </div>
        </div>
    );
}
