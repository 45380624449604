import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '../common/Button';
import Divider from '../common/Divider';
import { CryptoWithdrawalFormValues } from '../form/schema/cryptoWithdrawalSchema';
import { FiatWithdrawalFormValues } from '../form/schema/fiatWithdrawalSchema';
import Modal, { ModalHeader, ModalProps, ModalTitle } from './Modal';

interface WithdrawalConfirmationModalProps extends ModalProps {
    type: string;
    user?: string;
    keys: Array<keyof CryptoWithdrawalFormValues> | Array<keyof FiatWithdrawalFormValues>;
    manualOnSubmit: () => void;
}

const WithdrawalConfirmationModal = (props: WithdrawalConfirmationModalProps) => {
    const { type, opened, handlers, user, keys, manualOnSubmit } = props;

    const {
        getValues,
        formState: { isSubmitting }
    } = useFormContext();

    const message = useMemo(() => {
        if (type === 'Crypto') {
            const cryptoKeys = keys as Array<keyof CryptoWithdrawalFormValues>;
            const [account, amount, asset, address, email] = getValues(cryptoKeys);

            return (
                <div className="flex flex-col space-y-2">
                    <div className="space-y-1">
                        <h3 className="text-md font-bold pb-1">Withdrawal Details</h3>

                        <p>User : {user}</p>
                        <p>Account To Withdraw : {account.value}</p>
                        <p>Withdrawal Amount : {amount}</p>
                        <p>Withdrawal Asset : {asset.value}</p>
                        <p>Wallet Address: {address}</p>
                    </div>
                    <div className="space-y-1">
                        <h3 className="text-md font-bold pb-1">Email Details</h3>

                        <p>CC To : {email?.cc_to || '-'}</p>
                    </div>
                </div>
            );
        } else {
            const fiatKeys = keys as Array<keyof FiatWithdrawalFormValues>;
            const [account, amount, currency, bank, intermediary_bank, email] = getValues(fiatKeys);

            return (
                <div className="flex flex-col space-y-2">
                    <div className="space-y-1">
                        <h3 className="text-md font-bold pb-1">Withdrawal Details</h3>

                        {/* <p>User : {account.}</p> */}
                        <p>Account To Withdraw : {account.value}</p>
                        <p>Withdrawal Amount : {amount}</p>
                        <p>Withdrawal Currency : {currency.value}</p>
                    </div>
                    <div className="space-y-1">
                        <h3 className="text-md font-bold pb-1">Bank Details</h3>

                        <p>Beneficiary Bank Name: {bank?.beneficiary_name}</p>
                        <p>SWIFT ID / BIC: {bank?.swift_bic}</p>
                        <p>Clearing Code (Optional): {bank?.clearing_code}</p>
                        <p>Beneficiary Name: {bank?.account_name}</p>
                        <p>Account Number / IBAN: {bank?.account_number_iban}</p>
                        <p>Account Currency: {bank?.account_currency?.value}</p>
                    </div>
                    <div className="space-y-1">
                        <h3 className="text-md font-bold pb-1">Intermediary Bank Details (if applicable)</h3>

                        <p>Bank Name: {intermediary_bank?.beneficiary_name || '-'}</p>
                        <p>Bank Address: {intermediary_bank?.address || '-'}</p>
                        <p>SWIFT ID / BIC: {intermediary_bank?.swift_bic || '-'}</p>
                        <p>Account Number: {intermediary_bank?.account_number || '-'}</p>
                    </div>
                    <div className="space-y-1">
                        <h3 className="text-md font-bold pb-1">Email Details</h3>

                        <p>CC To : {email?.cc_to || '-'}</p>
                    </div>
                </div>
            );
        }
    }, [opened]);

    return (
        <Modal {...props} className="h-auto" size="max-w-md">
            <ModalHeader>
                <ModalTitle>{type} Withdrawal Request</ModalTitle>
            </ModalHeader>
            <Divider />
            <div className="text-neutral-200 p-2 sm:p-4">
                <div className="pb-4 text-sm">{message}</div>
                <div className="text-sm">Please confirm your details for withdrawal.</div>
            </div>
            <Divider />
            <div className="text-neutral-200 p-2 sm:p-4 text-sm flex space-x-2">
                <button
                    className="rounded-md p-2 px-4 bg-neutral-600 hover:bg-neutral-500"
                    onClick={() => {
                        handlers.close();
                    }}>
                    Cancel
                </button>
                <Button
                    className="rounded-md p-2 px-4 bg-brand-primary hover:bg-brand-primary-light"
                    onClick={() => {
                        manualOnSubmit();
                        handlers.close();
                    }}
                    isLoading={isSubmitting}>
                    Confirm
                </Button>
            </div>
        </Modal>
    );
};

export default WithdrawalConfirmationModal;
