import { ModalOperations } from '@/model/common';
import {
    TraderMarket,
    addWatchList,
    deleteWatchList,
    editWatchList,
    selectCurrentWatchList,
    selectTraderWatchLists
} from '@/state/reducers/traderMarketSlice';
import { store } from '@/state/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { Button } from '../common/Button';
import Divider from '../common/Divider';
import { WatchListFormInput, WatchListFormValues, watchListSchema } from '../form/schema/watchListSchema';
import Input from '../inputs/Input';
import InputController from '../inputs/InputController';
import Modal, { ModalHeader, ModalProps, ModalTitle } from './Modal';

interface WatchListModalProps extends ModalProps {
    mode: ModalOperations;
    setItems(items: { id: number; market: TraderMarket }[]): void;
}

const WatchListModal = (props: WatchListModalProps) => {
    const { opened, handlers, mode = 'Create', setItems } = props;

    const dispatch = useAppDispatch();
    const selected = useAppSelector(selectCurrentWatchList);
    const watchLists = useAppSelector(selectTraderWatchLists);

    const selectedWatchList = useMemo(() => {
        if (selected) return selected;
        else {
            return {
                id: watchLists[0].id,
                label: watchLists[0].name,
                value: watchLists[0].id,
                markets: watchLists[0].markets
            };
        }
    }, [selected, watchLists]);

    const [stateWatchList, setStateWatchList] = useState(selectedWatchList);

    const form = useForm<WatchListFormInput>({
        defaultValues: { name: '' },
        mode: 'onChange',
        resolver: yupResolver(watchListSchema())
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting }
    } = form;

    const onSubmit = async (data: WatchListFormInput) => {
        const { name } = data as WatchListFormValues;
        if (mode === 'Edit') {
            dispatch(editWatchList({ id: selectedWatchList.id, name }));
        } else {
            dispatch(addWatchList({ name }));
            setItems([]);
        }
        handlers.close();
    };

    const onDelete = useCallback(() => {
        dispatch(deleteWatchList({ id: selectedWatchList.id }));
        const selected = store.getState().traderMarket.selectedWatchList;
        setItems(selected?.markets?.map((market) => ({ id: uuidv4(), market })) || []);
        handlers.close();
    }, [selectedWatchList]);

    useEffect(() => {
        if (opened) {
            if (mode === 'Edit') reset({ name: selectedWatchList.label });
            else reset({ name: '' });
            setStateWatchList(selectedWatchList);
        }
    }, [opened, mode]);

    return (
        <Modal {...props} className="h-auto" size="max-w-md">
            <FormProvider {...form}>
                <form className="h-full flex flex-col lg:block" onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader>
                        <ModalTitle>{mode === 'Create' ? 'New' : mode} Watchlist</ModalTitle>
                    </ModalHeader>
                    <Divider className={mode === 'Delete' ? 'bg-brand-red' : ''} />
                    <div className="flex flex-col text-neutral-200 p-2 py-3 sm:p-4 gap-3">
                        {mode === 'Delete' ? (
                            <div className="text-sm">
                                Are you sure you want to delete <b>{stateWatchList.label}</b> ?
                                <br />
                                <span className="text-neutral-400">
                                    Deleting this watchlist will also remove all the configured instruments in the
                                    process.
                                </span>
                                <br />
                                <br /> This action cannot be undone.
                            </div>
                        ) : (
                            <Fragment>
                                <div className="text-sm">
                                    {mode === 'Create'
                                        ? 'Please input the name of the new watchlist you wish to create and we will add it into your list.'
                                        : 'Please input the new name for your watchlist and we will update it for you.'}
                                </div>
                                <InputController name="name" placeholder="My Watchlist" label="" errorPadding={false}>
                                    <Input />
                                </InputController>
                            </Fragment>
                        )}
                    </div>
                    <Divider className={mode === 'Delete' ? 'bg-brand-red' : ''} />
                    <div className="text-neutral-200 p-2 sm:p-4 text-sm flex space-x-2">
                        <button
                            type="button"
                            className="rounded-md p-2 px-4 bg-neutral-600 hover:bg-neutral-500"
                            onClick={handlers.close}>
                            Cancel
                        </button>
                        {mode === 'Delete' ? (
                            <Button
                                type="button"
                                className="rounded-md p-2 px-4 bg-brand-red hover:bg-brand-red-light"
                                onClick={onDelete}>
                                Delete Watchlist
                            </Button>
                        ) : (
                            <Button
                                className="rounded-md p-2 px-4 bg-brand-primary hover:bg-brand-primary-light"
                                isLoading={isSubmitting}>
                                {mode === 'Edit' ? 'Save Changes' : `${mode} Watchlist`}
                            </Button>
                        )}
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default WatchListModal;
