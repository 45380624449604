/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.orderrouting.api.order';

export interface OrderIdData {
    orderSnapshotId: string;
    orderId: string;
}

function createBaseOrderIdData(): OrderIdData {
    return { orderSnapshotId: '0', orderId: '0' };
}

export const OrderIdData = {
    encode(message: OrderIdData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.orderSnapshotId !== '0') {
            writer.uint32(8).int64(message.orderSnapshotId);
        }
        if (message.orderId !== '0') {
            writer.uint32(16).int64(message.orderId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): OrderIdData {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderIdData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderSnapshotId = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<OrderIdData>): OrderIdData {
        const message = createBaseOrderIdData();
        message.orderSnapshotId = object.orderSnapshotId ?? '0';
        message.orderId = object.orderId ?? '0';
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
