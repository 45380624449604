/* eslint-disable */
import { CallContext, CallOptions } from 'nice-grpc-common';
import {
    GenerateResetUserPasswordTokenRequest,
    ChangeUserPasswordRequest,
    RequestQRCodeForUser
} from './UpstreamAuthenticationUserProto';
import {
    ResetUserPasswordToken,
    ChangeUserPasswordConfirmation,
    UserQRCode
} from './DownstreamAuthenticationUserProto';
import { Empty } from '../../../../../google/protobuf/empty';

export const protobufPackage = 'com.celertech.baseserver.api.user';

export type ChangePasswordServiceProtoDefinition = typeof ChangePasswordServiceProtoDefinition;
export const ChangePasswordServiceProtoDefinition = {
    name: 'ChangePasswordServiceProto',
    fullName: 'com.celertech.baseserver.api.user.ChangePasswordServiceProto',
    methods: {
        generatePasswordToken: {
            name: 'generatePasswordToken',
            requestType: GenerateResetUserPasswordTokenRequest,
            requestStream: false,
            responseType: ResetUserPasswordToken,
            responseStream: false,
            options: {}
        },
        unauthenticatedGeneratePasswordToken: {
            name: 'unauthenticatedGeneratePasswordToken',
            requestType: GenerateResetUserPasswordTokenRequest,
            requestStream: false,
            responseType: Empty,
            responseStream: false,
            options: {}
        },
        resetUsersPassword: {
            name: 'resetUsersPassword',
            requestType: ChangeUserPasswordRequest,
            requestStream: false,
            responseType: ChangeUserPasswordConfirmation,
            responseStream: false,
            options: {}
        },
        getQRCodeForUser: {
            name: 'getQRCodeForUser',
            requestType: RequestQRCodeForUser,
            requestStream: false,
            responseType: UserQRCode,
            responseStream: false,
            options: {}
        }
    }
} as const;

export interface ChangePasswordServiceProtoServiceImplementation<CallContextExt = {}> {
    generatePasswordToken(
        request: GenerateResetUserPasswordTokenRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<ResetUserPasswordToken>>;
    unauthenticatedGeneratePasswordToken(
        request: GenerateResetUserPasswordTokenRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<Empty>>;
    resetUsersPassword(
        request: ChangeUserPasswordRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<ChangeUserPasswordConfirmation>>;
    getQRCodeForUser(
        request: RequestQRCodeForUser,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<UserQRCode>>;
}

export interface ChangePasswordServiceProtoClient<CallOptionsExt = {}> {
    generatePasswordToken(
        request: DeepPartial<GenerateResetUserPasswordTokenRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<ResetUserPasswordToken>;
    unauthenticatedGeneratePasswordToken(
        request: DeepPartial<GenerateResetUserPasswordTokenRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<Empty>;
    resetUsersPassword(
        request: DeepPartial<ChangeUserPasswordRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<ChangeUserPasswordConfirmation>;
    getQRCodeForUser(
        request: DeepPartial<RequestQRCodeForUser>,
        options?: CallOptions & CallOptionsExt
    ): Promise<UserQRCode>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;
