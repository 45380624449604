// title: GCEX NOP Limit & Cash Balances API
import { User } from '@/state/reducers/authSlice';
import axios from 'axios';

const balanceUrl = window.config.balances?.rest;

// Get account balances for a user under an account
export async function getUserAccountBalances(credentials: User, accountCode: string) {
    const { username, authToken } = credentials;

    return await axios.get(`${balanceUrl}/external/balances/${username}/${accountCode}`, {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    });
}
