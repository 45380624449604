import currencyConfig from '@/config/currency';

export interface CurrencyOption {
    label: string;
    ccy: string;
    value: string;
}

export const getOtherCurrency = (currency: string, securityId: string) => {
    if (securityId.indexOf('/') == -1) return '';
    const [baseCurrency, quoteCurrency] = securityId.split('/');
    return currency.toLowerCase() == baseCurrency.toLowerCase()
        ? quoteCurrency
        : currency.toLowerCase() == quoteCurrency.toLowerCase()
        ? baseCurrency
        : '';
};

export const currencyOptions: CurrencyOption[] = currencyConfig.map((ccy) => {
    return {
        label: ccy.longName,
        ccy: ccy.show.toLowerCase(),
        value: ccy.celer
    };
});

export const ccyOption = (name: string) => currencyOptions.find((ccy) => ccy.ccy === name);
export const availableCcyOption = (options: CurrencyOption[], name: string) => options.find((ccy) => ccy.ccy === name);
