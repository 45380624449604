import { Tab } from '@headlessui/react';
import Divider from '../../common/Divider';
import Modal, { ModalClose, ModalHeader, ModalTab, ModalTabList, ModalTitle } from '../Modal';
import CryptoDepositTab from './CryptoDepositTab';
import FiatDepositTab from './FiatDepositTab';

export interface DepositModalProps {
    opened: boolean;
    handlers: {
        open: () => void;
        close: () => void;
        toggle: () => void;
    };
}

const tabs = ['Fiat Deposit', 'Crypto Deposit'];

const DepositModal = (props: DepositModalProps) => {
    const { handlers } = props;

    return (
        <Modal {...props} size="max-w-2xl">
            <ModalHeader>
                <ModalTitle>Deposits</ModalTitle>
                <ModalClose handlers={handlers} />
            </ModalHeader>
            <Divider />
            <div className="flex-1 basis-0 h-full flex flex-col lg:h-auto lg:block w-full text-neutral-200 mt-[1px]">
                <Tab.Group>
                    <ModalTabList>
                        {tabs.map((tab) => (
                            <ModalTab key={tab}>{tab}</ModalTab>
                        ))}
                    </ModalTabList>
                    <Tab.Panels className="flex-1 basis-0">
                        <FiatDepositTab {...props} />
                        <CryptoDepositTab {...props} />
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </Modal>
    );
};

export default DepositModal;
