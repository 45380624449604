import { ReactElement } from 'react';

interface PopOutProps {
    children: ReactElement | ReactElement[];
}

const PopOut = (props: PopOutProps) => {
    const { children } = props;
    return (
        <div className="flex flex-col w-full transform overflow-hidden bg-brand-background h-full text-left align-middle transition-all">
            {children}
        </div>
    );
};

export const PopOutHeader = ({ children }: any) => {
    return <div className="flex gap-3 items-center p-2 text-neutral-200">{children}</div>;
};
export const PopOutTitle = ({ children }: any) => {
    return <h3 className="text-base sm:text-lg text-neutral-200 font-bold m-0">{children}</h3>;
};

export default PopOut;
