import { invalidStopPriceMessage } from '@/utils/hooks/useOrderBook';
import { number } from 'yup';
import { Side } from '../../../compiled_proto/com/celertech/marketdata/api/enums/SideProto';
import { OrderType } from '../../../compiled_proto/com/celertech/positionmanager/api/enums/OrderTypeProto';

export interface QuantitySchemaParams {
    currencyPair: string;
    currencyOut: string;
    order_decimals: number;
    min_order_size: number;
}

export const quantitySchema = (params: QuantitySchemaParams) => {
    const { currencyPair, currencyOut, order_decimals, min_order_size } = params;
    return number()
        .min(min_order_size, `Invalid input: The minimum order quantity is ${min_order_size} ${currencyOut}`)
        .test(
            'maxDigitsAfterDecimal',
            `Invalid Input: The maximum allowed number of decimals for ${currencyPair} is ${order_decimals}`,
            (number) => {
                const regex = new RegExp(`^\\d+(\\.\\d{0,${order_decimals}})?$`, 'gm');
                return regex.test((number || '').toString());
            }
        )
        .required('Quantity cannot be empty!')
        .typeError('Invalid Input: Please input a number.');
};

export interface PriceSchemaParams {
    side: string;
    orderType: string;
    bestAsk: number;
    bestBid: number;
    formattedBestAsk: string;
    formattedBestBid: string;
    ccy2Order: boolean;
}

export const priceSchema = (params: PriceSchemaParams) => {
    const { side, orderType, bestAsk, bestBid, formattedBestAsk, formattedBestBid, ccy2Order } = params;

    const bestPrice = () => {
        switch (Side[side]) {
            case Side.BUY:
                return ccy2Order ? formattedBestBid : formattedBestAsk;
            case Side.SELL:
                return ccy2Order ? formattedBestAsk : formattedBestBid;
            default:
                return ccy2Order ? formattedBestBid : formattedBestAsk;
        }
    };

    if (OrderType[orderType] === OrderType.LIMIT) {
        return number().required('Limit Price cannot be empty!').typeError('Invalid Input: Please input a number.');
    } else if ([OrderType.STOP_MARKET, OrderType.STOP_LIMIT].includes(OrderType[orderType])) {
        return number()
            .test('stopPriceConstraint', invalidStopPriceMessage(Side[side], bestPrice(), ccy2Order), (price) => {
                if (Side[side] === Side.BUY) {
                    if (ccy2Order && +(price || 0) >= bestBid) {
                        return false;
                    } else if (!ccy2Order && +(price || 0) <= bestAsk) {
                        return false;
                    }
                }
                if (Side[side] === Side.SELL) {
                    if (ccy2Order && +(price || 0) <= bestAsk) {
                        return false;
                    } else if (!ccy2Order && +(price || 0) >= bestBid) {
                        return false;
                    }
                }
                return true;
            })
            .required('Stop Price cannot be empty!')
            .typeError('Invalid Input: Please input a number.');
    }

    return number().required('Limit Price cannot be empty!').typeError('Invalid Input: Please input a number.');
};
