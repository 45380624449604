/* eslint-disable */
import { MarketDataTradingStatus } from '../enums/MarketDataTradingStatusProto';
import { AssetType } from '../enums/AssetTypeProto';
import { TradingSessionStatus } from '../enums/TradingSessionStatusProto';
import { ProductType } from '../enums/ProductTypeProto';
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.marketdata.api.status';

/** Sent out when a destination changes status. */
export interface MarketDataDestinationStatusDownstreamEvent {
    exchangeCode: string;
    destinationName: string;
    status: MarketDataTradingStatus;
    updated: string;
}

export interface TradingSessionStatusDownstreamEvent {
    exchangeCode: string;
    destinationName: string;
    securityCode: string;
    securityId: string;
    assetType: AssetType;
    tradingSessionStatus: TradingSessionStatus;
    text: string;
    productType: ProductType;
}

function createBaseMarketDataDestinationStatusDownstreamEvent(): MarketDataDestinationStatusDownstreamEvent {
    return { exchangeCode: '', destinationName: '', status: 1, updated: '0' };
}

export const MarketDataDestinationStatusDownstreamEvent = {
    encode(message: MarketDataDestinationStatusDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.exchangeCode !== '') {
            writer.uint32(10).string(message.exchangeCode);
        }
        if (message.destinationName !== '') {
            writer.uint32(18).string(message.destinationName);
        }
        if (message.status !== 1) {
            writer.uint32(24).int32(message.status);
        }
        if (message.updated !== '0') {
            writer.uint32(32).int64(message.updated);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataDestinationStatusDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataDestinationStatusDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.exchangeCode = reader.string();
                    break;
                case 2:
                    message.destinationName = reader.string();
                    break;
                case 3:
                    message.status = reader.int32() as any;
                    break;
                case 4:
                    message.updated = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<MarketDataDestinationStatusDownstreamEvent>
    ): MarketDataDestinationStatusDownstreamEvent {
        const message = createBaseMarketDataDestinationStatusDownstreamEvent();
        message.exchangeCode = object.exchangeCode ?? '';
        message.destinationName = object.destinationName ?? '';
        message.status = object.status ?? 1;
        message.updated = object.updated ?? '0';
        return message;
    }
};

function createBaseTradingSessionStatusDownstreamEvent(): TradingSessionStatusDownstreamEvent {
    return {
        exchangeCode: '',
        destinationName: '',
        securityCode: '',
        securityId: '',
        assetType: 1,
        tradingSessionStatus: 0,
        text: '',
        productType: 1
    };
}

export const TradingSessionStatusDownstreamEvent = {
    encode(message: TradingSessionStatusDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.exchangeCode !== '') {
            writer.uint32(10).string(message.exchangeCode);
        }
        if (message.destinationName !== '') {
            writer.uint32(18).string(message.destinationName);
        }
        if (message.securityCode !== '') {
            writer.uint32(26).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(34).string(message.securityId);
        }
        if (message.assetType !== 1) {
            writer.uint32(40).int32(message.assetType);
        }
        if (message.tradingSessionStatus !== 0) {
            writer.uint32(48).int32(message.tradingSessionStatus);
        }
        if (message.text !== '') {
            writer.uint32(58).string(message.text);
        }
        if (message.productType !== 1) {
            writer.uint32(64).int32(message.productType);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): TradingSessionStatusDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradingSessionStatusDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.exchangeCode = reader.string();
                    break;
                case 2:
                    message.destinationName = reader.string();
                    break;
                case 3:
                    message.securityCode = reader.string();
                    break;
                case 4:
                    message.securityId = reader.string();
                    break;
                case 5:
                    message.assetType = reader.int32() as any;
                    break;
                case 6:
                    message.tradingSessionStatus = reader.int32() as any;
                    break;
                case 7:
                    message.text = reader.string();
                    break;
                case 8:
                    message.productType = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<TradingSessionStatusDownstreamEvent>): TradingSessionStatusDownstreamEvent {
        const message = createBaseTradingSessionStatusDownstreamEvent();
        message.exchangeCode = object.exchangeCode ?? '';
        message.destinationName = object.destinationName ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.assetType = object.assetType ?? 1;
        message.tradingSessionStatus = object.tradingSessionStatus ?? 0;
        message.text = object.text ?? '';
        message.productType = object.productType ?? 1;
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
