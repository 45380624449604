import { grpc } from '@improbable-eng/grpc-web';
import { createChannel, createClient, Metadata } from 'nice-grpc-web';
import { v4 as uuidv4 } from 'uuid';
// import { NodeHttpTransport } from '@improbable-eng/grpc-web';

import {
    LoginServiceClient,
    LoginServiceDefinition
} from '@/compiled_proto/com/celertech/baseserver/communication/login/LoginProto';
import {
    PreferenceServiceClient,
    PreferenceServiceDefinition
} from '@/compiled_proto/com/celertech/preference/api/profile/PreferenceProto';
import {
    UserLimitServiceClient,
    UserLimitServiceDefinition
} from '@/compiled_proto/com/celertech/staticdata/api/user/limit/UserLimitProto';
import { LoginError, LoginStatus, User } from '@/state/reducers/authSlice';
import { MarketItem } from '@/state/reducers/celerMarketSlice';

import {
    ChangePasswordServiceProtoClient,
    ChangePasswordServiceProtoDefinition
} from '@/compiled_proto/com/celertech/baseserver/api/user/ChangePasswordServiceProto';
import { ResetUserPasswordToken } from '@/compiled_proto/com/celertech/baseserver/api/user/DownstreamAuthenticationUserProto';
import {
    LoginResponse,
    LoginResponseType
} from '@/compiled_proto/com/celertech/baseserver/communication/login/DownstreamLoginProto';
import {
    GrpcLogoutMessage,
    LogoutServiceClient,
    LogoutServiceDefinition
} from '@/compiled_proto/com/celertech/baseserver/communication/login/LogoutProto';
import {
    PiggyBankServiceClient,
    PiggyBankServiceDefinition,
    PiggyBankUpdateEvent
} from '@/compiled_proto/com/celertech/piggybank/api/PiggyBankServiceProto';
import {
    UserAccountServiceClient,
    UserAccountServiceDefinition
} from '@/compiled_proto/com/celertech/staticdata/api/user/account/UserAccountProto';
import { asyncList } from '@/helpers/asyncIterableHelper';
import { extractBalanceUpdate } from './utils/BalanceUpdateParser';

const CHANNEL_URL = window.config.integration.celertech.rest;
const AUTHORITY = window.config.integration.celertech.authority;
const exchangeCode = window.config.integration.celertech.exchangeCode || 'XCEL';

// const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';
// const channel = createChannel(CHANNEL_URL, isBrowser ? undefined : NodeHttpTransport());
const channel = createChannel(CHANNEL_URL);

export const getAuthMetadata = (credentials) => ({
    metadata: Metadata({
        'authorization-token': credentials.authToken
    })
});

export async function login(
    username: string,
    password: string,
    twoFactorToken?: string
): Promise<Omit<LoginResponse, 'accounts'>> {
    const loginClient: LoginServiceClient = createClient(LoginServiceDefinition, channel);
    const resp = await loginClient.login(
        { username, password, twoFactorToken },
        {
            metadata: Metadata({
                authority: AUTHORITY
            })
        }
    );
    if (resp.loginResponseType == LoginResponseType.LOGGED_IN) {
        return resp;
    } else if (resp.loginResponseType == LoginResponseType.BAD_CREDENTIALS) {
        throw new LoginError(LoginStatus.BAD_CREDENTIALS);
    } else if (resp.loginResponseType == LoginResponseType.QR_TOKEN_CODE_REQUIRED) {
        return resp;
    } else {
        throw new LoginError(LoginStatus.UNEXPECTED);
    }
}

export async function logout(credentials: User): Promise<GrpcLogoutMessage> {
    const logoutClient: LogoutServiceClient = createClient(LogoutServiceDefinition, channel);
    const resp = await logoutClient.logout({}, getAuthMetadata(credentials));
    return resp;
}

export async function sendResetPasswordEmail(username: string): Promise<ResetUserPasswordToken> {
    const resetPasswordClient: ChangePasswordServiceProtoClient = createClient(
        ChangePasswordServiceProtoDefinition,
        channel
    );
    const resp = await resetPasswordClient.generatePasswordToken({ username, sendUserNotification: true }, {});
    return resp;
}

export async function resetUserPassword(credentials: User, currentPassword: string, newPassword: string) {
    const resetPasswordClient: ChangePasswordServiceProtoClient = createClient(
        ChangePasswordServiceProtoDefinition,
        channel
    );

    const { username, clientRequestId } = credentials;

    const { resetUserPasswordToken: resetPasswordToken } = await resetPasswordClient.generatePasswordToken({
        clientRequestId,
        username,
        sendUserNotification: false
    });

    const resp = await resetPasswordClient.resetUsersPassword({
        clientRequestId,
        username,
        currentPassword,
        newPassword,
        resetPasswordToken
    });
    return resp;
}

export async function getUserProfile(credentials: User): Promise<string> {
    const preferenceClient: PreferenceServiceClient = createClient(PreferenceServiceDefinition, channel);
    const resp = await preferenceClient.findProfilesByUsernameAndType(
        {
            clientRequestId: uuidv4(),
            username: credentials.username,
            type: 'USER'
        },
        getAuthMetadata(credentials)
    );

    const profileId = resp.profile[0].id;
    return profileId;
}

type UserAccountsReturn = {
    code: string;
    name: string;
}[];

export async function getUserAccounts(credentials: User): Promise<UserAccountsReturn> {
    const userAccountClient: UserAccountServiceClient = createClient(UserAccountServiceDefinition, channel);
    const resp = await userAccountClient.findUserAccountsByUsername(
        {
            clientRequestId: uuidv4(),
            username: credentials.username
        },
        {
            metadata: Metadata({
                'authorization-token': credentials.authToken
            })
        }
    );

    return resp.userAccount
        .map((ua) => ({ code: ua.account?.code || '', name: ua.account?.name || '' }))
        .filter((ua) => ua.code) as UserAccountsReturn;
}

export async function getUserMarkets(credentials): Promise<MarketItem[]> {
    const userLimitClient: UserLimitServiceClient = createClient(UserLimitServiceDefinition, channel);

    const resp = await userLimitClient.findUserLimitsByUsername(
        {
            clientRequestId: uuidv4(),
            username: credentials.username
        },
        getAuthMetadata(credentials)
    );

    const userMarkets = resp.limit
        .filter((x) => x.enabled)
        .filter((x) => x.securityCode.indexOf('/') !== -1)
        .map((x) => {
            return {
                securityCode: x.securityCode,
                exchangeCode: x.exchangeCode
            } as any;
        });

    // Sort alphabetically by securityCode
    userMarkets.sort((a, b) => (a.securityCode > b.securityCode ? 1 : -1));

    return userMarkets;
}

export async function getUserBalances(piggybankClient: PiggyBankServiceClient, credentials: User, currentAccount) {
    const findAllCurrencyPositionsSubscription = piggybankClient.findAllCurrencyPositionsByAccount(
        { account: currentAccount || credentials.accounts[0].code },
        {
            metadata: Metadata({
                deadline: new Date().setSeconds(new Date().getSeconds() + 86400).toString(),
                'authorization-token': credentials.authToken
            })
        }
    );

    const items = await asyncList(findAllCurrencyPositionsSubscription, (event: PiggyBankUpdateEvent) => ({
        currency: event.subLedgerSnapshotDownstreamEvent?.currency,
        netPosition: event.subLedgerSnapshotDownstreamEvent?.netPosition,
        notionalCurrency: event.subLedgerSnapshotDownstreamEvent?.notionalCurrency,
        notionalNetPosition: event.subLedgerSnapshotDownstreamEvent?.notionalNetPosition,
        settlementDate: event.subLedgerSnapshotDownstreamEvent?.settlementDate
    }));

    return items;
}

export async function fetchLatestBalance(credentials: User, currentAccount?: string) {
    const wsChannelUrl = window.config.integration.celertech.websocket;
    const wsChannel = createChannel(wsChannelUrl, grpc.WebsocketTransport());

    const piggybankClient: PiggyBankServiceClient = createClient(PiggyBankServiceDefinition, wsChannel);
    const account = currentAccount || credentials.accounts[0].code;
    const findAllCurrencyPositionsSubscription = piggybankClient.findAllCurrencyPositionsByAccount(
        { account },
        {
            metadata: Metadata({
                deadline: new Date().setSeconds(new Date().getSeconds() + 86400).toString(),
                'authorization-token': credentials.authToken
            })
        }
    );

    const balanceEvents = await asyncList(findAllCurrencyPositionsSubscription);
    const balanceItems = balanceEvents.map((ev) => extractBalanceUpdate(ev.subLedgerSnapshotDownstreamEvent));
    return balanceItems;
}
