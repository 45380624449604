import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export type OrderBookWorkspace = 'Vertical' | 'Horizontal';

export interface WorkspaceState {
    orderBook: OrderBookWorkspace;
}

const initialState: WorkspaceState = {
    orderBook: 'Vertical'
};

export const workspaceSlice = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        setOrderBookWorkspace: (state, action: PayloadAction<OrderBookWorkspace>) => {
            state.orderBook = action.payload;
        }
    }
});

export const { setOrderBookWorkspace } = workspaceSlice.actions;

export const selectOrderBookWorkspace = (state: RootState) => state.workspace.orderBook;

export default workspaceSlice.reducer;
