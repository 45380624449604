/* eslint-disable */
import { HandlingInstruction } from '../enums/HandlingInstructionProto';
import { AssetType } from '../enums/AssetTypeProto';
import { ProductType } from '../enums/ProductTypeProto';
import { Side } from '../enums/SideProto';
import { QuoteCancelType } from '../enums/QuoteCancelTypeProto';
import { QuoteRequestRejectReason } from '../enums/QuoteRequestRejectReasonProto';
import Long from 'long';
import { KeyValue } from './QuoteMetadata';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.marketdata.api.quote';

/** Users can submit a request to receive a price for a give size from a given destination. */
export interface QuoteRequest {
    quoteRequestId: string;
    quoteRequestGroup: QuoteRequestGroup[];
    onBehalfOfUsername: string;
    quoteMetadata: KeyValue[];
    quoteRequestAllocGroup: QuoteRequestAllocGroup[];
    /**
     * by default, the market data would resolve the handlingInstruction inside the module
     * but if the suggestedHandlingInstruction is present, we expect the market data to use this instead of the default one
     */
    suggestedHandlingInstruction: HandlingInstruction;
}

export interface QuoteRequestGroup {
    assetType: AssetType;
    productType: ProductType;
    exchangeCode: string;
    securityCode: string;
    securityId: string;
    settlementDate: string;
    maturityDate: string;
    side: Side;
    qty: number;
    currency: string;
    partyId: string;
    settlementType: string;
    /** Only used for NDF */
    fixingSource: string;
    settlCurrency: string;
    /** Expiration time of the quote specified */
    expireTimeInUtcInMillis: string;
}

export interface QuoteRequestAllocGroup {
    allocAccount: string;
    allocQty: number;
    allocSettlementDate: string;
    allocSide: Side;
}

/** To be sent in to the OMS to cancel a previous quote */
export interface QuoteCancelRequest {
    quoteRequestId: string;
    quoteCancelType: QuoteCancelType;
    onBehalfOfUsername: string;
    quoteMetadata: KeyValue[];
}

/** Quote to be sent from Celer Trading Dealer Intervention screen to cancel a QuoteNotification */
export interface QuoteCancelNotification {
    clientRequestId: string;
    requestorSessionToken: string;
    requestorSessionKey: string;
    quoteRequestId: string;
    quoteId: string;
    quoteCancelType: QuoteCancelType;
    clientTierCode: string;
}

/** Quote to be sent from Celer Trading Dealer Intervention screen in response to a QuoteRequestNotification */
export interface QuoteNotification {
    requestorSessionKey: string;
    requestorSessionToken: string;
    quoteRequestId: string;
    assetType: AssetType;
    exchangeCode: string;
    securityCode: string;
    securityId: string;
    bidPx: number;
    bidSpotPx: number;
    offerPx: number;
    offerSpotPx: number;
    quoteValidityInSecs: number;
    clientTierCode: string;
    legQuoteGroup: QuoteNotification_LegQuoteGroup[];
    underlyingQuotePrice: QuoteNotification_UnderlyingQuotePrice[];
    productType: ProductType;
    /** Account on which orders on which this quote request is executed against is booked into */
    accountBookOn: string;
    bidMargin: number;
    offerMargin: number;
    /** this midPx is only for single leg quote request */
    midPx: number;
    skipCreditCheck: string;
    /** the quote request id of the reference price from the merchant */
    referenceQuoteRequestId: string;
}

export interface QuoteNotification_LegQuoteGroup {
    bidForwardPoints: number;
    offerForwardPoints: number;
    currency: string;
    settlementDate: string;
    maturityDate: string;
    side: Side;
    bidSize: number;
    offerSize: number;
    rawMidPxForManulQuote: number;
}

export interface QuoteNotification_UnderlyingQuotePrice {
    securityId: string;
    bidSpotPx: number;
    offerSpotPx: number;
}

/** Quote reject to be sent from Celer Trading Dealer Intervention screen to reject a quote request */
export interface QuoteRequestRejectNotification {
    requestorSessionKey: string;
    requestorSessionToken: string;
    quoteRequestId: string;
    quoteRequestRejectReason: QuoteRequestRejectReason;
    rejectText: string;
    quoteRequestRejectGroup: QuoteRequestRejectGroupNotification[];
}

export interface QuoteRequestRejectGroupNotification {
    assetType: AssetType;
    productType: ProductType;
    exchangeCode: string;
    securityCode: string;
    securityId: string;
    text: string;
}

/** Message to be used for when we want to find all outstanding quotes ie. On Celer Sales Dealer GUI login, go get all outstanding quotes */
export interface FindAllQuotesRequest {
    clientRequestId: string;
}

/** Message to subscribe to any create, update or delete quote actions */
export interface UpdateOnMarketDataQuoteRequest {}

function createBaseQuoteRequest(): QuoteRequest {
    return {
        quoteRequestId: '',
        quoteRequestGroup: [],
        onBehalfOfUsername: '',
        quoteMetadata: [],
        quoteRequestAllocGroup: [],
        suggestedHandlingInstruction: 1
    };
}

export const QuoteRequest = {
    encode(message: QuoteRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.quoteRequestId !== '') {
            writer.uint32(10).string(message.quoteRequestId);
        }
        for (const v of message.quoteRequestGroup) {
            QuoteRequestGroup.encode(v!, writer.uint32(18).fork()).ldelim();
        }
        if (message.onBehalfOfUsername !== '') {
            writer.uint32(26).string(message.onBehalfOfUsername);
        }
        for (const v of message.quoteMetadata) {
            KeyValue.encode(v!, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.quoteRequestAllocGroup) {
            QuoteRequestAllocGroup.encode(v!, writer.uint32(42).fork()).ldelim();
        }
        if (message.suggestedHandlingInstruction !== 1) {
            writer.uint32(48).int32(message.suggestedHandlingInstruction);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): QuoteRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuoteRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.quoteRequestId = reader.string();
                    break;
                case 2:
                    message.quoteRequestGroup.push(QuoteRequestGroup.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.onBehalfOfUsername = reader.string();
                    break;
                case 4:
                    message.quoteMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.quoteRequestAllocGroup.push(QuoteRequestAllocGroup.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.suggestedHandlingInstruction = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<QuoteRequest>): QuoteRequest {
        const message = createBaseQuoteRequest();
        message.quoteRequestId = object.quoteRequestId ?? '';
        message.quoteRequestGroup = object.quoteRequestGroup?.map((e) => QuoteRequestGroup.fromPartial(e)) || [];
        message.onBehalfOfUsername = object.onBehalfOfUsername ?? '';
        message.quoteMetadata = object.quoteMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.quoteRequestAllocGroup =
            object.quoteRequestAllocGroup?.map((e) => QuoteRequestAllocGroup.fromPartial(e)) || [];
        message.suggestedHandlingInstruction = object.suggestedHandlingInstruction ?? 1;
        return message;
    }
};

function createBaseQuoteRequestGroup(): QuoteRequestGroup {
    return {
        assetType: 1,
        productType: 1,
        exchangeCode: '',
        securityCode: '',
        securityId: '',
        settlementDate: '',
        maturityDate: '',
        side: 1,
        qty: 0,
        currency: '',
        partyId: '',
        settlementType: '',
        fixingSource: '',
        settlCurrency: '',
        expireTimeInUtcInMillis: '0'
    };
}

export const QuoteRequestGroup = {
    encode(message: QuoteRequestGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.assetType !== 1) {
            writer.uint32(8).int32(message.assetType);
        }
        if (message.productType !== 1) {
            writer.uint32(16).int32(message.productType);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(26).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(34).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(42).string(message.securityId);
        }
        if (message.settlementDate !== '') {
            writer.uint32(50).string(message.settlementDate);
        }
        if (message.maturityDate !== '') {
            writer.uint32(58).string(message.maturityDate);
        }
        if (message.side !== 1) {
            writer.uint32(64).int32(message.side);
        }
        if (message.qty !== 0) {
            writer.uint32(73).double(message.qty);
        }
        if (message.currency !== '') {
            writer.uint32(82).string(message.currency);
        }
        if (message.partyId !== '') {
            writer.uint32(90).string(message.partyId);
        }
        if (message.settlementType !== '') {
            writer.uint32(98).string(message.settlementType);
        }
        if (message.fixingSource !== '') {
            writer.uint32(106).string(message.fixingSource);
        }
        if (message.settlCurrency !== '') {
            writer.uint32(114).string(message.settlCurrency);
        }
        if (message.expireTimeInUtcInMillis !== '0') {
            writer.uint32(120).int64(message.expireTimeInUtcInMillis);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): QuoteRequestGroup {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuoteRequestGroup();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.assetType = reader.int32() as any;
                    break;
                case 2:
                    message.productType = reader.int32() as any;
                    break;
                case 3:
                    message.exchangeCode = reader.string();
                    break;
                case 4:
                    message.securityCode = reader.string();
                    break;
                case 5:
                    message.securityId = reader.string();
                    break;
                case 6:
                    message.settlementDate = reader.string();
                    break;
                case 7:
                    message.maturityDate = reader.string();
                    break;
                case 8:
                    message.side = reader.int32() as any;
                    break;
                case 9:
                    message.qty = reader.double();
                    break;
                case 10:
                    message.currency = reader.string();
                    break;
                case 11:
                    message.partyId = reader.string();
                    break;
                case 12:
                    message.settlementType = reader.string();
                    break;
                case 13:
                    message.fixingSource = reader.string();
                    break;
                case 14:
                    message.settlCurrency = reader.string();
                    break;
                case 15:
                    message.expireTimeInUtcInMillis = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<QuoteRequestGroup>): QuoteRequestGroup {
        const message = createBaseQuoteRequestGroup();
        message.assetType = object.assetType ?? 1;
        message.productType = object.productType ?? 1;
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.maturityDate = object.maturityDate ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.currency = object.currency ?? '';
        message.partyId = object.partyId ?? '';
        message.settlementType = object.settlementType ?? '';
        message.fixingSource = object.fixingSource ?? '';
        message.settlCurrency = object.settlCurrency ?? '';
        message.expireTimeInUtcInMillis = object.expireTimeInUtcInMillis ?? '0';
        return message;
    }
};

function createBaseQuoteRequestAllocGroup(): QuoteRequestAllocGroup {
    return { allocAccount: '', allocQty: 0, allocSettlementDate: '', allocSide: 1 };
}

export const QuoteRequestAllocGroup = {
    encode(message: QuoteRequestAllocGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.allocAccount !== '') {
            writer.uint32(10).string(message.allocAccount);
        }
        if (message.allocQty !== 0) {
            writer.uint32(17).double(message.allocQty);
        }
        if (message.allocSettlementDate !== '') {
            writer.uint32(26).string(message.allocSettlementDate);
        }
        if (message.allocSide !== 1) {
            writer.uint32(32).int32(message.allocSide);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): QuoteRequestAllocGroup {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuoteRequestAllocGroup();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.allocAccount = reader.string();
                    break;
                case 2:
                    message.allocQty = reader.double();
                    break;
                case 3:
                    message.allocSettlementDate = reader.string();
                    break;
                case 4:
                    message.allocSide = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<QuoteRequestAllocGroup>): QuoteRequestAllocGroup {
        const message = createBaseQuoteRequestAllocGroup();
        message.allocAccount = object.allocAccount ?? '';
        message.allocQty = object.allocQty ?? 0;
        message.allocSettlementDate = object.allocSettlementDate ?? '';
        message.allocSide = object.allocSide ?? 1;
        return message;
    }
};

function createBaseQuoteCancelRequest(): QuoteCancelRequest {
    return { quoteRequestId: '', quoteCancelType: 1, onBehalfOfUsername: '', quoteMetadata: [] };
}

export const QuoteCancelRequest = {
    encode(message: QuoteCancelRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.quoteRequestId !== '') {
            writer.uint32(10).string(message.quoteRequestId);
        }
        if (message.quoteCancelType !== 1) {
            writer.uint32(16).int32(message.quoteCancelType);
        }
        if (message.onBehalfOfUsername !== '') {
            writer.uint32(26).string(message.onBehalfOfUsername);
        }
        for (const v of message.quoteMetadata) {
            KeyValue.encode(v!, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): QuoteCancelRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuoteCancelRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.quoteRequestId = reader.string();
                    break;
                case 2:
                    message.quoteCancelType = reader.int32() as any;
                    break;
                case 3:
                    message.onBehalfOfUsername = reader.string();
                    break;
                case 4:
                    message.quoteMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<QuoteCancelRequest>): QuoteCancelRequest {
        const message = createBaseQuoteCancelRequest();
        message.quoteRequestId = object.quoteRequestId ?? '';
        message.quoteCancelType = object.quoteCancelType ?? 1;
        message.onBehalfOfUsername = object.onBehalfOfUsername ?? '';
        message.quoteMetadata = object.quoteMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        return message;
    }
};

function createBaseQuoteCancelNotification(): QuoteCancelNotification {
    return {
        clientRequestId: '',
        requestorSessionToken: '',
        requestorSessionKey: '',
        quoteRequestId: '',
        quoteId: '',
        quoteCancelType: 1,
        clientTierCode: ''
    };
}

export const QuoteCancelNotification = {
    encode(message: QuoteCancelNotification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.requestorSessionToken !== '') {
            writer.uint32(18).string(message.requestorSessionToken);
        }
        if (message.requestorSessionKey !== '') {
            writer.uint32(26).string(message.requestorSessionKey);
        }
        if (message.quoteRequestId !== '') {
            writer.uint32(34).string(message.quoteRequestId);
        }
        if (message.quoteId !== '') {
            writer.uint32(42).string(message.quoteId);
        }
        if (message.quoteCancelType !== 1) {
            writer.uint32(48).int32(message.quoteCancelType);
        }
        if (message.clientTierCode !== '') {
            writer.uint32(58).string(message.clientTierCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): QuoteCancelNotification {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuoteCancelNotification();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.requestorSessionToken = reader.string();
                    break;
                case 3:
                    message.requestorSessionKey = reader.string();
                    break;
                case 4:
                    message.quoteRequestId = reader.string();
                    break;
                case 5:
                    message.quoteId = reader.string();
                    break;
                case 6:
                    message.quoteCancelType = reader.int32() as any;
                    break;
                case 7:
                    message.clientTierCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<QuoteCancelNotification>): QuoteCancelNotification {
        const message = createBaseQuoteCancelNotification();
        message.clientRequestId = object.clientRequestId ?? '';
        message.requestorSessionToken = object.requestorSessionToken ?? '';
        message.requestorSessionKey = object.requestorSessionKey ?? '';
        message.quoteRequestId = object.quoteRequestId ?? '';
        message.quoteId = object.quoteId ?? '';
        message.quoteCancelType = object.quoteCancelType ?? 1;
        message.clientTierCode = object.clientTierCode ?? '';
        return message;
    }
};

function createBaseQuoteNotification(): QuoteNotification {
    return {
        requestorSessionKey: '',
        requestorSessionToken: '',
        quoteRequestId: '',
        assetType: 1,
        exchangeCode: '',
        securityCode: '',
        securityId: '',
        bidPx: 0,
        bidSpotPx: 0,
        offerPx: 0,
        offerSpotPx: 0,
        quoteValidityInSecs: 0,
        clientTierCode: '',
        legQuoteGroup: [],
        underlyingQuotePrice: [],
        productType: 1,
        accountBookOn: '',
        bidMargin: 0,
        offerMargin: 0,
        midPx: 0,
        skipCreditCheck: '',
        referenceQuoteRequestId: ''
    };
}

export const QuoteNotification = {
    encode(message: QuoteNotification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.requestorSessionKey !== '') {
            writer.uint32(10).string(message.requestorSessionKey);
        }
        if (message.requestorSessionToken !== '') {
            writer.uint32(18).string(message.requestorSessionToken);
        }
        if (message.quoteRequestId !== '') {
            writer.uint32(26).string(message.quoteRequestId);
        }
        if (message.assetType !== 1) {
            writer.uint32(32).int32(message.assetType);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(50).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(58).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(66).string(message.securityId);
        }
        if (message.bidPx !== 0) {
            writer.uint32(81).double(message.bidPx);
        }
        if (message.bidSpotPx !== 0) {
            writer.uint32(89).double(message.bidSpotPx);
        }
        if (message.offerPx !== 0) {
            writer.uint32(97).double(message.offerPx);
        }
        if (message.offerSpotPx !== 0) {
            writer.uint32(105).double(message.offerSpotPx);
        }
        if (message.quoteValidityInSecs !== 0) {
            writer.uint32(128).int32(message.quoteValidityInSecs);
        }
        if (message.clientTierCode !== '') {
            writer.uint32(138).string(message.clientTierCode);
        }
        for (const v of message.legQuoteGroup) {
            QuoteNotification_LegQuoteGroup.encode(v!, writer.uint32(146).fork()).ldelim();
        }
        for (const v of message.underlyingQuotePrice) {
            QuoteNotification_UnderlyingQuotePrice.encode(v!, writer.uint32(154).fork()).ldelim();
        }
        if (message.productType !== 1) {
            writer.uint32(160).int32(message.productType);
        }
        if (message.accountBookOn !== '') {
            writer.uint32(170).string(message.accountBookOn);
        }
        if (message.bidMargin !== 0) {
            writer.uint32(177).double(message.bidMargin);
        }
        if (message.offerMargin !== 0) {
            writer.uint32(185).double(message.offerMargin);
        }
        if (message.midPx !== 0) {
            writer.uint32(193).double(message.midPx);
        }
        if (message.skipCreditCheck !== '') {
            writer.uint32(202).string(message.skipCreditCheck);
        }
        if (message.referenceQuoteRequestId !== '') {
            writer.uint32(210).string(message.referenceQuoteRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): QuoteNotification {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuoteNotification();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.requestorSessionKey = reader.string();
                    break;
                case 2:
                    message.requestorSessionToken = reader.string();
                    break;
                case 3:
                    message.quoteRequestId = reader.string();
                    break;
                case 4:
                    message.assetType = reader.int32() as any;
                    break;
                case 6:
                    message.exchangeCode = reader.string();
                    break;
                case 7:
                    message.securityCode = reader.string();
                    break;
                case 8:
                    message.securityId = reader.string();
                    break;
                case 10:
                    message.bidPx = reader.double();
                    break;
                case 11:
                    message.bidSpotPx = reader.double();
                    break;
                case 12:
                    message.offerPx = reader.double();
                    break;
                case 13:
                    message.offerSpotPx = reader.double();
                    break;
                case 16:
                    message.quoteValidityInSecs = reader.int32();
                    break;
                case 17:
                    message.clientTierCode = reader.string();
                    break;
                case 18:
                    message.legQuoteGroup.push(QuoteNotification_LegQuoteGroup.decode(reader, reader.uint32()));
                    break;
                case 19:
                    message.underlyingQuotePrice.push(
                        QuoteNotification_UnderlyingQuotePrice.decode(reader, reader.uint32())
                    );
                    break;
                case 20:
                    message.productType = reader.int32() as any;
                    break;
                case 21:
                    message.accountBookOn = reader.string();
                    break;
                case 22:
                    message.bidMargin = reader.double();
                    break;
                case 23:
                    message.offerMargin = reader.double();
                    break;
                case 24:
                    message.midPx = reader.double();
                    break;
                case 25:
                    message.skipCreditCheck = reader.string();
                    break;
                case 26:
                    message.referenceQuoteRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<QuoteNotification>): QuoteNotification {
        const message = createBaseQuoteNotification();
        message.requestorSessionKey = object.requestorSessionKey ?? '';
        message.requestorSessionToken = object.requestorSessionToken ?? '';
        message.quoteRequestId = object.quoteRequestId ?? '';
        message.assetType = object.assetType ?? 1;
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.bidPx = object.bidPx ?? 0;
        message.bidSpotPx = object.bidSpotPx ?? 0;
        message.offerPx = object.offerPx ?? 0;
        message.offerSpotPx = object.offerSpotPx ?? 0;
        message.quoteValidityInSecs = object.quoteValidityInSecs ?? 0;
        message.clientTierCode = object.clientTierCode ?? '';
        message.legQuoteGroup = object.legQuoteGroup?.map((e) => QuoteNotification_LegQuoteGroup.fromPartial(e)) || [];
        message.underlyingQuotePrice =
            object.underlyingQuotePrice?.map((e) => QuoteNotification_UnderlyingQuotePrice.fromPartial(e)) || [];
        message.productType = object.productType ?? 1;
        message.accountBookOn = object.accountBookOn ?? '';
        message.bidMargin = object.bidMargin ?? 0;
        message.offerMargin = object.offerMargin ?? 0;
        message.midPx = object.midPx ?? 0;
        message.skipCreditCheck = object.skipCreditCheck ?? '';
        message.referenceQuoteRequestId = object.referenceQuoteRequestId ?? '';
        return message;
    }
};

function createBaseQuoteNotification_LegQuoteGroup(): QuoteNotification_LegQuoteGroup {
    return {
        bidForwardPoints: 0,
        offerForwardPoints: 0,
        currency: '',
        settlementDate: '',
        maturityDate: '',
        side: 1,
        bidSize: 0,
        offerSize: 0,
        rawMidPxForManulQuote: 0
    };
}

export const QuoteNotification_LegQuoteGroup = {
    encode(message: QuoteNotification_LegQuoteGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.bidForwardPoints !== 0) {
            writer.uint32(17).double(message.bidForwardPoints);
        }
        if (message.offerForwardPoints !== 0) {
            writer.uint32(25).double(message.offerForwardPoints);
        }
        if (message.currency !== '') {
            writer.uint32(34).string(message.currency);
        }
        if (message.settlementDate !== '') {
            writer.uint32(42).string(message.settlementDate);
        }
        if (message.maturityDate !== '') {
            writer.uint32(50).string(message.maturityDate);
        }
        if (message.side !== 1) {
            writer.uint32(56).int32(message.side);
        }
        if (message.bidSize !== 0) {
            writer.uint32(65).double(message.bidSize);
        }
        if (message.offerSize !== 0) {
            writer.uint32(73).double(message.offerSize);
        }
        if (message.rawMidPxForManulQuote !== 0) {
            writer.uint32(81).double(message.rawMidPxForManulQuote);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): QuoteNotification_LegQuoteGroup {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuoteNotification_LegQuoteGroup();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.bidForwardPoints = reader.double();
                    break;
                case 3:
                    message.offerForwardPoints = reader.double();
                    break;
                case 4:
                    message.currency = reader.string();
                    break;
                case 5:
                    message.settlementDate = reader.string();
                    break;
                case 6:
                    message.maturityDate = reader.string();
                    break;
                case 7:
                    message.side = reader.int32() as any;
                    break;
                case 8:
                    message.bidSize = reader.double();
                    break;
                case 9:
                    message.offerSize = reader.double();
                    break;
                case 10:
                    message.rawMidPxForManulQuote = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<QuoteNotification_LegQuoteGroup>): QuoteNotification_LegQuoteGroup {
        const message = createBaseQuoteNotification_LegQuoteGroup();
        message.bidForwardPoints = object.bidForwardPoints ?? 0;
        message.offerForwardPoints = object.offerForwardPoints ?? 0;
        message.currency = object.currency ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.maturityDate = object.maturityDate ?? '';
        message.side = object.side ?? 1;
        message.bidSize = object.bidSize ?? 0;
        message.offerSize = object.offerSize ?? 0;
        message.rawMidPxForManulQuote = object.rawMidPxForManulQuote ?? 0;
        return message;
    }
};

function createBaseQuoteNotification_UnderlyingQuotePrice(): QuoteNotification_UnderlyingQuotePrice {
    return { securityId: '', bidSpotPx: 0, offerSpotPx: 0 };
}

export const QuoteNotification_UnderlyingQuotePrice = {
    encode(message: QuoteNotification_UnderlyingQuotePrice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.securityId !== '') {
            writer.uint32(10).string(message.securityId);
        }
        if (message.bidSpotPx !== 0) {
            writer.uint32(17).double(message.bidSpotPx);
        }
        if (message.offerSpotPx !== 0) {
            writer.uint32(25).double(message.offerSpotPx);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): QuoteNotification_UnderlyingQuotePrice {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuoteNotification_UnderlyingQuotePrice();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.securityId = reader.string();
                    break;
                case 2:
                    message.bidSpotPx = reader.double();
                    break;
                case 3:
                    message.offerSpotPx = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<QuoteNotification_UnderlyingQuotePrice>): QuoteNotification_UnderlyingQuotePrice {
        const message = createBaseQuoteNotification_UnderlyingQuotePrice();
        message.securityId = object.securityId ?? '';
        message.bidSpotPx = object.bidSpotPx ?? 0;
        message.offerSpotPx = object.offerSpotPx ?? 0;
        return message;
    }
};

function createBaseQuoteRequestRejectNotification(): QuoteRequestRejectNotification {
    return {
        requestorSessionKey: '',
        requestorSessionToken: '',
        quoteRequestId: '',
        quoteRequestRejectReason: 1,
        rejectText: '',
        quoteRequestRejectGroup: []
    };
}

export const QuoteRequestRejectNotification = {
    encode(message: QuoteRequestRejectNotification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.requestorSessionKey !== '') {
            writer.uint32(10).string(message.requestorSessionKey);
        }
        if (message.requestorSessionToken !== '') {
            writer.uint32(18).string(message.requestorSessionToken);
        }
        if (message.quoteRequestId !== '') {
            writer.uint32(26).string(message.quoteRequestId);
        }
        if (message.quoteRequestRejectReason !== 1) {
            writer.uint32(32).int32(message.quoteRequestRejectReason);
        }
        if (message.rejectText !== '') {
            writer.uint32(42).string(message.rejectText);
        }
        for (const v of message.quoteRequestRejectGroup) {
            QuoteRequestRejectGroupNotification.encode(v!, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): QuoteRequestRejectNotification {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuoteRequestRejectNotification();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.requestorSessionKey = reader.string();
                    break;
                case 2:
                    message.requestorSessionToken = reader.string();
                    break;
                case 3:
                    message.quoteRequestId = reader.string();
                    break;
                case 4:
                    message.quoteRequestRejectReason = reader.int32() as any;
                    break;
                case 5:
                    message.rejectText = reader.string();
                    break;
                case 6:
                    message.quoteRequestRejectGroup.push(
                        QuoteRequestRejectGroupNotification.decode(reader, reader.uint32())
                    );
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<QuoteRequestRejectNotification>): QuoteRequestRejectNotification {
        const message = createBaseQuoteRequestRejectNotification();
        message.requestorSessionKey = object.requestorSessionKey ?? '';
        message.requestorSessionToken = object.requestorSessionToken ?? '';
        message.quoteRequestId = object.quoteRequestId ?? '';
        message.quoteRequestRejectReason = object.quoteRequestRejectReason ?? 1;
        message.rejectText = object.rejectText ?? '';
        message.quoteRequestRejectGroup =
            object.quoteRequestRejectGroup?.map((e) => QuoteRequestRejectGroupNotification.fromPartial(e)) || [];
        return message;
    }
};

function createBaseQuoteRequestRejectGroupNotification(): QuoteRequestRejectGroupNotification {
    return { assetType: 1, productType: 1, exchangeCode: '', securityCode: '', securityId: '', text: '' };
}

export const QuoteRequestRejectGroupNotification = {
    encode(message: QuoteRequestRejectGroupNotification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.assetType !== 1) {
            writer.uint32(8).int32(message.assetType);
        }
        if (message.productType !== 1) {
            writer.uint32(16).int32(message.productType);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(34).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(42).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(50).string(message.securityId);
        }
        if (message.text !== '') {
            writer.uint32(58).string(message.text);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): QuoteRequestRejectGroupNotification {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseQuoteRequestRejectGroupNotification();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.assetType = reader.int32() as any;
                    break;
                case 2:
                    message.productType = reader.int32() as any;
                    break;
                case 4:
                    message.exchangeCode = reader.string();
                    break;
                case 5:
                    message.securityCode = reader.string();
                    break;
                case 6:
                    message.securityId = reader.string();
                    break;
                case 7:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<QuoteRequestRejectGroupNotification>): QuoteRequestRejectGroupNotification {
        const message = createBaseQuoteRequestRejectGroupNotification();
        message.assetType = object.assetType ?? 1;
        message.productType = object.productType ?? 1;
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.text = object.text ?? '';
        return message;
    }
};

function createBaseFindAllQuotesRequest(): FindAllQuotesRequest {
    return { clientRequestId: '' };
}

export const FindAllQuotesRequest = {
    encode(message: FindAllQuotesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAllQuotesRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAllQuotesRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAllQuotesRequest>): FindAllQuotesRequest {
        const message = createBaseFindAllQuotesRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseUpdateOnMarketDataQuoteRequest(): UpdateOnMarketDataQuoteRequest {
    return {};
}

export const UpdateOnMarketDataQuoteRequest = {
    encode(_: UpdateOnMarketDataQuoteRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOnMarketDataQuoteRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateOnMarketDataQuoteRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(_: DeepPartial<UpdateOnMarketDataQuoteRequest>): UpdateOnMarketDataQuoteRequest {
        const message = createBaseUpdateOnMarketDataQuoteRequest();
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
