import { useAppSelector } from '../../state/hooks';
import { PairMap, selectActiveMarketPair } from '../../state/reducers/marketPairSlice';

const useActivePair = (): PairMap => {
    const activePair = useAppSelector(selectActiveMarketPair);

    if (activePair?.netdania) return activePair as PairMap;
    else
        return {
            celer: '',
            netdania: '',
            show: ''
        };
};

export default useActivePair;
