import { Tab } from '@headlessui/react';
import { useDisclosure } from '../../../utils/hooks/useDisclosure';
import ResetPasswordModal from '../ResetPasswordModal';

const AdvancedTab = () => {
    const [opened, handlers] = useDisclosure(false);

    return (
        <Tab.Panel className="p-2 py-3 sm:p-4 h-auto lg:h-[400px]">
            <div className="space-y-4">
                <div className="flex flex-col sm:flex-row justify-between items-center gap-0 sm:gap-3 text-neutral-200 w-full">
                    <div className="text-sm w-full sm:w-60">Change Password</div>
                    <span className="hidden sm:block">:</span>
                    <button
                        type="button"
                        className="w-full text-sm p-2 rounded-md bg-brand-background-dark border border-neutral-700 hover:bg-brand-primary"
                        onClick={handlers.open}>
                        Reset Current Password
                    </button>
                </div>
            </div>
            <ResetPasswordModal opened={opened} handlers={handlers} />
        </Tab.Panel>
    );
};

export default AdvancedTab;
