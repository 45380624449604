import { addDays } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';

export const todayAt17 = () => {
    const localDate = new Date();
    localDate.setHours(17);
    localDate.setMinutes(0);
    localDate.setSeconds(0);
    localDate.setMilliseconds(0);
    return localDate;
};

export const nyOffset = getTimezoneOffset('America/New_York');

export const getTradingDayStart = (date: Date = new Date()) => {
    // Now (in NY time)
    const nyNow = new Date(date.getTime() + nyOffset);

    // Is it after 17 NY time? ..else push back 24 hours.
    const currentTradingStart = nyNow.getHours() <= 17 ? addDays(todayAt17(), -1) : todayAt17();

    // Turn the time back to "neutral" (no offset)
    return new Date(currentTradingStart.getTime() - nyOffset);
};
