import { useAppDispatch, useAppSelector } from '@/state/hooks';
import {
    TraderMarket,
    selectCurrentWatchList,
    selectTraderWatchLists,
    setSelectedWatchList
} from '@/state/reducers/traderMarketSlice';
import { Combobox, Transition } from '@headlessui/react';
import cn from 'classnames';
import { Dispatch, Fragment, SetStateAction, useMemo, useState } from 'react';
import { MdExpandMore } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';

interface Option {
    id: string;
    label: string;
    value: string;
    markets: TraderMarket[];
}

interface WatchListSwitchProps {
    parentSize?: DOMRectReadOnly;
    setItems: Dispatch<
        SetStateAction<
            {
                id: number;
                market: TraderMarket;
            }[]
        >
    >;
}

const WatchListSwitch = (props: WatchListSwitchProps) => {
    const { parentSize, setItems } = props;

    const dispatch = useAppDispatch();
    const selected = useAppSelector(selectCurrentWatchList);
    const watchLists = useAppSelector(selectTraderWatchLists);

    const selectedWatchList = useMemo(() => {
        if (selected) return selected;
        else {
            return {
                id: watchLists[0].id,
                label: watchLists[0].name,
                value: watchLists[0].id,
                markets: watchLists[0].markets
            };
        }
    }, [selected, watchLists]);

    const options: Option[] = useMemo(() => {
        return watchLists.map((watchList) => ({
            id: watchList.id,
            label: watchList.name,
            value: watchList.id,
            markets: watchList.markets
        }));
    }, [watchLists]);

    const [query, setQuery] = useState<any>('');

    const filteredOptions = useMemo(() => {
        if (query) {
            const temp = query.replace('/', '').toUpperCase();
            return options.filter((option) => option.label.replace('/', '').match(temp));
        }
        return options;
    }, [options, query]);

    const { shouldFullWidth, shouldTruncate } = useMemo(() => {
        if (!parentSize) return { shouldFullWidth: false, shouldTruncate: false };
        else if (parentSize.width >= 345 && parentSize.width <= 650)
            return { shouldFullWidth: false, shouldTruncate: true };
        else if (parentSize.width < 345) return { shouldFullWidth: true, shouldTruncate: false };
        return { shouldFullWidth: false, shouldTruncate: false };
    }, [parentSize]);

    return (
        <Combobox
            value={selectedWatchList}
            onChange={(option) => {
                if (option) dispatch(setSelectedWatchList(option));
            }}>
            <div
                className={cn('relative', {
                    'w-full': shouldFullWidth
                })}>
                <div className="relative w-full cursor-default overflow-hidden bg-brand-background-dark border border-neutral-700 hover:border-neutral-600 text-left shadow-md sm:text-xs">
                    <Combobox.Button className="w-full py-1 pl-2 pr-7 text-left text-xs leading-5 text-neutral-200 truncate">
                        <div
                            className={cn('', {
                                'w-24 truncate text-ellipsis': shouldTruncate
                            })}>
                            {selectedWatchList.label}
                        </div>
                        <div className="absolute inset-y-0 right-0 flex items-center pr-1">
                            <MdExpandMore className="h-5 w-5 text-neutral-400" aria-hidden="true" />
                        </div>
                    </Combobox.Button>
                </div>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}>
                    <Combobox.Options
                        className={cn(
                            'absolute mt-2 z-[60] flex cursor-default bg-brand-background-dark border border-neutral-700 text-xs shadow-lg shadow-neutral-900 overflow-clip',
                            {
                                'left-0': !shouldFullWidth,
                                'right-0': shouldFullWidth
                            }
                        )}>
                        <div className="flex flex-col w-[203px] max-h-60 overflow-auto">
                            {/* <div className="flex justify-between items-center p-2 border-b border-neutral-700">
                                <span className="text-sm">Select Watchlist</span>
                                <MdClose className="w-4 h-4 cursor-pointer hover:text-neutral-400" />
                            </div> */}
                            {filteredOptions.length === 0 && query !== '' ? (
                                <div className="relative select-none p-2 text-neutral-200">Nothing found.</div>
                            ) : (
                                filteredOptions.map((option, optionIdx) => {
                                    return (
                                        <Combobox.Option
                                            key={optionIdx}
                                            className="relative cursor-pointer select-none py-2 pl-2 pr-4 group hover:bg-brand-primary text-neutral-200"
                                            value={option}
                                            onClick={() => {
                                                setItems(option.markets.map((market) => ({ id: uuidv4(), market })));
                                            }}>
                                            <span className="block truncate">{option?.label}</span>
                                        </Combobox.Option>
                                    );
                                })
                            )}
                        </div>
                    </Combobox.Options>
                </Transition>
            </div>
        </Combobox>
    );
};

export default WatchListSwitch;
