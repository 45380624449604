import { Nullable } from '@/model/common';
import { CelerBalance, CollapsedBalances } from '@/state/reducers/balanceSlice';
import { InferType, number, object, string } from 'yup';

export const fiatWithdrawalSchema = (balances?: CollapsedBalances[]) =>
    object({
        // select
        account: object({ label: string(), value: string() }).test(
            'valueNotUndefined',
            `Please select an account`,
            (acc) => !!acc.value
        ),
        amount: number()
            .required('Withdrawal Amount cannot be empty!')
            .typeError('Invalid Input: Please input a number.')
            .min(0, 'Invalid Input: Can not be negative.')
            .test('noAccount', `Please select an account.`, function () {
                const { account } = this.parent;
                return account.value;
            })
            .test('noCurrency', `Please select a currency.`, function () {
                const { currency } = this.parent;
                return currency.value;
            })
            .test('invalidBalance', `Insufficient balance for withdrawal`, function (value) {
                const { currency } = this.parent;
                let availableAmount;
                if (currency?.value && balances) {
                    const ccy = balances.find((balance) => balance.currency === currency.value) as CollapsedBalances &
                        CelerBalance;
                    const value = ccy?.totalAmount || ccy?.netPosition;
                    availableAmount = value || 0;
                } else {
                    availableAmount = 'No Currency/Balance';
                }

                return availableAmount !== 'No Currency/Balance' && !(availableAmount < +(value || 0));
            }),
        // select
        currency: object({ label: string(), value: string() }).test(
            'valueNotUndefined',
            `Please select an currency`,
            (ccy) => !!ccy.value
        ),
        bank: object({
            beneficiary_name: string().required('Beneficiary Bank Name cannot be empty!'),
            swift_bic: string().required('SWIFT ID / BIC cannot be empty!'),
            clearing_code: string().optional(),
            account_name: string().required('Account Name cannot be empty!'),
            account_number_iban: string().required('Account Number / IBAN cannot be empty!'),
            account_currency: object({ label: string(), value: string() })
                .test('valueNotUndefined', `Please select an currency`, (ccy) => !!ccy.value)
                .required('Account Currency cannot be empty!')
        }),
        intermediary_bank: object({
            beneficiary_name: string(),
            address: string(),
            swift_bic: string(),
            account_number: string()
        }).optional(),
        email: object({
            cc_to: string()
        }).optional()
    });

export type FiatWithdrawalFormValues = InferType<ReturnType<typeof fiatWithdrawalSchema>>;
export type FiatWithdrawalFormInput = Nullable<FiatWithdrawalFormValues>;
