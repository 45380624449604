/* eslint-disable */
import { CallContext, CallOptions } from 'nice-grpc-common';
import Long from 'long';
import { Empty } from '../../../../../google/protobuf/empty';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.baseserver.communication.protobuf';

export interface ServerEvent {
    serverAckTime: string;
    serviceConnected: boolean;
}

function createBaseServerEvent(): ServerEvent {
    return { serverAckTime: '0', serviceConnected: false };
}

export const ServerEvent = {
    encode(message: ServerEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.serverAckTime !== '0') {
            writer.uint32(8).int64(message.serverAckTime);
        }
        if (message.serviceConnected === true) {
            writer.uint32(16).bool(message.serviceConnected);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): ServerEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseServerEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.serverAckTime = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.serviceConnected = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<ServerEvent>): ServerEvent {
        const message = createBaseServerEvent();
        message.serverAckTime = object.serverAckTime ?? '0';
        message.serviceConnected = object.serviceConnected ?? false;
        return message;
    }
};

export type ServerEventServiceDefinition = typeof ServerEventServiceDefinition;
export const ServerEventServiceDefinition = {
    name: 'ServerEventService',
    fullName: 'com.celertech.baseserver.communication.protobuf.ServerEventService',
    methods: {
        subscribeToServerEvents: {
            name: 'subscribeToServerEvents',
            requestType: Empty,
            requestStream: false,
            responseType: ServerEvent,
            responseStream: true,
            options: {}
        }
    }
} as const;

export interface ServerEventServiceServiceImplementation<CallContextExt = {}> {
    subscribeToServerEvents(
        request: Empty,
        context: CallContext & CallContextExt
    ): ServerStreamingMethodResult<DeepPartial<ServerEvent>>;
}

export interface ServerEventServiceClient<CallOptionsExt = {}> {
    subscribeToServerEvents(
        request: DeepPartial<Empty>,
        options?: CallOptions & CallOptionsExt
    ): AsyncIterable<ServerEvent>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}

export type ServerStreamingMethodResult<Response> = {
    [Symbol.asyncIterator](): AsyncIterator<Response, void>;
};
