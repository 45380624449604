import { useCallback, useEffect, useRef, useState } from 'react';
import { useDidUpdate } from './useDidUpdate';

const DEFAULT_EVENTS: string[] = ['price_increased', 'price_decreased'];
const DEFAULT_OPTIONS = {
    events: DEFAULT_EVENTS,
    initialState: false
};

export function useTickerIdle(timeout: number, event) {
    const { events, initialState } = DEFAULT_OPTIONS;
    const [tickerIdle, setTickerIdle] = useState<boolean>(initialState);
    const timer = useRef<NodeJS.Timeout>();

    const handleEvents = useCallback(() => {
        if (timer.current) {
            setTickerIdle(false);
            clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                setTickerIdle(true);
            }, timeout);
        }
    }, [timer.current]);

    useDidUpdate(() => {
        if (events.includes(event.status)) handleEvents();
    }, [event]);

    useEffect(() => {
        timer.current = setTimeout(() => {
            setTickerIdle(true);
        }, timeout);

        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, []);

    return tickerIdle;
}
