import { useEffect, useState } from 'react';

function useDynamicSVGImport(svgPath?: string) {
    const [svg, setSvg] = useState(null);

    useEffect(() => {
        import(`cryptocurrency-icons/svg/color/${svgPath}.svg`)
            .then((svg) => setSvg(svg.default))
            .catch((error) => setSvg(null));
    }, [svgPath]);

    return svg;
}

export default useDynamicSVGImport;
