import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import Divider from '../common/Divider';
import {
    ResetPasswordFormInput,
    ResetPasswordFormValues,
    resetPasswordSchema
} from '../form/schema/resetPasswordSchema';

import { resetUserPassword } from '../../services/UserService';
import { useAppSelector } from '../../state/hooks';
import { selectCredentials } from '../../state/reducers/authSlice';
import useToast from '../../utils/hooks/useToast';
import { Button } from '../common/Button';
import Input from '../inputs/Input';
import InputController from '../inputs/InputController';
import Modal, { ModalClose, ModalHeader, ModalProps, ModalTitle } from './Modal';

const defaultValues: Partial<ResetPasswordFormInput> = {
    current_password: undefined,
    new_password: undefined,
    confirm_new_password: undefined
};

const ResetPasswordModal = (props: ModalProps) => {
    const { opened, handlers } = props;

    const [toastError, toastSuccess] = useToast();
    const credentials = useAppSelector(selectCredentials);

    const form = useForm<ResetPasswordFormInput>({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(resetPasswordSchema)
    });
    const {
        handleSubmit,
        reset,
        setError,
        formState: { isSubmitting }
    } = form;

    const onSubmit = async (data: ResetPasswordFormInput) => {
        const dataValidated = data as ResetPasswordFormValues;
        const { current_password, new_password, confirm_new_password } = dataValidated;
        if (credentials) {
            if (new_password === confirm_new_password) {
                const resp = await resetUserPassword(credentials, current_password, new_password);

                if (resp.succeeded) {
                    const title = resp.message[0];
                    const body = 'Your password had been successfully changed to the new password.';
                    toastSuccess({ body, title });
                    handlers.close();
                } else {
                    const title = 'Change User Password';
                    const body = resp.message[0];
                    toastError({ body, title, persist: true });
                }
            } else {
                const title = 'Change User Password';
                const body = 'Confirm Password does not match with the New Password.';
                setError('confirm_new_password', { type: 'custom', message: body });
                toastError({ body, title, persist: true });
            }
        }
    };

    const onError = (e: FieldErrors) => console.log(e);

    useEffect(() => {
        if (opened) {
            reset(defaultValues);
        }
    }, [opened]);

    return (
        <Modal {...props} className="h-auto" size="max-w-md">
            <ModalHeader>
                <ModalTitle>Change Password</ModalTitle>
                <ModalClose handlers={handlers} />
            </ModalHeader>
            <Divider />
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="p-2 sm:p-4">
                        <div className="flex flex-col gap-4">
                            <div className="space-y-3 sm:space-y-2">
                                <InputController
                                    name="current_password"
                                    label="Current Password"
                                    placeholder="Current Password"
                                    required>
                                    <Input type="password" />
                                </InputController>
                                <InputController
                                    name="new_password"
                                    label="New Password"
                                    placeholder="New Password"
                                    required>
                                    <Input type="password" />
                                </InputController>
                                <InputController
                                    name="confirm_new_password"
                                    label="Confirm Password"
                                    placeholder="Confirm Password"
                                    required>
                                    <Input type="password" />
                                </InputController>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div className="flex flex-row justify-between text-neutral-200 text-sm items-center p-2 sm:p-4">
                        <div className="justify-end flex space-x-2">
                            <button
                                type="button"
                                className="rounded-md p-2 px-4 bg-neutral-600 hover:bg-neutral-500"
                                onClick={handlers.close}>
                                Cancel
                            </button>
                            <Button
                                type="button"
                                className="rounded-md p-2 px-4 bg-brand-primary hover:bg-brand-primary-light"
                                onClick={handleSubmit(onSubmit, onError)}
                                isLoading={isSubmitting}>
                                Change Password
                            </Button>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default ResetPasswordModal;
