// import Footer from './Footer';
// import UserMarket from '../UserMarket';
import Footer from './Footer';
import MobileSubHeader from './MobileSubHeader';
import Navbar from './Navbar';

interface LayoutProps extends React.PropsWithChildren {}

export default function Layout({ children }: LayoutProps) {
    return (
        <div className="flex flex-col">
            <header className="w-full">
                <Navbar />
            </header>
            <MobileSubHeader />
            <main className="trading-layout">{children}</main>
            <Footer />
        </div>
    );
}
