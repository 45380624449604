/* eslint-disable */
export const protobufPackage = 'com.celertech.orderrouting.api.enums.executiontype';

export enum ExecutionType {
    NEW = 1,
    TRADE = 2,
    CANCELED = 3,
    REPLACED = 4,
    PENDING_CANCEL = 5,
    REJECTED = 6,
    PENDING_NEW = 7,
    RESTATEMENT = 8,
    UNKNOWN = 9,
    PENDING_AMEND = 10,
    SUSPENDED = 11,
    TRADE_CORRECT = 12,
    TRADE_CANCEL = 13,
    TRADE_RELEASED_CLEARING = 14,
    EXPIRED = 15,
    TRIGGERED_OR_ACTIVATED_BY_SYSTEM = 16,
    SENDING = 17,
    ORDER_STATUS = 18,
    DONE_FOR_DAY = 19,
    CALCULATED = 20,
    UNRECOGNIZED = -1
}
