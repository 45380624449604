import { useEffect, useRef } from 'react';

function usePreviousState(state: any) {
    const prevListRef = useRef(state);

    useEffect(() => {
        prevListRef.current = state;
    }, [state]);

    return prevListRef.current;
}

export default usePreviousState;
