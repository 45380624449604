import cn from 'classnames';
import { ComponentProps, ReactElement, cloneElement, forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// to be deprecated

interface ExecutionInputControllerProps extends ComponentProps<'input'> {
    name: string;
    label?: string;
    className?: string;
    children: ReactElement;
    hasTooltipIcon?: boolean;
}

const ExecutionInputController = forwardRef<HTMLInputElement, ExecutionInputControllerProps>((props, ref) => {
    const { name, label, children, hasTooltipIcon = false, ...rest } = props;
    const { required, disabled } = rest;
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState: { isSubmitted }
            }) => (
                <div>
                    <div className="flex flex-col gap-1 text-neutral-200 w-full">
                        {label ? (
                            <label
                                className={cn('relative text-sm', {
                                    'w-[calc(100%-20px)]': hasTooltipIcon
                                })}>
                                {label}
                                {required && <span className="absolute -top-1 -right-1.5">*</span>}
                            </label>
                        ) : null}
                        <div className="w-full">
                            {cloneElement(children, {
                                ref,
                                onChange,
                                onBlur,
                                value,
                                selected: value,
                                className: {
                                    'text-neutral-400 cursor-not-allowed': disabled,
                                    '': !disabled
                                },
                                ...rest
                            })}
                        </div>
                    </div>
                    {error && (
                        <div className="flex justify-between items-center gap-3">
                            <span className="hidden sm:block w-60" />
                            <span className="hidden sm:block" />
                            <div className="text-brand-red italic text-sm pt-1 w-full">{error?.message}</div>
                        </div>
                    )}
                </div>
            )}
        />
    );
});

ExecutionInputController.displayName = 'ExecutionInputController';

export default ExecutionInputController;
