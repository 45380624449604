import { chartConfig } from '@/config/chart';
import { getLocalChartState, initChartSettings } from '@/helpers/chartHelper';
import { useAppSelector } from '@/state/hooks';
import { selectActiveMarketPair, selectMarketPairs } from '@/state/reducers/marketPairSlice';
import { IChartingLibraryWidget, ResolutionString } from '@/types/charting_library';
import useCSSVariables from '@/utils/hooks/useCSSVariables';
import { useDatafeed } from '@/utils/hooks/useDatafeed';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import { useEffect, useRef } from 'react';
import { useLocale } from 'react-aria';

export default function AdvancedChartView() {
    const activePair = useAppSelector(selectActiveMarketPair);
    const pairs = useAppSelector(selectMarketPairs);

    const { locale } = useLocale();
    const datafeed = useDatafeed();
    const variables = useCSSVariables();

    const widgetRef = useRef<IChartingLibraryWidget | null>(null);

    useEffect(() => {
        window.chartLoaded = false;
        const chartState = getLocalChartState();
        if (chartState) {
            chartState.charts[0].panes[0].sources[0].state.symbol = activePair.celer;
            localStorage.setItem('chartState', JSON.stringify(chartState));
        } else {
            window.chartLoaded = true;
        }

        const TVWidget = new window.TradingView.widget(chartConfig(locale, datafeed, variables));
        window.TVWidget = TVWidget;

        TVWidget.onChartReady(() => {
            widgetRef.current = TVWidget;
            if (chartState) {
                TVWidget.load(chartState);
                window.chartLoaded = true;
            }
            initChartSettings(TVWidget, variables);

            const widget = TVWidget as any;
            widget._iFrame.onload = () => {
                const chartState = getLocalChartState();
                setTimeout(() => {
                    if (chartState) TVWidget.load(chartState);
                    initChartSettings(TVWidget, variables);
                }, 100);
            };
        });
    }, []);

    useDidUpdate(() => {
        if (widgetRef.current && pairs.find((pair) => pair.netdania === activePair.netdania)) {
            const resolution = widgetRef.current.chart().resolution() as ResolutionString;
            widgetRef.current.setSymbol(`${activePair.netdania}`, resolution, () => {});
            console.log({ info: 'current active pair have changed', activePair });
        }
    }, [activePair]);

    return <div id="tv_chart_container" className="h-full" />;
}
