import { useState } from 'react';

export type UseDisclosureReturn = [
    boolean,
    {
        open: () => void;
        close: () => void;
        toggle: () => void;
    }
];

export const useDisclosure = (initialState: boolean): UseDisclosureReturn => {
    const [opened, setOpened] = useState(initialState);

    const open = () => setOpened(true);
    const close = () => setOpened(false);
    const toggle = () => setOpened(!opened);

    return [
        opened,
        {
            open,
            close,
            toggle
        }
    ];
};
