import { DEFAULT_MOBILE_LAYOUT, XplorSpotModules } from '@/config/xplorSpotLayout';
import { selectXplorSpotMobileLayoutNode, setXplorSpotMobileLayoutNode } from '@/state/reducers/xplorSpotLayoutSlice';
import cn from 'classnames';
import { useMemo } from 'react';
import { IconType } from 'react-icons/lib';
import {
    MdAccountBalanceWallet,
    MdCandlestickChart,
    MdHistory,
    MdPriceChange,
    MdSwapVerticalCircle
} from 'react-icons/md';
import { updateTree } from 'react-mosaic-component';
import { useAppDispatch, useAppSelector } from '../../state/hooks';

const tabs: {
    label: string;
    name: XplorSpotModules;
    Icon: IconType;
}[] = [
    { label: 'Order', name: 'Order', Icon: MdSwapVerticalCircle },
    { label: 'Depth', name: 'Order Book', Icon: MdPriceChange },
    { label: 'Chart', name: 'Chart', Icon: MdCandlestickChart },
    { label: 'Blotters', name: 'Blotter', Icon: MdHistory },
    { label: 'Balances', name: 'Balances', Icon: MdAccountBalanceWallet }
];

const MobileTabNavigation = () => {
    const dispatch = useAppDispatch();
    const mobileLayoutNode = useAppSelector(selectXplorSpotMobileLayoutNode) as any;
    const layout = useMemo(() => (mobileLayoutNode ? mobileLayoutNode : DEFAULT_MOBILE_LAYOUT), [mobileLayoutNode]);

    return (
        <div className="w-full h-full grid grid-flow-col auto-cols-fr divide-x-0 2xs:divide-x 2xs:divide-neutral-700 bg-brand-background-dark">
            {tabs.map((tab, index) => (
                <TabButton
                    key={tab.name}
                    Icon={tab.Icon}
                    isActive={layout?.first === tab.name}
                    onClick={() => switchTab(layout, tab.name, dispatch)}>
                    {tab.label}
                </TabButton>
            ))}
        </div>
    );
};

export default MobileTabNavigation;

const TabButton = ({ Icon, isActive, onClick, children }: any) => {
    return (
        <button
            className={cn('h-full flex flex-col items-center justify-center gap-0.5 p-2 min-w-[42px]', {
                ['text-brand-primary']: isActive,
                ['text-neutral-200']: !isActive
            })}
            onClick={onClick}>
            <div>
                <Icon className="w-4 h-4" />
            </div>
            <span className="text-2xs 2xs:text-xs">{children}</span>
        </button>
    );
};

const switchTab = (mobileLayoutNode, moduleToDrop, dispatch) => {
    let currentNode = mobileLayoutNode;
    if (currentNode) {
        currentNode = updateTree(currentNode, [
            {
                path: [],
                spec: {
                    $set: {
                        direction: 'row',
                        first: moduleToDrop,
                        second: '',
                        splitPercentage: 100
                    }
                }
            }
        ]);
    } else {
        currentNode = moduleToDrop;
    }
    dispatch(setXplorSpotMobileLayoutNode(currentNode));
};
