import CurrencySelect, { Option } from '@/components/inputs/CurrencySelect';
import ErrorMessage from '@/components/inputs/ErrorMessage';
import { currencyOptions } from '@/helpers/currencyHelper';
import { BalanceItem, CollapsedBalances } from '@/state/reducers/balanceSlice';
import { store } from '@/state/store';
import { useCurrency } from '@/utils/hooks/useCurrency';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface ConvertFromProps {
    balances: any[];
    // balances: BalanceItem[] | CollapsedBalances[];
    setConvertFrom(e: Option): void;
}

const mode = window.config?.modules?.conversion?.mode || 'enableAll';

const ConvertFrom = ({ balances, setConvertFrom }: ConvertFromProps) => {
    const { watch, setValue, control } = useFormContext();
    const [convertFrom, amount] = watch(['convertFrom', 'amount']);

    const { min_order_size, order_decimals, increments } = useCurrency(convertFrom.value);

    const convertFromOptions = useMemo(() => {
        const availablePairs = Object.keys(store.getState().marketPair.bidAsk);
        const options = currencyOptions
            .filter((ccy) => availablePairs.find((pair) => pair.includes(ccy.value)))
            .sort((a, b) => a.label.localeCompare(b.label));
        if (mode === 'enableAll') {
            return options;
        } else {
            return options.filter((b) => {
                const balance = balances.find((balance) => balance.currency === b.value) as BalanceItem &
                    CollapsedBalances;
                return b.value === 'BTC' || (balance?.netPosition || balance?.totalAmount || 0) > 0;
            });
        }
    }, [balances]);

    const convertFromBalance = useMemo(() => {
        const balance = balances.find((b) => b.currency === convertFrom.value) as BalanceItem & CollapsedBalances;
        return balance?.netPosition || balance?.totalAmount || 0;
    }, [balances, convertFrom.value]);

    return (
        <div className="w-full">
            <span className="text-neutral-400 text-xs">From</span>
            <Controller
                control={control}
                name="convertFrom"
                render={({ field: { onChange }, fieldState: { error } }) => {
                    return (
                        <CurrencySelect
                            selected={convertFrom}
                            onSelectChange={(e) => {
                                setConvertFrom(e);
                                onChange(e);
                            }}
                            options={convertFromOptions}>
                            <div className="flex flex-col border border-neutral-700 border-t-0 bg-brand-background-dark py-2 pb-4 px-3 rounded-b-md">
                                <div className="flex flex-col items-end justify-center h-32 w-full">
                                    <Controller
                                        control={control}
                                        name="amount"
                                        render={({ field: { name, onChange }, fieldState: { error } }) => {
                                            return (
                                                <div>
                                                    <div className="relative">
                                                        <input
                                                            className="w-full text-right pr-6 h-10 leading-none text-4xl font-bold tracking-wide bg-transparent text-neutral-200 focus-visible:outline-none placeholder:text-neutral-400"
                                                            value={amount || ''}
                                                            onChange={(e) => onChange(e.target.value)}
                                                            placeholder="0"
                                                            min={min_order_size}
                                                            max={convertFromBalance}
                                                        />
                                                        <div className="absolute right-0 top-0 -mt-0.5 h-full flex flex-col justify-center text-xs text-neutral-200 w-5">
                                                            <button
                                                                type="button"
                                                                className={cn('hover:text-brand-primary-light px-0')}
                                                                onClick={() => {
                                                                    const prevValue = +(amount || 0);
                                                                    const nextValue = BigNumber(prevValue)
                                                                        .plus(increments)
                                                                        .toNumber();
                                                                    setValue(name, nextValue, { shouldValidate: true });
                                                                }}>
                                                                &#x25B2;
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className={cn('hover:text-brand-primary-light px-0')}
                                                                onClick={() => {
                                                                    const prevValue = +(amount || 0);
                                                                    const nextValue = BigNumber(prevValue)
                                                                        .minus(increments)
                                                                        .toNumber();
                                                                    if (nextValue <= +min_order_size)
                                                                        setValue(name, min_order_size, {
                                                                            shouldValidate: true
                                                                        });
                                                                    else
                                                                        setValue(name, nextValue, {
                                                                            shouldValidate: true
                                                                        });
                                                                }}>
                                                                &#x25BC;
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage className="text-end" error={error} isPadded={false} />
                                                </div>
                                            );
                                        }}
                                    />
                                    <span className="text-2xs text-neutral-400">
                                        You have{' '}
                                        <u>
                                            {convertFromBalance} {convertFrom.value}
                                        </u>
                                    </span>
                                </div>
                                <div className="flex items-center justify-center gap-3 text-xs">
                                    {[0.25, 0.5, 0.75, 1].map((percent) => (
                                        <PercentButton
                                            key={percent}
                                            percent={percent}
                                            onClick={() => {
                                                const val = onPercent(convertFromBalance, percent);
                                                setValue('amount', val ? val.toFixed(order_decimals) : val, {
                                                    shouldValidate: true
                                                });
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                            <ErrorMessage error={error} />
                        </CurrencySelect>
                    );
                }}
            />
        </div>
    );
};

export default ConvertFrom;

const PercentButton = ({ percent, onClick }: { percent: number; onClick: any }) => {
    return (
        <div className="p-1 px-3 bg-neutral-700 rounded-full cursor-pointer" onClick={onClick}>
            {percent === 1 ? 'MAX' : `${percent * 100}%`}
        </div>
    );
};

function onPercent(convertFromBalance: number, percent: number) {
    if (convertFromBalance > 0) return BigNumber(convertFromBalance).multipliedBy(percent).toNumber();
    else return 0;
}

export function convertToRate(bidAsk: { bid: number; ask: number } | undefined, type: string) {
    if (bidAsk?.ask && type === 'ask') return BigNumber(1).dividedBy(bidAsk.ask).toNumber();
    else if (bidAsk?.bid && type === 'bid') return BigNumber(bidAsk.bid).toNumber();
    else return 0;
}
