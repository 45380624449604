import { RadioGroup } from '@headlessui/react';
import cn from 'classnames';
import { Side } from '../../compiled_proto/com/celertech/marketdata/api/enums/SideProto';

interface OrderTypeSwitchProps {
    initialValue: Side;
    onChange: any;
    // onChange: (side: Side) => void;
}

export default function OrderTypeSwitch(props: OrderTypeSwitchProps) {
    return (
        <RadioGroup value={props.initialValue} onChange={props.onChange}>
            <div className="flex flex-row rounded-sm overflow-hidden font-semibold text-sm uppercase">
                <RadioGroup.Option value={Side.BUY} className="w-1/2 text-center focus:outline-none">
                    {({ checked }) => {
                        const buyButtonClassname = cn('p-3 cursor-pointer transition-colors', {
                            'bg-brand-primary hover:bg-brand-primary-light text-white': checked,
                            'bg-neutral-600 hover:bg-neutral-500': !checked
                        });
                        return <div className={buyButtonClassname}>Buy</div>;
                    }}
                </RadioGroup.Option>
                <RadioGroup.Option value={Side.SELL} className="w-1/2 text-center focus:outline-none">
                    {({ checked }) => {
                        const sellButtonClassname = cn('p-3 cursor-pointer transition-colors', {
                            'bg-brand-red hover:bg-brand-red-light text-white': checked,
                            'bg-neutral-600 hover:bg-neutral-500': !checked
                        });
                        return <div className={sellButtonClassname}>Sell</div>;
                    }}
                </RadioGroup.Option>
            </div>
        </RadioGroup>
    );
}
