import { User } from '@/state/reducers/authSlice';
import axios from 'axios';
import { CryptoDepositFormValues } from '../components/form/schema/cryptoDepositSchema';
import { CryptoWithdrawalFormValues } from '../components/form/schema/cryptoWithdrawalSchema';
import { FiatDepositFormValues } from '../components/form/schema/fiatDepositSchema';
import { FiatWithdrawalFormValues } from '../components/form/schema/fiatWithdrawalSchema';

export async function requestFiatWithdrawal(credentials: User, data: FiatWithdrawalFormValues) {
    const expressUrl = window.config.integration.express.rest;
    const receivers: any = [];

    if (data.email.cc_to) receivers.push(data.email.cc_to);

    return await axios.post(`${expressUrl}/request-withdrawal`, {
        type: 'Fiat',
        send_to: receivers,
        context: { user: credentials.username, sessionToken: credentials.authToken, ...data }
    });
}

export async function requestCryptoWithdrawal(credentials: User, data: CryptoWithdrawalFormValues) {
    const expressUrl = window.config.integration.express.rest;
    const receivers: any = [];

    if (data.email.cc_to) receivers.push(data.email.cc_to);

    return await axios.post(`${expressUrl}/request-withdrawal`, {
        type: 'Crypto',
        send_to: receivers,
        context: { user: credentials.username, sessionToken: credentials.authToken, ...data }
    });
}

export async function requestFiatDeposit(credentials: User, data: FiatDepositFormValues) {
    const expressUrl = window.config.integration.express.rest;
    const receivers: any = [];

    if (data.email.cc_to) receivers.push(data.email.cc_to);

    return await axios.post(`${expressUrl}/request-deposit`, {
        type: 'Fiat',
        send_to: receivers,
        context: { user: credentials.username, sessionToken: credentials.authToken, ...data }
    });
}

export async function requestCryptoDeposit(credentials: User, data: CryptoDepositFormValues) {
    const expressUrl = window.config.integration.express.rest;
    const receivers: any = [];

    if (data.email.cc_to) receivers.push(data.email.cc_to);

    return await axios.post(`${expressUrl}/request-deposit`, {
        type: 'Crypto',
        send_to: receivers,
        context: { user: credentials.username, sessionToken: credentials.authToken, ...data }
    });
}
