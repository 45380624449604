import { Account } from '@/state/reducers/authSlice';
import { store } from '@/state/store';

export function navigateAccordingToAccountType(account: Account, navigate: (path: string) => void) {
    const accountProperty = account.properties.find((property) => property.key === 'TYPE');

    switch (accountProperty?.value || '') {
        case 'MARGIN':
            navigate('/trader');
            break;
        case 'NOP':
            if (location.pathname.includes('/trader')) navigateAwayFromXplorTrader(navigate);
            break;
        default:
            break;
    }
}

export function navigateAwayFromXplorTrader(navigate: (path: string) => void) {
    const defaultLayout = store.getState().settings.trading.tradingLayout.value;
    if (defaultLayout === 'xplorspot') navigate('/');
    if (defaultLayout === 'xplorspotlite') navigate('/lite');
    else navigate('/');
}
