import ModuleWindow from '@/components/mosaic/ModuleWindow';
import ModuleWindowControls from '@/components/mosaic/ModuleWindowControl';
import instrumentConfig from '@/config/instruments';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { selectActiveMarketPair, selectMarketPairs, setActivePair } from '@/state/reducers/marketPairSlice';
import cn from 'classnames';
import { useCallback, useMemo } from 'react';
import { isTablet } from 'react-device-detect';
import { MdOpenInNew } from 'react-icons/md';
import AdvancedChartView from './AdvancedChartView';
import SwitchActivePair from './SwitchActivePair';

const ChartModule = (props: any) => {
    const { screen, shouldOverlayCharts, tradingModule } = props;
    const dispatch = useAppDispatch();
    const pairs = useAppSelector(selectMarketPairs);
    const activePair = useAppSelector(selectActiveMarketPair);

    const onPopOut = useCallback(
        () => window.open(`/chart?symbol=${activePair.netdania}`, '', 'width=1200,height=600'),
        [activePair]
    );

    const instrumentOptions = useMemo(() => {
        return pairs.map((instrument) => ({
            label: instrument.show,
            value: instrument.celer,
            market: instrument,
            config: instrumentConfig[instrument.celer]
        }));
    }, [pairs]);

    return (
        <ModuleWindow
            {...props}
            renderToolbar={() => (
                <div className="flex justify-between items-center w-full h-full border-b border-b-neutral-700 text-neutral-200">
                    <div className="h-full flex items-center">
                        <span className="p-2 font-semibold">{tradingModule.title}</span>

                        {(screen.laptop || screen.desktop) && !isTablet && (
                            <div
                                title="Open in Separate Window"
                                className={cn('h-full px-2 flex items-center', {
                                    ['cursor-pointer hover:bg-brand-primary']: true
                                })}
                                onClick={onPopOut}>
                                <MdOpenInNew className="w-4 h-4" />
                            </div>
                        )}
                    </div>
                    <div className="flex h-full items-center">
                        {(screen.laptop || screen.desktop) && !isTablet && (
                            <SwitchActivePair
                                selected={{
                                    label: activePair.show || 'Instrument Selector',
                                    value: activePair.celer || undefined,
                                    market: undefined
                                }}
                                options={instrumentOptions}
                                onSubmit={(instrument) => {
                                    dispatch(setActivePair(instrument));
                                }}
                            />
                        )}
                        <ModuleWindowControls screen={screen} />
                    </div>
                </div>
            )}>
            <div className="h-full bg-brand-background">
                {shouldOverlayCharts && <div className="overlay h-full w-full absolute z-10" />}
                {activePair?.celer && <AdvancedChartView />}
            </div>
        </ModuleWindow>
    );
};

export default ChartModule;
