/* eslint-disable */
import { MarketDataRequestType } from '../enums/MarketDataRequestTypeProto';
import { MarketDataUpdateType } from '../enums/MarketDataUpdateTypeProto';
import { MarketDataBookType } from '../enums/MarketDataBookTypeProto';
import { AssetType } from '../enums/AssetTypeProto';
import { ProductType } from '../enums/ProductTypeProto';
import { MarketDataMidrateLevelType } from '../enums/MarketDataMidrateLevelTypeProto';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.marketdata.api.price';

export interface MarketDataSubscriptionRequest {
    clientRequestId: string;
    marketDataRequestType: MarketDataRequestType;
    marketDataUpdateType: MarketDataUpdateType;
    marketDataBookType: MarketDataBookType;
    assetType: AssetType;
    exchangeCode: string;
    securityCode: string;
    securityId: string;
    settlementType: string;
    settlementDate: string;
    partyId: string;
    requestedByUser: string;
    productType: ProductType;
    marketDataRequestId: string;
    marketDataMidrateLevelType: MarketDataMidrateLevelType;
    marketDataRequestLeg: MarketDataRequestLeg[];
    /** Add currency for Future asset type */
    currency: string;
}

export interface MarketDataRequestLeg {
    securityCode: string;
    securityId: string;
    settlementType: string;
    settlementDate: string;
    legQty: number;
}

export interface MarketDataDestinationSubscriptionRequest {
    marketDataRequestId: string;
    marketDataRequestType: MarketDataRequestType;
    marketDataUpdateType: MarketDataUpdateType;
    marketDataBookType: MarketDataBookType;
    destinationKey: string;
    assetType: AssetType;
    exchangeCode: string;
    securityCode: string;
    securityId: string;
    settlementType: string;
    settlementDate: string;
    partyId: string;
    sessionKey: string;
    sessionToken: string;
    requestedByUser: string;
    productType: ProductType;
    marketDataMidrateLevelType: MarketDataMidrateLevelType;
    marketDataRequestLeg: MarketDataRequestLeg[];
    /** Add currency for Future asset type */
    currency: string;
}

export interface MarketDataSystemSubscriptionRequest {
    clientRequestId: string;
    marketDataRequestType: MarketDataRequestType;
    marketDataUpdateType: MarketDataUpdateType;
    marketDataBookType: MarketDataBookType;
    assetType: AssetType;
    exchangeCode: string;
    securityCode: string;
    securityId: string;
    settlementType: string;
    settlementDate: string;
    partyId: string;
    productType: ProductType;
    marketDataRequestId: string;
    trader: string;
}

export interface UpdateOnAllSecurityStatusRequest {
    clientRequestId: string;
    exchangeCode: string;
    securityCode: string;
    type: MarketDataUpdateType;
}

export interface MarketDataDestinationCommand {
    clientRequestId: string;
    operation: MarketDataDestinationCommand_Operation;
    marketDataDestinationKey: string;
    incomingSeqNo: number;
    outgoingSeqNo: number;
}

export enum MarketDataDestinationCommand_Operation {
    START = 0,
    STOP = 1,
    EOD = 2,
    RESET_SEQ = 3,
    UNRECOGNIZED = -1
}

/** Used to return the MarketDataFullSnapshot with Raw Price * */
export interface UpdateOnMarketDataFullSnapshotRawPriceRequest {
    clientRequestId: string;
}

/** * Used to subscribe to ticker events */
export interface UpdatedOnMarketDataTickerPricesRequest {
    clientRequestId: string;
}

function createBaseMarketDataSubscriptionRequest(): MarketDataSubscriptionRequest {
    return {
        clientRequestId: '',
        marketDataRequestType: 1,
        marketDataUpdateType: 1,
        marketDataBookType: 1,
        assetType: 1,
        exchangeCode: '',
        securityCode: '',
        securityId: '',
        settlementType: '',
        settlementDate: '',
        partyId: '',
        requestedByUser: '',
        productType: 1,
        marketDataRequestId: '',
        marketDataMidrateLevelType: 1,
        marketDataRequestLeg: [],
        currency: ''
    };
}

export const MarketDataSubscriptionRequest = {
    encode(message: MarketDataSubscriptionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.marketDataRequestType !== 1) {
            writer.uint32(16).int32(message.marketDataRequestType);
        }
        if (message.marketDataUpdateType !== 1) {
            writer.uint32(24).int32(message.marketDataUpdateType);
        }
        if (message.marketDataBookType !== 1) {
            writer.uint32(32).int32(message.marketDataBookType);
        }
        if (message.assetType !== 1) {
            writer.uint32(40).int32(message.assetType);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(50).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(58).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(66).string(message.securityId);
        }
        if (message.settlementType !== '') {
            writer.uint32(74).string(message.settlementType);
        }
        if (message.settlementDate !== '') {
            writer.uint32(82).string(message.settlementDate);
        }
        if (message.partyId !== '') {
            writer.uint32(90).string(message.partyId);
        }
        if (message.requestedByUser !== '') {
            writer.uint32(98).string(message.requestedByUser);
        }
        if (message.productType !== 1) {
            writer.uint32(104).int32(message.productType);
        }
        if (message.marketDataRequestId !== '') {
            writer.uint32(114).string(message.marketDataRequestId);
        }
        if (message.marketDataMidrateLevelType !== 1) {
            writer.uint32(120).int32(message.marketDataMidrateLevelType);
        }
        for (const v of message.marketDataRequestLeg) {
            MarketDataRequestLeg.encode(v!, writer.uint32(130).fork()).ldelim();
        }
        if (message.currency !== '') {
            writer.uint32(138).string(message.currency);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataSubscriptionRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataSubscriptionRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.marketDataRequestType = reader.int32() as any;
                    break;
                case 3:
                    message.marketDataUpdateType = reader.int32() as any;
                    break;
                case 4:
                    message.marketDataBookType = reader.int32() as any;
                    break;
                case 5:
                    message.assetType = reader.int32() as any;
                    break;
                case 6:
                    message.exchangeCode = reader.string();
                    break;
                case 7:
                    message.securityCode = reader.string();
                    break;
                case 8:
                    message.securityId = reader.string();
                    break;
                case 9:
                    message.settlementType = reader.string();
                    break;
                case 10:
                    message.settlementDate = reader.string();
                    break;
                case 11:
                    message.partyId = reader.string();
                    break;
                case 12:
                    message.requestedByUser = reader.string();
                    break;
                case 13:
                    message.productType = reader.int32() as any;
                    break;
                case 14:
                    message.marketDataRequestId = reader.string();
                    break;
                case 15:
                    message.marketDataMidrateLevelType = reader.int32() as any;
                    break;
                case 16:
                    message.marketDataRequestLeg.push(MarketDataRequestLeg.decode(reader, reader.uint32()));
                    break;
                case 17:
                    message.currency = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<MarketDataSubscriptionRequest>): MarketDataSubscriptionRequest {
        const message = createBaseMarketDataSubscriptionRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.marketDataRequestType = object.marketDataRequestType ?? 1;
        message.marketDataUpdateType = object.marketDataUpdateType ?? 1;
        message.marketDataBookType = object.marketDataBookType ?? 1;
        message.assetType = object.assetType ?? 1;
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.settlementType = object.settlementType ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.partyId = object.partyId ?? '';
        message.requestedByUser = object.requestedByUser ?? '';
        message.productType = object.productType ?? 1;
        message.marketDataRequestId = object.marketDataRequestId ?? '';
        message.marketDataMidrateLevelType = object.marketDataMidrateLevelType ?? 1;
        message.marketDataRequestLeg =
            object.marketDataRequestLeg?.map((e) => MarketDataRequestLeg.fromPartial(e)) || [];
        message.currency = object.currency ?? '';
        return message;
    }
};

function createBaseMarketDataRequestLeg(): MarketDataRequestLeg {
    return { securityCode: '', securityId: '', settlementType: '', settlementDate: '', legQty: 0 };
}

export const MarketDataRequestLeg = {
    encode(message: MarketDataRequestLeg, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.securityCode !== '') {
            writer.uint32(10).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(18).string(message.securityId);
        }
        if (message.settlementType !== '') {
            writer.uint32(26).string(message.settlementType);
        }
        if (message.settlementDate !== '') {
            writer.uint32(34).string(message.settlementDate);
        }
        if (message.legQty !== 0) {
            writer.uint32(41).double(message.legQty);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataRequestLeg {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataRequestLeg();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.securityCode = reader.string();
                    break;
                case 2:
                    message.securityId = reader.string();
                    break;
                case 3:
                    message.settlementType = reader.string();
                    break;
                case 4:
                    message.settlementDate = reader.string();
                    break;
                case 5:
                    message.legQty = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<MarketDataRequestLeg>): MarketDataRequestLeg {
        const message = createBaseMarketDataRequestLeg();
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.settlementType = object.settlementType ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.legQty = object.legQty ?? 0;
        return message;
    }
};

function createBaseMarketDataDestinationSubscriptionRequest(): MarketDataDestinationSubscriptionRequest {
    return {
        marketDataRequestId: '',
        marketDataRequestType: 1,
        marketDataUpdateType: 1,
        marketDataBookType: 1,
        destinationKey: '',
        assetType: 1,
        exchangeCode: '',
        securityCode: '',
        securityId: '',
        settlementType: '',
        settlementDate: '',
        partyId: '',
        sessionKey: '',
        sessionToken: '',
        requestedByUser: '',
        productType: 1,
        marketDataMidrateLevelType: 1,
        marketDataRequestLeg: [],
        currency: ''
    };
}

export const MarketDataDestinationSubscriptionRequest = {
    encode(message: MarketDataDestinationSubscriptionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.marketDataRequestId !== '') {
            writer.uint32(10).string(message.marketDataRequestId);
        }
        if (message.marketDataRequestType !== 1) {
            writer.uint32(16).int32(message.marketDataRequestType);
        }
        if (message.marketDataUpdateType !== 1) {
            writer.uint32(24).int32(message.marketDataUpdateType);
        }
        if (message.marketDataBookType !== 1) {
            writer.uint32(32).int32(message.marketDataBookType);
        }
        if (message.destinationKey !== '') {
            writer.uint32(42).string(message.destinationKey);
        }
        if (message.assetType !== 1) {
            writer.uint32(48).int32(message.assetType);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(58).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(66).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(74).string(message.securityId);
        }
        if (message.settlementType !== '') {
            writer.uint32(82).string(message.settlementType);
        }
        if (message.settlementDate !== '') {
            writer.uint32(90).string(message.settlementDate);
        }
        if (message.partyId !== '') {
            writer.uint32(98).string(message.partyId);
        }
        if (message.sessionKey !== '') {
            writer.uint32(106).string(message.sessionKey);
        }
        if (message.sessionToken !== '') {
            writer.uint32(114).string(message.sessionToken);
        }
        if (message.requestedByUser !== '') {
            writer.uint32(122).string(message.requestedByUser);
        }
        if (message.productType !== 1) {
            writer.uint32(128).int32(message.productType);
        }
        if (message.marketDataMidrateLevelType !== 1) {
            writer.uint32(136).int32(message.marketDataMidrateLevelType);
        }
        for (const v of message.marketDataRequestLeg) {
            MarketDataRequestLeg.encode(v!, writer.uint32(146).fork()).ldelim();
        }
        if (message.currency !== '') {
            writer.uint32(154).string(message.currency);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataDestinationSubscriptionRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataDestinationSubscriptionRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketDataRequestId = reader.string();
                    break;
                case 2:
                    message.marketDataRequestType = reader.int32() as any;
                    break;
                case 3:
                    message.marketDataUpdateType = reader.int32() as any;
                    break;
                case 4:
                    message.marketDataBookType = reader.int32() as any;
                    break;
                case 5:
                    message.destinationKey = reader.string();
                    break;
                case 6:
                    message.assetType = reader.int32() as any;
                    break;
                case 7:
                    message.exchangeCode = reader.string();
                    break;
                case 8:
                    message.securityCode = reader.string();
                    break;
                case 9:
                    message.securityId = reader.string();
                    break;
                case 10:
                    message.settlementType = reader.string();
                    break;
                case 11:
                    message.settlementDate = reader.string();
                    break;
                case 12:
                    message.partyId = reader.string();
                    break;
                case 13:
                    message.sessionKey = reader.string();
                    break;
                case 14:
                    message.sessionToken = reader.string();
                    break;
                case 15:
                    message.requestedByUser = reader.string();
                    break;
                case 16:
                    message.productType = reader.int32() as any;
                    break;
                case 17:
                    message.marketDataMidrateLevelType = reader.int32() as any;
                    break;
                case 18:
                    message.marketDataRequestLeg.push(MarketDataRequestLeg.decode(reader, reader.uint32()));
                    break;
                case 19:
                    message.currency = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<MarketDataDestinationSubscriptionRequest>
    ): MarketDataDestinationSubscriptionRequest {
        const message = createBaseMarketDataDestinationSubscriptionRequest();
        message.marketDataRequestId = object.marketDataRequestId ?? '';
        message.marketDataRequestType = object.marketDataRequestType ?? 1;
        message.marketDataUpdateType = object.marketDataUpdateType ?? 1;
        message.marketDataBookType = object.marketDataBookType ?? 1;
        message.destinationKey = object.destinationKey ?? '';
        message.assetType = object.assetType ?? 1;
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.settlementType = object.settlementType ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.partyId = object.partyId ?? '';
        message.sessionKey = object.sessionKey ?? '';
        message.sessionToken = object.sessionToken ?? '';
        message.requestedByUser = object.requestedByUser ?? '';
        message.productType = object.productType ?? 1;
        message.marketDataMidrateLevelType = object.marketDataMidrateLevelType ?? 1;
        message.marketDataRequestLeg =
            object.marketDataRequestLeg?.map((e) => MarketDataRequestLeg.fromPartial(e)) || [];
        message.currency = object.currency ?? '';
        return message;
    }
};

function createBaseMarketDataSystemSubscriptionRequest(): MarketDataSystemSubscriptionRequest {
    return {
        clientRequestId: '',
        marketDataRequestType: 1,
        marketDataUpdateType: 1,
        marketDataBookType: 1,
        assetType: 1,
        exchangeCode: '',
        securityCode: '',
        securityId: '',
        settlementType: '',
        settlementDate: '',
        partyId: '',
        productType: 1,
        marketDataRequestId: '',
        trader: ''
    };
}

export const MarketDataSystemSubscriptionRequest = {
    encode(message: MarketDataSystemSubscriptionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.marketDataRequestType !== 1) {
            writer.uint32(16).int32(message.marketDataRequestType);
        }
        if (message.marketDataUpdateType !== 1) {
            writer.uint32(24).int32(message.marketDataUpdateType);
        }
        if (message.marketDataBookType !== 1) {
            writer.uint32(32).int32(message.marketDataBookType);
        }
        if (message.assetType !== 1) {
            writer.uint32(40).int32(message.assetType);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(50).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(58).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(66).string(message.securityId);
        }
        if (message.settlementType !== '') {
            writer.uint32(74).string(message.settlementType);
        }
        if (message.settlementDate !== '') {
            writer.uint32(82).string(message.settlementDate);
        }
        if (message.partyId !== '') {
            writer.uint32(90).string(message.partyId);
        }
        if (message.productType !== 1) {
            writer.uint32(96).int32(message.productType);
        }
        if (message.marketDataRequestId !== '') {
            writer.uint32(106).string(message.marketDataRequestId);
        }
        if (message.trader !== '') {
            writer.uint32(114).string(message.trader);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataSystemSubscriptionRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataSystemSubscriptionRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.marketDataRequestType = reader.int32() as any;
                    break;
                case 3:
                    message.marketDataUpdateType = reader.int32() as any;
                    break;
                case 4:
                    message.marketDataBookType = reader.int32() as any;
                    break;
                case 5:
                    message.assetType = reader.int32() as any;
                    break;
                case 6:
                    message.exchangeCode = reader.string();
                    break;
                case 7:
                    message.securityCode = reader.string();
                    break;
                case 8:
                    message.securityId = reader.string();
                    break;
                case 9:
                    message.settlementType = reader.string();
                    break;
                case 10:
                    message.settlementDate = reader.string();
                    break;
                case 11:
                    message.partyId = reader.string();
                    break;
                case 12:
                    message.productType = reader.int32() as any;
                    break;
                case 13:
                    message.marketDataRequestId = reader.string();
                    break;
                case 14:
                    message.trader = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<MarketDataSystemSubscriptionRequest>): MarketDataSystemSubscriptionRequest {
        const message = createBaseMarketDataSystemSubscriptionRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.marketDataRequestType = object.marketDataRequestType ?? 1;
        message.marketDataUpdateType = object.marketDataUpdateType ?? 1;
        message.marketDataBookType = object.marketDataBookType ?? 1;
        message.assetType = object.assetType ?? 1;
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.settlementType = object.settlementType ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.partyId = object.partyId ?? '';
        message.productType = object.productType ?? 1;
        message.marketDataRequestId = object.marketDataRequestId ?? '';
        message.trader = object.trader ?? '';
        return message;
    }
};

function createBaseUpdateOnAllSecurityStatusRequest(): UpdateOnAllSecurityStatusRequest {
    return { clientRequestId: '', exchangeCode: '', securityCode: '', type: 1 };
}

export const UpdateOnAllSecurityStatusRequest = {
    encode(message: UpdateOnAllSecurityStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(18).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(26).string(message.securityCode);
        }
        if (message.type !== 1) {
            writer.uint32(32).int32(message.type);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOnAllSecurityStatusRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateOnAllSecurityStatusRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.exchangeCode = reader.string();
                    break;
                case 3:
                    message.securityCode = reader.string();
                    break;
                case 4:
                    message.type = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UpdateOnAllSecurityStatusRequest>): UpdateOnAllSecurityStatusRequest {
        const message = createBaseUpdateOnAllSecurityStatusRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.type = object.type ?? 1;
        return message;
    }
};

function createBaseMarketDataDestinationCommand(): MarketDataDestinationCommand {
    return { clientRequestId: '', operation: 0, marketDataDestinationKey: '', incomingSeqNo: 0, outgoingSeqNo: 0 };
}

export const MarketDataDestinationCommand = {
    encode(message: MarketDataDestinationCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.operation !== 0) {
            writer.uint32(16).int32(message.operation);
        }
        if (message.marketDataDestinationKey !== '') {
            writer.uint32(26).string(message.marketDataDestinationKey);
        }
        if (message.incomingSeqNo !== 0) {
            writer.uint32(32).int32(message.incomingSeqNo);
        }
        if (message.outgoingSeqNo !== 0) {
            writer.uint32(40).int32(message.outgoingSeqNo);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataDestinationCommand {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketDataDestinationCommand();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.operation = reader.int32() as any;
                    break;
                case 3:
                    message.marketDataDestinationKey = reader.string();
                    break;
                case 4:
                    message.incomingSeqNo = reader.int32();
                    break;
                case 5:
                    message.outgoingSeqNo = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<MarketDataDestinationCommand>): MarketDataDestinationCommand {
        const message = createBaseMarketDataDestinationCommand();
        message.clientRequestId = object.clientRequestId ?? '';
        message.operation = object.operation ?? 0;
        message.marketDataDestinationKey = object.marketDataDestinationKey ?? '';
        message.incomingSeqNo = object.incomingSeqNo ?? 0;
        message.outgoingSeqNo = object.outgoingSeqNo ?? 0;
        return message;
    }
};

function createBaseUpdateOnMarketDataFullSnapshotRawPriceRequest(): UpdateOnMarketDataFullSnapshotRawPriceRequest {
    return { clientRequestId: '' };
}

export const UpdateOnMarketDataFullSnapshotRawPriceRequest = {
    encode(
        message: UpdateOnMarketDataFullSnapshotRawPriceRequest,
        writer: _m0.Writer = _m0.Writer.create()
    ): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOnMarketDataFullSnapshotRawPriceRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateOnMarketDataFullSnapshotRawPriceRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<UpdateOnMarketDataFullSnapshotRawPriceRequest>
    ): UpdateOnMarketDataFullSnapshotRawPriceRequest {
        const message = createBaseUpdateOnMarketDataFullSnapshotRawPriceRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseUpdatedOnMarketDataTickerPricesRequest(): UpdatedOnMarketDataTickerPricesRequest {
    return { clientRequestId: '' };
}

export const UpdatedOnMarketDataTickerPricesRequest = {
    encode(message: UpdatedOnMarketDataTickerPricesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdatedOnMarketDataTickerPricesRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdatedOnMarketDataTickerPricesRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UpdatedOnMarketDataTickerPricesRequest>): UpdatedOnMarketDataTickerPricesRequest {
        const message = createBaseUpdatedOnMarketDataTickerPricesRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;
