import accountUsageReducer from './accountUsageSlice';
import authReducer from './authSlice';
import balanceReducer from './balanceSlice';
import blotterReducer from './blotterSlice';
import celerMarketReducer from './celerMarketSlice';
import chartDataReducer from './chartDataSlice';
import liteMarketReducer from './liteMarketSlice';
import marketPairReducer from './marketPairSlice';
import notificationsReducer from './notificationSlice';
import openPositionReducer from './openPositionSlice';
import settingReducer from './settingSlice';
import tickerReducer from './tickerSlice';
import traderMarketReducer from './traderMarketSlice';
import workspaceReducer from './workspaceSlice';
import xplorSpotLayoutReducer from './xplorSpotLayoutSlice';
import xplorTraderLayoutReducer from './xplorTraderLayoutSlice';

import { combineReducers } from 'redux';

export default combineReducers({
    auth: authReducer,
    marketPair: marketPairReducer,
    liteMarket: liteMarketReducer,
    celerMarket: celerMarketReducer,
    traderMarket: traderMarketReducer,
    ticker: tickerReducer,
    chartData: chartDataReducer,
    balance: balanceReducer,
    accountUsage: accountUsageReducer,
    openPosition: openPositionReducer,
    blotter: blotterReducer,
    settings: settingReducer,
    workspace: workspaceReducer,
    notifications: notificationsReducer,
    xplorSpotLayout: xplorSpotLayoutReducer,
    xplorTraderLayout: xplorTraderLayoutReducer
});
