import { OrderStatus } from '@/compiled_proto/com/celertech/orderrouting/api/enums/OrderStatusProto';
import { TimeInForceType } from '@/compiled_proto/com/celertech/orderrouting/api/enums/TimeInForceTypeProto';
import { OrderType } from '@/compiled_proto/com/celertech/positionmanager/api/enums/OrderTypeProto';
import ContextMenu, { ContextMenuItem } from '@/components/common/ContextMenu';
import AmendOrderModal from '@/components/modal/AmendOrderModal';
import DealHistoryModal from '@/components/modal/DealHistoryModal';
import OrderCancellationModal from '@/components/modal/OrderCancellationModal';
import Table from '@/components/table/Table';
import { defaultColumn, numberFormatterConfig } from '@/config/config';
import { orderColumns } from '@/helpers/table/orderBlotterTableHelper';
import { BlotterItem } from '@/model/blotters';
import { selectCurrentAccount } from '@/state/reducers/authSlice';
import { selectBlotterItems } from '@/state/reducers/blotterSlice';
import { selectGeneralSettings } from '@/state/reducers/settingSlice';
import { formatDateTime, formatLocalDateTime } from '@/utils/format';
import useContextMenu from '@/utils/hooks/useContextMenu';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import { getInstrumentFormatter } from '@/utils/hooks/useInstrument';
import useScreenSizes from '@/utils/hooks/useScreenSizes';
import { useTrueDateFormatter } from '@/utils/hooks/useTrueDateFormatter';
import { translatePair } from '@/utils/symbolMapping';
import {
    Row,
    SortingState,
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useLocale, useNumberFormatter } from 'react-aria';
import { MdCancel, MdEdit, MdHistory } from 'react-icons/md';
import { useSelector } from 'react-redux';

const onAction = (e: Row<any>, callback: (amendable: boolean) => void) => {
    const { original } = e;
    const tif = TimeInForceType[original?.tif || ''] as unknown as TimeInForceType;
    const status = OrderStatus[original?.status || ''] as unknown as OrderStatus;
    const orderType = OrderType[original?.orderType || ''] as unknown as OrderType;
    // if duration is DAY/GTC && if order status is NEW/PART_FILLED

    const amendable =
        [TimeInForceType.DAY, TimeInForceType.GTC].includes(tif) &&
        [OrderStatus.NEW, OrderStatus.PART_FILLED].includes(status) &&
        [OrderType.LIMIT, OrderType.STOP_MARKET, OrderType.STOP_LIMIT].includes(orderType);

    callback(amendable);
};

const OrderBlotterTable = (props: any) => {
    const { setCSVData } = props;

    const { locale } = useLocale();
    const orders = useSelector(selectBlotterItems);
    const currentAccount = useSelector(selectCurrentAccount);
    const generalSettings = useSelector(selectGeneralSettings);
    const screen = useScreenSizes();

    const amendDisclosure = useDisclosure(false);
    const historyDisclosure = useDisclosure(false);
    const cancelDisclosure = useDisclosure(false);

    const { contextMenuX, contextMenuY, contextMenuVisible, handleOpenContextMenu, handleCloseContextMenu } =
        useContextMenu();
    const formatDate = useTrueDateFormatter();
    const formatNumber = useNumberFormatter(numberFormatterConfig);

    const [orderId, setOrderId] = useState<string | undefined>();
    const [isAmendable, setIsAmendable] = useState<boolean>(false);

    const [sorting, setSorting] = useState<SortingState>([{ id: 'updated', desc: true }]);

    const order = useMemo(() => orders.find((order) => order.orderId === orderId), [orderId, amendDisclosure[0]]);
    const isUTC = useMemo(() => generalSettings.timezone.value === 'UTC', [generalSettings]);
    const formattedOrders: any = useMemo(() => {
        return orders
            .filter((order) => order.account === currentAccount || order.account === currentAccount?.slice(0, 36))
            .map((order) => mapOrderBlotters(order, locale, formatDate, formatNumber, isUTC));
    }, [orders, locale, currentAccount, isUTC]);

    const columns = useMemo(() => orderColumns(generalSettings), [generalSettings]);

    const tableInstance = useReactTable({
        data: formattedOrders,
        columns,
        state: { sorting },
        defaultColumn,
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        globalFilterFn: 'includesString',
        onSortingChange: (sorting) => setSorting(sorting),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getSubRows: (row: any) => row.subRows,
        enableRowSelection: true
    });

    useEffect(() => {
        setCSVData(formattedOrders);
    }, [formattedOrders]);

    return (
        <>
            <Table
                tableInstance={tableInstance}
                onCellContextMenu={(e) =>
                    onAction(e, (amendable) => {
                        setOrderId(e.original.orderId);
                        setIsAmendable(amendable);
                        handleOpenContextMenu(e.event as any);
                    })
                }
                onRowDoubleClicked={(e) =>
                    onAction(e, (amendable) => {
                        setOrderId(e.original.orderId);
                        setIsAmendable(amendable);
                        amendDisclosure[1].toggle();
                    })
                }
                onRowClicked={(e) => {
                    if (screen.mobile || screen.tablet) {
                        onAction(e, (amendable) => {
                            setOrderId(e.original.orderId);
                            setIsAmendable(amendable);
                            handleOpenContextMenu(e.event as any);
                        });
                    }
                }}
                isLoadingTableContent={false}
            />
            <ContextMenu
                x={contextMenuX}
                y={contextMenuY}
                visible={contextMenuVisible}
                onClose={handleCloseContextMenu}>
                <ContextMenuItem onClick={historyDisclosure[1].open}>
                    <MdHistory />
                    <span>View Order History</span>
                </ContextMenuItem>
                <ContextMenuItem onClick={amendDisclosure[1].open} disabled={!isAmendable}>
                    <MdEdit />
                    <span>Amend Order</span>
                </ContextMenuItem>
                <ContextMenuItem onClick={cancelDisclosure[1].open} disabled={!isAmendable}>
                    <MdCancel />
                    <span>Cancel Order</span>
                </ContextMenuItem>
            </ContextMenu>
            {order && (
                <>
                    <AmendOrderModal order={order} opened={amendDisclosure[0]} handlers={amendDisclosure[1]} />
                    <DealHistoryModal order={order} opened={historyDisclosure[0]} handlers={historyDisclosure[1]} />
                    <OrderCancellationModal order={order} opened={cancelDisclosure[0]} handlers={cancelDisclosure[1]} />
                </>
            )}
        </>
    );
};

export default OrderBlotterTable;

export const mapOrderBlotters = (
    order: BlotterItem,
    locale: string,
    formatDate: any,
    formatNumber: any,
    isUTC: boolean
) => {
    const { formatPrice, formatAvgPrice } = getInstrumentFormatter(locale, order.pair);
    return {
        ...order,
        created:
            order.created &&
            (isUTC ? formatDateTime(formatDate, order.created) : formatLocalDateTime(formatDate, order.created)),
        updated:
            order.updated &&
            (isUTC ? formatDateTime(formatDate, order.updated) : formatLocalDateTime(formatDate, order.updated)),
        instrument: translatePair(order.pair),
        qty: formatNumber.format(order.qty),
        price: formatPrice(order.price),
        slippage: formatNumber.format(+(order.slippage || 0)),
        lastPrice: formatPrice(order.lastPrice),
        avgPrice: formatAvgPrice(order.avgPrice),
        stopPrice: formatPrice(order.stopPrice)
    };
};
