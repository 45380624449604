import { useAppSelector } from '@/state/hooks';
import { selectCredentials } from '@/state/reducers/authSlice';
import useXplorPortal from '@/utils/hooks/useXplorPortal';
import Divider from '../../common/Divider';
import Modal, { ModalHeader, ModalProps, ModalTitle } from '../Modal';

const WeAreMoving = (props: ModalProps) => {
    const { handlers } = props;
    const credentials = useAppSelector(selectCredentials);

    const { onXplorPortal } = useXplorPortal(credentials);

    return (
        <Modal {...props} className="h-auto" size="max-w-2xl">
            <ModalHeader>
                <ModalTitle>Enhanced Withdrawal Experience</ModalTitle>
            </ModalHeader>
            <Divider />
            <div className="text-sm sm:text-base text-neutral-200 p-2 py-3 sm:p-4">
                <div className="flex flex-col gap-2 text-justify">
                    <span>
                        We are excited to announce an enhancement to our withdrawal process aimed at improving your
                        experience and efficiency.
                    </span>
                    <span>
                        Going forward, all withdrawal requests will be submitted through our new enhanced withdrawal
                        module within <b className="text-brand-primary-light">XplorPortal</b>.
                    </span>
                    <span>
                        To access the new withdrawal process, simply log into your{' '}
                        <b className="text-brand-primary-light">XplorPortal</b> account, select{' '}
                        <b className="text-brand-primary-light">Deposits & Withdrawals {'>'} Withdrawal Requests</b>.
                    </span>
                    <span>
                        If you have any questions or need assistance, please do not hesitate to contact our Client
                        Services team at{' '}
                        <a href="mailto:clientservices@gc.exchange" className="text-brand-primary-light underline">
                            clientservices@gc.exchange
                        </a>
                    </span>
                    <span className="text-neutral-400 pt-3">
                        Do you wish to proceed withdrawing funds in XplorPortal?
                    </span>
                </div>
            </div>
            <Divider />
            <div className="text-neutral-200 p-2 sm:p-4 text-sm flex space-x-2">
                <button
                    className="rounded-md p-2 px-4 bg-neutral-600 hover:bg-neutral-500"
                    onClick={() => {
                        handlers.close();
                    }}>
                    Cancel
                </button>
                <button
                    className="rounded-md p-2 px-4 bg-brand-primary hover:bg-brand-primary-light"
                    onClick={() => onXplorPortal()}>
                    Bring me to XplorPortal
                    {/* Go to XplorPortal */}
                </button>
            </div>
        </Modal>
    );
};

export default WeAreMoving;
