import cn from 'classnames';
import React, { Fragment } from 'react';
interface EntryProps {
    label: string;
    value: number | string | React.ReactNode;
    className?: string;
    labelClassName?: string;
    disabled?: boolean;
    required?: boolean;
    hidden?: boolean;
}

const Entry = (props: EntryProps) => {
    const {
        label,
        value,
        className = '',
        labelClassName = '',
        disabled = false,
        required = false,
        hidden = false
    } = props;

    return (
        <Fragment>
            {!hidden && (
                <div className={cn('flex flex-col sm:flex-row gap-2 md:gap-4 justify-between text-neutral-200 w-full')}>
                    <div className={cn('flex flex-row items-center w-full sm:w-auto flex-none')}>
                        <div className={cn('flex flex-col sm:min-w-60', labelClassName)}>
                            <label className="flex justify-between relative text-sm w-full">
                                <div
                                    className={cn('', {
                                        'pl-3': required
                                    })}>
                                    {required && (
                                        <sup className="absolute -top-1 left-0 text-lg mr-1.5 text-secondaryLight">
                                            *
                                        </sup>
                                    )}
                                    {label}
                                </div>
                            </label>
                        </div>
                        <div>
                            <span className="hidden sm:block space-x-1.5">
                                <span>:</span>
                            </span>
                        </div>
                    </div>

                    <span
                        className={cn(
                            'block w-full break-all px-3 py-2 bg-backgroundDark border border-neutral-700 rounded-md shadow-sm text-sm',
                            {
                                'text-neutral-400 cursor-not-allowed': disabled,
                                '': !disabled
                            },
                            className
                        )}>
                        {value || '-'}
                    </span>
                </div>
            )}
        </Fragment>
    );
};

export default Entry;
