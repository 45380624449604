import { isTablet } from 'react-device-detect';
import { MosaicWindow } from 'react-mosaic-component';

const ModuleWindow = ({ path, screen, dragStart, dragEnd, tradingModule, renderToolbar, children }: any) => {
    return (
        <MosaicWindow<string>
            path={path}
            title={tradingModule?.title}
            draggable={(screen.laptop || screen.desktop) && !isTablet}
            onDragStart={dragStart}
            onDragEnd={dragEnd}
            renderToolbar={renderToolbar}>
            {children}
        </MosaicWindow>
    );
};

export default ModuleWindow;
