/* eslint-disable */
import { CreditSettlementType } from '../enums/CreditSettlementTypeProto';
import Long from 'long';
import { ProductType } from '../enums/ProductTypeProto';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.staticdata.api.account';

/** Creates an account in the Staticdata module */
export interface CreateAccount {
    clientRequestId: string;
    /** Unique code that represents the account */
    code: string;
    /** Name of the account, can be non-unique */
    name: string;
    /** Description of the account, can be non-unique */
    description: string;
    /** CtiCode of the account, can be non-unique */
    ctiCode: string;
    /** FeeCode of the account, can be non-unique */
    feeCode: string;
    /** Party message that is used to encapsulate multiple party information for this account */
    party: Party[];
    /** Enables this account to auto clear any trades made against this account */
    autoClearEnabled: boolean;
    /** Enables this account */
    enabled: boolean;
    /** Enables this account to be a CSA account */
    creditCsa: boolean;
    /** Enables this account to be a credit checked */
    creditCheckEnabled: boolean;
    /** Deprecated */
    partyId: string;
    productType: ProductType[];
    creditSettlementType: CreditSettlementType;
}

/** Deletes the account either by id or code.  If both specified, then Id will take precendence */
export interface DeleteAccount {
    clientRequestId: string;
    /** Id of the account */
    id: string;
    /** Code of the account */
    code: string;
}

/** Find a single account by its Id */
export interface FindAccountById {
    clientRequestId: string;
    /** Id of the account */
    id: string;
}

/** Find a single account by its code */
export interface FindAccountByCode {
    clientRequestId: string;
    /** Code of the account */
    code: string;
}

/** Finds all accounts */
export interface FindAllAccounts {
    clientRequestId: string;
}

/** Bulk update message that takes a multiple number of UpdateAccount messages */
export interface BulkUpdateAccountRequest {
    clientRequestId: string;
    updateAccountRequest: UpdateAccount[];
}

/** Updates an account in the Staticdata module */
export interface UpdateAccount {
    clientRequestId: string;
    /** Id of the account to be updated */
    id: string;
    /** Unique code that represents the account */
    code: string;
    /** Name of the account, can be non-unique */
    name: string;
    /** Description of the account, can be non-unique */
    description: string;
    /** CtiCode of the account, can be non-unique */
    ctiCode: string;
    /** FeeCode of the account, can be non-unique */
    feeCode: string;
    /** Party message that is used to encapsulate multiple party information for this account */
    party: Party[];
    /** Enables this account to auto clear any trades made against this account */
    autoClearEnabled: boolean;
    /** Enables this account */
    enabled: boolean;
    /** Enables this account to be a CSA account */
    creditCsa: boolean;
    /** Enables this account to be a CLS settled account */
    creditCheckEnabled: boolean;
    /** deprecated */
    partyId: string;
    productType: ProductType[];
    creditSettlementType: CreditSettlementType;
}

/** Party message that is used to encapsulate multiple party information for a given account */
export interface Party {
    /** Free text */
    partyIdSource: string;
    partyId: string;
}

/** Message to subscribe to any create, update or delete account actions */
export interface UpdateOnStaticDataAccountRequest {}

export interface BulkUpateAccountPropertyValue {
    clientRequestId: string;
    /** Account property key */
    propertyKey: string;
    /** Repeated account update account property */
    updateAccountProperty: UpdateAccountPropertyValue[];
}

export interface UpdateAccountPropertyValue {
    clientRequestId: string;
    /** Account code */
    accountCode: string;
    /** Account property key */
    propertyKey: string;
    /** Account property value */
    propertyVaue: string;
}

function createBaseCreateAccount(): CreateAccount {
    return {
        clientRequestId: '',
        code: '',
        name: '',
        description: '',
        ctiCode: '',
        feeCode: '',
        party: [],
        autoClearEnabled: false,
        enabled: false,
        creditCsa: false,
        creditCheckEnabled: false,
        partyId: '',
        productType: [],
        creditSettlementType: 1
    };
}

export const CreateAccount = {
    encode(message: CreateAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.code !== '') {
            writer.uint32(18).string(message.code);
        }
        if (message.name !== '') {
            writer.uint32(26).string(message.name);
        }
        if (message.description !== '') {
            writer.uint32(34).string(message.description);
        }
        if (message.ctiCode !== '') {
            writer.uint32(42).string(message.ctiCode);
        }
        if (message.feeCode !== '') {
            writer.uint32(50).string(message.feeCode);
        }
        for (const v of message.party) {
            Party.encode(v!, writer.uint32(58).fork()).ldelim();
        }
        if (message.autoClearEnabled === true) {
            writer.uint32(64).bool(message.autoClearEnabled);
        }
        if (message.enabled === true) {
            writer.uint32(72).bool(message.enabled);
        }
        if (message.creditCsa === true) {
            writer.uint32(80).bool(message.creditCsa);
        }
        if (message.creditCheckEnabled === true) {
            writer.uint32(96).bool(message.creditCheckEnabled);
        }
        if (message.partyId !== '') {
            writer.uint32(106).string(message.partyId);
        }
        writer.uint32(114).fork();
        for (const v of message.productType) {
            writer.int32(v);
        }
        writer.ldelim();
        if (message.creditSettlementType !== 1) {
            writer.uint32(120).int32(message.creditSettlementType);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateAccount {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateAccount();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.code = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.description = reader.string();
                    break;
                case 5:
                    message.ctiCode = reader.string();
                    break;
                case 6:
                    message.feeCode = reader.string();
                    break;
                case 7:
                    message.party.push(Party.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.autoClearEnabled = reader.bool();
                    break;
                case 9:
                    message.enabled = reader.bool();
                    break;
                case 10:
                    message.creditCsa = reader.bool();
                    break;
                case 12:
                    message.creditCheckEnabled = reader.bool();
                    break;
                case 13:
                    message.partyId = reader.string();
                    break;
                case 14:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.productType.push(reader.int32() as any);
                        }
                    } else {
                        message.productType.push(reader.int32() as any);
                    }
                    break;
                case 15:
                    message.creditSettlementType = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateAccount>): CreateAccount {
        const message = createBaseCreateAccount();
        message.clientRequestId = object.clientRequestId ?? '';
        message.code = object.code ?? '';
        message.name = object.name ?? '';
        message.description = object.description ?? '';
        message.ctiCode = object.ctiCode ?? '';
        message.feeCode = object.feeCode ?? '';
        message.party = object.party?.map((e) => Party.fromPartial(e)) || [];
        message.autoClearEnabled = object.autoClearEnabled ?? false;
        message.enabled = object.enabled ?? false;
        message.creditCsa = object.creditCsa ?? false;
        message.creditCheckEnabled = object.creditCheckEnabled ?? false;
        message.partyId = object.partyId ?? '';
        message.productType = object.productType?.map((e) => e) || [];
        message.creditSettlementType = object.creditSettlementType ?? 1;
        return message;
    }
};

function createBaseDeleteAccount(): DeleteAccount {
    return { clientRequestId: '', id: '0', code: '' };
}

export const DeleteAccount = {
    encode(message: DeleteAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.id !== '0') {
            writer.uint32(16).int64(message.id);
        }
        if (message.code !== '') {
            writer.uint32(26).string(message.code);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): DeleteAccount {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeleteAccount();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.code = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<DeleteAccount>): DeleteAccount {
        const message = createBaseDeleteAccount();
        message.clientRequestId = object.clientRequestId ?? '';
        message.id = object.id ?? '0';
        message.code = object.code ?? '';
        return message;
    }
};

function createBaseFindAccountById(): FindAccountById {
    return { clientRequestId: '', id: '0' };
}

export const FindAccountById = {
    encode(message: FindAccountById, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.id !== '0') {
            writer.uint32(16).int64(message.id);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAccountById {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAccountById();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.id = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAccountById>): FindAccountById {
        const message = createBaseFindAccountById();
        message.clientRequestId = object.clientRequestId ?? '';
        message.id = object.id ?? '0';
        return message;
    }
};

function createBaseFindAccountByCode(): FindAccountByCode {
    return { clientRequestId: '', code: '' };
}

export const FindAccountByCode = {
    encode(message: FindAccountByCode, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.code !== '') {
            writer.uint32(18).string(message.code);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAccountByCode {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAccountByCode();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.code = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAccountByCode>): FindAccountByCode {
        const message = createBaseFindAccountByCode();
        message.clientRequestId = object.clientRequestId ?? '';
        message.code = object.code ?? '';
        return message;
    }
};

function createBaseFindAllAccounts(): FindAllAccounts {
    return { clientRequestId: '' };
}

export const FindAllAccounts = {
    encode(message: FindAllAccounts, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAllAccounts {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAllAccounts();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAllAccounts>): FindAllAccounts {
        const message = createBaseFindAllAccounts();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseBulkUpdateAccountRequest(): BulkUpdateAccountRequest {
    return { clientRequestId: '', updateAccountRequest: [] };
}

export const BulkUpdateAccountRequest = {
    encode(message: BulkUpdateAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        for (const v of message.updateAccountRequest) {
            UpdateAccount.encode(v!, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): BulkUpdateAccountRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBulkUpdateAccountRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.updateAccountRequest.push(UpdateAccount.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<BulkUpdateAccountRequest>): BulkUpdateAccountRequest {
        const message = createBaseBulkUpdateAccountRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.updateAccountRequest = object.updateAccountRequest?.map((e) => UpdateAccount.fromPartial(e)) || [];
        return message;
    }
};

function createBaseUpdateAccount(): UpdateAccount {
    return {
        clientRequestId: '',
        id: '0',
        code: '',
        name: '',
        description: '',
        ctiCode: '',
        feeCode: '',
        party: [],
        autoClearEnabled: false,
        enabled: false,
        creditCsa: false,
        creditCheckEnabled: false,
        partyId: '',
        productType: [],
        creditSettlementType: 1
    };
}

export const UpdateAccount = {
    encode(message: UpdateAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.id !== '0') {
            writer.uint32(16).int64(message.id);
        }
        if (message.code !== '') {
            writer.uint32(26).string(message.code);
        }
        if (message.name !== '') {
            writer.uint32(34).string(message.name);
        }
        if (message.description !== '') {
            writer.uint32(42).string(message.description);
        }
        if (message.ctiCode !== '') {
            writer.uint32(50).string(message.ctiCode);
        }
        if (message.feeCode !== '') {
            writer.uint32(58).string(message.feeCode);
        }
        for (const v of message.party) {
            Party.encode(v!, writer.uint32(66).fork()).ldelim();
        }
        if (message.autoClearEnabled === true) {
            writer.uint32(72).bool(message.autoClearEnabled);
        }
        if (message.enabled === true) {
            writer.uint32(80).bool(message.enabled);
        }
        if (message.creditCsa === true) {
            writer.uint32(88).bool(message.creditCsa);
        }
        if (message.creditCheckEnabled === true) {
            writer.uint32(104).bool(message.creditCheckEnabled);
        }
        if (message.partyId !== '') {
            writer.uint32(114).string(message.partyId);
        }
        writer.uint32(122).fork();
        for (const v of message.productType) {
            writer.int32(v);
        }
        writer.ldelim();
        if (message.creditSettlementType !== 1) {
            writer.uint32(128).int32(message.creditSettlementType);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccount {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateAccount();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.code = reader.string();
                    break;
                case 4:
                    message.name = reader.string();
                    break;
                case 5:
                    message.description = reader.string();
                    break;
                case 6:
                    message.ctiCode = reader.string();
                    break;
                case 7:
                    message.feeCode = reader.string();
                    break;
                case 8:
                    message.party.push(Party.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.autoClearEnabled = reader.bool();
                    break;
                case 10:
                    message.enabled = reader.bool();
                    break;
                case 11:
                    message.creditCsa = reader.bool();
                    break;
                case 13:
                    message.creditCheckEnabled = reader.bool();
                    break;
                case 14:
                    message.partyId = reader.string();
                    break;
                case 15:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.productType.push(reader.int32() as any);
                        }
                    } else {
                        message.productType.push(reader.int32() as any);
                    }
                    break;
                case 16:
                    message.creditSettlementType = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UpdateAccount>): UpdateAccount {
        const message = createBaseUpdateAccount();
        message.clientRequestId = object.clientRequestId ?? '';
        message.id = object.id ?? '0';
        message.code = object.code ?? '';
        message.name = object.name ?? '';
        message.description = object.description ?? '';
        message.ctiCode = object.ctiCode ?? '';
        message.feeCode = object.feeCode ?? '';
        message.party = object.party?.map((e) => Party.fromPartial(e)) || [];
        message.autoClearEnabled = object.autoClearEnabled ?? false;
        message.enabled = object.enabled ?? false;
        message.creditCsa = object.creditCsa ?? false;
        message.creditCheckEnabled = object.creditCheckEnabled ?? false;
        message.partyId = object.partyId ?? '';
        message.productType = object.productType?.map((e) => e) || [];
        message.creditSettlementType = object.creditSettlementType ?? 1;
        return message;
    }
};

function createBaseParty(): Party {
    return { partyIdSource: '', partyId: '' };
}

export const Party = {
    encode(message: Party, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.partyIdSource !== '') {
            writer.uint32(10).string(message.partyIdSource);
        }
        if (message.partyId !== '') {
            writer.uint32(18).string(message.partyId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): Party {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseParty();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.partyIdSource = reader.string();
                    break;
                case 2:
                    message.partyId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<Party>): Party {
        const message = createBaseParty();
        message.partyIdSource = object.partyIdSource ?? '';
        message.partyId = object.partyId ?? '';
        return message;
    }
};

function createBaseUpdateOnStaticDataAccountRequest(): UpdateOnStaticDataAccountRequest {
    return {};
}

export const UpdateOnStaticDataAccountRequest = {
    encode(_: UpdateOnStaticDataAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOnStaticDataAccountRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateOnStaticDataAccountRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(_: DeepPartial<UpdateOnStaticDataAccountRequest>): UpdateOnStaticDataAccountRequest {
        const message = createBaseUpdateOnStaticDataAccountRequest();
        return message;
    }
};

function createBaseBulkUpateAccountPropertyValue(): BulkUpateAccountPropertyValue {
    return { clientRequestId: '', propertyKey: '', updateAccountProperty: [] };
}

export const BulkUpateAccountPropertyValue = {
    encode(message: BulkUpateAccountPropertyValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.propertyKey !== '') {
            writer.uint32(18).string(message.propertyKey);
        }
        for (const v of message.updateAccountProperty) {
            UpdateAccountPropertyValue.encode(v!, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): BulkUpateAccountPropertyValue {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBulkUpateAccountPropertyValue();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.propertyKey = reader.string();
                    break;
                case 3:
                    message.updateAccountProperty.push(UpdateAccountPropertyValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<BulkUpateAccountPropertyValue>): BulkUpateAccountPropertyValue {
        const message = createBaseBulkUpateAccountPropertyValue();
        message.clientRequestId = object.clientRequestId ?? '';
        message.propertyKey = object.propertyKey ?? '';
        message.updateAccountProperty =
            object.updateAccountProperty?.map((e) => UpdateAccountPropertyValue.fromPartial(e)) || [];
        return message;
    }
};

function createBaseUpdateAccountPropertyValue(): UpdateAccountPropertyValue {
    return { clientRequestId: '', accountCode: '', propertyKey: '', propertyVaue: '' };
}

export const UpdateAccountPropertyValue = {
    encode(message: UpdateAccountPropertyValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.accountCode !== '') {
            writer.uint32(18).string(message.accountCode);
        }
        if (message.propertyKey !== '') {
            writer.uint32(26).string(message.propertyKey);
        }
        if (message.propertyVaue !== '') {
            writer.uint32(34).string(message.propertyVaue);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountPropertyValue {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateAccountPropertyValue();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.accountCode = reader.string();
                    break;
                case 3:
                    message.propertyKey = reader.string();
                    break;
                case 4:
                    message.propertyVaue = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UpdateAccountPropertyValue>): UpdateAccountPropertyValue {
        const message = createBaseUpdateAccountPropertyValue();
        message.clientRequestId = object.clientRequestId ?? '';
        message.accountCode = object.accountCode ?? '';
        message.propertyKey = object.propertyKey ?? '';
        message.propertyVaue = object.propertyVaue ?? '';
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
