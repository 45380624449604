/* eslint-disable */
import { UserLoginPolicyType } from '../enums/UserLoginPolicyTypeProto';
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.baseserver.api.user';

export interface SystemUserDownstreamEvent {
    id: string;
    username: string;
    email: string;
    userLoginPolicyType: UserLoginPolicyType;
    enabled: boolean;
    expired: boolean;
    locked: boolean;
    passwordExpired: boolean;
    createdInMillis: string;
    updatedInMillis: string;
}

export interface StandardUserDownstreamEvent {
    id: string;
    username: string;
    forename: string;
    surname: string;
    region: string;
    email: string;
    telephone: string;
    passwordValidDays: number;
    passwordExpiryDateInMillis: string;
    userLoginPolicyType: UserLoginPolicyType;
    enabled: boolean;
    expired: boolean;
    locked: boolean;
    passwordExpired: boolean;
    createdInMillis: string;
    updatedInMillis: string;
    lastLoggedInMillis: string;
    termsAndConditionsAccepted: boolean;
    isTwoFactorAuth: boolean;
}

export interface DeleteUserConfirmation {
    id: string;
    username: string;
}

export interface ChangeUserPasswordConfirmation {
    username: string;
    succeeded: boolean;
    message: string[];
}

export interface ResetUserPasswordToken {
    username: string;
    resetUserPasswordToken: string;
    validToTimestampUtcInMillis: string;
}

export interface VerifyUserPasswordResponse {
    succeeded: boolean;
    message: string;
}

export interface UserQRCode {
    username: string;
    image: Uint8Array;
}

function createBaseSystemUserDownstreamEvent(): SystemUserDownstreamEvent {
    return {
        id: '0',
        username: '',
        email: '',
        userLoginPolicyType: 0,
        enabled: false,
        expired: false,
        locked: false,
        passwordExpired: false,
        createdInMillis: '0',
        updatedInMillis: '0'
    };
}

export const SystemUserDownstreamEvent = {
    encode(message: SystemUserDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== '0') {
            writer.uint32(8).int64(message.id);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        if (message.email !== '') {
            writer.uint32(26).string(message.email);
        }
        if (message.userLoginPolicyType !== 0) {
            writer.uint32(32).int32(message.userLoginPolicyType);
        }
        if (message.enabled === true) {
            writer.uint32(40).bool(message.enabled);
        }
        if (message.expired === true) {
            writer.uint32(48).bool(message.expired);
        }
        if (message.locked === true) {
            writer.uint32(56).bool(message.locked);
        }
        if (message.passwordExpired === true) {
            writer.uint32(64).bool(message.passwordExpired);
        }
        if (message.createdInMillis !== '0') {
            writer.uint32(72).int64(message.createdInMillis);
        }
        if (message.updatedInMillis !== '0') {
            writer.uint32(80).int64(message.updatedInMillis);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): SystemUserDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSystemUserDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 3:
                    message.email = reader.string();
                    break;
                case 4:
                    message.userLoginPolicyType = reader.int32() as any;
                    break;
                case 5:
                    message.enabled = reader.bool();
                    break;
                case 6:
                    message.expired = reader.bool();
                    break;
                case 7:
                    message.locked = reader.bool();
                    break;
                case 8:
                    message.passwordExpired = reader.bool();
                    break;
                case 9:
                    message.createdInMillis = longToString(reader.int64() as Long);
                    break;
                case 10:
                    message.updatedInMillis = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<SystemUserDownstreamEvent>): SystemUserDownstreamEvent {
        const message = createBaseSystemUserDownstreamEvent();
        message.id = object.id ?? '0';
        message.username = object.username ?? '';
        message.email = object.email ?? '';
        message.userLoginPolicyType = object.userLoginPolicyType ?? 0;
        message.enabled = object.enabled ?? false;
        message.expired = object.expired ?? false;
        message.locked = object.locked ?? false;
        message.passwordExpired = object.passwordExpired ?? false;
        message.createdInMillis = object.createdInMillis ?? '0';
        message.updatedInMillis = object.updatedInMillis ?? '0';
        return message;
    }
};

function createBaseStandardUserDownstreamEvent(): StandardUserDownstreamEvent {
    return {
        id: '0',
        username: '',
        forename: '',
        surname: '',
        region: '',
        email: '',
        telephone: '',
        passwordValidDays: 0,
        passwordExpiryDateInMillis: '0',
        userLoginPolicyType: 0,
        enabled: false,
        expired: false,
        locked: false,
        passwordExpired: false,
        createdInMillis: '0',
        updatedInMillis: '0',
        lastLoggedInMillis: '0',
        termsAndConditionsAccepted: false,
        isTwoFactorAuth: false
    };
}

export const StandardUserDownstreamEvent = {
    encode(message: StandardUserDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== '0') {
            writer.uint32(8).int64(message.id);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        if (message.forename !== '') {
            writer.uint32(26).string(message.forename);
        }
        if (message.surname !== '') {
            writer.uint32(34).string(message.surname);
        }
        if (message.region !== '') {
            writer.uint32(42).string(message.region);
        }
        if (message.email !== '') {
            writer.uint32(50).string(message.email);
        }
        if (message.telephone !== '') {
            writer.uint32(58).string(message.telephone);
        }
        if (message.passwordValidDays !== 0) {
            writer.uint32(64).int32(message.passwordValidDays);
        }
        if (message.passwordExpiryDateInMillis !== '0') {
            writer.uint32(72).int64(message.passwordExpiryDateInMillis);
        }
        if (message.userLoginPolicyType !== 0) {
            writer.uint32(80).int32(message.userLoginPolicyType);
        }
        if (message.enabled === true) {
            writer.uint32(88).bool(message.enabled);
        }
        if (message.expired === true) {
            writer.uint32(96).bool(message.expired);
        }
        if (message.locked === true) {
            writer.uint32(104).bool(message.locked);
        }
        if (message.passwordExpired === true) {
            writer.uint32(112).bool(message.passwordExpired);
        }
        if (message.createdInMillis !== '0') {
            writer.uint32(120).int64(message.createdInMillis);
        }
        if (message.updatedInMillis !== '0') {
            writer.uint32(128).int64(message.updatedInMillis);
        }
        if (message.lastLoggedInMillis !== '0') {
            writer.uint32(136).int64(message.lastLoggedInMillis);
        }
        if (message.termsAndConditionsAccepted === true) {
            writer.uint32(144).bool(message.termsAndConditionsAccepted);
        }
        if (message.isTwoFactorAuth === true) {
            writer.uint32(152).bool(message.isTwoFactorAuth);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): StandardUserDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStandardUserDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 3:
                    message.forename = reader.string();
                    break;
                case 4:
                    message.surname = reader.string();
                    break;
                case 5:
                    message.region = reader.string();
                    break;
                case 6:
                    message.email = reader.string();
                    break;
                case 7:
                    message.telephone = reader.string();
                    break;
                case 8:
                    message.passwordValidDays = reader.int32();
                    break;
                case 9:
                    message.passwordExpiryDateInMillis = longToString(reader.int64() as Long);
                    break;
                case 10:
                    message.userLoginPolicyType = reader.int32() as any;
                    break;
                case 11:
                    message.enabled = reader.bool();
                    break;
                case 12:
                    message.expired = reader.bool();
                    break;
                case 13:
                    message.locked = reader.bool();
                    break;
                case 14:
                    message.passwordExpired = reader.bool();
                    break;
                case 15:
                    message.createdInMillis = longToString(reader.int64() as Long);
                    break;
                case 16:
                    message.updatedInMillis = longToString(reader.int64() as Long);
                    break;
                case 17:
                    message.lastLoggedInMillis = longToString(reader.int64() as Long);
                    break;
                case 18:
                    message.termsAndConditionsAccepted = reader.bool();
                    break;
                case 19:
                    message.isTwoFactorAuth = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<StandardUserDownstreamEvent>): StandardUserDownstreamEvent {
        const message = createBaseStandardUserDownstreamEvent();
        message.id = object.id ?? '0';
        message.username = object.username ?? '';
        message.forename = object.forename ?? '';
        message.surname = object.surname ?? '';
        message.region = object.region ?? '';
        message.email = object.email ?? '';
        message.telephone = object.telephone ?? '';
        message.passwordValidDays = object.passwordValidDays ?? 0;
        message.passwordExpiryDateInMillis = object.passwordExpiryDateInMillis ?? '0';
        message.userLoginPolicyType = object.userLoginPolicyType ?? 0;
        message.enabled = object.enabled ?? false;
        message.expired = object.expired ?? false;
        message.locked = object.locked ?? false;
        message.passwordExpired = object.passwordExpired ?? false;
        message.createdInMillis = object.createdInMillis ?? '0';
        message.updatedInMillis = object.updatedInMillis ?? '0';
        message.lastLoggedInMillis = object.lastLoggedInMillis ?? '0';
        message.termsAndConditionsAccepted = object.termsAndConditionsAccepted ?? false;
        message.isTwoFactorAuth = object.isTwoFactorAuth ?? false;
        return message;
    }
};

function createBaseDeleteUserConfirmation(): DeleteUserConfirmation {
    return { id: '0', username: '' };
}

export const DeleteUserConfirmation = {
    encode(message: DeleteUserConfirmation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== '0') {
            writer.uint32(8).int64(message.id);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUserConfirmation {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeleteUserConfirmation();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<DeleteUserConfirmation>): DeleteUserConfirmation {
        const message = createBaseDeleteUserConfirmation();
        message.id = object.id ?? '0';
        message.username = object.username ?? '';
        return message;
    }
};

function createBaseChangeUserPasswordConfirmation(): ChangeUserPasswordConfirmation {
    return { username: '', succeeded: false, message: [] };
}

export const ChangeUserPasswordConfirmation = {
    encode(message: ChangeUserPasswordConfirmation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.username !== '') {
            writer.uint32(10).string(message.username);
        }
        if (message.succeeded === true) {
            writer.uint32(16).bool(message.succeeded);
        }
        for (const v of message.message) {
            writer.uint32(26).string(v!);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): ChangeUserPasswordConfirmation {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseChangeUserPasswordConfirmation();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.username = reader.string();
                    break;
                case 2:
                    message.succeeded = reader.bool();
                    break;
                case 3:
                    message.message.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<ChangeUserPasswordConfirmation>): ChangeUserPasswordConfirmation {
        const message = createBaseChangeUserPasswordConfirmation();
        message.username = object.username ?? '';
        message.succeeded = object.succeeded ?? false;
        message.message = object.message?.map((e) => e) || [];
        return message;
    }
};

function createBaseResetUserPasswordToken(): ResetUserPasswordToken {
    return { username: '', resetUserPasswordToken: '', validToTimestampUtcInMillis: '0' };
}

export const ResetUserPasswordToken = {
    encode(message: ResetUserPasswordToken, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.username !== '') {
            writer.uint32(10).string(message.username);
        }
        if (message.resetUserPasswordToken !== '') {
            writer.uint32(18).string(message.resetUserPasswordToken);
        }
        if (message.validToTimestampUtcInMillis !== '0') {
            writer.uint32(24).int64(message.validToTimestampUtcInMillis);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): ResetUserPasswordToken {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseResetUserPasswordToken();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.username = reader.string();
                    break;
                case 2:
                    message.resetUserPasswordToken = reader.string();
                    break;
                case 3:
                    message.validToTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<ResetUserPasswordToken>): ResetUserPasswordToken {
        const message = createBaseResetUserPasswordToken();
        message.username = object.username ?? '';
        message.resetUserPasswordToken = object.resetUserPasswordToken ?? '';
        message.validToTimestampUtcInMillis = object.validToTimestampUtcInMillis ?? '0';
        return message;
    }
};

function createBaseVerifyUserPasswordResponse(): VerifyUserPasswordResponse {
    return { succeeded: false, message: '' };
}

export const VerifyUserPasswordResponse = {
    encode(message: VerifyUserPasswordResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.succeeded === true) {
            writer.uint32(8).bool(message.succeeded);
        }
        if (message.message !== '') {
            writer.uint32(18).string(message.message);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): VerifyUserPasswordResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVerifyUserPasswordResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.succeeded = reader.bool();
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<VerifyUserPasswordResponse>): VerifyUserPasswordResponse {
        const message = createBaseVerifyUserPasswordResponse();
        message.succeeded = object.succeeded ?? false;
        message.message = object.message ?? '';
        return message;
    }
};

function createBaseUserQRCode(): UserQRCode {
    return { username: '', image: new Uint8Array() };
}

export const UserQRCode = {
    encode(message: UserQRCode, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.username !== '') {
            writer.uint32(10).string(message.username);
        }
        if (message.image.length !== 0) {
            writer.uint32(18).bytes(message.image);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UserQRCode {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUserQRCode();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.username = reader.string();
                    break;
                case 2:
                    message.image = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UserQRCode>): UserQRCode {
        const message = createBaseUserQRCode();
        message.username = object.username ?? '';
        message.image = object.image ?? new Uint8Array();
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
