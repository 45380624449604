import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { FetchingStatus } from '../../model/fetchingStatus';
import { RootState } from '../store';

export enum ChangeAccountUsageAccountStatus {
    SUCCESS = 1,
    BAD_REQUEST = 2,
    UNEXPECTED = -1
}

export class ChangeAccountUsageAccountError extends Error {
    public constructor(public code: ChangeAccountUsageAccountStatus, message?: string) {
        super(message);
    }
}

export interface AccountUsage {
    account: string;
    usage: number;
    accountValue: number;
    unrealisedPnl: number;
    currency: string;
}

export interface AccountUsageState {
    status: FetchingStatus;
    accountUsages: Record<string, AccountUsage> | null;
}

const initialState: AccountUsageState = {
    status: 'idle',
    accountUsages: null
};

export const accountUsageSlice = createSlice({
    name: 'accountUsage',
    initialState,
    reducers: {
        setAccountUsage: (state, action: PayloadAction<AccountUsage>) => {
            if (state.accountUsages) {
                state.accountUsages[action.payload.account] = action.payload;
            } else {
                state.accountUsages = { [action.payload.account]: action.payload };
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => initialState);
    }
});

export const { setAccountUsage } = accountUsageSlice.actions;
export const selectAccountUsages = (state: RootState) => state.accountUsage.accountUsages;
export const selectAccountUsageStatus = (state: RootState) => state.accountUsage.status;

export default accountUsageSlice.reducer;
