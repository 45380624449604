import { Side } from '@/compiled_proto/com/celertech/marketdata/api/enums/SideProto';
import HighlightedPips from '@/components/common/HighlightedPips';
import { TickerOrderFormInput } from '@/components/form/schema/tickerOrderSchema';
import { numberFormatterConfig, tickerIdleTimer } from '@/config/config';
import { accumulatePriceBookBidAsks } from '@/helpers/orderbookHelper';
import { useAppSelector } from '@/state/hooks';
import { PairMap } from '@/state/reducers/marketPairSlice';
import { formatTraderNumber } from '@/utils/format';
import { UseDisclosureReturn } from '@/utils/hooks/useDisclosure';
import { GetColorIndexesReturn, GetPipIndexesReturn, useInstrument } from '@/utils/hooks/useInstrument';
import { useTickerIdle } from '@/utils/hooks/useTickerIdle';
import cn from 'classnames';
import { Fragment, cloneElement, useEffect, useMemo, useState } from 'react';
import { useNumberFormatter } from 'react-aria';
import { useFormContext } from 'react-hook-form';

interface TickerPriceProps {
    side: Side;
    market: PairMap;
    bestPrice: number;
    currencyAssetName: string;
    showOrderBook: UseDisclosureReturn;
    alignItems?: 'left' | 'right';
    priceType: 'bid' | 'ask';
    isCcy2Order: boolean;
    onSubmit?: () => void;
    children: React.ReactNode;
    disabled?: boolean;
}

const expirationDuration = 5000;

const TickerPrice = (props: TickerPriceProps) => {
    const {
        side,
        bestPrice,
        showOrderBook,
        currencyAssetName,
        alignItems = 'right',
        disabled = false,
        onSubmit,
        children
    } = props;

    const [locked, setLocked] = useState<boolean>(true);
    const [expirationTimer, setExpirationTimer] = useState<number>(expirationDuration);

    const { setValue } = useFormContext<TickerOrderFormInput>();

    const tickerTitle = useMemo(() => {
        if (side === Side.BUY) return `BUY ${currencyAssetName}`;
        else return `SELL ${currencyAssetName}`;
    }, [currencyAssetName, side]);

    useEffect(() => {
        if (!locked) {
            const timer = setTimeout(() => setExpirationTimer(expirationTimer - 1000), 1000);
            if (expirationTimer <= 0) {
                clearTimeout(timer);
                setLocked(true);
            }
        }
    }, [locked, expirationTimer]);

    // is not disabled and it has a price
    const isTradeable = useMemo(() => !disabled && !!bestPrice, [disabled, bestPrice, locked]);

    return (
        <div className="flex-1 basis-0">
            <div
                className={cn('relative h-12 w-full flex items-center cursor-pointer p-1 pt-3.5 z-[1] select-none', {
                    ['border-2 border-brand-primary hover:border-brand-primary-light']: side === Side.BUY,
                    ['border-2 border-brand-red hover:border-brand-red-light']: side === Side.SELL,
                    [`bg-brand-primary hover:bg-brand-primary-light`]: isTradeable && !locked && side === Side.BUY,
                    [`bg-brand-red hover:bg-brand-red-light`]: isTradeable && !locked && side === Side.SELL,
                    ['pl-1 justify-start']: alignItems === 'left',
                    ['pr-1 justify-end']: alignItems === 'right',
                    'cursor-not-allowed': !isTradeable
                })}
                onClick={(event) => {
                    if (locked && isTradeable) {
                        setLocked(false);
                        setExpirationTimer(5000);
                        event.preventDefault();
                    }
                    if (!locked) {
                        setValue('side', side);
                        setValue('bestPrice', bestPrice);
                        onSubmit?.();
                        setLocked(true);
                    }
                }}>
                {cloneElement(children as React.ReactElement, { locked })}
                <div
                    className={cn('absolute top-0 uppercase text-2xs', {
                        ['left-1']: alignItems === 'left',
                        ['right-1']: alignItems === 'right'
                    })}>
                    {tickerTitle}
                </div>
            </div>
            {showOrderBook[0] && <TickerVerticalBook {...props} />}
        </div>
    );
};

export default TickerPrice;

interface HighlightsProps {
    ticker?: GetPipIndexesReturn;
    highlights?: GetColorIndexesReturn;
    locked?: boolean;
}

export const Highlights = ({ ticker, locked, highlights }: HighlightsProps) => {
    const tickerIdle = useTickerIdle(tickerIdleTimer, highlights);
    return (
        <div className="mt-1.5">
            {ticker ? (
                <HighlightedPips
                    price={ticker.price}
                    boldIndexes={ticker.pipIndexes}
                    colorIndexes={!tickerIdle && locked ? highlights?.colorIndexes : []}
                    color={!tickerIdle && locked ? highlights?.color : 'text-neutral-200'}
                    fontSize="text-lg"
                />
            ) : (
                <span className="text-sm">No Price</span>
            )}
        </div>
    );
};

const TickerVerticalBook = ({ market, side, priceType, alignItems, isCcy2Order }: TickerPriceProps) => {
    const priceBooks = useAppSelector((state) => state.celerMarket.priceBooks[market.celer]);
    const { bids, asks } = useMemo(() => accumulatePriceBookBidAsks(priceBooks), [priceBooks, market.celer]);

    const { formatPip } = useInstrument(market.celer);

    const hasOrderBook = bids && asks && bids[0] && asks[0];

    const { rows, ...rowProps } = useMemo(() => {
        let rows: any = [];
        if (priceType === 'ask' && asks) {
            const asksCopy = [...asks];
            rows = asksCopy.reverse();
        } else {
            rows = bids;
        }

        const type: 'ask' | 'bid' = priceType;

        if (rows) {
            return {
                rows,
                side,
                type,
                formatPip,
                totalQuantity: rows[0]?.accQuantity || 0,
                alignItems,
                isCcy2Order
            };
        } else {
            return {
                rows: [],
                side,
                type,
                formatPip,
                totalQuantity: 0,
                alignItems,
                isCcy2Order
            };
        }
    }, [bids, asks, side, alignItems, formatPip]);

    return (
        <div
            className={cn(
                'ticker-order-book h-32 lg:w-[113px] border border-t-0 border-neutral-600 overflow-y-auto',
                // 'ticker-order-book max-h-32 lg:w-[113px] border border-t-0 border-neutral-600 overflow-y-auto',
                {
                    sell: side === Side.SELL
                }
            )}>
            <Fragment>
                {!hasOrderBook ? (
                    <div className="p-2 text-xs italic text-neutral-400">No orderbook data</div>
                ) : (
                    <div className="divide-y divide-neutral-600">
                        {rows.map((row, i) => (
                            <OrderBookRow key={i} {...row} {...rowProps} />
                        ))}
                    </div>
                )}
            </Fragment>
        </div>
    );
};

interface OrderBookRowProps {
    side: Side;
    price: number;
    quantity: number;
    accQuantity: number;
    totalQuantity: number;
    formatPip: (price: number) => GetPipIndexesReturn;
    type: 'ask' | 'bid';
    alignItems?: 'left' | 'right';
    isCcy2Order: boolean;
}

const OrderBookRow = ({ side, price, quantity, formatPip, alignItems }: OrderBookRowProps) => {
    const defaultFormatNumber = useNumberFormatter(numberFormatterConfig);
    const thousandsFormatNumber = useNumberFormatter({ maximumFractionDigits: 0, notation: 'compact' });
    const millionsFormatNumber = useNumberFormatter({ maximumFractionDigits: 1, notation: 'compact' });

    const { price: formattedPrice, pipIndexes } = formatPip(price);

    return (
        <div
            className={cn(
                'w-full justify-between flex gap-1 text-xs px-1 py-1 relative group hover:bg-brand-background-dark',
                {
                    'flex-row-reverse': alignItems === 'right'
                }
            )}>
            <div className="flex items-center text-neutral-500 overflow-hidden">
                <span className="truncate">
                    {formatTraderNumber(quantity, defaultFormatNumber, thousandsFormatNumber, millionsFormatNumber)}
                </span>
            </div>
            <div
                className={cn('', {
                    'text-brand-red': side === Side.SELL,
                    'text-brand-primary': side === Side.BUY
                })}>
                <HighlightedPips price={formattedPrice} boldIndexes={pipIndexes} fontSize="text-base" />
            </div>
        </div>
    );
};
