import { Logger } from '@/utils/logger';

export async function forEachAsync<T>(
    asyncIterable: AsyncIterable<T>,
    callback: (result: T) => void,
    stopIterator: () => boolean,
    stopMsg?: string
) {
    for await (const result of asyncIterable) {
        if (stopIterator()) {
            Logger({ title: `${stopMsg}: Old Subscriptions Terminated`, callback: () => {} });
            break;
            // throw new Error(stopMsg || 'Old Subscriptions Terminated');
        }
        try {
            callback(result);
        } catch (err) {
            console.log(err);
            continue;
        }
    }
}

export async function asyncList<T>(asyncIterable: AsyncIterable<T>, format?: (event: T) => any) {
    const resList: any[] = [];
    for await (const val of asyncIterable) resList.push(format ? format(val) : val);
    return resList;
}
