import { Dialog } from '@headlessui/react';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { LoginResponse } from '../../compiled_proto/com/celertech/baseserver/communication/login/DownstreamLoginProto';
import { Button } from '../common/Button';
import Divider from '../common/Divider';
import { LoginFormInput } from '../form/schema/loginSchema';
import RHFInput from '../inputs/RHFInput';
import Modal, { ModalHeader, ModalProps, ModalTitle } from './Modal';

interface TwoFactorAuthModalProps extends ModalProps {
    userRequires2FA?: LoginResponse;
    manualOnSubmit(): void;
    cancelTwoFactorAuth(): void;
}

const TwoFactorAuthModal = (props: TwoFactorAuthModalProps) => {
    const { userRequires2FA, manualOnSubmit, cancelTwoFactorAuth } = props;

    const {
        register,
        formState: { isSubmitting }
    } = useFormContext<LoginFormInput>();

    const blob = useMemo(() => {
        if (userRequires2FA?.qrCode.byteLength) {
            return new Blob([userRequires2FA.qrCode]);
        }
        return null;
    }, [userRequires2FA]);

    return (
        <Modal {...props} className="h-auto" size="max-w-md" overlayOpacity="bg-opacity-100">
            <ModalHeader>
                <ModalTitle>Two Factor Authentication</ModalTitle>
            </ModalHeader>
            <Divider />
            <div className="text-neutral-200 p-2 py-3 sm:p-4">
                <div className="space-y-4">
                    {blob ? (
                        <div className="space-y-2">
                            <div className="text-sm">
                                Scan this QR Code on your MFA device to get the verification code
                            </div>
                            <div className="w-1/2">
                                <img src={blob ? URL.createObjectURL(blob) : ''} />
                            </div>
                        </div>
                    ) : null}
                    <RHFInput
                        {...register('twoFactorToken')}
                        type="twoFactorToken"
                        placeholder="Enter the code from your MFA device to authenticate"
                        label="Verification Code"
                    />
                </div>
            </div>
            <Divider />
            <div className="text-neutral-200 p-2 sm:p-4 text-sm flex space-x-2">
                <button
                    className="rounded-md p-2 px-4 bg-neutral-600 hover:bg-neutral-500"
                    onClick={cancelTwoFactorAuth}>
                    Back
                </button>
                <Button
                    className="rounded-md p-2 px-4 bg-brand-primary hover:bg-brand-primary-light"
                    onClick={manualOnSubmit}
                    isLoading={isSubmitting}>
                    Submit
                </Button>
            </div>
        </Modal>
    );
};

export default TwoFactorAuthModal;
