import { MarketDataBookType } from '@/compiled_proto/com/celertech/marketdata/api/enums/MarketDataBookTypeProto';
import { FetchingStatus } from '@/model/fetchingStatus';
import { getUserMarkets } from '@/services/UserService';
import { PriceBook, PricebookEntry, PriceEvent } from '@/utils/pricebook';
import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const ORDER_BOOK_DEPTH = 10;
const initPriceBook = new Array(ORDER_BOOK_DEPTH).fill(null);

export const asyncFetchMarkets = createAsyncThunk('celerMarket/fetch', async (_, { getState }) => {
    const state = getState() as RootState;
    const markets = await getUserMarkets(state.auth.user);
    return markets;
});

export const addSubscription = createAction<MarketItem>('celerMarket/addSubscription');
export const cancelSubscription = createAction<MarketItem>('celerMarket/cancelSubscription');
export const setSubscriptions = createAction<MarketItem[]>('celerMarket/setSubscriptions');
export const subscribeAll = createAction('celerMarket/subscribeAll');

interface Candle {
    opentime: number;
    open: number;
    high: number;
    low: number;
    close: number;
}

export interface MarketItem {
    securityCode: string;
    exchangeCode: string;
    settlementType: string;
}

export interface MarketItemSubscribed extends MarketItem {
    subscriptionId: string;
}

export interface CelerMarketState {
    status: FetchingStatus;
    markets: MarketItem[];
    subscribedMarkets: MarketItem[];
    priceBooks: Record<string, PriceBook>;
    currentCandle?: Candle;
    currentPair?: string;
}

const initialState: CelerMarketState = {
    status: 'idle',
    markets: [],
    subscribedMarkets: [],
    priceBooks: {}
};

interface UpdateOrderBookPayload {
    priceEvent: PriceEvent;
    // subscriptions: Record<string, SubscriptionInfo>;
}

export const celerMarketSlice = createSlice({
    name: 'celerMarket',
    initialState,
    reducers: {
        // PriceEvent, any
        updateOrderBook: (state, action: PayloadAction<UpdateOrderBookPayload>) => {
            const priceEvent = action.payload.priceEvent;
            // const subscriptions = action.payload.subscriptions;

            // priceBook is of type WritableDraft<PriceBook>
            let newPriceBook = { bids: [...initPriceBook], asks: [...initPriceBook], midprice: 0 };
            if (priceEvent.bookType === MarketDataBookType.TOP_OF_THE_BOOK) {
                // const isFullBook = Object.entries(subscriptions).find(
                //     ([_, subInfo]) => subInfo.pair === priceEvent.securityCode && subInfo.type === 'full'
                // );
                // if (state.priceBooks[priceEvent.securityCode] && isFullBook)
                if (state.priceBooks[priceEvent.securityCode]) newPriceBook = state.priceBooks[priceEvent.securityCode];
            }

            priceEvent.priceBook.map((entry: PricebookEntry) => {
                if (entry.type === 'midprice') {
                    newPriceBook.midprice = entry.price;
                } else {
                    newPriceBook[entry.type][entry.position - 1] = {
                        price: entry.price,
                        quantity: entry.size
                    };
                }
            });

            state.priceBooks[priceEvent.securityCode] = newPriceBook;
        },
        clearOrderBook: (state, action: PayloadAction<string>) => {
            const securityCode = action.payload;

            state.priceBooks[securityCode] = {
                bids: [...initPriceBook],
                asks: [...initPriceBook],
                midprice: 0
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(asyncFetchMarkets.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(asyncFetchMarkets.fulfilled, (state, action) => {
                state.status = 'idle';
                state.markets = action.payload;
                // console.log('Fetched markets', state.markets);
            })
            .addCase(asyncFetchMarkets.rejected, (state) => {
                state.status = 'failed';
            });
    }
});

export const { updateOrderBook, clearOrderBook } = celerMarketSlice.actions;
export const selectUserMarkets = (state: RootState) => state.celerMarket.markets;
export const selectPriceBooks = (state: RootState) => state.celerMarket.priceBooks;
export const selectSubscribedMarkets = (state: RootState) => state.celerMarket.subscribedMarkets;

export default celerMarketSlice.reducer;
