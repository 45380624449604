/* eslint-disable */
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.orderrouting.api.execution';

export interface EndOfDayNotification {
    exchange: string[];
}

function createBaseEndOfDayNotification(): EndOfDayNotification {
    return { exchange: [] };
}

export const EndOfDayNotification = {
    encode(message: EndOfDayNotification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.exchange) {
            writer.uint32(10).string(v!);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): EndOfDayNotification {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEndOfDayNotification();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.exchange.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<EndOfDayNotification>): EndOfDayNotification {
        const message = createBaseEndOfDayNotification();
        message.exchange = object.exchange?.map((e) => e) || [];
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;
