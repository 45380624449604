import { InferType, object, string } from 'yup';
import { Nullable } from '../../../model/common';

export const resetPasswordSchema = object({
    current_password: string().required('Current Password can not be empty.'),
    new_password: string().required('New Password can not be empty.'),
    confirm_new_password: string().required('Confirm Password can not be empty.')
});

export type ResetPasswordFormValues = InferType<typeof resetPasswordSchema>;
export type ResetPasswordFormInput = Nullable<ResetPasswordFormValues>;
