export const KLINE_INTERVAL = '1d';
export const ORDERBOOK_LEVELS = 50;

export const numberFormatterConfig: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 5
};

export const qtyFormatterConfig: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 8
};

export const convertedQtyFormatterConfig: Intl.NumberFormatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 20
};

export const tickerIdleTimer = +(window.config.tickerIdleTimer || 5000);

export const defaultColumn = {
    minSize: 60, //enforced during column resizing
    maxSize: 500 //enforced during column resizing
};
