import { RootState, store } from '@/state/store';
import {
    ChartingLibraryWidgetOptions,
    DatafeedConfiguration,
    LanguageCode,
    LibrarySymbolInfo,
    ResolutionString,
    StudyOverrides,
    WidgetOverrides
} from '@/types/charting_library';
import { CSSVariables } from '@/utils/hooks/useCSSVariables';
import { getInstrumentFormatter } from '@/utils/hooks/useInstrument';
import { format, toZonedTime } from 'date-fns-tz';

const protocol = 'https:' === document.location.protocol ? 'https://' : 'http://';

export const connectionConfig = {
    pollingInterval: 1000,
    host: `${protocol}balancer.netdania.com/StreamingServer/StreamingServer`,
    failoverHosts: [
        `${protocol}balancer-cro.netdania.com/StreamingServer/StreamingServer`,
        `${protocol}balancer.datafeeds.io/StreamingServer/StreamingServer`,
        `${protocol}balancer-cro.datafeeds.io/StreamingServer/StreamingServer`
    ],
    behavior: global.NetDania.JsApi.ConnectionType.AUTO
};

export const configurationData = {
    // '2S',
    // '3S',
    // '4S',
    // '5S',
    // '10S',
    // '15S',
    // '30S',
    // '5',
    supported_resolutions: ['1T', '1S', '1', '15', '1H', '4H', '12H', '1D', '1W', '1M'],
    exchanges: [],
    symbols_types: [{ name: 'crypto', value: 'crypto' }]
} as unknown as DatafeedConfiguration;

export const chartConfig = (locale: string, datafeed: any, variables: CSSVariables): ChartingLibraryWidgetOptions => {
    const timezone = 'Etc/UTC';
    // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone;

    const keys = Object.keys(localStorage);
    const saved_settings = {};
    keys.filter((key) => !['pushCelerPrice', 'persist:root', 'chartproperties', 'chartState'].includes(key)).forEach(
        (key, index) => {
            saved_settings[key] = localStorage.getItem(key);
        }
    );

    const state: RootState = store.getState();
    const activePair = state.marketPair.activePair;
    const symbol = activePair.celer || 'BTC/USD';

    return {
        theme: 'Dark',
        symbol,
        // debug: true,
        debug: false,
        fullscreen: false,
        datafeed,
        interval: '1D' as ResolutionString,
        container: 'tv_chart_container',
        library_path: '/charting_library/',
        custom_css_url: '/charting_library/styles/tradingview.css',
        locale: locale as LanguageCode,
        disabled_features: [
            'timeframes_toolbar',
            'header_compare',
            'show_object_tree',
            'header_symbol_search',
            'header_undo_redo',
            'header_saveload',
            'lock_visible_time_range_on_resize',
            'symbol_info',
            'legend_inplace_edit' as any
        ],
        enabled_features: [
            'create_volume_indicator_by_default',
            'use_localstorage_for_settings',
            'save_chart_properties_to_local_storage',
            'seconds_resolution',
            'tick_resolution',
            'items_favoriting',
            'determine_first_data_request_size_using_visible_range'
        ],
        autosize: true,
        timeframe: '3M',
        time_frames: [
            { text: '1m', resolution: '1' as ResolutionString, description: '1 Minute' },
            { text: '5m', resolution: '5' as ResolutionString, description: '5 Minutes' },
            { text: '1h', resolution: '1h' as ResolutionString, description: '1 Hour' },
            { text: '1d', resolution: '1d' as ResolutionString, description: '1 Day' }
        ],
        custom_formatters: {
            timeFormatter: {
                format: (date) => format(toZonedTime(date, timezone), 'HH:mm:ss', { timeZone: timezone }),
                formatLocal: (date) => format(toZonedTime(date, timezone), 'HH:mm:ss', { timeZone: timezone })
            },
            dateFormatter: {
                format: (date) => Intl.DateTimeFormat(locale).format(date),
                formatLocal: (date) => Intl.DateTimeFormat(locale).format(date)
            },
            priceFormatterFactory: (symbolInfo: LibrarySymbolInfo | null, minTick: string) => {
                const { formatPrice } = getInstrumentFormatter(locale, symbolInfo?.ticker || '');
                return { format: formatPrice };
            }
        },
        timezone: timezone,
        auto_save_delay: 1,
        // saved_data: saved_chart ? JSON.parse(saved_chart) : undefined,
        overrides: chartTheme(variables),
        studies_overrides: chartStudiesTheme(variables),
        loading_screen: {
            foregroundColor: variables['--color-brand-primary'],
            backgroundColor: variables['--color-brand-background-dark']
        },
        settings_adapter: {
            initialSettings: {
                ...saved_settings,
                ...(chartTheme(variables) as any),
                ...chartStudiesTheme(variables)
            },
            setValue(key, value) {
                localStorage.setItem(key, value);
            },
            removeValue(key) {
                localStorage.removeItem(key);
            }
        }
    };
};

export const simpleChartConfig = (
    symbol,
    locale: string,
    datafeed: any,
    variables: CSSVariables
): ChartingLibraryWidgetOptions => {
    const timezone = 'Etc/UTC';

    const keys = Object.keys(localStorage);
    const saved_settings = {};
    keys.filter((key) => !['pushCelerPrice', 'persist:root', 'chartproperties', 'chartState'].includes(key)).forEach(
        (key, index) => {
            saved_settings[key] = localStorage.getItem(key);
        }
    );

    return {
        theme: 'Dark',
        symbol,
        // debug: true,
        debug: false,
        fullscreen: false,
        datafeed,
        interval: '1D' as ResolutionString,
        container: 'tv_chart_container',
        library_path: '/charting_library/',
        custom_css_url: '/charting_library/styles/tradingview.css',
        locale: locale as LanguageCode,
        disabled_features: [
            'timeframes_toolbar',
            'header_compare',
            'show_object_tree',
            'header_symbol_search',
            'header_undo_redo',
            'header_saveload',
            'lock_visible_time_range_on_resize'
        ],
        enabled_features: [
            'create_volume_indicator_by_default',
            'use_localstorage_for_settings',
            'save_chart_properties_to_local_storage',
            'seconds_resolution',
            'tick_resolution',
            'items_favoriting',
            'determine_first_data_request_size_using_visible_range'
        ],
        autosize: true,
        timeframe: '3M',
        time_frames: [
            { text: '1m', resolution: '1' as ResolutionString, description: '1 Minute' },
            { text: '5m', resolution: '5' as ResolutionString, description: '5 Minutes' },
            { text: '1h', resolution: '1h' as ResolutionString, description: '1 Hour' },
            { text: '1d', resolution: '1d' as ResolutionString, description: '1 Day' }
        ],
        custom_formatters: {
            timeFormatter: {
                format: (date) => format(toZonedTime(date, timezone), 'HH:mm:ss', { timeZone: timezone }),
                formatLocal: (date) => format(toZonedTime(date, timezone), 'HH:mm:ss', { timeZone: timezone })
            },
            dateFormatter: {
                format: (date) => Intl.DateTimeFormat(locale).format(date),
                formatLocal: (date) => Intl.DateTimeFormat(locale).format(date)
            },
            priceFormatterFactory: (symbolInfo: LibrarySymbolInfo | null, minTick: string) => {
                const { formatPrice } = getInstrumentFormatter(locale, symbolInfo?.ticker || '');
                return { format: formatPrice };
            }
        },
        timezone,
        auto_save_delay: 1,
        overrides: chartTheme(variables),
        studies_overrides: chartStudiesTheme(variables),
        loading_screen: {
            foregroundColor: variables['--color-brand-primary'],
            backgroundColor: variables['--color-brand-background-dark']
        },
        settings_adapter: {
            initialSettings: {
                ...saved_settings,
                ...(chartTheme(variables) as any),
                ...chartStudiesTheme(variables)
            },
            setValue(key, value) {
                localStorage.setItem(key, value);
            },
            removeValue(key) {
                localStorage.removeItem(key);
            }
        }
    };
};

export const chartStudiesTheme = (variables: CSSVariables): StudyOverrides => ({
    // 'volume.volume.color.0': variables['--color-brand-red'],
    // 'volume.volume.color.1': variables['--color-brand-primary'],
    // 'volume.volume.transparency': 70,
    // 'volume.volume ma.color': variables['--color-brand-neutral'],
    // 'volume.volume ma.transparency': 30,
    // 'volume.volume ma.linewidth': 5,
    // 'volume.show ma': true,
    // 'bollinger bands.median.color': variables['--color-brand-primary'],
    // 'bollinger bands.upper.linewidth': 7,
    // 'bollinger bands.upper.color': variables['--color-brand-primary'],
    // 'bollinger bands.lower.linewidth': 7,
    // 'bollinger bands.lower.color': variables['--color-brand-primary'],
    // 'bollinger bands.upper.transparency': 70,
    // 'bollinger bands.lower.transparency': 70,
    // 'bollinger bands.type': 'simple',
    // 'bollinger bands.ma': 'EMA',
    // 'bollinger bands.linewidth': 1,
    // 'bollinger bands.color': variables['--color-brand-primary'],
    // 'bollinger bands.transparency': 70,
    // 'bollinger bands.show': true,
    // 'bollinger bands.fill': 0,
    // 'bollinger bands.fill.color.0': variables['--color-brand-primary'],
    // 'bollinger bands.fill.color.1': variables['--color-brand-primary'],
    // 'bollinger bands.hband.fill.color.0': variables['--color-brand-primary'],
    // 'bollinger bands.hband.fill.color.1': variables['--color-brand-primary'],
    // 'bollinger bands.plots background.color': 'RGB(19, 132, 132, 0.05)',
    // 'parabolic sar.plot.color': '#3a6ca8',
    // 'macd.macd.color': '#0493fc',
    // 'macd.signal.color': '#f86a26',
    // 'macd.histogram.plottype': 'columns',
    // 'relative strength index.Plot.color': '#904599',
    // 'relative strength index.hlines background.color': 'RGBA(153,21,255,0.1)',
    // 'moving average exponential.plot.color': '#317407'
});

export const chartTheme = (variables: CSSVariables): Partial<WidgetOverrides> => ({
    'paneProperties.background': variables['--color-brand-background-dark'],
    'paneProperties.backgroundType': 'solid',
    'paneProperties.vertGridProperties.color': variables['--color-brand-neutral-darker-2'],
    'paneProperties.horzGridProperties.color': variables['--color-brand-neutral-darker-2'],
    'paneProperties.vertGridProperties.style': '1',
    'paneProperties.horzGridProperties.style': '1',
    'scalesProperties.textColor': variables['--color-brand-neutral'],
    'scalesProperties.lineColor': variables['--color-brand-neutral-darker-2'],
    'scalesProperties.axisHighlightColor': variables['--color-brand-neutral-darker-2'],
    // Bars
    'mainSeriesProperties.barStyle.upColor': variables['--color-brand-primary'],
    'mainSeriesProperties.barStyle.downColor': variables['--color-brand-red'],
    // Candles
    'mainSeriesProperties.candleStyle.borderUpColor': variables['--color-brand-primary'],
    'mainSeriesProperties.candleStyle.borderDownColor': variables['--color-brand-red'],
    'mainSeriesProperties.candleStyle.wickUpColor': variables['--color-brand-primary'],
    'mainSeriesProperties.candleStyle.wickDownColor': variables['--color-brand-red'],
    'mainSeriesProperties.candleStyle.upColor': variables['--color-brand-primary'],
    'mainSeriesProperties.candleStyle.downColor': variables['--color-brand-red'],
    // Columns
    'mainSeriesProperties.columnStyle.upColor': variables['--color-brand-primary-dark'],
    'mainSeriesProperties.columnStyle.downColor': variables['--color-brand-red-dark'],
    // Line
    'mainSeriesProperties.baselineStyle.topLineColor': variables['--color-brand-primary'],
    'mainSeriesProperties.baselineStyle.bottomLineColor': variables['--color-brand-red'],
    // haStyle
    'mainSeriesProperties.haStyle.borderUpColor': variables['--color-brand-primary'],
    'mainSeriesProperties.haStyle.borderDownColor': variables['--color-brand-red'],
    'mainSeriesProperties.haStyle.wickUpColor': variables['--color-brand-primary'],
    'mainSeriesProperties.haStyle.wickDownColor': variables['--color-brand-red'],
    'mainSeriesProperties.haStyle.upColor': variables['--color-brand-primary'],
    'mainSeriesProperties.haStyle.downColor': variables['--color-brand-red'],
    // hlcAreaStyle
    'mainSeriesProperties.hlcAreaStyle.highLineColor': variables['--color-brand-primary'],
    'mainSeriesProperties.hlcAreaStyle.lowLineColor': variables['--color-brand-red'],
    'mainSeriesProperties.hlcAreaStyle.closeLineColor': '#2962FF',
    // hollowCandleStyle
    'mainSeriesProperties.hollowCandleStyle.borderUpColor': variables['--color-brand-primary'],
    'mainSeriesProperties.hollowCandleStyle.borderDownColor': variables['--color-brand-red'],
    'mainSeriesProperties.hollowCandleStyle.wickUpColor': variables['--color-brand-primary'],
    'mainSeriesProperties.hollowCandleStyle.wickDownColor': variables['--color-brand-red'],
    'mainSeriesProperties.hollowCandleStyle.upColor': variables['--color-brand-primary'],
    'mainSeriesProperties.hollowCandleStyle.downColor': variables['--color-brand-red'],
    // kagiStyle
    'mainSeriesProperties.kagiStyle.upColor': variables['--color-brand-primary'],
    'mainSeriesProperties.kagiStyle.downColor': variables['--color-brand-red'],
    // pbStyle
    'mainSeriesProperties.pbStyle.borderUpColor': variables['--color-brand-primary'],
    'mainSeriesProperties.pbStyle.borderDownColor': variables['--color-brand-red'],
    'mainSeriesProperties.pbStyle.upColor': variables['--color-brand-primary'],
    'mainSeriesProperties.pbStyle.downColor': variables['--color-brand-red'],
    // pnfStyle
    'mainSeriesProperties.pnfStyle.upColor': variables['--color-brand-primary'],
    'mainSeriesProperties.pnfStyle.downColor': variables['--color-brand-red'],
    // renkoStyle
    'mainSeriesProperties.renkoStyle.borderUpColor': variables['--color-brand-primary'],
    'mainSeriesProperties.renkoStyle.borderDownColor': variables['--color-brand-red'],
    'mainSeriesProperties.renkoStyle.wickUpColor': variables['--color-brand-primary'],
    'mainSeriesProperties.renkoStyle.wickDownColor': variables['--color-brand-red'],
    'mainSeriesProperties.renkoStyle.upColor': variables['--color-brand-primary'],
    'mainSeriesProperties.renkoStyle.downColor': variables['--color-brand-red']
});
