import { DEFAULT_MOBILE_LAYOUT, XplorTraderModules, xplorTraderTabIcon } from '@/config/xplorTraderLayout';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import {
    selectXplorTraderMobileLayoutNode,
    setXplorTraderMobileLayoutNode
} from '@/state/reducers/xplorTraderLayoutSlice';
import cn from 'classnames';
import { useMemo } from 'react';
import { IconType } from 'react-icons/lib';
import { updateTree } from 'react-mosaic-component';

const tabs: {
    label: string;
    name: XplorTraderModules;
    Icon: IconType;
}[] = [
    { label: 'Tickers', name: 'Tickers', Icon: xplorTraderTabIcon['Tickers'] },
    { label: 'Blotters', name: 'Blotter', Icon: xplorTraderTabIcon['Blotter'] },
    { label: 'Account Value', name: 'Account Value', Icon: xplorTraderTabIcon['Account Value'] },
    { label: 'Chart', name: 'Chart', Icon: xplorTraderTabIcon['Chart'] }
];

const TraderMobileTabNavigation = () => {
    const dispatch = useAppDispatch();
    const mobileLayoutNode = useAppSelector(selectXplorTraderMobileLayoutNode) as any;
    const layout = useMemo(() => (mobileLayoutNode ? mobileLayoutNode : DEFAULT_MOBILE_LAYOUT), [mobileLayoutNode]);

    return (
        <div className="w-full h-full grid grid-flow-col auto-cols-fr divide-x-0 2xs:divide-x 2xs:divide-neutral-700 bg-brand-background-dark">
            {tabs.map((tab, index) => (
                <TabButton
                    key={tab.name}
                    Icon={tab.Icon}
                    isActive={layout?.second === tab.name}
                    onClick={() => switchTab(layout, tab.name, dispatch)}>
                    {tab.label}
                </TabButton>
            ))}
        </div>
    );
};

export default TraderMobileTabNavigation;

const TabButton = ({ Icon, isActive, onClick, children }: any) => {
    return (
        <button
            className={cn('h-full flex flex-col items-center justify-center gap-0.5 p-2 min-w-[42px]', {
                ['text-brand-primary']: isActive,
                ['text-neutral-200']: !isActive
            })}
            onClick={onClick}>
            <div>
                <Icon className="w-4 h-4" />
            </div>
            <span className="text-2xs 2xs:text-xs">{children}</span>
        </button>
    );
};

const switchTab = (mobileLayoutNode, moduleToDrop, dispatch) => {
    let currentNode = mobileLayoutNode;
    if (currentNode) {
        currentNode = updateTree(currentNode, [
            {
                path: [],
                spec: {
                    $set: {
                        direction: 'row',
                        first: '',
                        second: moduleToDrop,
                        splitPercentage: 0
                    }
                }
            }
        ]);
    } else {
        currentNode = moduleToDrop;
    }
    dispatch(setXplorTraderMobileLayoutNode(currentNode));
};
