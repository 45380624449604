import { useDisclosure } from '@/utils/hooks/useDisclosure';
import { Dialog, Tab, Transition } from '@headlessui/react';
import cn from 'classnames';
import { Fragment, ReactElement, ReactNode } from 'react';
import { MdClose } from 'react-icons/md';

type Children = ReactElement | ReactElement[] | JSX.Element | JSX.Element[] | string | string[];

interface EntryProps {
    title: string;
    value: number | string | ReactNode;
    hidden?: boolean;
}

interface InfoGroupProps {
    title?: string;
    children: Children;
}

interface InfoSubGroupProps {
    className?: string;
    labelClassName?: string;
    valueClassName?: string;
    details: Record<
        string,
        {
            value?: number | string;
            element?: Children;
            className?: string;
            hidden?: boolean;
            allowCopy?: boolean;
            copyValue?: number | string;
        }
    >;
}

export interface DrawerProps {
    title?: string;
    className?: string;
    children?: Children;
    disabled?: boolean;
}

export interface DrawerMainProps extends DrawerProps {
    opened: ReturnType<typeof useDisclosure>[0];
    handlers: ReturnType<typeof useDisclosure>[1];
}

export const defaultDrawerWidth = 'max-w-md';
export const defaultDrawerBodyHeight = 'sm:h-[450px]';
export const defaultPanelsClassName = 'overflow-hidden sm:overflow-visible flex-1 basis-0 sm:flex-auto';
export const drawerBodyClassName = 'flex flex-col h-full w-full overflow-y-auto';
export const drawerSubBodyClassName = 'flex flex-col gap-2 p-2 sm:p-4';

const Drawer = {
    Main: ({ opened, handlers, className, children }: DrawerMainProps) => {
        return (
            <Transition.Root show={opened} as={Fragment}>
                <Dialog className="relative z-40" onClose={handlers.close}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-150"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-background bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-150 sm:duration-300"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-150 sm:duration-300"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full">
                                    <Dialog.Panel
                                        className={cn('pointer-events-auto relative w-screen', {
                                            className,
                                            [defaultDrawerWidth]: !className
                                        })}>
                                        <div className="relative flex h-full flex-col bg-backgroundDark shadow-xl">
                                            <Transition.Child
                                                as={Fragment}
                                                enter="ease-in-out duration-150"
                                                enterFrom="opacity-0"
                                                enterTo="opacity-100"
                                                leave="ease-in-out duration-150"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0">
                                                <button
                                                    type="button"
                                                    className="absolute right-4 top-5 rounded-md text-neutral-200 hover:text-white-full focus:outline-none"
                                                    onClick={handlers.close}>
                                                    <MdClose className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </Transition.Child>
                                            {children}
                                            {/* <div className="px-4 sm:px-6">
                                                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                    Panel title
                                                </Dialog.Title>
                                            </div>
                                            <div className="relative mt-6 flex-1 px-4 sm:px-6">{children}</div> */}
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        );
    },

    Header: ({ children }: DrawerProps) => <div className="flex text-neutral-200 p-2 sm:p-4">{children}</div>,

    Title: ({ children }: DrawerProps) => (
        <div className="px-4 sm:px-6 py-5 bg-primaryDark">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">{children}</Dialog.Title>
        </div>
    ),

    Body: ({ children, className }: DrawerProps) => {
        return (
            <div
                className={cn(drawerBodyClassName, {
                    className
                })}>
                {children}
            </div>
        );
    },

    SubBody: ({ children, className }: DrawerProps) => {
        return (
            <div
                className={cn(drawerSubBodyClassName, {
                    className
                })}>
                {children}
            </div>
        );
    },

    Section: ({ title, children, className }: DrawerProps) => {
        return (
            <div className={className}>
                <Drawer.SectionHeader>{title}</Drawer.SectionHeader>
                <Drawer.SectionBody className={className}>{children}</Drawer.SectionBody>
            </div>
        );
    },

    SectionHeader: ({ children, className }: DrawerProps) => {
        return (
            <div className={cn('p-2 px-6 bg-primaryDarker text-neutral-300 text-sm font-medium', className)}>
                {children}
            </div>
        );
    },

    SectionBody: ({ children, className }: DrawerProps) => {
        return <div className={cn('', className)}>{children}</div>;
    },

    Entry: ({ title, value, hidden = false }: EntryProps) => {
        return (
            <Fragment>
                {!hidden && (
                    <div className="flex flex-col gap-1 text-neutral-200 w-full border-b border-neutral-700 px-6 py-2 text-xs">
                        <div className="text-neutral-300 font-medium">{title}</div>
                        <span className="block w-full break-all">{value || '-'}</span>
                    </div>
                )}
            </Fragment>
        );
    },

    InfoGroup: ({ title, children }: InfoGroupProps) => {
        return (
            <div
                className={cn('flex flex-col gap-3 text-neutral-200 w-full border-b border-neutral-700 px-6 pb-4', {
                    'pt-2': title,
                    'pt-4': !title
                })}>
                {title && <div className="text-neutral-300 font-medium text-sm">{title}</div>}
                <div className="flex flex-wrap gap-3 justify-between text-xs">{children}</div>
            </div>
        );
    },

    InfoSubGroup: ({ className, labelClassName, valueClassName, details }: InfoSubGroupProps) => {
        const keys = Object.keys(details).filter((key) => !details[key].hidden);

        return (
            <div
                className={cn('flex flex-col gap-1 text-xs', {
                    ['flex-1 basis-0']: !className,
                    className
                })}>
                {keys.map((key) => (
                    <div
                        key={key}
                        className={cn('flex min-h-[24.2px]', {
                            ['gap-1.5']: details[key].allowCopy,
                            ['gap-1']: !details[key].allowCopy
                        })}>
                        <div
                            className={cn('flex flex-col gap-2', {
                                [`${details[key].className}`]: details[key].className,
                                ['pt-[5px]']: !details[key].className
                            })}>
                            <div className="flex gap-1">
                                <div
                                    className={cn(
                                        'text-neutral-400 text-end',
                                        {
                                            ['w-28']: !labelClassName
                                        },
                                        labelClassName
                                    )}>
                                    {key}
                                </div>
                                <div>:</div>
                            </div>
                        </div>
                        {details[key].value !== undefined && (
                            <div className={cn('flex flex-col gap-2 flex-1 pt-[5px]', {}, valueClassName)}>
                                <span className="">{details[key].value || '-'}</span>
                            </div>
                        )}
                        {details[key].element && details[key].element}
                        {/* {details[key].allowCopy && (
                            <CopyButton value={`${details[key]?.copyValue || details[key].value}`}>
                                {({ copied, copy }) => (
                                    <button
                                        onClick={copy}
                                        className="border border-neutral-500 hover:border-neutral-400 rounded-sm h-fit p-1">
                                        {copied ? (
                                            <MdCheck className="w-3.5 h-3.5 text-neutral-200" />
                                        ) : (
                                            <MdContentCopy className="w-3.5 h-3.5 text-neutral-200" />
                                        )}
                                    </button>
                                )}
                            </CopyButton>
                        )} */}
                    </div>
                ))}
            </div>
        );
    },

    Footer: ({ children }: DrawerProps) => (
        <div className="flex justify-end w-full gap-3 text-neutral-200 text-sm p-2 sm:p-4 flex-wrap">{children}</div>
    ),

    // Cancel: ({ onClose }: { onClose(): void }) => (
    //     <Button type="button" customColorClassName="bg-neutral-600 hover:bg-neutral-500" onClick={onClose}>
    //         Cancel
    //     </Button>
    // ),

    Close: ({ onClose }: { onClose(): void }) => (
        <button type="button" className="text-lg sm:text-xl" onClick={onClose}>
            <MdClose />
        </button>
    ),

    TabList: ({ children }: DrawerProps) => <Tab.List className="flex p-4 h-16 gap-3">{children}</Tab.List>,

    Tab: ({ children, disabled }: DrawerProps) => (
        <Tab
            className={({ selected }) =>
                cn('py-1 px-1.5 text-sm font-medium leading-5 text-neutral-200', {
                    'border-b-2 bg-backgroundDark border-b-neutral-200': selected && !disabled,
                    'text-neutral-400 hover:bg-white/[0.12] hover:text-neutral-200': !selected && !disabled,
                    'text-neutral-500 cursor-not-allowed': disabled
                })
            }
            disabled={disabled}>
            {children}
        </Tab>
    )
};

export default Drawer;
