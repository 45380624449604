import InputController from '@/components/inputs/InputController';
import Select from '@/components/inputs/Select';
import { Tab } from '@headlessui/react';

export const timezoneOptions = [
    { label: 'UTC Timezone', value: 'UTC' },
    { label: 'Local Timezone', value: 'local' }
];

const GeneralTab = () => {
    return (
        <Tab.Panel className="p-2 py-3 sm:p-4 h-auto lg:h-[400px]">
            <div className="space-y-4">
                <div className="space-y-3 sm:space-y-2">
                    <InputController name="general.timezone" label="Timezone Settings" required>
                        <Select options={timezoneOptions} />
                    </InputController>
                    <div className="space-y-3 sm:space-y-1">
                        <div className="text-xs text-neutral-400">
                            Set a timezone default for the trading interface, this can be changed at any time
                        </div>
                        <div className="text-xs text-neutral-400">
                            This will affect the timestamps on order blotters, notifications, and more.
                        </div>
                    </div>
                </div>
            </div>
        </Tab.Panel>
    );
};

export default GeneralTab;
