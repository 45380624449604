import { grpc } from '@improbable-eng/grpc-web';
import { createChannel, createClient, Metadata } from 'nice-grpc-web';
import { Middleware } from 'redux';
import { AppDispatch, RootState } from '../store';

import {
    PiggyBankServiceClient,
    PiggyBankServiceDefinition,
    PiggyBankUpdateEvent
} from '@/compiled_proto/com/celertech/piggybank/api/PiggyBankServiceProto';
import { forEachAsync } from '@/helpers/asyncIterableHelper';
import { getEnvironment } from '@/helpers/environmentHelper';
import { extractBalanceUpdate } from '@/services/utils/BalanceUpdateParser';
import { Logger } from '@/utils/logger';
import { v4 as uuidv4 } from 'uuid';
import { controlClearSubscriptions, controlInitSubscriptions, User } from '../reducers/authSlice';
import { BalanceItem, changeBalanceAccount, setCelerBalance } from '../reducers/balanceSlice';

let subscriptionId: any = null;
let balancesSubscription: any = null;

const isNewBalances = ['uat', 'prod'].includes(getEnvironment());

const setupBalancesSubscription = async (store: any, dispatch: AppDispatch, credentials: User) => {
    // Subscribe for changes to balances
    balancesSubscription = piggybankClient.subscribeForCurrencyPositions(
        {},
        {
            metadata: Metadata({
                'authorization-token': credentials.authToken
            })
        }
    );

    const currentSubscriptionId = uuidv4();
    subscriptionId = currentSubscriptionId;
    forEachAsync(
        balancesSubscription,
        (balanceUpdate: PiggyBankUpdateEvent) => {
            const state: RootState = store.getState();
            const account = state.auth.currentAccount || credentials.accounts[0].code;

            if (!balanceUpdate.isHeartBeat) {
                const generalLedger = balanceUpdate.transactionDownstreamEvent?.generalLedger;
                const subLedger = balanceUpdate.transactionDownstreamEvent?.subLedger;
                if (generalLedger && generalLedger.account == account && subLedger?.length) {
                    const balanceItemUpdates = subLedger.map(extractBalanceUpdate) as BalanceItem[];
                    dispatch(setCelerBalance(balanceItemUpdates));
                }
            }
        },
        () => subscriptionId !== currentSubscriptionId,
        `BalancesMiddleware`
    ).catch((error) => console.error({ 'BalancesMiddleware subscription': error }));

    // Fetch latest balance on init
    const isProd = getEnvironment() === 'prod';
    if (!isProd) {
        await dispatch(changeBalanceAccount({ credentials }));
    }
};

const wsChannelUrl = window.config.integration.celertech.websocket;
const wsChannel = createChannel(wsChannelUrl, grpc.WebsocketTransport());

// Subscribe to balance updates

const piggybankClient: PiggyBankServiceClient = createClient(PiggyBankServiceDefinition, wsChannel);

const BalancesMiddleware: Middleware = (store) => (next) => (action) => {
    if (controlClearSubscriptions.match(action)) {
        Logger({ title: `BalancesMiddleware: Clear Subscriptions`, callback: () => {} });
        subscriptionId = null;
        balancesSubscription = null;
    } else if (controlInitSubscriptions.match(action)) {
        Logger({ title: `BalancesMiddleware: Initialise Subscriptions`, callback: () => {} });
        const dispatch: AppDispatch = store.dispatch;
        const state: RootState = store.getState();
        const creds = state.auth.user;

        if (!isNewBalances) {
            // If not already listening, setup subscriptions
            if (!balancesSubscription && creds) {
                setupBalancesSubscription(store, dispatch, creds);
            }
        }
    }

    // Pass on to next middlewares in line
    next(action);
};

export default BalancesMiddleware;
