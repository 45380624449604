import { Nullable } from '@/model/common';
import { BalanceItem, CollapsedBalances } from '@/state/reducers/balanceSlice';
import { InferType, number, object, string } from 'yup';
import { QuantitySchemaParams } from './common';

export const convertOrderSchema = (
    quantityParams: QuantitySchemaParams,
    shortSellPrevention: { label: string; value: boolean },
    balances: any[]
    // balances: BalanceItem[] | CollapsedBalances[]
) => {
    const { currencyPair, currencyOut, order_decimals, min_order_size } = quantityParams;
    return object({
        pair: string(),
        bestPrice: number(),
        convertFrom: object({ label: string(), ccy: string(), value: string() }).test(
            'valueNotUndefined',
            `Please select a currency`,
            (ccy) => !!ccy.value
        ),
        convertTo: object({ label: string(), ccy: string(), value: string() }).test(
            'valueNotUndefined',
            `Please select a currency`,
            (ccy) => !!ccy.value
        ),
        amount: number()
            .required('Convert Amount cannot be empty!')
            .typeError('Please input a number.')
            .min(0, 'Can not be negative.')
            .min(min_order_size, `The minimum order quantity is ${min_order_size} ${currencyOut}`)
            .test(
                'shortSellPrevention',
                'Short Sell Prevention: The order quantity should not be more than your balance.',
                function (number) {
                    if (shortSellPrevention.value && number) {
                        const { convertFrom } = this.parent;
                        if (convertFrom?.value && balances) {
                            const ccy = balances.find(
                                (balance) => balance.currency === convertFrom.value
                            ) as BalanceItem & CollapsedBalances;
                            const value = ccy?.netPosition || ccy?.totalAmount || 0;
                            return number <= value;
                        }
                    }
                    return true;
                }
            )
            .test(
                'maxDigitsAfterDecimal',
                `The maximum allowed number of decimals for ${currencyPair} is ${order_decimals}`,
                (number) => {
                    const regex = new RegExp(`^\\d+(\\.\\d{0,${order_decimals}})?$`, 'gm');
                    return regex.test((number || '').toString());
                }
            )
            .test('emptyAmount', `Amount can not be empty`, function (value) {
                return value !== 0;
            }),
        // .test('invalidBalance', `Insufficient balance for conversion`, function (value) {
        //     const { convertFrom } = this.parent;
        //     let availableAmount;
        //     if (convertFrom?.value && balances) {
        //         const ccy = balances.find((balance) => balance.currency === convertFrom.value);
        //         const value = ccy?.netPosition;
        //         availableAmount = value || 0;
        //     } else {
        //         availableAmount = 'No Currency/Balance';
        //     }

        //     return availableAmount !== 'No Currency/Balance' && !(availableAmount < +(value || 0));
        // })
        estimatedReturn: number(),
        side: number()
    });
};

export type ConvertOrderFormValues = InferType<ReturnType<typeof convertOrderSchema>>;
export type ConvertOrderFormInput = Nullable<ConvertOrderFormValues>;
