import cn from 'classnames';
import { ComponentProps, ReactElement, cloneElement, forwardRef } from 'react';

interface Props extends ComponentProps<'textarea'> {
    icon?: ReactElement;
    className?: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
    return (
        <div className="w-full relative">
            <textarea
                {...props}
                ref={ref}
                autoComplete="off"
                className={cn(
                    'appearance-none block w-full px-3 py-2 bg-brand-background-dark border border-neutral-700 rounded-md shadow-sm text-sm text-neutral-200 placeholder-neutral-500 focus:outline-none focus:ring-primary focus:border-primary',
                    { 'pr-8': props.icon },
                    props.className
                )}
            />
            {props.icon && cloneElement(props.icon, { className: 'absolute right-2 top-2 w-5 h-5 text-neutral-500' })}
        </div>
    );
});

TextArea.displayName = 'TextArea';

export default TextArea;
