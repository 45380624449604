// import Footer from './Footer';
// import UserMarket from '../UserMarket';
import Navbar from '../Navbar';
import TraderFooter from './TraderFooter';
import TraderMobileSubHeader from './TraderMobileSubHeader';

interface TraderLayoutProps extends React.PropsWithChildren {}

export default function TraderLayout({ children }: TraderLayoutProps) {
    return (
        <div className="flex flex-col">
            <header className="w-full flex-col">
                <Navbar />
            </header>
            <TraderMobileSubHeader />
            <main className="xplortrader-layout">{children}</main>
            <TraderFooter />
        </div>
    );
}
