/* eslint-disable */
import { OrderType } from '../enums/OrderTypeProto';
import { StrategyType } from '../enums/StrategyTypeProto';
import { AccountType } from '../enums/AccountTypeProto';
import { Side } from '../enums/SideProto';
import { OrderStatus } from '../enums/OrderStatusProto';
import { ExecutionType } from '../enums/ExecutionTypeProto';
import { TimeInForceType } from '../enums/TimeInForceTypeProto';
import { HandlingInstruction } from '../enums/HandlingInstructionProto';
import { ProductType } from '../enums/ProductTypeProto';
import { OptionType } from '../enums/OptionTypeProto';
import { ExecutionInstruction } from '../enums/ExecutionInstructionProto';
import { PegPriceType } from '../enums/PegPriceTypeProto';
import { CancelRejectResponseTo } from '../enums/CancelRejectResponseToProto';
import { CancelRejectReason } from '../enums/CancelRejectReasonProto';
import { PartyIdSource } from '../enums/PartyIdSourceProto';
import { PartyRole } from '../enums/PartyRoleProto';
import Long from 'long';
import { KeyValue } from './OrderMetadata';
import { OrderIdData } from './OrderIds';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.orderrouting.api.order';

export interface FutureOrderSnapshotDownstreamEvent {
    id: string;
    orderId: string;
    externalClOrderId: string;
    originalExternalClOrderId: string;
    externalOrderId: string;
    secondaryExecId: string;
    exchangeCode: string;
    securityCode: string;
    securityId: string;
    securityIdSource: string;
    externalSecurityCode: string;
    externalSecurityId: string;
    orderType: OrderType;
    strategyType: StrategyType;
    expiry: string;
    accountType: AccountType;
    account: string;
    side: Side;
    qty: number;
    price: number;
    currency: string;
    trader: string;
    broker: string;
    giveUp: string;
    giveIn: string;
    lastPx: number;
    lastQty: number;
    cumQty: number;
    leavesQty: number;
    avgPx: number;
    orderStatus: OrderStatus;
    executionType: ExecutionType;
    orderText: string;
    info: string;
    timeInForce: TimeInForceType;
    timeInForceExpiry: string;
    handlingInstruction: HandlingInstruction;
    isChildOrder: boolean;
    parentOrderId: string;
    orderVersion: number;
    isParentOrder: boolean;
    celerClOrderId: string;
    originalCelerClOrderId: string;
    leg: FutureLeg[];
    createdTimestampUtcInMillis: string;
    updatedTimestampUtcInMillis: string;
    onBehalfOfCompId: string;
    deliverToCompId: string;
    externalExchangeCode: string;
    minQty: number;
    maxFloor: number;
    quoteId: string;
    refId: string;
    bookAsFilled: boolean;
    destinationKey: string;
    algoType: string;
    commission: number;
    tradeAllocGroup: OrderAllocGroup[];
    tradePartiesGroup: OrderPartiesGroup[];
    orderMetadata: KeyValue[];
    orderSource: string;
    accountName: string;
    stopPx: number;
    productType: ProductType;
    sourceExecutionId: string;
    destinationBackOfficeKey: string;
}

export interface FutureLeg {
    legNumber: number;
    underlyingExchangeCode: string;
    underlyingSecurityCode: string;
    underlyingSecurityId: string;
    underlyingExpiry: string;
    legRatioQty: number;
    side: Side;
    legPrice: number;
    legLastPx: number;
    /** Add settlementType(i.e. tenor) for Future asset type. e.g. 3M forward, 3rd Wed date base metals. */
    settlementType: string;
}

export interface OptionOrderSnapshotDownstreamEvent {
    id: string;
    orderId: string;
    externalClOrderId: string;
    originalExternalClOrderId: string;
    externalOrderId: string;
    secondaryExecId: string;
    exchangeCode: string;
    securityCode: string;
    securityId: string;
    securityIdSource: string;
    externalSecurityCode: string;
    externalSecurityId: string;
    orderType: OrderType;
    expiry: string;
    strike: number;
    accountType: AccountType;
    account: string;
    side: Side;
    qty: number;
    price: number;
    currency: string;
    trader: string;
    broker: string;
    giveUp: string;
    giveIn: string;
    lastPx: number;
    lastQty: number;
    cumQty: number;
    leavesQty: number;
    avgPx: number;
    orderStatus: OrderStatus;
    executionType: ExecutionType;
    optionType: OptionType;
    orderText: string;
    info: string;
    handlingInstruction: HandlingInstruction;
    parentOrderId: string;
    orderVersion: number;
    isParentOrder: boolean;
    celerClOrderId: string;
    originalCelerClOrderId: string;
    isChildOrder: boolean;
    createdTimestampUtcInMillis: string;
    updatedTimestampUtcInMillis: string;
    onBehalfOfCompId: string;
    deliverToCompId: string;
    externalExchangeCode: string;
    timeInForce: TimeInForceType;
    timeInForceExpiry: string;
    minQty: number;
    maxFloor: number;
    quoteId: string;
    refId: string;
    bookAsFilled: boolean;
    destinationKey: string;
    algoType: string;
    commission: number;
    tradeAllocGroup: OrderAllocGroup[];
    tradePartiesGroup: OrderPartiesGroup[];
    orderMetadata: KeyValue[];
    orderSource: string;
    accountName: string;
    stopPx: number;
    productType: ProductType;
    sourceExecutionId: string;
    destinationBackOfficeKey: string;
}

export interface FxOrderSnapshotDownstreamEvent {
    id: string;
    orderId: string;
    externalClOrderId: string;
    originalExternalClOrderId: string;
    externalOrderId: string;
    secondaryExecId: string;
    exchangeCode: string;
    securityCode: string;
    securityId: string;
    securityIdSource: string;
    externalSecurityCode: string;
    externalSecurityId: string;
    orderType: OrderType;
    strategyType: StrategyType;
    accountType: AccountType;
    account: string;
    side: Side;
    qty: number;
    price: number;
    currency: string;
    trader: string;
    broker: string;
    giveUp: string;
    giveIn: string;
    lastPx: number;
    lastQty: number;
    cumQty: number;
    leavesQty: number;
    avgPx: number;
    orderStatus: OrderStatus;
    executionType: ExecutionType;
    orderText: string;
    info: string;
    handlingInstruction: HandlingInstruction;
    parentOrderId: string;
    orderVersion: number;
    isParentOrder: boolean;
    celerClOrderId: string;
    originalCelerClOrderId: string;
    isChildOrder: boolean;
    /** the createdTimestampUtc is the create time of the original order, NOT the create time of the snapshot generated this event */
    createdTimestampUtcInMillis: string;
    /** the updatedTimestamp is the updatedTime of the order which is the create time of the snapshot generated this event */
    updatedTimestampUtcInMillis: string;
    onBehalfOfCompId: string;
    deliverToCompId: string;
    externalExchangeCode: string;
    timeInForce: TimeInForceType;
    timeInForceExpiry: string;
    minQty: number;
    maxFloor: number;
    quoteId: string;
    settlementDate: string;
    checkpointTimestamp: string[];
    refId: string;
    bookAsFilled: boolean;
    productType: ProductType;
    destinationKey: string;
    algoType: string;
    commission: number;
    leg: FxLeg[];
    tradeAllocGroup: OrderAllocGroup[];
    tradePartiesGroup: OrderPartiesGroup[];
    settlementType: string;
    splitValueDate: boolean;
    splitLegCurrency1: string;
    splitLegCurrency2: string;
    splitLegCurrency1SettlementDate: string;
    splitLegCurrency2SettlementDate: string;
    orderMetadata: KeyValue[];
    orderSource: string;
    settlCurrency: string;
    rateSource: string;
    accountName: string;
    stopPx: number;
    sourceExecutionId: string;
    destinationBackOfficeKey: string;
    executionInstruction: ExecutionInstruction;
    maxShow: number;
    /** Type of pegged order */
    pegPriceType: PegPriceType;
    lastActionedBy: string;
}

export interface FxLeg {
    underlyingExchangeCode: string;
    underlyingSecurityCode: string;
    underlyingSecurityId: string;
    settlementDate: string;
    qty: number;
    side: Side;
    price: number;
    legForwardPts: number;
    spotPrice: number;
    settlementType: string;
    /**
     * Maturity of the quote; used for quote requests related to FIXED_INCOME
     * Fixing date for NON_DELIVERABLE_FORWARD
     */
    maturityDate: string;
}

export interface CryptoOrderSnapshotDownstreamEvent {
    id: string;
    orderId: string;
    externalClOrderId: string;
    originalExternalClOrderId: string;
    externalOrderId: string;
    secondaryExecId: string;
    exchangeCode: string;
    securityCode: string;
    securityId: string;
    securityIdSource: string;
    externalSecurityCode: string;
    externalSecurityId: string;
    orderType: OrderType;
    strategyType: StrategyType;
    accountType: AccountType;
    account: string;
    side: Side;
    qty: number;
    price: number;
    currency: string;
    trader: string;
    broker: string;
    giveUp: string;
    giveIn: string;
    lastPx: number;
    lastQty: number;
    cumQty: number;
    leavesQty: number;
    avgPx: number;
    orderStatus: OrderStatus;
    executionType: ExecutionType;
    orderText: string;
    info: string;
    handlingInstruction: HandlingInstruction;
    parentOrderId: string;
    orderVersion: number;
    isParentOrder: boolean;
    celerClOrderId: string;
    originalCelerClOrderId: string;
    isChildOrder: boolean;
    createdTimestampUtcInMillis: string;
    updatedTimestampUtcInMillis: string;
    onBehalfOfCompId: string;
    deliverToCompId: string;
    externalExchangeCode: string;
    timeInForce: TimeInForceType;
    timeInForceExpiry: string;
    minQty: number;
    maxFloor: number;
    quoteId: string;
    settlementDate: string;
    checkpointTimestamp: string[];
    refId: string;
    bookAsFilled: boolean;
    productType: ProductType;
    destinationKey: string;
    algoType: string;
    commission: number;
    leg: CryptoLeg[];
    tradeAllocGroup: OrderAllocGroup[];
    tradePartiesGroup: OrderPartiesGroup[];
    settlementType: string;
    splitValueDate: boolean;
    splitLegCurrency1: string;
    splitLegCurrency2: string;
    splitLegCurrency1SettlementDate: string;
    splitLegCurrency2SettlementDate: string;
    orderMetadata: KeyValue[];
    orderSource: string;
    settlCurrency: string;
    rateSource: string;
    accountName: string;
    stopPx: number;
    destinationBackOfficeKey: string;
}

export interface CryptoLeg {
    underlyingExchangeCode: string;
    underlyingSecurityCode: string;
    underlyingSecurityId: string;
    settlementDate: string;
    qty: number;
    side: Side;
    price: number;
    legForwardPts: number;
    spotPrice: number;
    settlementType: string;
    /**
     * Maturity of the quote; used for quote requests related to FIXED_INCOME
     * Fixing date for NON_DELIVERABLE_FORWARD
     */
    maturityDate: string;
}

export interface FixedIncomeOrderSnapshotDownstreamEvent {
    id: string;
    orderId: string;
    externalClOrderId: string;
    originalExternalClOrderId: string;
    externalOrderId: string;
    secondaryExecId: string;
    exchangeCode: string;
    securityCode: string;
    securityId: string;
    securityIdSource: string;
    externalSecurityCode: string;
    externalSecurityId: string;
    orderType: OrderType;
    strategyType: StrategyType;
    accountType: AccountType;
    account: string;
    side: Side;
    qty: number;
    price: number;
    currency: string;
    trader: string;
    broker: string;
    giveUp: string;
    giveIn: string;
    lastPx: number;
    lastQty: number;
    cumQty: number;
    leavesQty: number;
    avgPx: number;
    orderStatus: OrderStatus;
    executionType: ExecutionType;
    orderText: string;
    info: string;
    handlingInstruction: HandlingInstruction;
    parentOrderId: string;
    orderVersion: number;
    isParentOrder: boolean;
    celerClOrderId: string;
    originalCelerClOrderId: string;
    isChildOrder: boolean;
    createdTimestampUtcInMillis: string;
    updatedTimestampUtcInMillis: string;
    onBehalfOfCompId: string;
    deliverToCompId: string;
    externalExchangeCode: string;
    timeInForce: TimeInForceType;
    timeInForceExpiry: string;
    minQty: number;
    maxFloor: number;
    quoteId: string;
    settlementDate: string;
    maturityDate: string;
    checkpointTimestamp: string[];
    refId: string;
    bookAsFilled: boolean;
    productType: ProductType;
    destinationKey: string;
    algoType: string;
    commission: number;
    tradeAllocGroup: OrderAllocGroup[];
    tradePartiesGroup: OrderPartiesGroup[];
    settlementType: string;
    orderMetadata: KeyValue[];
    orderSource: string;
    accountName: string;
    sourceExecutionId: string;
    destinationBackOfficeKey: string;
}

export interface EquityOrderSnapshotDownstreamEvent {
    id: string;
    orderId: string;
    externalClOrderId: string;
    originalExternalClOrderId: string;
    externalOrderId: string;
    secondaryExecId: string;
    exchangeCode: string;
    securityCode: string;
    securityId: string;
    securityIdSource: string;
    externalSecurityCode: string;
    externalSecurityId: string;
    orderType: OrderType;
    accountType: AccountType;
    account: string;
    side: Side;
    qty: number;
    price: number;
    currency: string;
    trader: string;
    broker: string;
    giveUp: string;
    giveIn: string;
    lastPx: number;
    lastQty: number;
    cumQty: number;
    leavesQty: number;
    avgPx: number;
    orderStatus: OrderStatus;
    executionType: ExecutionType;
    orderText: string;
    info: string;
    handlingInstruction: HandlingInstruction;
    parentOrderId: string;
    orderVersion: number;
    isParentOrder: boolean;
    celerClOrderId: string;
    originalCelerClOrderId: string;
    isChildOrder: boolean;
    createdTimestampUtcInMillis: string;
    updatedTimestampUtcInMillis: string;
    onBehalfOfCompId: string;
    deliverToCompId: string;
    externalExchangeCode: string;
    timeInForce: TimeInForceType;
    timeInForceExpiry: string;
    minQty: number;
    maxFloor: number;
    quoteId: string;
    refId: string;
    bookAsFilled: boolean;
    destinationKey: string;
    algoType: string;
    checkpointTimestamp: string[];
    tradeAllocGroup: OrderAllocGroup[];
    tradePartiesGroup: OrderPartiesGroup[];
    orderMetadata: KeyValue[];
    orderSource: string;
    accountName: string;
    stopPx: number;
    productType: ProductType;
    sourceExecutionId: string;
    destinationBackOfficeKey: string;
}

export interface OrderRoutingDestinationCommandDownstreamEvent {
    operation: OrderRoutingDestinationCommandDownstreamEvent_Operation;
    orderRoutingProviderName: string;
    exchangeName: string;
}

export enum OrderRoutingDestinationCommandDownstreamEvent_Operation {
    START = 0,
    STOP = 1,
    EOD = 2,
    UNRECOGNIZED = -1
}

export interface CancelOrderRequestRejectDownstreamEvent {
    cancelRejectResponseTo: CancelRejectResponseTo;
    cancelRejectReason: CancelRejectReason;
    orderId: string;
    externalOrderId: string;
    externalClOrderId: string;
    originalExternalClOrderId: string;
    orderStatus: OrderStatus;
    qty: number;
    price: number;
    leavesQty: number;
    text: string;
    onBehalfOfCompId: string;
    deliverToCompId: string;
    handlingInstruction: HandlingInstruction;
    isParentOrder: boolean;
    isChildOrder: boolean;
    trader: string;
    createdTimestampUtcInMillis: string;
    orderVersion: number;
}

export interface CreateOrderRequestRejectDownstreamEvent {
    refSequenceNum: string;
    refMessageType: string;
    rejectReason: string;
    onBehalfOfCompId: string;
    /** The fields below are to allow executionReport rejects with additional fields. */
    externalClOrderId: string;
    side: Side;
    securityCode: string;
    securityId: string;
    securityIdSource: string;
    externalSecurityCode: string;
    externalSecurityId: string;
    qty: number;
    orderType: OrderType;
    price: number;
    timeInForce: TimeInForceType;
    handlingInstruction: HandlingInstruction;
    createdTimestampUtcInMillis: string;
    exchangeCode: string;
    externalExchangeCode: string;
    execId: string;
    deliverToCompId: string;
    productType: ProductType;
    currencyCode: string;
    securityType: string;
    settlementType: string;
    settlementDate: string;
    account: string;
    orderMetadata: KeyValue[];
    fixingDate: string;
}

export interface RemoveOrdersFromScreenNotification {
    orderId: string[];
}

export interface TradeAmendmentNotificationDownstreamEvent {
    orderId: string;
    message: string;
    statusType: TradeAmendmentNotificationDownstreamEvent_StatusType;
}

export enum TradeAmendmentNotificationDownstreamEvent_StatusType {
    SUCCESSFUL = 0,
    UNSUCCESSFUL = 1,
    UNRECOGNIZED = -1
}

export interface OrderAllocGroup {
    allocAccount: string;
    allocQty: number;
    allocSettlementDate: string;
    allocSide: Side;
}

export interface OrderPartiesGroup {
    partyId: string;
    partyIdSource: PartyIdSource;
    partyRole: PartyRole;
}

export interface CancelTradeRequestReject {
    id: string;
    cancelRejectReason: CancelRejectReason;
    orderId: string;
}

export interface OrderStatusRequestRejectDownstreamEvent {
    orderId: string;
    clOrderId: string;
    message: string;
    requestedByUser: string;
}

export interface DontKnowTradeDownstreamEvent {
    securityId: string;
    referenceExecutionId: string;
    text: string;
    side: string;
    orderId: string;
}

export interface TradeAggregationNotificationDownstreamEvent {
    clientReportId: string;
    orderIdData: OrderIdData[];
    message: string;
    statusType: TradeAggregationNotificationDownstreamEvent_StatusType;
}

export enum TradeAggregationNotificationDownstreamEvent_StatusType {
    SUCCESSFUL = 0,
    UNSUCCESSFUL = 1,
    UNRECOGNIZED = -1
}

export interface PassiveOrderMigrationStatusDownstreamEvent {
    orderRoutingInfo: OrderRoutingInfo[];
}

export interface OrderMigrationGatewayDownstreamEvent {
    clientRequestId: string;
    active: boolean;
}

export interface OrderRoutingInfo {
    orderSnapshot?: FxOrderSnapshotDownstreamEvent;
    orderId: string;
    reason: string;
    status: OrderRoutingInfo_RoutingStatus;
    destination: string;
    newOutgoingOrderId: string;
}

export enum OrderRoutingInfo_RoutingStatus {
    PENDING_NEW = 0,
    NEW = 1,
    PENDING = 2,
    COMPLETED = 3,
    FAILED = 4,
    PULL_BACK_REQUESTED = 5,
    FILLED = 6,
    PART_FILLED = 7,
    REPLACED = 8,
    DESTINATION_UNAVAILABLE = 9,
    TRADE = 10,
    CANCELLED = 11,
    REJECTED = 12,
    EXPIRED = 13,
    REQUESTED = 14,
    UNRECOGNIZED = -1
}

function createBaseFutureOrderSnapshotDownstreamEvent(): FutureOrderSnapshotDownstreamEvent {
    return {
        id: '0',
        orderId: '0',
        externalClOrderId: '',
        originalExternalClOrderId: '',
        externalOrderId: '',
        secondaryExecId: '',
        exchangeCode: '',
        securityCode: '',
        securityId: '',
        securityIdSource: '',
        externalSecurityCode: '',
        externalSecurityId: '',
        orderType: 1,
        strategyType: 1,
        expiry: '',
        accountType: 1,
        account: '',
        side: 1,
        qty: 0,
        price: 0,
        currency: '',
        trader: '',
        broker: '',
        giveUp: '',
        giveIn: '',
        lastPx: 0,
        lastQty: 0,
        cumQty: 0,
        leavesQty: 0,
        avgPx: 0,
        orderStatus: 1,
        executionType: 1,
        orderText: '',
        info: '',
        timeInForce: 1,
        timeInForceExpiry: '',
        handlingInstruction: 1,
        isChildOrder: false,
        parentOrderId: '0',
        orderVersion: 0,
        isParentOrder: false,
        celerClOrderId: '',
        originalCelerClOrderId: '0',
        leg: [],
        createdTimestampUtcInMillis: '0',
        updatedTimestampUtcInMillis: '0',
        onBehalfOfCompId: '',
        deliverToCompId: '',
        externalExchangeCode: '',
        minQty: 0,
        maxFloor: 0,
        quoteId: '',
        refId: '0',
        bookAsFilled: false,
        destinationKey: '',
        algoType: '',
        commission: 0,
        tradeAllocGroup: [],
        tradePartiesGroup: [],
        orderMetadata: [],
        orderSource: '',
        accountName: '',
        stopPx: 0,
        productType: 1,
        sourceExecutionId: '',
        destinationBackOfficeKey: ''
    };
}

export const FutureOrderSnapshotDownstreamEvent = {
    encode(message: FutureOrderSnapshotDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== '0') {
            writer.uint32(8).int64(message.id);
        }
        if (message.orderId !== '0') {
            writer.uint32(16).int64(message.orderId);
        }
        if (message.externalClOrderId !== '') {
            writer.uint32(26).string(message.externalClOrderId);
        }
        if (message.originalExternalClOrderId !== '') {
            writer.uint32(34).string(message.originalExternalClOrderId);
        }
        if (message.externalOrderId !== '') {
            writer.uint32(42).string(message.externalOrderId);
        }
        if (message.secondaryExecId !== '') {
            writer.uint32(50).string(message.secondaryExecId);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(58).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(66).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(74).string(message.securityId);
        }
        if (message.securityIdSource !== '') {
            writer.uint32(82).string(message.securityIdSource);
        }
        if (message.externalSecurityCode !== '') {
            writer.uint32(90).string(message.externalSecurityCode);
        }
        if (message.externalSecurityId !== '') {
            writer.uint32(98).string(message.externalSecurityId);
        }
        if (message.orderType !== 1) {
            writer.uint32(104).int32(message.orderType);
        }
        if (message.strategyType !== 1) {
            writer.uint32(112).int32(message.strategyType);
        }
        if (message.expiry !== '') {
            writer.uint32(122).string(message.expiry);
        }
        if (message.accountType !== 1) {
            writer.uint32(128).int32(message.accountType);
        }
        if (message.account !== '') {
            writer.uint32(138).string(message.account);
        }
        if (message.side !== 1) {
            writer.uint32(144).int32(message.side);
        }
        if (message.qty !== 0) {
            writer.uint32(153).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(161).double(message.price);
        }
        if (message.currency !== '') {
            writer.uint32(170).string(message.currency);
        }
        if (message.trader !== '') {
            writer.uint32(178).string(message.trader);
        }
        if (message.broker !== '') {
            writer.uint32(186).string(message.broker);
        }
        if (message.giveUp !== '') {
            writer.uint32(194).string(message.giveUp);
        }
        if (message.giveIn !== '') {
            writer.uint32(202).string(message.giveIn);
        }
        if (message.lastPx !== 0) {
            writer.uint32(209).double(message.lastPx);
        }
        if (message.lastQty !== 0) {
            writer.uint32(217).double(message.lastQty);
        }
        if (message.cumQty !== 0) {
            writer.uint32(225).double(message.cumQty);
        }
        if (message.leavesQty !== 0) {
            writer.uint32(233).double(message.leavesQty);
        }
        if (message.avgPx !== 0) {
            writer.uint32(241).double(message.avgPx);
        }
        if (message.orderStatus !== 1) {
            writer.uint32(248).int32(message.orderStatus);
        }
        if (message.executionType !== 1) {
            writer.uint32(256).int32(message.executionType);
        }
        if (message.orderText !== '') {
            writer.uint32(266).string(message.orderText);
        }
        if (message.info !== '') {
            writer.uint32(274).string(message.info);
        }
        if (message.timeInForce !== 1) {
            writer.uint32(280).int32(message.timeInForce);
        }
        if (message.timeInForceExpiry !== '') {
            writer.uint32(290).string(message.timeInForceExpiry);
        }
        if (message.handlingInstruction !== 1) {
            writer.uint32(296).int32(message.handlingInstruction);
        }
        if (message.isChildOrder === true) {
            writer.uint32(304).bool(message.isChildOrder);
        }
        if (message.parentOrderId !== '0') {
            writer.uint32(312).int64(message.parentOrderId);
        }
        if (message.orderVersion !== 0) {
            writer.uint32(320).int32(message.orderVersion);
        }
        if (message.isParentOrder === true) {
            writer.uint32(328).bool(message.isParentOrder);
        }
        if (message.celerClOrderId !== '') {
            writer.uint32(338).string(message.celerClOrderId);
        }
        if (message.originalCelerClOrderId !== '0') {
            writer.uint32(344).int64(message.originalCelerClOrderId);
        }
        for (const v of message.leg) {
            FutureLeg.encode(v!, writer.uint32(362).fork()).ldelim();
        }
        if (message.createdTimestampUtcInMillis !== '0') {
            writer.uint32(368).int64(message.createdTimestampUtcInMillis);
        }
        if (message.updatedTimestampUtcInMillis !== '0') {
            writer.uint32(376).int64(message.updatedTimestampUtcInMillis);
        }
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(386).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(394).string(message.deliverToCompId);
        }
        if (message.externalExchangeCode !== '') {
            writer.uint32(402).string(message.externalExchangeCode);
        }
        if (message.minQty !== 0) {
            writer.uint32(409).double(message.minQty);
        }
        if (message.maxFloor !== 0) {
            writer.uint32(417).double(message.maxFloor);
        }
        if (message.quoteId !== '') {
            writer.uint32(426).string(message.quoteId);
        }
        if (message.refId !== '0') {
            writer.uint32(432).int64(message.refId);
        }
        if (message.bookAsFilled === true) {
            writer.uint32(440).bool(message.bookAsFilled);
        }
        if (message.destinationKey !== '') {
            writer.uint32(450).string(message.destinationKey);
        }
        if (message.algoType !== '') {
            writer.uint32(458).string(message.algoType);
        }
        if (message.commission !== 0) {
            writer.uint32(465).double(message.commission);
        }
        for (const v of message.tradeAllocGroup) {
            OrderAllocGroup.encode(v!, writer.uint32(474).fork()).ldelim();
        }
        for (const v of message.tradePartiesGroup) {
            OrderPartiesGroup.encode(v!, writer.uint32(482).fork()).ldelim();
        }
        for (const v of message.orderMetadata) {
            KeyValue.encode(v!, writer.uint32(490).fork()).ldelim();
        }
        if (message.orderSource !== '') {
            writer.uint32(498).string(message.orderSource);
        }
        if (message.accountName !== '') {
            writer.uint32(506).string(message.accountName);
        }
        if (message.stopPx !== 0) {
            writer.uint32(513).double(message.stopPx);
        }
        if (message.productType !== 1) {
            writer.uint32(520).int32(message.productType);
        }
        if (message.sourceExecutionId !== '') {
            writer.uint32(530).string(message.sourceExecutionId);
        }
        if (message.destinationBackOfficeKey !== '') {
            writer.uint32(538).string(message.destinationBackOfficeKey);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FutureOrderSnapshotDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFutureOrderSnapshotDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.externalClOrderId = reader.string();
                    break;
                case 4:
                    message.originalExternalClOrderId = reader.string();
                    break;
                case 5:
                    message.externalOrderId = reader.string();
                    break;
                case 6:
                    message.secondaryExecId = reader.string();
                    break;
                case 7:
                    message.exchangeCode = reader.string();
                    break;
                case 8:
                    message.securityCode = reader.string();
                    break;
                case 9:
                    message.securityId = reader.string();
                    break;
                case 10:
                    message.securityIdSource = reader.string();
                    break;
                case 11:
                    message.externalSecurityCode = reader.string();
                    break;
                case 12:
                    message.externalSecurityId = reader.string();
                    break;
                case 13:
                    message.orderType = reader.int32() as any;
                    break;
                case 14:
                    message.strategyType = reader.int32() as any;
                    break;
                case 15:
                    message.expiry = reader.string();
                    break;
                case 16:
                    message.accountType = reader.int32() as any;
                    break;
                case 17:
                    message.account = reader.string();
                    break;
                case 18:
                    message.side = reader.int32() as any;
                    break;
                case 19:
                    message.qty = reader.double();
                    break;
                case 20:
                    message.price = reader.double();
                    break;
                case 21:
                    message.currency = reader.string();
                    break;
                case 22:
                    message.trader = reader.string();
                    break;
                case 23:
                    message.broker = reader.string();
                    break;
                case 24:
                    message.giveUp = reader.string();
                    break;
                case 25:
                    message.giveIn = reader.string();
                    break;
                case 26:
                    message.lastPx = reader.double();
                    break;
                case 27:
                    message.lastQty = reader.double();
                    break;
                case 28:
                    message.cumQty = reader.double();
                    break;
                case 29:
                    message.leavesQty = reader.double();
                    break;
                case 30:
                    message.avgPx = reader.double();
                    break;
                case 31:
                    message.orderStatus = reader.int32() as any;
                    break;
                case 32:
                    message.executionType = reader.int32() as any;
                    break;
                case 33:
                    message.orderText = reader.string();
                    break;
                case 34:
                    message.info = reader.string();
                    break;
                case 35:
                    message.timeInForce = reader.int32() as any;
                    break;
                case 36:
                    message.timeInForceExpiry = reader.string();
                    break;
                case 37:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 38:
                    message.isChildOrder = reader.bool();
                    break;
                case 39:
                    message.parentOrderId = longToString(reader.int64() as Long);
                    break;
                case 40:
                    message.orderVersion = reader.int32();
                    break;
                case 41:
                    message.isParentOrder = reader.bool();
                    break;
                case 42:
                    message.celerClOrderId = reader.string();
                    break;
                case 43:
                    message.originalCelerClOrderId = longToString(reader.int64() as Long);
                    break;
                case 45:
                    message.leg.push(FutureLeg.decode(reader, reader.uint32()));
                    break;
                case 46:
                    message.createdTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 47:
                    message.updatedTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 48:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 49:
                    message.deliverToCompId = reader.string();
                    break;
                case 50:
                    message.externalExchangeCode = reader.string();
                    break;
                case 51:
                    message.minQty = reader.double();
                    break;
                case 52:
                    message.maxFloor = reader.double();
                    break;
                case 53:
                    message.quoteId = reader.string();
                    break;
                case 54:
                    message.refId = longToString(reader.int64() as Long);
                    break;
                case 55:
                    message.bookAsFilled = reader.bool();
                    break;
                case 56:
                    message.destinationKey = reader.string();
                    break;
                case 57:
                    message.algoType = reader.string();
                    break;
                case 58:
                    message.commission = reader.double();
                    break;
                case 59:
                    message.tradeAllocGroup.push(OrderAllocGroup.decode(reader, reader.uint32()));
                    break;
                case 60:
                    message.tradePartiesGroup.push(OrderPartiesGroup.decode(reader, reader.uint32()));
                    break;
                case 61:
                    message.orderMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 62:
                    message.orderSource = reader.string();
                    break;
                case 63:
                    message.accountName = reader.string();
                    break;
                case 64:
                    message.stopPx = reader.double();
                    break;
                case 65:
                    message.productType = reader.int32() as any;
                    break;
                case 66:
                    message.sourceExecutionId = reader.string();
                    break;
                case 67:
                    message.destinationBackOfficeKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FutureOrderSnapshotDownstreamEvent>): FutureOrderSnapshotDownstreamEvent {
        const message = createBaseFutureOrderSnapshotDownstreamEvent();
        message.id = object.id ?? '0';
        message.orderId = object.orderId ?? '0';
        message.externalClOrderId = object.externalClOrderId ?? '';
        message.originalExternalClOrderId = object.originalExternalClOrderId ?? '';
        message.externalOrderId = object.externalOrderId ?? '';
        message.secondaryExecId = object.secondaryExecId ?? '';
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.securityIdSource = object.securityIdSource ?? '';
        message.externalSecurityCode = object.externalSecurityCode ?? '';
        message.externalSecurityId = object.externalSecurityId ?? '';
        message.orderType = object.orderType ?? 1;
        message.strategyType = object.strategyType ?? 1;
        message.expiry = object.expiry ?? '';
        message.accountType = object.accountType ?? 1;
        message.account = object.account ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.currency = object.currency ?? '';
        message.trader = object.trader ?? '';
        message.broker = object.broker ?? '';
        message.giveUp = object.giveUp ?? '';
        message.giveIn = object.giveIn ?? '';
        message.lastPx = object.lastPx ?? 0;
        message.lastQty = object.lastQty ?? 0;
        message.cumQty = object.cumQty ?? 0;
        message.leavesQty = object.leavesQty ?? 0;
        message.avgPx = object.avgPx ?? 0;
        message.orderStatus = object.orderStatus ?? 1;
        message.executionType = object.executionType ?? 1;
        message.orderText = object.orderText ?? '';
        message.info = object.info ?? '';
        message.timeInForce = object.timeInForce ?? 1;
        message.timeInForceExpiry = object.timeInForceExpiry ?? '';
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.isChildOrder = object.isChildOrder ?? false;
        message.parentOrderId = object.parentOrderId ?? '0';
        message.orderVersion = object.orderVersion ?? 0;
        message.isParentOrder = object.isParentOrder ?? false;
        message.celerClOrderId = object.celerClOrderId ?? '';
        message.originalCelerClOrderId = object.originalCelerClOrderId ?? '0';
        message.leg = object.leg?.map((e) => FutureLeg.fromPartial(e)) || [];
        message.createdTimestampUtcInMillis = object.createdTimestampUtcInMillis ?? '0';
        message.updatedTimestampUtcInMillis = object.updatedTimestampUtcInMillis ?? '0';
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.externalExchangeCode = object.externalExchangeCode ?? '';
        message.minQty = object.minQty ?? 0;
        message.maxFloor = object.maxFloor ?? 0;
        message.quoteId = object.quoteId ?? '';
        message.refId = object.refId ?? '0';
        message.bookAsFilled = object.bookAsFilled ?? false;
        message.destinationKey = object.destinationKey ?? '';
        message.algoType = object.algoType ?? '';
        message.commission = object.commission ?? 0;
        message.tradeAllocGroup = object.tradeAllocGroup?.map((e) => OrderAllocGroup.fromPartial(e)) || [];
        message.tradePartiesGroup = object.tradePartiesGroup?.map((e) => OrderPartiesGroup.fromPartial(e)) || [];
        message.orderMetadata = object.orderMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.orderSource = object.orderSource ?? '';
        message.accountName = object.accountName ?? '';
        message.stopPx = object.stopPx ?? 0;
        message.productType = object.productType ?? 1;
        message.sourceExecutionId = object.sourceExecutionId ?? '';
        message.destinationBackOfficeKey = object.destinationBackOfficeKey ?? '';
        return message;
    }
};

function createBaseFutureLeg(): FutureLeg {
    return {
        legNumber: 0,
        underlyingExchangeCode: '',
        underlyingSecurityCode: '',
        underlyingSecurityId: '',
        underlyingExpiry: '',
        legRatioQty: 0,
        side: 1,
        legPrice: 0,
        legLastPx: 0,
        settlementType: ''
    };
}

export const FutureLeg = {
    encode(message: FutureLeg, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.legNumber !== 0) {
            writer.uint32(8).int32(message.legNumber);
        }
        if (message.underlyingExchangeCode !== '') {
            writer.uint32(18).string(message.underlyingExchangeCode);
        }
        if (message.underlyingSecurityCode !== '') {
            writer.uint32(26).string(message.underlyingSecurityCode);
        }
        if (message.underlyingSecurityId !== '') {
            writer.uint32(34).string(message.underlyingSecurityId);
        }
        if (message.underlyingExpiry !== '') {
            writer.uint32(42).string(message.underlyingExpiry);
        }
        if (message.legRatioQty !== 0) {
            writer.uint32(49).double(message.legRatioQty);
        }
        if (message.side !== 1) {
            writer.uint32(56).int32(message.side);
        }
        if (message.legPrice !== 0) {
            writer.uint32(65).double(message.legPrice);
        }
        if (message.legLastPx !== 0) {
            writer.uint32(73).double(message.legLastPx);
        }
        if (message.settlementType !== '') {
            writer.uint32(82).string(message.settlementType);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FutureLeg {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFutureLeg();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.legNumber = reader.int32();
                    break;
                case 2:
                    message.underlyingExchangeCode = reader.string();
                    break;
                case 3:
                    message.underlyingSecurityCode = reader.string();
                    break;
                case 4:
                    message.underlyingSecurityId = reader.string();
                    break;
                case 5:
                    message.underlyingExpiry = reader.string();
                    break;
                case 6:
                    message.legRatioQty = reader.double();
                    break;
                case 7:
                    message.side = reader.int32() as any;
                    break;
                case 8:
                    message.legPrice = reader.double();
                    break;
                case 9:
                    message.legLastPx = reader.double();
                    break;
                case 10:
                    message.settlementType = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FutureLeg>): FutureLeg {
        const message = createBaseFutureLeg();
        message.legNumber = object.legNumber ?? 0;
        message.underlyingExchangeCode = object.underlyingExchangeCode ?? '';
        message.underlyingSecurityCode = object.underlyingSecurityCode ?? '';
        message.underlyingSecurityId = object.underlyingSecurityId ?? '';
        message.underlyingExpiry = object.underlyingExpiry ?? '';
        message.legRatioQty = object.legRatioQty ?? 0;
        message.side = object.side ?? 1;
        message.legPrice = object.legPrice ?? 0;
        message.legLastPx = object.legLastPx ?? 0;
        message.settlementType = object.settlementType ?? '';
        return message;
    }
};

function createBaseOptionOrderSnapshotDownstreamEvent(): OptionOrderSnapshotDownstreamEvent {
    return {
        id: '0',
        orderId: '0',
        externalClOrderId: '',
        originalExternalClOrderId: '',
        externalOrderId: '',
        secondaryExecId: '',
        exchangeCode: '',
        securityCode: '',
        securityId: '',
        securityIdSource: '',
        externalSecurityCode: '',
        externalSecurityId: '',
        orderType: 1,
        expiry: '',
        strike: 0,
        accountType: 1,
        account: '',
        side: 1,
        qty: 0,
        price: 0,
        currency: '',
        trader: '',
        broker: '',
        giveUp: '',
        giveIn: '',
        lastPx: 0,
        lastQty: 0,
        cumQty: 0,
        leavesQty: 0,
        avgPx: 0,
        orderStatus: 1,
        executionType: 1,
        optionType: 1,
        orderText: '',
        info: '',
        handlingInstruction: 1,
        parentOrderId: '0',
        orderVersion: 0,
        isParentOrder: false,
        celerClOrderId: '',
        originalCelerClOrderId: '0',
        isChildOrder: false,
        createdTimestampUtcInMillis: '0',
        updatedTimestampUtcInMillis: '0',
        onBehalfOfCompId: '',
        deliverToCompId: '',
        externalExchangeCode: '',
        timeInForce: 1,
        timeInForceExpiry: '',
        minQty: 0,
        maxFloor: 0,
        quoteId: '',
        refId: '0',
        bookAsFilled: false,
        destinationKey: '',
        algoType: '',
        commission: 0,
        tradeAllocGroup: [],
        tradePartiesGroup: [],
        orderMetadata: [],
        orderSource: '',
        accountName: '',
        stopPx: 0,
        productType: 1,
        sourceExecutionId: '',
        destinationBackOfficeKey: ''
    };
}

export const OptionOrderSnapshotDownstreamEvent = {
    encode(message: OptionOrderSnapshotDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== '0') {
            writer.uint32(8).int64(message.id);
        }
        if (message.orderId !== '0') {
            writer.uint32(16).int64(message.orderId);
        }
        if (message.externalClOrderId !== '') {
            writer.uint32(26).string(message.externalClOrderId);
        }
        if (message.originalExternalClOrderId !== '') {
            writer.uint32(34).string(message.originalExternalClOrderId);
        }
        if (message.externalOrderId !== '') {
            writer.uint32(42).string(message.externalOrderId);
        }
        if (message.secondaryExecId !== '') {
            writer.uint32(50).string(message.secondaryExecId);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(58).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(66).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(74).string(message.securityId);
        }
        if (message.securityIdSource !== '') {
            writer.uint32(82).string(message.securityIdSource);
        }
        if (message.externalSecurityCode !== '') {
            writer.uint32(90).string(message.externalSecurityCode);
        }
        if (message.externalSecurityId !== '') {
            writer.uint32(98).string(message.externalSecurityId);
        }
        if (message.orderType !== 1) {
            writer.uint32(104).int32(message.orderType);
        }
        if (message.expiry !== '') {
            writer.uint32(114).string(message.expiry);
        }
        if (message.strike !== 0) {
            writer.uint32(121).double(message.strike);
        }
        if (message.accountType !== 1) {
            writer.uint32(128).int32(message.accountType);
        }
        if (message.account !== '') {
            writer.uint32(138).string(message.account);
        }
        if (message.side !== 1) {
            writer.uint32(144).int32(message.side);
        }
        if (message.qty !== 0) {
            writer.uint32(153).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(161).double(message.price);
        }
        if (message.currency !== '') {
            writer.uint32(170).string(message.currency);
        }
        if (message.trader !== '') {
            writer.uint32(178).string(message.trader);
        }
        if (message.broker !== '') {
            writer.uint32(186).string(message.broker);
        }
        if (message.giveUp !== '') {
            writer.uint32(194).string(message.giveUp);
        }
        if (message.giveIn !== '') {
            writer.uint32(202).string(message.giveIn);
        }
        if (message.lastPx !== 0) {
            writer.uint32(209).double(message.lastPx);
        }
        if (message.lastQty !== 0) {
            writer.uint32(217).double(message.lastQty);
        }
        if (message.cumQty !== 0) {
            writer.uint32(225).double(message.cumQty);
        }
        if (message.leavesQty !== 0) {
            writer.uint32(233).double(message.leavesQty);
        }
        if (message.avgPx !== 0) {
            writer.uint32(241).double(message.avgPx);
        }
        if (message.orderStatus !== 1) {
            writer.uint32(248).int32(message.orderStatus);
        }
        if (message.executionType !== 1) {
            writer.uint32(256).int32(message.executionType);
        }
        if (message.optionType !== 1) {
            writer.uint32(264).int32(message.optionType);
        }
        if (message.orderText !== '') {
            writer.uint32(274).string(message.orderText);
        }
        if (message.info !== '') {
            writer.uint32(282).string(message.info);
        }
        if (message.handlingInstruction !== 1) {
            writer.uint32(288).int32(message.handlingInstruction);
        }
        if (message.parentOrderId !== '0') {
            writer.uint32(296).int64(message.parentOrderId);
        }
        if (message.orderVersion !== 0) {
            writer.uint32(304).int32(message.orderVersion);
        }
        if (message.isParentOrder === true) {
            writer.uint32(312).bool(message.isParentOrder);
        }
        if (message.celerClOrderId !== '') {
            writer.uint32(322).string(message.celerClOrderId);
        }
        if (message.originalCelerClOrderId !== '0') {
            writer.uint32(328).int64(message.originalCelerClOrderId);
        }
        if (message.isChildOrder === true) {
            writer.uint32(336).bool(message.isChildOrder);
        }
        if (message.createdTimestampUtcInMillis !== '0') {
            writer.uint32(352).int64(message.createdTimestampUtcInMillis);
        }
        if (message.updatedTimestampUtcInMillis !== '0') {
            writer.uint32(360).int64(message.updatedTimestampUtcInMillis);
        }
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(370).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(378).string(message.deliverToCompId);
        }
        if (message.externalExchangeCode !== '') {
            writer.uint32(386).string(message.externalExchangeCode);
        }
        if (message.timeInForce !== 1) {
            writer.uint32(392).int32(message.timeInForce);
        }
        if (message.timeInForceExpiry !== '') {
            writer.uint32(402).string(message.timeInForceExpiry);
        }
        if (message.minQty !== 0) {
            writer.uint32(409).double(message.minQty);
        }
        if (message.maxFloor !== 0) {
            writer.uint32(417).double(message.maxFloor);
        }
        if (message.quoteId !== '') {
            writer.uint32(426).string(message.quoteId);
        }
        if (message.refId !== '0') {
            writer.uint32(432).int64(message.refId);
        }
        if (message.bookAsFilled === true) {
            writer.uint32(440).bool(message.bookAsFilled);
        }
        if (message.destinationKey !== '') {
            writer.uint32(450).string(message.destinationKey);
        }
        if (message.algoType !== '') {
            writer.uint32(458).string(message.algoType);
        }
        if (message.commission !== 0) {
            writer.uint32(465).double(message.commission);
        }
        for (const v of message.tradeAllocGroup) {
            OrderAllocGroup.encode(v!, writer.uint32(474).fork()).ldelim();
        }
        for (const v of message.tradePartiesGroup) {
            OrderPartiesGroup.encode(v!, writer.uint32(482).fork()).ldelim();
        }
        for (const v of message.orderMetadata) {
            KeyValue.encode(v!, writer.uint32(490).fork()).ldelim();
        }
        if (message.orderSource !== '') {
            writer.uint32(498).string(message.orderSource);
        }
        if (message.accountName !== '') {
            writer.uint32(506).string(message.accountName);
        }
        if (message.stopPx !== 0) {
            writer.uint32(513).double(message.stopPx);
        }
        if (message.productType !== 1) {
            writer.uint32(520).int32(message.productType);
        }
        if (message.sourceExecutionId !== '') {
            writer.uint32(530).string(message.sourceExecutionId);
        }
        if (message.destinationBackOfficeKey !== '') {
            writer.uint32(538).string(message.destinationBackOfficeKey);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): OptionOrderSnapshotDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOptionOrderSnapshotDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.externalClOrderId = reader.string();
                    break;
                case 4:
                    message.originalExternalClOrderId = reader.string();
                    break;
                case 5:
                    message.externalOrderId = reader.string();
                    break;
                case 6:
                    message.secondaryExecId = reader.string();
                    break;
                case 7:
                    message.exchangeCode = reader.string();
                    break;
                case 8:
                    message.securityCode = reader.string();
                    break;
                case 9:
                    message.securityId = reader.string();
                    break;
                case 10:
                    message.securityIdSource = reader.string();
                    break;
                case 11:
                    message.externalSecurityCode = reader.string();
                    break;
                case 12:
                    message.externalSecurityId = reader.string();
                    break;
                case 13:
                    message.orderType = reader.int32() as any;
                    break;
                case 14:
                    message.expiry = reader.string();
                    break;
                case 15:
                    message.strike = reader.double();
                    break;
                case 16:
                    message.accountType = reader.int32() as any;
                    break;
                case 17:
                    message.account = reader.string();
                    break;
                case 18:
                    message.side = reader.int32() as any;
                    break;
                case 19:
                    message.qty = reader.double();
                    break;
                case 20:
                    message.price = reader.double();
                    break;
                case 21:
                    message.currency = reader.string();
                    break;
                case 22:
                    message.trader = reader.string();
                    break;
                case 23:
                    message.broker = reader.string();
                    break;
                case 24:
                    message.giveUp = reader.string();
                    break;
                case 25:
                    message.giveIn = reader.string();
                    break;
                case 26:
                    message.lastPx = reader.double();
                    break;
                case 27:
                    message.lastQty = reader.double();
                    break;
                case 28:
                    message.cumQty = reader.double();
                    break;
                case 29:
                    message.leavesQty = reader.double();
                    break;
                case 30:
                    message.avgPx = reader.double();
                    break;
                case 31:
                    message.orderStatus = reader.int32() as any;
                    break;
                case 32:
                    message.executionType = reader.int32() as any;
                    break;
                case 33:
                    message.optionType = reader.int32() as any;
                    break;
                case 34:
                    message.orderText = reader.string();
                    break;
                case 35:
                    message.info = reader.string();
                    break;
                case 36:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 37:
                    message.parentOrderId = longToString(reader.int64() as Long);
                    break;
                case 38:
                    message.orderVersion = reader.int32();
                    break;
                case 39:
                    message.isParentOrder = reader.bool();
                    break;
                case 40:
                    message.celerClOrderId = reader.string();
                    break;
                case 41:
                    message.originalCelerClOrderId = longToString(reader.int64() as Long);
                    break;
                case 42:
                    message.isChildOrder = reader.bool();
                    break;
                case 44:
                    message.createdTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 45:
                    message.updatedTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 46:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 47:
                    message.deliverToCompId = reader.string();
                    break;
                case 48:
                    message.externalExchangeCode = reader.string();
                    break;
                case 49:
                    message.timeInForce = reader.int32() as any;
                    break;
                case 50:
                    message.timeInForceExpiry = reader.string();
                    break;
                case 51:
                    message.minQty = reader.double();
                    break;
                case 52:
                    message.maxFloor = reader.double();
                    break;
                case 53:
                    message.quoteId = reader.string();
                    break;
                case 54:
                    message.refId = longToString(reader.int64() as Long);
                    break;
                case 55:
                    message.bookAsFilled = reader.bool();
                    break;
                case 56:
                    message.destinationKey = reader.string();
                    break;
                case 57:
                    message.algoType = reader.string();
                    break;
                case 58:
                    message.commission = reader.double();
                    break;
                case 59:
                    message.tradeAllocGroup.push(OrderAllocGroup.decode(reader, reader.uint32()));
                    break;
                case 60:
                    message.tradePartiesGroup.push(OrderPartiesGroup.decode(reader, reader.uint32()));
                    break;
                case 61:
                    message.orderMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 62:
                    message.orderSource = reader.string();
                    break;
                case 63:
                    message.accountName = reader.string();
                    break;
                case 64:
                    message.stopPx = reader.double();
                    break;
                case 65:
                    message.productType = reader.int32() as any;
                    break;
                case 66:
                    message.sourceExecutionId = reader.string();
                    break;
                case 67:
                    message.destinationBackOfficeKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<OptionOrderSnapshotDownstreamEvent>): OptionOrderSnapshotDownstreamEvent {
        const message = createBaseOptionOrderSnapshotDownstreamEvent();
        message.id = object.id ?? '0';
        message.orderId = object.orderId ?? '0';
        message.externalClOrderId = object.externalClOrderId ?? '';
        message.originalExternalClOrderId = object.originalExternalClOrderId ?? '';
        message.externalOrderId = object.externalOrderId ?? '';
        message.secondaryExecId = object.secondaryExecId ?? '';
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.securityIdSource = object.securityIdSource ?? '';
        message.externalSecurityCode = object.externalSecurityCode ?? '';
        message.externalSecurityId = object.externalSecurityId ?? '';
        message.orderType = object.orderType ?? 1;
        message.expiry = object.expiry ?? '';
        message.strike = object.strike ?? 0;
        message.accountType = object.accountType ?? 1;
        message.account = object.account ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.currency = object.currency ?? '';
        message.trader = object.trader ?? '';
        message.broker = object.broker ?? '';
        message.giveUp = object.giveUp ?? '';
        message.giveIn = object.giveIn ?? '';
        message.lastPx = object.lastPx ?? 0;
        message.lastQty = object.lastQty ?? 0;
        message.cumQty = object.cumQty ?? 0;
        message.leavesQty = object.leavesQty ?? 0;
        message.avgPx = object.avgPx ?? 0;
        message.orderStatus = object.orderStatus ?? 1;
        message.executionType = object.executionType ?? 1;
        message.optionType = object.optionType ?? 1;
        message.orderText = object.orderText ?? '';
        message.info = object.info ?? '';
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.parentOrderId = object.parentOrderId ?? '0';
        message.orderVersion = object.orderVersion ?? 0;
        message.isParentOrder = object.isParentOrder ?? false;
        message.celerClOrderId = object.celerClOrderId ?? '';
        message.originalCelerClOrderId = object.originalCelerClOrderId ?? '0';
        message.isChildOrder = object.isChildOrder ?? false;
        message.createdTimestampUtcInMillis = object.createdTimestampUtcInMillis ?? '0';
        message.updatedTimestampUtcInMillis = object.updatedTimestampUtcInMillis ?? '0';
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.externalExchangeCode = object.externalExchangeCode ?? '';
        message.timeInForce = object.timeInForce ?? 1;
        message.timeInForceExpiry = object.timeInForceExpiry ?? '';
        message.minQty = object.minQty ?? 0;
        message.maxFloor = object.maxFloor ?? 0;
        message.quoteId = object.quoteId ?? '';
        message.refId = object.refId ?? '0';
        message.bookAsFilled = object.bookAsFilled ?? false;
        message.destinationKey = object.destinationKey ?? '';
        message.algoType = object.algoType ?? '';
        message.commission = object.commission ?? 0;
        message.tradeAllocGroup = object.tradeAllocGroup?.map((e) => OrderAllocGroup.fromPartial(e)) || [];
        message.tradePartiesGroup = object.tradePartiesGroup?.map((e) => OrderPartiesGroup.fromPartial(e)) || [];
        message.orderMetadata = object.orderMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.orderSource = object.orderSource ?? '';
        message.accountName = object.accountName ?? '';
        message.stopPx = object.stopPx ?? 0;
        message.productType = object.productType ?? 1;
        message.sourceExecutionId = object.sourceExecutionId ?? '';
        message.destinationBackOfficeKey = object.destinationBackOfficeKey ?? '';
        return message;
    }
};

function createBaseFxOrderSnapshotDownstreamEvent(): FxOrderSnapshotDownstreamEvent {
    return {
        id: '0',
        orderId: '0',
        externalClOrderId: '',
        originalExternalClOrderId: '',
        externalOrderId: '',
        secondaryExecId: '',
        exchangeCode: '',
        securityCode: '',
        securityId: '',
        securityIdSource: '',
        externalSecurityCode: '',
        externalSecurityId: '',
        orderType: 1,
        strategyType: 1,
        accountType: 1,
        account: '',
        side: 1,
        qty: 0,
        price: 0,
        currency: '',
        trader: '',
        broker: '',
        giveUp: '',
        giveIn: '',
        lastPx: 0,
        lastQty: 0,
        cumQty: 0,
        leavesQty: 0,
        avgPx: 0,
        orderStatus: 1,
        executionType: 1,
        orderText: '',
        info: '',
        handlingInstruction: 1,
        parentOrderId: '0',
        orderVersion: 0,
        isParentOrder: false,
        celerClOrderId: '',
        originalCelerClOrderId: '0',
        isChildOrder: false,
        createdTimestampUtcInMillis: '0',
        updatedTimestampUtcInMillis: '0',
        onBehalfOfCompId: '',
        deliverToCompId: '',
        externalExchangeCode: '',
        timeInForce: 1,
        timeInForceExpiry: '',
        minQty: 0,
        maxFloor: 0,
        quoteId: '',
        settlementDate: '',
        checkpointTimestamp: [],
        refId: '0',
        bookAsFilled: false,
        productType: 1,
        destinationKey: '',
        algoType: '',
        commission: 0,
        leg: [],
        tradeAllocGroup: [],
        tradePartiesGroup: [],
        settlementType: '',
        splitValueDate: false,
        splitLegCurrency1: '',
        splitLegCurrency2: '',
        splitLegCurrency1SettlementDate: '',
        splitLegCurrency2SettlementDate: '',
        orderMetadata: [],
        orderSource: '',
        settlCurrency: '',
        rateSource: '',
        accountName: '',
        stopPx: 0,
        sourceExecutionId: '',
        destinationBackOfficeKey: '',
        executionInstruction: 1,
        maxShow: 0,
        pegPriceType: 1,
        lastActionedBy: ''
    };
}

export const FxOrderSnapshotDownstreamEvent = {
    encode(message: FxOrderSnapshotDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== '0') {
            writer.uint32(8).int64(message.id);
        }
        if (message.orderId !== '0') {
            writer.uint32(16).int64(message.orderId);
        }
        if (message.externalClOrderId !== '') {
            writer.uint32(26).string(message.externalClOrderId);
        }
        if (message.originalExternalClOrderId !== '') {
            writer.uint32(34).string(message.originalExternalClOrderId);
        }
        if (message.externalOrderId !== '') {
            writer.uint32(42).string(message.externalOrderId);
        }
        if (message.secondaryExecId !== '') {
            writer.uint32(50).string(message.secondaryExecId);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(58).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(66).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(74).string(message.securityId);
        }
        if (message.securityIdSource !== '') {
            writer.uint32(82).string(message.securityIdSource);
        }
        if (message.externalSecurityCode !== '') {
            writer.uint32(90).string(message.externalSecurityCode);
        }
        if (message.externalSecurityId !== '') {
            writer.uint32(98).string(message.externalSecurityId);
        }
        if (message.orderType !== 1) {
            writer.uint32(104).int32(message.orderType);
        }
        if (message.strategyType !== 1) {
            writer.uint32(112).int32(message.strategyType);
        }
        if (message.accountType !== 1) {
            writer.uint32(120).int32(message.accountType);
        }
        if (message.account !== '') {
            writer.uint32(130).string(message.account);
        }
        if (message.side !== 1) {
            writer.uint32(136).int32(message.side);
        }
        if (message.qty !== 0) {
            writer.uint32(145).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(153).double(message.price);
        }
        if (message.currency !== '') {
            writer.uint32(162).string(message.currency);
        }
        if (message.trader !== '') {
            writer.uint32(170).string(message.trader);
        }
        if (message.broker !== '') {
            writer.uint32(178).string(message.broker);
        }
        if (message.giveUp !== '') {
            writer.uint32(186).string(message.giveUp);
        }
        if (message.giveIn !== '') {
            writer.uint32(194).string(message.giveIn);
        }
        if (message.lastPx !== 0) {
            writer.uint32(201).double(message.lastPx);
        }
        if (message.lastQty !== 0) {
            writer.uint32(209).double(message.lastQty);
        }
        if (message.cumQty !== 0) {
            writer.uint32(217).double(message.cumQty);
        }
        if (message.leavesQty !== 0) {
            writer.uint32(225).double(message.leavesQty);
        }
        if (message.avgPx !== 0) {
            writer.uint32(233).double(message.avgPx);
        }
        if (message.orderStatus !== 1) {
            writer.uint32(240).int32(message.orderStatus);
        }
        if (message.executionType !== 1) {
            writer.uint32(248).int32(message.executionType);
        }
        if (message.orderText !== '') {
            writer.uint32(258).string(message.orderText);
        }
        if (message.info !== '') {
            writer.uint32(266).string(message.info);
        }
        if (message.handlingInstruction !== 1) {
            writer.uint32(272).int32(message.handlingInstruction);
        }
        if (message.parentOrderId !== '0') {
            writer.uint32(280).int64(message.parentOrderId);
        }
        if (message.orderVersion !== 0) {
            writer.uint32(288).int32(message.orderVersion);
        }
        if (message.isParentOrder === true) {
            writer.uint32(296).bool(message.isParentOrder);
        }
        if (message.celerClOrderId !== '') {
            writer.uint32(306).string(message.celerClOrderId);
        }
        if (message.originalCelerClOrderId !== '0') {
            writer.uint32(312).int64(message.originalCelerClOrderId);
        }
        if (message.isChildOrder === true) {
            writer.uint32(320).bool(message.isChildOrder);
        }
        if (message.createdTimestampUtcInMillis !== '0') {
            writer.uint32(336).int64(message.createdTimestampUtcInMillis);
        }
        if (message.updatedTimestampUtcInMillis !== '0') {
            writer.uint32(344).int64(message.updatedTimestampUtcInMillis);
        }
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(354).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(362).string(message.deliverToCompId);
        }
        if (message.externalExchangeCode !== '') {
            writer.uint32(370).string(message.externalExchangeCode);
        }
        if (message.timeInForce !== 1) {
            writer.uint32(376).int32(message.timeInForce);
        }
        if (message.timeInForceExpiry !== '') {
            writer.uint32(386).string(message.timeInForceExpiry);
        }
        if (message.minQty !== 0) {
            writer.uint32(393).double(message.minQty);
        }
        if (message.maxFloor !== 0) {
            writer.uint32(401).double(message.maxFloor);
        }
        if (message.quoteId !== '') {
            writer.uint32(410).string(message.quoteId);
        }
        if (message.settlementDate !== '') {
            writer.uint32(418).string(message.settlementDate);
        }
        writer.uint32(426).fork();
        for (const v of message.checkpointTimestamp) {
            writer.int64(v);
        }
        writer.ldelim();
        if (message.refId !== '0') {
            writer.uint32(432).int64(message.refId);
        }
        if (message.bookAsFilled === true) {
            writer.uint32(440).bool(message.bookAsFilled);
        }
        if (message.productType !== 1) {
            writer.uint32(448).int32(message.productType);
        }
        if (message.destinationKey !== '') {
            writer.uint32(458).string(message.destinationKey);
        }
        if (message.algoType !== '') {
            writer.uint32(466).string(message.algoType);
        }
        if (message.commission !== 0) {
            writer.uint32(473).double(message.commission);
        }
        for (const v of message.leg) {
            FxLeg.encode(v!, writer.uint32(482).fork()).ldelim();
        }
        for (const v of message.tradeAllocGroup) {
            OrderAllocGroup.encode(v!, writer.uint32(490).fork()).ldelim();
        }
        for (const v of message.tradePartiesGroup) {
            OrderPartiesGroup.encode(v!, writer.uint32(498).fork()).ldelim();
        }
        if (message.settlementType !== '') {
            writer.uint32(506).string(message.settlementType);
        }
        if (message.splitValueDate === true) {
            writer.uint32(512).bool(message.splitValueDate);
        }
        if (message.splitLegCurrency1 !== '') {
            writer.uint32(522).string(message.splitLegCurrency1);
        }
        if (message.splitLegCurrency2 !== '') {
            writer.uint32(530).string(message.splitLegCurrency2);
        }
        if (message.splitLegCurrency1SettlementDate !== '') {
            writer.uint32(538).string(message.splitLegCurrency1SettlementDate);
        }
        if (message.splitLegCurrency2SettlementDate !== '') {
            writer.uint32(546).string(message.splitLegCurrency2SettlementDate);
        }
        for (const v of message.orderMetadata) {
            KeyValue.encode(v!, writer.uint32(554).fork()).ldelim();
        }
        if (message.orderSource !== '') {
            writer.uint32(562).string(message.orderSource);
        }
        if (message.settlCurrency !== '') {
            writer.uint32(570).string(message.settlCurrency);
        }
        if (message.rateSource !== '') {
            writer.uint32(578).string(message.rateSource);
        }
        if (message.accountName !== '') {
            writer.uint32(586).string(message.accountName);
        }
        if (message.stopPx !== 0) {
            writer.uint32(593).double(message.stopPx);
        }
        if (message.sourceExecutionId !== '') {
            writer.uint32(602).string(message.sourceExecutionId);
        }
        if (message.destinationBackOfficeKey !== '') {
            writer.uint32(610).string(message.destinationBackOfficeKey);
        }
        if (message.executionInstruction !== 1) {
            writer.uint32(616).int32(message.executionInstruction);
        }
        if (message.maxShow !== 0) {
            writer.uint32(625).double(message.maxShow);
        }
        if (message.pegPriceType !== 1) {
            writer.uint32(632).int32(message.pegPriceType);
        }
        if (message.lastActionedBy !== '') {
            writer.uint32(642).string(message.lastActionedBy);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FxOrderSnapshotDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFxOrderSnapshotDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.externalClOrderId = reader.string();
                    break;
                case 4:
                    message.originalExternalClOrderId = reader.string();
                    break;
                case 5:
                    message.externalOrderId = reader.string();
                    break;
                case 6:
                    message.secondaryExecId = reader.string();
                    break;
                case 7:
                    message.exchangeCode = reader.string();
                    break;
                case 8:
                    message.securityCode = reader.string();
                    break;
                case 9:
                    message.securityId = reader.string();
                    break;
                case 10:
                    message.securityIdSource = reader.string();
                    break;
                case 11:
                    message.externalSecurityCode = reader.string();
                    break;
                case 12:
                    message.externalSecurityId = reader.string();
                    break;
                case 13:
                    message.orderType = reader.int32() as any;
                    break;
                case 14:
                    message.strategyType = reader.int32() as any;
                    break;
                case 15:
                    message.accountType = reader.int32() as any;
                    break;
                case 16:
                    message.account = reader.string();
                    break;
                case 17:
                    message.side = reader.int32() as any;
                    break;
                case 18:
                    message.qty = reader.double();
                    break;
                case 19:
                    message.price = reader.double();
                    break;
                case 20:
                    message.currency = reader.string();
                    break;
                case 21:
                    message.trader = reader.string();
                    break;
                case 22:
                    message.broker = reader.string();
                    break;
                case 23:
                    message.giveUp = reader.string();
                    break;
                case 24:
                    message.giveIn = reader.string();
                    break;
                case 25:
                    message.lastPx = reader.double();
                    break;
                case 26:
                    message.lastQty = reader.double();
                    break;
                case 27:
                    message.cumQty = reader.double();
                    break;
                case 28:
                    message.leavesQty = reader.double();
                    break;
                case 29:
                    message.avgPx = reader.double();
                    break;
                case 30:
                    message.orderStatus = reader.int32() as any;
                    break;
                case 31:
                    message.executionType = reader.int32() as any;
                    break;
                case 32:
                    message.orderText = reader.string();
                    break;
                case 33:
                    message.info = reader.string();
                    break;
                case 34:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 35:
                    message.parentOrderId = longToString(reader.int64() as Long);
                    break;
                case 36:
                    message.orderVersion = reader.int32();
                    break;
                case 37:
                    message.isParentOrder = reader.bool();
                    break;
                case 38:
                    message.celerClOrderId = reader.string();
                    break;
                case 39:
                    message.originalCelerClOrderId = longToString(reader.int64() as Long);
                    break;
                case 40:
                    message.isChildOrder = reader.bool();
                    break;
                case 42:
                    message.createdTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 43:
                    message.updatedTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 44:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 45:
                    message.deliverToCompId = reader.string();
                    break;
                case 46:
                    message.externalExchangeCode = reader.string();
                    break;
                case 47:
                    message.timeInForce = reader.int32() as any;
                    break;
                case 48:
                    message.timeInForceExpiry = reader.string();
                    break;
                case 49:
                    message.minQty = reader.double();
                    break;
                case 50:
                    message.maxFloor = reader.double();
                    break;
                case 51:
                    message.quoteId = reader.string();
                    break;
                case 52:
                    message.settlementDate = reader.string();
                    break;
                case 53:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                        }
                    } else {
                        message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                    }
                    break;
                case 54:
                    message.refId = longToString(reader.int64() as Long);
                    break;
                case 55:
                    message.bookAsFilled = reader.bool();
                    break;
                case 56:
                    message.productType = reader.int32() as any;
                    break;
                case 57:
                    message.destinationKey = reader.string();
                    break;
                case 58:
                    message.algoType = reader.string();
                    break;
                case 59:
                    message.commission = reader.double();
                    break;
                case 60:
                    message.leg.push(FxLeg.decode(reader, reader.uint32()));
                    break;
                case 61:
                    message.tradeAllocGroup.push(OrderAllocGroup.decode(reader, reader.uint32()));
                    break;
                case 62:
                    message.tradePartiesGroup.push(OrderPartiesGroup.decode(reader, reader.uint32()));
                    break;
                case 63:
                    message.settlementType = reader.string();
                    break;
                case 64:
                    message.splitValueDate = reader.bool();
                    break;
                case 65:
                    message.splitLegCurrency1 = reader.string();
                    break;
                case 66:
                    message.splitLegCurrency2 = reader.string();
                    break;
                case 67:
                    message.splitLegCurrency1SettlementDate = reader.string();
                    break;
                case 68:
                    message.splitLegCurrency2SettlementDate = reader.string();
                    break;
                case 69:
                    message.orderMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 70:
                    message.orderSource = reader.string();
                    break;
                case 71:
                    message.settlCurrency = reader.string();
                    break;
                case 72:
                    message.rateSource = reader.string();
                    break;
                case 73:
                    message.accountName = reader.string();
                    break;
                case 74:
                    message.stopPx = reader.double();
                    break;
                case 75:
                    message.sourceExecutionId = reader.string();
                    break;
                case 76:
                    message.destinationBackOfficeKey = reader.string();
                    break;
                case 77:
                    message.executionInstruction = reader.int32() as any;
                    break;
                case 78:
                    message.maxShow = reader.double();
                    break;
                case 79:
                    message.pegPriceType = reader.int32() as any;
                    break;
                case 80:
                    message.lastActionedBy = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FxOrderSnapshotDownstreamEvent>): FxOrderSnapshotDownstreamEvent {
        const message = createBaseFxOrderSnapshotDownstreamEvent();
        message.id = object.id ?? '0';
        message.orderId = object.orderId ?? '0';
        message.externalClOrderId = object.externalClOrderId ?? '';
        message.originalExternalClOrderId = object.originalExternalClOrderId ?? '';
        message.externalOrderId = object.externalOrderId ?? '';
        message.secondaryExecId = object.secondaryExecId ?? '';
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.securityIdSource = object.securityIdSource ?? '';
        message.externalSecurityCode = object.externalSecurityCode ?? '';
        message.externalSecurityId = object.externalSecurityId ?? '';
        message.orderType = object.orderType ?? 1;
        message.strategyType = object.strategyType ?? 1;
        message.accountType = object.accountType ?? 1;
        message.account = object.account ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.currency = object.currency ?? '';
        message.trader = object.trader ?? '';
        message.broker = object.broker ?? '';
        message.giveUp = object.giveUp ?? '';
        message.giveIn = object.giveIn ?? '';
        message.lastPx = object.lastPx ?? 0;
        message.lastQty = object.lastQty ?? 0;
        message.cumQty = object.cumQty ?? 0;
        message.leavesQty = object.leavesQty ?? 0;
        message.avgPx = object.avgPx ?? 0;
        message.orderStatus = object.orderStatus ?? 1;
        message.executionType = object.executionType ?? 1;
        message.orderText = object.orderText ?? '';
        message.info = object.info ?? '';
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.parentOrderId = object.parentOrderId ?? '0';
        message.orderVersion = object.orderVersion ?? 0;
        message.isParentOrder = object.isParentOrder ?? false;
        message.celerClOrderId = object.celerClOrderId ?? '';
        message.originalCelerClOrderId = object.originalCelerClOrderId ?? '0';
        message.isChildOrder = object.isChildOrder ?? false;
        message.createdTimestampUtcInMillis = object.createdTimestampUtcInMillis ?? '0';
        message.updatedTimestampUtcInMillis = object.updatedTimestampUtcInMillis ?? '0';
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.externalExchangeCode = object.externalExchangeCode ?? '';
        message.timeInForce = object.timeInForce ?? 1;
        message.timeInForceExpiry = object.timeInForceExpiry ?? '';
        message.minQty = object.minQty ?? 0;
        message.maxFloor = object.maxFloor ?? 0;
        message.quoteId = object.quoteId ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.checkpointTimestamp = object.checkpointTimestamp?.map((e) => e) || [];
        message.refId = object.refId ?? '0';
        message.bookAsFilled = object.bookAsFilled ?? false;
        message.productType = object.productType ?? 1;
        message.destinationKey = object.destinationKey ?? '';
        message.algoType = object.algoType ?? '';
        message.commission = object.commission ?? 0;
        message.leg = object.leg?.map((e) => FxLeg.fromPartial(e)) || [];
        message.tradeAllocGroup = object.tradeAllocGroup?.map((e) => OrderAllocGroup.fromPartial(e)) || [];
        message.tradePartiesGroup = object.tradePartiesGroup?.map((e) => OrderPartiesGroup.fromPartial(e)) || [];
        message.settlementType = object.settlementType ?? '';
        message.splitValueDate = object.splitValueDate ?? false;
        message.splitLegCurrency1 = object.splitLegCurrency1 ?? '';
        message.splitLegCurrency2 = object.splitLegCurrency2 ?? '';
        message.splitLegCurrency1SettlementDate = object.splitLegCurrency1SettlementDate ?? '';
        message.splitLegCurrency2SettlementDate = object.splitLegCurrency2SettlementDate ?? '';
        message.orderMetadata = object.orderMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.orderSource = object.orderSource ?? '';
        message.settlCurrency = object.settlCurrency ?? '';
        message.rateSource = object.rateSource ?? '';
        message.accountName = object.accountName ?? '';
        message.stopPx = object.stopPx ?? 0;
        message.sourceExecutionId = object.sourceExecutionId ?? '';
        message.destinationBackOfficeKey = object.destinationBackOfficeKey ?? '';
        message.executionInstruction = object.executionInstruction ?? 1;
        message.maxShow = object.maxShow ?? 0;
        message.pegPriceType = object.pegPriceType ?? 1;
        message.lastActionedBy = object.lastActionedBy ?? '';
        return message;
    }
};

function createBaseFxLeg(): FxLeg {
    return {
        underlyingExchangeCode: '',
        underlyingSecurityCode: '',
        underlyingSecurityId: '',
        settlementDate: '',
        qty: 0,
        side: 1,
        price: 0,
        legForwardPts: 0,
        spotPrice: 0,
        settlementType: '',
        maturityDate: ''
    };
}

export const FxLeg = {
    encode(message: FxLeg, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.underlyingExchangeCode !== '') {
            writer.uint32(18).string(message.underlyingExchangeCode);
        }
        if (message.underlyingSecurityCode !== '') {
            writer.uint32(26).string(message.underlyingSecurityCode);
        }
        if (message.underlyingSecurityId !== '') {
            writer.uint32(34).string(message.underlyingSecurityId);
        }
        if (message.settlementDate !== '') {
            writer.uint32(42).string(message.settlementDate);
        }
        if (message.qty !== 0) {
            writer.uint32(49).double(message.qty);
        }
        if (message.side !== 1) {
            writer.uint32(56).int32(message.side);
        }
        if (message.price !== 0) {
            writer.uint32(65).double(message.price);
        }
        if (message.legForwardPts !== 0) {
            writer.uint32(73).double(message.legForwardPts);
        }
        if (message.spotPrice !== 0) {
            writer.uint32(81).double(message.spotPrice);
        }
        if (message.settlementType !== '') {
            writer.uint32(90).string(message.settlementType);
        }
        if (message.maturityDate !== '') {
            writer.uint32(98).string(message.maturityDate);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FxLeg {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFxLeg();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.underlyingExchangeCode = reader.string();
                    break;
                case 3:
                    message.underlyingSecurityCode = reader.string();
                    break;
                case 4:
                    message.underlyingSecurityId = reader.string();
                    break;
                case 5:
                    message.settlementDate = reader.string();
                    break;
                case 6:
                    message.qty = reader.double();
                    break;
                case 7:
                    message.side = reader.int32() as any;
                    break;
                case 8:
                    message.price = reader.double();
                    break;
                case 9:
                    message.legForwardPts = reader.double();
                    break;
                case 10:
                    message.spotPrice = reader.double();
                    break;
                case 11:
                    message.settlementType = reader.string();
                    break;
                case 12:
                    message.maturityDate = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FxLeg>): FxLeg {
        const message = createBaseFxLeg();
        message.underlyingExchangeCode = object.underlyingExchangeCode ?? '';
        message.underlyingSecurityCode = object.underlyingSecurityCode ?? '';
        message.underlyingSecurityId = object.underlyingSecurityId ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.qty = object.qty ?? 0;
        message.side = object.side ?? 1;
        message.price = object.price ?? 0;
        message.legForwardPts = object.legForwardPts ?? 0;
        message.spotPrice = object.spotPrice ?? 0;
        message.settlementType = object.settlementType ?? '';
        message.maturityDate = object.maturityDate ?? '';
        return message;
    }
};

function createBaseCryptoOrderSnapshotDownstreamEvent(): CryptoOrderSnapshotDownstreamEvent {
    return {
        id: '0',
        orderId: '0',
        externalClOrderId: '',
        originalExternalClOrderId: '',
        externalOrderId: '',
        secondaryExecId: '',
        exchangeCode: '',
        securityCode: '',
        securityId: '',
        securityIdSource: '',
        externalSecurityCode: '',
        externalSecurityId: '',
        orderType: 1,
        strategyType: 1,
        accountType: 1,
        account: '',
        side: 1,
        qty: 0,
        price: 0,
        currency: '',
        trader: '',
        broker: '',
        giveUp: '',
        giveIn: '',
        lastPx: 0,
        lastQty: 0,
        cumQty: 0,
        leavesQty: 0,
        avgPx: 0,
        orderStatus: 1,
        executionType: 1,
        orderText: '',
        info: '',
        handlingInstruction: 1,
        parentOrderId: '0',
        orderVersion: 0,
        isParentOrder: false,
        celerClOrderId: '',
        originalCelerClOrderId: '0',
        isChildOrder: false,
        createdTimestampUtcInMillis: '0',
        updatedTimestampUtcInMillis: '0',
        onBehalfOfCompId: '',
        deliverToCompId: '',
        externalExchangeCode: '',
        timeInForce: 1,
        timeInForceExpiry: '',
        minQty: 0,
        maxFloor: 0,
        quoteId: '',
        settlementDate: '',
        checkpointTimestamp: [],
        refId: '0',
        bookAsFilled: false,
        productType: 1,
        destinationKey: '',
        algoType: '',
        commission: 0,
        leg: [],
        tradeAllocGroup: [],
        tradePartiesGroup: [],
        settlementType: '',
        splitValueDate: false,
        splitLegCurrency1: '',
        splitLegCurrency2: '',
        splitLegCurrency1SettlementDate: '',
        splitLegCurrency2SettlementDate: '',
        orderMetadata: [],
        orderSource: '',
        settlCurrency: '',
        rateSource: '',
        accountName: '',
        stopPx: 0,
        destinationBackOfficeKey: ''
    };
}

export const CryptoOrderSnapshotDownstreamEvent = {
    encode(message: CryptoOrderSnapshotDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== '0') {
            writer.uint32(8).int64(message.id);
        }
        if (message.orderId !== '0') {
            writer.uint32(16).int64(message.orderId);
        }
        if (message.externalClOrderId !== '') {
            writer.uint32(26).string(message.externalClOrderId);
        }
        if (message.originalExternalClOrderId !== '') {
            writer.uint32(34).string(message.originalExternalClOrderId);
        }
        if (message.externalOrderId !== '') {
            writer.uint32(42).string(message.externalOrderId);
        }
        if (message.secondaryExecId !== '') {
            writer.uint32(50).string(message.secondaryExecId);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(58).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(66).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(74).string(message.securityId);
        }
        if (message.securityIdSource !== '') {
            writer.uint32(82).string(message.securityIdSource);
        }
        if (message.externalSecurityCode !== '') {
            writer.uint32(90).string(message.externalSecurityCode);
        }
        if (message.externalSecurityId !== '') {
            writer.uint32(98).string(message.externalSecurityId);
        }
        if (message.orderType !== 1) {
            writer.uint32(104).int32(message.orderType);
        }
        if (message.strategyType !== 1) {
            writer.uint32(112).int32(message.strategyType);
        }
        if (message.accountType !== 1) {
            writer.uint32(120).int32(message.accountType);
        }
        if (message.account !== '') {
            writer.uint32(130).string(message.account);
        }
        if (message.side !== 1) {
            writer.uint32(136).int32(message.side);
        }
        if (message.qty !== 0) {
            writer.uint32(145).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(153).double(message.price);
        }
        if (message.currency !== '') {
            writer.uint32(162).string(message.currency);
        }
        if (message.trader !== '') {
            writer.uint32(170).string(message.trader);
        }
        if (message.broker !== '') {
            writer.uint32(178).string(message.broker);
        }
        if (message.giveUp !== '') {
            writer.uint32(186).string(message.giveUp);
        }
        if (message.giveIn !== '') {
            writer.uint32(194).string(message.giveIn);
        }
        if (message.lastPx !== 0) {
            writer.uint32(201).double(message.lastPx);
        }
        if (message.lastQty !== 0) {
            writer.uint32(209).double(message.lastQty);
        }
        if (message.cumQty !== 0) {
            writer.uint32(217).double(message.cumQty);
        }
        if (message.leavesQty !== 0) {
            writer.uint32(225).double(message.leavesQty);
        }
        if (message.avgPx !== 0) {
            writer.uint32(233).double(message.avgPx);
        }
        if (message.orderStatus !== 1) {
            writer.uint32(240).int32(message.orderStatus);
        }
        if (message.executionType !== 1) {
            writer.uint32(248).int32(message.executionType);
        }
        if (message.orderText !== '') {
            writer.uint32(258).string(message.orderText);
        }
        if (message.info !== '') {
            writer.uint32(266).string(message.info);
        }
        if (message.handlingInstruction !== 1) {
            writer.uint32(272).int32(message.handlingInstruction);
        }
        if (message.parentOrderId !== '0') {
            writer.uint32(280).int64(message.parentOrderId);
        }
        if (message.orderVersion !== 0) {
            writer.uint32(288).int32(message.orderVersion);
        }
        if (message.isParentOrder === true) {
            writer.uint32(296).bool(message.isParentOrder);
        }
        if (message.celerClOrderId !== '') {
            writer.uint32(306).string(message.celerClOrderId);
        }
        if (message.originalCelerClOrderId !== '0') {
            writer.uint32(312).int64(message.originalCelerClOrderId);
        }
        if (message.isChildOrder === true) {
            writer.uint32(320).bool(message.isChildOrder);
        }
        if (message.createdTimestampUtcInMillis !== '0') {
            writer.uint32(336).int64(message.createdTimestampUtcInMillis);
        }
        if (message.updatedTimestampUtcInMillis !== '0') {
            writer.uint32(344).int64(message.updatedTimestampUtcInMillis);
        }
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(354).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(362).string(message.deliverToCompId);
        }
        if (message.externalExchangeCode !== '') {
            writer.uint32(370).string(message.externalExchangeCode);
        }
        if (message.timeInForce !== 1) {
            writer.uint32(376).int32(message.timeInForce);
        }
        if (message.timeInForceExpiry !== '') {
            writer.uint32(386).string(message.timeInForceExpiry);
        }
        if (message.minQty !== 0) {
            writer.uint32(393).double(message.minQty);
        }
        if (message.maxFloor !== 0) {
            writer.uint32(401).double(message.maxFloor);
        }
        if (message.quoteId !== '') {
            writer.uint32(410).string(message.quoteId);
        }
        if (message.settlementDate !== '') {
            writer.uint32(418).string(message.settlementDate);
        }
        writer.uint32(426).fork();
        for (const v of message.checkpointTimestamp) {
            writer.int64(v);
        }
        writer.ldelim();
        if (message.refId !== '0') {
            writer.uint32(432).int64(message.refId);
        }
        if (message.bookAsFilled === true) {
            writer.uint32(440).bool(message.bookAsFilled);
        }
        if (message.productType !== 1) {
            writer.uint32(448).int32(message.productType);
        }
        if (message.destinationKey !== '') {
            writer.uint32(458).string(message.destinationKey);
        }
        if (message.algoType !== '') {
            writer.uint32(466).string(message.algoType);
        }
        if (message.commission !== 0) {
            writer.uint32(473).double(message.commission);
        }
        for (const v of message.leg) {
            CryptoLeg.encode(v!, writer.uint32(482).fork()).ldelim();
        }
        for (const v of message.tradeAllocGroup) {
            OrderAllocGroup.encode(v!, writer.uint32(490).fork()).ldelim();
        }
        for (const v of message.tradePartiesGroup) {
            OrderPartiesGroup.encode(v!, writer.uint32(498).fork()).ldelim();
        }
        if (message.settlementType !== '') {
            writer.uint32(506).string(message.settlementType);
        }
        if (message.splitValueDate === true) {
            writer.uint32(512).bool(message.splitValueDate);
        }
        if (message.splitLegCurrency1 !== '') {
            writer.uint32(522).string(message.splitLegCurrency1);
        }
        if (message.splitLegCurrency2 !== '') {
            writer.uint32(530).string(message.splitLegCurrency2);
        }
        if (message.splitLegCurrency1SettlementDate !== '') {
            writer.uint32(538).string(message.splitLegCurrency1SettlementDate);
        }
        if (message.splitLegCurrency2SettlementDate !== '') {
            writer.uint32(546).string(message.splitLegCurrency2SettlementDate);
        }
        for (const v of message.orderMetadata) {
            KeyValue.encode(v!, writer.uint32(554).fork()).ldelim();
        }
        if (message.orderSource !== '') {
            writer.uint32(562).string(message.orderSource);
        }
        if (message.settlCurrency !== '') {
            writer.uint32(570).string(message.settlCurrency);
        }
        if (message.rateSource !== '') {
            writer.uint32(578).string(message.rateSource);
        }
        if (message.accountName !== '') {
            writer.uint32(586).string(message.accountName);
        }
        if (message.stopPx !== 0) {
            writer.uint32(593).double(message.stopPx);
        }
        if (message.destinationBackOfficeKey !== '') {
            writer.uint32(602).string(message.destinationBackOfficeKey);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CryptoOrderSnapshotDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCryptoOrderSnapshotDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.externalClOrderId = reader.string();
                    break;
                case 4:
                    message.originalExternalClOrderId = reader.string();
                    break;
                case 5:
                    message.externalOrderId = reader.string();
                    break;
                case 6:
                    message.secondaryExecId = reader.string();
                    break;
                case 7:
                    message.exchangeCode = reader.string();
                    break;
                case 8:
                    message.securityCode = reader.string();
                    break;
                case 9:
                    message.securityId = reader.string();
                    break;
                case 10:
                    message.securityIdSource = reader.string();
                    break;
                case 11:
                    message.externalSecurityCode = reader.string();
                    break;
                case 12:
                    message.externalSecurityId = reader.string();
                    break;
                case 13:
                    message.orderType = reader.int32() as any;
                    break;
                case 14:
                    message.strategyType = reader.int32() as any;
                    break;
                case 15:
                    message.accountType = reader.int32() as any;
                    break;
                case 16:
                    message.account = reader.string();
                    break;
                case 17:
                    message.side = reader.int32() as any;
                    break;
                case 18:
                    message.qty = reader.double();
                    break;
                case 19:
                    message.price = reader.double();
                    break;
                case 20:
                    message.currency = reader.string();
                    break;
                case 21:
                    message.trader = reader.string();
                    break;
                case 22:
                    message.broker = reader.string();
                    break;
                case 23:
                    message.giveUp = reader.string();
                    break;
                case 24:
                    message.giveIn = reader.string();
                    break;
                case 25:
                    message.lastPx = reader.double();
                    break;
                case 26:
                    message.lastQty = reader.double();
                    break;
                case 27:
                    message.cumQty = reader.double();
                    break;
                case 28:
                    message.leavesQty = reader.double();
                    break;
                case 29:
                    message.avgPx = reader.double();
                    break;
                case 30:
                    message.orderStatus = reader.int32() as any;
                    break;
                case 31:
                    message.executionType = reader.int32() as any;
                    break;
                case 32:
                    message.orderText = reader.string();
                    break;
                case 33:
                    message.info = reader.string();
                    break;
                case 34:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 35:
                    message.parentOrderId = longToString(reader.int64() as Long);
                    break;
                case 36:
                    message.orderVersion = reader.int32();
                    break;
                case 37:
                    message.isParentOrder = reader.bool();
                    break;
                case 38:
                    message.celerClOrderId = reader.string();
                    break;
                case 39:
                    message.originalCelerClOrderId = longToString(reader.int64() as Long);
                    break;
                case 40:
                    message.isChildOrder = reader.bool();
                    break;
                case 42:
                    message.createdTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 43:
                    message.updatedTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 44:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 45:
                    message.deliverToCompId = reader.string();
                    break;
                case 46:
                    message.externalExchangeCode = reader.string();
                    break;
                case 47:
                    message.timeInForce = reader.int32() as any;
                    break;
                case 48:
                    message.timeInForceExpiry = reader.string();
                    break;
                case 49:
                    message.minQty = reader.double();
                    break;
                case 50:
                    message.maxFloor = reader.double();
                    break;
                case 51:
                    message.quoteId = reader.string();
                    break;
                case 52:
                    message.settlementDate = reader.string();
                    break;
                case 53:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                        }
                    } else {
                        message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                    }
                    break;
                case 54:
                    message.refId = longToString(reader.int64() as Long);
                    break;
                case 55:
                    message.bookAsFilled = reader.bool();
                    break;
                case 56:
                    message.productType = reader.int32() as any;
                    break;
                case 57:
                    message.destinationKey = reader.string();
                    break;
                case 58:
                    message.algoType = reader.string();
                    break;
                case 59:
                    message.commission = reader.double();
                    break;
                case 60:
                    message.leg.push(CryptoLeg.decode(reader, reader.uint32()));
                    break;
                case 61:
                    message.tradeAllocGroup.push(OrderAllocGroup.decode(reader, reader.uint32()));
                    break;
                case 62:
                    message.tradePartiesGroup.push(OrderPartiesGroup.decode(reader, reader.uint32()));
                    break;
                case 63:
                    message.settlementType = reader.string();
                    break;
                case 64:
                    message.splitValueDate = reader.bool();
                    break;
                case 65:
                    message.splitLegCurrency1 = reader.string();
                    break;
                case 66:
                    message.splitLegCurrency2 = reader.string();
                    break;
                case 67:
                    message.splitLegCurrency1SettlementDate = reader.string();
                    break;
                case 68:
                    message.splitLegCurrency2SettlementDate = reader.string();
                    break;
                case 69:
                    message.orderMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 70:
                    message.orderSource = reader.string();
                    break;
                case 71:
                    message.settlCurrency = reader.string();
                    break;
                case 72:
                    message.rateSource = reader.string();
                    break;
                case 73:
                    message.accountName = reader.string();
                    break;
                case 74:
                    message.stopPx = reader.double();
                    break;
                case 75:
                    message.destinationBackOfficeKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CryptoOrderSnapshotDownstreamEvent>): CryptoOrderSnapshotDownstreamEvent {
        const message = createBaseCryptoOrderSnapshotDownstreamEvent();
        message.id = object.id ?? '0';
        message.orderId = object.orderId ?? '0';
        message.externalClOrderId = object.externalClOrderId ?? '';
        message.originalExternalClOrderId = object.originalExternalClOrderId ?? '';
        message.externalOrderId = object.externalOrderId ?? '';
        message.secondaryExecId = object.secondaryExecId ?? '';
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.securityIdSource = object.securityIdSource ?? '';
        message.externalSecurityCode = object.externalSecurityCode ?? '';
        message.externalSecurityId = object.externalSecurityId ?? '';
        message.orderType = object.orderType ?? 1;
        message.strategyType = object.strategyType ?? 1;
        message.accountType = object.accountType ?? 1;
        message.account = object.account ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.currency = object.currency ?? '';
        message.trader = object.trader ?? '';
        message.broker = object.broker ?? '';
        message.giveUp = object.giveUp ?? '';
        message.giveIn = object.giveIn ?? '';
        message.lastPx = object.lastPx ?? 0;
        message.lastQty = object.lastQty ?? 0;
        message.cumQty = object.cumQty ?? 0;
        message.leavesQty = object.leavesQty ?? 0;
        message.avgPx = object.avgPx ?? 0;
        message.orderStatus = object.orderStatus ?? 1;
        message.executionType = object.executionType ?? 1;
        message.orderText = object.orderText ?? '';
        message.info = object.info ?? '';
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.parentOrderId = object.parentOrderId ?? '0';
        message.orderVersion = object.orderVersion ?? 0;
        message.isParentOrder = object.isParentOrder ?? false;
        message.celerClOrderId = object.celerClOrderId ?? '';
        message.originalCelerClOrderId = object.originalCelerClOrderId ?? '0';
        message.isChildOrder = object.isChildOrder ?? false;
        message.createdTimestampUtcInMillis = object.createdTimestampUtcInMillis ?? '0';
        message.updatedTimestampUtcInMillis = object.updatedTimestampUtcInMillis ?? '0';
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.externalExchangeCode = object.externalExchangeCode ?? '';
        message.timeInForce = object.timeInForce ?? 1;
        message.timeInForceExpiry = object.timeInForceExpiry ?? '';
        message.minQty = object.minQty ?? 0;
        message.maxFloor = object.maxFloor ?? 0;
        message.quoteId = object.quoteId ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.checkpointTimestamp = object.checkpointTimestamp?.map((e) => e) || [];
        message.refId = object.refId ?? '0';
        message.bookAsFilled = object.bookAsFilled ?? false;
        message.productType = object.productType ?? 1;
        message.destinationKey = object.destinationKey ?? '';
        message.algoType = object.algoType ?? '';
        message.commission = object.commission ?? 0;
        message.leg = object.leg?.map((e) => CryptoLeg.fromPartial(e)) || [];
        message.tradeAllocGroup = object.tradeAllocGroup?.map((e) => OrderAllocGroup.fromPartial(e)) || [];
        message.tradePartiesGroup = object.tradePartiesGroup?.map((e) => OrderPartiesGroup.fromPartial(e)) || [];
        message.settlementType = object.settlementType ?? '';
        message.splitValueDate = object.splitValueDate ?? false;
        message.splitLegCurrency1 = object.splitLegCurrency1 ?? '';
        message.splitLegCurrency2 = object.splitLegCurrency2 ?? '';
        message.splitLegCurrency1SettlementDate = object.splitLegCurrency1SettlementDate ?? '';
        message.splitLegCurrency2SettlementDate = object.splitLegCurrency2SettlementDate ?? '';
        message.orderMetadata = object.orderMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.orderSource = object.orderSource ?? '';
        message.settlCurrency = object.settlCurrency ?? '';
        message.rateSource = object.rateSource ?? '';
        message.accountName = object.accountName ?? '';
        message.stopPx = object.stopPx ?? 0;
        message.destinationBackOfficeKey = object.destinationBackOfficeKey ?? '';
        return message;
    }
};

function createBaseCryptoLeg(): CryptoLeg {
    return {
        underlyingExchangeCode: '',
        underlyingSecurityCode: '',
        underlyingSecurityId: '',
        settlementDate: '',
        qty: 0,
        side: 1,
        price: 0,
        legForwardPts: 0,
        spotPrice: 0,
        settlementType: '',
        maturityDate: ''
    };
}

export const CryptoLeg = {
    encode(message: CryptoLeg, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.underlyingExchangeCode !== '') {
            writer.uint32(18).string(message.underlyingExchangeCode);
        }
        if (message.underlyingSecurityCode !== '') {
            writer.uint32(26).string(message.underlyingSecurityCode);
        }
        if (message.underlyingSecurityId !== '') {
            writer.uint32(34).string(message.underlyingSecurityId);
        }
        if (message.settlementDate !== '') {
            writer.uint32(42).string(message.settlementDate);
        }
        if (message.qty !== 0) {
            writer.uint32(49).double(message.qty);
        }
        if (message.side !== 1) {
            writer.uint32(56).int32(message.side);
        }
        if (message.price !== 0) {
            writer.uint32(65).double(message.price);
        }
        if (message.legForwardPts !== 0) {
            writer.uint32(73).double(message.legForwardPts);
        }
        if (message.spotPrice !== 0) {
            writer.uint32(81).double(message.spotPrice);
        }
        if (message.settlementType !== '') {
            writer.uint32(90).string(message.settlementType);
        }
        if (message.maturityDate !== '') {
            writer.uint32(98).string(message.maturityDate);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CryptoLeg {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCryptoLeg();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.underlyingExchangeCode = reader.string();
                    break;
                case 3:
                    message.underlyingSecurityCode = reader.string();
                    break;
                case 4:
                    message.underlyingSecurityId = reader.string();
                    break;
                case 5:
                    message.settlementDate = reader.string();
                    break;
                case 6:
                    message.qty = reader.double();
                    break;
                case 7:
                    message.side = reader.int32() as any;
                    break;
                case 8:
                    message.price = reader.double();
                    break;
                case 9:
                    message.legForwardPts = reader.double();
                    break;
                case 10:
                    message.spotPrice = reader.double();
                    break;
                case 11:
                    message.settlementType = reader.string();
                    break;
                case 12:
                    message.maturityDate = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CryptoLeg>): CryptoLeg {
        const message = createBaseCryptoLeg();
        message.underlyingExchangeCode = object.underlyingExchangeCode ?? '';
        message.underlyingSecurityCode = object.underlyingSecurityCode ?? '';
        message.underlyingSecurityId = object.underlyingSecurityId ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.qty = object.qty ?? 0;
        message.side = object.side ?? 1;
        message.price = object.price ?? 0;
        message.legForwardPts = object.legForwardPts ?? 0;
        message.spotPrice = object.spotPrice ?? 0;
        message.settlementType = object.settlementType ?? '';
        message.maturityDate = object.maturityDate ?? '';
        return message;
    }
};

function createBaseFixedIncomeOrderSnapshotDownstreamEvent(): FixedIncomeOrderSnapshotDownstreamEvent {
    return {
        id: '0',
        orderId: '0',
        externalClOrderId: '',
        originalExternalClOrderId: '',
        externalOrderId: '',
        secondaryExecId: '',
        exchangeCode: '',
        securityCode: '',
        securityId: '',
        securityIdSource: '',
        externalSecurityCode: '',
        externalSecurityId: '',
        orderType: 1,
        strategyType: 1,
        accountType: 1,
        account: '',
        side: 1,
        qty: 0,
        price: 0,
        currency: '',
        trader: '',
        broker: '',
        giveUp: '',
        giveIn: '',
        lastPx: 0,
        lastQty: 0,
        cumQty: 0,
        leavesQty: 0,
        avgPx: 0,
        orderStatus: 1,
        executionType: 1,
        orderText: '',
        info: '',
        handlingInstruction: 1,
        parentOrderId: '0',
        orderVersion: 0,
        isParentOrder: false,
        celerClOrderId: '',
        originalCelerClOrderId: '0',
        isChildOrder: false,
        createdTimestampUtcInMillis: '0',
        updatedTimestampUtcInMillis: '0',
        onBehalfOfCompId: '',
        deliverToCompId: '',
        externalExchangeCode: '',
        timeInForce: 1,
        timeInForceExpiry: '',
        minQty: 0,
        maxFloor: 0,
        quoteId: '',
        settlementDate: '',
        maturityDate: '',
        checkpointTimestamp: [],
        refId: '0',
        bookAsFilled: false,
        productType: 1,
        destinationKey: '',
        algoType: '',
        commission: 0,
        tradeAllocGroup: [],
        tradePartiesGroup: [],
        settlementType: '',
        orderMetadata: [],
        orderSource: '',
        accountName: '',
        sourceExecutionId: '',
        destinationBackOfficeKey: ''
    };
}

export const FixedIncomeOrderSnapshotDownstreamEvent = {
    encode(message: FixedIncomeOrderSnapshotDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== '0') {
            writer.uint32(8).int64(message.id);
        }
        if (message.orderId !== '0') {
            writer.uint32(16).int64(message.orderId);
        }
        if (message.externalClOrderId !== '') {
            writer.uint32(26).string(message.externalClOrderId);
        }
        if (message.originalExternalClOrderId !== '') {
            writer.uint32(34).string(message.originalExternalClOrderId);
        }
        if (message.externalOrderId !== '') {
            writer.uint32(42).string(message.externalOrderId);
        }
        if (message.secondaryExecId !== '') {
            writer.uint32(50).string(message.secondaryExecId);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(58).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(66).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(74).string(message.securityId);
        }
        if (message.securityIdSource !== '') {
            writer.uint32(82).string(message.securityIdSource);
        }
        if (message.externalSecurityCode !== '') {
            writer.uint32(90).string(message.externalSecurityCode);
        }
        if (message.externalSecurityId !== '') {
            writer.uint32(98).string(message.externalSecurityId);
        }
        if (message.orderType !== 1) {
            writer.uint32(104).int32(message.orderType);
        }
        if (message.strategyType !== 1) {
            writer.uint32(112).int32(message.strategyType);
        }
        if (message.accountType !== 1) {
            writer.uint32(120).int32(message.accountType);
        }
        if (message.account !== '') {
            writer.uint32(130).string(message.account);
        }
        if (message.side !== 1) {
            writer.uint32(136).int32(message.side);
        }
        if (message.qty !== 0) {
            writer.uint32(145).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(153).double(message.price);
        }
        if (message.currency !== '') {
            writer.uint32(162).string(message.currency);
        }
        if (message.trader !== '') {
            writer.uint32(170).string(message.trader);
        }
        if (message.broker !== '') {
            writer.uint32(178).string(message.broker);
        }
        if (message.giveUp !== '') {
            writer.uint32(186).string(message.giveUp);
        }
        if (message.giveIn !== '') {
            writer.uint32(194).string(message.giveIn);
        }
        if (message.lastPx !== 0) {
            writer.uint32(201).double(message.lastPx);
        }
        if (message.lastQty !== 0) {
            writer.uint32(209).double(message.lastQty);
        }
        if (message.cumQty !== 0) {
            writer.uint32(217).double(message.cumQty);
        }
        if (message.leavesQty !== 0) {
            writer.uint32(225).double(message.leavesQty);
        }
        if (message.avgPx !== 0) {
            writer.uint32(233).double(message.avgPx);
        }
        if (message.orderStatus !== 1) {
            writer.uint32(240).int32(message.orderStatus);
        }
        if (message.executionType !== 1) {
            writer.uint32(248).int32(message.executionType);
        }
        if (message.orderText !== '') {
            writer.uint32(258).string(message.orderText);
        }
        if (message.info !== '') {
            writer.uint32(266).string(message.info);
        }
        if (message.handlingInstruction !== 1) {
            writer.uint32(272).int32(message.handlingInstruction);
        }
        if (message.parentOrderId !== '0') {
            writer.uint32(280).int64(message.parentOrderId);
        }
        if (message.orderVersion !== 0) {
            writer.uint32(288).int32(message.orderVersion);
        }
        if (message.isParentOrder === true) {
            writer.uint32(296).bool(message.isParentOrder);
        }
        if (message.celerClOrderId !== '') {
            writer.uint32(306).string(message.celerClOrderId);
        }
        if (message.originalCelerClOrderId !== '0') {
            writer.uint32(312).int64(message.originalCelerClOrderId);
        }
        if (message.isChildOrder === true) {
            writer.uint32(320).bool(message.isChildOrder);
        }
        if (message.createdTimestampUtcInMillis !== '0') {
            writer.uint32(336).int64(message.createdTimestampUtcInMillis);
        }
        if (message.updatedTimestampUtcInMillis !== '0') {
            writer.uint32(344).int64(message.updatedTimestampUtcInMillis);
        }
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(354).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(362).string(message.deliverToCompId);
        }
        if (message.externalExchangeCode !== '') {
            writer.uint32(370).string(message.externalExchangeCode);
        }
        if (message.timeInForce !== 1) {
            writer.uint32(376).int32(message.timeInForce);
        }
        if (message.timeInForceExpiry !== '') {
            writer.uint32(386).string(message.timeInForceExpiry);
        }
        if (message.minQty !== 0) {
            writer.uint32(393).double(message.minQty);
        }
        if (message.maxFloor !== 0) {
            writer.uint32(401).double(message.maxFloor);
        }
        if (message.quoteId !== '') {
            writer.uint32(410).string(message.quoteId);
        }
        if (message.settlementDate !== '') {
            writer.uint32(418).string(message.settlementDate);
        }
        if (message.maturityDate !== '') {
            writer.uint32(426).string(message.maturityDate);
        }
        writer.uint32(434).fork();
        for (const v of message.checkpointTimestamp) {
            writer.int64(v);
        }
        writer.ldelim();
        if (message.refId !== '0') {
            writer.uint32(440).int64(message.refId);
        }
        if (message.bookAsFilled === true) {
            writer.uint32(448).bool(message.bookAsFilled);
        }
        if (message.productType !== 1) {
            writer.uint32(456).int32(message.productType);
        }
        if (message.destinationKey !== '') {
            writer.uint32(466).string(message.destinationKey);
        }
        if (message.algoType !== '') {
            writer.uint32(474).string(message.algoType);
        }
        if (message.commission !== 0) {
            writer.uint32(481).double(message.commission);
        }
        for (const v of message.tradeAllocGroup) {
            OrderAllocGroup.encode(v!, writer.uint32(490).fork()).ldelim();
        }
        for (const v of message.tradePartiesGroup) {
            OrderPartiesGroup.encode(v!, writer.uint32(498).fork()).ldelim();
        }
        if (message.settlementType !== '') {
            writer.uint32(506).string(message.settlementType);
        }
        for (const v of message.orderMetadata) {
            KeyValue.encode(v!, writer.uint32(514).fork()).ldelim();
        }
        if (message.orderSource !== '') {
            writer.uint32(522).string(message.orderSource);
        }
        if (message.accountName !== '') {
            writer.uint32(530).string(message.accountName);
        }
        if (message.sourceExecutionId !== '') {
            writer.uint32(538).string(message.sourceExecutionId);
        }
        if (message.destinationBackOfficeKey !== '') {
            writer.uint32(546).string(message.destinationBackOfficeKey);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FixedIncomeOrderSnapshotDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFixedIncomeOrderSnapshotDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.externalClOrderId = reader.string();
                    break;
                case 4:
                    message.originalExternalClOrderId = reader.string();
                    break;
                case 5:
                    message.externalOrderId = reader.string();
                    break;
                case 6:
                    message.secondaryExecId = reader.string();
                    break;
                case 7:
                    message.exchangeCode = reader.string();
                    break;
                case 8:
                    message.securityCode = reader.string();
                    break;
                case 9:
                    message.securityId = reader.string();
                    break;
                case 10:
                    message.securityIdSource = reader.string();
                    break;
                case 11:
                    message.externalSecurityCode = reader.string();
                    break;
                case 12:
                    message.externalSecurityId = reader.string();
                    break;
                case 13:
                    message.orderType = reader.int32() as any;
                    break;
                case 14:
                    message.strategyType = reader.int32() as any;
                    break;
                case 15:
                    message.accountType = reader.int32() as any;
                    break;
                case 16:
                    message.account = reader.string();
                    break;
                case 17:
                    message.side = reader.int32() as any;
                    break;
                case 18:
                    message.qty = reader.double();
                    break;
                case 19:
                    message.price = reader.double();
                    break;
                case 20:
                    message.currency = reader.string();
                    break;
                case 21:
                    message.trader = reader.string();
                    break;
                case 22:
                    message.broker = reader.string();
                    break;
                case 23:
                    message.giveUp = reader.string();
                    break;
                case 24:
                    message.giveIn = reader.string();
                    break;
                case 25:
                    message.lastPx = reader.double();
                    break;
                case 26:
                    message.lastQty = reader.double();
                    break;
                case 27:
                    message.cumQty = reader.double();
                    break;
                case 28:
                    message.leavesQty = reader.double();
                    break;
                case 29:
                    message.avgPx = reader.double();
                    break;
                case 30:
                    message.orderStatus = reader.int32() as any;
                    break;
                case 31:
                    message.executionType = reader.int32() as any;
                    break;
                case 32:
                    message.orderText = reader.string();
                    break;
                case 33:
                    message.info = reader.string();
                    break;
                case 34:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 35:
                    message.parentOrderId = longToString(reader.int64() as Long);
                    break;
                case 36:
                    message.orderVersion = reader.int32();
                    break;
                case 37:
                    message.isParentOrder = reader.bool();
                    break;
                case 38:
                    message.celerClOrderId = reader.string();
                    break;
                case 39:
                    message.originalCelerClOrderId = longToString(reader.int64() as Long);
                    break;
                case 40:
                    message.isChildOrder = reader.bool();
                    break;
                case 42:
                    message.createdTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 43:
                    message.updatedTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 44:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 45:
                    message.deliverToCompId = reader.string();
                    break;
                case 46:
                    message.externalExchangeCode = reader.string();
                    break;
                case 47:
                    message.timeInForce = reader.int32() as any;
                    break;
                case 48:
                    message.timeInForceExpiry = reader.string();
                    break;
                case 49:
                    message.minQty = reader.double();
                    break;
                case 50:
                    message.maxFloor = reader.double();
                    break;
                case 51:
                    message.quoteId = reader.string();
                    break;
                case 52:
                    message.settlementDate = reader.string();
                    break;
                case 53:
                    message.maturityDate = reader.string();
                    break;
                case 54:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                        }
                    } else {
                        message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                    }
                    break;
                case 55:
                    message.refId = longToString(reader.int64() as Long);
                    break;
                case 56:
                    message.bookAsFilled = reader.bool();
                    break;
                case 57:
                    message.productType = reader.int32() as any;
                    break;
                case 58:
                    message.destinationKey = reader.string();
                    break;
                case 59:
                    message.algoType = reader.string();
                    break;
                case 60:
                    message.commission = reader.double();
                    break;
                case 61:
                    message.tradeAllocGroup.push(OrderAllocGroup.decode(reader, reader.uint32()));
                    break;
                case 62:
                    message.tradePartiesGroup.push(OrderPartiesGroup.decode(reader, reader.uint32()));
                    break;
                case 63:
                    message.settlementType = reader.string();
                    break;
                case 64:
                    message.orderMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 65:
                    message.orderSource = reader.string();
                    break;
                case 66:
                    message.accountName = reader.string();
                    break;
                case 67:
                    message.sourceExecutionId = reader.string();
                    break;
                case 68:
                    message.destinationBackOfficeKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FixedIncomeOrderSnapshotDownstreamEvent>): FixedIncomeOrderSnapshotDownstreamEvent {
        const message = createBaseFixedIncomeOrderSnapshotDownstreamEvent();
        message.id = object.id ?? '0';
        message.orderId = object.orderId ?? '0';
        message.externalClOrderId = object.externalClOrderId ?? '';
        message.originalExternalClOrderId = object.originalExternalClOrderId ?? '';
        message.externalOrderId = object.externalOrderId ?? '';
        message.secondaryExecId = object.secondaryExecId ?? '';
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.securityIdSource = object.securityIdSource ?? '';
        message.externalSecurityCode = object.externalSecurityCode ?? '';
        message.externalSecurityId = object.externalSecurityId ?? '';
        message.orderType = object.orderType ?? 1;
        message.strategyType = object.strategyType ?? 1;
        message.accountType = object.accountType ?? 1;
        message.account = object.account ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.currency = object.currency ?? '';
        message.trader = object.trader ?? '';
        message.broker = object.broker ?? '';
        message.giveUp = object.giveUp ?? '';
        message.giveIn = object.giveIn ?? '';
        message.lastPx = object.lastPx ?? 0;
        message.lastQty = object.lastQty ?? 0;
        message.cumQty = object.cumQty ?? 0;
        message.leavesQty = object.leavesQty ?? 0;
        message.avgPx = object.avgPx ?? 0;
        message.orderStatus = object.orderStatus ?? 1;
        message.executionType = object.executionType ?? 1;
        message.orderText = object.orderText ?? '';
        message.info = object.info ?? '';
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.parentOrderId = object.parentOrderId ?? '0';
        message.orderVersion = object.orderVersion ?? 0;
        message.isParentOrder = object.isParentOrder ?? false;
        message.celerClOrderId = object.celerClOrderId ?? '';
        message.originalCelerClOrderId = object.originalCelerClOrderId ?? '0';
        message.isChildOrder = object.isChildOrder ?? false;
        message.createdTimestampUtcInMillis = object.createdTimestampUtcInMillis ?? '0';
        message.updatedTimestampUtcInMillis = object.updatedTimestampUtcInMillis ?? '0';
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.externalExchangeCode = object.externalExchangeCode ?? '';
        message.timeInForce = object.timeInForce ?? 1;
        message.timeInForceExpiry = object.timeInForceExpiry ?? '';
        message.minQty = object.minQty ?? 0;
        message.maxFloor = object.maxFloor ?? 0;
        message.quoteId = object.quoteId ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.maturityDate = object.maturityDate ?? '';
        message.checkpointTimestamp = object.checkpointTimestamp?.map((e) => e) || [];
        message.refId = object.refId ?? '0';
        message.bookAsFilled = object.bookAsFilled ?? false;
        message.productType = object.productType ?? 1;
        message.destinationKey = object.destinationKey ?? '';
        message.algoType = object.algoType ?? '';
        message.commission = object.commission ?? 0;
        message.tradeAllocGroup = object.tradeAllocGroup?.map((e) => OrderAllocGroup.fromPartial(e)) || [];
        message.tradePartiesGroup = object.tradePartiesGroup?.map((e) => OrderPartiesGroup.fromPartial(e)) || [];
        message.settlementType = object.settlementType ?? '';
        message.orderMetadata = object.orderMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.orderSource = object.orderSource ?? '';
        message.accountName = object.accountName ?? '';
        message.sourceExecutionId = object.sourceExecutionId ?? '';
        message.destinationBackOfficeKey = object.destinationBackOfficeKey ?? '';
        return message;
    }
};

function createBaseEquityOrderSnapshotDownstreamEvent(): EquityOrderSnapshotDownstreamEvent {
    return {
        id: '0',
        orderId: '0',
        externalClOrderId: '',
        originalExternalClOrderId: '',
        externalOrderId: '',
        secondaryExecId: '',
        exchangeCode: '',
        securityCode: '',
        securityId: '',
        securityIdSource: '',
        externalSecurityCode: '',
        externalSecurityId: '',
        orderType: 1,
        accountType: 1,
        account: '',
        side: 1,
        qty: 0,
        price: 0,
        currency: '',
        trader: '',
        broker: '',
        giveUp: '',
        giveIn: '',
        lastPx: 0,
        lastQty: 0,
        cumQty: 0,
        leavesQty: 0,
        avgPx: 0,
        orderStatus: 1,
        executionType: 1,
        orderText: '',
        info: '',
        handlingInstruction: 1,
        parentOrderId: '0',
        orderVersion: 0,
        isParentOrder: false,
        celerClOrderId: '',
        originalCelerClOrderId: '0',
        isChildOrder: false,
        createdTimestampUtcInMillis: '0',
        updatedTimestampUtcInMillis: '0',
        onBehalfOfCompId: '',
        deliverToCompId: '',
        externalExchangeCode: '',
        timeInForce: 1,
        timeInForceExpiry: '',
        minQty: 0,
        maxFloor: 0,
        quoteId: '',
        refId: '0',
        bookAsFilled: false,
        destinationKey: '',
        algoType: '',
        checkpointTimestamp: [],
        tradeAllocGroup: [],
        tradePartiesGroup: [],
        orderMetadata: [],
        orderSource: '',
        accountName: '',
        stopPx: 0,
        productType: 1,
        sourceExecutionId: '',
        destinationBackOfficeKey: ''
    };
}

export const EquityOrderSnapshotDownstreamEvent = {
    encode(message: EquityOrderSnapshotDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== '0') {
            writer.uint32(8).int64(message.id);
        }
        if (message.orderId !== '0') {
            writer.uint32(16).int64(message.orderId);
        }
        if (message.externalClOrderId !== '') {
            writer.uint32(26).string(message.externalClOrderId);
        }
        if (message.originalExternalClOrderId !== '') {
            writer.uint32(34).string(message.originalExternalClOrderId);
        }
        if (message.externalOrderId !== '') {
            writer.uint32(42).string(message.externalOrderId);
        }
        if (message.secondaryExecId !== '') {
            writer.uint32(50).string(message.secondaryExecId);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(58).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(66).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(74).string(message.securityId);
        }
        if (message.securityIdSource !== '') {
            writer.uint32(82).string(message.securityIdSource);
        }
        if (message.externalSecurityCode !== '') {
            writer.uint32(90).string(message.externalSecurityCode);
        }
        if (message.externalSecurityId !== '') {
            writer.uint32(98).string(message.externalSecurityId);
        }
        if (message.orderType !== 1) {
            writer.uint32(104).int32(message.orderType);
        }
        if (message.accountType !== 1) {
            writer.uint32(112).int32(message.accountType);
        }
        if (message.account !== '') {
            writer.uint32(122).string(message.account);
        }
        if (message.side !== 1) {
            writer.uint32(128).int32(message.side);
        }
        if (message.qty !== 0) {
            writer.uint32(137).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(145).double(message.price);
        }
        if (message.currency !== '') {
            writer.uint32(154).string(message.currency);
        }
        if (message.trader !== '') {
            writer.uint32(162).string(message.trader);
        }
        if (message.broker !== '') {
            writer.uint32(170).string(message.broker);
        }
        if (message.giveUp !== '') {
            writer.uint32(178).string(message.giveUp);
        }
        if (message.giveIn !== '') {
            writer.uint32(186).string(message.giveIn);
        }
        if (message.lastPx !== 0) {
            writer.uint32(193).double(message.lastPx);
        }
        if (message.lastQty !== 0) {
            writer.uint32(201).double(message.lastQty);
        }
        if (message.cumQty !== 0) {
            writer.uint32(209).double(message.cumQty);
        }
        if (message.leavesQty !== 0) {
            writer.uint32(217).double(message.leavesQty);
        }
        if (message.avgPx !== 0) {
            writer.uint32(225).double(message.avgPx);
        }
        if (message.orderStatus !== 1) {
            writer.uint32(232).int32(message.orderStatus);
        }
        if (message.executionType !== 1) {
            writer.uint32(240).int32(message.executionType);
        }
        if (message.orderText !== '') {
            writer.uint32(250).string(message.orderText);
        }
        if (message.info !== '') {
            writer.uint32(258).string(message.info);
        }
        if (message.handlingInstruction !== 1) {
            writer.uint32(264).int32(message.handlingInstruction);
        }
        if (message.parentOrderId !== '0') {
            writer.uint32(272).int64(message.parentOrderId);
        }
        if (message.orderVersion !== 0) {
            writer.uint32(280).int32(message.orderVersion);
        }
        if (message.isParentOrder === true) {
            writer.uint32(288).bool(message.isParentOrder);
        }
        if (message.celerClOrderId !== '') {
            writer.uint32(298).string(message.celerClOrderId);
        }
        if (message.originalCelerClOrderId !== '0') {
            writer.uint32(304).int64(message.originalCelerClOrderId);
        }
        if (message.isChildOrder === true) {
            writer.uint32(312).bool(message.isChildOrder);
        }
        if (message.createdTimestampUtcInMillis !== '0') {
            writer.uint32(328).int64(message.createdTimestampUtcInMillis);
        }
        if (message.updatedTimestampUtcInMillis !== '0') {
            writer.uint32(336).int64(message.updatedTimestampUtcInMillis);
        }
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(346).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(354).string(message.deliverToCompId);
        }
        if (message.externalExchangeCode !== '') {
            writer.uint32(362).string(message.externalExchangeCode);
        }
        if (message.timeInForce !== 1) {
            writer.uint32(368).int32(message.timeInForce);
        }
        if (message.timeInForceExpiry !== '') {
            writer.uint32(378).string(message.timeInForceExpiry);
        }
        if (message.minQty !== 0) {
            writer.uint32(385).double(message.minQty);
        }
        if (message.maxFloor !== 0) {
            writer.uint32(393).double(message.maxFloor);
        }
        if (message.quoteId !== '') {
            writer.uint32(402).string(message.quoteId);
        }
        if (message.refId !== '0') {
            writer.uint32(408).int64(message.refId);
        }
        if (message.bookAsFilled === true) {
            writer.uint32(416).bool(message.bookAsFilled);
        }
        if (message.destinationKey !== '') {
            writer.uint32(450).string(message.destinationKey);
        }
        if (message.algoType !== '') {
            writer.uint32(458).string(message.algoType);
        }
        writer.uint32(466).fork();
        for (const v of message.checkpointTimestamp) {
            writer.int64(v);
        }
        writer.ldelim();
        for (const v of message.tradeAllocGroup) {
            OrderAllocGroup.encode(v!, writer.uint32(474).fork()).ldelim();
        }
        for (const v of message.tradePartiesGroup) {
            OrderPartiesGroup.encode(v!, writer.uint32(482).fork()).ldelim();
        }
        for (const v of message.orderMetadata) {
            KeyValue.encode(v!, writer.uint32(490).fork()).ldelim();
        }
        if (message.orderSource !== '') {
            writer.uint32(498).string(message.orderSource);
        }
        if (message.accountName !== '') {
            writer.uint32(506).string(message.accountName);
        }
        if (message.stopPx !== 0) {
            writer.uint32(513).double(message.stopPx);
        }
        if (message.productType !== 1) {
            writer.uint32(520).int32(message.productType);
        }
        if (message.sourceExecutionId !== '') {
            writer.uint32(530).string(message.sourceExecutionId);
        }
        if (message.destinationBackOfficeKey !== '') {
            writer.uint32(538).string(message.destinationBackOfficeKey);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): EquityOrderSnapshotDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEquityOrderSnapshotDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.externalClOrderId = reader.string();
                    break;
                case 4:
                    message.originalExternalClOrderId = reader.string();
                    break;
                case 5:
                    message.externalOrderId = reader.string();
                    break;
                case 6:
                    message.secondaryExecId = reader.string();
                    break;
                case 7:
                    message.exchangeCode = reader.string();
                    break;
                case 8:
                    message.securityCode = reader.string();
                    break;
                case 9:
                    message.securityId = reader.string();
                    break;
                case 10:
                    message.securityIdSource = reader.string();
                    break;
                case 11:
                    message.externalSecurityCode = reader.string();
                    break;
                case 12:
                    message.externalSecurityId = reader.string();
                    break;
                case 13:
                    message.orderType = reader.int32() as any;
                    break;
                case 14:
                    message.accountType = reader.int32() as any;
                    break;
                case 15:
                    message.account = reader.string();
                    break;
                case 16:
                    message.side = reader.int32() as any;
                    break;
                case 17:
                    message.qty = reader.double();
                    break;
                case 18:
                    message.price = reader.double();
                    break;
                case 19:
                    message.currency = reader.string();
                    break;
                case 20:
                    message.trader = reader.string();
                    break;
                case 21:
                    message.broker = reader.string();
                    break;
                case 22:
                    message.giveUp = reader.string();
                    break;
                case 23:
                    message.giveIn = reader.string();
                    break;
                case 24:
                    message.lastPx = reader.double();
                    break;
                case 25:
                    message.lastQty = reader.double();
                    break;
                case 26:
                    message.cumQty = reader.double();
                    break;
                case 27:
                    message.leavesQty = reader.double();
                    break;
                case 28:
                    message.avgPx = reader.double();
                    break;
                case 29:
                    message.orderStatus = reader.int32() as any;
                    break;
                case 30:
                    message.executionType = reader.int32() as any;
                    break;
                case 31:
                    message.orderText = reader.string();
                    break;
                case 32:
                    message.info = reader.string();
                    break;
                case 33:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 34:
                    message.parentOrderId = longToString(reader.int64() as Long);
                    break;
                case 35:
                    message.orderVersion = reader.int32();
                    break;
                case 36:
                    message.isParentOrder = reader.bool();
                    break;
                case 37:
                    message.celerClOrderId = reader.string();
                    break;
                case 38:
                    message.originalCelerClOrderId = longToString(reader.int64() as Long);
                    break;
                case 39:
                    message.isChildOrder = reader.bool();
                    break;
                case 41:
                    message.createdTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 42:
                    message.updatedTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 43:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 44:
                    message.deliverToCompId = reader.string();
                    break;
                case 45:
                    message.externalExchangeCode = reader.string();
                    break;
                case 46:
                    message.timeInForce = reader.int32() as any;
                    break;
                case 47:
                    message.timeInForceExpiry = reader.string();
                    break;
                case 48:
                    message.minQty = reader.double();
                    break;
                case 49:
                    message.maxFloor = reader.double();
                    break;
                case 50:
                    message.quoteId = reader.string();
                    break;
                case 51:
                    message.refId = longToString(reader.int64() as Long);
                    break;
                case 52:
                    message.bookAsFilled = reader.bool();
                    break;
                case 56:
                    message.destinationKey = reader.string();
                    break;
                case 57:
                    message.algoType = reader.string();
                    break;
                case 58:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                        }
                    } else {
                        message.checkpointTimestamp.push(longToString(reader.int64() as Long));
                    }
                    break;
                case 59:
                    message.tradeAllocGroup.push(OrderAllocGroup.decode(reader, reader.uint32()));
                    break;
                case 60:
                    message.tradePartiesGroup.push(OrderPartiesGroup.decode(reader, reader.uint32()));
                    break;
                case 61:
                    message.orderMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 62:
                    message.orderSource = reader.string();
                    break;
                case 63:
                    message.accountName = reader.string();
                    break;
                case 64:
                    message.stopPx = reader.double();
                    break;
                case 65:
                    message.productType = reader.int32() as any;
                    break;
                case 66:
                    message.sourceExecutionId = reader.string();
                    break;
                case 67:
                    message.destinationBackOfficeKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<EquityOrderSnapshotDownstreamEvent>): EquityOrderSnapshotDownstreamEvent {
        const message = createBaseEquityOrderSnapshotDownstreamEvent();
        message.id = object.id ?? '0';
        message.orderId = object.orderId ?? '0';
        message.externalClOrderId = object.externalClOrderId ?? '';
        message.originalExternalClOrderId = object.originalExternalClOrderId ?? '';
        message.externalOrderId = object.externalOrderId ?? '';
        message.secondaryExecId = object.secondaryExecId ?? '';
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.securityIdSource = object.securityIdSource ?? '';
        message.externalSecurityCode = object.externalSecurityCode ?? '';
        message.externalSecurityId = object.externalSecurityId ?? '';
        message.orderType = object.orderType ?? 1;
        message.accountType = object.accountType ?? 1;
        message.account = object.account ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.currency = object.currency ?? '';
        message.trader = object.trader ?? '';
        message.broker = object.broker ?? '';
        message.giveUp = object.giveUp ?? '';
        message.giveIn = object.giveIn ?? '';
        message.lastPx = object.lastPx ?? 0;
        message.lastQty = object.lastQty ?? 0;
        message.cumQty = object.cumQty ?? 0;
        message.leavesQty = object.leavesQty ?? 0;
        message.avgPx = object.avgPx ?? 0;
        message.orderStatus = object.orderStatus ?? 1;
        message.executionType = object.executionType ?? 1;
        message.orderText = object.orderText ?? '';
        message.info = object.info ?? '';
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.parentOrderId = object.parentOrderId ?? '0';
        message.orderVersion = object.orderVersion ?? 0;
        message.isParentOrder = object.isParentOrder ?? false;
        message.celerClOrderId = object.celerClOrderId ?? '';
        message.originalCelerClOrderId = object.originalCelerClOrderId ?? '0';
        message.isChildOrder = object.isChildOrder ?? false;
        message.createdTimestampUtcInMillis = object.createdTimestampUtcInMillis ?? '0';
        message.updatedTimestampUtcInMillis = object.updatedTimestampUtcInMillis ?? '0';
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.externalExchangeCode = object.externalExchangeCode ?? '';
        message.timeInForce = object.timeInForce ?? 1;
        message.timeInForceExpiry = object.timeInForceExpiry ?? '';
        message.minQty = object.minQty ?? 0;
        message.maxFloor = object.maxFloor ?? 0;
        message.quoteId = object.quoteId ?? '';
        message.refId = object.refId ?? '0';
        message.bookAsFilled = object.bookAsFilled ?? false;
        message.destinationKey = object.destinationKey ?? '';
        message.algoType = object.algoType ?? '';
        message.checkpointTimestamp = object.checkpointTimestamp?.map((e) => e) || [];
        message.tradeAllocGroup = object.tradeAllocGroup?.map((e) => OrderAllocGroup.fromPartial(e)) || [];
        message.tradePartiesGroup = object.tradePartiesGroup?.map((e) => OrderPartiesGroup.fromPartial(e)) || [];
        message.orderMetadata = object.orderMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.orderSource = object.orderSource ?? '';
        message.accountName = object.accountName ?? '';
        message.stopPx = object.stopPx ?? 0;
        message.productType = object.productType ?? 1;
        message.sourceExecutionId = object.sourceExecutionId ?? '';
        message.destinationBackOfficeKey = object.destinationBackOfficeKey ?? '';
        return message;
    }
};

function createBaseOrderRoutingDestinationCommandDownstreamEvent(): OrderRoutingDestinationCommandDownstreamEvent {
    return { operation: 0, orderRoutingProviderName: '', exchangeName: '' };
}

export const OrderRoutingDestinationCommandDownstreamEvent = {
    encode(
        message: OrderRoutingDestinationCommandDownstreamEvent,
        writer: _m0.Writer = _m0.Writer.create()
    ): _m0.Writer {
        if (message.operation !== 0) {
            writer.uint32(16).int32(message.operation);
        }
        if (message.orderRoutingProviderName !== '') {
            writer.uint32(26).string(message.orderRoutingProviderName);
        }
        if (message.exchangeName !== '') {
            writer.uint32(34).string(message.exchangeName);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): OrderRoutingDestinationCommandDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderRoutingDestinationCommandDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.operation = reader.int32() as any;
                    break;
                case 3:
                    message.orderRoutingProviderName = reader.string();
                    break;
                case 4:
                    message.exchangeName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<OrderRoutingDestinationCommandDownstreamEvent>
    ): OrderRoutingDestinationCommandDownstreamEvent {
        const message = createBaseOrderRoutingDestinationCommandDownstreamEvent();
        message.operation = object.operation ?? 0;
        message.orderRoutingProviderName = object.orderRoutingProviderName ?? '';
        message.exchangeName = object.exchangeName ?? '';
        return message;
    }
};

function createBaseCancelOrderRequestRejectDownstreamEvent(): CancelOrderRequestRejectDownstreamEvent {
    return {
        cancelRejectResponseTo: 1,
        cancelRejectReason: 1,
        orderId: '0',
        externalOrderId: '',
        externalClOrderId: '',
        originalExternalClOrderId: '',
        orderStatus: 1,
        qty: 0,
        price: 0,
        leavesQty: 0,
        text: '',
        onBehalfOfCompId: '',
        deliverToCompId: '',
        handlingInstruction: 1,
        isParentOrder: false,
        isChildOrder: false,
        trader: '',
        createdTimestampUtcInMillis: '0',
        orderVersion: 0
    };
}

export const CancelOrderRequestRejectDownstreamEvent = {
    encode(message: CancelOrderRequestRejectDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.cancelRejectResponseTo !== 1) {
            writer.uint32(8).int32(message.cancelRejectResponseTo);
        }
        if (message.cancelRejectReason !== 1) {
            writer.uint32(16).int32(message.cancelRejectReason);
        }
        if (message.orderId !== '0') {
            writer.uint32(24).int64(message.orderId);
        }
        if (message.externalOrderId !== '') {
            writer.uint32(34).string(message.externalOrderId);
        }
        if (message.externalClOrderId !== '') {
            writer.uint32(42).string(message.externalClOrderId);
        }
        if (message.originalExternalClOrderId !== '') {
            writer.uint32(50).string(message.originalExternalClOrderId);
        }
        if (message.orderStatus !== 1) {
            writer.uint32(56).int32(message.orderStatus);
        }
        if (message.qty !== 0) {
            writer.uint32(65).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(73).double(message.price);
        }
        if (message.leavesQty !== 0) {
            writer.uint32(81).double(message.leavesQty);
        }
        if (message.text !== '') {
            writer.uint32(90).string(message.text);
        }
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(98).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(106).string(message.deliverToCompId);
        }
        if (message.handlingInstruction !== 1) {
            writer.uint32(112).int32(message.handlingInstruction);
        }
        if (message.isParentOrder === true) {
            writer.uint32(120).bool(message.isParentOrder);
        }
        if (message.isChildOrder === true) {
            writer.uint32(128).bool(message.isChildOrder);
        }
        if (message.trader !== '') {
            writer.uint32(138).string(message.trader);
        }
        if (message.createdTimestampUtcInMillis !== '0') {
            writer.uint32(144).int64(message.createdTimestampUtcInMillis);
        }
        if (message.orderVersion !== 0) {
            writer.uint32(152).int32(message.orderVersion);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CancelOrderRequestRejectDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCancelOrderRequestRejectDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.cancelRejectResponseTo = reader.int32() as any;
                    break;
                case 2:
                    message.cancelRejectReason = reader.int32() as any;
                    break;
                case 3:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 4:
                    message.externalOrderId = reader.string();
                    break;
                case 5:
                    message.externalClOrderId = reader.string();
                    break;
                case 6:
                    message.originalExternalClOrderId = reader.string();
                    break;
                case 7:
                    message.orderStatus = reader.int32() as any;
                    break;
                case 8:
                    message.qty = reader.double();
                    break;
                case 9:
                    message.price = reader.double();
                    break;
                case 10:
                    message.leavesQty = reader.double();
                    break;
                case 11:
                    message.text = reader.string();
                    break;
                case 12:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 13:
                    message.deliverToCompId = reader.string();
                    break;
                case 14:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 15:
                    message.isParentOrder = reader.bool();
                    break;
                case 16:
                    message.isChildOrder = reader.bool();
                    break;
                case 17:
                    message.trader = reader.string();
                    break;
                case 18:
                    message.createdTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 19:
                    message.orderVersion = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CancelOrderRequestRejectDownstreamEvent>): CancelOrderRequestRejectDownstreamEvent {
        const message = createBaseCancelOrderRequestRejectDownstreamEvent();
        message.cancelRejectResponseTo = object.cancelRejectResponseTo ?? 1;
        message.cancelRejectReason = object.cancelRejectReason ?? 1;
        message.orderId = object.orderId ?? '0';
        message.externalOrderId = object.externalOrderId ?? '';
        message.externalClOrderId = object.externalClOrderId ?? '';
        message.originalExternalClOrderId = object.originalExternalClOrderId ?? '';
        message.orderStatus = object.orderStatus ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.leavesQty = object.leavesQty ?? 0;
        message.text = object.text ?? '';
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.isParentOrder = object.isParentOrder ?? false;
        message.isChildOrder = object.isChildOrder ?? false;
        message.trader = object.trader ?? '';
        message.createdTimestampUtcInMillis = object.createdTimestampUtcInMillis ?? '0';
        message.orderVersion = object.orderVersion ?? 0;
        return message;
    }
};

function createBaseCreateOrderRequestRejectDownstreamEvent(): CreateOrderRequestRejectDownstreamEvent {
    return {
        refSequenceNum: '0',
        refMessageType: '',
        rejectReason: '',
        onBehalfOfCompId: '',
        externalClOrderId: '',
        side: 1,
        securityCode: '',
        securityId: '',
        securityIdSource: '',
        externalSecurityCode: '',
        externalSecurityId: '',
        qty: 0,
        orderType: 1,
        price: 0,
        timeInForce: 1,
        handlingInstruction: 1,
        createdTimestampUtcInMillis: '0',
        exchangeCode: '',
        externalExchangeCode: '',
        execId: '',
        deliverToCompId: '',
        productType: 1,
        currencyCode: '',
        securityType: '',
        settlementType: '',
        settlementDate: '',
        account: '',
        orderMetadata: [],
        fixingDate: ''
    };
}

export const CreateOrderRequestRejectDownstreamEvent = {
    encode(message: CreateOrderRequestRejectDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.refSequenceNum !== '0') {
            writer.uint32(8).int64(message.refSequenceNum);
        }
        if (message.refMessageType !== '') {
            writer.uint32(18).string(message.refMessageType);
        }
        if (message.rejectReason !== '') {
            writer.uint32(26).string(message.rejectReason);
        }
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(34).string(message.onBehalfOfCompId);
        }
        if (message.externalClOrderId !== '') {
            writer.uint32(42).string(message.externalClOrderId);
        }
        if (message.side !== 1) {
            writer.uint32(48).int32(message.side);
        }
        if (message.securityCode !== '') {
            writer.uint32(58).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(66).string(message.securityId);
        }
        if (message.securityIdSource !== '') {
            writer.uint32(74).string(message.securityIdSource);
        }
        if (message.externalSecurityCode !== '') {
            writer.uint32(82).string(message.externalSecurityCode);
        }
        if (message.externalSecurityId !== '') {
            writer.uint32(90).string(message.externalSecurityId);
        }
        if (message.qty !== 0) {
            writer.uint32(97).double(message.qty);
        }
        if (message.orderType !== 1) {
            writer.uint32(104).int32(message.orderType);
        }
        if (message.price !== 0) {
            writer.uint32(113).double(message.price);
        }
        if (message.timeInForce !== 1) {
            writer.uint32(120).int32(message.timeInForce);
        }
        if (message.handlingInstruction !== 1) {
            writer.uint32(128).int32(message.handlingInstruction);
        }
        if (message.createdTimestampUtcInMillis !== '0') {
            writer.uint32(136).int64(message.createdTimestampUtcInMillis);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(146).string(message.exchangeCode);
        }
        if (message.externalExchangeCode !== '') {
            writer.uint32(154).string(message.externalExchangeCode);
        }
        if (message.execId !== '') {
            writer.uint32(162).string(message.execId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(170).string(message.deliverToCompId);
        }
        if (message.productType !== 1) {
            writer.uint32(176).int32(message.productType);
        }
        if (message.currencyCode !== '') {
            writer.uint32(186).string(message.currencyCode);
        }
        if (message.securityType !== '') {
            writer.uint32(194).string(message.securityType);
        }
        if (message.settlementType !== '') {
            writer.uint32(202).string(message.settlementType);
        }
        if (message.settlementDate !== '') {
            writer.uint32(210).string(message.settlementDate);
        }
        if (message.account !== '') {
            writer.uint32(218).string(message.account);
        }
        for (const v of message.orderMetadata) {
            KeyValue.encode(v!, writer.uint32(226).fork()).ldelim();
        }
        if (message.fixingDate !== '') {
            writer.uint32(234).string(message.fixingDate);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrderRequestRejectDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateOrderRequestRejectDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.refSequenceNum = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.refMessageType = reader.string();
                    break;
                case 3:
                    message.rejectReason = reader.string();
                    break;
                case 4:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 5:
                    message.externalClOrderId = reader.string();
                    break;
                case 6:
                    message.side = reader.int32() as any;
                    break;
                case 7:
                    message.securityCode = reader.string();
                    break;
                case 8:
                    message.securityId = reader.string();
                    break;
                case 9:
                    message.securityIdSource = reader.string();
                    break;
                case 10:
                    message.externalSecurityCode = reader.string();
                    break;
                case 11:
                    message.externalSecurityId = reader.string();
                    break;
                case 12:
                    message.qty = reader.double();
                    break;
                case 13:
                    message.orderType = reader.int32() as any;
                    break;
                case 14:
                    message.price = reader.double();
                    break;
                case 15:
                    message.timeInForce = reader.int32() as any;
                    break;
                case 16:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 17:
                    message.createdTimestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                case 18:
                    message.exchangeCode = reader.string();
                    break;
                case 19:
                    message.externalExchangeCode = reader.string();
                    break;
                case 20:
                    message.execId = reader.string();
                    break;
                case 21:
                    message.deliverToCompId = reader.string();
                    break;
                case 22:
                    message.productType = reader.int32() as any;
                    break;
                case 23:
                    message.currencyCode = reader.string();
                    break;
                case 24:
                    message.securityType = reader.string();
                    break;
                case 25:
                    message.settlementType = reader.string();
                    break;
                case 26:
                    message.settlementDate = reader.string();
                    break;
                case 27:
                    message.account = reader.string();
                    break;
                case 28:
                    message.orderMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 29:
                    message.fixingDate = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateOrderRequestRejectDownstreamEvent>): CreateOrderRequestRejectDownstreamEvent {
        const message = createBaseCreateOrderRequestRejectDownstreamEvent();
        message.refSequenceNum = object.refSequenceNum ?? '0';
        message.refMessageType = object.refMessageType ?? '';
        message.rejectReason = object.rejectReason ?? '';
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.externalClOrderId = object.externalClOrderId ?? '';
        message.side = object.side ?? 1;
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.securityIdSource = object.securityIdSource ?? '';
        message.externalSecurityCode = object.externalSecurityCode ?? '';
        message.externalSecurityId = object.externalSecurityId ?? '';
        message.qty = object.qty ?? 0;
        message.orderType = object.orderType ?? 1;
        message.price = object.price ?? 0;
        message.timeInForce = object.timeInForce ?? 1;
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.createdTimestampUtcInMillis = object.createdTimestampUtcInMillis ?? '0';
        message.exchangeCode = object.exchangeCode ?? '';
        message.externalExchangeCode = object.externalExchangeCode ?? '';
        message.execId = object.execId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.productType = object.productType ?? 1;
        message.currencyCode = object.currencyCode ?? '';
        message.securityType = object.securityType ?? '';
        message.settlementType = object.settlementType ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.account = object.account ?? '';
        message.orderMetadata = object.orderMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.fixingDate = object.fixingDate ?? '';
        return message;
    }
};

function createBaseRemoveOrdersFromScreenNotification(): RemoveOrdersFromScreenNotification {
    return { orderId: [] };
}

export const RemoveOrdersFromScreenNotification = {
    encode(message: RemoveOrdersFromScreenNotification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        writer.uint32(10).fork();
        for (const v of message.orderId) {
            writer.int64(v);
        }
        writer.ldelim();
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): RemoveOrdersFromScreenNotification {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRemoveOrdersFromScreenNotification();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.orderId.push(longToString(reader.int64() as Long));
                        }
                    } else {
                        message.orderId.push(longToString(reader.int64() as Long));
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<RemoveOrdersFromScreenNotification>): RemoveOrdersFromScreenNotification {
        const message = createBaseRemoveOrdersFromScreenNotification();
        message.orderId = object.orderId?.map((e) => e) || [];
        return message;
    }
};

function createBaseTradeAmendmentNotificationDownstreamEvent(): TradeAmendmentNotificationDownstreamEvent {
    return { orderId: '0', message: '', statusType: 0 };
}

export const TradeAmendmentNotificationDownstreamEvent = {
    encode(message: TradeAmendmentNotificationDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.orderId !== '0') {
            writer.uint32(8).int64(message.orderId);
        }
        if (message.message !== '') {
            writer.uint32(18).string(message.message);
        }
        if (message.statusType !== 0) {
            writer.uint32(24).int32(message.statusType);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): TradeAmendmentNotificationDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradeAmendmentNotificationDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                case 3:
                    message.statusType = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<TradeAmendmentNotificationDownstreamEvent>
    ): TradeAmendmentNotificationDownstreamEvent {
        const message = createBaseTradeAmendmentNotificationDownstreamEvent();
        message.orderId = object.orderId ?? '0';
        message.message = object.message ?? '';
        message.statusType = object.statusType ?? 0;
        return message;
    }
};

function createBaseOrderAllocGroup(): OrderAllocGroup {
    return { allocAccount: '', allocQty: 0, allocSettlementDate: '', allocSide: 1 };
}

export const OrderAllocGroup = {
    encode(message: OrderAllocGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.allocAccount !== '') {
            writer.uint32(10).string(message.allocAccount);
        }
        if (message.allocQty !== 0) {
            writer.uint32(17).double(message.allocQty);
        }
        if (message.allocSettlementDate !== '') {
            writer.uint32(26).string(message.allocSettlementDate);
        }
        if (message.allocSide !== 1) {
            writer.uint32(32).int32(message.allocSide);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): OrderAllocGroup {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderAllocGroup();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.allocAccount = reader.string();
                    break;
                case 2:
                    message.allocQty = reader.double();
                    break;
                case 3:
                    message.allocSettlementDate = reader.string();
                    break;
                case 4:
                    message.allocSide = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<OrderAllocGroup>): OrderAllocGroup {
        const message = createBaseOrderAllocGroup();
        message.allocAccount = object.allocAccount ?? '';
        message.allocQty = object.allocQty ?? 0;
        message.allocSettlementDate = object.allocSettlementDate ?? '';
        message.allocSide = object.allocSide ?? 1;
        return message;
    }
};

function createBaseOrderPartiesGroup(): OrderPartiesGroup {
    return { partyId: '', partyIdSource: 1, partyRole: 1 };
}

export const OrderPartiesGroup = {
    encode(message: OrderPartiesGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.partyId !== '') {
            writer.uint32(10).string(message.partyId);
        }
        if (message.partyIdSource !== 1) {
            writer.uint32(16).int32(message.partyIdSource);
        }
        if (message.partyRole !== 1) {
            writer.uint32(24).int32(message.partyRole);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): OrderPartiesGroup {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderPartiesGroup();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.partyId = reader.string();
                    break;
                case 2:
                    message.partyIdSource = reader.int32() as any;
                    break;
                case 3:
                    message.partyRole = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<OrderPartiesGroup>): OrderPartiesGroup {
        const message = createBaseOrderPartiesGroup();
        message.partyId = object.partyId ?? '';
        message.partyIdSource = object.partyIdSource ?? 1;
        message.partyRole = object.partyRole ?? 1;
        return message;
    }
};

function createBaseCancelTradeRequestReject(): CancelTradeRequestReject {
    return { id: '0', cancelRejectReason: 1, orderId: '0' };
}

export const CancelTradeRequestReject = {
    encode(message: CancelTradeRequestReject, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== '0') {
            writer.uint32(8).int64(message.id);
        }
        if (message.cancelRejectReason !== 1) {
            writer.uint32(16).int32(message.cancelRejectReason);
        }
        if (message.orderId !== '0') {
            writer.uint32(24).int64(message.orderId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CancelTradeRequestReject {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCancelTradeRequestReject();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.cancelRejectReason = reader.int32() as any;
                    break;
                case 3:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CancelTradeRequestReject>): CancelTradeRequestReject {
        const message = createBaseCancelTradeRequestReject();
        message.id = object.id ?? '0';
        message.cancelRejectReason = object.cancelRejectReason ?? 1;
        message.orderId = object.orderId ?? '0';
        return message;
    }
};

function createBaseOrderStatusRequestRejectDownstreamEvent(): OrderStatusRequestRejectDownstreamEvent {
    return { orderId: '0', clOrderId: '', message: '', requestedByUser: '' };
}

export const OrderStatusRequestRejectDownstreamEvent = {
    encode(message: OrderStatusRequestRejectDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.orderId !== '0') {
            writer.uint32(8).int64(message.orderId);
        }
        if (message.clOrderId !== '') {
            writer.uint32(18).string(message.clOrderId);
        }
        if (message.message !== '') {
            writer.uint32(26).string(message.message);
        }
        if (message.requestedByUser !== '') {
            writer.uint32(34).string(message.requestedByUser);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): OrderStatusRequestRejectDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderStatusRequestRejectDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.clOrderId = reader.string();
                    break;
                case 3:
                    message.message = reader.string();
                    break;
                case 4:
                    message.requestedByUser = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<OrderStatusRequestRejectDownstreamEvent>): OrderStatusRequestRejectDownstreamEvent {
        const message = createBaseOrderStatusRequestRejectDownstreamEvent();
        message.orderId = object.orderId ?? '0';
        message.clOrderId = object.clOrderId ?? '';
        message.message = object.message ?? '';
        message.requestedByUser = object.requestedByUser ?? '';
        return message;
    }
};

function createBaseDontKnowTradeDownstreamEvent(): DontKnowTradeDownstreamEvent {
    return { securityId: '', referenceExecutionId: '', text: '', side: '', orderId: '' };
}

export const DontKnowTradeDownstreamEvent = {
    encode(message: DontKnowTradeDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.securityId !== '') {
            writer.uint32(10).string(message.securityId);
        }
        if (message.referenceExecutionId !== '') {
            writer.uint32(18).string(message.referenceExecutionId);
        }
        if (message.text !== '') {
            writer.uint32(26).string(message.text);
        }
        if (message.side !== '') {
            writer.uint32(34).string(message.side);
        }
        if (message.orderId !== '') {
            writer.uint32(42).string(message.orderId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): DontKnowTradeDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDontKnowTradeDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.securityId = reader.string();
                    break;
                case 2:
                    message.referenceExecutionId = reader.string();
                    break;
                case 3:
                    message.text = reader.string();
                    break;
                case 4:
                    message.side = reader.string();
                    break;
                case 5:
                    message.orderId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<DontKnowTradeDownstreamEvent>): DontKnowTradeDownstreamEvent {
        const message = createBaseDontKnowTradeDownstreamEvent();
        message.securityId = object.securityId ?? '';
        message.referenceExecutionId = object.referenceExecutionId ?? '';
        message.text = object.text ?? '';
        message.side = object.side ?? '';
        message.orderId = object.orderId ?? '';
        return message;
    }
};

function createBaseTradeAggregationNotificationDownstreamEvent(): TradeAggregationNotificationDownstreamEvent {
    return { clientReportId: '', orderIdData: [], message: '', statusType: 0 };
}

export const TradeAggregationNotificationDownstreamEvent = {
    encode(message: TradeAggregationNotificationDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientReportId !== '') {
            writer.uint32(10).string(message.clientReportId);
        }
        for (const v of message.orderIdData) {
            OrderIdData.encode(v!, writer.uint32(18).fork()).ldelim();
        }
        if (message.message !== '') {
            writer.uint32(26).string(message.message);
        }
        if (message.statusType !== 0) {
            writer.uint32(32).int32(message.statusType);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): TradeAggregationNotificationDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTradeAggregationNotificationDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientReportId = reader.string();
                    break;
                case 2:
                    message.orderIdData.push(OrderIdData.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.message = reader.string();
                    break;
                case 4:
                    message.statusType = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<TradeAggregationNotificationDownstreamEvent>
    ): TradeAggregationNotificationDownstreamEvent {
        const message = createBaseTradeAggregationNotificationDownstreamEvent();
        message.clientReportId = object.clientReportId ?? '';
        message.orderIdData = object.orderIdData?.map((e) => OrderIdData.fromPartial(e)) || [];
        message.message = object.message ?? '';
        message.statusType = object.statusType ?? 0;
        return message;
    }
};

function createBasePassiveOrderMigrationStatusDownstreamEvent(): PassiveOrderMigrationStatusDownstreamEvent {
    return { orderRoutingInfo: [] };
}

export const PassiveOrderMigrationStatusDownstreamEvent = {
    encode(message: PassiveOrderMigrationStatusDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.orderRoutingInfo) {
            OrderRoutingInfo.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): PassiveOrderMigrationStatusDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePassiveOrderMigrationStatusDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderRoutingInfo.push(OrderRoutingInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<PassiveOrderMigrationStatusDownstreamEvent>
    ): PassiveOrderMigrationStatusDownstreamEvent {
        const message = createBasePassiveOrderMigrationStatusDownstreamEvent();
        message.orderRoutingInfo = object.orderRoutingInfo?.map((e) => OrderRoutingInfo.fromPartial(e)) || [];
        return message;
    }
};

function createBaseOrderMigrationGatewayDownstreamEvent(): OrderMigrationGatewayDownstreamEvent {
    return { clientRequestId: '', active: false };
}

export const OrderMigrationGatewayDownstreamEvent = {
    encode(message: OrderMigrationGatewayDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.active === true) {
            writer.uint32(16).bool(message.active);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): OrderMigrationGatewayDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderMigrationGatewayDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.active = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<OrderMigrationGatewayDownstreamEvent>): OrderMigrationGatewayDownstreamEvent {
        const message = createBaseOrderMigrationGatewayDownstreamEvent();
        message.clientRequestId = object.clientRequestId ?? '';
        message.active = object.active ?? false;
        return message;
    }
};

function createBaseOrderRoutingInfo(): OrderRoutingInfo {
    return { orderSnapshot: undefined, orderId: '0', reason: '', status: 0, destination: '', newOutgoingOrderId: '0' };
}

export const OrderRoutingInfo = {
    encode(message: OrderRoutingInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.orderSnapshot !== undefined) {
            FxOrderSnapshotDownstreamEvent.encode(message.orderSnapshot, writer.uint32(10).fork()).ldelim();
        }
        if (message.orderId !== '0') {
            writer.uint32(16).int64(message.orderId);
        }
        if (message.reason !== '') {
            writer.uint32(26).string(message.reason);
        }
        if (message.status !== 0) {
            writer.uint32(32).int32(message.status);
        }
        if (message.destination !== '') {
            writer.uint32(42).string(message.destination);
        }
        if (message.newOutgoingOrderId !== '0') {
            writer.uint32(48).int64(message.newOutgoingOrderId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): OrderRoutingInfo {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOrderRoutingInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.orderSnapshot = FxOrderSnapshotDownstreamEvent.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.reason = reader.string();
                    break;
                case 4:
                    message.status = reader.int32() as any;
                    break;
                case 5:
                    message.destination = reader.string();
                    break;
                case 6:
                    message.newOutgoingOrderId = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<OrderRoutingInfo>): OrderRoutingInfo {
        const message = createBaseOrderRoutingInfo();
        message.orderSnapshot =
            object.orderSnapshot !== undefined && object.orderSnapshot !== null
                ? FxOrderSnapshotDownstreamEvent.fromPartial(object.orderSnapshot)
                : undefined;
        message.orderId = object.orderId ?? '0';
        message.reason = object.reason ?? '';
        message.status = object.status ?? 0;
        message.destination = object.destination ?? '';
        message.newOutgoingOrderId = object.newOutgoingOrderId ?? '0';
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
