import { Side } from '@/compiled_proto/com/celertech/positionmanager/api/enums/SideProto';
import { TimeInForceType } from '@/compiled_proto/com/celertech/positionmanager/api/enums/TimeInForceTypeProto';
import Divider from '@/components/common/Divider';
import FormSubmit from '@/components/form/components/FormSubmit';
import Totals from '@/components/form/components/Totals';
import { closePositionSchema } from '@/components/form/schema/closePositionSchema';
import { MarketOrderFormInput, MarketOrderFormValues } from '@/components/form/schema/marketOrderSchema';
import ExecutionInputController from '@/components/inputs/ExecutionInputController';
import RHFNumberInput from '@/components/inputs/RHFNumberInput';
import Select from '@/components/inputs/Select';
import { OpenPositionRow } from '@/components/trader-modules/Blotters/OpenPositionTable';
import { formatOrderMessage } from '@/helpers/orderHelper';
import { submitMarketOrder } from '@/services/OrderService';
import { useAppSelector } from '@/state/hooks';
import { selectCredentials, selectCurrentAccount } from '@/state/reducers/authSlice';
import { selectSettings } from '@/state/reducers/settingSlice';
import { selectActiveTicker } from '@/state/reducers/tickerSlice';
import { useCurrency } from '@/utils/hooks/useCurrency';
import useOrderBook from '@/utils/hooks/useOrderBook';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Modal, { ModalClose, ModalHeader, ModalProps, ModalTitle } from './/Modal';

const durationOptions: { label: keyof typeof TimeInForceType; value: TimeInForceType }[] = [
    { label: 'IOC', value: TimeInForceType.IOC },
    { label: 'FOK', value: TimeInForceType.FOK }
];

const defaultValues: Partial<MarketOrderFormInput> = {
    quantity: null,
    duration: durationOptions[0]
};

interface ClosePositionModalProps extends ModalProps {
    prefillValues?: OpenPositionRow;
}

export default function ClosePositionModal(props: ClosePositionModalProps) {
    const { opened, handlers, prefillValues } = props;

    const settings = useAppSelector(selectSettings);
    const activeTicker = useAppSelector(selectActiveTicker);
    const credentials = useAppSelector(selectCredentials);
    const currentAccount = useAppSelector(selectCurrentAccount);

    const [ccy1, ccy2] = useMemo(() => activeTicker.celer.split('/'), [activeTicker.celer]);
    const [side, setSide] = useState<Side>(Side.BUY);
    const [currency, setCurrency] = useState<string>(ccy1);
    const isCcy2Order = useMemo(() => currency === ccy2, [currency, ccy2]);

    const ccy1Config = useCurrency(ccy1);
    const ccy2Config = useCurrency(ccy2);
    const { asks, bids, bestPrice } = useOrderBook(activeTicker.celer, side, isCcy2Order);

    const orderMessage = useMemo(
        () => formatOrderMessage({ side, isCcy2Order, ccy1Config, ccy2Config }),
        [side, isCcy2Order, ccy1Config, ccy2Config]
    );

    const form = useForm<MarketOrderFormInput>({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(closePositionSchema({}))
    });

    const {
        reset,
        register,
        handleSubmit,
        formState: { isSubmitting, isValid }
    } = form;

    const onSubmit = async (data: MarketOrderFormInput, e) => {
        const dataValidated = data as MarketOrderFormValues;
        if (dataValidated.quantity && credentials) {
            handlers.close();
            await submitMarketOrder(
                {
                    spotPrice: bestPrice,
                    securityId: activeTicker.celer,
                    currencyOut: currency,
                    quantity: dataValidated.quantity,
                    timeInForce: dataValidated.duration.value as TimeInForceType,
                    side
                },
                credentials,
                currentAccount
            );
        }
    };

    const onError = (errors) => console.error(errors);

    useEffect(() => {
        if (opened && prefillValues) {
            setCurrency(ccy1);
            setSide((prefillValues.netQtyPosition || 0) > 0 ? Side.SELL : Side.BUY);
            reset({
                ...defaultValues,
                duration: settings.trading.defaultMarketOrderDuration,
                quantity: Math.abs(prefillValues.netQtyPosition)
            });
        }
    }, [opened]);

    return (
        <Modal {...props} size="max-w-lg">
            <div className="h-full flex flex-col lg:block lg:h-auto">
                <ModalHeader>
                    <ModalTitle>Close Position</ModalTitle>
                    <ModalClose handlers={handlers} />
                </ModalHeader>
                <Divider />
                <div className="flex flex-col flex-1 basis-0 h-full lg:h-auto lg:block w-full text-neutral-200">
                    <FormProvider {...form}>
                        <form
                            onSubmit={handleSubmit(onSubmit, onError)}
                            className="relative flex flex-col h-full w-full text-neutral-200">
                            <div
                                className={cn(
                                    'flex flex-col flex-1 overflow-y-auto gap-3 p-2 sm:p-4 pb-14',
                                    Side[side].toLowerCase()
                                )}>
                                <div className="flex justify-between items-center">{orderMessage}</div>
                                <ExecutionInputController name="duration" label="Duration *">
                                    <Select type="execution" options={durationOptions} />
                                </ExecutionInputController>
                                <RHFNumberInput
                                    {...register('quantity')}
                                    label="Quantity *"
                                    placeholder="Quantity"
                                    disabled
                                />
                                <Totals
                                    activePair={activeTicker}
                                    side={side}
                                    ccy1={ccy1}
                                    ccy2={ccy2}
                                    ccy2Order={currency === ccy2}
                                    bids={bids}
                                    asks={asks}
                                    isTrader
                                />
                            </div>
                            <Divider />
                            <div className="p-2 sm:p-4 w-full">
                                <FormSubmit side={side} isValid={isValid} disabled={isSubmitting || !isValid} />
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </Modal>
    );
}
