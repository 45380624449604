/* eslint-disable */
import { Side } from '../enums/SideProto';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.piggybank.api.generalledger';

export interface TransactionLeg {
    /** Quantity of the leg */
    legQty: number;
    /** Price of the leg */
    legPrice: number;
    /** Spot Price of the leg.  If modelling a SPOT transaction, this should be the same value as legPrice */
    legSpotPrice: number;
    /** Forward Points of the leg.  If modelling a SPOT transaction, this should be zero */
    legForwardPts: number;
    /** Side of the leg */
    side: Side;
    /** SettlementDate of the leg to determine when this transaction is to settle */
    settlementDate: string;
    /** SettlementType of the leg. For example, SP, 1W, 2W, etc */
    settlementType: string;
}

function createBaseTransactionLeg(): TransactionLeg {
    return {
        legQty: 0,
        legPrice: 0,
        legSpotPrice: 0,
        legForwardPts: 0,
        side: 1,
        settlementDate: '',
        settlementType: ''
    };
}

export const TransactionLeg = {
    encode(message: TransactionLeg, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.legQty !== 0) {
            writer.uint32(9).double(message.legQty);
        }
        if (message.legPrice !== 0) {
            writer.uint32(17).double(message.legPrice);
        }
        if (message.legSpotPrice !== 0) {
            writer.uint32(25).double(message.legSpotPrice);
        }
        if (message.legForwardPts !== 0) {
            writer.uint32(33).double(message.legForwardPts);
        }
        if (message.side !== 1) {
            writer.uint32(40).int32(message.side);
        }
        if (message.settlementDate !== '') {
            writer.uint32(50).string(message.settlementDate);
        }
        if (message.settlementType !== '') {
            writer.uint32(58).string(message.settlementType);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): TransactionLeg {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTransactionLeg();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.legQty = reader.double();
                    break;
                case 2:
                    message.legPrice = reader.double();
                    break;
                case 3:
                    message.legSpotPrice = reader.double();
                    break;
                case 4:
                    message.legForwardPts = reader.double();
                    break;
                case 5:
                    message.side = reader.int32() as any;
                    break;
                case 6:
                    message.settlementDate = reader.string();
                    break;
                case 7:
                    message.settlementType = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<TransactionLeg>): TransactionLeg {
        const message = createBaseTransactionLeg();
        message.legQty = object.legQty ?? 0;
        message.legPrice = object.legPrice ?? 0;
        message.legSpotPrice = object.legSpotPrice ?? 0;
        message.legForwardPts = object.legForwardPts ?? 0;
        message.side = object.side ?? 1;
        message.settlementDate = object.settlementDate ?? '';
        message.settlementType = object.settlementType ?? '';
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;
