import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchingStatus } from '../../model/fetchingStatus';
import { RootState } from '../store';
import { PairMap } from './marketPairSlice';

export const fetchBarsAsync = createAsyncThunk('chartData/fetchBars', async (pair: PairMap, { getState, dispatch }) => {
    const state = getState() as RootState;

    const bestBidAsk = state.marketPair.bidAsk[pair.celer];
    if (bestBidAsk) {
        dispatch(setHistoricSupport(false));
        const dummyCandleStart = Math.floor(new Date().getTime() / 1000);

        // create candle to be handled in reducer below
        return {
            symbol: pair.netdania,
            data: [
                {
                    time: dummyCandleStart,
                    close: bestBidAsk.bid,
                    high: bestBidAsk.bid,
                    low: bestBidAsk.bid,
                    open: bestBidAsk.bid,
                    volume: 0.0
                }
            ] as any[]
        };
    } else {
        return {
            symbol: pair.netdania,
            data: [] as any[]
        };
    }
});

export interface Candle {
    symbol: string;
    opentime: number;
    open: number;
    high: number;
    low: number;
    close: number;
}

export interface PriceTick {
    symbol: string;
    time: number;
    open: number;
    high: number;
    low: number;
    close: number;
}

export interface ChartDataState {
    status: FetchingStatus;
    historicDataSupport: boolean;
    furtherStatus: FetchingStatus;
    bars: any[];
    updateBar: any;
    currentCandle?: Candle;
    priceTrend?: number;
    priceTicks: Record<string, PriceTick>;
}

const initialState: ChartDataState = {
    status: 'idle',
    furtherStatus: 'idle',
    bars: [],
    historicDataSupport: true,
    updateBar: null,
    priceTicks: {}
};

export const chartDataSlice = createSlice({
    name: 'chartData',
    initialState,
    reducers: {
        setUpdateBar: (state, action: PayloadAction<any>) => {
            state.updateBar = action.payload;
        },
        setHistoricSupport: (state, action: PayloadAction<boolean>) => {
            state.historicDataSupport = action.payload;
        },
        resetChart: (state) => {
            state.currentCandle = undefined;
            state.priceTrend = undefined;
        },
        pushPriceChange: (state, action: PayloadAction<{ symbol: string; price: number; timestamp: number }>) => {
            // symbol is netdania syntax
            const { symbol, price, timestamp } = action.payload;

            const currentCandle = state.currentCandle;

            // Check that symbol matches latest candle - else ignore incoming price change.
            // Additional check - price updates for non-active symbol should be filtered away in middleware
            if (currentCandle && currentCandle.symbol === symbol) {
                const updateCandle = {
                    symbol,
                    opentime: timestamp,
                    open: price,
                    high: price,
                    low: price,
                    close: price
                };
                state.currentCandle = updateCandle;

                const tradingViewBar = {
                    symbol,
                    time: updateCandle.opentime,
                    close: updateCandle.close,
                    high: updateCandle.high,
                    low: updateCandle.low,
                    open: updateCandle.open
                };

                state.updateBar = tradingViewBar;
            } else if (currentCandle === undefined) {
                const updateCandle = {
                    symbol,
                    opentime: timestamp,
                    open: price,
                    high: price,
                    low: price,
                    close: price
                };
                state.currentCandle = updateCandle;

                const tradingViewBar = {
                    symbol,
                    time: updateCandle.opentime,
                    close: updateCandle.close,
                    high: updateCandle.high,
                    low: updateCandle.low,
                    open: updateCandle.open
                };

                state.updateBar = tradingViewBar;
            }
        },
        onPriceTick: (state, action: PayloadAction<{ symbol: string; price: number; timestamp: number }>) => {
            // symbol is netdania syntax
            const { symbol, price, timestamp } = action.payload;

            const tradingViewBar = {
                symbol,
                time: timestamp,
                close: price,
                high: price,
                low: price,
                open: price
            };

            state.priceTicks[symbol] = tradingViewBar;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBarsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchBarsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                const { data: candles, symbol } = action.payload;
                state.bars = candles;
                if (candles.length) {
                    const latestCandle = candles[candles.length - 1];
                    state.currentCandle = {
                        symbol,
                        opentime: latestCandle.time * 1000,
                        open: latestCandle.open,
                        high: latestCandle.high,
                        low: latestCandle.low,
                        close: latestCandle.close
                    } as Candle;
                } else {
                    const minuteMs = 60 * 1000;
                    const dummyCandleStart = Math.floor(new Date().getTime() / 1000 / minuteMs) * minuteMs;

                    state.currentCandle = {
                        symbol,
                        opentime: dummyCandleStart
                    } as Candle;
                }
            })
            .addCase(fetchBarsAsync.rejected, (state) => {
                state.status = 'failed';
            });
    }
});
export const { setUpdateBar, setHistoricSupport, pushPriceChange, onPriceTick, resetChart } = chartDataSlice.actions;

export const selectStatus = (state: RootState) => state.chartData.status;
export const selectFurtherStatus = (state: RootState) => state.chartData.furtherStatus;
export const selectBars = (state: RootState) => state.chartData.bars;
export const selectUpdateBar = (state: RootState) => state.chartData.updateBar;
export const selectCurrentCandle = (state: RootState) => state.chartData.currentCandle;
export const selectEarlistCandle = (state: RootState) => state.chartData.bars[0];
export const selectPriceTrend = (state: RootState) => state.chartData.priceTrend;
export const selectHistoricDataSupport = (state: RootState) => state.chartData.historicDataSupport;

export default chartDataSlice.reducer;
