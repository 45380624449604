/* eslint-disable */
export const protobufPackage = 'com.celertech.positionmanager.api.enums.ordertype';

export enum OrderType {
    MARKET = 1,
    LIMIT = 2,
    STOP_LIMIT = 3,
    STOP_MARKET = 4,
    MOC = 5,
    MOO = 6,
    PEGGED = 7,
    PREVIOUSLY_QUOTED = 8,
    UNRECOGNIZED = -1
}
