/* eslint-disable */
export const protobufPackage = 'com.celertech.marketdata.api.enums.side';

export enum Side {
    BUY = 1,
    SELL = 2,
    SELL_SHORT = 3,
    SELL_SHORT_EXCEMPT = 4,
    UNRECOGNIZED = -1
}
