import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FetchingStatus } from '../../model/fetchingStatus';
import { RootState } from '../store';

export interface LiteMarket {
    name: string;
    logo: string;
    toName: string;
    toLogo: string;
}

export interface LiteMarketState {
    status: FetchingStatus;
    liteMarkets: LiteMarket[];
}

export const defaultMarkets: LiteMarket[] = [
    { name: 'BTC', logo: 'btc', toName: 'USD', toLogo: 'usd' },
    { name: 'ETH', logo: 'eth', toName: 'USD', toLogo: 'usd' },
    { name: 'UST', logo: 'usdt', toName: 'USD', toLogo: 'usd' },
    { name: 'BNB', logo: 'bnb', toName: 'USD', toLogo: 'usd' },
    { name: 'USC', logo: 'usdc', toName: 'USD', toLogo: 'usd' },
    { name: 'XRP', logo: 'xrp', toName: 'USD', toLogo: 'usd' }
];

const initialState: LiteMarketState = {
    status: 'idle',
    liteMarkets: defaultMarkets
};

export const liteMarketSlice = createSlice({
    name: 'liteMarket',
    initialState,
    reducers: {
        setLiteMarkets: (state, action: PayloadAction<LiteMarket[]>) => {
            state.liteMarkets = action.payload;
        }
    }
});

export const { setLiteMarkets } = liteMarketSlice.actions;
export const selectLiteMarkets = (state: RootState) => state.liteMarket.liteMarkets;

export default liteMarketSlice.reducer;
