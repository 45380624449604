import { Dialog } from '@headlessui/react';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '../common/Button';
import Divider from '../common/Divider';
import { CryptoDepositFormValues } from '../form/schema/cryptoDepositSchema';
import { FiatDepositFormValues } from '../form/schema/fiatDepositSchema';
import Modal, { ModalHeader, ModalProps, ModalTitle } from './Modal';

interface DepositConfirmationModalProps extends ModalProps {
    type: string;
    user?: string;
    keys: Array<keyof CryptoDepositFormValues> | Array<keyof FiatDepositFormValues>;
    manualOnSubmit: () => void;
}

const DepositConfirmationModal = (props: DepositConfirmationModalProps) => {
    const { type, opened, handlers, user, keys, manualOnSubmit } = props;

    const {
        getValues,
        formState: { isSubmitting }
    } = useFormContext();

    const message = useMemo(() => {
        if (type === 'Crypto') {
            const cryptoKeys = keys as Array<keyof CryptoDepositFormValues>;
            const [account, amount, asset, address, email] = getValues(cryptoKeys);

            return (
                <div className="flex flex-col space-y-2">
                    <div className="space-y-1">
                        <h3 className="text-md font-bold pb-1">Deposit Details</h3>

                        <p>User : {user}</p>
                        <p>Account To Deposit : {account.value}</p>
                        <p>Deposit Amount : {amount}</p>
                        <p>Deposit Asset : {asset.value}</p>
                        <p>Wallet Address: {address}</p>
                    </div>
                    <div className="space-y-1">
                        <h3 className="text-md font-bold pb-1">Email Details</h3>

                        <p>CC To : {email?.cc_to || '-'}</p>
                    </div>
                </div>
            );
        } else {
            const fiatKeys = keys as Array<keyof FiatDepositFormValues>;
            const [account, amount, currency, email] = getValues(fiatKeys);
            return (
                <div className="flex flex-col space-y-2">
                    <div className="space-y-1">
                        <h3 className="text-md font-bold pb-1">Deposit Details</h3>
                        <p>User : {user}</p>
                        <p>Account To Deposit : {account.value}</p>
                        <p>Deposit Amount : {amount}</p>
                        <p>Deposit Currency : {currency.value}</p>
                    </div>
                    <div className="space-y-1">
                        <h3 className="text-md font-bold pb-1">Email Details</h3>
                        <p>CC To : {email?.cc_to || '-'}</p>
                    </div>
                </div>
            );
        }
    }, [opened]);

    return (
        <Modal {...props} className="h-auto" size="max-w-md">
            <ModalHeader>
                <ModalTitle>{type} Deposit Request</ModalTitle>
            </ModalHeader>
            <Divider />
            <div className="text-neutral-200 p-2 sm:p-4">
                <div className="pb-4 text-sm">{message}</div>
                <div className="text-sm">Please confirm your details for Deposit.</div>
            </div>
            <Divider />
            <div className="text-neutral-200 p-2 sm:p-4 text-sm flex space-x-2">
                <button
                    className="rounded-md p-2 px-4 bg-neutral-600 hover:bg-neutral-500"
                    onClick={() => {
                        handlers.close();
                    }}>
                    Cancel
                </button>
                <Button
                    className="rounded-md p-2 px-4 bg-brand-primary hover:bg-brand-primary-light"
                    onClick={() => {
                        manualOnSubmit();
                        handlers.close();
                    }}
                    isLoading={isSubmitting}>
                    Confirm
                </Button>
            </div>
        </Modal>
    );
};

export default DepositConfirmationModal;
