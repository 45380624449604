/* eslint-disable */
import { CreditSettlementType } from '../enums/CreditSettlementTypeProto';
import Long from 'long';
import { Party } from './UpstreamAccountProto';
import { ProductType } from '../enums/ProductTypeProto';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.staticdata.api.account';

/** Account message that is sent from the server */
export interface AccountDownstreamEvent {
    /** Id of the account */
    id: string;
    /** Unique code that represents the account */
    code: string;
    /** Name of the account, can be non-unique */
    name: string;
    /** Description of the account, can be non-unique */
    description: string;
    /** CtiCode of the account, can be non-unique */
    ctiCode: string;
    /** FeeCode of the account, can be non-unique */
    feeCode: string;
    /**
     * Looks to be weird to be using the Party object from the upstream proto in the AccountDownstreamEvent.
     * However since both the UpstreamAccountProto and DownstreamAccountProto are in the same java packages, we
     * can't have the same Class name.  We could have the same object but with different names, but that
     * might be overkill so just using the upstream object for now
     */
    party: Party[];
    /** Enables this account to auto clear any trades made against this account */
    autoClearEnabled: boolean;
    /** Enables this account */
    enabled: boolean;
    /** Enables this account to be a CSA account */
    creditCsa: boolean;
    /** Enables this account to be a CLS settled account */
    creditCheckEnabled: boolean;
    /** UTC timestamp represented as milliseconds since epoch */
    createdInMillis: string;
    /** UTC timestamp represented as milliseconds since epoch */
    updatedInMillis: string;
    productType: ProductType[];
    creditSettlementType: CreditSettlementType;
}

/** Confirmation message when an account is deleted */
export interface DeleteAccountConfirmation {
    id: string;
}

export interface BulkAccountDownstreamEvent {
    id: string;
    statusCode: number;
    errorText: string;
    successful: IdAndCode[];
    failed: IdAndCode[];
}

export interface IdAndCode {
    statusCode: number;
    errorText: string;
    id: string;
    code: string;
}

function createBaseAccountDownstreamEvent(): AccountDownstreamEvent {
    return {
        id: '0',
        code: '',
        name: '',
        description: '',
        ctiCode: '',
        feeCode: '',
        party: [],
        autoClearEnabled: false,
        enabled: false,
        creditCsa: false,
        creditCheckEnabled: false,
        createdInMillis: '0',
        updatedInMillis: '0',
        productType: [],
        creditSettlementType: 1
    };
}

export const AccountDownstreamEvent = {
    encode(message: AccountDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== '0') {
            writer.uint32(8).int64(message.id);
        }
        if (message.code !== '') {
            writer.uint32(18).string(message.code);
        }
        if (message.name !== '') {
            writer.uint32(26).string(message.name);
        }
        if (message.description !== '') {
            writer.uint32(34).string(message.description);
        }
        if (message.ctiCode !== '') {
            writer.uint32(42).string(message.ctiCode);
        }
        if (message.feeCode !== '') {
            writer.uint32(58).string(message.feeCode);
        }
        for (const v of message.party) {
            Party.encode(v!, writer.uint32(66).fork()).ldelim();
        }
        if (message.autoClearEnabled === true) {
            writer.uint32(72).bool(message.autoClearEnabled);
        }
        if (message.enabled === true) {
            writer.uint32(80).bool(message.enabled);
        }
        if (message.creditCsa === true) {
            writer.uint32(88).bool(message.creditCsa);
        }
        if (message.creditCheckEnabled === true) {
            writer.uint32(104).bool(message.creditCheckEnabled);
        }
        if (message.createdInMillis !== '0') {
            writer.uint32(112).int64(message.createdInMillis);
        }
        if (message.updatedInMillis !== '0') {
            writer.uint32(120).int64(message.updatedInMillis);
        }
        writer.uint32(130).fork();
        for (const v of message.productType) {
            writer.int32(v);
        }
        writer.ldelim();
        if (message.creditSettlementType !== 1) {
            writer.uint32(136).int32(message.creditSettlementType);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): AccountDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAccountDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.code = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.description = reader.string();
                    break;
                case 5:
                    message.ctiCode = reader.string();
                    break;
                case 7:
                    message.feeCode = reader.string();
                    break;
                case 8:
                    message.party.push(Party.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.autoClearEnabled = reader.bool();
                    break;
                case 10:
                    message.enabled = reader.bool();
                    break;
                case 11:
                    message.creditCsa = reader.bool();
                    break;
                case 13:
                    message.creditCheckEnabled = reader.bool();
                    break;
                case 14:
                    message.createdInMillis = longToString(reader.int64() as Long);
                    break;
                case 15:
                    message.updatedInMillis = longToString(reader.int64() as Long);
                    break;
                case 16:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.productType.push(reader.int32() as any);
                        }
                    } else {
                        message.productType.push(reader.int32() as any);
                    }
                    break;
                case 17:
                    message.creditSettlementType = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<AccountDownstreamEvent>): AccountDownstreamEvent {
        const message = createBaseAccountDownstreamEvent();
        message.id = object.id ?? '0';
        message.code = object.code ?? '';
        message.name = object.name ?? '';
        message.description = object.description ?? '';
        message.ctiCode = object.ctiCode ?? '';
        message.feeCode = object.feeCode ?? '';
        message.party = object.party?.map((e) => Party.fromPartial(e)) || [];
        message.autoClearEnabled = object.autoClearEnabled ?? false;
        message.enabled = object.enabled ?? false;
        message.creditCsa = object.creditCsa ?? false;
        message.creditCheckEnabled = object.creditCheckEnabled ?? false;
        message.createdInMillis = object.createdInMillis ?? '0';
        message.updatedInMillis = object.updatedInMillis ?? '0';
        message.productType = object.productType?.map((e) => e) || [];
        message.creditSettlementType = object.creditSettlementType ?? 1;
        return message;
    }
};

function createBaseDeleteAccountConfirmation(): DeleteAccountConfirmation {
    return { id: '0' };
}

export const DeleteAccountConfirmation = {
    encode(message: DeleteAccountConfirmation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== '0') {
            writer.uint32(8).int64(message.id);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): DeleteAccountConfirmation {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeleteAccountConfirmation();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<DeleteAccountConfirmation>): DeleteAccountConfirmation {
        const message = createBaseDeleteAccountConfirmation();
        message.id = object.id ?? '0';
        return message;
    }
};

function createBaseBulkAccountDownstreamEvent(): BulkAccountDownstreamEvent {
    return { id: '0', statusCode: 0, errorText: '', successful: [], failed: [] };
}

export const BulkAccountDownstreamEvent = {
    encode(message: BulkAccountDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== '0') {
            writer.uint32(8).int64(message.id);
        }
        if (message.statusCode !== 0) {
            writer.uint32(16).int32(message.statusCode);
        }
        if (message.errorText !== '') {
            writer.uint32(26).string(message.errorText);
        }
        for (const v of message.successful) {
            IdAndCode.encode(v!, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.failed) {
            IdAndCode.encode(v!, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): BulkAccountDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBulkAccountDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.statusCode = reader.int32();
                    break;
                case 3:
                    message.errorText = reader.string();
                    break;
                case 4:
                    message.successful.push(IdAndCode.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.failed.push(IdAndCode.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<BulkAccountDownstreamEvent>): BulkAccountDownstreamEvent {
        const message = createBaseBulkAccountDownstreamEvent();
        message.id = object.id ?? '0';
        message.statusCode = object.statusCode ?? 0;
        message.errorText = object.errorText ?? '';
        message.successful = object.successful?.map((e) => IdAndCode.fromPartial(e)) || [];
        message.failed = object.failed?.map((e) => IdAndCode.fromPartial(e)) || [];
        return message;
    }
};

function createBaseIdAndCode(): IdAndCode {
    return { statusCode: 0, errorText: '', id: '0', code: '' };
}

export const IdAndCode = {
    encode(message: IdAndCode, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.statusCode !== 0) {
            writer.uint32(8).int32(message.statusCode);
        }
        if (message.errorText !== '') {
            writer.uint32(18).string(message.errorText);
        }
        if (message.id !== '0') {
            writer.uint32(24).int64(message.id);
        }
        if (message.code !== '') {
            writer.uint32(34).string(message.code);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): IdAndCode {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseIdAndCode();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.statusCode = reader.int32();
                    break;
                case 2:
                    message.errorText = reader.string();
                    break;
                case 3:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 4:
                    message.code = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<IdAndCode>): IdAndCode {
        const message = createBaseIdAndCode();
        message.statusCode = object.statusCode ?? 0;
        message.errorText = object.errorText ?? '';
        message.id = object.id ?? '0';
        message.code = object.code ?? '';
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
