import { useAppSelector } from '@/state/hooks';
import { selectNotifications, selectNotificationsVisited } from '@/state/reducers/notificationSlice';
import cn from 'classnames';
import { Fragment, useMemo } from 'react';
import { MdNotifications } from 'react-icons/md';

interface NotificationButtonProps {
    size: string;
    isActive: boolean;
    hasBorders?: boolean;
    onClick(): void;
}

const NotificationButton = ({ size, isActive, hasBorders = true, onClick }: NotificationButtonProps) => {
    const notifications = useAppSelector(selectNotifications);
    const notificationsVisited = useAppSelector(selectNotificationsVisited);

    const ping = useMemo(() => {
        const latest = notifications[0];
        if (latest) {
            switch (latest.type) {
                case 'info':
                    return {
                        dot: 'bg-sky-500',
                        wave: 'bg-sky-400'
                    };
                case 'success':
                    return {
                        dot: 'bg-brand-primary',
                        wave: 'bg-brand-primary-light'
                    };
                case 'error':
                    return {
                        dot: 'bg-brand-red',
                        wave: 'bg-brand-red-light'
                    };
                default:
                    return false;
            }
        }
        return false;
    }, [notifications[0]]);

    return (
        <button
            type="button"
            className={cn(
                'inline-flex lg:my-0 items-center justify-center rounded-md text-neutral-200 focus:outline-none transition duration-150 ease-in-out',
                {
                    ['bg-brand-primary']: isActive && hasBorders,
                    ['border border-neutral-700 p-1 px-2 lg:px-1 my-1']: hasBorders
                }
            )}
            onClick={onClick}>
            <div
                className={cn('relative flex', size, {
                    ['animate-wiggle']: ping && !notificationsVisited
                })}>
                {ping && !notificationsVisited && (
                    <Fragment>
                        <span
                            className={cn(
                                'z-[2] absolute top-1 right-0 h-2.5 w-2.5 rounded-full opacity-75 animate-ping',
                                { [ping.wave]: true }
                            )}
                        />
                        <span
                            className={cn(
                                'z-[2] absolute top-1 right-0 h-2.5 w-2.5 rounded-full border-[1px] border-brand-background text-[6px]',
                                {
                                    [ping.dot]: true
                                }
                            )}
                        />
                    </Fragment>
                )}
                <MdNotifications className={cn('absolute z-[1]', size)} />
            </div>
        </button>
    );
};

export default NotificationButton;
