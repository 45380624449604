import cn from 'classnames';
import { useCallback } from 'react';

interface HighlightedPipsProps {
    price?: string;
    boldIndexes: number[];
    colorIndexes?: number[];
    color?: string;
    fontSize?: string;
}

const HighlightedPips = ({
    price,
    boldIndexes,
    colorIndexes = [],
    color = 'text-neutral-300',
    fontSize = 'text-base'
}: HighlightedPipsProps) => {
    const result: any = [];

    const getCharacterClassNames = useCallback(
        (index: number) => {
            let classNames = 'leading-none';

            if (boldIndexes.includes(index)) {
                classNames += ` ${fontSize}`;
            }

            if (colorIndexes.includes(index)) {
                classNames += ` ${color}`;
            }

            return classNames;
        },
        [boldIndexes, colorIndexes, color, fontSize]
    );

    if (price) {
        let i = 0;

        while (i < price.length) {
            let classNames = 'leading-none';
            let charCount = 1;

            if (boldIndexes.includes(i)) {
                classNames += ` ${fontSize}`;
            }

            if (colorIndexes.includes(i)) {
                classNames += ` ${color}`;
            }

            while (i + charCount < price.length && classNames === getCharacterClassNames(i + charCount)) {
                charCount++;
            }

            result.push(
                <span key={i} className={cn(classNames)}>
                    {price.substr(i, charCount)}
                </span>
            );

            i += charCount;
        }
    }

    return <>{result}</>;
};

export default HighlightedPips;

// return (
//     <>
//         {price.split('').map((num, i) => {
//             if (boldIndexes.includes(i) && colorIndexes.includes(i)) {
//                 return (
//                     <span key={i} className={cn('leading-none', { [color]: color, [fontSize]: fontSize })}>
//                         {price.charAt(i)}
//                     </span>
//                 );
//             } else if (boldIndexes.includes(i)) {
//                 return (
//                     <span key={i} className={cn('leading-none', { [fontSize]: fontSize })}>
//                         {price.charAt(i)}
//                     </span>
//                 );
//             } else if (colorIndexes.includes(i)) {
//                 return (
//                     <span key={i} className={cn('leading-none', { [color]: color })}>
//                         {price.charAt(i)}
//                     </span>
//                 );
//             } else {
//                 return <span key={i}>{price.charAt(i)}</span>;
//             }
//         })}
//     </>
// );
