import { Route, Routes, useNavigate } from 'react-router-dom';

import { useEffect } from 'react';
import reactManifest from 'react-manifest';
import Chart from './Chart';
import ForgotPassword from './ForgotPassword';
import LiteHistory from './LiteHistory';
import Login from './Login';
import XplorSpot from './XplorSpot';
import XplorSpotLite from './XplorSpotLite';
import XplorTrader from './XplorTrader';
import { digitalManifest, sportManifest, traderManifest } from './config/manifest';
import { getEnvironment, getPlatform } from './helpers/environmentHelper';

const platform = getPlatform();
const environment = getEnvironment();

import danish from 'date-fns/locale/da';
import greek from 'date-fns/locale/el';
import australia from 'date-fns/locale/en-AU';
import canada from 'date-fns/locale/en-CA';
import united_kingdom from 'date-fns/locale/en-GB';
import new_zealand from 'date-fns/locale/en-NZ';
import fr_canada from 'date-fns/locale/fr-CA';
import polish from 'date-fns/locale/pl';
import turkish from 'date-fns/locale/tr';
import ukraine from 'date-fns/locale/uk';
import { registerLocale } from 'react-datepicker';

registerLocale('el', greek);
registerLocale('pl', polish);
registerLocale('tr', turkish);
registerLocale('en-AU', australia);
registerLocale('en-GB', united_kingdom);
registerLocale('en-NZ', new_zealand);
registerLocale('en-CA', canada);
registerLocale('fr-CA', fr_canada);
registerLocale('uk', ukraine);
registerLocale('da', danish);

function App() {
    useEffect(() => {
        if (platform === 'spot') {
            document.title = 'GCEXplorSpot';
            reactManifest.update(sportManifest(environment), '#manifest-placeholder');
        } else if (platform === 'trader') {
            document.title = 'GCEXplorTrader';
            reactManifest.update(traderManifest(environment), '#manifest-placeholder');
        } else if (platform === 'digital') {
            document.title = 'GCEXplorDigital';
            reactManifest.update(digitalManifest(environment), '#manifest-placeholder');
        }
    }, []);
    return (
        <Routes>
            <Route path="/">
                <Route index element={<XplorSpot />} />
                <Route path="trader" element={<XplorTrader />} />
                <Route path="lite">
                    <Route index element={<XplorSpotLite />} />
                    {/* <Route path="transfer" element={<LiteTransfer />} /> */}
                    <Route path="history" element={<LiteHistory />} />
                </Route>
                <Route path="chart" element={<Chart />} />
                <Route path="login" element={<Login />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="*" element={<NoMatch />} />
            </Route>
        </Routes>
    );
}

export default App;

const NoMatch = () => {
    const navigate = useNavigate();
    return (
        <div className="flex flex-col gap-4 h-full w-full justify-center items-center bg-brand-background">
            <div className="flex gap-3">
                <div className="text-neutral-400 text-4xl">404</div>
                <div className="text-neutral-200 text-4xl font-bold">Page not found</div>
            </div>
            <div className="text-neutral-300 text-lg">We&apos;re sorry, the page you requested could not be found</div>
            <div
                className="bg-brand-primary hover:bg-brand-primary-light cursor-pointer rounded-md p-2 px-4 text-neutral-200 mt-4"
                onClick={() => navigate('/')}>
                Go to login page
            </div>
            <div className="text-brand-primary-light mt-6">Kindly reach out to support if you need any help.</div>
        </div>
    );
};
