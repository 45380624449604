export enum TwoFactorEnums {
    Setup = 'setup',
    Verify = 'verify'
}

export enum Environments {
    dev = 'Dev',
    uat = 'Uat',
    demo = 'Demo'
}

export enum EntityAttributes {
    executingBroker = 'Executing Broker',
    customer = 'Customer',
    liquidityProvider = 'Liquidity Provider',
    fundManager = 'Fund Manager',
    custodian = 'Custodian',
    bank = 'Bank'
}

export enum WhitelistRequestType {
    WHITELIST_BANK_INFO = 'BANK ACCOUNT',
    WHITELIST_WALLET = 'WALLET ADDRESS',
    REMOVE_BANK_INFO = 'REMOVE BANK ACCOUNT',
    REMOVE_WALLET = 'REMOVE WALLET',
    WITHDRAWAL = 'WITHDRAWAL',
    DEPOSIT = 'DEPOSIT',
    UPDATE_WALLET = 'UPDATE WALLET',
    UPDATE_BANK_INFO = 'UPDATE BANK ACCOUNT',
    INTERNAL_TRANSFER = 'INTERNAL TRANSFER'
}

export enum DepositWithdrawalRequestType {
    WITHDRAWAL = 'Withdrawal',
    DEPOSIT = 'Deposit',
    INTERNAL_TRANSFER = 'Internal Transfer'
}

export enum DepositType {
    FIAT = 'FIAT',
    CRYPTO = 'CRYPTO'
}

export enum WithdrawalType {
    FIAT = 'FIAT',
    CRYPTO = 'CRYPTO'
}

export enum InternalTransferType {
    FIAT = 'FIAT',
    CRYPTO = 'CRYPTO'
}

export enum FileExtensionsBasedOnMimeType {
    'video/mp4' = '.mp4',
    'video/avi' = '.avi',
    'video/x-msvideo' = '.avi',
    'video/msvideo' = '.avi',
    'application/x-troff-msvideo' = '.avi',
    'video/mpeg' = '.mpeg',
    'video/webm' = '.webm'
}

export const notSupportedVideosTypes = [
    'video/avi',
    'video/x-msvideo',
    'video/msvideo',
    'application/x-troff-msvideo',
    'video/mpeg'
];
