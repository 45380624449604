import { qtyFormatterConfig } from '@/config/config';
import { CollapsedBalances } from '@/state/reducers/balanceSlice';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import usePreviousState from '@/utils/hooks/usePreviousState';
import cn from 'classnames';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNumberFormatter } from 'react-aria';
import { MdKeyboardArrowRight, MdKeyboardArrowUp, MdSwapVert } from 'react-icons/md';

interface BalanceProps {
    balance: CollapsedBalances;
    isNewlyAdded: boolean;
}

const Balance = ({ balance, isNewlyAdded }: BalanceProps) => {
    const disclosure = useDisclosure(false);
    const prevPosition = usePreviousState(balance.totalAmount);
    const formatQty = useNumberFormatter(qtyFormatterConfig);

    const timer = useRef<NodeJS.Timeout>();
    const [priceChange, setPriceChange] = useState({ increased: false, decreased: false });

    const balanceColor = useMemo(() => {
        if (balance.totalAmount === 0) return 'text-neutral-200';
        else if (balance.totalAmount > 0) return 'text-brand-primary';
        else return 'text-brand-red';
    }, [balance.totalAmount]);

    const handleEvents = useCallback(() => {
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => setPriceChange({ increased: false, decreased: false }), 3000);
    }, [timer.current]);

    useDidUpdate(() => {
        if (balance.totalAmount && prevPosition) {
            if (balance.totalAmount === prevPosition) {
                setPriceChange({ increased: false, decreased: false });
            } else if (balance.totalAmount > prevPosition) {
                setPriceChange({ increased: true, decreased: false });
                handleEvents();
            } else {
                setPriceChange({ increased: false, decreased: true });
                handleEvents();
            }
        }
    }, [balance.totalAmount]);

    useEffect(() => {
        if (isNewlyAdded) {
            if (balance.totalAmount > 0) {
                setPriceChange({ increased: true, decreased: false });
                handleEvents();
            } else if (balance.totalAmount < 0) {
                setPriceChange({ increased: false, decreased: true });
                handleEvents();
            }
        }
    }, [isNewlyAdded]);

    return (
        <div className="flex flex-col">
            <div
                className="flex justify-between cursor-pointer hover:bg-brand-background-dark p-1"
                onClick={disclosure[1].toggle}>
                <div className="relative flex items-center gap-1 text-left flex-[1_1_0]">
                    <div className="absolute left-0">
                        {disclosure[0] ? (
                            <MdKeyboardArrowUp className="w-4 h-4 text-brand-primary-light" />
                        ) : (
                            <MdKeyboardArrowRight className="w-4 h-4 text-brand-primary-light" />
                        )}
                    </div>
                    <span className="pl-5">{balance.currency}</span>
                </div>
                <div
                    className={cn('flex flex-[2_1_0] relative items-center justify-center gap-2 text-right', {
                        [balanceColor]: true
                    })}>
                    <span className="absolute left-0">
                        {priceChange.increased && <MdSwapVert className={cn('w-4 h-4 text-brand-primary-light')} />}
                        {priceChange.decreased && <MdSwapVert className={cn('w-4 h-4 text-brand-red-light')} />}
                    </span>
                    <span className="flex-[1_1_0]">{formatQty.format(balance.totalAmount)}</span>
                    {balance.totalAmountInBaseCurrency && (
                        <span className="flex-[1_1_0]">{formatQty.format(balance.totalAmountInBaseCurrency)}</span>
                    )}
                </div>
            </div>
            <div className="flex flex-col gap-1">
                {disclosure[0] && (
                    <Fragment>
                        <div className="flex justify-between text-sm px-1">
                            <div className="flex flex-[1_1_0]">
                                <div className="text-left flex-[1_1_0] pl-5 whitespace-nowrap">Settlement Date</div>
                            </div>
                            <div className="flex flex-[2_1_0] relative items-center justify-center gap-2 text-right">
                                <div className="text-right flex-[1_1_0]">-</div>
                                <div className="text-right flex-[1_1_0]">-</div>
                            </div>
                        </div>
                        {balance.rows.map((row, index) => (
                            <div key={index} className="flex justify-between text-sm px-1">
                                <div className="flex flex-[1_1_0]">
                                    <div className="text-left flex-[1_1_0] pl-5">{row.valueDate}</div>
                                </div>
                                <div
                                    className={cn(
                                        'flex flex-[2_1_0] relative items-center justify-center gap-2 text-right',
                                        {
                                            ['text-neutral-200']: row.amount === 0,
                                            ['text-brand-primary']: row.amount > 0,
                                            ['text-brand-red']: row.amount < 0
                                        }
                                    )}>
                                    <div className="text-right flex-[1_1_0]">{formatQty.format(row.amount)}</div>
                                    <div className="text-right flex-[1_1_0]">
                                        {formatQty.format(row.amountInBaseCurrency)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export default Balance;
