/* eslint-disable */
import { AssetType } from '../../enums/AssetTypeProto';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.staticdata.api.user.limit';

export interface CreateUserLimitRequest {
    clientRequestId: string;
    username: string;
    exchangeCode: string;
    securityCode: string;
    maximumVolume: number;
    positionLimit: number;
    enabled: boolean;
    assetType: AssetType;
}

export interface FindUserLimitsByUsername {
    clientRequestId: string;
    username: string;
}

/** FIXME: This should be an internal message, not to be used as part of public API */
export interface FindAllUserLimits {
    clientRequestId: string;
}

/** FIXME: This should be an internal message, not to be used as part of public API */
export interface UpdateOnStaticDataUserLimitRequest {
    clientRequestId: string;
}

export interface FindAssignedUserLimits {
    clientRequestId: string;
}

export interface DeleteUserLimitRequest {
    clientRequestId: string;
    username: string;
    assetType: AssetType;
    exchangeCode: string;
    securityCode: string;
}

export interface UpdateUserLimitRequest {
    clientRequestId: string;
    username: string;
    exchangeCode: string;
    securityCode: string;
    maximumVolume: number;
    positionLimit: number;
    enabled: boolean;
    assetType: AssetType;
}

function createBaseCreateUserLimitRequest(): CreateUserLimitRequest {
    return {
        clientRequestId: '',
        username: '',
        exchangeCode: '',
        securityCode: '',
        maximumVolume: 0,
        positionLimit: 0,
        enabled: false,
        assetType: 1
    };
}

export const CreateUserLimitRequest = {
    encode(message: CreateUserLimitRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(34).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(50).string(message.securityCode);
        }
        if (message.maximumVolume !== 0) {
            writer.uint32(57).double(message.maximumVolume);
        }
        if (message.positionLimit !== 0) {
            writer.uint32(65).double(message.positionLimit);
        }
        if (message.enabled === true) {
            writer.uint32(72).bool(message.enabled);
        }
        if (message.assetType !== 1) {
            writer.uint32(80).int32(message.assetType);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateUserLimitRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateUserLimitRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 4:
                    message.exchangeCode = reader.string();
                    break;
                case 6:
                    message.securityCode = reader.string();
                    break;
                case 7:
                    message.maximumVolume = reader.double();
                    break;
                case 8:
                    message.positionLimit = reader.double();
                    break;
                case 9:
                    message.enabled = reader.bool();
                    break;
                case 10:
                    message.assetType = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateUserLimitRequest>): CreateUserLimitRequest {
        const message = createBaseCreateUserLimitRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.username = object.username ?? '';
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.maximumVolume = object.maximumVolume ?? 0;
        message.positionLimit = object.positionLimit ?? 0;
        message.enabled = object.enabled ?? false;
        message.assetType = object.assetType ?? 1;
        return message;
    }
};

function createBaseFindUserLimitsByUsername(): FindUserLimitsByUsername {
    return { clientRequestId: '', username: '' };
}

export const FindUserLimitsByUsername = {
    encode(message: FindUserLimitsByUsername, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindUserLimitsByUsername {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindUserLimitsByUsername();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindUserLimitsByUsername>): FindUserLimitsByUsername {
        const message = createBaseFindUserLimitsByUsername();
        message.clientRequestId = object.clientRequestId ?? '';
        message.username = object.username ?? '';
        return message;
    }
};

function createBaseFindAllUserLimits(): FindAllUserLimits {
    return { clientRequestId: '' };
}

export const FindAllUserLimits = {
    encode(message: FindAllUserLimits, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAllUserLimits {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAllUserLimits();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAllUserLimits>): FindAllUserLimits {
        const message = createBaseFindAllUserLimits();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseUpdateOnStaticDataUserLimitRequest(): UpdateOnStaticDataUserLimitRequest {
    return { clientRequestId: '' };
}

export const UpdateOnStaticDataUserLimitRequest = {
    encode(message: UpdateOnStaticDataUserLimitRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOnStaticDataUserLimitRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateOnStaticDataUserLimitRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UpdateOnStaticDataUserLimitRequest>): UpdateOnStaticDataUserLimitRequest {
        const message = createBaseUpdateOnStaticDataUserLimitRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseFindAssignedUserLimits(): FindAssignedUserLimits {
    return { clientRequestId: '' };
}

export const FindAssignedUserLimits = {
    encode(message: FindAssignedUserLimits, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAssignedUserLimits {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAssignedUserLimits();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAssignedUserLimits>): FindAssignedUserLimits {
        const message = createBaseFindAssignedUserLimits();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseDeleteUserLimitRequest(): DeleteUserLimitRequest {
    return { clientRequestId: '', username: '', assetType: 1, exchangeCode: '', securityCode: '' };
}

export const DeleteUserLimitRequest = {
    encode(message: DeleteUserLimitRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        if (message.assetType !== 1) {
            writer.uint32(24).int32(message.assetType);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(34).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(42).string(message.securityCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUserLimitRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeleteUserLimitRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 3:
                    message.assetType = reader.int32() as any;
                    break;
                case 4:
                    message.exchangeCode = reader.string();
                    break;
                case 5:
                    message.securityCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<DeleteUserLimitRequest>): DeleteUserLimitRequest {
        const message = createBaseDeleteUserLimitRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.username = object.username ?? '';
        message.assetType = object.assetType ?? 1;
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        return message;
    }
};

function createBaseUpdateUserLimitRequest(): UpdateUserLimitRequest {
    return {
        clientRequestId: '',
        username: '',
        exchangeCode: '',
        securityCode: '',
        maximumVolume: 0,
        positionLimit: 0,
        enabled: false,
        assetType: 1
    };
}

export const UpdateUserLimitRequest = {
    encode(message: UpdateUserLimitRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(26).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(34).string(message.securityCode);
        }
        if (message.maximumVolume !== 0) {
            writer.uint32(41).double(message.maximumVolume);
        }
        if (message.positionLimit !== 0) {
            writer.uint32(49).double(message.positionLimit);
        }
        if (message.enabled === true) {
            writer.uint32(56).bool(message.enabled);
        }
        if (message.assetType !== 1) {
            writer.uint32(64).int32(message.assetType);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserLimitRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateUserLimitRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 3:
                    message.exchangeCode = reader.string();
                    break;
                case 4:
                    message.securityCode = reader.string();
                    break;
                case 5:
                    message.maximumVolume = reader.double();
                    break;
                case 6:
                    message.positionLimit = reader.double();
                    break;
                case 7:
                    message.enabled = reader.bool();
                    break;
                case 8:
                    message.assetType = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UpdateUserLimitRequest>): UpdateUserLimitRequest {
        const message = createBaseUpdateUserLimitRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.username = object.username ?? '';
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.maximumVolume = object.maximumVolume ?? 0;
        message.positionLimit = object.positionLimit ?? 0;
        message.enabled = object.enabled ?? false;
        message.assetType = object.assetType ?? 1;
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;
