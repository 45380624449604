import { Side } from '@/compiled_proto/com/celertech/marketdata/api/enums/SideProto';
import { accumulatePriceBookBidAsks } from '@/helpers/orderbookHelper';
import { useAppSelector } from '@/state/hooks';
import { useMemo } from 'react';
import { AccumulatedPriceLevel } from '../pricebook';
import { useInstrument } from './useInstrument';

export interface UseOrderBookReturn {
    bestAsk: number;
    bestBid: number;
    bestPrice: number;
    worstAsk: number;
    worstBid: number;
    bids: AccumulatedPriceLevel[] | null;
    asks: AccumulatedPriceLevel[] | null;
    nextWorstAsk: (quantity: number) => number;
    nextWorstBid: (quantity: number) => number;
}

const useOrderBook = (pair: string, side?: Side, ccy2Order?: boolean): UseOrderBookReturn => {
    const { roundPrice } = useInstrument(pair);
    const priceBooks = useAppSelector((state) => state.celerMarket.priceBooks[pair]);
    const { bids, asks } = useMemo(() => accumulatePriceBookBidAsks(priceBooks), [priceBooks, pair]);

    const bestAsk = useMemo(() => (asks ? roundPrice(asks[asks.length - 1]?.price || 0) : 0), [asks]);
    const bestBid = useMemo(() => (bids ? roundPrice(bids[0]?.price || 0) : 0), [bids]);
    const worstAsk = useMemo(() => (asks ? roundPrice(asks[0]?.price || 0) : 0), [asks]);
    const worstBid = useMemo(() => (bids ? roundPrice(bids[bids.length - 1]?.price || 0) : 0), [bids]);

    const bestPrice = useMemo(() => {
        if (ccy2Order) {
            return side === Side.BUY ? bestBid : bestAsk;
        } else if (!ccy2Order) {
            return side === Side.BUY ? bestAsk : bestBid;
        } else return 0;
    }, [asks, bids, ccy2Order]);

    const nextWorstAsk = (quantity: number) => getNextWorstAsk(asks || [], quantity);
    const nextWorstBid = (quantity: number) => getNextWorstBid(bids || [], quantity);

    return {
        bestAsk,
        bestBid,
        bestPrice,
        worstAsk,
        worstBid,
        bids,
        asks,
        nextWorstAsk,
        nextWorstBid
    };
};

export default useOrderBook;

/* 
    Sets value for limit price based on quantity. 
    Take limit price that has accumulated quantity >= quantity
    If Quantity === undefined, take worst price
*/

const getNextWorstAsk = (asks: AccumulatedPriceLevel[], quantity: number) => {
    const worstAsks = asks.filter((ask) => ask.accQuantity >= quantity);
    worstAsks.sort((a, b) => a.accQuantity - b.accQuantity);
    return worstAsks[0] ? worstAsks[0].price : asks[0].price;
};

const getNextWorstBid = (bids: AccumulatedPriceLevel[], quantity: number) => {
    const worstBids = bids.filter((bid) => bid.accQuantity >= quantity);
    return worstBids[0] ? worstBids[0].price : bids[0].price;
};

// Limit Order Helpers
export const invalidLimitPriceTooltip = (side: Side, bestPrice: number, ccy2Order: boolean) => {
    const tooltip = () => {
        switch (side) {
            case Side.BUY:
                return {
                    position: ccy2Order ? 'Bid' : 'Ask',
                    constraint: ccy2Order ? 'lower' : 'higher'
                };
            case Side.SELL:
                return {
                    position: ccy2Order ? 'Ask' : 'Bid',
                    constraint: ccy2Order ? 'higher' : 'lower'
                };
            default:
                return {
                    position: ccy2Order ? 'Bid' : 'Ask',
                    constraint: ccy2Order ? 'lower' : 'higher'
                };
        }
    };

    return `The Limit Price is 
    ${tooltip().constraint} than the current best 
    ${tooltip().position} price ${bestPrice}. The order will likely be filled immediately.`;
};

// Stop Order Helpers

export const invalidStopPriceMessage = (side: Side, bestPrice: string, ccy2Order: boolean) => {
    const tooltip = () => {
        switch (side) {
            case Side.BUY:
                return {
                    constraint: ccy2Order ? 'below' : 'above'
                };
            case Side.SELL:
                return {
                    constraint: ccy2Order ? 'above' : 'below'
                };
            default:
                return {
                    constraint: ccy2Order ? 'below' : 'above'
                };
        }
    };

    return `The Stop Price is on the wrong side of the market. The Stop Price must be ${
        tooltip().constraint
    } ${bestPrice}`;
};
