import { Combobox, Transition } from '@headlessui/react';
import cn from 'classnames';
import { Fragment, useMemo } from 'react';
import { MdDelete, MdEdit, MdMoreVert } from 'react-icons/md';

interface WatchListActionsProps {
    parentSize?: DOMRectReadOnly;
    onEdit(): void;
    onDelete(): void;
    deleteDisabled: boolean;
}

const WatchListActions = (props: WatchListActionsProps) => {
    const { parentSize, onEdit, onDelete, deleteDisabled } = props;

    const { shouldFullWidth, shouldShowRight } = useMemo(() => {
        if (!parentSize) return { shouldFullWidth: false, shouldShowRight: false };
        else if (parentSize.width >= 345 && parentSize.width < 470)
            return { shouldFullWidth: false, shouldShowRight: true };
        else if (parentSize.width < 345) return { shouldFullWidth: true, shouldShowRight: true };
        return { shouldFullWidth: false, shouldShowRight: false };
    }, [parentSize]);

    return (
        <Combobox value={null}>
            <div
                className={cn('relative', {
                    'w-full': shouldFullWidth
                })}>
                <div className="relative w-full cursor-default overflow-hidden bg-brand-background-dark border border-neutral-700 hover:border-neutral-600 text-left shadow-md sm:text-xs">
                    <Combobox.Button className="w-full py-1 pl-2 pr-6 text-left text-xs leading-5 text-neutral-200 truncate">
                        <span>Actions</span>
                        <div className="absolute inset-y-0 right-0 flex items-center pr-1">
                            <MdMoreVert className="h-4 w-4 text-neutral-200" aria-hidden="true" />
                        </div>
                    </Combobox.Button>
                </div>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <Combobox.Options
                        className={cn(
                            'absolute mt-2 z-[60] flex cursor-default bg-brand-background-dark border border-neutral-700 text-xs shadow-lg shadow-neutral-900 overflow-clip',
                            {
                                'left-0': !shouldShowRight,
                                'right-0': shouldShowRight
                            }
                        )}>
                        <div className="flex flex-col w-[203px] max-h-60 overflow-auto text-neutral-200">
                            <Combobox.Option
                                value={null}
                                className="flex items-center gap-2 relative cursor-pointer select-none py-2 pl-2 pr-4 group hover:bg-brand-primary"
                                onClick={onEdit}>
                                <MdEdit />
                                <span>Edit Watchlist</span>
                            </Combobox.Option>
                            <Combobox.Option
                                className={cn(
                                    'flex items-center gap-2 relative cursor-pointer select-none py-2 pl-2 pr-4 group',
                                    {
                                        'text-neutral-400 cursor-not-allowed': deleteDisabled,
                                        'hover:bg-brand-red': !deleteDisabled
                                    }
                                )}
                                value={null}
                                onClick={onDelete}
                                disabled={deleteDisabled}>
                                <MdDelete />
                                <span>Delete Watchlist</span>
                            </Combobox.Option>
                        </div>
                    </Combobox.Options>
                </Transition>
            </div>
        </Combobox>
    );
};

export default WatchListActions;
