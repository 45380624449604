import { useAppSelector } from '@/state/hooks';
import { LiteMarket, selectLiteMarkets } from '@/state/reducers/liteMarketSlice';
import useContextMenu from '@/utils/hooks/useContextMenu';
import { useCurrency } from '@/utils/hooks/useCurrency';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import { GetColorIndexesReturn, useInstrument } from '@/utils/hooks/useInstrument';
import usePreviousSelector from '@/utils/hooks/usePreviousSelector';
import BigNumber from 'bignumber.js';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { MdAddCircle, MdRemoveCircle, MdSettings } from 'react-icons/md';
import ContextMenu, { ContextMenuItem } from '../common/ContextMenu';
import CurrencyIcon from '../common/CurrencyIcon';
import LiteMarketsModal from '../modal/LiteMarketsModal';
import LiteOrderModal from '../modal/LiteOrderModal';
import { Highlights } from '../modules/MarketList/UserMarket';

const LiteMarkets = () => {
    const marketModal = useDisclosure(false);
    const convertModal = useDisclosure(false);
    const tradeMarkets = useAppSelector(selectLiteMarkets);

    const [selectedMarket, setSelectedMarket] = useState<LiteMarket>({} as LiteMarket);
    const [initFrom, setInitFrom] = useState<string>('');
    const [initTo, setInitTo] = useState<string>('');

    const [showConvertFrom, showConvertTo] = useMemo(() => {
        const showConvertFrom = useCurrency(selectedMarket?.name);
        const showConvertTo = useCurrency(selectedMarket?.toName);

        return [showConvertFrom.show, showConvertTo.show];
    }, [selectedMarket]);

    const { contextMenuX, contextMenuY, contextMenuVisible, handleOpenContextMenu, handleCloseContextMenu } =
        useContextMenu();

    const onSelectingBalance = useCallback(
        (e: any, market: LiteMarket) => {
            setSelectedMarket(market);
            handleOpenContextMenu(e);
        },
        [handleOpenContextMenu]
    );

    return (
        <Fragment>
            <div className="flex flex-col h-fit w-full md:w-3/5 gap-5 bg-brand-background-dark p-6">
                <div className="flex justify-between items-center">
                    <span className="text-base sm:text-lg font-bold">Today&apos;s Market</span>
                    <div className="p-1 rounded-full cursor-pointer hover:bg-neutral-700">
                        <MdSettings className="w-5 h-5" onClick={marketModal[1].open} />
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    {tradeMarkets.map((market, index) => (
                        <LiteMarketMonitor key={index} market={market} onClick={onSelectingBalance} />
                    ))}
                </div>
            </div>
            <ContextMenu
                x={contextMenuX}
                y={contextMenuY}
                visible={contextMenuVisible}
                onClose={handleCloseContextMenu}>
                <ContextMenuItem
                    onClick={() => {
                        setInitFrom(selectedMarket.toName);
                        setInitTo(selectedMarket.name);
                        convertModal[1].toggle();
                    }}>
                    <MdAddCircle />
                    <span>
                        Buy {showConvertFrom}, Sell {showConvertTo}
                    </span>
                </ContextMenuItem>
                <ContextMenuItem
                    className="hover:bg-brand-red"
                    onClick={() => {
                        setInitFrom(selectedMarket.name);
                        setInitTo(selectedMarket.toName);
                        convertModal[1].toggle();
                    }}>
                    <MdRemoveCircle />
                    <span>
                        Sell {showConvertFrom}, Buy {showConvertTo}
                    </span>
                </ContextMenuItem>
            </ContextMenu>
            <LiteMarketsModal opened={marketModal[0]} handlers={marketModal[1]} />
            <LiteOrderModal initFrom={initFrom} initTo={initTo} opened={convertModal[0]} handlers={convertModal[1]} />
        </Fragment>
    );
};

export default LiteMarkets;

interface LiteMarketMonitorProps {
    market: LiteMarket;
    onClick: (e: any, market: LiteMarket) => void;
}

const LiteMarketMonitor = ({ market, onClick }: LiteMarketMonitorProps) => {
    const linear = `${market.name}/${market.toName}`;
    const invert = `${market.toName}/${market.name}`;

    const { instrumentDirection, formatPip, formatHighlights } = useInstrument(linear, invert);
    const instrument = instrumentDirection === 'linear' ? linear : invert;
    const bidAskType = instrumentDirection === 'linear' ? 'ask' : 'bid';

    const bidAsk = useAppSelector((state) => state.marketPair.bidAsk[instrument]);
    const prevBid = usePreviousSelector(
        (state) => state.marketPair.bidAsk[instrument] && state.marketPair.bidAsk[instrument][bidAskType]
    ) as number;

    const rate = convertToRate(bidAsk, bidAskType);

    const { longName, show } = useCurrency(market.name);
    const [bidHighlights, setBidHighlights] = useState<GetColorIndexesReturn | undefined>();

    const marketPrice = useMemo(() => rate && formatPip(rate), [rate]);

    // for highlighting changes based on prev price
    useDidUpdate(() => {
        if (bidAsk && rate && prevBid) setBidHighlights(formatHighlights(prevBid, rate, bidHighlights));
    }, [rate]);

    return (
        <div
            className="flex justify-between w-full gap-3 p-1 px-3 rounded-sm text-xs sm:text-sm hover:bg-neutral-700 focus:bg-neutral-700 cursor-pointer"
            onClick={(e) => onClick(e, market)}>
            <div className="flex items-center gap-2">
                <CurrencyIcon ccy={market.logo} />
                <div className="flex flex-col">
                    <span>{longName || market.name}</span>
                    <span className="text-neutral-400 text-2xs sm:text-xs">{show}</span>
                </div>
            </div>
            {marketPrice && (
                <div className="flex items-center gap-2">
                    <span>{market.toName}</span>
                    <div className="flex-1 flex items-center justify-end">
                        <Highlights ticker={marketPrice} highlights={bidHighlights} />
                    </div>
                </div>
            )}
        </div>
    );
};

function convertToRate(bidAsk: { bid: number; ask: number } | undefined, type: string) {
    if (bidAsk?.ask && type === 'ask') return BigNumber(bidAsk.ask).toNumber();
    else if (bidAsk?.bid && type === 'bid') return BigNumber(bidAsk.bid).toNumber();
    else return 0;
}
