/* eslint-disable */
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.piggybank.api.datastream';

/** Clients can register for updates on all ledger changes */
export interface UpdateOnTransactionCreationRequest {
    clientRequestId: string;
}

function createBaseUpdateOnTransactionCreationRequest(): UpdateOnTransactionCreationRequest {
    return { clientRequestId: '' };
}

export const UpdateOnTransactionCreationRequest = {
    encode(message: UpdateOnTransactionCreationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOnTransactionCreationRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateOnTransactionCreationRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UpdateOnTransactionCreationRequest>): UpdateOnTransactionCreationRequest {
        const message = createBaseUpdateOnTransactionCreationRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;
