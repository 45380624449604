import Divider from '@/components/common/Divider';
import { switchTab } from '@/components/layout/MobileNavbar';
import Modal, { ModalClose, ModalHeader, ModalTitle } from '@/components/modal/Modal';
import { NotificationIcon } from '@/components/modules/Notifications';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { selectCredentials } from '@/state/reducers/authSlice';
import {
    selectNotifications,
    selectNotificationsVisited,
    setNotificationsVisited
} from '@/state/reducers/notificationSlice';
import { selectGeneralSettings } from '@/state/reducers/settingSlice';
import { formatNotification, formatUTCNotification } from '@/utils/format';
import { useDebouncedValue } from '@/utils/hooks/useDebouncedValue';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import useScreenSizes from '@/utils/hooks/useScreenSizes';
import useLayout from '@/utils/hooks/useXplorSpotLayout';
import cn from 'classnames';
import { differenceInMinutes } from 'date-fns';
import { now } from 'lodash';
import { useMemo } from 'react';

interface MobileNotificationsModal {}

const MobileNotificationsModal = (props: MobileNotificationsModal) => {
    // const { opened, handlers, type, title, body, created_at } = props;
    const dispatch = useAppDispatch();

    const credentials = useAppSelector(selectCredentials);
    const notifications = useAppSelector(selectNotifications);
    const generalSettings = useAppSelector(selectGeneralSettings);
    const notificationsVisited = useAppSelector(selectNotificationsVisited);
    const [debouncedNotifications] = useDebouncedValue(notifications, 1000);

    const screen = useScreenSizes();
    const { layout, default_layout } = useLayout(screen);

    const [opened, handlers] = useDisclosure(false);

    const shouldOpen = useMemo(
        () => (screen.mobile || screen.tablet) && opened,
        [screen.mobile, screen.tablet, opened]
    );

    const latestNotifications = useMemo(() => {
        const currentTime = now();
        const latest = debouncedNotifications.filter((notification) => {
            const minutesDifference = differenceInMinutes(currentTime, notification.created_at);
            return minutesDifference <= 3;
        });
        return latest;
    }, [debouncedNotifications]);

    useDidUpdate(() => {
        if (!notificationsVisited && latestNotifications.length > 0) handlers.open();
    }, [latestNotifications]);

    return (
        <Modal opened={!!shouldOpen} handlers={handlers} className="h-2/3 sm:h-full" size="max-w-md sm:max-w-lg">
            <ModalHeader>
                <ModalTitle>Notifications</ModalTitle>
                <ModalClose handlers={handlers} />
            </ModalHeader>
            <Divider />
            <div className="flex-1 basis-0 h-full overflow-y-scroll w-full bg-brand-background-dark text-neutral-200">
                {latestNotifications.map(({ title, body, type, created_at }, id) => {
                    return (
                        <div
                            key={id}
                            className={cn('flex gap-3 bg-brand-background-dark text-neutral-200 p-2 sm:px-4', {
                                ['border-b border-neutral-700']: true
                            })}>
                            <span className="mt-1">
                                <NotificationIcon type={type} />
                            </span>
                            <div className="flex flex-col w-full gap-1 text-sm sm:text-base">
                                <div className="flex w-full justify-between gap-2">
                                    <div className="font-semibold line-clamp-2">{title}</div>
                                    <div className="text-sm text-neutral-400 whitespace-nowrap flex flex-col items-end">
                                        <span>
                                            {generalSettings.timezone.value === 'UTC'
                                                ? formatUTCNotification(created_at)
                                                : formatNotification(created_at)}
                                        </span>
                                        <span className="text-xs">( {generalSettings.timezone.label} )</span>
                                    </div>
                                </div>
                                <div className="text-neutral-400 whitespace-pre-wrap">{body}</div>
                            </div>
                        </div>
                    );
                })}
                <div className="text-neutral-400 text-2xs sm:text-sm text-center p-2 py-4 sm:px-4">
                    <div>Showing notifications from 3 minutes ago</div>
                </div>
            </div>
            <Divider />
            <div className="text-neutral-200 p-2 sm:p-4 text-sm flex space-x-2">
                <button
                    className={cn('rounded-md p-2 px-4 bg-neutral-600 hover:bg-neutral-500', {
                        ['w-full']: !credentials
                    })}
                    onClick={handlers.close}>
                    Close
                </button>
                {credentials && location.pathname === '/' && (
                    <button
                        className="rounded-md p-2 px-4 bg-brand-primary hover:bg-brand-primary-light"
                        onClick={() => {
                            handlers.close();
                            dispatch(setNotificationsVisited(true));
                            if (layout.first !== 'Notifications') switchTab(screen, layout, 'Notifications', dispatch);
                        }}>
                        View All
                    </button>
                )}
            </div>
        </Modal>
    );
};

export default MobileNotificationsModal;
