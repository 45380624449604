import { invalidStopPriceMessage } from '@/utils/hooks/useOrderBook';
import { InferType, number, object, string } from 'yup';
import { Side } from '../../../compiled_proto/com/celertech/marketdata/api/enums/SideProto';
import { OrderType } from '../../../compiled_proto/com/celertech/positionmanager/api/enums/OrderTypeProto';
import { Nullable } from '../../../model/common';
import { QuantitySchemaParams, quantitySchema } from './common';

interface StopOrderSchemaParams {
    quantityOptions: QuantitySchemaParams;
    priceOptions: {
        side: Side;
        bestAsk: number;
        bestBid: number;
        formattedBestAsk: string;
        formattedBestBid: string;
        ccy2Order: boolean;
    };
}

export const stopOrderSchema = ({ quantityOptions, priceOptions }: StopOrderSchemaParams) => {
    const { side, bestAsk, bestBid, formattedBestAsk, formattedBestBid, ccy2Order } = priceOptions;

    const bestPrice = () => {
        switch (side) {
            case Side.BUY:
                return ccy2Order ? formattedBestAsk : formattedBestBid;
            case Side.SELL:
                return ccy2Order ? formattedBestBid : formattedBestAsk;
            default:
                return ccy2Order ? formattedBestBid : formattedBestAsk;
        }
    };

    return object({
        type: object({ label: string(), value: number() }).required('Stop Type cannot be empty!'),
        stopPrice: number()
            .test('stopPriceConstraint', invalidStopPriceMessage(side, bestPrice(), ccy2Order), (price) => {
                if (side === Side.BUY) {
                    if (ccy2Order && +(price || 0) >= bestAsk) {
                        return false;
                    } else if (!ccy2Order && +(price || 0) <= bestBid) {
                        return false;
                    }
                }
                if (side === Side.SELL) {
                    if (ccy2Order && +(price || 0) <= bestBid) {
                        return false;
                    } else if (!ccy2Order && +(price || 0) >= bestAsk) {
                        return false;
                    }
                }
                return true;
            })
            .required('Stop Price cannot be empty!')
            .typeError('Invalid Input: Please input a number.'),
        stopLimitPrice: number()
            .test('stopLimitPriceRequired', 'Stop Limit Price cannot be empty!', function (value) {
                const { type } = this.parent;
                if (type === OrderType.STOP_LIMIT) return !!value;
                return true;
            })
            .typeError('Invalid Input: Please input a number.'),
        quantity: quantitySchema(quantityOptions),
        duration: object({ label: string(), value: number() }).required('Duration cannot be empty!'),
        slippage: string()
    });
};

export type StopOrderFormValues = InferType<ReturnType<typeof stopOrderSchema>>;
export type StopOrderFormInput = Nullable<StopOrderFormValues>;
