/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.preference.api.profile';

export interface ProfileDownstreamEvent {
    id: string;
    name: string;
    description: string;
    active: boolean;
    createdInMillis: string;
    updatedInMillis: string;
    type: string;
}

export interface PreferenceDownstreamEvent {
    profileId: string;
    key: string;
    value: string;
}

function createBaseProfileDownstreamEvent(): ProfileDownstreamEvent {
    return { id: '0', name: '', description: '', active: false, createdInMillis: '0', updatedInMillis: '0', type: '' };
}

export const ProfileDownstreamEvent = {
    encode(message: ProfileDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== '0') {
            writer.uint32(8).int64(message.id);
        }
        if (message.name !== '') {
            writer.uint32(18).string(message.name);
        }
        if (message.description !== '') {
            writer.uint32(26).string(message.description);
        }
        if (message.active === true) {
            writer.uint32(32).bool(message.active);
        }
        if (message.createdInMillis !== '0') {
            writer.uint32(40).int64(message.createdInMillis);
        }
        if (message.updatedInMillis !== '0') {
            writer.uint32(48).int64(message.updatedInMillis);
        }
        if (message.type !== '') {
            writer.uint32(58).string(message.type);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): ProfileDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProfileDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.description = reader.string();
                    break;
                case 4:
                    message.active = reader.bool();
                    break;
                case 5:
                    message.createdInMillis = longToString(reader.int64() as Long);
                    break;
                case 6:
                    message.updatedInMillis = longToString(reader.int64() as Long);
                    break;
                case 7:
                    message.type = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<ProfileDownstreamEvent>): ProfileDownstreamEvent {
        const message = createBaseProfileDownstreamEvent();
        message.id = object.id ?? '0';
        message.name = object.name ?? '';
        message.description = object.description ?? '';
        message.active = object.active ?? false;
        message.createdInMillis = object.createdInMillis ?? '0';
        message.updatedInMillis = object.updatedInMillis ?? '0';
        message.type = object.type ?? '';
        return message;
    }
};

function createBasePreferenceDownstreamEvent(): PreferenceDownstreamEvent {
    return { profileId: '0', key: '', value: '' };
}

export const PreferenceDownstreamEvent = {
    encode(message: PreferenceDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.profileId !== '0') {
            writer.uint32(8).int64(message.profileId);
        }
        if (message.key !== '') {
            writer.uint32(18).string(message.key);
        }
        if (message.value !== '') {
            writer.uint32(26).string(message.value);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): PreferenceDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePreferenceDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.profileId = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.key = reader.string();
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<PreferenceDownstreamEvent>): PreferenceDownstreamEvent {
        const message = createBasePreferenceDownstreamEvent();
        message.profileId = object.profileId ?? '0';
        message.key = object.key ?? '';
        message.value = object.value ?? '';
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
