/* Source: https://github.com/KyberNetwork/kyberswap-interface/blob/860030939a688248772755ad15c2392f1d563e7a/src/components/TradingViewChart/datafeed.tsx */

import { useRef } from 'react';

import { configurationData } from '@/config/chart';
import instrumentConfig from '@/config/instruments';
import netdaniaConfig from '@/config/netdania';
import {
    constructSymbolInfo,
    convertResolutionToSeconds,
    initDataFetch,
    subsequentDataFetch
} from '@/helpers/datafeedHelper';
import { setActivePair } from '@/state/reducers/marketPairSlice';
import { RootState, store } from '@/state/store';
import {
    ErrorCallback,
    HistoryCallback,
    IDatafeedChartApi,
    IExternalDatafeed,
    LibrarySymbolInfo,
    OnReadyCallback,
    PeriodParams,
    ResolutionString,
    ResolveCallback,
    SearchSymbolsCallback,
    SubscribeBarsCallback
} from '@/types/charting_library';

export const useDatafeed = () => {
    const intervalRef = useRef<any>(null);
    // const NDConnection = useMemo(() => new global.NetDania.JsApi.JSONConnection(connectionConfig), []);
    // useEffect(() => {
    //     if (NDConnection && NDConnection?.listeners?.OnHistoricalData?.length >= 5) {
    //         NDConnection.removeListener(global.NetDania.JsApi.Events.ONHISTORICALDATA);
    //     }
    // }, [NDConnection.listeners.OnHistoricalData]);

    return {
        onReady: (callback: OnReadyCallback) => {
            setTimeout(() => callback(configurationData));
        },
        resolveSymbol: async (
            symbolName: string,
            onSymbolResolvedCallback: ResolveCallback,
            onResolveErrorCallback: ErrorCallback
        ) => {
            const state: RootState = store.getState();
            const activePair = state.marketPair.activePair;
            const pairs = state.marketPair.pairs;

            const TVWidget = window.TVWidget;

            TVWidget.chart()
                .onIntervalChanged()
                .subscribe(null, (resolution: ResolutionString) => {
                    // if (resolution === '1T') TVWidget.chart().setChartType(2);
                    // else TVWidget.chart().setChartType(1);
                    const chartState = localStorage.getItem('chartState');
                    const savedChart = chartState ? JSON.parse(chartState) : undefined;
                    savedChart.charts[0].panes[0].sources[0].state.interval = resolution;
                    localStorage.setItem('chartState', JSON.stringify(savedChart));
                });

            TVWidget.subscribe('onAutoSaveNeeded', () => {
                TVWidget.save((state: any) => {
                    localStorage.setItem('chartState', JSON.stringify(state));
                });
            });

            const pairToUse = activePair.netdania !== symbolName ? activePair.netdania : symbolName;
            let symbolItem;
            if (pairs.length > 0) {
                const allSymbols = pairs.map((pair) => ({
                    symbol: pair.netdania,
                    full_name: pair.celer,
                    description: pair.show,
                    exchange: '',
                    type: instrumentConfig[pair.celer]?.type || ''
                }));
                symbolItem = allSymbols.find(({ symbol }) => symbol === pairToUse);
            } else {
                symbolItem = {
                    symbol: 'BTC/USD',
                    full_name: 'BTC/USD',
                    description: 'BTC/USD',
                    exchange: '',
                    type: instrumentConfig['BTC/USD']?.type || ''
                };
            }
            if (symbolItem) {
                const symbolInfo = constructSymbolInfo(symbolItem, configurationData) as LibrarySymbolInfo;
                onSymbolResolvedCallback(symbolInfo);
            } else {
                onResolveErrorCallback('Cannot resolve symbol');
            }
        },
        getBars: async (
            symbolInfo: LibrarySymbolInfo,
            resolution: ResolutionString,
            periodParams: PeriodParams,
            onHistory: HistoryCallback,
            onError: ErrorCallback
        ) => {
            if (window.chartLoaded) {
                const interval = convertResolutionToSeconds(resolution);
                const symbol = symbolInfo.name.replace('/', '');
                const provider = netdaniaConfig[symbol]?.provider;

                const chartState = localStorage.getItem('chartState');
                const savedChart = chartState ? JSON.parse(chartState) : undefined;
                const isChartLoaded =
                    savedChart && savedChart.charts[0].panes[0].sources[0].state.interval === resolution;

                if ((isChartLoaded || savedChart === undefined) && provider) {
                    if (periodParams.firstDataRequest) {
                        initDataFetch(symbol, interval, periodParams, provider, onHistory, onError);
                    } else {
                        // This includes historical prices when user scrolls back
                        subsequentDataFetch(symbol, interval, periodParams, provider, onHistory, onError);
                    }
                } else {
                    onHistory([], { noData: true });
                }
            } else {
                onHistory([], { noData: true });
            }
        },
        searchSymbols: (userInput: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback) => {
            const state: RootState = store.getState();
            const pairs = state.marketPair.pairs;

            const temp = userInput.replace('/', '').toUpperCase();
            const filtered: any = pairs
                .filter((pair) => pair.show.replace('/', '').match(temp))
                .map((pair) => pair.show);
            // const filtered = allSymbols.filter((symbol) => symbol.match(temp));
            const returned = filtered.map((symbol) => ({
                description: symbol,
                exchange: '',
                full_name: symbol,
                symbol: symbol,
                ticker: symbol,
                type: instrumentConfig[symbol]?.type || ''
            }));
            onResult(returned);
        },
        subscribeBars: async (
            symbolInfo: LibrarySymbolInfo,
            resolution: ResolutionString,
            onTick: SubscribeBarsCallback,
            listenerGuid: string,
            onResetCacheNeededCallback: () => void
        ) => {
            if (intervalRef.current) clearInterval(intervalRef.current);

            const interval = setInterval(async () => {
                const state: RootState = store.getState();
                const updateBar = state.chartData.updateBar;
                if (
                    updateBar &&
                    updateBar?.symbol === symbolInfo.name &&
                    updateBar?.symbol === state.marketPair.activePair.netdania
                ) {
                    const { symbol, ...bar } = updateBar;
                    onTick(bar);
                }
            }, 1000);

            const state: RootState = store.getState();
            if (symbolInfo.name !== state.marketPair.activePair.netdania) {
                const dispatch = store.dispatch;
                const pair = state.marketPair.pairs.find((pair) => pair.netdania === symbolInfo.name) as any;
                dispatch(setActivePair(pair));
            }

            intervalRef.current = interval;
        },
        unsubscribeBars: (listenerGuid: string) => {}
    } as IDatafeedChartApi & IExternalDatafeed;
};
