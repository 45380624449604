/* eslint-disable */
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.baseserver.communication.login';

export interface LoginRequest {
    clientRequestId: string;
    username: string;
    password: string;
    text: string;
    twoFactorToken: string;
}

export interface LogoutRequest {
    clientRequestId: string;
}

export interface TermsAndConditionsRequest {
    clientRequestId: string;
}

export interface TermsAndConditionsAcceptReject {
    clientRequestId: string;
    accepted: boolean;
}

function createBaseLoginRequest(): LoginRequest {
    return { clientRequestId: '', username: '', password: '', text: '', twoFactorToken: '' };
}

export const LoginRequest = {
    encode(message: LoginRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        if (message.password !== '') {
            writer.uint32(26).string(message.password);
        }
        if (message.text !== '') {
            writer.uint32(34).string(message.text);
        }
        if (message.twoFactorToken !== '') {
            writer.uint32(42).string(message.twoFactorToken);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): LoginRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLoginRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 3:
                    message.password = reader.string();
                    break;
                case 4:
                    message.text = reader.string();
                    break;
                case 5:
                    message.twoFactorToken = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<LoginRequest>): LoginRequest {
        const message = createBaseLoginRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.username = object.username ?? '';
        message.password = object.password ?? '';
        message.text = object.text ?? '';
        message.twoFactorToken = object.twoFactorToken ?? '';
        return message;
    }
};

function createBaseLogoutRequest(): LogoutRequest {
    return { clientRequestId: '' };
}

export const LogoutRequest = {
    encode(message: LogoutRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): LogoutRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLogoutRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<LogoutRequest>): LogoutRequest {
        const message = createBaseLogoutRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseTermsAndConditionsRequest(): TermsAndConditionsRequest {
    return { clientRequestId: '' };
}

export const TermsAndConditionsRequest = {
    encode(message: TermsAndConditionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): TermsAndConditionsRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTermsAndConditionsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<TermsAndConditionsRequest>): TermsAndConditionsRequest {
        const message = createBaseTermsAndConditionsRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseTermsAndConditionsAcceptReject(): TermsAndConditionsAcceptReject {
    return { clientRequestId: '', accepted: false };
}

export const TermsAndConditionsAcceptReject = {
    encode(message: TermsAndConditionsAcceptReject, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.accepted === true) {
            writer.uint32(16).bool(message.accepted);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): TermsAndConditionsAcceptReject {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTermsAndConditionsAcceptReject();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.accepted = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<TermsAndConditionsAcceptReject>): TermsAndConditionsAcceptReject {
        const message = createBaseTermsAndConditionsAcceptReject();
        message.clientRequestId = object.clientRequestId ?? '';
        message.accepted = object.accepted ?? false;
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;
