/* eslint-disable */
import { AssetType } from '../enums/AssetTypeProto';
import { ProductType } from '../enums/ProductTypeProto';
import { MarketDataEntryType } from '../enums/MarketDataEntryTypeProto';
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.midrate.api.price';

export interface DownstreamMarketDataPriceEvent {
    assetType: AssetType;
    productType: ProductType;
    exchangeCode: string;
    securityCode: string;
    securityId: string;
    settlementType: string;
    settlementDate: string;
    marketDataPriceSnapshotLevel: DownstreamMarketDataPriceEvent_MarketDataPriceSnapshotLevel[];
    snapshotTimestampInMillis: string;
    /** If initial flag is true, it means we are sending a dummy price with the only intention of initialising resources, but avoiding persistence */
    initialPrice: boolean;
}

export interface DownstreamMarketDataPriceEvent_MarketDataPriceSnapshotLevel {
    marketDataEntryType: MarketDataEntryType;
    entryPrice: number;
    entrySize: number;
    entryPosition: number;
}

function createBaseDownstreamMarketDataPriceEvent(): DownstreamMarketDataPriceEvent {
    return {
        assetType: 1,
        productType: 1,
        exchangeCode: '',
        securityCode: '',
        securityId: '',
        settlementType: '',
        settlementDate: '',
        marketDataPriceSnapshotLevel: [],
        snapshotTimestampInMillis: '0',
        initialPrice: false
    };
}

export const DownstreamMarketDataPriceEvent = {
    encode(message: DownstreamMarketDataPriceEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.assetType !== 1) {
            writer.uint32(8).int32(message.assetType);
        }
        if (message.productType !== 1) {
            writer.uint32(16).int32(message.productType);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(26).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(34).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(42).string(message.securityId);
        }
        if (message.settlementType !== '') {
            writer.uint32(50).string(message.settlementType);
        }
        if (message.settlementDate !== '') {
            writer.uint32(58).string(message.settlementDate);
        }
        for (const v of message.marketDataPriceSnapshotLevel) {
            DownstreamMarketDataPriceEvent_MarketDataPriceSnapshotLevel.encode(v!, writer.uint32(66).fork()).ldelim();
        }
        if (message.snapshotTimestampInMillis !== '0') {
            writer.uint32(72).int64(message.snapshotTimestampInMillis);
        }
        if (message.initialPrice === true) {
            writer.uint32(80).bool(message.initialPrice);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): DownstreamMarketDataPriceEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDownstreamMarketDataPriceEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.assetType = reader.int32() as any;
                    break;
                case 2:
                    message.productType = reader.int32() as any;
                    break;
                case 3:
                    message.exchangeCode = reader.string();
                    break;
                case 4:
                    message.securityCode = reader.string();
                    break;
                case 5:
                    message.securityId = reader.string();
                    break;
                case 6:
                    message.settlementType = reader.string();
                    break;
                case 7:
                    message.settlementDate = reader.string();
                    break;
                case 8:
                    message.marketDataPriceSnapshotLevel.push(
                        DownstreamMarketDataPriceEvent_MarketDataPriceSnapshotLevel.decode(reader, reader.uint32())
                    );
                    break;
                case 9:
                    message.snapshotTimestampInMillis = longToString(reader.int64() as Long);
                    break;
                case 10:
                    message.initialPrice = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<DownstreamMarketDataPriceEvent>): DownstreamMarketDataPriceEvent {
        const message = createBaseDownstreamMarketDataPriceEvent();
        message.assetType = object.assetType ?? 1;
        message.productType = object.productType ?? 1;
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.settlementType = object.settlementType ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.marketDataPriceSnapshotLevel =
            object.marketDataPriceSnapshotLevel?.map((e) =>
                DownstreamMarketDataPriceEvent_MarketDataPriceSnapshotLevel.fromPartial(e)
            ) || [];
        message.snapshotTimestampInMillis = object.snapshotTimestampInMillis ?? '0';
        message.initialPrice = object.initialPrice ?? false;
        return message;
    }
};

function createBaseDownstreamMarketDataPriceEvent_MarketDataPriceSnapshotLevel(): DownstreamMarketDataPriceEvent_MarketDataPriceSnapshotLevel {
    return { marketDataEntryType: 1, entryPrice: 0, entrySize: 0, entryPosition: 0 };
}

export const DownstreamMarketDataPriceEvent_MarketDataPriceSnapshotLevel = {
    encode(
        message: DownstreamMarketDataPriceEvent_MarketDataPriceSnapshotLevel,
        writer: _m0.Writer = _m0.Writer.create()
    ): _m0.Writer {
        if (message.marketDataEntryType !== 1) {
            writer.uint32(8).int32(message.marketDataEntryType);
        }
        if (message.entryPrice !== 0) {
            writer.uint32(17).double(message.entryPrice);
        }
        if (message.entrySize !== 0) {
            writer.uint32(25).double(message.entrySize);
        }
        if (message.entryPosition !== 0) {
            writer.uint32(32).int32(message.entryPosition);
        }
        return writer;
    },

    decode(
        input: _m0.Reader | Uint8Array,
        length?: number
    ): DownstreamMarketDataPriceEvent_MarketDataPriceSnapshotLevel {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDownstreamMarketDataPriceEvent_MarketDataPriceSnapshotLevel();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketDataEntryType = reader.int32() as any;
                    break;
                case 2:
                    message.entryPrice = reader.double();
                    break;
                case 3:
                    message.entrySize = reader.double();
                    break;
                case 4:
                    message.entryPosition = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<DownstreamMarketDataPriceEvent_MarketDataPriceSnapshotLevel>
    ): DownstreamMarketDataPriceEvent_MarketDataPriceSnapshotLevel {
        const message = createBaseDownstreamMarketDataPriceEvent_MarketDataPriceSnapshotLevel();
        message.marketDataEntryType = object.marketDataEntryType ?? 1;
        message.entryPrice = object.entryPrice ?? 0;
        message.entrySize = object.entrySize ?? 0;
        message.entryPosition = object.entryPosition ?? 0;
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
