import App from '@/App';
import BuildRevision from '@/components/BuildRevision';
import ResponsiveToaster from '@/components/ResponsiveToaster';
import EventListeners from '@/components/common/EventListeners';
import { persistor, store } from '@/state/store';
import history from '@/utils/history';
import '@fontsource/roboto';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { SWROptions } from '@/config/swr';
import { SWRConfig } from 'swr';

import '@/styles/index.css';
import '@/styles/react-datepicker.css';
import '@/styles/react-mosaic-component.css';
import '@/styles/scrollbar.css';

import { MantineProvider, createTheme } from '@mantine/core';
import { BalancesProvider } from './utils/providers/BalancesProvider';
import { SSOProvider } from './utils/providers/SSOProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const theme = createTheme({
    /** Your theme override here */
});

root.render(
    <SWRConfig value={SWROptions}>
        <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <HistoryRouter history={history}>
                    <MantineProvider theme={theme}>
                        <SSOProvider>
                            <BalancesProvider>
                                <App />
                                <EventListeners />
                                <ResponsiveToaster />
                                <BuildRevision />
                            </BalancesProvider>
                        </SSOProvider>
                    </MantineProvider>
                </HistoryRouter>
            </PersistGate>
        </ReduxProvider>
    </SWRConfig>
);
