import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Mosaic, MosaicBranch } from 'react-mosaic-component';
import { useNavigate } from 'react-router';
import TraderLayout from './components/layout/trader/TraderLayout';
import IdleModal from './components/modal/IdleModal';
import FeatureModule from './components/mosaic/FeatureModule';
import BlottersModule from './components/trader-modules/Blotters/BlottersModule';
import ChartModule from './components/trader-modules/Chart/ChartModule';
import TickersModule from './components/trader-modules/Tickers/TickersModule';
import { XplorTraderModules, xplorTraderLayoutConfig } from './config/xplorTraderLayout';
import { useAppDispatch, useAppSelector } from './state/hooks';
import { controlInitSubscriptions, selectCredentials } from './state/reducers/authSlice';
import { getBlotterItemsThunk, getBlotterTradesThunk } from './state/reducers/blotterSlice';
import { PairMap, fetchUserMarkets, setActivePair } from './state/reducers/marketPairSlice';
import {
    setXplorTraderDesktopLayoutNode,
    setXplorTraderLaptopLayoutNode
} from './state/reducers/xplorTraderLayoutSlice';
import { store } from './state/store';
import { useDisclosure } from './utils/hooks/useDisclosure';
import { useIdle } from './utils/hooks/useIdle';
import useScreenSizes from './utils/hooks/useScreenSizes';
import useXplorTraderLayout from './utils/hooks/useXplorTraderLayout';
import { navigateAwayFromXplorTrader } from './utils/navigation';
import { isCorrectLayout } from './utils/route';

const idleTimer = +(window.config.idleTimer || 600000);
const xplortraderEnabled = window.config?.layout?.xplortrader?.enabled;

export const initActivePair = () => {
    const state = store.getState();
    const dispatch = store.dispatch;

    const currentPair = state.marketPair.activePair?.netdania;
    const pairs = state.marketPair.pairs;
    if (!currentPair || !pairs.find((pair) => pair.netdania === currentPair)) {
        const primary = pairs.find((pair) => pair.netdania === 'BTC/USD');
        const secondary = pairs.find((pair) => pair.netdania === 'EUR/USD');
        const activePair = primary || secondary || pairs[0];
        dispatch(setActivePair(activePair));
        return activePair;
    } else {
        dispatch(setActivePair(state.marketPair.activePair as PairMap));
        return state.marketPair.activePair;
    }
};

function XplorTrader() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const credentials = useAppSelector(selectCredentials);

    const idle = useIdle(idleTimer);
    const idleDisclosure = useDisclosure(false);

    const screen = useScreenSizes();
    const { layout, default_layout } = useXplorTraderLayout(screen);

    const [isResizing, setIsResizing] = useState<any>(false);
    const [isDragging, setIsDragging] = useState<any>(false);
    const resizeStart = useCallback(() => setIsResizing(true), []);
    const resizeEnd = useCallback(() => setIsResizing(false), []);
    const dragStart = useCallback(() => setIsDragging(true), []);
    const dragEnd = useCallback(() => setIsDragging(false), []);

    const shouldOverlayCharts = useMemo(() => isDragging || isResizing, [isDragging, isResizing]);

    const renderTile = useCallback(
        (id: XplorTraderModules, path: MosaicBranch[]) => {
            const props = { path, screen, dragStart, dragEnd, tradingModule: xplorTraderLayoutConfig[id] };
            switch (id) {
                case 'Tickers':
                    return <TickersModule {...props} />;
                case 'Blotter':
                    return <BlottersModule {...props} />;
                case 'Chart':
                    return <ChartModule {...props} shouldOverlayCharts={shouldOverlayCharts} />;
                default:
                    return <FeatureModule {...props} />;
            }
        },
        [screen]
    );

    useEffect(() => {
        if (idle) idleDisclosure[1].open();
    }, [idle]);

    useEffect(() => {
        if (!credentials) {
            navigate('/login');
        } else {
            if (xplortraderEnabled && isCorrectLayout('xplortrader')) {
                // Fire initial requests
                dispatch(controlInitSubscriptions());
                dispatch(fetchUserMarkets()).then(() => {
                    // const activePair = initActivePair() as PairMap;
                    // upgradeMarket(credentials, activePair.celer, dispatch);
                    initActivePair();
                    dispatch(getBlotterItemsThunk()).then(() => dispatch(getBlotterTradesThunk()));
                });
            } else {
                navigateAwayFromXplorTrader(navigate);
            }
        }
    }, []);

    return credentials ? (
        <Fragment>
            <TraderLayout>
                <Mosaic<XplorTraderModules>
                    renderTile={renderTile}
                    value={layout}
                    initialValue={layout || default_layout}
                    onChange={(node) => {
                        if (screen.laptop) dispatch(setXplorTraderLaptopLayoutNode(node));
                        if (screen.desktop) dispatch(setXplorTraderDesktopLayoutNode(node));
                        resizeStart();
                    }}
                    onRelease={resizeEnd}
                    resize={{ minimumPaneSizePercentage: 0 }}
                />
                <IdleModal opened={idleDisclosure[0]} handlers={idleDisclosure[1]} />
            </TraderLayout>
        </Fragment>
    ) : null;
}

export default XplorTrader;
