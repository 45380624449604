import { UseDisclosureReturn } from '@/utils/hooks/useDisclosure';
import { MdMenu } from 'react-icons/md';

interface LiteMobileNavbarProps {
    drawerDisclosure: UseDisclosureReturn;
}

const LiteMobileNavbar = ({ drawerDisclosure }: LiteMobileNavbarProps) => {
    return (
        <div className="flex lg:hidden h-full gap-1 mr-1">
            {/* <NotificationButton
                size="w-6 h-6"
                isActive={layout.first === 'Notifications'}
                onClick={() => {
                    dispatch(setNotificationsVisited(true));
                    if (layout.first !== 'Notifications') switchTab(screen, layout, 'Notifications', dispatch);
                    else switchTab(screen, layout, layout.second?.second || '', dispatch);
                }}
            /> */}
            <button
                type="button"
                className="inline-flex p-2 my-1 items-center justify-center rounded-md border border-neutral-700 text-neutral-200 focus:outline-none transition duration-150 ease-in-out"
                onClick={drawerDisclosure[1].toggle}>
                <MdMenu className="w-6 h-6" />
            </button>
        </div>
    );
};

export default LiteMobileNavbar;
