import { InferType, number, object, string } from 'yup';
import { Nullable } from '../../../model/common';

interface ClosePositionSchemaParams {}

export const closePositionSchema = (params: ClosePositionSchemaParams) => {
    return object({
        quantity: number().required('Quantity cannot be empty!').typeError('Invalid Input: Please input a number.'),
        duration: object({ label: string(), value: number() }).required('Duration cannot be empty!')
    });
};

type ClosePositionSchema = ReturnType<typeof closePositionSchema>;
export type ClosePositionFormValues = InferType<ClosePositionSchema>;
export type ClosePositionFormInput = Nullable<ClosePositionFormValues>;
