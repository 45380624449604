import { shouldUseNopBalance } from '@/helpers/environmentHelper';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { selectCredentials, selectCurrentAccount } from '@/state/reducers/authSlice';
import { setBalanceStatus, setNopBalance } from '@/state/reducers/balanceSlice';
import { createContext, useContext, useEffect, useMemo } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { WebSocketHook } from 'react-use-websocket/dist/lib/types';

export interface BalancesContext extends Partial<WebSocketHook> {}

export interface BalancesProviderProps {
    children: React.ReactNode;
}

const nopBalancesURL = window.config?.balances?.websocket;

const BalancesContext = createContext({} as BalancesContext);
export const useBalances = () => useContext(BalancesContext);
export const BalancesProvider = (props: BalancesProviderProps) => {
    const dispatch = useAppDispatch();
    const credentials = useAppSelector(selectCredentials);
    const currentAccount = useAppSelector(selectCurrentAccount);

    const wsURL = useMemo(() => {
        if (credentials && currentAccount && shouldUseNopBalance) {
            return `${nopBalancesURL}/${credentials.username}/${currentAccount}?sessionToken=${credentials.authToken}`;
        } else {
            return null;
        }
    }, [credentials, currentAccount]);

    const websocket = useWebSocket(wsURL, {
        share: false,
        shouldReconnect: () => true
    });

    const { readyState, lastJsonMessage, getWebSocket } = websocket;

    useEffect(() => {
        dispatch(setBalanceStatus(ReadyState[readyState] as keyof typeof ReadyState));
    }, [readyState]);

    useEffect(() => {
        if (lastJsonMessage?.balances) dispatch(setNopBalance(lastJsonMessage));
    }, [lastJsonMessage]);

    const value = useMemo<BalancesContext>(() => {
        return { readyState, lastJsonMessage, getWebSocket };
    }, [readyState, lastJsonMessage, getWebSocket]);

    return <BalancesContext.Provider value={value}>{props.children}</BalancesContext.Provider>;
};
