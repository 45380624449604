import { Side } from '@/compiled_proto/com/celertech/positionmanager/api/enums/SideProto';
import { formatOrderMessage } from '@/helpers/orderHelper';
import { PairMap } from '@/state/reducers/marketPairSlice';
import { useEffect, useMemo, useState } from 'react';
import useActivePair from './useActivePair';
import { UseCurrencyReturn, useCurrency } from './useCurrency';
import { UseInstrumentReturn, useInstrument } from './useInstrument';
import useOrderBook, { UseOrderBookReturn } from './useOrderBook';

export interface UseOrderExecutionReturn {
    ccy1: string;
    ccy2: string;
    sideState: [Side, React.Dispatch<React.SetStateAction<Side>>];
    currencyState: [string, React.Dispatch<React.SetStateAction<string>>];
    activePair: PairMap;
    orderBook: UseOrderBookReturn;
    orderMessage: string;
    instrument: UseInstrumentReturn;
    isCcy2Order: boolean;
    ccy1Config: UseCurrencyReturn;
    ccy2Config: UseCurrencyReturn;
    activeCurrencyConfig: UseCurrencyReturn;
}

const useOrderExecution = (): UseOrderExecutionReturn => {
    const activePair = useActivePair();
    const [ccy1, ccy2] = useMemo(() => activePair.celer.split('/'), [activePair.celer]);

    const sideState = useState<Side>(Side.BUY);
    const currencyState = useState<string>(ccy1);

    const ccy1Config = useCurrency(ccy1);
    const ccy2Config = useCurrency(ccy2);

    const isCcy2Order = useMemo(() => currencyState[0] === ccy2, [currencyState[0], ccy2]);

    const instrument = useInstrument(activePair.celer);
    const orderBook = useOrderBook(activePair.celer, sideState[0], isCcy2Order);

    const activeCurrencyConfig = useMemo(
        () => (currencyState[0] === ccy1 ? ccy1Config : ccy2Config),
        [currencyState[0]]
    );

    const orderMessage = useMemo(
        () => formatOrderMessage({ side: sideState[0], isCcy2Order, ccy1Config, ccy2Config }),
        [sideState[0], isCcy2Order, ccy1Config, ccy2Config]
    );

    useEffect(() => {
        currencyState[1](ccy1);
    }, [activePair.celer]);

    return {
        ccy1,
        ccy2,
        sideState,
        currencyState,
        orderBook,
        orderMessage,
        instrument,
        activeCurrencyConfig,
        activePair,
        ccy1Config,
        ccy2Config,
        isCcy2Order
    };
};

export default useOrderExecution;
