import useDynamicSVGImport from '@/utils/hooks/useDynamicSVGImport';
import cn from 'classnames';

const CurrencyIcon = ({ ccy, disabled }: { ccy: string; disabled?: boolean }) => {
    const svg = useDynamicSVGImport(ccy) as any;
    return svg ? (
        <img className={cn('block h-6', { ['opacity-80']: disabled })} src={svg} />
    ) : (
        <div className="block h-6 min-w-[1.5rem] rounded-full bg-neutral-300" />
    );
};

export default CurrencyIcon;
