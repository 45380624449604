import { InferType, number, object, string } from 'yup';
import { Nullable } from '../../../model/common';
import { QuantitySchemaParams, quantitySchema } from './common';

interface LimitOrderSchemaParams extends QuantitySchemaParams {}

export const limitOrderSchema = (params: LimitOrderSchemaParams) => {
    return object({
        limitPrice: number()
            .required('Limit Price cannot be empty!')
            .typeError('Invalid Input: Please input a number.'),
        quantity: quantitySchema(params),
        duration: object({ label: string(), value: number() }).required('Duration cannot be empty!'),
        slippage: string()
    });
};

export type LimitOrderFormValues = InferType<ReturnType<typeof limitOrderSchema>>;
export type LimitOrderFormInput = Nullable<LimitOrderFormValues>;
