import { Viewer, Worker } from '@react-pdf-viewer/core';
import { ToolbarProps, ToolbarSlot, defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import React, { ReactElement, useRef } from 'react';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import '@/styles/pdf-viewer.css';

type PdfViewerTypes = {
    file?: Uint8Array | string;
};

export const PdfViewer: React.FC<PdfViewerTypes> = ({ file }) => {
    const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
        <Toolbar>
            {(slots: ToolbarSlot) => {
                const {
                    CurrentPageInput,
                    CurrentScale,
                    GoToNextPage,
                    GoToPreviousPage,
                    Download,
                    NumberOfPages,
                    ZoomIn,
                    ZoomOut,
                    EnterFullScreen
                } = slots;
                return (
                    <div className="flex w-full items-center justify-center sticky top-0 gap-2 bg-brand-background-dark text-neutral-200">
                        <ZoomOut />
                        <CurrentScale>
                            {(props: any) => <span>{`${Math.round(props.scale * 100)}%`}</span>}
                        </CurrentScale>
                        <ZoomIn />
                        <GoToPreviousPage />
                        <div className={`w-max p-2 searchInput`}>
                            <CurrentPageInput />
                        </div>
                        / <NumberOfPages />
                        <GoToNextPage />
                        <EnterFullScreen />
                        <Download />
                    </div>
                );
            }}
        </Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
        sidebarTabs: () => []
    });

    const myRef = useRef();

    return (
        <div className="flex-1 flex basis-0 overflow-y-auto h-full relative w-full">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
                <Viewer
                    fileUrl={file as any}
                    plugins={[defaultLayoutPluginInstance]}
                    theme="dark"
                    initialPage={0}
                    key={myRef.current}
                />
            </Worker>
        </div>
    );
};
