import { Listbox, Transition } from '@headlessui/react';
import cn from 'classnames';
import { Fragment, forwardRef } from 'react';
import { MdExpandMore } from 'react-icons/md';
import CurrencyIcon from '../common/CurrencyIcon';

export interface Option {
    label: string;
    ccy: string;
    value: any;
}

interface CurrencySelectProps {
    options: Option[];
    selected?: Option;
    onSelectChange?: (e: Option) => void;
    onClick?: () => void;
    children?: any;
    disabled?: boolean;
}

const CurrencySelect = forwardRef((props: CurrencySelectProps, ref) => {
    const { options, selected, onSelectChange, onClick, children, disabled = false } = props;

    return (
        <div>
            <Listbox value={selected?.value} onChange={onSelectChange}>
                {({ open }) => (
                    <div className="relative min-w-[150px] w-full">
                        <Listbox.Button
                            className={cn(
                                'relative w-full bg-brand-background-dark border border-neutral-700 py-2 pl-3 pr-10 text-left text-sm',
                                {
                                    ['rounded-t-md']: children || open,
                                    ['rounded-md']: (!children && !open) || disabled,
                                    ['cursor-not-allowed text-neutral-400']: disabled,
                                    ['cursor-pointer']: !disabled
                                }
                            )}
                            onClick={onClick}>
                            <div className="flex flex-[2_1_0] items-center gap-2">
                                <CurrencyIcon ccy={selected?.ccy || ''} disabled={disabled} />
                                <div className="flex flex-col">
                                    <span className="block truncate text-sm">{selected?.label || 'N/A'}</span>
                                    <span className="text-neutral-400 text-2xs sm:text-xs leading-normal">
                                        {(selected?.ccy || '').toUpperCase() || 'N/A'}
                                    </span>
                                </div>
                            </div>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <MdExpandMore
                                    className={cn('h-5 w-5', {
                                        ['text-neutral-400']: disabled,
                                        ['text-neutral-200']: !disabled
                                    })}
                                    aria-hidden="true"
                                />
                            </span>
                        </Listbox.Button>
                        {!disabled && (
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0">
                                {/* temporary shadow to the dropdown to differentiate */}
                                <Listbox.Options className="absolute z-[60] max-h-60 w-full overflow-auto rounded-b-md bg-brand-background-dark border border-t-0 border-neutral-700 py-1 text-sm shadow-lg shadow-neutral-900">
                                    {options.length > 0 ? (
                                        options.map((option, optionIdx) => (
                                            <Listbox.Option
                                                key={optionIdx}
                                                className={({ active }) =>
                                                    cn(`group relative cursor-pointer select-none py-2 pl-3 pr-4`, {
                                                        ['bg-brand-primary']: active,
                                                        'text-brand-primary-light': selected?.value === option.value,
                                                        'text-neutral-200': active && selected?.value === option.value
                                                    })
                                                }
                                                value={option}>
                                                <CurrencyOption option={option} />
                                            </Listbox.Option>
                                        ))
                                    ) : (
                                        <div className="relative cursor-default select-none py-2 px-4 text-neutral-200">
                                            No Available Options
                                        </div>
                                    )}
                                </Listbox.Options>
                            </Transition>
                        )}
                    </div>
                )}
            </Listbox>
            {children && children}
        </div>
    );
});

CurrencySelect.displayName = 'CurrencySelect';

export default CurrencySelect;

const CurrencyOption = ({ option }: { option: Option }) => {
    return (
        <div className="flex flex-[2_1_0] items-center gap-2">
            <CurrencyIcon ccy={option.ccy} />
            <div className="flex flex-col">
                <span className="block truncate text-sm">{option.label}</span>
                <span className="text-neutral-400 group-hover:text-neutral-200 text-2xs sm:text-xs leading-normal">
                    {option.ccy.toUpperCase()}
                </span>
            </div>
        </div>
    );
};
