import { Side } from '@/compiled_proto/com/celertech/marketdata/api/enums/SideProto';
import CurrencyIcon from '@/components/common/CurrencyIcon';
import { numberFormatterConfig } from '@/config/config';
import { availableCcyOption, ccyOption, currencyOptions } from '@/helpers/currencyHelper';
import { useAppSelector } from '@/state/hooks';
import { useCurrency } from '@/utils/hooks/useCurrency';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import { Tab } from '@headlessui/react';
import { useCallback, useEffect, useMemo } from 'react';
import { useNumberFormatter } from 'react-aria';
import { useFormContext } from 'react-hook-form';
import { MdSwapHoriz, MdSwapVert } from 'react-icons/md';
import ConvertFrom from './ConvertFrom';
import ConvertTo from './ConvertTo';

export const defaultFrom = ccyOption('usd') || ccyOption('usdt') || currencyOptions[0];
export const defaultTo = ccyOption('btc') || ccyOption('eth') || ccyOption('eur') || currencyOptions[1];
export const alternativeFrom = ccyOption('btc') || ccyOption('eth') || ccyOption('eur') || currencyOptions[0];
export const alternativeTo = ccyOption('usd') || ccyOption('usdt') || ccyOption('eur') || currencyOptions[1];

const emptyTo = {
    label: '',
    ccy: '',
    value: ''
};

const ConvertTab = ({ balances, conversionProps, setConvertFrom, convertToDisclosure }: any) => {
    const { availableConverts, estimatedProceeds, pair, type } = conversionProps;
    const bidAsk = useAppSelector((state) => state.marketPair.bidAsk[pair]);
    const numberFormatter = useNumberFormatter(numberFormatterConfig);

    const { resetField, watch, setValue } = useFormContext();

    const [convertFrom, convertTo] = watch(['convertFrom', 'convertTo']);
    const [convertFromCcy, convertToCcy] = useMemo(() => pair.split('/'), [pair]);
    const showConvertFrom = useCurrency(convertFromCcy);
    const showConvertTo = useCurrency(convertToCcy);

    const switchConversionDirections = useCallback(() => {
        const newConvertFrom = convertTo;
        const newConvertTo = convertFrom;
        setValue('convertFrom', newConvertFrom);
        setValue('convertTo', newConvertTo);
        setConvertFrom(newConvertFrom);
    }, [convertFrom, convertTo]);

    useDidUpdate(() => {
        resetField('amount', { defaultValue: '' });
    }, [convertFrom.value]);

    useEffect(() => {
        setValue('pair', pair);
        setValue('side', Side.SELL);
    }, [pair, type]);

    useEffect(() => {
        // if current convertTo is not available, set to first available
        if (!availableConverts.find((ccy) => ccy.value === convertTo.value)) {
            const convertToDefault =
                availableCcyOption(availableConverts, 'usd') ||
                availableCcyOption(availableConverts, 'btc') ||
                availableCcyOption(availableConverts, 'eth') ||
                availableCcyOption(availableConverts, 'eur');
            setValue('convertTo', convertToDefault || availableConverts[0] || emptyTo);
        }
    }, [convertFrom.value]);

    return (
        <Tab.Panel className="py-2 h-auto">
            <div className="flex flex-col items-center space-y-4">
                <ConvertFrom balances={balances} setConvertFrom={setConvertFrom} />
                <div
                    className="flex items-center gap-2 p-1 px-3 cursor-pointer text-sm rounded-full hover:bg-neutral-700"
                    onClick={switchConversionDirections}>
                    Switch
                    <MdSwapVert className="h-5 w-5" />
                </div>
                <ConvertTo
                    availableConverts={availableConverts}
                    estimatedProceeds={estimatedProceeds}
                    disclosure={convertToDisclosure}
                />
                {bidAsk && (
                    <div className="flex w-full items-center justify-between rounded-md bg-brand-background-dark border border-neutral-700 py-2 px-3">
                        <span className="text-neutral-400 text-sm tracking-wider">
                            1 {showConvertFrom.show} = {numberFormatter.format(bidAsk[type])} {showConvertTo.show}
                        </span>
                        <span className="flex justify-center gap-2">
                            <CurrencyIcon ccy={convertFrom.ccy} />
                            <MdSwapHoriz className="h-6 w-6" />
                            <CurrencyIcon ccy={convertTo.ccy} />
                        </span>
                    </div>
                )}
            </div>
        </Tab.Panel>
    );
};

export default ConvertTab;
