import cn from 'classnames';
import { ComponentProps, forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';

// to be deprecated

interface Props extends ComponentProps<'input'> {
    name: string;
    label?: string;
    className?: string;
}

const RHFInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
    const { getFieldState, formState } = useFormContext();
    const fieldState = getFieldState(props.name, formState);

    return (
        <div className="w-full">
            {props.label ? <label className="block text-sm font-medium text-neutral-200">{props.label}</label> : null}
            <input
                {...props}
                ref={ref}
                autoComplete="off"
                className={cn(
                    'appearance-none block w-full px-3 py-2 text-sm bg-brand-background-dark border border-neutral-700 rounded-md shadow-sm text-neutral-400 placeholder-neutral-600 focus:outline-none focus:ring-brand-primary focus:border-brand-primary',
                    props.className
                )}
            />
            {fieldState.error && (fieldState.isTouched || formState.isSubmitted) ? (
                <span className="text-brand-red italic">{fieldState.error?.message}</span>
            ) : null}
        </div>
    );
});

RHFInput.displayName = 'RHFInput';

export default RHFInput;
