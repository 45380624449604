import { useAppDispatch } from '@/state/hooks';
import { setNotificationsVisited } from '@/state/reducers/notificationSlice';
import {
    setXplorTraderMobileLayoutNode,
    setXplorTraderTabletLayoutNode
} from '@/state/reducers/xplorTraderLayoutSlice';
import { UseDisclosureReturn } from '@/utils/hooks/useDisclosure';
import useScreenSizes from '@/utils/hooks/useScreenSizes';
import useXplorTraderLayout from '@/utils/hooks/useXplorTraderLayout';
import { MdMenu } from 'react-icons/md';
import { updateTree } from 'react-mosaic-component';
import NotificationButton from '../../common/NotificationButton';

interface TraderMobileNavbarProps {
    drawerDisclosure: UseDisclosureReturn;
}

const TraderMobileNavbar = ({ drawerDisclosure }: TraderMobileNavbarProps) => {
    const dispatch = useAppDispatch();

    const screen = useScreenSizes();
    const { layout } = useXplorTraderLayout(screen);

    return (
        <div className="flex lg:hidden h-full gap-1 mr-1">
            <NotificationButton
                size="w-6 h-6"
                isActive={layout.second === 'Notifications'}
                onClick={() => {
                    dispatch(setNotificationsVisited(true));
                    if (layout.second !== 'Notifications') switchTab(screen, layout, 'Notifications', dispatch);
                    else switchTab(screen, layout, layout.first?.second || '', dispatch);
                }}
            />
            <button
                type="button"
                className="inline-flex p-2 my-1 items-center justify-center rounded-md border border-neutral-700 text-neutral-200 focus:outline-none transition duration-150 ease-in-out"
                onClick={drawerDisclosure[1].toggle}>
                <MdMenu className="w-6 h-6" />
            </button>
        </div>
    );
};

export default TraderMobileNavbar;

export const switchTab = (screen, layoutNode, moduleToDrop, dispatch) => {
    let currentNode = layoutNode;
    if (currentNode) {
        currentNode = updateTree(currentNode, [
            {
                path: [],
                spec: {
                    $set: {
                        direction: 'row',
                        first: {
                            direction: 'row',
                            first: screen.tablet ? 'Tickers' : '',
                            // saves the previous tab here, it doesn't show up on the screen
                            second: layoutNode.second,
                            splitPercentage: 100
                        },
                        second: moduleToDrop,
                        splitPercentage: screen.tablet ? 45 : 0
                    }
                }
            }
        ]);
    } else {
        currentNode = moduleToDrop;
    }

    if (screen.mobile) dispatch(setXplorTraderMobileLayoutNode(currentNode));
    if (screen.tablet) dispatch(setXplorTraderTabletLayoutNode(currentNode));
};
