import cn from 'classnames';
import { forwardRef, MutableRefObject, Ref } from 'react';
import ReactDatePicker from 'react-datepicker';
import { MdCalendarMonth, MdExpandMore } from 'react-icons/md';

interface DatePickerInputProps {
    label?: string;
    value?: string;
    labelClassName?: string;
    className?: string;
    disabled?: boolean;
    onClick?(): void;
    parentRef?: MutableRefObject<ExtendedDatePicker | null>;
    inline?: boolean;
}

export interface ExtendedDatePicker extends ReactDatePicker<never, undefined> {
    handleBlur(): void;
    handleCalendarClickOutside(): void;
    state: {
        open: boolean;
    };
}

const DatePickerInput = forwardRef(
    (
        { label, value, labelClassName, className, disabled, onClick, parentRef, inline = false }: DatePickerInputProps,
        ref: Ref<HTMLButtonElement>
    ) => {
        return (
            <div className="relative">
                {label && (
                    <div
                        className={cn(
                            'absolute -top-[8px] text-xs ml-2 text-neutral-200 px-1 bg-background',
                            labelClassName
                        )}>
                        {label}
                    </div>
                )}
                <button
                    type="button"
                    className={cn(
                        'flex items-center justify-between gap-3 border text-sm border-neutral-700 hover:border-neutral-600 px-2 py-1 rounded-md',
                        {
                            ['text-neutral-400 cursor-not-allowed']: disabled,
                            ['text-neutral-200 cursor-pointer']: !disabled,
                            ['bg-brand-background']: !inline,
                            ['bg-brand-background-darkark']: inline,
                            ['h-9']: !className
                        },
                        className
                    )}
                    onClick={() => {
                        if (onClick) onClick();
                        if (parentRef) parentRef.current?.setOpen(!parentRef.current.state.open);
                    }}
                    ref={ref}>
                    <MdCalendarMonth className="w-4 h-4" />
                    <div className="-mb-0.5">{value}</div>
                    <MdExpandMore className="h-4 w-4 text-neutral-200" aria-hidden="true" />
                </button>
            </div>
        );
    }
);

DatePickerInput.displayName = 'DatePickerInput';

export default DatePickerInput;
