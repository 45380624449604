/* eslint-disable */
import { AssetType } from '../enums/AssetTypeProto';
import { ProductType } from '../enums/ProductTypeProto';
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.positionmanager.api.pnl';

/** Response with calculated position effective-net-value. Request is com/celertech/positionmanager/api/pnl/UpstreamPnlProto.proto::EffectiveNetPositionRequest */
export interface EffectiveNetPositionResponse {
    clOrderId: string;
    account: string;
    rejectTrade: boolean;
    effectiveNetValue: number;
    message: string;
}

export interface NetPositionUpdate {
    username: string;
    /** key fields */
    accountCode: string;
    /** key fields */
    assetType: AssetType;
    exchangeCode: string;
    /** key fields */
    securityCode: string;
    /** key fields */
    securityId: string;
    netQtyPosition: number;
    netValuePosition: number;
    realisedPnl: number;
    destinationCode: string;
    /** key fields */
    productType: ProductType;
    /** currency pair asset types only */
    baseCurrencyBuys: number;
    termCurrencySells: number;
    baseCurrencySells: number;
    termCurrencyBuys: number;
    realisedPnLCurrency: string;
    convertedRealisedPnl: number;
    convertedRealisedPnlCurrency: string;
    clientTransactionId: string;
    startDate: string;
    endDate: string;
    commission: number;
    /** key fields */
    settlementType: string;
    /** key fields */
    settlementDate: string;
    shouldDelete: boolean;
    /** key fields */
    trader: string;
    isOverride: boolean;
    orderId: string;
    lastQty: number;
}

export interface PositionLimitDetails {
    username: string;
    positionKey: string;
    position: string;
    potentialPosition: string;
    maxLimitInDollars: string;
    runningTotal: string;
    currentOrderPosition: string;
    hasBreached: boolean;
}

function createBaseEffectiveNetPositionResponse(): EffectiveNetPositionResponse {
    return { clOrderId: '', account: '', rejectTrade: false, effectiveNetValue: 0, message: '' };
}

export const EffectiveNetPositionResponse = {
    encode(message: EffectiveNetPositionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clOrderId !== '') {
            writer.uint32(10).string(message.clOrderId);
        }
        if (message.account !== '') {
            writer.uint32(18).string(message.account);
        }
        if (message.rejectTrade === true) {
            writer.uint32(24).bool(message.rejectTrade);
        }
        if (message.effectiveNetValue !== 0) {
            writer.uint32(33).double(message.effectiveNetValue);
        }
        if (message.message !== '') {
            writer.uint32(42).string(message.message);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): EffectiveNetPositionResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEffectiveNetPositionResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clOrderId = reader.string();
                    break;
                case 2:
                    message.account = reader.string();
                    break;
                case 3:
                    message.rejectTrade = reader.bool();
                    break;
                case 4:
                    message.effectiveNetValue = reader.double();
                    break;
                case 5:
                    message.message = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<EffectiveNetPositionResponse>): EffectiveNetPositionResponse {
        const message = createBaseEffectiveNetPositionResponse();
        message.clOrderId = object.clOrderId ?? '';
        message.account = object.account ?? '';
        message.rejectTrade = object.rejectTrade ?? false;
        message.effectiveNetValue = object.effectiveNetValue ?? 0;
        message.message = object.message ?? '';
        return message;
    }
};

function createBaseNetPositionUpdate(): NetPositionUpdate {
    return {
        username: '',
        accountCode: '',
        assetType: 1,
        exchangeCode: '',
        securityCode: '',
        securityId: '',
        netQtyPosition: 0,
        netValuePosition: 0,
        realisedPnl: 0,
        destinationCode: '',
        productType: 1,
        baseCurrencyBuys: 0,
        termCurrencySells: 0,
        baseCurrencySells: 0,
        termCurrencyBuys: 0,
        realisedPnLCurrency: '',
        convertedRealisedPnl: 0,
        convertedRealisedPnlCurrency: '',
        clientTransactionId: '0',
        startDate: '',
        endDate: '',
        commission: 0,
        settlementType: '',
        settlementDate: '',
        shouldDelete: false,
        trader: '',
        isOverride: false,
        orderId: '0',
        lastQty: 0
    };
}

export const NetPositionUpdate = {
    encode(message: NetPositionUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.username !== '') {
            writer.uint32(10).string(message.username);
        }
        if (message.accountCode !== '') {
            writer.uint32(18).string(message.accountCode);
        }
        if (message.assetType !== 1) {
            writer.uint32(24).int32(message.assetType);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(34).string(message.exchangeCode);
        }
        if (message.securityCode !== '') {
            writer.uint32(42).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(50).string(message.securityId);
        }
        if (message.netQtyPosition !== 0) {
            writer.uint32(57).double(message.netQtyPosition);
        }
        if (message.netValuePosition !== 0) {
            writer.uint32(65).double(message.netValuePosition);
        }
        if (message.realisedPnl !== 0) {
            writer.uint32(73).double(message.realisedPnl);
        }
        if (message.destinationCode !== '') {
            writer.uint32(82).string(message.destinationCode);
        }
        if (message.productType !== 1) {
            writer.uint32(88).int32(message.productType);
        }
        if (message.baseCurrencyBuys !== 0) {
            writer.uint32(97).double(message.baseCurrencyBuys);
        }
        if (message.termCurrencySells !== 0) {
            writer.uint32(105).double(message.termCurrencySells);
        }
        if (message.baseCurrencySells !== 0) {
            writer.uint32(113).double(message.baseCurrencySells);
        }
        if (message.termCurrencyBuys !== 0) {
            writer.uint32(121).double(message.termCurrencyBuys);
        }
        if (message.realisedPnLCurrency !== '') {
            writer.uint32(130).string(message.realisedPnLCurrency);
        }
        if (message.convertedRealisedPnl !== 0) {
            writer.uint32(137).double(message.convertedRealisedPnl);
        }
        if (message.convertedRealisedPnlCurrency !== '') {
            writer.uint32(146).string(message.convertedRealisedPnlCurrency);
        }
        if (message.clientTransactionId !== '0') {
            writer.uint32(152).int64(message.clientTransactionId);
        }
        if (message.startDate !== '') {
            writer.uint32(162).string(message.startDate);
        }
        if (message.endDate !== '') {
            writer.uint32(170).string(message.endDate);
        }
        if (message.commission !== 0) {
            writer.uint32(177).double(message.commission);
        }
        if (message.settlementType !== '') {
            writer.uint32(186).string(message.settlementType);
        }
        if (message.settlementDate !== '') {
            writer.uint32(194).string(message.settlementDate);
        }
        if (message.shouldDelete === true) {
            writer.uint32(200).bool(message.shouldDelete);
        }
        if (message.trader !== '') {
            writer.uint32(210).string(message.trader);
        }
        if (message.isOverride === true) {
            writer.uint32(216).bool(message.isOverride);
        }
        if (message.orderId !== '0') {
            writer.uint32(224).int64(message.orderId);
        }
        if (message.lastQty !== 0) {
            writer.uint32(233).double(message.lastQty);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): NetPositionUpdate {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseNetPositionUpdate();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.username = reader.string();
                    break;
                case 2:
                    message.accountCode = reader.string();
                    break;
                case 3:
                    message.assetType = reader.int32() as any;
                    break;
                case 4:
                    message.exchangeCode = reader.string();
                    break;
                case 5:
                    message.securityCode = reader.string();
                    break;
                case 6:
                    message.securityId = reader.string();
                    break;
                case 7:
                    message.netQtyPosition = reader.double();
                    break;
                case 8:
                    message.netValuePosition = reader.double();
                    break;
                case 9:
                    message.realisedPnl = reader.double();
                    break;
                case 10:
                    message.destinationCode = reader.string();
                    break;
                case 11:
                    message.productType = reader.int32() as any;
                    break;
                case 12:
                    message.baseCurrencyBuys = reader.double();
                    break;
                case 13:
                    message.termCurrencySells = reader.double();
                    break;
                case 14:
                    message.baseCurrencySells = reader.double();
                    break;
                case 15:
                    message.termCurrencyBuys = reader.double();
                    break;
                case 16:
                    message.realisedPnLCurrency = reader.string();
                    break;
                case 17:
                    message.convertedRealisedPnl = reader.double();
                    break;
                case 18:
                    message.convertedRealisedPnlCurrency = reader.string();
                    break;
                case 19:
                    message.clientTransactionId = longToString(reader.int64() as Long);
                    break;
                case 20:
                    message.startDate = reader.string();
                    break;
                case 21:
                    message.endDate = reader.string();
                    break;
                case 22:
                    message.commission = reader.double();
                    break;
                case 23:
                    message.settlementType = reader.string();
                    break;
                case 24:
                    message.settlementDate = reader.string();
                    break;
                case 25:
                    message.shouldDelete = reader.bool();
                    break;
                case 26:
                    message.trader = reader.string();
                    break;
                case 27:
                    message.isOverride = reader.bool();
                    break;
                case 28:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 29:
                    message.lastQty = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<NetPositionUpdate>): NetPositionUpdate {
        const message = createBaseNetPositionUpdate();
        message.username = object.username ?? '';
        message.accountCode = object.accountCode ?? '';
        message.assetType = object.assetType ?? 1;
        message.exchangeCode = object.exchangeCode ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.netQtyPosition = object.netQtyPosition ?? 0;
        message.netValuePosition = object.netValuePosition ?? 0;
        message.realisedPnl = object.realisedPnl ?? 0;
        message.destinationCode = object.destinationCode ?? '';
        message.productType = object.productType ?? 1;
        message.baseCurrencyBuys = object.baseCurrencyBuys ?? 0;
        message.termCurrencySells = object.termCurrencySells ?? 0;
        message.baseCurrencySells = object.baseCurrencySells ?? 0;
        message.termCurrencyBuys = object.termCurrencyBuys ?? 0;
        message.realisedPnLCurrency = object.realisedPnLCurrency ?? '';
        message.convertedRealisedPnl = object.convertedRealisedPnl ?? 0;
        message.convertedRealisedPnlCurrency = object.convertedRealisedPnlCurrency ?? '';
        message.clientTransactionId = object.clientTransactionId ?? '0';
        message.startDate = object.startDate ?? '';
        message.endDate = object.endDate ?? '';
        message.commission = object.commission ?? 0;
        message.settlementType = object.settlementType ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.shouldDelete = object.shouldDelete ?? false;
        message.trader = object.trader ?? '';
        message.isOverride = object.isOverride ?? false;
        message.orderId = object.orderId ?? '0';
        message.lastQty = object.lastQty ?? 0;
        return message;
    }
};

function createBasePositionLimitDetails(): PositionLimitDetails {
    return {
        username: '',
        positionKey: '',
        position: '',
        potentialPosition: '',
        maxLimitInDollars: '',
        runningTotal: '',
        currentOrderPosition: '',
        hasBreached: false
    };
}

export const PositionLimitDetails = {
    encode(message: PositionLimitDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.username !== '') {
            writer.uint32(10).string(message.username);
        }
        if (message.positionKey !== '') {
            writer.uint32(18).string(message.positionKey);
        }
        if (message.position !== '') {
            writer.uint32(26).string(message.position);
        }
        if (message.potentialPosition !== '') {
            writer.uint32(34).string(message.potentialPosition);
        }
        if (message.maxLimitInDollars !== '') {
            writer.uint32(42).string(message.maxLimitInDollars);
        }
        if (message.runningTotal !== '') {
            writer.uint32(50).string(message.runningTotal);
        }
        if (message.currentOrderPosition !== '') {
            writer.uint32(58).string(message.currentOrderPosition);
        }
        if (message.hasBreached === true) {
            writer.uint32(64).bool(message.hasBreached);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): PositionLimitDetails {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePositionLimitDetails();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.username = reader.string();
                    break;
                case 2:
                    message.positionKey = reader.string();
                    break;
                case 3:
                    message.position = reader.string();
                    break;
                case 4:
                    message.potentialPosition = reader.string();
                    break;
                case 5:
                    message.maxLimitInDollars = reader.string();
                    break;
                case 6:
                    message.runningTotal = reader.string();
                    break;
                case 7:
                    message.currentOrderPosition = reader.string();
                    break;
                case 8:
                    message.hasBreached = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<PositionLimitDetails>): PositionLimitDetails {
        const message = createBasePositionLimitDetails();
        message.username = object.username ?? '';
        message.positionKey = object.positionKey ?? '';
        message.position = object.position ?? '';
        message.potentialPosition = object.potentialPosition ?? '';
        message.maxLimitInDollars = object.maxLimitInDollars ?? '';
        message.runningTotal = object.runningTotal ?? '';
        message.currentOrderPosition = object.currentOrderPosition ?? '';
        message.hasBreached = object.hasBreached ?? false;
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
