import { MarketDataBookType } from '@/compiled_proto/com/celertech/marketdata/api/enums/MarketDataBookTypeProto';
import { MarketDataEntryType } from '../compiled_proto/com/celertech/marketdata/api/enums/MarketDataEntryTypeProto';
import { MarketDataPriceEvent } from '../compiled_proto/com/celertech/marketdata/api/notification/MarketDataServiceProto';
import { MarketDataFullSnapshotDownstreamEvent_MarketDataPriceSnapshotLevel } from '../compiled_proto/com/celertech/marketdata/api/price/DownstreamPriceProto';

export interface PriceLevel {
    price: number;
    quantity: number;
}

export interface AccumulatedPriceLevel extends PriceLevel {
    accQuantity: number;
}

export interface PriceBook {
    bids: PriceLevel[];
    asks: PriceLevel[];
    midprice: number;
}

export type PricebookType = 'bids' | 'asks' | 'midprice';

export interface PricebookEntry {
    type: PricebookType;
    position: number;
    price: number;
    size: number;
}

export interface PriceEvent {
    // exchangeCode: string;
    // maturityDatetime: string;
    // requestId: string;
    bookType: MarketDataBookType;
    snapshotMillis: string;
    securityCode: string;
    priceBook: PricebookEntry[];
}

const convertPricebookType = (celerEntryType: MarketDataEntryType): PricebookType | null => {
    switch (celerEntryType) {
        case MarketDataEntryType.BID:
            return 'bids';
        case MarketDataEntryType.OFFER:
            return 'asks';
        case MarketDataEntryType.MID_PRICE:
            return 'midprice';
        default:
            console.warn(`Received unexpected pricebook entry type in convertPricebookType: ${celerEntryType}`);
            break;
    }
    return null;
};

const convertPricebookEntry = (
    celerLevel: MarketDataFullSnapshotDownstreamEvent_MarketDataPriceSnapshotLevel
): PricebookEntry | null => {
    const entryType = convertPricebookType(celerLevel.marketDataEntryType);
    if (!entryType) return null;
    return {
        type: entryType,
        position: celerLevel.pricePosition,
        price: celerLevel.entryPrice,
        size: celerLevel.entrySize
    };
};

export const convertPriceEvent = (celerPriceEvent: MarketDataPriceEvent): PriceEvent | null => {
    const snap = celerPriceEvent.fullSnapshot;
    if (!snap || !snap.marketDataPriceSnapshotLevel) return null;

    return {
        bookType: snap.marketDataBookType,
        // exchangeCode: snap.exchangeCode,
        // requestId: snap.marketDataRequestId,
        // maturityDatetime: snap.maturityDate,
        snapshotMillis: snap.snapshotTimestampInMillis,
        securityCode: snap.securityCode,
        priceBook: snap.marketDataPriceSnapshotLevel.map(convertPricebookEntry).filter(Boolean) as PricebookEntry[]
    };
};
