import { Menu, Transition } from '@headlessui/react';
import cn from 'classnames';
import { Fragment, ReactNode } from 'react';

interface IDropDown {
    label?: string;
    button: ReactNode;
    className?: string;
    position?: 'left' | 'right';
    children: ReactNode;
}

export const DropDown = ({ label, button, className, position = 'right', children }: IDropDown) => {
    return (
        <div className={cn(`flex z-20`, className)}>
            <Menu as="div" className="relative inline-block text-left border border-neutral-700 rounded-md">
                <div className="relative flex items-center">
                    <span className="absolute -top-2 left-2 text-2xs text-neutral-400 font-bold">{label}</span>
                    {button}
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items
                        className={cn(
                            `absolute mt-2 min-w-full w-fit origin-top-right divide-y divide-white/10 rounded-md py-1 bg-brand-background-dark border border-neutral-700 overflow-hidden`,
                            {
                                [`${position}-0`]: true
                            }
                        )}>
                        {children}
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
};
