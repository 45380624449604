/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.baseserver.communication.protobuf';

/** The encompassing message that wraps all messages that are sent / received via the API */
export interface ProtobufMessage {
    protobufClassName: string;
    protobufMessageContents: Uint8Array;
    sequenceNumber: string;
    metadata?: Metadata;
    conflationKey: string;
}

export interface Metadata {
    keyValue: KeyValue[];
}

export interface KeyValue {
    key: string;
    value: string;
}

/**
 * A special, higher abstraction message, that represents a response to a request sent in.  The
 * SingleResponseMessage represents the response to any requests that are to return only either
 * zero or one result.  i.e. findById
 *
 * This SingleResponseMessage will still need to be wrapped in a ProtobufMessage
 */
export interface SingleResponseMessage {
    clientRequestId: string;
    payload?: ResponsePayload;
}

/**
 * A special, higher abstraction message, that represents a response to a request sent in.  The
 * MultiResponseMessage represents the response to any requests that return zero or more results
 * i.e. findAll
 *
 * This MultiResponseMessage will still need to be wrapped in a ProtobufMessage
 */
export interface MultiResponseMessage {
    clientRequestId: string;
    payload: ResponsePayload[];
}

/**
 * A special, higher abstraction message, that represents an exception was encountered when processing a request.
 *
 * This ExceptionResponseMessage will still need to be wrapped in a ProtobufMessage
 */
export interface ExceptionResponseMessage {
    clientRequestId: string;
    payload?: ResponsePayload;
}

/**
 * A special, higher abstraction message, that represents the request was not allowed to be sent by this particular connection.
 *
 * This AccessDeniedMessage will still need to be wrapped in a ProtobufMessage
 */
export interface AccessDeniedMessage {
    clientRequestId: string;
    text: string;
    payload?: ResponsePayload;
}

/** The payload of either the SingleResponseMessage or MultiResponseMessage */
export interface ResponsePayload {
    className: string;
    contents: Uint8Array;
}

/** A request to reconnect to the server.  Must pass in a sessionToken and last received sequence number */
export interface ReconnectionRequest {
    sessionToken: string;
    lastReceivedSequenceNumber: string;
}

/** A message to denote that a reconnection attempt has failed. */
export interface ReconnectionFailedMessage {
    sessionToken: string;
    message: string;
}

/** An event that is sent from the server to notify the client which host they are connect to */
export interface ConnectedEvent {
    connectedHost: string;
    heartbeatIntervalInSecs: number;
}

/** Heartbeat message that is sent in both directions between the client and server */
export interface Heartbeat {
    clientRequestId: string;
    text: string;
}

export interface Ping {
    unthrottledBytes: string;
    throttledBytes: string;
    lastServerPingIntervalInMillis: string;
    timestampUtcInMillis: string;
}

export interface Pong {
    /** echo back Ping message */
    pingMessage?: Ping;
    /** time between latest Ping received on client and the previous */
    lastClientPingIntervalInMillis: string;
}

/** Warm up message used to prime the buffers */
export interface FakeWarmUpMessage {}

function createBaseProtobufMessage(): ProtobufMessage {
    return {
        protobufClassName: '',
        protobufMessageContents: new Uint8Array(),
        sequenceNumber: '0',
        metadata: undefined,
        conflationKey: ''
    };
}

export const ProtobufMessage = {
    encode(message: ProtobufMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.protobufClassName !== '') {
            writer.uint32(10).string(message.protobufClassName);
        }
        if (message.protobufMessageContents.length !== 0) {
            writer.uint32(18).bytes(message.protobufMessageContents);
        }
        if (message.sequenceNumber !== '0') {
            writer.uint32(24).int64(message.sequenceNumber);
        }
        if (message.metadata !== undefined) {
            Metadata.encode(message.metadata, writer.uint32(34).fork()).ldelim();
        }
        if (message.conflationKey !== '') {
            writer.uint32(42).string(message.conflationKey);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): ProtobufMessage {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProtobufMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.protobufClassName = reader.string();
                    break;
                case 2:
                    message.protobufMessageContents = reader.bytes();
                    break;
                case 3:
                    message.sequenceNumber = longToString(reader.int64() as Long);
                    break;
                case 4:
                    message.metadata = Metadata.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.conflationKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<ProtobufMessage>): ProtobufMessage {
        const message = createBaseProtobufMessage();
        message.protobufClassName = object.protobufClassName ?? '';
        message.protobufMessageContents = object.protobufMessageContents ?? new Uint8Array();
        message.sequenceNumber = object.sequenceNumber ?? '0';
        message.metadata =
            object.metadata !== undefined && object.metadata !== null
                ? Metadata.fromPartial(object.metadata)
                : undefined;
        message.conflationKey = object.conflationKey ?? '';
        return message;
    }
};

function createBaseMetadata(): Metadata {
    return { keyValue: [] };
}

export const Metadata = {
    encode(message: Metadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.keyValue) {
            KeyValue.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): Metadata {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMetadata();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.keyValue.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<Metadata>): Metadata {
        const message = createBaseMetadata();
        message.keyValue = object.keyValue?.map((e) => KeyValue.fromPartial(e)) || [];
        return message;
    }
};

function createBaseKeyValue(): KeyValue {
    return { key: '', value: '' };
}

export const KeyValue = {
    encode(message: KeyValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.key !== '') {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== '') {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): KeyValue {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseKeyValue();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<KeyValue>): KeyValue {
        const message = createBaseKeyValue();
        message.key = object.key ?? '';
        message.value = object.value ?? '';
        return message;
    }
};

function createBaseSingleResponseMessage(): SingleResponseMessage {
    return { clientRequestId: '', payload: undefined };
}

export const SingleResponseMessage = {
    encode(message: SingleResponseMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.payload !== undefined) {
            ResponsePayload.encode(message.payload, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): SingleResponseMessage {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSingleResponseMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.payload = ResponsePayload.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<SingleResponseMessage>): SingleResponseMessage {
        const message = createBaseSingleResponseMessage();
        message.clientRequestId = object.clientRequestId ?? '';
        message.payload =
            object.payload !== undefined && object.payload !== null
                ? ResponsePayload.fromPartial(object.payload)
                : undefined;
        return message;
    }
};

function createBaseMultiResponseMessage(): MultiResponseMessage {
    return { clientRequestId: '', payload: [] };
}

export const MultiResponseMessage = {
    encode(message: MultiResponseMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        for (const v of message.payload) {
            ResponsePayload.encode(v!, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MultiResponseMessage {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMultiResponseMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.payload.push(ResponsePayload.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<MultiResponseMessage>): MultiResponseMessage {
        const message = createBaseMultiResponseMessage();
        message.clientRequestId = object.clientRequestId ?? '';
        message.payload = object.payload?.map((e) => ResponsePayload.fromPartial(e)) || [];
        return message;
    }
};

function createBaseExceptionResponseMessage(): ExceptionResponseMessage {
    return { clientRequestId: '', payload: undefined };
}

export const ExceptionResponseMessage = {
    encode(message: ExceptionResponseMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.payload !== undefined) {
            ResponsePayload.encode(message.payload, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): ExceptionResponseMessage {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExceptionResponseMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.payload = ResponsePayload.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<ExceptionResponseMessage>): ExceptionResponseMessage {
        const message = createBaseExceptionResponseMessage();
        message.clientRequestId = object.clientRequestId ?? '';
        message.payload =
            object.payload !== undefined && object.payload !== null
                ? ResponsePayload.fromPartial(object.payload)
                : undefined;
        return message;
    }
};

function createBaseAccessDeniedMessage(): AccessDeniedMessage {
    return { clientRequestId: '', text: '', payload: undefined };
}

export const AccessDeniedMessage = {
    encode(message: AccessDeniedMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.text !== '') {
            writer.uint32(18).string(message.text);
        }
        if (message.payload !== undefined) {
            ResponsePayload.encode(message.payload, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): AccessDeniedMessage {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAccessDeniedMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.text = reader.string();
                    break;
                case 3:
                    message.payload = ResponsePayload.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<AccessDeniedMessage>): AccessDeniedMessage {
        const message = createBaseAccessDeniedMessage();
        message.clientRequestId = object.clientRequestId ?? '';
        message.text = object.text ?? '';
        message.payload =
            object.payload !== undefined && object.payload !== null
                ? ResponsePayload.fromPartial(object.payload)
                : undefined;
        return message;
    }
};

function createBaseResponsePayload(): ResponsePayload {
    return { className: '', contents: new Uint8Array() };
}

export const ResponsePayload = {
    encode(message: ResponsePayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.className !== '') {
            writer.uint32(10).string(message.className);
        }
        if (message.contents.length !== 0) {
            writer.uint32(18).bytes(message.contents);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): ResponsePayload {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseResponsePayload();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.className = reader.string();
                    break;
                case 2:
                    message.contents = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<ResponsePayload>): ResponsePayload {
        const message = createBaseResponsePayload();
        message.className = object.className ?? '';
        message.contents = object.contents ?? new Uint8Array();
        return message;
    }
};

function createBaseReconnectionRequest(): ReconnectionRequest {
    return { sessionToken: '', lastReceivedSequenceNumber: '0' };
}

export const ReconnectionRequest = {
    encode(message: ReconnectionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.sessionToken !== '') {
            writer.uint32(10).string(message.sessionToken);
        }
        if (message.lastReceivedSequenceNumber !== '0') {
            writer.uint32(16).int64(message.lastReceivedSequenceNumber);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): ReconnectionRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseReconnectionRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sessionToken = reader.string();
                    break;
                case 2:
                    message.lastReceivedSequenceNumber = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<ReconnectionRequest>): ReconnectionRequest {
        const message = createBaseReconnectionRequest();
        message.sessionToken = object.sessionToken ?? '';
        message.lastReceivedSequenceNumber = object.lastReceivedSequenceNumber ?? '0';
        return message;
    }
};

function createBaseReconnectionFailedMessage(): ReconnectionFailedMessage {
    return { sessionToken: '', message: '' };
}

export const ReconnectionFailedMessage = {
    encode(message: ReconnectionFailedMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.sessionToken !== '') {
            writer.uint32(10).string(message.sessionToken);
        }
        if (message.message !== '') {
            writer.uint32(18).string(message.message);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): ReconnectionFailedMessage {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseReconnectionFailedMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sessionToken = reader.string();
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<ReconnectionFailedMessage>): ReconnectionFailedMessage {
        const message = createBaseReconnectionFailedMessage();
        message.sessionToken = object.sessionToken ?? '';
        message.message = object.message ?? '';
        return message;
    }
};

function createBaseConnectedEvent(): ConnectedEvent {
    return { connectedHost: '', heartbeatIntervalInSecs: 0 };
}

export const ConnectedEvent = {
    encode(message: ConnectedEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.connectedHost !== '') {
            writer.uint32(10).string(message.connectedHost);
        }
        if (message.heartbeatIntervalInSecs !== 0) {
            writer.uint32(16).int32(message.heartbeatIntervalInSecs);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): ConnectedEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConnectedEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.connectedHost = reader.string();
                    break;
                case 2:
                    message.heartbeatIntervalInSecs = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<ConnectedEvent>): ConnectedEvent {
        const message = createBaseConnectedEvent();
        message.connectedHost = object.connectedHost ?? '';
        message.heartbeatIntervalInSecs = object.heartbeatIntervalInSecs ?? 0;
        return message;
    }
};

function createBaseHeartbeat(): Heartbeat {
    return { clientRequestId: '', text: '' };
}

export const Heartbeat = {
    encode(message: Heartbeat, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.text !== '') {
            writer.uint32(18).string(message.text);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): Heartbeat {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseHeartbeat();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.text = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<Heartbeat>): Heartbeat {
        const message = createBaseHeartbeat();
        message.clientRequestId = object.clientRequestId ?? '';
        message.text = object.text ?? '';
        return message;
    }
};

function createBasePing(): Ping {
    return {
        unthrottledBytes: '0',
        throttledBytes: '0',
        lastServerPingIntervalInMillis: '0',
        timestampUtcInMillis: '0'
    };
}

export const Ping = {
    encode(message: Ping, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.unthrottledBytes !== '0') {
            writer.uint32(8).int64(message.unthrottledBytes);
        }
        if (message.throttledBytes !== '0') {
            writer.uint32(16).int64(message.throttledBytes);
        }
        if (message.lastServerPingIntervalInMillis !== '0') {
            writer.uint32(24).int64(message.lastServerPingIntervalInMillis);
        }
        if (message.timestampUtcInMillis !== '0') {
            writer.uint32(32).int64(message.timestampUtcInMillis);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): Ping {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePing();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.unthrottledBytes = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.throttledBytes = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.lastServerPingIntervalInMillis = longToString(reader.int64() as Long);
                    break;
                case 4:
                    message.timestampUtcInMillis = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<Ping>): Ping {
        const message = createBasePing();
        message.unthrottledBytes = object.unthrottledBytes ?? '0';
        message.throttledBytes = object.throttledBytes ?? '0';
        message.lastServerPingIntervalInMillis = object.lastServerPingIntervalInMillis ?? '0';
        message.timestampUtcInMillis = object.timestampUtcInMillis ?? '0';
        return message;
    }
};

function createBasePong(): Pong {
    return { pingMessage: undefined, lastClientPingIntervalInMillis: '0' };
}

export const Pong = {
    encode(message: Pong, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.pingMessage !== undefined) {
            Ping.encode(message.pingMessage, writer.uint32(10).fork()).ldelim();
        }
        if (message.lastClientPingIntervalInMillis !== '0') {
            writer.uint32(16).int64(message.lastClientPingIntervalInMillis);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): Pong {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePong();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.pingMessage = Ping.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.lastClientPingIntervalInMillis = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<Pong>): Pong {
        const message = createBasePong();
        message.pingMessage =
            object.pingMessage !== undefined && object.pingMessage !== null
                ? Ping.fromPartial(object.pingMessage)
                : undefined;
        message.lastClientPingIntervalInMillis = object.lastClientPingIntervalInMillis ?? '0';
        return message;
    }
};

function createBaseFakeWarmUpMessage(): FakeWarmUpMessage {
    return {};
}

export const FakeWarmUpMessage = {
    encode(_: FakeWarmUpMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FakeWarmUpMessage {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFakeWarmUpMessage();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(_: DeepPartial<FakeWarmUpMessage>): FakeWarmUpMessage {
        const message = createBaseFakeWarmUpMessage();
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
