import { Side } from '@/compiled_proto/com/celertech/marketdata/api/enums/SideProto';
import { UseCurrencyReturn } from '@/utils/hooks/useCurrency';

interface OrderMessageParams {
    side: Side;
    isCcy2Order: boolean;
    ccy1Config: UseCurrencyReturn;
    ccy2Config: UseCurrencyReturn;
}

export function formatOrderMessage({ side, isCcy2Order, ccy1Config, ccy2Config }: OrderMessageParams) {
    if (!isCcy2Order) {
        return `${Side[side]} ${ccy1Config.show} / ${Side[side === 1 ? 2 : 1]} ${ccy2Config.show}`;
    } else {
        return `${Side[side]} ${ccy2Config.show} / ${Side[side === 1 ? 2 : 1]} ${ccy1Config.show}`;
    }
}
