import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';

import { Tab } from '@headlessui/react';
import { AxiosError } from 'axios';
import currencyConfig from '../../../config/currency';
import { requestFiatDeposit } from '../../../services/EmailService';
import { useAppSelector } from '../../../state/hooks';
import { selectCredentials } from '../../../state/reducers/authSlice';
import { useDisclosure } from '../../../utils/hooks/useDisclosure';
import useToast from '../../../utils/hooks/useToast';
import { Button } from '../../common/Button';
import Divider from '../../common/Divider';
import { FiatDepositFormInput, FiatDepositFormValues, fiatDepositSchema } from '../../form/schema/fiatDepositSchema';
import FilterSelect from '../../inputs/FilterSelect';
import Input from '../../inputs/Input';
import InputController from '../../inputs/InputController';
import Select from '../../inputs/Select';
import DepositConfirmationModal from '../DepositConfirmationModal';
import { ModalProps } from '../Modal';

const disclaimer = window.config?.modules?.deposit?.fiat?.disclaimer;

export const defaultAccount = { label: 'Select an account', value: undefined };
export const defaultCurrency = { label: 'Select an currency', value: undefined };

const defaultValues: Partial<FiatDepositFormInput> = {
    account: defaultAccount,
    amount: undefined,
    currency: defaultCurrency,
    email: undefined
};

const fiatCurrency = [
    defaultCurrency,
    ...currencyConfig
        .filter((ccy) => ccy.type === 'Fiat' && ccy.allowDepositWithdrawal)
        .map((ccy) => ({
            label: ccy.show,
            value: ccy.show
        }))
];

const FiatDepositTab = (props: ModalProps) => {
    const { opened, handlers } = props;

    const credentials = useAppSelector(selectCredentials);
    const confirmation = useDisclosure(false);
    const [toastError, toastSuccess] = useToast();

    const form = useForm<FiatDepositFormInput>({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(fiatDepositSchema())
    });

    const {
        reset,
        handleSubmit,
        formState: { isValid, isSubmitting }
    } = form;

    const onSubmit = async (data: FiatDepositFormInput, e) => {
        const dataValidated = data as FiatDepositFormValues;
        try {
            if (credentials) {
                await requestFiatDeposit(credentials, dataValidated);
                const title = `Fiat Deposit Request`;
                const body = `Your request to deposit ${dataValidated.currency.label} ${dataValidated.amount} has been submitted`;
                toastSuccess({ body, title, persist: true });
            }
        } catch (err: any) {
            const { response } = err as AxiosError<string>;
            const title = response?.statusText;
            const body = response?.data;
            toastError({ body, title, persist: true });
        }
    };

    const onError = (e: FieldErrors) => console.log(e);

    const accounts = useMemo(() => {
        const temp = credentials?.accounts?.map((account) => ({
            label: account.code,
            value: account.code
        }));
        if (temp) return [defaultAccount, ...temp];
        return [defaultAccount];
    }, [credentials]);

    useEffect(() => {
        if (opened) reset(defaultValues);
    }, [opened]);

    return (
        <Tab.Panel className="h-full">
            <FormProvider {...form}>
                <form className="h-full flex flex-col lg:block" onSubmit={handleSubmit(onSubmit, onError)} noValidate>
                    <div className="flex-1 basis-0 lg:flex-auto p-2 py-3 sm:p-4 h-auto lg:h-[450px] overflow-y-scroll">
                        <div className="flex flex-col gap-4">
                            <div className="space-y-3 sm:space-y-2">
                                <h3 className="font-bold">Deposit Details</h3>
                                <InputController name="account" label="Account To Deposit" required>
                                    <Select options={accounts || []} />
                                </InputController>
                                <InputController name="currency" label="Deposit Currency" required>
                                    <FilterSelect options={fiatCurrency} />
                                </InputController>
                                <InputController
                                    name="amount"
                                    label="Deposit Amount"
                                    placeholder="Deposit Amount"
                                    required>
                                    <Input />
                                </InputController>
                            </div>
                            <div className="space-y-3 sm:space-y-2">
                                <h3 className="font-bold">Email Details (optional)</h3>
                                <InputController name="email.cc_to" label="CC To" placeholder="CC To">
                                    <Input />
                                </InputController>
                            </div>
                            {disclaimer && (
                                <div className="space-y-3 sm:space-y-2">
                                    <h3 className="font-bold">Terms & Conditions</h3>
                                    <div className="text-sm text-neutral-400 text-justify">{disclaimer}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <Divider />
                    <div className="flex flex-row justify-between text-neutral-200 text-sm items-center p-2 sm:p-4">
                        <div className="flex justify-center w-full">
                            <Button
                                type="button"
                                onClick={confirmation[1].open}
                                isLoading={isSubmitting}
                                disabled={!isValid || isSubmitting}>
                                Send Deposit Request
                            </Button>
                        </div>
                    </div>
                    <DepositConfirmationModal
                        type="Fiat"
                        opened={confirmation[0]}
                        handlers={confirmation[1]}
                        user={credentials?.username}
                        keys={Object.keys(defaultValues) as Array<keyof FiatDepositFormValues>}
                        manualOnSubmit={handleSubmit(onSubmit, onError)}
                    />
                </form>
            </FormProvider>
        </Tab.Panel>
    );
};

export default FiatDepositTab;
