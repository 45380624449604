/* eslint-disable */
import { UserLoginPolicyType } from '../enums/UserLoginPolicyTypeProto';
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.baseserver.api.user';

export interface CreateSystemUser {
    clientRequestId: string;
    username: string;
    userLoginPolicyType: UserLoginPolicyType;
    email: string;
    enabled: boolean;
    expired: boolean;
    locked: boolean;
    passwordExpired: boolean;
}

export interface CreateStandardUser {
    clientRequestId: string;
    username: string;
    forename: string;
    surname: string;
    region: string;
    email: string;
    telephone: string;
    passwordValidDays: number;
    passwordExpiryDateInMillis: string;
    userLoginPolicyType: UserLoginPolicyType;
    enabled: boolean;
    expired: boolean;
    locked: boolean;
    passwordExpired: boolean;
    isTwoFactorAuth: boolean;
}

export interface UpdateSystemUser {
    clientRequestId: string;
    id: string;
    username: string;
    email: string;
    userLoginPolicyType: UserLoginPolicyType;
    enabled: boolean;
    expired: boolean;
    locked: boolean;
    passwordExpired: boolean;
}

export interface UpdateStandardUser {
    clientRequestId: string;
    id: string;
    username: string;
    forename: string;
    surname: string;
    region: string;
    email: string;
    telephone: string;
    passwordValidDays: number;
    passwordExpiryDateInMillis: string;
    userLoginPolicyType: UserLoginPolicyType;
    enabled: boolean;
    expired: boolean;
    locked: boolean;
    passwordExpired: boolean;
    isTwoFactorAuth: boolean;
}

export interface DeleteAuthenticationUser {
    clientRequestId: string;
    id: string;
}

export interface FindSystemUser {
    clientRequestId: string;
    username: string;
}

export interface FindStandardUser {
    clientRequestId: string;
    username: string;
}

export interface FindAllSystemUsers {
    clientRequestId: string;
}

export interface FindAllStandardUsers {
    clientRequestId: string;
}

export interface ChangeUserPasswordRequest {
    clientRequestId: string;
    username: string;
    currentPassword: string;
    newPassword: string;
    resetPasswordToken: string;
}

export interface GenerateResetUserPasswordTokenRequest {
    clientRequestId: string;
    username: string;
    sendUserNotification: boolean;
}

/** Updates the API user whenever an authentication user is created */
export interface UpdateOnBaseServerAuthenticationUserRequest {}

export interface VerifyUserPasswordRequest {
    clientRequestId: string;
    password: string;
}

export interface RequestQRCodeForUser {
    clientRequestId: string;
    username: string;
}

function createBaseCreateSystemUser(): CreateSystemUser {
    return {
        clientRequestId: '',
        username: '',
        userLoginPolicyType: 0,
        email: '',
        enabled: false,
        expired: false,
        locked: false,
        passwordExpired: false
    };
}

export const CreateSystemUser = {
    encode(message: CreateSystemUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        if (message.userLoginPolicyType !== 0) {
            writer.uint32(32).int32(message.userLoginPolicyType);
        }
        if (message.email !== '') {
            writer.uint32(42).string(message.email);
        }
        if (message.enabled === true) {
            writer.uint32(48).bool(message.enabled);
        }
        if (message.expired === true) {
            writer.uint32(56).bool(message.expired);
        }
        if (message.locked === true) {
            writer.uint32(64).bool(message.locked);
        }
        if (message.passwordExpired === true) {
            writer.uint32(72).bool(message.passwordExpired);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateSystemUser {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateSystemUser();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 4:
                    message.userLoginPolicyType = reader.int32() as any;
                    break;
                case 5:
                    message.email = reader.string();
                    break;
                case 6:
                    message.enabled = reader.bool();
                    break;
                case 7:
                    message.expired = reader.bool();
                    break;
                case 8:
                    message.locked = reader.bool();
                    break;
                case 9:
                    message.passwordExpired = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateSystemUser>): CreateSystemUser {
        const message = createBaseCreateSystemUser();
        message.clientRequestId = object.clientRequestId ?? '';
        message.username = object.username ?? '';
        message.userLoginPolicyType = object.userLoginPolicyType ?? 0;
        message.email = object.email ?? '';
        message.enabled = object.enabled ?? false;
        message.expired = object.expired ?? false;
        message.locked = object.locked ?? false;
        message.passwordExpired = object.passwordExpired ?? false;
        return message;
    }
};

function createBaseCreateStandardUser(): CreateStandardUser {
    return {
        clientRequestId: '',
        username: '',
        forename: '',
        surname: '',
        region: '',
        email: '',
        telephone: '',
        passwordValidDays: 0,
        passwordExpiryDateInMillis: '0',
        userLoginPolicyType: 0,
        enabled: false,
        expired: false,
        locked: false,
        passwordExpired: false,
        isTwoFactorAuth: false
    };
}

export const CreateStandardUser = {
    encode(message: CreateStandardUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        if (message.forename !== '') {
            writer.uint32(26).string(message.forename);
        }
        if (message.surname !== '') {
            writer.uint32(34).string(message.surname);
        }
        if (message.region !== '') {
            writer.uint32(42).string(message.region);
        }
        if (message.email !== '') {
            writer.uint32(50).string(message.email);
        }
        if (message.telephone !== '') {
            writer.uint32(58).string(message.telephone);
        }
        if (message.passwordValidDays !== 0) {
            writer.uint32(64).int32(message.passwordValidDays);
        }
        if (message.passwordExpiryDateInMillis !== '0') {
            writer.uint32(72).int64(message.passwordExpiryDateInMillis);
        }
        if (message.userLoginPolicyType !== 0) {
            writer.uint32(80).int32(message.userLoginPolicyType);
        }
        if (message.enabled === true) {
            writer.uint32(88).bool(message.enabled);
        }
        if (message.expired === true) {
            writer.uint32(96).bool(message.expired);
        }
        if (message.locked === true) {
            writer.uint32(104).bool(message.locked);
        }
        if (message.passwordExpired === true) {
            writer.uint32(112).bool(message.passwordExpired);
        }
        if (message.isTwoFactorAuth === true) {
            writer.uint32(120).bool(message.isTwoFactorAuth);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateStandardUser {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateStandardUser();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 3:
                    message.forename = reader.string();
                    break;
                case 4:
                    message.surname = reader.string();
                    break;
                case 5:
                    message.region = reader.string();
                    break;
                case 6:
                    message.email = reader.string();
                    break;
                case 7:
                    message.telephone = reader.string();
                    break;
                case 8:
                    message.passwordValidDays = reader.int32();
                    break;
                case 9:
                    message.passwordExpiryDateInMillis = longToString(reader.int64() as Long);
                    break;
                case 10:
                    message.userLoginPolicyType = reader.int32() as any;
                    break;
                case 11:
                    message.enabled = reader.bool();
                    break;
                case 12:
                    message.expired = reader.bool();
                    break;
                case 13:
                    message.locked = reader.bool();
                    break;
                case 14:
                    message.passwordExpired = reader.bool();
                    break;
                case 15:
                    message.isTwoFactorAuth = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateStandardUser>): CreateStandardUser {
        const message = createBaseCreateStandardUser();
        message.clientRequestId = object.clientRequestId ?? '';
        message.username = object.username ?? '';
        message.forename = object.forename ?? '';
        message.surname = object.surname ?? '';
        message.region = object.region ?? '';
        message.email = object.email ?? '';
        message.telephone = object.telephone ?? '';
        message.passwordValidDays = object.passwordValidDays ?? 0;
        message.passwordExpiryDateInMillis = object.passwordExpiryDateInMillis ?? '0';
        message.userLoginPolicyType = object.userLoginPolicyType ?? 0;
        message.enabled = object.enabled ?? false;
        message.expired = object.expired ?? false;
        message.locked = object.locked ?? false;
        message.passwordExpired = object.passwordExpired ?? false;
        message.isTwoFactorAuth = object.isTwoFactorAuth ?? false;
        return message;
    }
};

function createBaseUpdateSystemUser(): UpdateSystemUser {
    return {
        clientRequestId: '',
        id: '0',
        username: '',
        email: '',
        userLoginPolicyType: 0,
        enabled: false,
        expired: false,
        locked: false,
        passwordExpired: false
    };
}

export const UpdateSystemUser = {
    encode(message: UpdateSystemUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.id !== '0') {
            writer.uint32(16).int64(message.id);
        }
        if (message.username !== '') {
            writer.uint32(26).string(message.username);
        }
        if (message.email !== '') {
            writer.uint32(34).string(message.email);
        }
        if (message.userLoginPolicyType !== 0) {
            writer.uint32(40).int32(message.userLoginPolicyType);
        }
        if (message.enabled === true) {
            writer.uint32(48).bool(message.enabled);
        }
        if (message.expired === true) {
            writer.uint32(56).bool(message.expired);
        }
        if (message.locked === true) {
            writer.uint32(64).bool(message.locked);
        }
        if (message.passwordExpired === true) {
            writer.uint32(72).bool(message.passwordExpired);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateSystemUser {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateSystemUser();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.username = reader.string();
                    break;
                case 4:
                    message.email = reader.string();
                    break;
                case 5:
                    message.userLoginPolicyType = reader.int32() as any;
                    break;
                case 6:
                    message.enabled = reader.bool();
                    break;
                case 7:
                    message.expired = reader.bool();
                    break;
                case 8:
                    message.locked = reader.bool();
                    break;
                case 9:
                    message.passwordExpired = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UpdateSystemUser>): UpdateSystemUser {
        const message = createBaseUpdateSystemUser();
        message.clientRequestId = object.clientRequestId ?? '';
        message.id = object.id ?? '0';
        message.username = object.username ?? '';
        message.email = object.email ?? '';
        message.userLoginPolicyType = object.userLoginPolicyType ?? 0;
        message.enabled = object.enabled ?? false;
        message.expired = object.expired ?? false;
        message.locked = object.locked ?? false;
        message.passwordExpired = object.passwordExpired ?? false;
        return message;
    }
};

function createBaseUpdateStandardUser(): UpdateStandardUser {
    return {
        clientRequestId: '',
        id: '0',
        username: '',
        forename: '',
        surname: '',
        region: '',
        email: '',
        telephone: '',
        passwordValidDays: 0,
        passwordExpiryDateInMillis: '0',
        userLoginPolicyType: 0,
        enabled: false,
        expired: false,
        locked: false,
        passwordExpired: false,
        isTwoFactorAuth: false
    };
}

export const UpdateStandardUser = {
    encode(message: UpdateStandardUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.id !== '0') {
            writer.uint32(16).int64(message.id);
        }
        if (message.username !== '') {
            writer.uint32(26).string(message.username);
        }
        if (message.forename !== '') {
            writer.uint32(34).string(message.forename);
        }
        if (message.surname !== '') {
            writer.uint32(42).string(message.surname);
        }
        if (message.region !== '') {
            writer.uint32(50).string(message.region);
        }
        if (message.email !== '') {
            writer.uint32(58).string(message.email);
        }
        if (message.telephone !== '') {
            writer.uint32(66).string(message.telephone);
        }
        if (message.passwordValidDays !== 0) {
            writer.uint32(72).int32(message.passwordValidDays);
        }
        if (message.passwordExpiryDateInMillis !== '0') {
            writer.uint32(80).int64(message.passwordExpiryDateInMillis);
        }
        if (message.userLoginPolicyType !== 0) {
            writer.uint32(88).int32(message.userLoginPolicyType);
        }
        if (message.enabled === true) {
            writer.uint32(96).bool(message.enabled);
        }
        if (message.expired === true) {
            writer.uint32(104).bool(message.expired);
        }
        if (message.locked === true) {
            writer.uint32(112).bool(message.locked);
        }
        if (message.passwordExpired === true) {
            writer.uint32(120).bool(message.passwordExpired);
        }
        if (message.isTwoFactorAuth === true) {
            writer.uint32(128).bool(message.isTwoFactorAuth);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateStandardUser {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateStandardUser();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.username = reader.string();
                    break;
                case 4:
                    message.forename = reader.string();
                    break;
                case 5:
                    message.surname = reader.string();
                    break;
                case 6:
                    message.region = reader.string();
                    break;
                case 7:
                    message.email = reader.string();
                    break;
                case 8:
                    message.telephone = reader.string();
                    break;
                case 9:
                    message.passwordValidDays = reader.int32();
                    break;
                case 10:
                    message.passwordExpiryDateInMillis = longToString(reader.int64() as Long);
                    break;
                case 11:
                    message.userLoginPolicyType = reader.int32() as any;
                    break;
                case 12:
                    message.enabled = reader.bool();
                    break;
                case 13:
                    message.expired = reader.bool();
                    break;
                case 14:
                    message.locked = reader.bool();
                    break;
                case 15:
                    message.passwordExpired = reader.bool();
                    break;
                case 16:
                    message.isTwoFactorAuth = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UpdateStandardUser>): UpdateStandardUser {
        const message = createBaseUpdateStandardUser();
        message.clientRequestId = object.clientRequestId ?? '';
        message.id = object.id ?? '0';
        message.username = object.username ?? '';
        message.forename = object.forename ?? '';
        message.surname = object.surname ?? '';
        message.region = object.region ?? '';
        message.email = object.email ?? '';
        message.telephone = object.telephone ?? '';
        message.passwordValidDays = object.passwordValidDays ?? 0;
        message.passwordExpiryDateInMillis = object.passwordExpiryDateInMillis ?? '0';
        message.userLoginPolicyType = object.userLoginPolicyType ?? 0;
        message.enabled = object.enabled ?? false;
        message.expired = object.expired ?? false;
        message.locked = object.locked ?? false;
        message.passwordExpired = object.passwordExpired ?? false;
        message.isTwoFactorAuth = object.isTwoFactorAuth ?? false;
        return message;
    }
};

function createBaseDeleteAuthenticationUser(): DeleteAuthenticationUser {
    return { clientRequestId: '', id: '0' };
}

export const DeleteAuthenticationUser = {
    encode(message: DeleteAuthenticationUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.id !== '0') {
            writer.uint32(16).int64(message.id);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): DeleteAuthenticationUser {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeleteAuthenticationUser();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.id = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<DeleteAuthenticationUser>): DeleteAuthenticationUser {
        const message = createBaseDeleteAuthenticationUser();
        message.clientRequestId = object.clientRequestId ?? '';
        message.id = object.id ?? '0';
        return message;
    }
};

function createBaseFindSystemUser(): FindSystemUser {
    return { clientRequestId: '', username: '' };
}

export const FindSystemUser = {
    encode(message: FindSystemUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindSystemUser {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindSystemUser();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindSystemUser>): FindSystemUser {
        const message = createBaseFindSystemUser();
        message.clientRequestId = object.clientRequestId ?? '';
        message.username = object.username ?? '';
        return message;
    }
};

function createBaseFindStandardUser(): FindStandardUser {
    return { clientRequestId: '', username: '' };
}

export const FindStandardUser = {
    encode(message: FindStandardUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindStandardUser {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindStandardUser();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindStandardUser>): FindStandardUser {
        const message = createBaseFindStandardUser();
        message.clientRequestId = object.clientRequestId ?? '';
        message.username = object.username ?? '';
        return message;
    }
};

function createBaseFindAllSystemUsers(): FindAllSystemUsers {
    return { clientRequestId: '' };
}

export const FindAllSystemUsers = {
    encode(message: FindAllSystemUsers, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAllSystemUsers {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAllSystemUsers();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAllSystemUsers>): FindAllSystemUsers {
        const message = createBaseFindAllSystemUsers();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseFindAllStandardUsers(): FindAllStandardUsers {
    return { clientRequestId: '' };
}

export const FindAllStandardUsers = {
    encode(message: FindAllStandardUsers, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAllStandardUsers {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAllStandardUsers();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAllStandardUsers>): FindAllStandardUsers {
        const message = createBaseFindAllStandardUsers();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseChangeUserPasswordRequest(): ChangeUserPasswordRequest {
    return { clientRequestId: '', username: '', currentPassword: '', newPassword: '', resetPasswordToken: '' };
}

export const ChangeUserPasswordRequest = {
    encode(message: ChangeUserPasswordRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        if (message.currentPassword !== '') {
            writer.uint32(26).string(message.currentPassword);
        }
        if (message.newPassword !== '') {
            writer.uint32(34).string(message.newPassword);
        }
        if (message.resetPasswordToken !== '') {
            writer.uint32(42).string(message.resetPasswordToken);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): ChangeUserPasswordRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseChangeUserPasswordRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 3:
                    message.currentPassword = reader.string();
                    break;
                case 4:
                    message.newPassword = reader.string();
                    break;
                case 5:
                    message.resetPasswordToken = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<ChangeUserPasswordRequest>): ChangeUserPasswordRequest {
        const message = createBaseChangeUserPasswordRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.username = object.username ?? '';
        message.currentPassword = object.currentPassword ?? '';
        message.newPassword = object.newPassword ?? '';
        message.resetPasswordToken = object.resetPasswordToken ?? '';
        return message;
    }
};

function createBaseGenerateResetUserPasswordTokenRequest(): GenerateResetUserPasswordTokenRequest {
    return { clientRequestId: '', username: '', sendUserNotification: false };
}

export const GenerateResetUserPasswordTokenRequest = {
    encode(message: GenerateResetUserPasswordTokenRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        if (message.sendUserNotification === true) {
            writer.uint32(24).bool(message.sendUserNotification);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): GenerateResetUserPasswordTokenRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGenerateResetUserPasswordTokenRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 3:
                    message.sendUserNotification = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<GenerateResetUserPasswordTokenRequest>): GenerateResetUserPasswordTokenRequest {
        const message = createBaseGenerateResetUserPasswordTokenRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.username = object.username ?? '';
        message.sendUserNotification = object.sendUserNotification ?? false;
        return message;
    }
};

function createBaseUpdateOnBaseServerAuthenticationUserRequest(): UpdateOnBaseServerAuthenticationUserRequest {
    return {};
}

export const UpdateOnBaseServerAuthenticationUserRequest = {
    encode(_: UpdateOnBaseServerAuthenticationUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOnBaseServerAuthenticationUserRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateOnBaseServerAuthenticationUserRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        _: DeepPartial<UpdateOnBaseServerAuthenticationUserRequest>
    ): UpdateOnBaseServerAuthenticationUserRequest {
        const message = createBaseUpdateOnBaseServerAuthenticationUserRequest();
        return message;
    }
};

function createBaseVerifyUserPasswordRequest(): VerifyUserPasswordRequest {
    return { clientRequestId: '', password: '' };
}

export const VerifyUserPasswordRequest = {
    encode(message: VerifyUserPasswordRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.password !== '') {
            writer.uint32(18).string(message.password);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): VerifyUserPasswordRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseVerifyUserPasswordRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.password = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<VerifyUserPasswordRequest>): VerifyUserPasswordRequest {
        const message = createBaseVerifyUserPasswordRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.password = object.password ?? '';
        return message;
    }
};

function createBaseRequestQRCodeForUser(): RequestQRCodeForUser {
    return { clientRequestId: '', username: '' };
}

export const RequestQRCodeForUser = {
    encode(message: RequestQRCodeForUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): RequestQRCodeForUser {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRequestQRCodeForUser();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<RequestQRCodeForUser>): RequestQRCodeForUser {
        const message = createBaseRequestQRCodeForUser();
        message.clientRequestId = object.clientRequestId ?? '';
        message.username = object.username ?? '';
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
