/* eslint-disable */
import { AccountType } from '../enums/AccountTypeProto';
import { Side } from '../enums/SideProto';
import { OrderType } from '../enums/OrderTypeProto';
import { TimeInForceType } from '../enums/TimeInForceTypeProto';
import { StrategyType } from '../enums/StrategyTypeProto';
import { HandlingInstruction } from '../enums/HandlingInstructionProto';
import { ProductType } from '../enums/ProductTypeProto';
import { OptionType } from '../enums/OptionTypeProto';
import { AssetType } from '../enums/AssetTypeProto';
import { PegPriceType } from '../enums/PegPriceTypeProto';
import { ExecutionInstruction } from '../enums/ExecutionInstructionProto';
import Long from 'long';
import { KeyValue } from './OrderMetadata';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.marketmerchant.api.order';

export interface CreateFutureOrderRequest {
    clientOrderId: string;
    exchangeIdSource: string;
    /** optional string exchange = 4; */
    securityIdSource: string;
    securityCode: string;
    securityId: string;
    expiry: string;
    accountType: AccountType;
    account: string;
    side: Side;
    qty: number;
    price: number;
    orderType: OrderType;
    timeInForce: TimeInForceType;
    timeInForceExpiry: string;
    orderText: string;
    giveIn: string;
    giveUp: string;
    strategyType: StrategyType;
    leg: CreateFutureStrategyLegOrder[];
    handlingInstruction: HandlingInstruction;
    onBehalfOfCompId: string;
    deliverToCompId: string;
    currency: string;
    /**
     * optional double minQty = 26;
     * optional double maxFloor = 27;
     */
    quoteId: string;
    /** streamCode is mandatory */
    streamCode: string;
    productType: ProductType;
    /** Execution destination as defined by institution when order is entered */
    exDestination: string;
    orderMetadata: KeyValue[];
    trader: string;
    /** List of LPs to execute the trade on */
    includedLPs: string[];
    /** List of LPs to not execute the trade on */
    excludedLPs: string[];
    /** FXAll block trade allocations */
    orderAllocGroup: CreateOrderAllocGroup[];
}

export interface CreateFutureStrategyLegOrder {
    underlyingCode: string;
    underlyingSecurityId: string;
    underlyingExpiry: string;
    side: Side;
    ratioQty: number;
    /** Add settlementType(i.e. tenor) for Future asset type. e.g. 3M forward, 3rd Wed date base metals. */
    settlementType: string;
}

export interface CreateOptionOrderRequest {
    clientRequestId: string;
    clientOrderId: string;
    exchangeIdSource: string;
    /** optional string exchange = 4; */
    securityIdSource: string;
    securityCode: string;
    securityId: string;
    expiry: string;
    optionType: OptionType;
    strike: number;
    account: string;
    side: Side;
    qty: number;
    price: number;
    orderType: OrderType;
    timeInForce: TimeInForceType;
    timeInForceExpiry: string;
    orderText: string;
    accountType: AccountType;
    giveIn: string;
    giveUp: string;
    handlingInstruction: HandlingInstruction;
    onBehalfOfCompId: string;
    deliverToCompId: string;
    currency: string;
    /**
     * optional double minQty = 26;
     * optional double maxFloor = 27;
     */
    quoteId: string;
    /** streamCode is mandatory */
    streamCode: string;
}

export interface CreateFxSspOrder {
    clientRequestId: string;
    listID: string;
    netSpotOrder?: CreateFxOrderRequest;
    orders: CreateFxOrderRequest[];
}

export interface CreateFxOrderRequest {
    clientRequestId: string;
    /** Unique identifier for orders. Uniqueness must be guaranteed within a single trading day */
    clOrderId: string;
    /**
     * Top level security identifier (Example: E-Mini S&P 500 contract ESH6, ES is the securityCode)
     * For FX the ISO standard security code
     */
    securityCode: string;
    /** Security identifier */
    securityId: string;
    /**
     * Account type associated with the account:
     * CLIENT external client account
     * HOUSE internal account
     */
    accountType: AccountType;
    /** Account associated with the quote request */
    account: string;
    /** Side of order; valid values are BUY, SELL */
    side: Side;
    /** Number of shares ordered */
    qty: number;
    /** Price per share */
    price: number;
    /** Identifies currency used for price */
    currency: string;
    /**
     * Order type
     * Valid value:
     * PREVIOUSLY_QUOTED previously quoted
     */
    orderType: OrderType;
    /** Free format text */
    orderText: string;
    /** Unique identifier for quote */
    quoteId: string;
    /**
     * Specifies how long the order remains in effect
     * Valid value:
     * FOK fill or kill
     */
    timeInForce: TimeInForceType;
    /**
     * Time in force expiry date
     * Format: yyyy-MM-dd
     * not in use
     */
    timeInForceExpiry: string;
    /**
     * Handling instructions for order request;
     * AUTOMATED_NO_BROKER quotes will not be manually priced
     * AUTOMATED_BROKER_OK quotes can be manually priced
     * MANUAL not in use
     */
    handlingInstruction: HandlingInstruction;
    /** Assigned value used to identify firm originating message if the message was delivered by a third party */
    onBehalfOfCompId: string;
    /** Assigned value used to identify the firm targeted to receive the message if the message is delivered by a third party */
    deliverToCompId: string;
    /**
     * Specific date if trade settlement (SettlementDate)
     * Format: yyyy-MM-dd
     */
    settlementDate: string;
    /** Product type associated with the order; valid values are SPOT, FORWARD, SWAP */
    productType: ProductType;
    /**
     * For productType of SPOT or FORWARD, the size of leg is 1
     * For productType of SWAP, the size of leg is 2
     */
    leg: CreateFxStrategyLegOrder[];
    /**
     * For productType of SPOT or FORWARD, settlementType is tenorCode, ie 1W
     * For productType of SWAP, the format of settlementType is near leg tenor - far leg tenor. ie 1W-2W
     */
    settlementType: string;
    /** Execution destination as defined by institution when order is entered */
    exDestination: string;
    orderMetadata: KeyValue[];
    trader: string;
    rateSource: string;
    /** for quantity vs. quantity trade e.g. Peer FX SPOT */
    contraQty: number;
    /** for some client that trade on certain asset types but want to follow FX workflow */
    assetType: AssetType;
    /** streamCode is mandatory */
    streamCode: string;
    /** list of LPs to execute the trade on */
    includedLPs: string[];
    /** list of LPs to not execute the trade on */
    excludedLPs: string[];
    /** FXAll block trade allocations */
    orderAllocGroup: CreateOrderAllocGroup[];
    minQty: number;
    maxShow: number;
    /** Type of pegged order */
    pegPriceType: PegPriceType;
}

export interface CreateBitcoinOrderRequest {
    clientRequestId: string;
    /** Unique identifier for orders. Uniqueness must be guaranteed within a single trading day */
    clOrderId: string;
    /**
     * optional string exchangeCode = 3;
     * Top level security identifier (Example: E-Mini S&P 500 contract ESH6, ES is the securityCode)
     * For FX the ISO standard security code
     */
    securityCode: string;
    /** Security identifier */
    securityId: string;
    /**
     * Account type associated with the account:
     * CLIENT external client account
     * HOUSE internal account
     */
    accountType: AccountType;
    /** Account associated with the quote request */
    account: string;
    /** Side of order; valid values are BUY, SELL */
    side: Side;
    /** Number of shares ordered */
    qty: number;
    /** Price per share */
    price: number;
    /** Identifies currency used for price */
    currency: string;
    /**
     * Order type
     * Valid value:
     * PREVIOUSLY_QUOTED previously quoted
     */
    orderType: OrderType;
    /** Free format text */
    orderText: string;
    /**
     * optional double minQty = 14;
     * optional double maxFloor = 15;
     * Unique identifier for quote
     */
    quoteId: string;
    /**
     * Specifies how long the order remains in effect
     * Valid value:
     * FOK fill or kill
     */
    timeInForce: TimeInForceType;
    /**
     * Time in force expiry date
     * Format: yyyy-MM-dd
     * not in use
     */
    timeInForceExpiry: string;
    /**
     * Handling instructions for order request;
     * AUTOMATED_NO_BROKER quotes will not be manually priced
     * AUTOMATED_BROKER_OK quotes can be manually priced
     * MANUAL not in use
     */
    handlingInstruction: HandlingInstruction;
    /** Assigned value used to identify firm originating message if the message was delivered by a third party */
    onBehalfOfCompId: string;
    /** Assigned value used to identify the firm targeted to receive the message if the message is delivered by a third party */
    deliverToCompId: string;
    /** Product type associated with the order; valid values are SPOT, FORWARD, SWAP */
    productType: ProductType;
    /** Execution destination as defined by institution when order is entered */
    exDestination: string;
    orderMetadata: KeyValue[];
    trader: string;
    /** represents a on-chain transaction by requestor; specific to bitcoin */
    requestorTransaction: Uint8Array;
    /** specific to bitcoin private share; only required if side = BUY */
    requestorCoinTransactionInput: Uint8Array;
    /** streamCode is mandatory */
    streamCode: string;
}

export interface CreateFxStrategyLegOrder {
    /**
     * Top level security identifier (Example: E-Mini S&P 500 contract ESH6, ES is the securityCode)
     * For FX the ISO standard security code
     */
    underlyingCode: string;
    /** Security identifier */
    underlyingSecurityId: string;
    /** Leg SettlementDate */
    legValueDate: string;
    settlementType: string;
    /** Side of order; valid values are BUY, SELL */
    side: Side;
    /** Order qty */
    qty: number;
    /** Order price */
    price: number;
    /** Leg Forward Point. May be a negative value */
    legForwardPts: number;
    /** Leg spot rate */
    legSpotPrice: number;
    /**
     * Maturity of the quote; used for quote requests related to FIXED_INCOME
     * Fixing date for NON_DELIVERABLE_FORWARD
     */
    maturityDate: string;
    /** for quantity vs. quantity trade e.g. Peer FX SPOT */
    contraQty: number;
}

export interface CreateFixedIncomeOrderRequest {
    clientRequestId: string;
    /** Unique identifier for order. Uniqueness must be guaranteed within a single trading day */
    clOrderId: string;
    /**
     * Top level security identifier (Example: E-Mini S&P 500 contract ESH6, ES is the securityCode)
     * For FX the ISO standard security code
     */
    securityCode: string;
    /** Security identifier */
    securityId: string;
    /**
     * Account type associated with the account:
     * CLIENT external client account
     * HOUSE internal account
     */
    accountType: AccountType;
    /** Account associated with the quote request */
    account: string;
    /** Side of order; valid values are BUY, SELL */
    side: Side;
    /** Order qty */
    qty: number;
    /** Order price */
    price: number;
    /** Identifies currency used for price */
    currency: string;
    /**
     * Order type
     * Valid value:
     * PREVIOUSLY_QUOTED previously quoted
     */
    orderType: OrderType;
    /** Free format text */
    orderText: string;
    /** Unique identifier for quote */
    quoteId: string;
    /**
     * Specifies how long the order remains in effect
     * Valid value:
     * FOK fill or kill
     */
    timeInForce: TimeInForceType;
    /**
     * Time in force expiry date
     * Format: yyyy-MM-dd
     * not in use
     */
    timeInForceExpiry: string;
    /**
     * Handling instructions for quote request;
     * AUTOMATED_NO_BROKER quotes will not be manually priced
     * AUTOMATED_BROKER_OK quotes can be manually priced
     * MANUAL not in use
     */
    handlingInstruction: HandlingInstruction;
    /** Assigned value used to identify firm originating message if the message was delivered by a third party */
    onBehalfOfCompId: string;
    /** Assigned value used to identify the firm targeted to receive the message if the message is delivered by a third party */
    deliverToCompId: string;
    /**
     * Specific date if trade settlement (SettlementDate)
     * Format: yyyy-MM-dd
     */
    settlementDate: string;
    /**
     * Date of maturity
     * Format: yyyy-MM-dd
     * should be the same as settlementDate
     */
    maturityDate: string;
    /**
     * ProductType
     * MONEY_MARKET money market
     */
    productType: ProductType;
    orderMetadata: KeyValue[];
    trader: string;
    /** FIX tag 63 */
    settlementType: string;
    /** streamCode is mandatory */
    streamCode: string;
}

export interface CreateEquityOrderRequest {
    clientOrderId: string;
    exchangeIdSource: string;
    /** optional string exchange = 4; */
    securityIdSource: string;
    securityCode: string;
    securityId: string;
    accountType: AccountType;
    account: string;
    side: Side;
    qty: number;
    price: number;
    currency: string;
    orderType: OrderType;
    timeInForce: TimeInForceType;
    timeInForceExpiry: string;
    orderText: string;
    giveIn: string;
    giveUp: string;
    handlingInstruction: HandlingInstruction;
    onBehalfOfCompId: string;
    deliverToCompId: string;
    /**
     * optional double minQty = 24;
     * optional double maxFloor = 26;
     */
    executionInstruction: ExecutionInstruction;
    quoteId: string;
    /** streamCode is mandatory */
    streamCode: string;
}

export interface CancelOrderRequest {
    orderId: string;
    clOrdId: string;
    origClOrdId: string;
    onBehalfOfCompId: string;
    deliverToCompId: string;
}

export interface AmendOrderRequest {
    orderId: string;
    clOrdId: string;
    origClOrdId: string;
    account: string;
    qty: number;
    price: number;
    orderText: string;
    giveIn: string;
    giveUp: string;
    onBehalfOfCompId: string;
    deliverToCompId: string;
}

export interface FindAllAssignedOrderSnapshots {
    clientRequestId: string;
}

export interface FindAllOrderSnapshotsBySessionKey {
    clientRequestId: string;
}

export interface FindAllOrderSnapshotsByClientOrderId {
    clientRequestId: string;
    /** Unique identifier for Order as assigned by institution. Uniqueness must be guaranteed within a single trading day */
    clOrderId: string;
}

export interface FindAllOrderSnapshotsByOrderId {
    clientRequestId: string;
    /** Unique identifier for Order as assigned by sell-side (broker, exchange, ECN). Uniqueness must be guaranteed within a single trading day */
    orderId: string;
}

export interface MarketMerchantDestinationCommand {
    clientRequestId: string;
    marketMerchantDestinationKey: string;
    operation: MarketMerchantDestinationCommand_Operation;
}

export enum MarketMerchantDestinationCommand_Operation {
    START = 0,
    STOP = 1,
    EOD = 2,
    UNRECOGNIZED = -1
}

export interface CreateOrderAllocGroup {
    allocAccount: string;
    allocQty: number;
    allocSide: Side;
    allocSettlementDate: string;
    allocPrice: number;
    linkingId: number;
}

function createBaseCreateFutureOrderRequest(): CreateFutureOrderRequest {
    return {
        clientOrderId: '',
        exchangeIdSource: '',
        securityIdSource: '',
        securityCode: '',
        securityId: '',
        expiry: '',
        accountType: 1,
        account: '',
        side: 1,
        qty: 0,
        price: 0,
        orderType: 1,
        timeInForce: 1,
        timeInForceExpiry: '',
        orderText: '',
        giveIn: '',
        giveUp: '',
        strategyType: 1,
        leg: [],
        handlingInstruction: 1,
        onBehalfOfCompId: '',
        deliverToCompId: '',
        currency: '',
        quoteId: '',
        streamCode: '',
        productType: 1,
        exDestination: '',
        orderMetadata: [],
        trader: '',
        includedLPs: [],
        excludedLPs: [],
        orderAllocGroup: []
    };
}

export const CreateFutureOrderRequest = {
    encode(message: CreateFutureOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientOrderId !== '') {
            writer.uint32(18).string(message.clientOrderId);
        }
        if (message.exchangeIdSource !== '') {
            writer.uint32(26).string(message.exchangeIdSource);
        }
        if (message.securityIdSource !== '') {
            writer.uint32(42).string(message.securityIdSource);
        }
        if (message.securityCode !== '') {
            writer.uint32(50).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(58).string(message.securityId);
        }
        if (message.expiry !== '') {
            writer.uint32(66).string(message.expiry);
        }
        if (message.accountType !== 1) {
            writer.uint32(72).int32(message.accountType);
        }
        if (message.account !== '') {
            writer.uint32(82).string(message.account);
        }
        if (message.side !== 1) {
            writer.uint32(88).int32(message.side);
        }
        if (message.qty !== 0) {
            writer.uint32(97).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(105).double(message.price);
        }
        if (message.orderType !== 1) {
            writer.uint32(112).int32(message.orderType);
        }
        if (message.timeInForce !== 1) {
            writer.uint32(120).int32(message.timeInForce);
        }
        if (message.timeInForceExpiry !== '') {
            writer.uint32(130).string(message.timeInForceExpiry);
        }
        if (message.orderText !== '') {
            writer.uint32(138).string(message.orderText);
        }
        if (message.giveIn !== '') {
            writer.uint32(146).string(message.giveIn);
        }
        if (message.giveUp !== '') {
            writer.uint32(154).string(message.giveUp);
        }
        if (message.strategyType !== 1) {
            writer.uint32(160).int32(message.strategyType);
        }
        for (const v of message.leg) {
            CreateFutureStrategyLegOrder.encode(v!, writer.uint32(170).fork()).ldelim();
        }
        if (message.handlingInstruction !== 1) {
            writer.uint32(176).int32(message.handlingInstruction);
        }
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(186).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(194).string(message.deliverToCompId);
        }
        if (message.currency !== '') {
            writer.uint32(202).string(message.currency);
        }
        if (message.quoteId !== '') {
            writer.uint32(226).string(message.quoteId);
        }
        if (message.streamCode !== '') {
            writer.uint32(234).string(message.streamCode);
        }
        if (message.productType !== 1) {
            writer.uint32(240).int32(message.productType);
        }
        if (message.exDestination !== '') {
            writer.uint32(250).string(message.exDestination);
        }
        for (const v of message.orderMetadata) {
            KeyValue.encode(v!, writer.uint32(258).fork()).ldelim();
        }
        if (message.trader !== '') {
            writer.uint32(266).string(message.trader);
        }
        for (const v of message.includedLPs) {
            writer.uint32(274).string(v!);
        }
        for (const v of message.excludedLPs) {
            writer.uint32(282).string(v!);
        }
        for (const v of message.orderAllocGroup) {
            CreateOrderAllocGroup.encode(v!, writer.uint32(290).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateFutureOrderRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateFutureOrderRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.clientOrderId = reader.string();
                    break;
                case 3:
                    message.exchangeIdSource = reader.string();
                    break;
                case 5:
                    message.securityIdSource = reader.string();
                    break;
                case 6:
                    message.securityCode = reader.string();
                    break;
                case 7:
                    message.securityId = reader.string();
                    break;
                case 8:
                    message.expiry = reader.string();
                    break;
                case 9:
                    message.accountType = reader.int32() as any;
                    break;
                case 10:
                    message.account = reader.string();
                    break;
                case 11:
                    message.side = reader.int32() as any;
                    break;
                case 12:
                    message.qty = reader.double();
                    break;
                case 13:
                    message.price = reader.double();
                    break;
                case 14:
                    message.orderType = reader.int32() as any;
                    break;
                case 15:
                    message.timeInForce = reader.int32() as any;
                    break;
                case 16:
                    message.timeInForceExpiry = reader.string();
                    break;
                case 17:
                    message.orderText = reader.string();
                    break;
                case 18:
                    message.giveIn = reader.string();
                    break;
                case 19:
                    message.giveUp = reader.string();
                    break;
                case 20:
                    message.strategyType = reader.int32() as any;
                    break;
                case 21:
                    message.leg.push(CreateFutureStrategyLegOrder.decode(reader, reader.uint32()));
                    break;
                case 22:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 23:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 24:
                    message.deliverToCompId = reader.string();
                    break;
                case 25:
                    message.currency = reader.string();
                    break;
                case 28:
                    message.quoteId = reader.string();
                    break;
                case 29:
                    message.streamCode = reader.string();
                    break;
                case 30:
                    message.productType = reader.int32() as any;
                    break;
                case 31:
                    message.exDestination = reader.string();
                    break;
                case 32:
                    message.orderMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 33:
                    message.trader = reader.string();
                    break;
                case 34:
                    message.includedLPs.push(reader.string());
                    break;
                case 35:
                    message.excludedLPs.push(reader.string());
                    break;
                case 36:
                    message.orderAllocGroup.push(CreateOrderAllocGroup.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateFutureOrderRequest>): CreateFutureOrderRequest {
        const message = createBaseCreateFutureOrderRequest();
        message.clientOrderId = object.clientOrderId ?? '';
        message.exchangeIdSource = object.exchangeIdSource ?? '';
        message.securityIdSource = object.securityIdSource ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.expiry = object.expiry ?? '';
        message.accountType = object.accountType ?? 1;
        message.account = object.account ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.orderType = object.orderType ?? 1;
        message.timeInForce = object.timeInForce ?? 1;
        message.timeInForceExpiry = object.timeInForceExpiry ?? '';
        message.orderText = object.orderText ?? '';
        message.giveIn = object.giveIn ?? '';
        message.giveUp = object.giveUp ?? '';
        message.strategyType = object.strategyType ?? 1;
        message.leg = object.leg?.map((e) => CreateFutureStrategyLegOrder.fromPartial(e)) || [];
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.currency = object.currency ?? '';
        message.quoteId = object.quoteId ?? '';
        message.streamCode = object.streamCode ?? '';
        message.productType = object.productType ?? 1;
        message.exDestination = object.exDestination ?? '';
        message.orderMetadata = object.orderMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.trader = object.trader ?? '';
        message.includedLPs = object.includedLPs?.map((e) => e) || [];
        message.excludedLPs = object.excludedLPs?.map((e) => e) || [];
        message.orderAllocGroup = object.orderAllocGroup?.map((e) => CreateOrderAllocGroup.fromPartial(e)) || [];
        return message;
    }
};

function createBaseCreateFutureStrategyLegOrder(): CreateFutureStrategyLegOrder {
    return {
        underlyingCode: '',
        underlyingSecurityId: '',
        underlyingExpiry: '',
        side: 1,
        ratioQty: 0,
        settlementType: ''
    };
}

export const CreateFutureStrategyLegOrder = {
    encode(message: CreateFutureStrategyLegOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.underlyingCode !== '') {
            writer.uint32(10).string(message.underlyingCode);
        }
        if (message.underlyingSecurityId !== '') {
            writer.uint32(18).string(message.underlyingSecurityId);
        }
        if (message.underlyingExpiry !== '') {
            writer.uint32(26).string(message.underlyingExpiry);
        }
        if (message.side !== 1) {
            writer.uint32(32).int32(message.side);
        }
        if (message.ratioQty !== 0) {
            writer.uint32(41).double(message.ratioQty);
        }
        if (message.settlementType !== '') {
            writer.uint32(50).string(message.settlementType);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateFutureStrategyLegOrder {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateFutureStrategyLegOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.underlyingCode = reader.string();
                    break;
                case 2:
                    message.underlyingSecurityId = reader.string();
                    break;
                case 3:
                    message.underlyingExpiry = reader.string();
                    break;
                case 4:
                    message.side = reader.int32() as any;
                    break;
                case 5:
                    message.ratioQty = reader.double();
                    break;
                case 6:
                    message.settlementType = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateFutureStrategyLegOrder>): CreateFutureStrategyLegOrder {
        const message = createBaseCreateFutureStrategyLegOrder();
        message.underlyingCode = object.underlyingCode ?? '';
        message.underlyingSecurityId = object.underlyingSecurityId ?? '';
        message.underlyingExpiry = object.underlyingExpiry ?? '';
        message.side = object.side ?? 1;
        message.ratioQty = object.ratioQty ?? 0;
        message.settlementType = object.settlementType ?? '';
        return message;
    }
};

function createBaseCreateOptionOrderRequest(): CreateOptionOrderRequest {
    return {
        clientRequestId: '',
        clientOrderId: '',
        exchangeIdSource: '',
        securityIdSource: '',
        securityCode: '',
        securityId: '',
        expiry: '',
        optionType: 1,
        strike: 0,
        account: '',
        side: 1,
        qty: 0,
        price: 0,
        orderType: 1,
        timeInForce: 1,
        timeInForceExpiry: '',
        orderText: '',
        accountType: 1,
        giveIn: '',
        giveUp: '',
        handlingInstruction: 1,
        onBehalfOfCompId: '',
        deliverToCompId: '',
        currency: '',
        quoteId: '',
        streamCode: ''
    };
}

export const CreateOptionOrderRequest = {
    encode(message: CreateOptionOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.clientOrderId !== '') {
            writer.uint32(18).string(message.clientOrderId);
        }
        if (message.exchangeIdSource !== '') {
            writer.uint32(26).string(message.exchangeIdSource);
        }
        if (message.securityIdSource !== '') {
            writer.uint32(42).string(message.securityIdSource);
        }
        if (message.securityCode !== '') {
            writer.uint32(50).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(58).string(message.securityId);
        }
        if (message.expiry !== '') {
            writer.uint32(66).string(message.expiry);
        }
        if (message.optionType !== 1) {
            writer.uint32(72).int32(message.optionType);
        }
        if (message.strike !== 0) {
            writer.uint32(81).double(message.strike);
        }
        if (message.account !== '') {
            writer.uint32(90).string(message.account);
        }
        if (message.side !== 1) {
            writer.uint32(96).int32(message.side);
        }
        if (message.qty !== 0) {
            writer.uint32(105).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(113).double(message.price);
        }
        if (message.orderType !== 1) {
            writer.uint32(120).int32(message.orderType);
        }
        if (message.timeInForce !== 1) {
            writer.uint32(128).int32(message.timeInForce);
        }
        if (message.timeInForceExpiry !== '') {
            writer.uint32(138).string(message.timeInForceExpiry);
        }
        if (message.orderText !== '') {
            writer.uint32(146).string(message.orderText);
        }
        if (message.accountType !== 1) {
            writer.uint32(152).int32(message.accountType);
        }
        if (message.giveIn !== '') {
            writer.uint32(162).string(message.giveIn);
        }
        if (message.giveUp !== '') {
            writer.uint32(170).string(message.giveUp);
        }
        if (message.handlingInstruction !== 1) {
            writer.uint32(176).int32(message.handlingInstruction);
        }
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(186).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(194).string(message.deliverToCompId);
        }
        if (message.currency !== '') {
            writer.uint32(202).string(message.currency);
        }
        if (message.quoteId !== '') {
            writer.uint32(226).string(message.quoteId);
        }
        if (message.streamCode !== '') {
            writer.uint32(234).string(message.streamCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateOptionOrderRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateOptionOrderRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.clientOrderId = reader.string();
                    break;
                case 3:
                    message.exchangeIdSource = reader.string();
                    break;
                case 5:
                    message.securityIdSource = reader.string();
                    break;
                case 6:
                    message.securityCode = reader.string();
                    break;
                case 7:
                    message.securityId = reader.string();
                    break;
                case 8:
                    message.expiry = reader.string();
                    break;
                case 9:
                    message.optionType = reader.int32() as any;
                    break;
                case 10:
                    message.strike = reader.double();
                    break;
                case 11:
                    message.account = reader.string();
                    break;
                case 12:
                    message.side = reader.int32() as any;
                    break;
                case 13:
                    message.qty = reader.double();
                    break;
                case 14:
                    message.price = reader.double();
                    break;
                case 15:
                    message.orderType = reader.int32() as any;
                    break;
                case 16:
                    message.timeInForce = reader.int32() as any;
                    break;
                case 17:
                    message.timeInForceExpiry = reader.string();
                    break;
                case 18:
                    message.orderText = reader.string();
                    break;
                case 19:
                    message.accountType = reader.int32() as any;
                    break;
                case 20:
                    message.giveIn = reader.string();
                    break;
                case 21:
                    message.giveUp = reader.string();
                    break;
                case 22:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 23:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 24:
                    message.deliverToCompId = reader.string();
                    break;
                case 25:
                    message.currency = reader.string();
                    break;
                case 28:
                    message.quoteId = reader.string();
                    break;
                case 29:
                    message.streamCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateOptionOrderRequest>): CreateOptionOrderRequest {
        const message = createBaseCreateOptionOrderRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.clientOrderId = object.clientOrderId ?? '';
        message.exchangeIdSource = object.exchangeIdSource ?? '';
        message.securityIdSource = object.securityIdSource ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.expiry = object.expiry ?? '';
        message.optionType = object.optionType ?? 1;
        message.strike = object.strike ?? 0;
        message.account = object.account ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.orderType = object.orderType ?? 1;
        message.timeInForce = object.timeInForce ?? 1;
        message.timeInForceExpiry = object.timeInForceExpiry ?? '';
        message.orderText = object.orderText ?? '';
        message.accountType = object.accountType ?? 1;
        message.giveIn = object.giveIn ?? '';
        message.giveUp = object.giveUp ?? '';
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.currency = object.currency ?? '';
        message.quoteId = object.quoteId ?? '';
        message.streamCode = object.streamCode ?? '';
        return message;
    }
};

function createBaseCreateFxSspOrder(): CreateFxSspOrder {
    return { clientRequestId: '', listID: '', netSpotOrder: undefined, orders: [] };
}

export const CreateFxSspOrder = {
    encode(message: CreateFxSspOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.listID !== '') {
            writer.uint32(18).string(message.listID);
        }
        if (message.netSpotOrder !== undefined) {
            CreateFxOrderRequest.encode(message.netSpotOrder, writer.uint32(26).fork()).ldelim();
        }
        for (const v of message.orders) {
            CreateFxOrderRequest.encode(v!, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateFxSspOrder {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateFxSspOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.listID = reader.string();
                    break;
                case 3:
                    message.netSpotOrder = CreateFxOrderRequest.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.orders.push(CreateFxOrderRequest.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateFxSspOrder>): CreateFxSspOrder {
        const message = createBaseCreateFxSspOrder();
        message.clientRequestId = object.clientRequestId ?? '';
        message.listID = object.listID ?? '';
        message.netSpotOrder =
            object.netSpotOrder !== undefined && object.netSpotOrder !== null
                ? CreateFxOrderRequest.fromPartial(object.netSpotOrder)
                : undefined;
        message.orders = object.orders?.map((e) => CreateFxOrderRequest.fromPartial(e)) || [];
        return message;
    }
};

function createBaseCreateFxOrderRequest(): CreateFxOrderRequest {
    return {
        clientRequestId: '',
        clOrderId: '',
        securityCode: '',
        securityId: '',
        accountType: 1,
        account: '',
        side: 1,
        qty: 0,
        price: 0,
        currency: '',
        orderType: 1,
        orderText: '',
        quoteId: '',
        timeInForce: 1,
        timeInForceExpiry: '',
        handlingInstruction: 1,
        onBehalfOfCompId: '',
        deliverToCompId: '',
        settlementDate: '',
        productType: 1,
        leg: [],
        settlementType: '',
        exDestination: '',
        orderMetadata: [],
        trader: '',
        rateSource: '',
        contraQty: 0,
        assetType: 1,
        streamCode: '',
        includedLPs: [],
        excludedLPs: [],
        orderAllocGroup: [],
        minQty: 0,
        maxShow: 0,
        pegPriceType: 1
    };
}

export const CreateFxOrderRequest = {
    encode(message: CreateFxOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.clOrderId !== '') {
            writer.uint32(18).string(message.clOrderId);
        }
        if (message.securityCode !== '') {
            writer.uint32(34).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(42).string(message.securityId);
        }
        if (message.accountType !== 1) {
            writer.uint32(48).int32(message.accountType);
        }
        if (message.account !== '') {
            writer.uint32(58).string(message.account);
        }
        if (message.side !== 1) {
            writer.uint32(64).int32(message.side);
        }
        if (message.qty !== 0) {
            writer.uint32(73).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(81).double(message.price);
        }
        if (message.currency !== '') {
            writer.uint32(90).string(message.currency);
        }
        if (message.orderType !== 1) {
            writer.uint32(96).int32(message.orderType);
        }
        if (message.orderText !== '') {
            writer.uint32(106).string(message.orderText);
        }
        if (message.quoteId !== '') {
            writer.uint32(130).string(message.quoteId);
        }
        if (message.timeInForce !== 1) {
            writer.uint32(136).int32(message.timeInForce);
        }
        if (message.timeInForceExpiry !== '') {
            writer.uint32(146).string(message.timeInForceExpiry);
        }
        if (message.handlingInstruction !== 1) {
            writer.uint32(152).int32(message.handlingInstruction);
        }
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(162).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(170).string(message.deliverToCompId);
        }
        if (message.settlementDate !== '') {
            writer.uint32(178).string(message.settlementDate);
        }
        if (message.productType !== 1) {
            writer.uint32(184).int32(message.productType);
        }
        for (const v of message.leg) {
            CreateFxStrategyLegOrder.encode(v!, writer.uint32(194).fork()).ldelim();
        }
        if (message.settlementType !== '') {
            writer.uint32(202).string(message.settlementType);
        }
        if (message.exDestination !== '') {
            writer.uint32(210).string(message.exDestination);
        }
        for (const v of message.orderMetadata) {
            KeyValue.encode(v!, writer.uint32(218).fork()).ldelim();
        }
        if (message.trader !== '') {
            writer.uint32(226).string(message.trader);
        }
        if (message.rateSource !== '') {
            writer.uint32(234).string(message.rateSource);
        }
        if (message.contraQty !== 0) {
            writer.uint32(241).double(message.contraQty);
        }
        if (message.assetType !== 1) {
            writer.uint32(248).int32(message.assetType);
        }
        if (message.streamCode !== '') {
            writer.uint32(258).string(message.streamCode);
        }
        for (const v of message.includedLPs) {
            writer.uint32(266).string(v!);
        }
        for (const v of message.excludedLPs) {
            writer.uint32(274).string(v!);
        }
        for (const v of message.orderAllocGroup) {
            CreateOrderAllocGroup.encode(v!, writer.uint32(282).fork()).ldelim();
        }
        if (message.minQty !== 0) {
            writer.uint32(289).double(message.minQty);
        }
        if (message.maxShow !== 0) {
            writer.uint32(297).double(message.maxShow);
        }
        if (message.pegPriceType !== 1) {
            writer.uint32(304).int32(message.pegPriceType);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateFxOrderRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateFxOrderRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.clOrderId = reader.string();
                    break;
                case 4:
                    message.securityCode = reader.string();
                    break;
                case 5:
                    message.securityId = reader.string();
                    break;
                case 6:
                    message.accountType = reader.int32() as any;
                    break;
                case 7:
                    message.account = reader.string();
                    break;
                case 8:
                    message.side = reader.int32() as any;
                    break;
                case 9:
                    message.qty = reader.double();
                    break;
                case 10:
                    message.price = reader.double();
                    break;
                case 11:
                    message.currency = reader.string();
                    break;
                case 12:
                    message.orderType = reader.int32() as any;
                    break;
                case 13:
                    message.orderText = reader.string();
                    break;
                case 16:
                    message.quoteId = reader.string();
                    break;
                case 17:
                    message.timeInForce = reader.int32() as any;
                    break;
                case 18:
                    message.timeInForceExpiry = reader.string();
                    break;
                case 19:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 20:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 21:
                    message.deliverToCompId = reader.string();
                    break;
                case 22:
                    message.settlementDate = reader.string();
                    break;
                case 23:
                    message.productType = reader.int32() as any;
                    break;
                case 24:
                    message.leg.push(CreateFxStrategyLegOrder.decode(reader, reader.uint32()));
                    break;
                case 25:
                    message.settlementType = reader.string();
                    break;
                case 26:
                    message.exDestination = reader.string();
                    break;
                case 27:
                    message.orderMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 28:
                    message.trader = reader.string();
                    break;
                case 29:
                    message.rateSource = reader.string();
                    break;
                case 30:
                    message.contraQty = reader.double();
                    break;
                case 31:
                    message.assetType = reader.int32() as any;
                    break;
                case 32:
                    message.streamCode = reader.string();
                    break;
                case 33:
                    message.includedLPs.push(reader.string());
                    break;
                case 34:
                    message.excludedLPs.push(reader.string());
                    break;
                case 35:
                    message.orderAllocGroup.push(CreateOrderAllocGroup.decode(reader, reader.uint32()));
                    break;
                case 36:
                    message.minQty = reader.double();
                    break;
                case 37:
                    message.maxShow = reader.double();
                    break;
                case 38:
                    message.pegPriceType = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateFxOrderRequest>): CreateFxOrderRequest {
        const message = createBaseCreateFxOrderRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.clOrderId = object.clOrderId ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.accountType = object.accountType ?? 1;
        message.account = object.account ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.currency = object.currency ?? '';
        message.orderType = object.orderType ?? 1;
        message.orderText = object.orderText ?? '';
        message.quoteId = object.quoteId ?? '';
        message.timeInForce = object.timeInForce ?? 1;
        message.timeInForceExpiry = object.timeInForceExpiry ?? '';
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.productType = object.productType ?? 1;
        message.leg = object.leg?.map((e) => CreateFxStrategyLegOrder.fromPartial(e)) || [];
        message.settlementType = object.settlementType ?? '';
        message.exDestination = object.exDestination ?? '';
        message.orderMetadata = object.orderMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.trader = object.trader ?? '';
        message.rateSource = object.rateSource ?? '';
        message.contraQty = object.contraQty ?? 0;
        message.assetType = object.assetType ?? 1;
        message.streamCode = object.streamCode ?? '';
        message.includedLPs = object.includedLPs?.map((e) => e) || [];
        message.excludedLPs = object.excludedLPs?.map((e) => e) || [];
        message.orderAllocGroup = object.orderAllocGroup?.map((e) => CreateOrderAllocGroup.fromPartial(e)) || [];
        message.minQty = object.minQty ?? 0;
        message.maxShow = object.maxShow ?? 0;
        message.pegPriceType = object.pegPriceType ?? 1;
        return message;
    }
};

function createBaseCreateBitcoinOrderRequest(): CreateBitcoinOrderRequest {
    return {
        clientRequestId: '',
        clOrderId: '',
        securityCode: '',
        securityId: '',
        accountType: 1,
        account: '',
        side: 1,
        qty: 0,
        price: 0,
        currency: '',
        orderType: 1,
        orderText: '',
        quoteId: '',
        timeInForce: 1,
        timeInForceExpiry: '',
        handlingInstruction: 1,
        onBehalfOfCompId: '',
        deliverToCompId: '',
        productType: 1,
        exDestination: '',
        orderMetadata: [],
        trader: '',
        requestorTransaction: new Uint8Array(),
        requestorCoinTransactionInput: new Uint8Array(),
        streamCode: ''
    };
}

export const CreateBitcoinOrderRequest = {
    encode(message: CreateBitcoinOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.clOrderId !== '') {
            writer.uint32(18).string(message.clOrderId);
        }
        if (message.securityCode !== '') {
            writer.uint32(34).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(42).string(message.securityId);
        }
        if (message.accountType !== 1) {
            writer.uint32(48).int32(message.accountType);
        }
        if (message.account !== '') {
            writer.uint32(58).string(message.account);
        }
        if (message.side !== 1) {
            writer.uint32(64).int32(message.side);
        }
        if (message.qty !== 0) {
            writer.uint32(73).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(81).double(message.price);
        }
        if (message.currency !== '') {
            writer.uint32(90).string(message.currency);
        }
        if (message.orderType !== 1) {
            writer.uint32(96).int32(message.orderType);
        }
        if (message.orderText !== '') {
            writer.uint32(106).string(message.orderText);
        }
        if (message.quoteId !== '') {
            writer.uint32(130).string(message.quoteId);
        }
        if (message.timeInForce !== 1) {
            writer.uint32(136).int32(message.timeInForce);
        }
        if (message.timeInForceExpiry !== '') {
            writer.uint32(146).string(message.timeInForceExpiry);
        }
        if (message.handlingInstruction !== 1) {
            writer.uint32(152).int32(message.handlingInstruction);
        }
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(162).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(170).string(message.deliverToCompId);
        }
        if (message.productType !== 1) {
            writer.uint32(184).int32(message.productType);
        }
        if (message.exDestination !== '') {
            writer.uint32(194).string(message.exDestination);
        }
        for (const v of message.orderMetadata) {
            KeyValue.encode(v!, writer.uint32(202).fork()).ldelim();
        }
        if (message.trader !== '') {
            writer.uint32(210).string(message.trader);
        }
        if (message.requestorTransaction.length !== 0) {
            writer.uint32(218).bytes(message.requestorTransaction);
        }
        if (message.requestorCoinTransactionInput.length !== 0) {
            writer.uint32(226).bytes(message.requestorCoinTransactionInput);
        }
        if (message.streamCode !== '') {
            writer.uint32(234).string(message.streamCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateBitcoinOrderRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateBitcoinOrderRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.clOrderId = reader.string();
                    break;
                case 4:
                    message.securityCode = reader.string();
                    break;
                case 5:
                    message.securityId = reader.string();
                    break;
                case 6:
                    message.accountType = reader.int32() as any;
                    break;
                case 7:
                    message.account = reader.string();
                    break;
                case 8:
                    message.side = reader.int32() as any;
                    break;
                case 9:
                    message.qty = reader.double();
                    break;
                case 10:
                    message.price = reader.double();
                    break;
                case 11:
                    message.currency = reader.string();
                    break;
                case 12:
                    message.orderType = reader.int32() as any;
                    break;
                case 13:
                    message.orderText = reader.string();
                    break;
                case 16:
                    message.quoteId = reader.string();
                    break;
                case 17:
                    message.timeInForce = reader.int32() as any;
                    break;
                case 18:
                    message.timeInForceExpiry = reader.string();
                    break;
                case 19:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 20:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 21:
                    message.deliverToCompId = reader.string();
                    break;
                case 23:
                    message.productType = reader.int32() as any;
                    break;
                case 24:
                    message.exDestination = reader.string();
                    break;
                case 25:
                    message.orderMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 26:
                    message.trader = reader.string();
                    break;
                case 27:
                    message.requestorTransaction = reader.bytes();
                    break;
                case 28:
                    message.requestorCoinTransactionInput = reader.bytes();
                    break;
                case 29:
                    message.streamCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateBitcoinOrderRequest>): CreateBitcoinOrderRequest {
        const message = createBaseCreateBitcoinOrderRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.clOrderId = object.clOrderId ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.accountType = object.accountType ?? 1;
        message.account = object.account ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.currency = object.currency ?? '';
        message.orderType = object.orderType ?? 1;
        message.orderText = object.orderText ?? '';
        message.quoteId = object.quoteId ?? '';
        message.timeInForce = object.timeInForce ?? 1;
        message.timeInForceExpiry = object.timeInForceExpiry ?? '';
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.productType = object.productType ?? 1;
        message.exDestination = object.exDestination ?? '';
        message.orderMetadata = object.orderMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.trader = object.trader ?? '';
        message.requestorTransaction = object.requestorTransaction ?? new Uint8Array();
        message.requestorCoinTransactionInput = object.requestorCoinTransactionInput ?? new Uint8Array();
        message.streamCode = object.streamCode ?? '';
        return message;
    }
};

function createBaseCreateFxStrategyLegOrder(): CreateFxStrategyLegOrder {
    return {
        underlyingCode: '',
        underlyingSecurityId: '',
        legValueDate: '',
        settlementType: '',
        side: 1,
        qty: 0,
        price: 0,
        legForwardPts: 0,
        legSpotPrice: 0,
        maturityDate: '',
        contraQty: 0
    };
}

export const CreateFxStrategyLegOrder = {
    encode(message: CreateFxStrategyLegOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.underlyingCode !== '') {
            writer.uint32(10).string(message.underlyingCode);
        }
        if (message.underlyingSecurityId !== '') {
            writer.uint32(18).string(message.underlyingSecurityId);
        }
        if (message.legValueDate !== '') {
            writer.uint32(26).string(message.legValueDate);
        }
        if (message.settlementType !== '') {
            writer.uint32(74).string(message.settlementType);
        }
        if (message.side !== 1) {
            writer.uint32(32).int32(message.side);
        }
        if (message.qty !== 0) {
            writer.uint32(41).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(49).double(message.price);
        }
        if (message.legForwardPts !== 0) {
            writer.uint32(57).double(message.legForwardPts);
        }
        if (message.legSpotPrice !== 0) {
            writer.uint32(65).double(message.legSpotPrice);
        }
        if (message.maturityDate !== '') {
            writer.uint32(82).string(message.maturityDate);
        }
        if (message.contraQty !== 0) {
            writer.uint32(89).double(message.contraQty);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateFxStrategyLegOrder {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateFxStrategyLegOrder();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.underlyingCode = reader.string();
                    break;
                case 2:
                    message.underlyingSecurityId = reader.string();
                    break;
                case 3:
                    message.legValueDate = reader.string();
                    break;
                case 9:
                    message.settlementType = reader.string();
                    break;
                case 4:
                    message.side = reader.int32() as any;
                    break;
                case 5:
                    message.qty = reader.double();
                    break;
                case 6:
                    message.price = reader.double();
                    break;
                case 7:
                    message.legForwardPts = reader.double();
                    break;
                case 8:
                    message.legSpotPrice = reader.double();
                    break;
                case 10:
                    message.maturityDate = reader.string();
                    break;
                case 11:
                    message.contraQty = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateFxStrategyLegOrder>): CreateFxStrategyLegOrder {
        const message = createBaseCreateFxStrategyLegOrder();
        message.underlyingCode = object.underlyingCode ?? '';
        message.underlyingSecurityId = object.underlyingSecurityId ?? '';
        message.legValueDate = object.legValueDate ?? '';
        message.settlementType = object.settlementType ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.legForwardPts = object.legForwardPts ?? 0;
        message.legSpotPrice = object.legSpotPrice ?? 0;
        message.maturityDate = object.maturityDate ?? '';
        message.contraQty = object.contraQty ?? 0;
        return message;
    }
};

function createBaseCreateFixedIncomeOrderRequest(): CreateFixedIncomeOrderRequest {
    return {
        clientRequestId: '',
        clOrderId: '',
        securityCode: '',
        securityId: '',
        accountType: 1,
        account: '',
        side: 1,
        qty: 0,
        price: 0,
        currency: '',
        orderType: 1,
        orderText: '',
        quoteId: '',
        timeInForce: 1,
        timeInForceExpiry: '',
        handlingInstruction: 1,
        onBehalfOfCompId: '',
        deliverToCompId: '',
        settlementDate: '',
        maturityDate: '',
        productType: 1,
        orderMetadata: [],
        trader: '',
        settlementType: '',
        streamCode: ''
    };
}

export const CreateFixedIncomeOrderRequest = {
    encode(message: CreateFixedIncomeOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.clOrderId !== '') {
            writer.uint32(18).string(message.clOrderId);
        }
        if (message.securityCode !== '') {
            writer.uint32(34).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(42).string(message.securityId);
        }
        if (message.accountType !== 1) {
            writer.uint32(48).int32(message.accountType);
        }
        if (message.account !== '') {
            writer.uint32(58).string(message.account);
        }
        if (message.side !== 1) {
            writer.uint32(64).int32(message.side);
        }
        if (message.qty !== 0) {
            writer.uint32(73).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(81).double(message.price);
        }
        if (message.currency !== '') {
            writer.uint32(90).string(message.currency);
        }
        if (message.orderType !== 1) {
            writer.uint32(96).int32(message.orderType);
        }
        if (message.orderText !== '') {
            writer.uint32(106).string(message.orderText);
        }
        if (message.quoteId !== '') {
            writer.uint32(130).string(message.quoteId);
        }
        if (message.timeInForce !== 1) {
            writer.uint32(136).int32(message.timeInForce);
        }
        if (message.timeInForceExpiry !== '') {
            writer.uint32(146).string(message.timeInForceExpiry);
        }
        if (message.handlingInstruction !== 1) {
            writer.uint32(152).int32(message.handlingInstruction);
        }
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(162).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(170).string(message.deliverToCompId);
        }
        if (message.settlementDate !== '') {
            writer.uint32(178).string(message.settlementDate);
        }
        if (message.maturityDate !== '') {
            writer.uint32(186).string(message.maturityDate);
        }
        if (message.productType !== 1) {
            writer.uint32(192).int32(message.productType);
        }
        for (const v of message.orderMetadata) {
            KeyValue.encode(v!, writer.uint32(202).fork()).ldelim();
        }
        if (message.trader !== '') {
            writer.uint32(210).string(message.trader);
        }
        if (message.settlementType !== '') {
            writer.uint32(218).string(message.settlementType);
        }
        if (message.streamCode !== '') {
            writer.uint32(226).string(message.streamCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateFixedIncomeOrderRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateFixedIncomeOrderRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.clOrderId = reader.string();
                    break;
                case 4:
                    message.securityCode = reader.string();
                    break;
                case 5:
                    message.securityId = reader.string();
                    break;
                case 6:
                    message.accountType = reader.int32() as any;
                    break;
                case 7:
                    message.account = reader.string();
                    break;
                case 8:
                    message.side = reader.int32() as any;
                    break;
                case 9:
                    message.qty = reader.double();
                    break;
                case 10:
                    message.price = reader.double();
                    break;
                case 11:
                    message.currency = reader.string();
                    break;
                case 12:
                    message.orderType = reader.int32() as any;
                    break;
                case 13:
                    message.orderText = reader.string();
                    break;
                case 16:
                    message.quoteId = reader.string();
                    break;
                case 17:
                    message.timeInForce = reader.int32() as any;
                    break;
                case 18:
                    message.timeInForceExpiry = reader.string();
                    break;
                case 19:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 20:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 21:
                    message.deliverToCompId = reader.string();
                    break;
                case 22:
                    message.settlementDate = reader.string();
                    break;
                case 23:
                    message.maturityDate = reader.string();
                    break;
                case 24:
                    message.productType = reader.int32() as any;
                    break;
                case 25:
                    message.orderMetadata.push(KeyValue.decode(reader, reader.uint32()));
                    break;
                case 26:
                    message.trader = reader.string();
                    break;
                case 27:
                    message.settlementType = reader.string();
                    break;
                case 28:
                    message.streamCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateFixedIncomeOrderRequest>): CreateFixedIncomeOrderRequest {
        const message = createBaseCreateFixedIncomeOrderRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.clOrderId = object.clOrderId ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.accountType = object.accountType ?? 1;
        message.account = object.account ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.currency = object.currency ?? '';
        message.orderType = object.orderType ?? 1;
        message.orderText = object.orderText ?? '';
        message.quoteId = object.quoteId ?? '';
        message.timeInForce = object.timeInForce ?? 1;
        message.timeInForceExpiry = object.timeInForceExpiry ?? '';
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.settlementDate = object.settlementDate ?? '';
        message.maturityDate = object.maturityDate ?? '';
        message.productType = object.productType ?? 1;
        message.orderMetadata = object.orderMetadata?.map((e) => KeyValue.fromPartial(e)) || [];
        message.trader = object.trader ?? '';
        message.settlementType = object.settlementType ?? '';
        message.streamCode = object.streamCode ?? '';
        return message;
    }
};

function createBaseCreateEquityOrderRequest(): CreateEquityOrderRequest {
    return {
        clientOrderId: '',
        exchangeIdSource: '',
        securityIdSource: '',
        securityCode: '',
        securityId: '',
        accountType: 1,
        account: '',
        side: 1,
        qty: 0,
        price: 0,
        currency: '',
        orderType: 1,
        timeInForce: 1,
        timeInForceExpiry: '',
        orderText: '',
        giveIn: '',
        giveUp: '',
        handlingInstruction: 1,
        onBehalfOfCompId: '',
        deliverToCompId: '',
        executionInstruction: 1,
        quoteId: '',
        streamCode: ''
    };
}

export const CreateEquityOrderRequest = {
    encode(message: CreateEquityOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientOrderId !== '') {
            writer.uint32(18).string(message.clientOrderId);
        }
        if (message.exchangeIdSource !== '') {
            writer.uint32(26).string(message.exchangeIdSource);
        }
        if (message.securityIdSource !== '') {
            writer.uint32(42).string(message.securityIdSource);
        }
        if (message.securityCode !== '') {
            writer.uint32(50).string(message.securityCode);
        }
        if (message.securityId !== '') {
            writer.uint32(58).string(message.securityId);
        }
        if (message.accountType !== 1) {
            writer.uint32(72).int32(message.accountType);
        }
        if (message.account !== '') {
            writer.uint32(82).string(message.account);
        }
        if (message.side !== 1) {
            writer.uint32(88).int32(message.side);
        }
        if (message.qty !== 0) {
            writer.uint32(97).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(105).double(message.price);
        }
        if (message.currency !== '') {
            writer.uint32(114).string(message.currency);
        }
        if (message.orderType !== 1) {
            writer.uint32(120).int32(message.orderType);
        }
        if (message.timeInForce !== 1) {
            writer.uint32(128).int32(message.timeInForce);
        }
        if (message.timeInForceExpiry !== '') {
            writer.uint32(138).string(message.timeInForceExpiry);
        }
        if (message.orderText !== '') {
            writer.uint32(146).string(message.orderText);
        }
        if (message.giveIn !== '') {
            writer.uint32(154).string(message.giveIn);
        }
        if (message.giveUp !== '') {
            writer.uint32(162).string(message.giveUp);
        }
        if (message.handlingInstruction !== 1) {
            writer.uint32(168).int32(message.handlingInstruction);
        }
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(178).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(186).string(message.deliverToCompId);
        }
        if (message.executionInstruction !== 1) {
            writer.uint32(216).int32(message.executionInstruction);
        }
        if (message.quoteId !== '') {
            writer.uint32(226).string(message.quoteId);
        }
        if (message.streamCode !== '') {
            writer.uint32(234).string(message.streamCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateEquityOrderRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateEquityOrderRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.clientOrderId = reader.string();
                    break;
                case 3:
                    message.exchangeIdSource = reader.string();
                    break;
                case 5:
                    message.securityIdSource = reader.string();
                    break;
                case 6:
                    message.securityCode = reader.string();
                    break;
                case 7:
                    message.securityId = reader.string();
                    break;
                case 9:
                    message.accountType = reader.int32() as any;
                    break;
                case 10:
                    message.account = reader.string();
                    break;
                case 11:
                    message.side = reader.int32() as any;
                    break;
                case 12:
                    message.qty = reader.double();
                    break;
                case 13:
                    message.price = reader.double();
                    break;
                case 14:
                    message.currency = reader.string();
                    break;
                case 15:
                    message.orderType = reader.int32() as any;
                    break;
                case 16:
                    message.timeInForce = reader.int32() as any;
                    break;
                case 17:
                    message.timeInForceExpiry = reader.string();
                    break;
                case 18:
                    message.orderText = reader.string();
                    break;
                case 19:
                    message.giveIn = reader.string();
                    break;
                case 20:
                    message.giveUp = reader.string();
                    break;
                case 21:
                    message.handlingInstruction = reader.int32() as any;
                    break;
                case 22:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 23:
                    message.deliverToCompId = reader.string();
                    break;
                case 27:
                    message.executionInstruction = reader.int32() as any;
                    break;
                case 28:
                    message.quoteId = reader.string();
                    break;
                case 29:
                    message.streamCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateEquityOrderRequest>): CreateEquityOrderRequest {
        const message = createBaseCreateEquityOrderRequest();
        message.clientOrderId = object.clientOrderId ?? '';
        message.exchangeIdSource = object.exchangeIdSource ?? '';
        message.securityIdSource = object.securityIdSource ?? '';
        message.securityCode = object.securityCode ?? '';
        message.securityId = object.securityId ?? '';
        message.accountType = object.accountType ?? 1;
        message.account = object.account ?? '';
        message.side = object.side ?? 1;
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.currency = object.currency ?? '';
        message.orderType = object.orderType ?? 1;
        message.timeInForce = object.timeInForce ?? 1;
        message.timeInForceExpiry = object.timeInForceExpiry ?? '';
        message.orderText = object.orderText ?? '';
        message.giveIn = object.giveIn ?? '';
        message.giveUp = object.giveUp ?? '';
        message.handlingInstruction = object.handlingInstruction ?? 1;
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        message.executionInstruction = object.executionInstruction ?? 1;
        message.quoteId = object.quoteId ?? '';
        message.streamCode = object.streamCode ?? '';
        return message;
    }
};

function createBaseCancelOrderRequest(): CancelOrderRequest {
    return { orderId: '0', clOrdId: '', origClOrdId: '', onBehalfOfCompId: '', deliverToCompId: '' };
}

export const CancelOrderRequest = {
    encode(message: CancelOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.orderId !== '0') {
            writer.uint32(16).int64(message.orderId);
        }
        if (message.clOrdId !== '') {
            writer.uint32(26).string(message.clOrdId);
        }
        if (message.origClOrdId !== '') {
            writer.uint32(34).string(message.origClOrdId);
        }
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(42).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(50).string(message.deliverToCompId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CancelOrderRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCancelOrderRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.clOrdId = reader.string();
                    break;
                case 4:
                    message.origClOrdId = reader.string();
                    break;
                case 5:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 6:
                    message.deliverToCompId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CancelOrderRequest>): CancelOrderRequest {
        const message = createBaseCancelOrderRequest();
        message.orderId = object.orderId ?? '0';
        message.clOrdId = object.clOrdId ?? '';
        message.origClOrdId = object.origClOrdId ?? '';
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        return message;
    }
};

function createBaseAmendOrderRequest(): AmendOrderRequest {
    return {
        orderId: '0',
        clOrdId: '',
        origClOrdId: '',
        account: '',
        qty: 0,
        price: 0,
        orderText: '',
        giveIn: '',
        giveUp: '',
        onBehalfOfCompId: '',
        deliverToCompId: ''
    };
}

export const AmendOrderRequest = {
    encode(message: AmendOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.orderId !== '0') {
            writer.uint32(16).int64(message.orderId);
        }
        if (message.clOrdId !== '') {
            writer.uint32(26).string(message.clOrdId);
        }
        if (message.origClOrdId !== '') {
            writer.uint32(34).string(message.origClOrdId);
        }
        if (message.account !== '') {
            writer.uint32(42).string(message.account);
        }
        if (message.qty !== 0) {
            writer.uint32(49).double(message.qty);
        }
        if (message.price !== 0) {
            writer.uint32(57).double(message.price);
        }
        if (message.orderText !== '') {
            writer.uint32(66).string(message.orderText);
        }
        if (message.giveIn !== '') {
            writer.uint32(74).string(message.giveIn);
        }
        if (message.giveUp !== '') {
            writer.uint32(82).string(message.giveUp);
        }
        if (message.onBehalfOfCompId !== '') {
            writer.uint32(90).string(message.onBehalfOfCompId);
        }
        if (message.deliverToCompId !== '') {
            writer.uint32(98).string(message.deliverToCompId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): AmendOrderRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAmendOrderRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.clOrdId = reader.string();
                    break;
                case 4:
                    message.origClOrdId = reader.string();
                    break;
                case 5:
                    message.account = reader.string();
                    break;
                case 6:
                    message.qty = reader.double();
                    break;
                case 7:
                    message.price = reader.double();
                    break;
                case 8:
                    message.orderText = reader.string();
                    break;
                case 9:
                    message.giveIn = reader.string();
                    break;
                case 10:
                    message.giveUp = reader.string();
                    break;
                case 11:
                    message.onBehalfOfCompId = reader.string();
                    break;
                case 12:
                    message.deliverToCompId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<AmendOrderRequest>): AmendOrderRequest {
        const message = createBaseAmendOrderRequest();
        message.orderId = object.orderId ?? '0';
        message.clOrdId = object.clOrdId ?? '';
        message.origClOrdId = object.origClOrdId ?? '';
        message.account = object.account ?? '';
        message.qty = object.qty ?? 0;
        message.price = object.price ?? 0;
        message.orderText = object.orderText ?? '';
        message.giveIn = object.giveIn ?? '';
        message.giveUp = object.giveUp ?? '';
        message.onBehalfOfCompId = object.onBehalfOfCompId ?? '';
        message.deliverToCompId = object.deliverToCompId ?? '';
        return message;
    }
};

function createBaseFindAllAssignedOrderSnapshots(): FindAllAssignedOrderSnapshots {
    return { clientRequestId: '' };
}

export const FindAllAssignedOrderSnapshots = {
    encode(message: FindAllAssignedOrderSnapshots, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAllAssignedOrderSnapshots {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAllAssignedOrderSnapshots();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAllAssignedOrderSnapshots>): FindAllAssignedOrderSnapshots {
        const message = createBaseFindAllAssignedOrderSnapshots();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseFindAllOrderSnapshotsBySessionKey(): FindAllOrderSnapshotsBySessionKey {
    return { clientRequestId: '' };
}

export const FindAllOrderSnapshotsBySessionKey = {
    encode(message: FindAllOrderSnapshotsBySessionKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAllOrderSnapshotsBySessionKey {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAllOrderSnapshotsBySessionKey();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAllOrderSnapshotsBySessionKey>): FindAllOrderSnapshotsBySessionKey {
        const message = createBaseFindAllOrderSnapshotsBySessionKey();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseFindAllOrderSnapshotsByClientOrderId(): FindAllOrderSnapshotsByClientOrderId {
    return { clientRequestId: '', clOrderId: '' };
}

export const FindAllOrderSnapshotsByClientOrderId = {
    encode(message: FindAllOrderSnapshotsByClientOrderId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.clOrderId !== '') {
            writer.uint32(18).string(message.clOrderId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAllOrderSnapshotsByClientOrderId {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAllOrderSnapshotsByClientOrderId();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.clOrderId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAllOrderSnapshotsByClientOrderId>): FindAllOrderSnapshotsByClientOrderId {
        const message = createBaseFindAllOrderSnapshotsByClientOrderId();
        message.clientRequestId = object.clientRequestId ?? '';
        message.clOrderId = object.clOrderId ?? '';
        return message;
    }
};

function createBaseFindAllOrderSnapshotsByOrderId(): FindAllOrderSnapshotsByOrderId {
    return { clientRequestId: '', orderId: '0' };
}

export const FindAllOrderSnapshotsByOrderId = {
    encode(message: FindAllOrderSnapshotsByOrderId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.orderId !== '0') {
            writer.uint32(16).int64(message.orderId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAllOrderSnapshotsByOrderId {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAllOrderSnapshotsByOrderId();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.orderId = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAllOrderSnapshotsByOrderId>): FindAllOrderSnapshotsByOrderId {
        const message = createBaseFindAllOrderSnapshotsByOrderId();
        message.clientRequestId = object.clientRequestId ?? '';
        message.orderId = object.orderId ?? '0';
        return message;
    }
};

function createBaseMarketMerchantDestinationCommand(): MarketMerchantDestinationCommand {
    return { clientRequestId: '', marketMerchantDestinationKey: '', operation: 0 };
}

export const MarketMerchantDestinationCommand = {
    encode(message: MarketMerchantDestinationCommand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.marketMerchantDestinationKey !== '') {
            writer.uint32(26).string(message.marketMerchantDestinationKey);
        }
        if (message.operation !== 0) {
            writer.uint32(16).int32(message.operation);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarketMerchantDestinationCommand {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarketMerchantDestinationCommand();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 3:
                    message.marketMerchantDestinationKey = reader.string();
                    break;
                case 2:
                    message.operation = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<MarketMerchantDestinationCommand>): MarketMerchantDestinationCommand {
        const message = createBaseMarketMerchantDestinationCommand();
        message.clientRequestId = object.clientRequestId ?? '';
        message.marketMerchantDestinationKey = object.marketMerchantDestinationKey ?? '';
        message.operation = object.operation ?? 0;
        return message;
    }
};

function createBaseCreateOrderAllocGroup(): CreateOrderAllocGroup {
    return { allocAccount: '', allocQty: 0, allocSide: 1, allocSettlementDate: '', allocPrice: 0, linkingId: 0 };
}

export const CreateOrderAllocGroup = {
    encode(message: CreateOrderAllocGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.allocAccount !== '') {
            writer.uint32(10).string(message.allocAccount);
        }
        if (message.allocQty !== 0) {
            writer.uint32(17).double(message.allocQty);
        }
        if (message.allocSide !== 1) {
            writer.uint32(24).int32(message.allocSide);
        }
        if (message.allocSettlementDate !== '') {
            writer.uint32(34).string(message.allocSettlementDate);
        }
        if (message.allocPrice !== 0) {
            writer.uint32(41).double(message.allocPrice);
        }
        if (message.linkingId !== 0) {
            writer.uint32(48).int32(message.linkingId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrderAllocGroup {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateOrderAllocGroup();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.allocAccount = reader.string();
                    break;
                case 2:
                    message.allocQty = reader.double();
                    break;
                case 3:
                    message.allocSide = reader.int32() as any;
                    break;
                case 4:
                    message.allocSettlementDate = reader.string();
                    break;
                case 5:
                    message.allocPrice = reader.double();
                    break;
                case 6:
                    message.linkingId = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateOrderAllocGroup>): CreateOrderAllocGroup {
        const message = createBaseCreateOrderAllocGroup();
        message.allocAccount = object.allocAccount ?? '';
        message.allocQty = object.allocQty ?? 0;
        message.allocSide = object.allocSide ?? 1;
        message.allocSettlementDate = object.allocSettlementDate ?? '';
        message.allocPrice = object.allocPrice ?? 0;
        message.linkingId = object.linkingId ?? 0;
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
