import { InferType, object, string } from 'yup';
import { LoginResponse } from '../../../compiled_proto/com/celertech/baseserver/communication/login/DownstreamLoginProto';
import { Nullable } from '../../../model/common';

export const loginSchema = (userRequires2FA?: LoginResponse) =>
    object({
        username: string().required('Username cannot be empty!'),
        password: string().required('Password cannot be empty!'),
        twoFactorToken: string()
            .optional()
            .test('noEmptyTwoFactorToken', 'Verification Code should not be empty.', (token) => {
                if (userRequires2FA && !token) return false;
                return true;
            })
    });

export type LoginFormValues = InferType<ReturnType<typeof loginSchema>>;
export type LoginFormInput = Nullable<LoginFormValues>;
