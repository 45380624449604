import { useAppDispatch } from '@/state/hooks';
import { setNotificationsVisited } from '@/state/reducers/notificationSlice';
import { setXplorSpotMobileLayoutNode, setXplorSpotTabletLayoutNode } from '@/state/reducers/xplorSpotLayoutSlice';
import { UseDisclosureReturn } from '@/utils/hooks/useDisclosure';
import useScreenSizes from '@/utils/hooks/useScreenSizes';
import useLayout from '@/utils/hooks/useXplorSpotLayout';
import { MdMenu } from 'react-icons/md';
import { updateTree } from 'react-mosaic-component';
import NotificationButton from '../common/NotificationButton';

interface MobileNavbarProps {
    drawerDisclosure: UseDisclosureReturn;
}

const MobileNavbar = ({ drawerDisclosure }: MobileNavbarProps) => {
    const dispatch = useAppDispatch();

    const screen = useScreenSizes();
    const { layout } = useLayout(screen);
    return (
        <div className="flex lg:hidden h-full gap-1 mr-1">
            <NotificationButton
                size="w-6 h-6"
                isActive={layout.first === 'Notifications'}
                onClick={() => {
                    dispatch(setNotificationsVisited(true));
                    if (layout.first !== 'Notifications') switchTab(screen, layout, 'Notifications', dispatch);
                    else switchTab(screen, layout, layout.second?.second || '', dispatch);
                }}
            />
            <button
                type="button"
                className="inline-flex p-2 my-1 items-center justify-center rounded-md border border-neutral-700 text-neutral-200 focus:outline-none transition duration-150 ease-in-out"
                onClick={drawerDisclosure[1].toggle}>
                <MdMenu className="w-6 h-6" />
            </button>
        </div>
    );
};

export default MobileNavbar;

export const switchTab = (screen, layoutNode, moduleToDrop, dispatch) => {
    let currentNode = layoutNode;
    if (currentNode) {
        currentNode = updateTree(currentNode, [
            {
                path: [],
                spec: {
                    $set: {
                        direction: 'row',
                        first: moduleToDrop,
                        second: {
                            direction: 'row',
                            first: screen.tablet ? 'Order' : '',
                            // saves the previous tab here, it doesn't show up on the screen
                            second: layoutNode.first,
                            splitPercentage: 100
                        },
                        splitPercentage: screen.tablet ? 60 : 100
                    }
                }
            }
        ]);
    } else {
        currentNode = moduleToDrop;
    }

    if (screen.mobile) dispatch(setXplorSpotMobileLayoutNode(currentNode));
    if (screen.tablet) dispatch(setXplorSpotTabletLayoutNode(currentNode));
};
