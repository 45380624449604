import { TimeInForceType } from '@/compiled_proto/com/celertech/orderrouting/api/enums/TimeInForceTypeProto';
import { Side } from '@/compiled_proto/com/celertech/positionmanager/api/enums/SideProto';
import { submitLimitOrder } from '@/services/OrderService';
import { useAppSelector } from '@/state/hooks';
import { selectCredentials, selectCurrentAccount } from '@/state/reducers/authSlice';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import useLimitOrder from '@/utils/hooks/useLimitOrder';
import { UseOrderExecutionReturn } from '@/utils/hooks/useOrderExecution';
import useScreenSizes from '@/utils/hooks/useScreenSizes';
import { yupResolver } from '@hookform/resolvers/yup';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { useEffect } from 'react';
import { isTablet } from 'react-device-detect';
import { FormProvider, useForm } from 'react-hook-form';
import CurrencySwitch from '../common/CurrencySwitch';
import InstrumentInfo, { formConfig } from '../common/InstrumentInfo';
import OrderTypeSwitch from '../common/OrderTypeSwitch';
import Tooltip from '../common/Tooltip';
import ExecutionInputController from '../inputs/ExecutionInputController';
import RHFNumberInput from '../inputs/RHFNumberInput';
import Select from '../inputs/Select';
import FormSubmit from './components/FormSubmit';
import Totals from './components/Totals';
import { LimitOrderFormInput, LimitOrderFormValues, limitOrderSchema } from './schema/limitOrderSchema';

const durationOptions: { label: keyof typeof TimeInForceType; value: TimeInForceType }[] = [
    { label: 'DAY', value: TimeInForceType.DAY },
    { label: 'IOC', value: TimeInForceType.IOC },
    { label: 'FOK', value: TimeInForceType.FOK },
    { label: 'GTC', value: TimeInForceType.GTC }
];

export const defaultValues: Partial<LimitOrderFormInput> = {
    limitPrice: null,
    quantity: null,
    duration: durationOptions[0],
    slippage: '0.00000'
};

interface LimitOrderFormProps extends UseOrderExecutionReturn {}

function LimitOrderForm(props: LimitOrderFormProps) {
    const {
        activePair,
        ccy1,
        ccy2,
        sideState,
        currencyState,
        orderBook,
        orderMessage,
        instrument,
        activeCurrencyConfig,
        ccy1Config,
        ccy2Config,
        isCcy2Order
    } = props;

    const screen = useScreenSizes();
    const credentials = useAppSelector(selectCredentials);
    const currentAccount = useAppSelector(selectCurrentAccount);

    const [side, setSide] = sideState;
    const [currency, setCurrency] = currencyState;

    const { asks, bids } = orderBook;
    const { pip_size, defaultSlippage, ccy2_enabled } = instrument;
    const { min_order_size, order_decimals, increments } = activeCurrencyConfig;

    const form = useForm<LimitOrderFormInput>({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(
            limitOrderSchema({
                min_order_size,
                order_decimals,
                currencyOut: currency,
                currencyPair: activePair.show
            })
        )
    });

    const {
        register,
        setValue,
        handleSubmit,
        formState: { isSubmitting, isValid }
    } = form;

    const onSubmit = async (data: LimitOrderFormInput, e) => {
        const dataValidated = data as LimitOrderFormValues;
        const slippage = dataValidated.slippage
            ? BigNumber(dataValidated.slippage)
                  .dividedBy(Math.pow(10, pip_size || 0))
                  .toString()
            : '0';
        if (dataValidated.quantity && credentials) {
            await submitLimitOrder(
                {
                    securityId: activePair.celer,
                    currencyOut: currency,
                    quantity: dataValidated.quantity,
                    limitPrice: dataValidated.limitPrice,
                    timeInForce: dataValidated.duration.value as TimeInForceType,
                    slippage,
                    side
                },
                credentials,
                currentAccount
            );
            setValue('quantity', null, { shouldValidate: false });
        }
    };

    const onError = (errors) => console.error(errors);

    const { limitPriceTooltip, isRestingLimitOrder, resetDefault } = useLimitOrder(form, props, '');

    useDidUpdate(() => resetDefault(), [currency]);

    useEffect(() => {
        resetDefault();
    }, [activePair.celer]);

    return (
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit, onError)} className="relative flex h-full w-full text-neutral-200">
                <div
                    className={cn(
                        'flex flex-col flex-1 overflow-y-auto gap-3 mb-14 p-2 pb-0',
                        Side[side].toLowerCase()
                    )}>
                    <OrderTypeSwitch initialValue={side} onChange={setSide} />
                    <div className="flex justify-between items-center">
                        <div className="flex items-center gap-1">
                            {orderMessage}
                            <InstrumentInfo market={activePair} {...formConfig} />
                        </div>
                        <CurrencySwitch
                            value={currency}
                            instrument={instrument}
                            baseCurrency={ccy1Config}
                            quoteCurrency={ccy2Config}
                            disabled={!ccy2_enabled}
                            onChange={setCurrency}
                        />
                    </div>
                    <div>
                        <Tooltip content={limitPriceTooltip || ''} className="-top-16 right-0 w-2/3">
                            <RHFNumberInput
                                label="Limit Price *"
                                placeholder="Limit Price"
                                {...register('limitPrice')}
                                disabled={!isRestingLimitOrder}
                                className={{
                                    'text-brand-red': limitPriceTooltip,
                                    'text-neutral-200': !limitPriceTooltip
                                }}
                                step={BigNumber(1)
                                    .dividedBy(Math.pow(10, pip_size || 0))
                                    .toNumber()}
                            />
                        </Tooltip>
                        {(screen.mobile || screen.tablet || isTablet) && (
                            <span className="text-brand-red italic">{limitPriceTooltip}</span>
                        )}
                    </div>
                    <ExecutionInputController name="duration" label="Duration *">
                        <Select type="execution" options={durationOptions} />
                    </ExecutionInputController>
                    <RHFNumberInput
                        {...register('quantity')}
                        label="Quantity *"
                        placeholder="Quantity"
                        min={min_order_size}
                        step={increments}
                    />
                    <RHFNumberInput
                        {...register('slippage')}
                        label="Slippage (pips)"
                        placeholder="Slippage (pips)"
                        disabled={isRestingLimitOrder}
                        min={defaultSlippage}
                        step={0.1}
                    />
                    <Totals
                        activePair={activePair}
                        side={side}
                        ccy1={ccy1}
                        ccy2={ccy2}
                        ccy2Order={isCcy2Order}
                        bids={bids}
                        asks={asks}
                    />
                </div>
                <div className="absolute bottom-0 p-2 w-full">
                    <FormSubmit side={side} isValid={isValid} disabled={isSubmitting || !isValid} />
                </div>
            </form>
        </FormProvider>
    );
}

export default LimitOrderForm;
