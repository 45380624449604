import { Nullable } from '@/model/common';
import { CelerBalance, CollapsedBalances } from '@/state/reducers/balanceSlice';
import { InferType, number, object, string } from 'yup';

export const cryptoWithdrawalSchema = (balances?: CollapsedBalances[]) =>
    object({
        // select
        account: object({ label: string(), value: string() }).test(
            'valueNotUndefined',
            `Please select an account`,
            (acc) => !!acc.value
        ),
        amount: number()
            .required('Withdrawal Amount cannot be empty!')
            .typeError('Invalid Input: Please input a number.')
            .min(0, 'Invalid Input: Can not be negative.')
            .test('noAccount', `Please select an account.`, function () {
                const { account } = this.parent;
                return account.value;
            })
            .test('noCurrency', `Please select an asset.`, function () {
                const { asset } = this.parent;
                return asset.value;
            })
            .test('invalidBalance', `Insufficient balance for withdrawal`, function (value) {
                const { asset } = this.parent;
                let availableAmount;
                if (asset?.value && balances) {
                    const ccy = balances.find((balance) => balance.currency === asset.value) as CollapsedBalances &
                        CelerBalance;
                    const value = ccy?.totalAmount || ccy?.netPosition;
                    availableAmount = value || 0;
                } else {
                    availableAmount = 'No Asset/Balance';
                }

                return availableAmount !== 'No Asset/Balance' && !(availableAmount < +(value || 0));
            }),
        asset: object({ label: string(), value: string() }).test(
            'valueNotUndefined',
            `Please select a crypto asset`,
            (ccy) => !!ccy.value
        ),
        address: string().required('Invalid Input: Please input an address.'),
        email: object({
            cc_to: string()
        }).optional()
    });

export type CryptoWithdrawalFormValues = InferType<ReturnType<typeof cryptoWithdrawalSchema>>;
export type CryptoWithdrawalFormInput = Nullable<CryptoWithdrawalFormValues>;
