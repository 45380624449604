import { InferType, number, object, string } from 'yup';
import { Nullable } from '../../../model/common';

export const fiatDepositSchema = () =>
    object({
        // select
        account: object({ label: string(), value: string() }).test(
            'valueNotUndefined',
            `Please select an account`,
            (acc) => !!acc.value
        ),
        amount: number()
            .required('Deposit Amount cannot be empty!')
            .typeError('Invalid Input: Please input a number.')
            .min(0, 'Invalid Input: Can not be negative.'),
        currency: object({ label: string(), value: string() }).test(
            'valueNotUndefined',
            `Please select a fiat currency`,
            (ccy) => !!ccy.value
        ),
        email: object({
            cc_to: string()
        }).optional()
    });

export type FiatDepositFormValues = InferType<ReturnType<typeof fiatDepositSchema>>;
export type FiatDepositFormInput = Nullable<FiatDepositFormValues>;
