/* eslint-disable */
import { CallContext, CallOptions } from 'nice-grpc-common';
import { Empty } from '../../../../../google/protobuf/empty';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.staticdata.api.valuedate';

export interface FxValueDateRequest {
    securityId: string;
    tenorCode: string;
    exchangeCode: string;
}

export interface FxValueDateRequestBatch {
    request: FxValueDateRequest[];
}

export interface ValueDateEvent {
    fxValueDateResponse?: FxValueDateResponse | undefined;
    valueDateRequestRejected?: ValueDateRequestRejected | undefined;
}

export interface ValueDateEventBatch {
    request: ValueDateEvent[];
}

export interface FxValueDateResponse {
    securityId: string;
    tenorCode: string;
    valueDate: string;
}

export interface TenorValueRequest {
    valueDate: string;
    baseCurrency: string;
    termCurrency: string;
}

export interface TenorValueEvent {
    tenorCode: string;
}

export interface ValueDateRequestRejected {
    securityId: string;
    tenorCode: string;
}

export interface TenorList {
    tenorCode: string[];
}

function createBaseFxValueDateRequest(): FxValueDateRequest {
    return { securityId: '', tenorCode: '', exchangeCode: '' };
}

export const FxValueDateRequest = {
    encode(message: FxValueDateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.securityId !== '') {
            writer.uint32(10).string(message.securityId);
        }
        if (message.tenorCode !== '') {
            writer.uint32(18).string(message.tenorCode);
        }
        if (message.exchangeCode !== '') {
            writer.uint32(26).string(message.exchangeCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FxValueDateRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFxValueDateRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.securityId = reader.string();
                    break;
                case 2:
                    message.tenorCode = reader.string();
                    break;
                case 3:
                    message.exchangeCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FxValueDateRequest>): FxValueDateRequest {
        const message = createBaseFxValueDateRequest();
        message.securityId = object.securityId ?? '';
        message.tenorCode = object.tenorCode ?? '';
        message.exchangeCode = object.exchangeCode ?? '';
        return message;
    }
};

function createBaseFxValueDateRequestBatch(): FxValueDateRequestBatch {
    return { request: [] };
}

export const FxValueDateRequestBatch = {
    encode(message: FxValueDateRequestBatch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.request) {
            FxValueDateRequest.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FxValueDateRequestBatch {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFxValueDateRequestBatch();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.request.push(FxValueDateRequest.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FxValueDateRequestBatch>): FxValueDateRequestBatch {
        const message = createBaseFxValueDateRequestBatch();
        message.request = object.request?.map((e) => FxValueDateRequest.fromPartial(e)) || [];
        return message;
    }
};

function createBaseValueDateEvent(): ValueDateEvent {
    return { fxValueDateResponse: undefined, valueDateRequestRejected: undefined };
}

export const ValueDateEvent = {
    encode(message: ValueDateEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.fxValueDateResponse !== undefined) {
            FxValueDateResponse.encode(message.fxValueDateResponse, writer.uint32(10).fork()).ldelim();
        }
        if (message.valueDateRequestRejected !== undefined) {
            ValueDateRequestRejected.encode(message.valueDateRequestRejected, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): ValueDateEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseValueDateEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.fxValueDateResponse = FxValueDateResponse.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.valueDateRequestRejected = ValueDateRequestRejected.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<ValueDateEvent>): ValueDateEvent {
        const message = createBaseValueDateEvent();
        message.fxValueDateResponse =
            object.fxValueDateResponse !== undefined && object.fxValueDateResponse !== null
                ? FxValueDateResponse.fromPartial(object.fxValueDateResponse)
                : undefined;
        message.valueDateRequestRejected =
            object.valueDateRequestRejected !== undefined && object.valueDateRequestRejected !== null
                ? ValueDateRequestRejected.fromPartial(object.valueDateRequestRejected)
                : undefined;
        return message;
    }
};

function createBaseValueDateEventBatch(): ValueDateEventBatch {
    return { request: [] };
}

export const ValueDateEventBatch = {
    encode(message: ValueDateEventBatch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.request) {
            ValueDateEvent.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): ValueDateEventBatch {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseValueDateEventBatch();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.request.push(ValueDateEvent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<ValueDateEventBatch>): ValueDateEventBatch {
        const message = createBaseValueDateEventBatch();
        message.request = object.request?.map((e) => ValueDateEvent.fromPartial(e)) || [];
        return message;
    }
};

function createBaseFxValueDateResponse(): FxValueDateResponse {
    return { securityId: '', tenorCode: '', valueDate: '' };
}

export const FxValueDateResponse = {
    encode(message: FxValueDateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.securityId !== '') {
            writer.uint32(10).string(message.securityId);
        }
        if (message.tenorCode !== '') {
            writer.uint32(18).string(message.tenorCode);
        }
        if (message.valueDate !== '') {
            writer.uint32(26).string(message.valueDate);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FxValueDateResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFxValueDateResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.securityId = reader.string();
                    break;
                case 2:
                    message.tenorCode = reader.string();
                    break;
                case 3:
                    message.valueDate = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FxValueDateResponse>): FxValueDateResponse {
        const message = createBaseFxValueDateResponse();
        message.securityId = object.securityId ?? '';
        message.tenorCode = object.tenorCode ?? '';
        message.valueDate = object.valueDate ?? '';
        return message;
    }
};

function createBaseTenorValueRequest(): TenorValueRequest {
    return { valueDate: '', baseCurrency: '', termCurrency: '' };
}

export const TenorValueRequest = {
    encode(message: TenorValueRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.valueDate !== '') {
            writer.uint32(10).string(message.valueDate);
        }
        if (message.baseCurrency !== '') {
            writer.uint32(18).string(message.baseCurrency);
        }
        if (message.termCurrency !== '') {
            writer.uint32(26).string(message.termCurrency);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): TenorValueRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTenorValueRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.valueDate = reader.string();
                    break;
                case 2:
                    message.baseCurrency = reader.string();
                    break;
                case 3:
                    message.termCurrency = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<TenorValueRequest>): TenorValueRequest {
        const message = createBaseTenorValueRequest();
        message.valueDate = object.valueDate ?? '';
        message.baseCurrency = object.baseCurrency ?? '';
        message.termCurrency = object.termCurrency ?? '';
        return message;
    }
};

function createBaseTenorValueEvent(): TenorValueEvent {
    return { tenorCode: '' };
}

export const TenorValueEvent = {
    encode(message: TenorValueEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.tenorCode !== '') {
            writer.uint32(10).string(message.tenorCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): TenorValueEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTenorValueEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tenorCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<TenorValueEvent>): TenorValueEvent {
        const message = createBaseTenorValueEvent();
        message.tenorCode = object.tenorCode ?? '';
        return message;
    }
};

function createBaseValueDateRequestRejected(): ValueDateRequestRejected {
    return { securityId: '', tenorCode: '' };
}

export const ValueDateRequestRejected = {
    encode(message: ValueDateRequestRejected, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.securityId !== '') {
            writer.uint32(10).string(message.securityId);
        }
        if (message.tenorCode !== '') {
            writer.uint32(18).string(message.tenorCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): ValueDateRequestRejected {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseValueDateRequestRejected();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.securityId = reader.string();
                    break;
                case 2:
                    message.tenorCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<ValueDateRequestRejected>): ValueDateRequestRejected {
        const message = createBaseValueDateRequestRejected();
        message.securityId = object.securityId ?? '';
        message.tenorCode = object.tenorCode ?? '';
        return message;
    }
};

function createBaseTenorList(): TenorList {
    return { tenorCode: [] };
}

export const TenorList = {
    encode(message: TenorList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.tenorCode) {
            writer.uint32(10).string(v!);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): TenorList {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTenorList();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.tenorCode.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<TenorList>): TenorList {
        const message = createBaseTenorList();
        message.tenorCode = object.tenorCode?.map((e) => e) || [];
        return message;
    }
};

export type ValueDateServiceDefinition = typeof ValueDateServiceDefinition;
export const ValueDateServiceDefinition = {
    name: 'ValueDateService',
    fullName: 'com.celertech.staticdata.api.valuedate.ValueDateService',
    methods: {
        getValueDateForFxSecurity: {
            name: 'getValueDateForFxSecurity',
            requestType: FxValueDateRequest,
            requestStream: false,
            responseType: ValueDateEvent,
            responseStream: false,
            options: {}
        },
        getValueDateForWarehouseSecurity: {
            name: 'getValueDateForWarehouseSecurity',
            requestType: FxValueDateRequest,
            requestStream: false,
            responseType: ValueDateEvent,
            responseStream: false,
            options: {}
        },
        getValueDateForFxSecurityBatch: {
            name: 'getValueDateForFxSecurityBatch',
            requestType: FxValueDateRequestBatch,
            requestStream: false,
            responseType: ValueDateEventBatch,
            responseStream: false,
            options: {}
        },
        getTenorFromValueDate: {
            name: 'getTenorFromValueDate',
            requestType: TenorValueRequest,
            requestStream: false,
            responseType: TenorValueEvent,
            responseStream: false,
            options: {}
        },
        getTenorList: {
            name: 'getTenorList',
            requestType: Empty,
            requestStream: false,
            responseType: TenorList,
            responseStream: false,
            options: {}
        }
    }
} as const;

export interface ValueDateServiceServiceImplementation<CallContextExt = {}> {
    getValueDateForFxSecurity(
        request: FxValueDateRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<ValueDateEvent>>;
    getValueDateForWarehouseSecurity(
        request: FxValueDateRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<ValueDateEvent>>;
    getValueDateForFxSecurityBatch(
        request: FxValueDateRequestBatch,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<ValueDateEventBatch>>;
    getTenorFromValueDate(
        request: TenorValueRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<TenorValueEvent>>;
    getTenorList(request: Empty, context: CallContext & CallContextExt): Promise<DeepPartial<TenorList>>;
}

export interface ValueDateServiceClient<CallOptionsExt = {}> {
    getValueDateForFxSecurity(
        request: DeepPartial<FxValueDateRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<ValueDateEvent>;
    getValueDateForWarehouseSecurity(
        request: DeepPartial<FxValueDateRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<ValueDateEvent>;
    getValueDateForFxSecurityBatch(
        request: DeepPartial<FxValueDateRequestBatch>,
        options?: CallOptions & CallOptionsExt
    ): Promise<ValueDateEventBatch>;
    getTenorFromValueDate(
        request: DeepPartial<TenorValueRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<TenorValueEvent>;
    getTenorList(request: DeepPartial<Empty>, options?: CallOptions & CallOptionsExt): Promise<TenorList>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;
