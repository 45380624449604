import { useCallback, useMemo } from 'react';
import { BlotterItem } from '../../model/blotters';
import Divider from '../common/Divider';

import { useInstrument } from '@/utils/hooks/useInstrument';
import { convertToPips } from '@/utils/slippage';
import { useSelector } from 'react-redux';
import { cancelLimitOrder } from '../../services/OrderService';
import { selectCredentials } from '../../state/reducers/authSlice';
import Modal, { ModalHeader, ModalProps, ModalTitle } from './Modal';

interface OrderCancellationModalProps extends ModalProps {
    order: BlotterItem;
    onSubmit?: () => void;
}

const OrderCancellationModal = (props: OrderCancellationModalProps) => {
    const { order, handlers, onSubmit } = props;
    const { orderType } = order;

    const credentials = useSelector(selectCredentials);
    const { pip_size } = useInstrument(order.pair);

    const { isStopMarket, isStopLimit } = useMemo(
        () => ({
            isStopMarket: orderType === 'STOP_MARKET',
            isStopLimit: orderType === 'STOP_LIMIT'
        }),
        [orderType]
    );
    const orderPriceType = useMemo(() => (isStopMarket || isStopLimit ? 'Stop Price' : 'Limit Price'), [orderType]);
    const onCancelOrder = useCallback(() => {
        if (order && credentials) {
            cancelLimitOrder(
                {
                    orderId: order.orderId,
                    clOrdId: order.celerClOrderId,
                    origClOrdId: order.originalCelerClOrderId,
                    onBehalfOfCompId: order.onBehalfOfCompId,
                    deliverToCompId: order.deliverToCompId,
                    checkpointTimestamp: order.checkpointTimestamp,
                    onBehalfOfUsername: '',
                    parentOrderId: order.parentOrderId
                },
                credentials
            );
            handlers.close();
            // onSubmit();
        }
    }, [order, credentials]);

    return (
        <Modal {...props} className="h-auto" size="max-w-md">
            <ModalHeader>
                <ModalTitle>Order Cancellation</ModalTitle>
            </ModalHeader>
            <Divider className="bg-brand-red" />
            <div className="text-neutral-200 p-2 sm:p-4">
                <div className="pb-4 text-sm">
                    <div className="flex flex-col space-y-2">
                        <div className="space-y-1">
                            <h3 className="text-md font-bold pb-1">Order Details</h3>
                            <p>Symbol Name : {order.pair}</p>
                            <p>Side : {order.side}</p>
                            <p>Order Type : {order.orderType}</p>
                            <p>Order Id : {order.orderId}</p>
                            <p>Status : {order.status}</p>
                            <p>Duration : {order.tif}</p>
                            <p>
                                {orderPriceType} : {isStopMarket || isStopLimit ? order.stopPrice : order.price}
                            </p>
                            <p>Quantity : {order.qty}</p>
                            {isStopLimit && (
                                <>
                                    <p>Slippage (pips) : {convertToPips(order.slippage, pip_size)}</p>
                                    <p>Stop Limit Price : {order.stopPrice}</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="text-sm">Are you sure you want to cancel the selected order?</div>
            </div>
            <Divider className="bg-brand-red" />
            <div className="text-neutral-200 p-2 sm:p-4 text-sm flex space-x-2">
                <button className="rounded-md p-2 px-4 bg-neutral-600 hover:bg-neutral-500" onClick={handlers.close}>
                    Back
                </button>
                <button className="rounded-md p-2 px-4 bg-brand-red hover:bg-brand-red-light" onClick={onCancelOrder}>
                    Cancel Order
                </button>
            </div>
        </Modal>
    );
};

export default OrderCancellationModal;
