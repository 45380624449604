/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.staticdata.api.account.property';

/** Creates an account property */
export interface CreateAccountPropertyRequest {
    clientRequestId: string;
    /** The account short code */
    code: string;
    /** The property key */
    key: string;
    /** The property value */
    value: string;
    /** The property description */
    description: string;
}

export interface UpdateAccountPropertyRequest {
    clientRequestId: string;
    id: string;
    code: string;
    key: string;
    value: string;
    description: string;
}

export interface DeleteAccountPropertyRequest {
    clientRequestId: string;
    id: string;
}

/** Clients can register for updates on all Account Property changes */
export interface UpdateOnStaticDataAccountPropertyRequest {
    clientRequestId: string;
}

/** Clients can register for updates on a specific Account Property changes */
export interface UpdateOnStaticDataAccountPropertyForCodeRequest {
    clientRequestId: string;
    /** The account short code */
    code: string;
}

export interface FindAccountPropertyByCodeAndKey {
    clientRequestId: string;
    code: string;
    key: string;
}

export interface FindAccountPropertiesByCode {
    clientRequestId: string;
    code: string;
}

export interface FindAccountPropertiesByKey {
    clientRequestId: string;
    key: string;
}

export interface FindAllAccountProperties {
    clientRequestId: string;
}

function createBaseCreateAccountPropertyRequest(): CreateAccountPropertyRequest {
    return { clientRequestId: '', code: '', key: '', value: '', description: '' };
}

export const CreateAccountPropertyRequest = {
    encode(message: CreateAccountPropertyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.code !== '') {
            writer.uint32(18).string(message.code);
        }
        if (message.key !== '') {
            writer.uint32(26).string(message.key);
        }
        if (message.value !== '') {
            writer.uint32(34).string(message.value);
        }
        if (message.description !== '') {
            writer.uint32(42).string(message.description);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): CreateAccountPropertyRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCreateAccountPropertyRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.code = reader.string();
                    break;
                case 3:
                    message.key = reader.string();
                    break;
                case 4:
                    message.value = reader.string();
                    break;
                case 5:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<CreateAccountPropertyRequest>): CreateAccountPropertyRequest {
        const message = createBaseCreateAccountPropertyRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.code = object.code ?? '';
        message.key = object.key ?? '';
        message.value = object.value ?? '';
        message.description = object.description ?? '';
        return message;
    }
};

function createBaseUpdateAccountPropertyRequest(): UpdateAccountPropertyRequest {
    return { clientRequestId: '', id: '0', code: '', key: '', value: '', description: '' };
}

export const UpdateAccountPropertyRequest = {
    encode(message: UpdateAccountPropertyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.id !== '0') {
            writer.uint32(16).int64(message.id);
        }
        if (message.code !== '') {
            writer.uint32(26).string(message.code);
        }
        if (message.key !== '') {
            writer.uint32(34).string(message.key);
        }
        if (message.value !== '') {
            writer.uint32(42).string(message.value);
        }
        if (message.description !== '') {
            writer.uint32(50).string(message.description);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountPropertyRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateAccountPropertyRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.code = reader.string();
                    break;
                case 4:
                    message.key = reader.string();
                    break;
                case 5:
                    message.value = reader.string();
                    break;
                case 6:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UpdateAccountPropertyRequest>): UpdateAccountPropertyRequest {
        const message = createBaseUpdateAccountPropertyRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.id = object.id ?? '0';
        message.code = object.code ?? '';
        message.key = object.key ?? '';
        message.value = object.value ?? '';
        message.description = object.description ?? '';
        return message;
    }
};

function createBaseDeleteAccountPropertyRequest(): DeleteAccountPropertyRequest {
    return { clientRequestId: '', id: '0' };
}

export const DeleteAccountPropertyRequest = {
    encode(message: DeleteAccountPropertyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.id !== '0') {
            writer.uint32(16).int64(message.id);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): DeleteAccountPropertyRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeleteAccountPropertyRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.id = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<DeleteAccountPropertyRequest>): DeleteAccountPropertyRequest {
        const message = createBaseDeleteAccountPropertyRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.id = object.id ?? '0';
        return message;
    }
};

function createBaseUpdateOnStaticDataAccountPropertyRequest(): UpdateOnStaticDataAccountPropertyRequest {
    return { clientRequestId: '' };
}

export const UpdateOnStaticDataAccountPropertyRequest = {
    encode(message: UpdateOnStaticDataAccountPropertyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOnStaticDataAccountPropertyRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateOnStaticDataAccountPropertyRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<UpdateOnStaticDataAccountPropertyRequest>
    ): UpdateOnStaticDataAccountPropertyRequest {
        const message = createBaseUpdateOnStaticDataAccountPropertyRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

function createBaseUpdateOnStaticDataAccountPropertyForCodeRequest(): UpdateOnStaticDataAccountPropertyForCodeRequest {
    return { clientRequestId: '', code: '' };
}

export const UpdateOnStaticDataAccountPropertyForCodeRequest = {
    encode(
        message: UpdateOnStaticDataAccountPropertyForCodeRequest,
        writer: _m0.Writer = _m0.Writer.create()
    ): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.code !== '') {
            writer.uint32(18).string(message.code);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOnStaticDataAccountPropertyForCodeRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUpdateOnStaticDataAccountPropertyForCodeRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.code = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(
        object: DeepPartial<UpdateOnStaticDataAccountPropertyForCodeRequest>
    ): UpdateOnStaticDataAccountPropertyForCodeRequest {
        const message = createBaseUpdateOnStaticDataAccountPropertyForCodeRequest();
        message.clientRequestId = object.clientRequestId ?? '';
        message.code = object.code ?? '';
        return message;
    }
};

function createBaseFindAccountPropertyByCodeAndKey(): FindAccountPropertyByCodeAndKey {
    return { clientRequestId: '', code: '', key: '' };
}

export const FindAccountPropertyByCodeAndKey = {
    encode(message: FindAccountPropertyByCodeAndKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.code !== '') {
            writer.uint32(18).string(message.code);
        }
        if (message.key !== '') {
            writer.uint32(26).string(message.key);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAccountPropertyByCodeAndKey {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAccountPropertyByCodeAndKey();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.code = reader.string();
                    break;
                case 3:
                    message.key = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAccountPropertyByCodeAndKey>): FindAccountPropertyByCodeAndKey {
        const message = createBaseFindAccountPropertyByCodeAndKey();
        message.clientRequestId = object.clientRequestId ?? '';
        message.code = object.code ?? '';
        message.key = object.key ?? '';
        return message;
    }
};

function createBaseFindAccountPropertiesByCode(): FindAccountPropertiesByCode {
    return { clientRequestId: '', code: '' };
}

export const FindAccountPropertiesByCode = {
    encode(message: FindAccountPropertiesByCode, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.code !== '') {
            writer.uint32(18).string(message.code);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAccountPropertiesByCode {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAccountPropertiesByCode();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.code = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAccountPropertiesByCode>): FindAccountPropertiesByCode {
        const message = createBaseFindAccountPropertiesByCode();
        message.clientRequestId = object.clientRequestId ?? '';
        message.code = object.code ?? '';
        return message;
    }
};

function createBaseFindAccountPropertiesByKey(): FindAccountPropertiesByKey {
    return { clientRequestId: '', key: '' };
}

export const FindAccountPropertiesByKey = {
    encode(message: FindAccountPropertiesByKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.key !== '') {
            writer.uint32(18).string(message.key);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAccountPropertiesByKey {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAccountPropertiesByKey();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.key = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAccountPropertiesByKey>): FindAccountPropertiesByKey {
        const message = createBaseFindAccountPropertiesByKey();
        message.clientRequestId = object.clientRequestId ?? '';
        message.key = object.key ?? '';
        return message;
    }
};

function createBaseFindAllAccountProperties(): FindAllAccountProperties {
    return { clientRequestId: '' };
}

export const FindAllAccountProperties = {
    encode(message: FindAllAccountProperties, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAllAccountProperties {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAllAccountProperties();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAllAccountProperties>): FindAllAccountProperties {
        const message = createBaseFindAllAccountProperties();
        message.clientRequestId = object.clientRequestId ?? '';
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
