/* eslint-disable */
export const protobufPackage = 'com.celertech.positionmanager.api.enums.producttype';

export enum ProductType {
    SPOT = 1,
    FORWARD = 2,
    SWAP = 3,
    MONEY_MARKET = 4,
    NON_DELIVERABLE_FORWARD = 5,
    /**
     * NON_DELIVERABLE_SWAP - CFD_FUTURE = 7;
     * 	CFD_SPOT = 8;
     * 	CFD_SHARE = 9;
     */
    NON_DELIVERABLE_SWAP = 6,
    /** CRYPTO_CROSS - Crypto */
    CRYPTO_CROSS = 10,
    BITCOIN = 11,
    BITCOIN_CASH = 12,
    ETHEREUM = 13,
    LITECOIN = 14,
    OAX = 15,
    RIPPLE = 16,
    CFD = 17,
    OUTRIGHT = 18,
    UNRECOGNIZED = -1
}
