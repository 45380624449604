import CurrencySelect, { Option } from '@/components/inputs/CurrencySelect';
import ErrorMessage from '@/components/inputs/ErrorMessage';
import { useCurrency } from '@/utils/hooks/useCurrency';
import { UseDisclosureReturn } from '@/utils/hooks/useDisclosure';
import { useNumberFormatter } from 'react-aria';
import { Controller, useFormContext } from 'react-hook-form';

interface ConvertToProps {
    availableConverts: Option[];
    estimatedProceeds: number;
    disclosure: UseDisclosureReturn;
}

const ConvertTo = ({ availableConverts, estimatedProceeds, disclosure }: ConvertToProps) => {
    const { watch, control } = useFormContext();
    const convertTo = watch('convertTo');

    const { order_decimals } = useCurrency(convertTo.value);

    const formatQty = useNumberFormatter({
        minimumFractionDigits: order_decimals,
        maximumFractionDigits: order_decimals
    });

    return (
        <div className="w-full">
            <span className="text-neutral-400 text-xs">To</span>
            <Controller
                control={control}
                name="convertTo"
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                        <CurrencySelect
                            selected={value}
                            onSelectChange={onChange}
                            onClick={disclosure[1].toggle}
                            options={availableConverts}>
                            <div className="flex flex-col border border-neutral-700 border-t-0 bg-brand-background-dark py-2 pb-4 px-3 rounded-b-md">
                                <div className="flex flex-col items-end justify-center h-32 w-full">
                                    <div className="w-full line-clamp-2 break-all text-right text-4xl leading-none font-bold tracking-wide bg-transparent text-neutral-200 focus-visible:outline-none placeholder:text-neutral-200">
                                        {estimatedProceeds ? formatQty.format(estimatedProceeds) : 0}
                                    </div>

                                    <span className="text-2xs textaz-neutral-400">Estimated Proceeds</span>
                                </div>
                            </div>
                            <ErrorMessage error={error} />
                        </CurrencySelect>
                    );
                }}
            />
        </div>
    );
};

export default ConvertTo;
