/* eslint-disable */
import { CallContext, CallOptions } from 'nice-grpc-common';
import { AccountPropertyDownstreamEvent, DeleteAccountPropertyNotification } from './DownstreamAccountPropertyProto';
import {
    FindAccountPropertiesByCode,
    CreateAccountPropertyRequest,
    UpdateAccountPropertyRequest,
    DeleteAccountPropertyRequest
} from './UpstreamAccountPropertyProto';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.staticdata.api.account.property';

export interface AccountPropertiesCollection {
    accountProperties: AccountPropertyDownstreamEvent[];
}

function createBaseAccountPropertiesCollection(): AccountPropertiesCollection {
    return { accountProperties: [] };
}

export const AccountPropertiesCollection = {
    encode(message: AccountPropertiesCollection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.accountProperties) {
            AccountPropertyDownstreamEvent.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): AccountPropertiesCollection {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAccountPropertiesCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.accountProperties.push(AccountPropertyDownstreamEvent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<AccountPropertiesCollection>): AccountPropertiesCollection {
        const message = createBaseAccountPropertiesCollection();
        message.accountProperties =
            object.accountProperties?.map((e) => AccountPropertyDownstreamEvent.fromPartial(e)) || [];
        return message;
    }
};

export type AccountPropertyServiceDefinition = typeof AccountPropertyServiceDefinition;
export const AccountPropertyServiceDefinition = {
    name: 'AccountPropertyService',
    fullName: 'com.celertech.staticdata.api.account.property.AccountPropertyService',
    methods: {
        findAccountPropertiesByCode: {
            name: 'findAccountPropertiesByCode',
            requestType: FindAccountPropertiesByCode,
            requestStream: false,
            responseType: AccountPropertiesCollection,
            responseStream: false,
            options: {}
        },
        createAccountProperty: {
            name: 'createAccountProperty',
            requestType: CreateAccountPropertyRequest,
            requestStream: false,
            responseType: AccountPropertyDownstreamEvent,
            responseStream: false,
            options: {}
        },
        updateAccountProperty: {
            name: 'updateAccountProperty',
            requestType: UpdateAccountPropertyRequest,
            requestStream: false,
            responseType: AccountPropertyDownstreamEvent,
            responseStream: false,
            options: {}
        },
        deleteAccountProperty: {
            name: 'deleteAccountProperty',
            requestType: DeleteAccountPropertyRequest,
            requestStream: false,
            responseType: DeleteAccountPropertyNotification,
            responseStream: false,
            options: {}
        }
    }
} as const;

export interface AccountPropertyServiceServiceImplementation<CallContextExt = {}> {
    findAccountPropertiesByCode(
        request: FindAccountPropertiesByCode,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<AccountPropertiesCollection>>;
    createAccountProperty(
        request: CreateAccountPropertyRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<AccountPropertyDownstreamEvent>>;
    updateAccountProperty(
        request: UpdateAccountPropertyRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<AccountPropertyDownstreamEvent>>;
    deleteAccountProperty(
        request: DeleteAccountPropertyRequest,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<DeleteAccountPropertyNotification>>;
}

export interface AccountPropertyServiceClient<CallOptionsExt = {}> {
    findAccountPropertiesByCode(
        request: DeepPartial<FindAccountPropertiesByCode>,
        options?: CallOptions & CallOptionsExt
    ): Promise<AccountPropertiesCollection>;
    createAccountProperty(
        request: DeepPartial<CreateAccountPropertyRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<AccountPropertyDownstreamEvent>;
    updateAccountProperty(
        request: DeepPartial<UpdateAccountPropertyRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<AccountPropertyDownstreamEvent>;
    deleteAccountProperty(
        request: DeepPartial<DeleteAccountPropertyRequest>,
        options?: CallOptions & CallOptionsExt
    ): Promise<DeleteAccountPropertyNotification>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;
