import { currencyConfigDict } from '@/config/currency';

export const getAssetName = (currency: string) =>
    currencyConfigDict[currency] ? currencyConfigDict[currency].show : currency;

export const translatePair = (pair: string) => {
    if (pair.indexOf('/') > -1) {
        const [ccy1, ccy2] = pair.split('/');
        const ccy1Show = getAssetName(ccy1);
        const ccy2Show = getAssetName(ccy2);
        return `${ccy1Show}/${ccy2Show}`;
    } else {
        return pair;
    }
};
