import ExportCSV from '@/components/common/ExportCSV';
import HistoricSearchModal from '@/components/modal/HistoricSearchModal';
import ModuleWindow from '@/components/mosaic/ModuleWindow';
import ModuleWindowControls from '@/components/mosaic/ModuleWindowControl';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import { useState } from 'react';
import Blotters, { HistoricSearch } from '.';

const BlottersModule = (props: any) => {
    const { screen, tradingModule } = props;
    const [CSVData, setCSVData] = useState<any[]>([]);
    const historicSearchDisclosure = useDisclosure(false);

    return (
        <ModuleWindow
            {...props}
            renderToolbar={() => (
                <div className="flex justify-between items-center w-full h-full border-b border-b-neutral-700 text-neutral-200">
                    <div className="h-full flex items-center">
                        <span className="p-2 font-semibold">{tradingModule.title}</span>

                        <div className="flex items-center h-full">
                            {(screen.laptop || screen.desktop) && (
                                <div
                                    title="Download to CSV"
                                    className="cursor-pointer hover:bg-brand-primary h-full px-2 flex items-center">
                                    <ExportCSV csvData={CSVData} />
                                </div>
                            )}
                            <div
                                title="Historic Search"
                                className="cursor-pointer hover:bg-brand-primary h-full px-2 flex items-center">
                                <HistoricSearch handlers={historicSearchDisclosure[1]} />
                            </div>
                        </div>
                    </div>
                    <ModuleWindowControls screen={screen} />
                </div>
            )}>
            <div id="orders" className="h-full bg-brand-background">
                <Blotters setCSVData={setCSVData} />
            </div>
            <HistoricSearchModal opened={historicSearchDisclosure[0]} handlers={historicSearchDisclosure[1]} />
        </ModuleWindow>
    );
};

export default BlottersModule;
