import { DepositType } from '@/components/modal/WithdrawalModal/DepositRequestModal';
import { Nullable } from '@/model/common';
import { InferType, mixed, number, object, string } from 'yup';

export const depositRequestSchema = () =>
    object({
        depositType: object({ label: string(), value: string() }).required('Please select a deposit type.'),
        bankInfoId: object({ label: string(), value: number() }).when('depositType', {
            is: (depositType) => depositType.value === DepositType.FIAT,
            then: object({ label: string(), value: number().required() }).required('Please select a bank account.')
        }),
        currencyId: object({ label: string(), value: number() }).required('Deposit Currency cannot be empty!'),

        walletId: object({ label: string(), value: number() })
            .required('Please select a wallet.')
            .when('depositType', {
                is: (depositType) => depositType.value === DepositType.CRYPTO,
                then: object({ label: string(), value: number().required() }).required('Please select a wallet.')
            }),

        // amount value must be greater than 0
        amount: string()
            .test('amount', 'Amount must be more than 0', (value) => {
                return parseFloat(value || '0') > 0;
            })
            .required('Amount cannot be empty!'),
        requestDescription: string(),
        supportingDetailsType: object({ label: string(), value: string() }).required(
            'Please select a supporting details type.'
        ),
        supportingDetails: mixed().when('supportingDetailsType', {
            is: () => false,
            // is: (state) => isInitiatingRequest === true && state.value === 'file',
            then: mixed().required('Please upload a proof of ownership.'),
            otherwise: mixed().nullable()
        }),
        transactionHash: string().test('transactionHash', 'Transaction hash is required', function (_value) {
            return true;
            // const { supportingDetailsType } = this.parent;
            // return supportingDetailsType.value === 'hash' ? !!value : true;
        })
    });

export type DepositRequestFormValues = InferType<ReturnType<typeof depositRequestSchema>>;
export type DepositRequestFormInput = Nullable<DepositRequestFormValues>;
