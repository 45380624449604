import { Side } from '@/compiled_proto/com/celertech/marketdata/api/enums/SideProto';
import { TimeInForceType } from '@/compiled_proto/com/celertech/positionmanager/api/enums/TimeInForceTypeProto';
import BigNumber from 'bignumber.js';
import { useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { invalidLimitPriceTooltip } from './useOrderBook';
import { UseOrderExecutionReturn } from './useOrderExecution';
import { UseOrderExecutionTraderReturn } from './useOrderExecutionTrader';

export interface UseOcoLimitOrderReturn {
    limitPriceTooltip: string;
    isRestingLimitOrder: boolean;
}

const useOcoLimitOrder = (
    formInstance: UseFormReturn<any>,
    orderExecutionProps: UseOrderExecutionReturn | UseOrderExecutionTraderReturn,
    idPrefix: string
): UseOcoLimitOrderReturn => {
    const { watch, setValue } = formInstance;
    const { sideState, isCcy2Order, orderBook } = orderExecutionProps;
    const { asks, bids, bestAsk, bestBid, worstAsk, worstBid, nextWorstAsk, nextWorstBid } = orderBook;

    const limitPriceKey = `${idPrefix}limitPrice`;

    const side = sideState[0];

    const [limitPrice, duration, quantity] = watch([limitPriceKey, 'duration', `${idPrefix}quantity`]);

    const isRestingLimitOrder = useMemo(() => {
        if (duration?.value) {
            return [TimeInForceType.DAY, TimeInForceType.GTC].includes(+duration.value as TimeInForceType);
        }
        return false;
    }, [duration]);

    const limitPriceTooltip = useMemo(() => {
        let tooltip: any = '';
        if (isRestingLimitOrder && limitPrice && bestAsk && bestBid) {
            if (side === Side.BUY) {
                if (isCcy2Order && limitPrice < bestBid) {
                    tooltip = invalidLimitPriceTooltip(side, bestBid, isCcy2Order);
                } else if (!isCcy2Order && limitPrice > bestAsk) {
                    tooltip = invalidLimitPriceTooltip(side, bestAsk, isCcy2Order);
                }
            }
            if (side === Side.SELL) {
                if (isCcy2Order && limitPrice > bestAsk) {
                    tooltip = invalidLimitPriceTooltip(side, bestAsk, isCcy2Order);
                } else if (!isCcy2Order && limitPrice < bestBid) {
                    tooltip = invalidLimitPriceTooltip(side, bestBid, isCcy2Order);
                }
            }
        }
        return tooltip;
    }, [bestAsk, bestBid, side, limitPrice, isRestingLimitOrder]);

    useEffect(() => {
        if (isRestingLimitOrder) {
            if (bestAsk && bestBid) {
                const limitPrice = BigNumber(bestAsk).plus(bestBid).dividedBy(2).toNumber();
                setValue(limitPriceKey, limitPrice, { shouldValidate: true });
            }
        }
    }, [isRestingLimitOrder]);

    useEffect(() => {
        if (!isRestingLimitOrder) {
            if (side === Side.BUY && asks) {
                if (quantity) setValue(limitPriceKey, nextWorstAsk(quantity), { shouldValidate: true });
                else setValue(limitPriceKey, worstAsk, { shouldValidate: true });
            } else if (side === Side.SELL && bids) {
                if (quantity) setValue(limitPriceKey, nextWorstBid(quantity), { shouldValidate: true });
                else setValue(limitPriceKey, worstBid, { shouldValidate: true });
            }
        }
    }, [asks, bids, quantity, side, isRestingLimitOrder]);

    return { limitPriceTooltip, isRestingLimitOrder };
};

export default useOcoLimitOrder;
