/* eslint-disable */
import { NetPositionUpdate } from './DownstreamPnlProto';
import { CallContext, CallOptions } from 'nice-grpc-common';
import Long from 'long';
import { MarginDownstreamEvent } from '../margin/DownstreamMarginProto';
import { DownstreamMarketDataPriceEvent } from '../../../midrate/api/price/DownstreamMarketDataProto';
import { MidRateSubscriptionRequest } from '../../../midrate/api/price/UpstreamMarketDataProto';
import { AccountUsageSubscribeRequest } from '../margin/UpstreamMarginProto';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.positionmanager.api.pnl';

export interface MarginEvent {
    event?: MarginDownstreamEvent | undefined;
    isHeartBeat: boolean;
}

export interface NetPositionUpdateEvent {
    positionEvent?: NetPositionEvent | undefined;
    isHeartBeat: boolean;
}

export interface NetPositionUpdateEventCollection {
    event: NetPositionUpdateEvent[];
}

export interface DownstreamMarketDataPrice {
    marketDataPriceEvent?: DownstreamMarketDataPriceEvent | undefined;
    isHeartBeat: boolean;
}

export interface SubscribeForPositionsRequest {
    accounts: string[];
}

export interface NetPositionEvent {
    positionUpdate?: NetPositionUpdate;
    unrealisedPnl: number;
    openPrice: number;
}

export interface ProfitAndLossRowSnapshot {
    securityId: string;
    account: string;
    netValue: number;
    netPosition: number;
    realisedPnlCurrency: string;
    realisedPnl: number;
    unRealisedPnl: number;
    baseCurrencyBuys: number;
    baseCurrencySells: number;
    termCurrencyBuys: number;
    termCurrencySells: number;
    transactionId: string;
    timestamp: string;
    createdTimestampUtc: string;
    displayUnRealisedPnl: string;
    displayRealisedPnl: string;
    displayNetPosition: string;
    netPositionEvent?: NetPositionEvent;
}

function createBaseMarginEvent(): MarginEvent {
    return { event: undefined, isHeartBeat: false };
}

export const MarginEvent = {
    encode(message: MarginEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.event !== undefined) {
            MarginDownstreamEvent.encode(message.event, writer.uint32(10).fork()).ldelim();
        }
        if (message.isHeartBeat === true) {
            writer.uint32(16).bool(message.isHeartBeat);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MarginEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMarginEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.event = MarginDownstreamEvent.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.isHeartBeat = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<MarginEvent>): MarginEvent {
        const message = createBaseMarginEvent();
        message.event =
            object.event !== undefined && object.event !== null
                ? MarginDownstreamEvent.fromPartial(object.event)
                : undefined;
        message.isHeartBeat = object.isHeartBeat ?? false;
        return message;
    }
};

function createBaseNetPositionUpdateEvent(): NetPositionUpdateEvent {
    return { positionEvent: undefined, isHeartBeat: false };
}

export const NetPositionUpdateEvent = {
    encode(message: NetPositionUpdateEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.positionEvent !== undefined) {
            NetPositionEvent.encode(message.positionEvent, writer.uint32(10).fork()).ldelim();
        }
        if (message.isHeartBeat === true) {
            writer.uint32(16).bool(message.isHeartBeat);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): NetPositionUpdateEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseNetPositionUpdateEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.positionEvent = NetPositionEvent.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.isHeartBeat = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<NetPositionUpdateEvent>): NetPositionUpdateEvent {
        const message = createBaseNetPositionUpdateEvent();
        message.positionEvent =
            object.positionEvent !== undefined && object.positionEvent !== null
                ? NetPositionEvent.fromPartial(object.positionEvent)
                : undefined;
        message.isHeartBeat = object.isHeartBeat ?? false;
        return message;
    }
};

function createBaseNetPositionUpdateEventCollection(): NetPositionUpdateEventCollection {
    return { event: [] };
}

export const NetPositionUpdateEventCollection = {
    encode(message: NetPositionUpdateEventCollection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.event) {
            NetPositionUpdateEvent.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): NetPositionUpdateEventCollection {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseNetPositionUpdateEventCollection();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.event.push(NetPositionUpdateEvent.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<NetPositionUpdateEventCollection>): NetPositionUpdateEventCollection {
        const message = createBaseNetPositionUpdateEventCollection();
        message.event = object.event?.map((e) => NetPositionUpdateEvent.fromPartial(e)) || [];
        return message;
    }
};

function createBaseDownstreamMarketDataPrice(): DownstreamMarketDataPrice {
    return { marketDataPriceEvent: undefined, isHeartBeat: false };
}

export const DownstreamMarketDataPrice = {
    encode(message: DownstreamMarketDataPrice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.marketDataPriceEvent !== undefined) {
            DownstreamMarketDataPriceEvent.encode(message.marketDataPriceEvent, writer.uint32(10).fork()).ldelim();
        }
        if (message.isHeartBeat === true) {
            writer.uint32(16).bool(message.isHeartBeat);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): DownstreamMarketDataPrice {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDownstreamMarketDataPrice();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.marketDataPriceEvent = DownstreamMarketDataPriceEvent.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.isHeartBeat = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<DownstreamMarketDataPrice>): DownstreamMarketDataPrice {
        const message = createBaseDownstreamMarketDataPrice();
        message.marketDataPriceEvent =
            object.marketDataPriceEvent !== undefined && object.marketDataPriceEvent !== null
                ? DownstreamMarketDataPriceEvent.fromPartial(object.marketDataPriceEvent)
                : undefined;
        message.isHeartBeat = object.isHeartBeat ?? false;
        return message;
    }
};

function createBaseSubscribeForPositionsRequest(): SubscribeForPositionsRequest {
    return { accounts: [] };
}

export const SubscribeForPositionsRequest = {
    encode(message: SubscribeForPositionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.accounts) {
            writer.uint32(10).string(v!);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): SubscribeForPositionsRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubscribeForPositionsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.accounts.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<SubscribeForPositionsRequest>): SubscribeForPositionsRequest {
        const message = createBaseSubscribeForPositionsRequest();
        message.accounts = object.accounts?.map((e) => e) || [];
        return message;
    }
};

function createBaseNetPositionEvent(): NetPositionEvent {
    return { positionUpdate: undefined, unrealisedPnl: 0, openPrice: 0 };
}

export const NetPositionEvent = {
    encode(message: NetPositionEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.positionUpdate !== undefined) {
            NetPositionUpdate.encode(message.positionUpdate, writer.uint32(10).fork()).ldelim();
        }
        if (message.unrealisedPnl !== 0) {
            writer.uint32(17).double(message.unrealisedPnl);
        }
        if (message.openPrice !== 0) {
            writer.uint32(25).double(message.openPrice);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): NetPositionEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseNetPositionEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.positionUpdate = NetPositionUpdate.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.unrealisedPnl = reader.double();
                    break;
                case 3:
                    message.openPrice = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<NetPositionEvent>): NetPositionEvent {
        const message = createBaseNetPositionEvent();
        message.positionUpdate =
            object.positionUpdate !== undefined && object.positionUpdate !== null
                ? NetPositionUpdate.fromPartial(object.positionUpdate)
                : undefined;
        message.unrealisedPnl = object.unrealisedPnl ?? 0;
        message.openPrice = object.openPrice ?? 0;
        return message;
    }
};

function createBaseProfitAndLossRowSnapshot(): ProfitAndLossRowSnapshot {
    return {
        securityId: '',
        account: '',
        netValue: 0,
        netPosition: 0,
        realisedPnlCurrency: '',
        realisedPnl: 0,
        unRealisedPnl: 0,
        baseCurrencyBuys: 0,
        baseCurrencySells: 0,
        termCurrencyBuys: 0,
        termCurrencySells: 0,
        transactionId: '',
        timestamp: '0',
        createdTimestampUtc: '',
        displayUnRealisedPnl: '',
        displayRealisedPnl: '',
        displayNetPosition: '',
        netPositionEvent: undefined
    };
}

export const ProfitAndLossRowSnapshot = {
    encode(message: ProfitAndLossRowSnapshot, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.securityId !== '') {
            writer.uint32(10).string(message.securityId);
        }
        if (message.account !== '') {
            writer.uint32(18).string(message.account);
        }
        if (message.netValue !== 0) {
            writer.uint32(25).double(message.netValue);
        }
        if (message.netPosition !== 0) {
            writer.uint32(33).double(message.netPosition);
        }
        if (message.realisedPnlCurrency !== '') {
            writer.uint32(42).string(message.realisedPnlCurrency);
        }
        if (message.realisedPnl !== 0) {
            writer.uint32(49).double(message.realisedPnl);
        }
        if (message.unRealisedPnl !== 0) {
            writer.uint32(57).double(message.unRealisedPnl);
        }
        if (message.baseCurrencyBuys !== 0) {
            writer.uint32(65).double(message.baseCurrencyBuys);
        }
        if (message.baseCurrencySells !== 0) {
            writer.uint32(73).double(message.baseCurrencySells);
        }
        if (message.termCurrencyBuys !== 0) {
            writer.uint32(81).double(message.termCurrencyBuys);
        }
        if (message.termCurrencySells !== 0) {
            writer.uint32(89).double(message.termCurrencySells);
        }
        if (message.transactionId !== '') {
            writer.uint32(98).string(message.transactionId);
        }
        if (message.timestamp !== '0') {
            writer.uint32(104).int64(message.timestamp);
        }
        if (message.createdTimestampUtc !== '') {
            writer.uint32(114).string(message.createdTimestampUtc);
        }
        if (message.displayUnRealisedPnl !== '') {
            writer.uint32(122).string(message.displayUnRealisedPnl);
        }
        if (message.displayRealisedPnl !== '') {
            writer.uint32(130).string(message.displayRealisedPnl);
        }
        if (message.displayNetPosition !== '') {
            writer.uint32(138).string(message.displayNetPosition);
        }
        if (message.netPositionEvent !== undefined) {
            NetPositionEvent.encode(message.netPositionEvent, writer.uint32(146).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): ProfitAndLossRowSnapshot {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProfitAndLossRowSnapshot();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.securityId = reader.string();
                    break;
                case 2:
                    message.account = reader.string();
                    break;
                case 3:
                    message.netValue = reader.double();
                    break;
                case 4:
                    message.netPosition = reader.double();
                    break;
                case 5:
                    message.realisedPnlCurrency = reader.string();
                    break;
                case 6:
                    message.realisedPnl = reader.double();
                    break;
                case 7:
                    message.unRealisedPnl = reader.double();
                    break;
                case 8:
                    message.baseCurrencyBuys = reader.double();
                    break;
                case 9:
                    message.baseCurrencySells = reader.double();
                    break;
                case 10:
                    message.termCurrencyBuys = reader.double();
                    break;
                case 11:
                    message.termCurrencySells = reader.double();
                    break;
                case 12:
                    message.transactionId = reader.string();
                    break;
                case 13:
                    message.timestamp = longToString(reader.int64() as Long);
                    break;
                case 14:
                    message.createdTimestampUtc = reader.string();
                    break;
                case 15:
                    message.displayUnRealisedPnl = reader.string();
                    break;
                case 16:
                    message.displayRealisedPnl = reader.string();
                    break;
                case 17:
                    message.displayNetPosition = reader.string();
                    break;
                case 18:
                    message.netPositionEvent = NetPositionEvent.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<ProfitAndLossRowSnapshot>): ProfitAndLossRowSnapshot {
        const message = createBaseProfitAndLossRowSnapshot();
        message.securityId = object.securityId ?? '';
        message.account = object.account ?? '';
        message.netValue = object.netValue ?? 0;
        message.netPosition = object.netPosition ?? 0;
        message.realisedPnlCurrency = object.realisedPnlCurrency ?? '';
        message.realisedPnl = object.realisedPnl ?? 0;
        message.unRealisedPnl = object.unRealisedPnl ?? 0;
        message.baseCurrencyBuys = object.baseCurrencyBuys ?? 0;
        message.baseCurrencySells = object.baseCurrencySells ?? 0;
        message.termCurrencyBuys = object.termCurrencyBuys ?? 0;
        message.termCurrencySells = object.termCurrencySells ?? 0;
        message.transactionId = object.transactionId ?? '';
        message.timestamp = object.timestamp ?? '0';
        message.createdTimestampUtc = object.createdTimestampUtc ?? '';
        message.displayUnRealisedPnl = object.displayUnRealisedPnl ?? '';
        message.displayRealisedPnl = object.displayRealisedPnl ?? '';
        message.displayNetPosition = object.displayNetPosition ?? '';
        message.netPositionEvent =
            object.netPositionEvent !== undefined && object.netPositionEvent !== null
                ? NetPositionEvent.fromPartial(object.netPositionEvent)
                : undefined;
        return message;
    }
};

export type PositionManagerServiceDefinition = typeof PositionManagerServiceDefinition;
export const PositionManagerServiceDefinition = {
    name: 'PositionManagerService',
    fullName: 'com.celertech.positionmanager.api.pnl.PositionManagerService',
    methods: {
        subscribeForPositions: {
            name: 'subscribeForPositions',
            requestType: SubscribeForPositionsRequest,
            requestStream: false,
            responseType: NetPositionUpdateEvent,
            responseStream: true,
            options: {}
        },
        subscribeForUnrealisedPnl: {
            name: 'subscribeForUnrealisedPnl',
            requestType: MidRateSubscriptionRequest,
            requestStream: false,
            responseType: NetPositionUpdateEventCollection,
            responseStream: true,
            options: {}
        },
        subscribeForAccountUsage: {
            name: 'subscribeForAccountUsage',
            requestType: AccountUsageSubscribeRequest,
            requestStream: false,
            responseType: MarginEvent,
            responseStream: true,
            options: {}
        }
    }
} as const;

export interface PositionManagerServiceServiceImplementation<CallContextExt = {}> {
    subscribeForPositions(
        request: SubscribeForPositionsRequest,
        context: CallContext & CallContextExt
    ): ServerStreamingMethodResult<DeepPartial<NetPositionUpdateEvent>>;
    subscribeForUnrealisedPnl(
        request: MidRateSubscriptionRequest,
        context: CallContext & CallContextExt
    ): ServerStreamingMethodResult<DeepPartial<NetPositionUpdateEventCollection>>;
    subscribeForAccountUsage(
        request: AccountUsageSubscribeRequest,
        context: CallContext & CallContextExt
    ): ServerStreamingMethodResult<DeepPartial<MarginEvent>>;
}

export interface PositionManagerServiceClient<CallOptionsExt = {}> {
    subscribeForPositions(
        request: DeepPartial<SubscribeForPositionsRequest>,
        options?: CallOptions & CallOptionsExt
    ): AsyncIterable<NetPositionUpdateEvent>;
    subscribeForUnrealisedPnl(
        request: DeepPartial<MidRateSubscriptionRequest>,
        options?: CallOptions & CallOptionsExt
    ): AsyncIterable<NetPositionUpdateEventCollection>;
    subscribeForAccountUsage(
        request: DeepPartial<AccountUsageSubscribeRequest>,
        options?: CallOptions & CallOptionsExt
    ): AsyncIterable<MarginEvent>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}

export type ServerStreamingMethodResult<Response> = {
    [Symbol.asyncIterator](): AsyncIterator<Response, void>;
};
