import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { ComponentProps, forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';

interface TickerSlippageInputProps extends ComponentProps<'input'> {
    name: string;
    label?: string;
    min?: string | number;
    step?: number;
    className?: any;
}

const TickerSlippageInput = forwardRef<HTMLInputElement, TickerSlippageInputProps>((props, ref) => {
    const { name, min = 0, step = 1, disabled = false, className } = props;
    const { getFieldState, getValues, formState, setValue } = useFormContext();
    const fieldState = getFieldState(name, formState);

    return (
        <div className="relative flex flex-2">
            <input
                {...props}
                ref={ref}
                autoComplete="off"
                className={cn(
                    'appearance-none block w-full px-2 py-1 bg-brand-background-dark border border-neutral-700 text-sm placeholder-neutral-500 focus:outline-none',
                    { 'text-neutral-400 cursor-not-allowed': disabled },
                    { 'text-neutral-200': !className && !disabled },
                    className
                )}
                placeholder="Slippage (pips)"
            />
            <div className="absolute right-1 flex flex-col h-full justify-center text-[8px] text-neutral-200 w-5">
                <button
                    type="button"
                    className={cn('hover:text-brand-primary-light -mb-0.5 px-0', {
                        'text-neutral-400 cursor-not-allowed': disabled
                    })}
                    onClick={() => {
                        if (!disabled) {
                            const prevValue = +(getValues(name) || 0);
                            const nextValue = BigNumber(prevValue).plus(step).toNumber();
                            setValue(name, nextValue, { shouldValidate: true });
                        }
                    }}>
                    &#x25B2;
                </button>
                <button
                    type="button"
                    className={cn('hover:text-brand-primary-light -mt-0.5 px-0', {
                        'text-neutral-400 cursor-not-allowed': disabled
                    })}
                    onClick={() => {
                        if (!disabled) {
                            const prevValue = +(getValues(name) || 0);
                            const nextValue = BigNumber(prevValue).minus(step).toNumber();
                            if (nextValue <= +min) setValue(name, min, { shouldValidate: true });
                            else setValue(name, nextValue, { shouldValidate: true });
                        }
                    }}>
                    &#x25BC;
                </button>
            </div>
            {fieldState.error ? <span className="text-brand-red italic">{fieldState.error?.message}</span> : null}
        </div>
    );
});

TickerSlippageInput.displayName = 'TickerSlippageInput';

export default TickerSlippageInput;
