/* eslint-disable */
import { AccountDownstreamEvent } from '../../account/DownstreamAccountProto';
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.staticdata.api.user.account';

export interface UserAccountDownstreamEvent {
    id: string;
    username: string;
    account?: AccountDownstreamEvent;
    /** UTC timestamp represented as milliseconds since epoch */
    createdInMillis: string;
    /** UTC timestamp represented as milliseconds since epoch */
    updatedInMillis: string;
}

export interface DeleteUserAccountNotification {
    id: string;
    accountId: string;
    accountCode: string;
    username: string;
}

function createBaseUserAccountDownstreamEvent(): UserAccountDownstreamEvent {
    return { id: '0', username: '', account: undefined, createdInMillis: '0', updatedInMillis: '0' };
}

export const UserAccountDownstreamEvent = {
    encode(message: UserAccountDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== '0') {
            writer.uint32(8).int64(message.id);
        }
        if (message.username !== '') {
            writer.uint32(18).string(message.username);
        }
        if (message.account !== undefined) {
            AccountDownstreamEvent.encode(message.account, writer.uint32(26).fork()).ldelim();
        }
        if (message.createdInMillis !== '0') {
            writer.uint32(32).int64(message.createdInMillis);
        }
        if (message.updatedInMillis !== '0') {
            writer.uint32(40).int64(message.updatedInMillis);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): UserAccountDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUserAccountDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.username = reader.string();
                    break;
                case 3:
                    message.account = AccountDownstreamEvent.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.createdInMillis = longToString(reader.int64() as Long);
                    break;
                case 5:
                    message.updatedInMillis = longToString(reader.int64() as Long);
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<UserAccountDownstreamEvent>): UserAccountDownstreamEvent {
        const message = createBaseUserAccountDownstreamEvent();
        message.id = object.id ?? '0';
        message.username = object.username ?? '';
        message.account =
            object.account !== undefined && object.account !== null
                ? AccountDownstreamEvent.fromPartial(object.account)
                : undefined;
        message.createdInMillis = object.createdInMillis ?? '0';
        message.updatedInMillis = object.updatedInMillis ?? '0';
        return message;
    }
};

function createBaseDeleteUserAccountNotification(): DeleteUserAccountNotification {
    return { id: '0', accountId: '0', accountCode: '', username: '' };
}

export const DeleteUserAccountNotification = {
    encode(message: DeleteUserAccountNotification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== '0') {
            writer.uint32(8).int64(message.id);
        }
        if (message.accountId !== '0') {
            writer.uint32(16).int64(message.accountId);
        }
        if (message.accountCode !== '') {
            writer.uint32(26).string(message.accountCode);
        }
        if (message.username !== '') {
            writer.uint32(34).string(message.username);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUserAccountNotification {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDeleteUserAccountNotification();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.accountId = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.accountCode = reader.string();
                    break;
                case 4:
                    message.username = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<DeleteUserAccountNotification>): DeleteUserAccountNotification {
        const message = createBaseDeleteUserAccountNotification();
        message.id = object.id ?? '0';
        message.accountId = object.accountId ?? '0';
        message.accountCode = object.accountCode ?? '';
        message.username = object.username ?? '';
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
