import cn from 'classnames';
import { CSVLink } from 'react-csv';
import { MdOutlineSimCardDownload } from 'react-icons/md';

interface ExportCSVProps {
    className?: string;
    blotter?: any;
    csvData?: any[];
}

const ExportCSV = (props: ExportCSVProps) => {
    const { csvData, className } = props;

    return (
        <CSVLink filename="export.csv" data={csvData || []}>
            <MdOutlineSimCardDownload className={cn(`w-4 h-4 text-white`, className)} />
        </CSVLink>
    );
};

export default ExportCSV;
