import { FileWithPath } from '@/types/common';
import { useSso } from '@/utils/providers/SSOProvider';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import cn from 'classnames';
import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { MdClose, MdOutlineFileUpload, MdOutlineImage } from 'react-icons/md';
import { DocumentPreview } from './DocumentPreview';

const maxFiles = 1;
const maxFileSize = 10 * 1024 * 1024;
const acceptedFileTypes = [
    MIME_TYPES.png,
    MIME_TYPES.jpeg,
    MIME_TYPES.pdf,
    MIME_TYPES.mp4,
    'video/avi',
    'video/x-msvideo',
    'video/msvideo',
    'application/x-troff-msvideo',
    'video/mpeg',
    'video/webm'
];
const DropzoneErrorMessage: {
    [key: string]: string;
} = {
    'file-too-large': 'File size should be less than 10MB',
    'file-invalid-type': 'Unsupported File Format. Only format allowed are JPEG, PNG, PDF, MP4, WEBM, AVI, MPG',
    'too-many-files': 'Only one file is allowed'
};

export type RequestFileProofUploadProps = {
    setError: Dispatch<
        SetStateAction<{
            message: string;
        }>
    >;
    hasFile?: boolean;
    previewFile?: string;
    getFilesData: (files: FileWithPath[]) => void;
    requestStatus?: any;
    // requestStatus?: Request['requestStatus'];
    fileType?: string;
    requestDetails: {
        entityId: number;
        requestId: number;
    };
};

export const RequestFileProofUpload: React.FC<RequestFileProofUploadProps> = ({
    setError,
    previewFile,
    hasFile,
    getFilesData,
    requestStatus,
    fileType,
    requestDetails
}) => {
    const [files, setFiles] = useState<FileWithPath[]>([]);
    const noFilesUploaded = !previewFile && !hasFile && !files.length;

    const { canRequestDeposit, canRequestWithdrawal } = useSso();

    useEffect(() => {
        getFilesData(files);
    }, [files]);

    return (
        <Fragment>
            {noFilesUploaded && (
                <Fragment>
                    {requestStatus !== 'INITIATED' && requestStatus !== undefined && (
                        <div className="items-center">There is no proof of ownership</div>
                    )}
                    {(requestStatus === 'INITIATED' || requestStatus === undefined) &&
                        (canRequestDeposit || canRequestWithdrawal) && (
                            <Dropzone
                                name="proofOfOwnership"
                                className="flex items-center justify-center h-full w-full bg-neutral-background py-2 px-4 rounded-sm border border-dashed border-neutral-500"
                                onDrop={setFiles}
                                autoFocus={true}
                                onReject={(error) =>
                                    setError({ message: DropzoneErrorMessage[error[0].errors[0].code] })
                                }
                                maxFiles={maxFiles}
                                maxSize={maxFileSize}
                                accept={acceptedFileTypes}>
                                <div
                                    className={cn(
                                        'hover:cursor-pointer h-full w-full flex items-center justify-center text-center'
                                    )}>
                                    {!files.length && (
                                        <div className="flex flex-col text-center items-center gap-3 text-sm text-neutral-200">
                                            <Dropzone.Accept>
                                                <MdOutlineFileUpload className="w-10 h-10" />
                                            </Dropzone.Accept>
                                            <Dropzone.Idle>
                                                <MdOutlineImage className="w-10 h-10" />
                                            </Dropzone.Idle>
                                            <Dropzone.Reject>
                                                <MdClose className="w-10 h-10" />
                                            </Dropzone.Reject>
                                            <div className="text-neutral-200">
                                                Drag or click to upload your Supporting Details here
                                            </div>
                                            <div className="text-sm text-neutral-500 flex flex-col">
                                                <span>
                                                    Support file formats are{' '}
                                                    <b>.jpeg, .png, .pdf, .mp4, .avi, .webm, .mpg</b>
                                                </span>
                                                <span>
                                                    Attached file should not exceed <b>10mb</b>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Dropzone>
                        )}
                </Fragment>
            )}

            {!noFilesUploaded && (
                <DocumentPreview
                    files={files}
                    setFiles={setFiles}
                    previewFile={previewFile}
                    fileType={fileType}
                    requestDetails={requestDetails}
                />
            )}
        </Fragment>
    );
};
