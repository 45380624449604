/* eslint-disable */
import { AssetType } from '../enums/AssetTypeProto';
import { ProductType } from '../enums/ProductTypeProto';
import { Side } from '../enums/SideProto';
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.piggybank.api.subledger';

export interface SubLedgerSnapshotDownstreamEvent {
    generalLedgerTransactionId: string;
    account: string;
    assetType: AssetType;
    productType: ProductType;
    securityId: string;
    currency: string;
    netPosition: number;
    notionalCurrency: string;
    notionalNetPosition: number;
    settlementDate: string;
    side: Side;
    settlementType: string;
    securityCode: string;
    productTypeNetPosition: ProductTypeNetPosition[];
    netRawPosition: number;
}

export interface ProductTypeNetPosition {
    productType: ProductType;
    netPosition: number;
}

function createBaseSubLedgerSnapshotDownstreamEvent(): SubLedgerSnapshotDownstreamEvent {
    return {
        generalLedgerTransactionId: '0',
        account: '',
        assetType: 1,
        productType: 1,
        securityId: '',
        currency: '',
        netPosition: 0,
        notionalCurrency: '',
        notionalNetPosition: 0,
        settlementDate: '',
        side: 1,
        settlementType: '',
        securityCode: '',
        productTypeNetPosition: [],
        netRawPosition: 0
    };
}

export const SubLedgerSnapshotDownstreamEvent = {
    encode(message: SubLedgerSnapshotDownstreamEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.generalLedgerTransactionId !== '0') {
            writer.uint32(8).int64(message.generalLedgerTransactionId);
        }
        if (message.account !== '') {
            writer.uint32(18).string(message.account);
        }
        if (message.assetType !== 1) {
            writer.uint32(24).int32(message.assetType);
        }
        if (message.productType !== 1) {
            writer.uint32(32).int32(message.productType);
        }
        if (message.securityId !== '') {
            writer.uint32(42).string(message.securityId);
        }
        if (message.currency !== '') {
            writer.uint32(50).string(message.currency);
        }
        if (message.netPosition !== 0) {
            writer.uint32(57).double(message.netPosition);
        }
        if (message.notionalCurrency !== '') {
            writer.uint32(66).string(message.notionalCurrency);
        }
        if (message.notionalNetPosition !== 0) {
            writer.uint32(73).double(message.notionalNetPosition);
        }
        if (message.settlementDate !== '') {
            writer.uint32(82).string(message.settlementDate);
        }
        if (message.side !== 1) {
            writer.uint32(88).int32(message.side);
        }
        if (message.settlementType !== '') {
            writer.uint32(98).string(message.settlementType);
        }
        if (message.securityCode !== '') {
            writer.uint32(106).string(message.securityCode);
        }
        for (const v of message.productTypeNetPosition) {
            ProductTypeNetPosition.encode(v!, writer.uint32(114).fork()).ldelim();
        }
        if (message.netRawPosition !== 0) {
            writer.uint32(121).double(message.netRawPosition);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): SubLedgerSnapshotDownstreamEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubLedgerSnapshotDownstreamEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.generalLedgerTransactionId = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.account = reader.string();
                    break;
                case 3:
                    message.assetType = reader.int32() as any;
                    break;
                case 4:
                    message.productType = reader.int32() as any;
                    break;
                case 5:
                    message.securityId = reader.string();
                    break;
                case 6:
                    message.currency = reader.string();
                    break;
                case 7:
                    message.netPosition = reader.double();
                    break;
                case 8:
                    message.notionalCurrency = reader.string();
                    break;
                case 9:
                    message.notionalNetPosition = reader.double();
                    break;
                case 10:
                    message.settlementDate = reader.string();
                    break;
                case 11:
                    message.side = reader.int32() as any;
                    break;
                case 12:
                    message.settlementType = reader.string();
                    break;
                case 13:
                    message.securityCode = reader.string();
                    break;
                case 14:
                    message.productTypeNetPosition.push(ProductTypeNetPosition.decode(reader, reader.uint32()));
                    break;
                case 15:
                    message.netRawPosition = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<SubLedgerSnapshotDownstreamEvent>): SubLedgerSnapshotDownstreamEvent {
        const message = createBaseSubLedgerSnapshotDownstreamEvent();
        message.generalLedgerTransactionId = object.generalLedgerTransactionId ?? '0';
        message.account = object.account ?? '';
        message.assetType = object.assetType ?? 1;
        message.productType = object.productType ?? 1;
        message.securityId = object.securityId ?? '';
        message.currency = object.currency ?? '';
        message.netPosition = object.netPosition ?? 0;
        message.notionalCurrency = object.notionalCurrency ?? '';
        message.notionalNetPosition = object.notionalNetPosition ?? 0;
        message.settlementDate = object.settlementDate ?? '';
        message.side = object.side ?? 1;
        message.settlementType = object.settlementType ?? '';
        message.securityCode = object.securityCode ?? '';
        message.productTypeNetPosition =
            object.productTypeNetPosition?.map((e) => ProductTypeNetPosition.fromPartial(e)) || [];
        message.netRawPosition = object.netRawPosition ?? 0;
        return message;
    }
};

function createBaseProductTypeNetPosition(): ProductTypeNetPosition {
    return { productType: 1, netPosition: 0 };
}

export const ProductTypeNetPosition = {
    encode(message: ProductTypeNetPosition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.productType !== 1) {
            writer.uint32(8).int32(message.productType);
        }
        if (message.netPosition !== 0) {
            writer.uint32(17).double(message.netPosition);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): ProductTypeNetPosition {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseProductTypeNetPosition();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.productType = reader.int32() as any;
                    break;
                case 2:
                    message.netPosition = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<ProductTypeNetPosition>): ProductTypeNetPosition {
        const message = createBaseProductTypeNetPosition();
        message.productType = object.productType ?? 1;
        message.netPosition = object.netPosition ?? 0;
        return message;
    }
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

function longToString(long: Long) {
    return long.toString();
}

if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
